import React from 'react';

import WatchDataStatisticsView from '../components/organisms/WatchDataStatisticsView';
import PageTemplate from '../components/templates/PageTemplate';

const ReportsContainer = () => {
  return (
    <PageTemplate>
      <WatchDataStatisticsView />
    </PageTemplate>
  );
};

export default ReportsContainer;

import slugify from 'slugify';
import { PLAYLIST_TYPE } from 'shared/const';
import { selectTranslation } from './selectTranslation';

const DEFAULT_SLUG = '-';

export const getCategorySlug = (category, locale) => {
  return (
    slugify(
      selectTranslation(category.titleTranslations, category.title, locale, ''),
      {
        replacement: '-',
        lower: true,
        strict: true,
      }
    ) || DEFAULT_SLUG
  );
};

export const getCategoryDetailUrl = (category, locale) => {
  const slug = getCategorySlug(category, locale);

  return `/${locale}/category/${category.shortId}/${slug}`;
};

export const getCourseSlug = (course, locale) => {
  return (
    slugify(
      selectTranslation(course.nameTranslations, course.name, locale, ''),
      {
        replacement: '-',
        lower: true,
        strict: true,
      }
    ) || DEFAULT_SLUG
  );
};

export const getCourseDetailUrl = (course, locale) => {
  const slug = getCourseSlug(course, locale);

  return `/${locale}/course/${course.shortId}/${slug}`;
};

export const getExpertSlug = (expert) => {
  return (
    slugify(`${expert.firstname} ${expert.lastname}`, {
      replacement: '-',
      lower: true,
      strict: true,
    }) || DEFAULT_SLUG
  );
};

export const getExpertDetailUrl = (expert, locale) => {
  const slug = getExpertSlug(expert);

  return `/${locale}/expert/${expert.shortId}/${slug}`;
};

export const getEpisodeSlug = (episode, locale) => {
  return (
    slugify(
      selectTranslation(episode.nameTranslations, episode.name, locale, ''),
      {
        replacement: '-',
        lower: true,
        strict: true,
      }
    ) || DEFAULT_SLUG
  );
};

export const getEpisodeDetailUrl = (course, episode, locale) => {
  const courseSlug = getCourseSlug(course, locale);
  const episodeSlug = getEpisodeSlug(episode, locale);

  return `/${locale}/course/${course.shortId}/${courseSlug}/watch/${episode.shortId}/${episodeSlug}`;
};

export const getQuizPageUrl = (course, quizShortId, locale) => {
  const courseSlug =
    slugify(
      selectTranslation(course.nameTranslations, course.name, locale, ''),
      {
        replacement: '-',
        lower: true,
        strict: true,
      }
    ) || DEFAULT_SLUG;

  return `/${locale}/course/${course.shortId}/${courseSlug}/quiz/${quizShortId}`;
};
export const getGamePageUrl = (course, quizShortId, locale) => {
  const courseSlug =
    slugify(
      selectTranslation(course.nameTranslations, course.name, locale, ''),
      {
        replacement: '-',
        lower: true,
        strict: true,
      }
    ) || DEFAULT_SLUG;

  return `/${locale}/course/${course.shortId}/${courseSlug}/game/${quizShortId}`;
};
export const getTrailerPageUrl = (course, locale) => {
  const courseSlug =
    slugify(
      selectTranslation(course.nameTranslations, course.name, locale, ''),
      {
        replacement: '-',
        lower: true,
        strict: true,
      }
    ) || DEFAULT_SLUG;

  return `/${locale}/course/${course.shortId}/${courseSlug}/trailer`;
};

export const getPlaylistSlug = (playlist, locale) => {
  return (
    slugify(selectTranslation(playlist.titleTranslations, '', locale, ''), {
      replacement: '-',
      lower: true,
      strict: true,
    }) || DEFAULT_SLUG
  );
};

const playlistSub = (type) =>
  type === PLAYLIST_TYPE.NANO_DEGREE ? 'nanodegree' : 'playlist';

export const getPlaylistTrailerUrl = (playlist, locale) => {
  const slug = getPlaylistSlug(playlist, locale);
  const { type } = playlist;

  return `/${locale}/${playlistSub(type)}/${playlist.shortId}/${slug}/trailer`;
};

export const getPlaylistDetailUrl = (playlist, locale) => {
  const slug = getPlaylistSlug(playlist, locale);
  const { type } = playlist;

  return `/${locale}/${playlistSub(type)}/${playlist.shortId}/${slug}`;
};

export const getPlaylistCourseDetailUrl = (playlist, course, locale) => {
  const playlistSlug = getPlaylistSlug(playlist, locale);
  const courseSlug = getCourseSlug(course, locale);
  const { type } = playlist;

  return `/${locale}/${playlistSub(type)}/${
    playlist.shortId
  }/${playlistSlug}/course/${course.shortId}/${courseSlug}`;
};

export const getPlaylistEpisodeDetailUrl = (
  playlist,
  course,
  episode,
  locale
) => {
  const playlistSlug = getPlaylistSlug(playlist, locale);
  const courseSlug = getCourseSlug(course, locale);
  const episodeSlug = getEpisodeSlug(episode, locale);
  const { type } = playlist;

  return `/${locale}/${playlistSub(type)}/${
    playlist.shortId
  }/${playlistSlug}/course/${course.shortId}/${courseSlug}/watch/${
    episode.shortId
  }/${episodeSlug}`;
};

export const getPlaylistExamDetailUrl = (playlist, locale) => {
  const playlistSlug = getPlaylistSlug(playlist, locale);
  const { type, quizShortId } = playlist;

  return `/${locale}/${playlistSub(type)}/${
    playlist.shortId
  }/${playlistSlug}/exam/${quizShortId}`;
};

export const getPlaylistCourseQuizDetailUrl = (playlist, course, locale) => {
  const playlistSlug = getPlaylistSlug(playlist, locale);
  const courseSlug = getCourseSlug(course, locale);
  const { type } = playlist;
  const {
    quiz: { shortId: quizShortId },
  } = course;

  return `/${locale}/${playlistSub(type)}/${
    playlist.shortId
  }/${playlistSlug}/course/${course.shortId}/${courseSlug}/quiz/${quizShortId}`;
};

export const getPlaylistCourseGameDetailUrl = (playlist, course, locale) => {
  const playlistSlug = getPlaylistSlug(playlist, locale);
  const courseSlug = getCourseSlug(course, locale);
  const { type } = playlist;
  const {
    quiz: { shortId: quizShortId },
  } = course;

  return `/${locale}/${playlistSub(type)}/${
    playlist.shortId
  }/${playlistSlug}/course/${course.shortId}/${courseSlug}/game/${quizShortId}`;
};

import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import MuiPagination from '@mui/material/Pagination';
import MuiPaginationItem from '@mui/material/PaginationItem';

import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';

import { BACKGROUND_THEMES } from 'shared/const';
import IconButton from 'shared/atoms/IconButton';
import theme from 'shared/themes/default';

const DARK_COLORS = {
  icon: theme.palette.lightPure,
  iconHover: theme.palette.disabledLight,
  iconDisabled: theme.palette.disabledDark,
  activeBackgroundHover: theme.palette.lightInteracting,
  activeNumberHover: theme.palette.mainDark,
  activeNumber: theme.palette.mainDark,
  number: theme.palette.lightPure,
  numberHover: theme.palette.disabledLight,
};

const LIGHT_COLORS = {
  icon: theme.palette.darkDistinct,
  iconHover: theme.palette.disabledDark,
  iconDisabled: theme.palette.disabledLight,
  activeBackgroundHover: theme.palette.darkDistinct,
  activeNumberHover: theme.palette.lightPure,
  activeNumber: theme.palette.mainDark,
  number: theme.palette.mainDark,
  numberHover: theme.palette.disabledDark,
};

const getColor = (themeVariant, item) => {
  if (themeVariant === 'light') return LIGHT_COLORS[item];
  return DARK_COLORS[item];
};

const paginationRootSx = {
  ul: {
    '&.MuiPagination-ul': {
      justifyContent: 'center',
    },
  },
};

const paginationItemSx = (themeVariant) => ({
  'min-width': '28px',
  height: '28px',
  margin: 0,
  padding: '0 5px',
  '&.MuiPaginationItem-page': {
    color: getColor(themeVariant, 'number'),
    backgroundColor: 'transparent',
    fontWeight: '600',
    fontSize: '1rem',
  },
  '&.MuiPaginationItem-page:hover': {
    color: getColor(themeVariant, 'numberHover'),
  },
  '&.Mui-selected': {
    color: getColor(themeVariant, 'activeNumber'),
    backgroundColor: theme.palette.brand,
  },
  '&.Mui-selected:hover': {
    color: getColor(themeVariant, 'activeNumberHover'),
    backgroundColor: getColor(themeVariant, 'activeBackgroundHover'),
  },
});

const PrevNextPageButton = (item) => {
  const {
    'aria-label': ariaLabel,
    disabled,
    onClick,
    type,
    themeVariant,
  } = item;
  return (
    <IconButton
      ariaLabel={ariaLabel}
      disabled={disabled}
      icon={PlayArrowRoundedIcon}
      onClick={onClick}
      small
      sx={{ mx: theme.spacing.s }}
      sxIcon={
        type === 'previous'
          ? {
              transform: 'rotate(180deg)',
            }
          : {}
      }
      theme={themeVariant}
      type="secondary"
    />
  );
};

const PaginationItemEllipsis = styled(MuiPaginationItem)((props) => ({
  color: getColor(props.themeVariant, 'number'),
  backgroundColor: 'transparent',
  fontWeight: '600',
}));

const isPrevNext = ({ type }) => ['previous', 'next'].includes(type);
const isEllipsis = ({ type }) =>
  ['start-ellipsis', 'end-ellipsis'].includes(type);

const renderItem = (item, themeVariant) => {
  if (isPrevNext(item)) {
    return <PrevNextPageButton {...item} themeVariant={themeVariant} />;
  }
  if (isEllipsis(item)) {
    // When being an ellipsis, MUI's PaginationItem was not taking sx into account
    return <PaginationItemEllipsis themeVariant={themeVariant} {...item} />;
  }
  return <MuiPaginationItem sx={paginationItemSx(themeVariant)} {...item} />;
};

const Pagination = ({
  totalPages,
  theme: themeVariant,
  initialPage,
  ...props
}) => {
  return (
    <MuiPagination
      sx={paginationRootSx}
      count={totalPages}
      defaultPage={initialPage}
      siblingCount={0}
      renderItem={(item) => renderItem(item, themeVariant)}
      {...props}
    />
  );
};

Pagination.propTypes = {
  initialPage: PropTypes.number,
  totalPages: PropTypes.number.isRequired,
  theme: PropTypes.oneOf(BACKGROUND_THEMES),
};

Pagination.defaultProps = {
  initialPage: 1,
  theme: 'light',
};

export default Pagination;

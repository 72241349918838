import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import useGroups from './useGroups';
import useInvitations from './useInvitations';
import useOrganizations from './useOrganizations';
import usePlaylists from './usePlaylists';
import useUsers from './useUsers';
import useUserRoles from './useUserRoles';

const OrganizationMembersContext = React.createContext();

function OrganizationMembersProvider({
  changeUserRole,
  createGroup,
  deleteOwnUser,
  deleteUserGroups,
  deleteUserPlaylists,
  downloadInvitations,
  downloadOrganizationGroups,
  downloadOrganizationMembers,
  getOrganizationMembersQuery,
  handleHistory,
  hasSubscription,
  isBackOffice,
  isManager,
  isOrganizationAdmin,
  listGroupMembers,
  listGroups,
  listInvitations,
  listView,
  locale,
  manageGroups,
  manageRoles,
  onClickSubscribe,
  onSubmitGroupMembers,
  onViewGroupActivity,
  organizationId,
  preSelectedUserIdToManageRole,
  removeFromGroup,
  removeFromOrganization,
  removeInvitations,
  requestToDeleteGroup,
  resendInvitations,
  sendInvitationsQuery,
  totalLicences,
  transferGroup,
  transferUserGroups,
  transferUserPlaylists,
  updateGroup,
  user,
  ...props
}) {
  // Global states
  const [availableLicences, setAvailableLicences] = useState([]);
  const [formStatus, setFormStatus] = useState();
  const [manageGroupsOpen, setManageGroupsOpen] = useState(manageGroups);
  const [registeredMembers, setRegisteredMembers] = useState([]);
  const [selectedAction, setSelectedAction] = useState('');

  const {
    createGroupFormStatus,
    deleteGroup,
    deleteGroupMember,
    getGroupsInfo,
    groupMembers,
    groupsInfo,
    handleOnSubmitGroupMembers,
    loadingGroupMembers,
    loadingGroupsInfo,
    onCreateGroup,
    onDeleteGroup,
    onDeleteGroupConfirm,
    onDeleteGroupMember,
    onDeleteGroupMemberConfirm,
    onSaveEditGroup,
    onTransferGroup,
    requestToDeleteUserGroups,
    requestToTransferUserGroups,
    selectedGroupId,
    setCreateGroupFormStatus,
    setDeleteGroup,
    setDeleteGroupMember,
    setMemberToDeleteFromGroup,
    setSelectedGroupId,
  } = useGroups({
    createGroup,
    deleteUserGroups,
    listGroupMembers,
    listGroups,
    onSubmitGroupMembers,
    organizationId,
    registeredMembers,
    removeFromGroup,
    requestToDeleteGroup,
    setFormStatus,
    setRegisteredMembers,
    transferGroup,
    transferUserGroups,
    updateGroup,
  });

  const {
    failureList,
    invitedMembers,
    inviteMembersOpen,
    loadingInvitedMembers,
    onCloseInviteMembers,
    setFailureList,
    sendInvitationsHandler,
    setInvitedMembers,
    setInviteMembersOpen,
    setShowMultipleFeedback,
    setSuccessList,
    showMultipleFeedback,
    successList,
  } = useInvitations({
    isBackOffice,
    isManager,
    listInvitations,
    organizationId,
    sendInvitationsQuery,
    setFormStatus,
  });

  const { getOrganizationManagers, loadingRegisteredMembers } =
    useOrganizations({
      getOrganizationMembersQuery,
      organizationId,
      registeredMembers,
      setRegisteredMembers,
    });

  const { requestToDeleteUserPlaylists, requestToTransferUserPlaylists } =
    usePlaylists({
      deleteUserPlaylists,
      organizationId,
      registeredMembers,
      setRegisteredMembers,
      transferUserPlaylists,
    });

  const {
    administrateManagersRoleOptions,
    administrateUserRole,
    administrateUserRoleTo,
    administrateUserRoleOpen,
    changeRoleFormStatus,
    onSubmitAdministrateUserRoleHandler,
    setAdministrateUserRoleOpen,
    setChangeRoleFormStatus,
  } = useUserRoles({
    changeUserRole,
    handleHistory,
    isOrganizationAdmin,
    manageRoles,
    organizationId,
    registeredMembers,
    requestToDeleteUserGroups,
    requestToDeleteUserPlaylists,
    requestToTransferUserGroups,
    requestToTransferUserPlaylists,
    setRegisteredMembers,
  });

  const {
    deleteUser,
    isUserInOrganization,
    manageContentOwnership,
    onDeleteUser,
    onDeleteUserConfirm,
    onSubmitManageContentOwnership,
    setDeleteUser,
    setManageContentOwnership,
    setUserToDelete,
    transferContentChoiceOptions,
    transferContentOwnershipFor,
  } = useUsers({
    invitedMembers,
    organizationId,
    registeredMembers,
    requestToDeleteUserGroups,
    requestToDeleteUserPlaylists,
    requestToTransferUserGroups,
    requestToTransferUserPlaylists,
    removeFromOrganization,
    setFormStatus,
    setRegisteredMembers,
  });

  const onCloseDelete = () => {
    // Setting all delete dialog states to allow having a single method
    setDeleteUser(false);
    setDeleteGroup(false);
    setDeleteGroupMember(false);
    setManageContentOwnership(false);
    setUserToDelete(null);
    setMemberToDeleteFromGroup(null);
    setFormStatus(null);
  };

  const downloadInvitationsHandler = () =>
    downloadInvitations(organizationId)();
  const downloadOrganizationGroupsHandler = () =>
    downloadOrganizationGroups(organizationId)();
  const downloadOrganizationMembersHandler = () =>
    downloadOrganizationMembers(organizationId)();
  const onViewGroupActivityHandler = (groupId) => {
    onViewGroupActivity(organizationId, groupId)();
  };
  const removeInvitationsHandler = (input) =>
    removeInvitations(organizationId)(input);
  const resendInvitationsHandler = (input) =>
    resendInvitations(organizationId)(input);

  useEffect(() => {
    const currentMembers = registeredMembers.length + invitedMembers.length;
    setAvailableLicences(Math.max(0, totalLicences - currentMembers));
  }, [invitedMembers, registeredMembers, totalLicences]);

  const contextValue = {
    // shared variables
    availableLicences,
    deleteOwnUser,
    downloadInvitations: downloadInvitationsHandler,
    downloadOrganizationGroups: downloadOrganizationGroupsHandler,
    downloadOrganizationMembers: downloadOrganizationMembersHandler,
    formStatus,
    handleHistory,
    hasSubscription,
    isBackOffice,
    isManager,
    listGroupMembers,
    listView,
    locale,
    manageGroupsOpen,
    onCloseDelete,
    onClickSubscribe,
    onViewGroupActivity: onViewGroupActivityHandler,
    preSelectedUserIdToManageRole,
    registeredMembers,
    removeFromGroup,
    removeInvitations: removeInvitationsHandler,
    resendInvitations: resendInvitationsHandler,
    selectedAction,
    setManageGroupsOpen,
    setSelectedAction,
    totalLicences,
    user,
    // context uses
    groups: {
      createGroupFormStatus,
      deleteGroup,
      deleteGroupMember,
      handleOnSubmitGroupMembers,
      getGroupsInfo,
      groupMembers,
      groupsInfo,
      loadingGroupMembers,
      loadingGroupsInfo,
      onCreateGroup,
      onDeleteGroup,
      onDeleteGroupConfirm,
      onDeleteGroupMember,
      onDeleteGroupMemberConfirm,
      onSaveEditGroup,
      onTransferGroup,
      selectedGroupId,
      setCreateGroupFormStatus,
      setSelectedGroupId,
    },
    invitations: {
      failureList,
      invitedMembers,
      inviteMembersOpen,
      loadingInvitedMembers,
      onCloseInviteMembers,
      sendInvitationsHandler,
      setFailureList,
      setInvitedMembers,
      setInviteMembersOpen,
      setShowMultipleFeedback,
      setSuccessList,
      showMultipleFeedback,
      successList,
    },
    organizations: { getOrganizationManagers, loadingRegisteredMembers },
    userRoles: {
      administrateManagersRoleOptions,
      administrateUserRole,
      administrateUserRoleOpen,
      administrateUserRoleTo,
      changeRoleFormStatus,
      onSubmitAdministrateUserRoleHandler,
      setAdministrateUserRoleOpen,
      setChangeRoleFormStatus,
    },
    users: {
      deleteUser,
      isUserInOrganization,
      manageContentOwnership,
      onDeleteUser,
      onDeleteUserConfirm,
      onSubmitManageContentOwnership,
      transferContentChoiceOptions,
      transferContentOwnershipFor,
    },
  };

  return (
    <OrganizationMembersContext.Provider value={contextValue} {...props} />
  );
}

OrganizationMembersProvider.propTypes = {
  createGroup: PropTypes.func.isRequired,
  deleteOwnUser: PropTypes.bool,
  downloadInvitations: PropTypes.func.isRequired,
  downloadOrganizationGroups: PropTypes.func.isRequired,
  downloadOrganizationMembers: PropTypes.func.isRequired,
  getOrganizationMembersQuery: PropTypes.func.isRequired,
  handleHistory: PropTypes.func,
  hasSubscription: PropTypes.bool.isRequired,
  isBackOffice: PropTypes.bool,
  isManager: PropTypes.bool,
  isOrganizationAdmin: PropTypes.bool,
  listGroupMembers: PropTypes.func,
  listGroups: PropTypes.func.isRequired,
  listInvitations: PropTypes.func.isRequired,
  listView: PropTypes.string,
  locale: PropTypes.string,
  manageGroups: PropTypes.bool,
  onClickSubscribe: PropTypes.func,
  onSubmitGroupMembers: PropTypes.func.isRequired,
  onViewGroupActivity: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  removeFromGroup: PropTypes.func.isRequired,
  removeFromOrganization: PropTypes.func.isRequired,
  removeInvitations: PropTypes.func.isRequired,
  requestToDeleteGroup: PropTypes.func.isRequired,
  resendInvitations: PropTypes.func.isRequired,
  sendInvitationsQuery: PropTypes.func.isRequired,
  totalLicences: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  transferGroup: PropTypes.func.isRequired,
  updateGroup: PropTypes.func.isRequired,
  user: PropTypes.object,
  changeUserRole: PropTypes.func.isRequired,
  transferUserGroups: PropTypes.func.isRequired,
  transferUserPlaylists: PropTypes.func.isRequired,
  deleteUserGroups: PropTypes.func.isRequired,
  deleteUserPlaylists: PropTypes.func.isRequired,
  manageRoles: PropTypes.bool,
  preSelectedUserIdToManageRole: PropTypes.string,
};

OrganizationMembersProvider.defaultProps = {
  handleHistory: null,
  isManager: false,
  isOrganizationAdmin: false,
  listView: 'members',
  manageGroups: false,
  manageRoles: false,
};

function useOrganizationMembers() {
  const context = React.useContext(OrganizationMembersContext);
  if (!context) {
    throw new Error(
      'useOrganizationMembers must be used within a OrganizationMembersProvider'
    );
  }

  return context;
}

export { OrganizationMembersProvider, useOrganizationMembers };

import React from 'react';

import PageTemplate from '../components/templates/PageTemplate';
import OrganizationsView from '../components/organisms/OrganizationsView';

const OrganizationsContainer = () => {
  return (
    <PageTemplate>
      <OrganizationsView />
    </PageTemplate>
  );
};

export default OrganizationsContainer;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Dialog from 'shared/molecules/Dialog';
import FullWidthTabs from 'shared/atoms/FullWidthTabs';
import T from 'shared/atoms/Typography';
import ListItems from 'shared/molecules/ListItems';
import theme from 'shared/themes/default';
import { registeredMembersRoles } from 'shared/const';

const SideSpaceWrapper = styled.div`
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
`;
const MultipleFeedback = ({
  onClose,
  failureList,
  successList,
  closeText,
  actionSuccessText,
  noSuccessText,
  actionFailureText,
  noFailureText,
  failedText,
  succeededText,
  headerText,
  notExistingInvitationText,
  recentlyAddedText,
}) => {
  const { t } = useTranslation();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [parsedFailureList, setParsedFailureList] = useState([]);
  const [parsedSuccessList, setParsedSuccessList] = useState([]);

  const memberParse = (member, failure) => {
    const roleParser = (role) => {
      if (role === registeredMembersRoles.admin) {
        return t('administrateUsers.administrator');
      }
      if (role === registeredMembersRoles.manager) {
        return t('administrateUsers.manager');
      }
      if (role === registeredMembersRoles.member) {
        return t('administrateUsers.member');
      }
    };

    const { email: subtitle, name: title, reason, role } = member;

    let newMember = {
      key: subtitle,
      label: roleParser(role),
      subtitle,
      title,
    };

    if (failure) {
      newMember = {
        ...newMember,
        error: true,
        errorTooltip:
          reason === 'RECENT' ? recentlyAddedText : notExistingInvitationText,
      };
    }

    return newMember;
  };

  useEffect(() => {
    setParsedFailureList(
      failureList.map((member) => memberParse(member, true))
    );
  }, [failureList]);

  useEffect(() => {
    setParsedSuccessList(successList.map((member) => memberParse(member)));
  }, [successList]);

  useEffect(() => {
    if (failureList.length !== 0 && successList.length === 0) {
      setSelectedTabIndex(1);
    }
  }, [failureList, successList]);
  const [errorMessage, setErrorMessage] = useState(null);

  const onDialogSubmit = () => {
    onClose();
  };

  const onChangeTabHandler = () => {
    setSelectedTabIndex(selectedTabIndex === 0 ? 1 : 0);
  };

  const actions = [
    {
      ariaLabel: closeText,
      handler: onDialogSubmit,
      label: closeText,
    },
  ];

  const successTab = () => {
    if (successList && successList.length > 0) {
      return (
        <>
          <SideSpaceWrapper>
            <T color={theme.palette.darkDistinct}>
              {actionSuccessText(successList.length)}
            </T>
          </SideSpaceWrapper>
          <ListItems items={parsedSuccessList} itemsPerPage={8} showLabel />
        </>
      );
    }
    return (
      <SideSpaceWrapper>
        <T color={theme.palette.darkDistinct}>{noSuccessText}</T>
      </SideSpaceWrapper>
    );
  };
  const failureTab = () => {
    if (failureList && failureList.length > 0) {
      return (
        <>
          <SideSpaceWrapper>
            <T color={theme.palette.darkDistinct}>
              {actionFailureText(failureList.length)}
            </T>
          </SideSpaceWrapper>
          <ListItems items={parsedFailureList} itemsPerPage={8} />
        </>
      );
    }
    return (
      <SideSpaceWrapper>
        <T color={theme.palette.darkDistinct}>{noFailureText}</T>
      </SideSpaceWrapper>
    );
  };

  const tabsToSelect = [
    {
      label: `${succeededText} (${successList.length})`,
      component: successTab(),
    },
    { label: `${failedText} (${failureList.length})`, component: failureTab() },
  ];

  return (
    <Dialog
      open
      actions={actions}
      contentPadding="0"
      onClose={onClose}
      ariaLabel={headerText}
      title={headerText}
      scroll="body"
      errorMessage={errorMessage}
      onCloseNotificationMessage={() => {
        setErrorMessage(null);
      }}
    >
      <FullWidthTabs
        tabsToSelect={tabsToSelect}
        onChangeTabHandler={onChangeTabHandler}
        selectedTabIndex={selectedTabIndex}
      />
    </Dialog>
  );
};

MultipleFeedback.propTypes = {
  closeText: PropTypes.string.isRequired,
  actionSuccessText: PropTypes.func.isRequired,
  noSuccessText: PropTypes.string.isRequired,
  actionFailureText: PropTypes.func.isRequired,
  noFailureText: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  successList: PropTypes.array.isRequired,
  failureList: PropTypes.array.isRequired,
  succeededText: PropTypes.string.isRequired,
  failedText: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  notExistingInvitationText: PropTypes.string.isRequired,
  recentlyAddedText: PropTypes.string.isRequired,
};

export default MultipleFeedback;

import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import CloseIcon from '@mui/icons-material/Close';

import IconButton from 'shared/atoms/IconButton';

const InnerTooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextContainer = styled.div`
  width: 90%;
  padding-top: 8px;
  font-weight: 400;
  font-size: 0.9rem;
`;

const TitleComponent = ({ title, onClose }) => {
  return (
    <InnerTooltipWrapper>
      <IconButton
        ariaLabel="close"
        icon={CloseIcon}
        onClick={onClose}
        sx={{ marginLeft: 'auto' }}
        theme="light"
        type="transparent"
      />
      <TextContainer>{title}</TextContainer>
    </InnerTooltipWrapper>
  );
};

TitleComponent.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClose: PropTypes.func.isRequired,
};

export default TitleComponent;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import AddIcon from '@mui/icons-material/Add';

import Button from '@shared/atoms/Button';
import SearchField from '@shared/atoms/SearchField';
import Snackbar from '@shared/atoms/Snackbar';
import T from '@shared/atoms/Typography';
import Dialog from '@shared/molecules/Dialog';

import theme from '@shared/themes/default';
import { selectTranslation, logger } from '@shared/utils';

import { useAppGlobals } from '../../contexts/AppContext';

import CategoriesList from './components/CategoriesList';
import CategoryDrawer from './components/CategoryDrawer';
import {
  createCategory,
  deleteCategory,
  updateCategory,
} from './graphql/mutations';
import { listCategories } from './graphql/queries';
import { useGraphQLQuery } from '../../../utils';

const SearchWrapper = styled.div`
  column-gap: ${theme.spacing.s};
  display: flex;
`;

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m1};
  max-width: 650px;
`;

const CategoriesView = () => {
  const [categories, setCategories] = useState([]);
  const [categoriesFiltered, setCategoriesFiltered] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [toDeleteCategory, setToDeleteCategory] = useState();
  const [deleteCategoryDialog, setDeleteCategoryDialog] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [displayError, setDisplayError] = useState('');

  const { domain, locale } = useAppGlobals();
  const { t } = useTranslation();

  const { callQuery, data, errors, hasEnded, hasErrors, loading } =
    useGraphQLQuery();

  const {
    callQuery: callCreateQuery,
    data: createData,
    hasEnded: hasEndedCreate,
    hasErrors: hasErrorsCreate,
  } = useGraphQLQuery();

  const {
    callQuery: callDeleteQuery,
    errors: errorsDelete,
    loading: loadingDelete,
    hasEnded: hasEndedDelete,
    hasErrors: hasErrorsDelete,
  } = useGraphQLQuery();

  const {
    callQuery: callEditQuery,
    data: editData,
    hasEnded: hasEndedEdit,
    hasErrors: hasErrorsEdit,
  } = useGraphQLQuery();

  const handleCreateCategory = () => {
    setSelectedCategory({});
  };

  const handleDeleteCategoryDialog = (id) => {
    setToDeleteCategory(categories.find((category) => category.id === id));
    setDeleteCategoryDialog(true);
  };

  const handleDeleteCategory = () => {
    callDeleteQuery(deleteCategory(toDeleteCategory.id));
    setDeleteCategoryDialog(false);
  };

  const handleCloseDrawer = () => {
    setSelectedCategory();
  };

  const loadCategories = async (nextToken) => {
    callQuery(listCategories(domain, nextToken));
  };

  useEffect(() => {
    if (searchValue) {
      setCategoriesFiltered(
        categories.filter((category) =>
          selectTranslation(category.titleTranslations, null, locale, '')
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        )
      );
    } else {
      setCategoriesFiltered(categories);
    }
  }, [categories, searchValue]);

  useEffect(() => {
    if (hasEnded) {
      if (hasErrors) {
        setDisplayError(t('backoffice.categories.errorFetching'));
        logger.error('Error: ', errors);
      } else {
        const {
          listCategories: { items, nextToken },
        } = data;
        setCategories(
          [...categories, ...items].sort((a, b) => {
            if (a.ordinal < b.ordinal) return -1;
            if (a.ordinal > b.ordinal) return 1;
            return 0;
          })
        );
        if (nextToken) {
          loadCategories(nextToken);
        }
      }
    }
  }, [hasEnded]);

  useEffect(() => {
    if (hasEndedCreate) {
      if (hasErrorsCreate) {
        logger.error('Error: ', errors);
      } else {
        const { createCategory } = createData;

        setCategories(
          [...categories, { ...createCategory, courses: { items: [] } }].sort(
            (a, b) => {
              if (a.ordinal < b.ordinal) return -1;
              if (a.ordinal > b.ordinal) return 1;
              return 0;
            }
          )
        );
        handleCloseDrawer();
      }
    }
  }, [hasEndedCreate]);

  useEffect(() => {
    if (hasEndedDelete) {
      if (hasErrorsDelete) {
        setDisplayError(t('backoffice.categories.errorDeleting'));
        logger.error('Error: ', errorsDelete);
      } else {
        setCategories(
          categories.filter((category) => category.id !== toDeleteCategory.id)
        );
      }
    }
  }, [hasEndedDelete]);

  useEffect(() => {
    if (hasEndedEdit) {
      if (hasErrorsEdit) {
        logger.error('Error: ', errors);
      } else {
        const { updateCategory } = editData;

        setCategories(
          categories
            .map((category) =>
              category.id === updateCategory.id
                ? { ...updateCategory, courses: category.courses }
                : category
            )
            .sort((a, b) => {
              if (a.ordinal < b.ordinal) return -1;
              if (a.ordinal > b.ordinal) return 1;
              return 0;
            })
        );
        handleCloseDrawer();
      }
    }
  }, [hasEndedEdit]);

  useEffect(() => {
    loadCategories();
  }, []);

  useEffect(() => {
    setDrawerOpen(!!selectedCategory);
  }, [selectedCategory]);

  const isLocked = loading || loadingDelete;

  return (
    <OuterWrapper>
      <Snackbar
        type="error"
        message={displayError}
        open={!!displayError}
        onClose={() => setDisplayError('')}
      />
      {deleteCategoryDialog && (
        <Dialog
          actions={[
            {
              ariaLabel: t('generic.cancel'),
              handler: () => {
                setDeleteCategoryDialog(false);
              },
              label: t('generic.cancel'),
              type: 'secondaryOutline',
            },
            {
              ariaLabel: t('backoffice.categories.delete'),
              handler: handleDeleteCategory,
              label: t('backoffice.categories.delete'),
            },
          ]}
          ariaLabel={t('backoffice.categories.deleteTitle')}
          onClose={() => setDeleteCategoryDialog(false)}
          open={deleteCategoryDialog}
          sx={{ '.MuiDialogContent-root': { overflowY: 'unset' } }}
          title={t('backoffice.categories.deleteTitle')}
        >
          <T
            color={theme.palette.mainDark}
            fontWeight="semibold"
            sx={{ textAlign: 'center' }}
            variant="subtitle"
          >
            {t('backoffice.categories.deleteText', {
              name: selectTranslation(
                toDeleteCategory.titleTranslations,
                null,
                locale,
                ''
              ),
            })}
          </T>
        </Dialog>
      )}
      <SearchWrapper>
        <SearchField
          ariaLabel={t('backoffice.categories.search')}
          disabled={isLocked}
          loading={isLocked}
          onChange={({ value }) => setSearchValue(value)}
          onClear={() => setSearchValue('')}
          placeholder={t('backoffice.categories.search')}
          theme="dark"
          type="primary"
          value={searchValue}
        />
        <Button
          ariaLabel={t('backoffice.categories.new')}
          disabled={loadingDelete}
          iconRight={AddIcon}
          onClick={handleCreateCategory}
          sx={{ flex: '0 0 auto' }}
        >
          {t('backoffice.categories.new')}
        </Button>
      </SearchWrapper>
      <CategoriesList
        categories={categoriesFiltered}
        filter={searchValue}
        handleDeleteCategory={handleDeleteCategoryDialog}
        isLocked={isLocked}
        loading={loading}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
      <CategoryDrawer
        category={selectedCategory}
        handleClose={handleCloseDrawer}
        handleCreate={(options) => callCreateQuery(createCategory(options))}
        handleCreateEnded={hasEndedCreate}
        handleUpdate={(options) => callEditQuery(updateCategory(options))}
        handleUpdateEnded={hasEndedEdit}
        open={drawerOpen}
        showError={hasErrorsCreate || hasErrorsEdit}
      />
    </OuterWrapper>
  );
};

export default CategoriesView;

import React, { useEffect, useState, useRef } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import AddIcon from '@mui/icons-material/Add';

import Portal from '@mui/material/Portal';
import useMediaQuery from '@mui/material/useMediaQuery';

import Button from 'shared/atoms/Button';
import CircularSpinner from 'shared/atoms/CircularSpinner';
import Snackbar from 'shared/atoms/Snackbar';
import T from 'shared/atoms/Typography';
import Dialog from 'shared/molecules/Dialog';
import Menu from 'shared/molecules/Menu';

import theme from 'shared/themes/default';
import { PLAYLIST_TYPE } from 'shared/const';

import PlaylistContentItemList from './PlaylistContentItemList';
import PlaylistTitleBoxList from './PlaylistTitleBoxList';

import Dialogs from './Dialogs';
import { usePlaylist } from '../context';

const HeadingCounterWrapper = styled.div`
  display: flex;
  column-gap: ${theme.spacing.m2};
`;

const HeadingCounter = styled.div`
  display: flex;
  column-gap: ${theme.spacing.s};
`;

const StyledOuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTitleBlock = styled.div`
  margin-top: 24px;
`;

const StyledPlaylistWrapper = styled.div`
  display: flex;
`;

const StyledPlaylist = styled.div`
  flex: 50%;
  max-width: 50%;
  flex-grow: 0;
  padding-right: 1.375rem;
  ${theme.breakpoints.mobile} {
    flex: 100%;
    max-width: 100%;
    padding-right: 0;
  }
`;

const StyledPlaylistTitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
  min-height: 40px;
  ${theme.breakpoints.tablet} {
    flex-direction: column;
    align-items: flex-start;
    min-height: 90px;
  }
  ${theme.breakpoints.mobile} {
    min-height: 72px;
  }
`;

const StyledPlaylistContentTitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 40px;
`;

const StyledCourses = styled.div`
  flex: 50%;
  max-width: 50%;
  padding-left: 1.375rem;
  text-align: center;
`;

const PlaylistOverview = ({ isBackOffice }) => {
  const {
    hasSubscription,
    isSubscriptionAdmin,
    locale,
    managerSelected,
    organization,
    playlistId,
    typeSelected,
    userId,
    dialogs: {
      openNanodegreeForm,
      openPlaylistForm,
      reminderStatus,
      errorDetailPublish,
      errorMessagePublish,
      scrollToSelected,
      successPublish,
      setErrorMessagePublish,
      setOpenCopyPlaylistForm,
      setOpenNanodegreeForm,
      setOpenPlaylistForm,
      setSuccessPublish,
      setScrollToSelected,
      openAddContentHandler,
      deleteFromPlaylist,
      onPlaylistContentDialogClose,
      setReminderStatus,
    },
    playlists: {
      canAddCourses,
      canSortCourses,
      isNanodegree,
      listOwnedPlaylists,
      loadingContent,
      loadingPlaylists,
      playlistContent,
      playlistContentOrderHandler,
      playlistName,
      selectedPlaylist,
      selectPlaylistHandler,
    },
  } = usePlaylist();
  const [anchorEl, setAnchorEl] = useState(null);

  const selectedRef = useRef(null);

  const { id: organizationId } = organization;
  const isGlobalOrganization = organizationId === 'global';

  const { t } = useTranslation();

  const createNewMenuItems = [
    { label: t('playlist.playlist'), handler: () => setOpenPlaylistForm(true) },
    {
      label: t('nanodegree.nanodegree'),
      handler: () => setOpenNanodegreeForm(true),
    },
  ];

  const createPlaylistMenuItems = [
    {
      label: t('playlist.createNewPlaylist'),
      handler: () => setOpenPlaylistForm(true),
    },
  ];

  if (!organization.lockVcCourses && !organization.hideVcCourses) {
    createPlaylistMenuItems.push({
      label: t('playlist.addExistingPlaylist'),
      handler: () => setOpenCopyPlaylistForm(true),
    });
  }

  const courseMenuItems = [
    {
      label: t(
        isNanodegree
          ? 'nanodegree.removeFromNanodegree'
          : 'playlist.removeFromPlaylist'
      ),
      handler: deleteFromPlaylist,
      disabled: !hasSubscription,
    },
  ];

  const CustomMenuHandler = (event) => {
    setAnchorEl(anchorEl === null ? event.currentTarget : null);
  };

  useEffect(() => {
    if (playlistId && !loadingPlaylists) {
      selectPlaylistHandler(playlistId);
    }
  }, [loadingPlaylists]);

  // We scroll here to make sure we scroll after the modal is closed
  useEffect(() => {
    if (scrollToSelected) {
      selectPlaylistHandler(scrollToSelected);
      selectedRef?.current?.scrollIntoView({ behavior: 'smooth' });
      setScrollToSelected(null);
    }
  }, [openNanodegreeForm, openPlaylistForm]);

  const isMobile = useMediaQuery(theme.breakpoints.mobile);
  const canSortPlaylists =
    isSubscriptionAdmin &&
    managerSelected === 'all' &&
    hasSubscription &&
    (!isGlobalOrganization || typeSelected in PLAYLIST_TYPE);
  const canManagePlaylists = !userId || managerSelected === userId;

  let buttonLabel = isGlobalOrganization
    ? t('playlist.createNew')
    : t('playlist.createPlaylist');
  let buttonAction = CustomMenuHandler;
  let showMenu = true;

  if (typeSelected && isGlobalOrganization) {
    if (typeSelected === PLAYLIST_TYPE.PLAYLIST) {
      buttonLabel = t('playlist.createPlaylist');
      buttonAction = () => setOpenPlaylistForm(true);
      showMenu = false;
    } else if (typeSelected === PLAYLIST_TYPE.NANO_DEGREE) {
      buttonLabel = t('playlist.createNanodegree');
      buttonAction = () => setOpenNanodegreeForm(true);
      showMenu = false;
    }
  }

  const noOfNanodegrees = listOwnedPlaylists
    ? listOwnedPlaylists.filter(
        (playlist) => playlist.type === PLAYLIST_TYPE.NANO_DEGREE
      ).length
    : 0;
  const noOfPlaylists = listOwnedPlaylists
    ? listOwnedPlaylists.length - noOfNanodegrees
    : 0;

  return (
    <StyledOuterWrapper>
      {/* Page title block */}
      <StyledTitleBlock>
        {listOwnedPlaylists &&
          listOwnedPlaylists.length === 0 &&
          canManagePlaylists &&
          (isGlobalOrganization || managerSelected !== 'all') && (
            <>
              <T
                color={theme.palette.lightPure}
                fontWeight="medium"
                variant="subtitle"
              >
                {t('playlist.newPlaylist')}
              </T>
              <T
                color={theme.palette.lightPure}
                fontWeight="medium"
                sx={{ mt: theme.spacing.m1 }}
                variant="paragraph"
              >
                {t('playlist.sharePlaylist')}
              </T>
              <Button
                ariaLabel={buttonLabel}
                disabled={!hasSubscription}
                iconRight={AddIcon}
                onClick={buttonAction}
                sx={{
                  width: '100%',
                  maxWidth: '300px',
                  margin: '10px 0',
                  justifyContent: 'space-between',
                }}
                theme="dark"
                type="secondary"
              >
                {buttonLabel}
              </Button>
              {showMenu && (
                <Menu
                  freeMenu
                  initAnchorEl={anchorEl}
                  menuItems={
                    isGlobalOrganization
                      ? createNewMenuItems
                      : createPlaylistMenuItems
                  }
                  setInitAnchorEl={setAnchorEl}
                />
              )}
            </>
          )}
      </StyledTitleBlock>

      {/* Playlists and content block */}
      {listOwnedPlaylists && listOwnedPlaylists.length > 0 && (
        <StyledPlaylistWrapper>
          {/* Playlists list block */}
          <StyledPlaylist>
            <StyledPlaylistTitleBlock>
              <HeadingCounterWrapper>
                {isBackOffice &&
                  (!typeSelected ||
                    typeSelected === 'ALL' ||
                    typeSelected === PLAYLIST_TYPE.NANO_DEGREE) && (
                    <HeadingCounter>
                      <T variant="helperText" color={theme.palette.lightPure}>
                        {`${t('playlist.noOfNanodegrees')}:`}
                      </T>
                      <T
                        variant="helperText"
                        color={theme.palette.lightPure}
                        fontWeight="bold"
                      >
                        {noOfNanodegrees}
                      </T>
                    </HeadingCounter>
                  )}
                {(!typeSelected ||
                  typeSelected === 'ALL' ||
                  typeSelected === PLAYLIST_TYPE.PLAYLIST) && (
                  <HeadingCounter>
                    <T variant="helperText" color={theme.palette.lightPure}>
                      {`${t('playlist.noOfPlaylists')}:`}
                    </T>
                    <T
                      variant="helperText"
                      color={theme.palette.lightPure}
                      fontWeight="bold"
                    >
                      {noOfPlaylists}
                    </T>
                  </HeadingCounter>
                )}
              </HeadingCounterWrapper>
              {canManagePlaylists &&
                (isGlobalOrganization || managerSelected !== 'all') && (
                  <Button
                    ariaLabel={buttonLabel}
                    disabled={!hasSubscription}
                    iconRight={AddIcon}
                    onClick={buttonAction}
                    sx={{
                      [theme.breakpoints.tablet]: {
                        width: '100%',
                        maxWidth: '300px',
                        justifyContent: 'space-between',
                      },
                    }}
                    theme="dark"
                    type="secondaryOutline"
                  >
                    {buttonLabel}
                  </Button>
                )}
            </StyledPlaylistTitleBlock>
            {showMenu && (
              <Menu
                freeMenu
                initAnchorEl={anchorEl}
                menuItems={
                  isGlobalOrganization
                    ? createNewMenuItems
                    : createPlaylistMenuItems
                }
                setInitAnchorEl={setAnchorEl}
              />
            )}
            <PlaylistTitleBoxList
              canManagePlaylists={canManagePlaylists}
              isSortable={canSortPlaylists}
              selectedRef={selectedRef}
            />
          </StyledPlaylist>
          {/* Playlists content block */}
          {selectedPlaylist &&
            (isMobile ? (
              <Dialog
                ariaLabel={t('playlist.courses')}
                title={
                  <>
                    <T
                      color={theme.palette.mainDark}
                      fontWeight="medium"
                      variant="paragraph"
                    >
                      {t('playlist.courses')}
                    </T>
                    <T
                      color={theme.palette.mainDark}
                      fontWeight="medium"
                      variant="subtitle"
                    >
                      {playlistName}
                    </T>
                  </>
                }
                onClose={onPlaylistContentDialogClose}
              >
                {canAddCourses &&
                  playlistContent.length > 0 &&
                  canManagePlaylists && (
                    <Button
                      ariaLabel={t('playlist.addCourses')}
                      disabled={!hasSubscription}
                      fullWidth
                      iconRight={AddIcon}
                      onClick={openAddContentHandler}
                      sx={{
                        justifyContent: 'space-between',
                        marginBottom: theme.spacing.m1,
                      }}
                      theme="light"
                      type="secondaryOutline"
                    >
                      {t('playlist.addCourses')}
                    </Button>
                  )}
                {loadingContent && <CircularSpinner static />}
                {playlistContent.length > 0 && !loadingContent ? (
                  <PlaylistContentItemList
                    locale={locale}
                    playlistContent={playlistContent}
                    menuItems={
                      canManagePlaylists && canAddCourses ? courseMenuItems : []
                    }
                    playlistContentOrderHandler={playlistContentOrderHandler}
                    isSortable={
                      canManagePlaylists && canSortCourses && hasSubscription
                    }
                  />
                ) : (
                  !loadingContent && (
                    <T
                      color={theme.palette.mainDark}
                      fontWeight="medium"
                      variant="paragraph"
                    >
                      {isNanodegree
                        ? t('playlist.currentlyHaveNoNanodegreeCourses')
                        : t('playlist.currentlyHaveNoCourses')}
                    </T>
                  )
                )}
                {canAddCourses &&
                  playlistContent.length === 0 &&
                  !loadingContent && (
                    <Button
                      ariaLabel={t('playlist.addCourses')}
                      disabled={!hasSubscription}
                      fullWidth
                      iconRight={AddIcon}
                      onClick={openAddContentHandler}
                      sx={{
                        justifyContent: 'space-between',
                        marginBottom: theme.spacing.m1,
                        marginTop: theme.spacing.m1,
                      }}
                      theme="light"
                      type="primary"
                    >
                      {t('playlist.addCourses')}
                    </Button>
                  )}
              </Dialog>
            ) : (
              <StyledCourses>
                <StyledPlaylistTitleBlock>
                  <StyledPlaylistContentTitleBlock>
                    <T
                      color={theme.palette.lightPure}
                      fontWeight="medium"
                      variant="helperText"
                    >
                      {t('playlist.courses')}
                    </T>
                    <T
                      color={theme.palette.lightPure}
                      fontWeight="medium"
                      variant="paragraph"
                    >
                      {playlistName}
                    </T>
                  </StyledPlaylistContentTitleBlock>
                  {canAddCourses &&
                    playlistContent.length > 0 &&
                    canManagePlaylists && (
                      <Button
                        ariaLabel={t('playlist.addCourses')}
                        disabled={!hasSubscription}
                        iconRight={AddIcon}
                        onClick={openAddContentHandler}
                        sx={{
                          [theme.breakpoints.tablet]: {
                            width: '100%',
                            maxWidth: '300px',
                            justifyContent: 'space-between',
                          },
                        }}
                        theme="dark"
                        type="secondaryOutline"
                      >
                        {t('playlist.addCourses')}
                      </Button>
                    )}
                </StyledPlaylistTitleBlock>
                {loadingContent && <CircularSpinner static />}
                {!loadingContent && playlistContent.length > 0 ? (
                  <PlaylistContentItemList
                    locale={locale}
                    playlistContent={playlistContent}
                    menuItems={
                      canManagePlaylists && canAddCourses ? courseMenuItems : []
                    }
                    playlistContentOrderHandler={playlistContentOrderHandler}
                    isSortable={
                      canManagePlaylists && canSortCourses && hasSubscription
                    }
                  />
                ) : (
                  !loadingContent && (
                    <T
                      align="center"
                      color={theme.palette.disabledLight}
                      fontWeight="medium"
                      variant="paragraph"
                    >
                      {isNanodegree
                        ? t('playlist.currentlyHaveNoNanodegreeCourses')
                        : t('playlist.currentlyHaveNoCourses')}
                    </T>
                  )
                )}
                {canAddCourses &&
                  !loadingContent &&
                  playlistContent.length === 0 && (
                    <Button
                      ariaLabel={t('playlist.addCourses')}
                      disabled={!hasSubscription}
                      iconRight={AddIcon}
                      onClick={openAddContentHandler}
                      sx={{
                        width: '100%',
                        margin: '10px 0',
                        maxWidth: '300px',
                        justifyContent: 'space-between',
                      }}
                      theme="dark"
                      type="secondary"
                    >
                      {t('playlist.addCourses')}
                    </Button>
                  )}
              </StyledCourses>
            ))}
        </StyledPlaylistWrapper>
      )}

      {/* Dialogs component */}

      <Dialogs />

      {/* Success message */}

      {successPublish && (
        <Portal>
          <Snackbar
            message={
              isNanodegree
                ? t('nanodegree.publishSuccess')
                : t('playlist.publishSuccess')
            }
            onClose={() => setSuccessPublish(false)}
            open={successPublish}
            type="success"
          />
        </Portal>
      )}

      {/* Error message */}

      {errorMessagePublish && (
        <Portal>
          <Snackbar
            detailedErrors={errorDetailPublish}
            message={errorMessagePublish}
            onClose={() => setErrorMessagePublish(false)}
            open={!!errorMessagePublish}
            type="error"
          />
        </Portal>
      )}

      {reminderStatus.open && (
        <Portal>
          <Snackbar
            message={reminderStatus.message}
            onClose={() =>
              setReminderStatus({ ...reminderStatus, open: false })
            }
            open={reminderStatus.open}
            type={reminderStatus.type}
          />
        </Portal>
      )}
    </StyledOuterWrapper>
  );
};

PlaylistOverview.propTypes = {
  isBackOffice: PropTypes.bool,
};

export default PlaylistOverview;

import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Drawer from '@shared/atoms/Drawer';
import FormInput from '@shared/atoms/FormFields/FormInput';
import FormToggle from '@shared/atoms/FormFields/FormToggle';
import T from '@shared/atoms/Typography';
import LanguageSelect from '@shared/molecules/LanguageSelect';
import ListItems from '@shared/molecules/ListItems';

import { selectTranslation, useForm } from '@shared/utils';
import theme from '@shared/themes/default';
import { DOMAIN_LOCALES } from '@shared/const';

import { useAppGlobals } from '../../../contexts/AppContext';
import { getShortUUID } from '../../../../utils';

const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m1};
  margin: 0 ${theme.spacing.m1} ${theme.spacing.m1} ${theme.spacing.m1};
  width: 496px;
`;

const FormRow = styled.div`
  align-items: end;
  display: flex;
  gap: ${theme.spacing.s};
`;

const ToggleRow = styled.div`
  display: flex;
  gap: ${theme.spacing.s};
`;

const CategoryDrawer = ({
  category,
  handleClose,
  handleCreate,
  handleCreateEnded,
  handleUpdate,
  handleUpdateEnded,
  open,
  showError,
}) => {
  const { domain, locale } = useAppGlobals();
  const { t } = useTranslation();
  const locales = DOMAIN_LOCALES[domain];

  const [drawerLocale, setDrawerLocale] = useState(locale);

  const {
    disableSubmit,
    edited,
    errors: formErrors,
    handleBlur,
    handleChange,
    handleSubmit,
    properties,
    submitting,
    setDefaults,
    values,
  } = useForm({
    changesRequired: true,
    fields: {
      title: {
        localised: true,
        maxLength: 70,
        required: true,
      },
      description: {
        localised: true,
        maxLength: 250,
      },
      ordinal: {
        mode: 'numeric',
        pattern: '[0-9]*',
        required: true,
        validation: (value) => /^[0-9]*$/.test(value),
      },
      expand: {},
    },
    locales,
    onSubmitHook: (newCategory) => {
      if (category.id) {
        handleUpdate({
          id: category.id,
          domain: [domain],
          title: newCategory.title[locale],
          description:
            newCategory.description[locale] === ''
              ? null
              : newCategory.description[locale],
          ordinal: newCategory.ordinal,
          expand: newCategory.expand,
          titleTranslations: JSON.stringify(newCategory.title),
          descriptionTranslations: JSON.stringify(newCategory.description),
        });
      } else {
        handleCreate({
          shortId: getShortUUID(),
          domain: [domain],
          title: newCategory.title[locale],
          description:
            newCategory.description[locale] === ''
              ? null
              : newCategory.description[locale],
          ordinal: newCategory.ordinal,
          expand: newCategory.expand,
          titleTranslations: JSON.stringify(newCategory.title),
          descriptionTranslations: JSON.stringify(newCategory.description),
        });
      }
    },
    onSubmitHookEnded: category?.id ? handleUpdateEnded : handleCreateEnded,
    requiredLocales: [locale],
  });

  useEffect(() => {
    if (category) {
      setDefaults({
        title:
          (category.titleTranslations &&
            JSON.parse(category.titleTranslations)) ||
          locales.reduce((acc, curr) => {
            acc[curr] = '';
            return acc;
          }, {}),
        description:
          (category.descriptionTranslations &&
            JSON.parse(category.descriptionTranslations)) ||
          locales.reduce((acc, curr) => {
            acc[curr] = '';
            return acc;
          }, {}),
        ordinal: category.ordinal?.toString() || '',
        expand: !!category.expand,
      });
    }
  }, [category]);

  // Reset language when the drawer is reopened
  useEffect(() => {
    setDrawerLocale(locale);
  }, [open]);

  return (
    <Drawer
      anchor="right"
      edited={edited}
      errorMessage={
        showError ? t('backoffice.categories.errorSaving') : undefined
      }
      open={open}
      onClose={handleClose}
      onSave={handleSubmit}
      title={
        category?.id
          ? selectTranslation(category.titleTranslations, null, locale, '')
          : t('backoffice.categories.new')
      }
      saveDisabled={disableSubmit || submitting}
      saving={submitting}
    >
      <DrawerContent>
        <FormRow>
          <LanguageSelect
            languages={locales}
            onChange={({ value }) => setDrawerLocale(value)}
            value={drawerLocale}
          />
          <FormInput
            id="title"
            label={t('backoffice.categories.title')}
            locale={drawerLocale}
            name="title"
            placeholder={t('backoffice.categories.titlePlaceholder')}
            showMaxLength
            theme="dark"
            errors={formErrors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            properties={properties}
            values={values}
          />
        </FormRow>
        <FormInput
          id="description"
          label={t('backoffice.categories.description')}
          locale={drawerLocale}
          name="description"
          minRows={6}
          multiline
          placeholder={t('backoffice.categories.descriptionPlaceholder')}
          showMaxLength
          errors={formErrors}
          handleBlur={handleBlur}
          handleChange={handleChange}
          properties={properties}
          values={values}
        />
        <FormInput
          id="ordinal"
          label={t('backoffice.categories.ordinal')}
          name="ordinal"
          placeholder={t('backoffice.categories.ordinalPlaceholder')}
          errors={formErrors}
          handleBlur={handleBlur}
          handleChange={handleChange}
          properties={properties}
          sx={{ width: '20%' }}
          values={values}
        />
        <ToggleRow>
          <FormToggle
            id="expand"
            label={t('backoffice.categories.expand')}
            name="expand"
            handleChange={handleChange}
            properties={properties}
            values={values}
          />
        </ToggleRow>
        {category?.courses?.items && (
          <div>
            <T fontWeight="bold">
              {t('backoffice.categories.courses', {
                count: category.courses.items.length,
              })}
            </T>
            {category?.courses.items.length > 0 && (
              <ListItems
                items={category.courses.items.map((course) => ({
                  key: course.id,
                  title: selectTranslation(
                    course.nameTranslations,
                    null,
                    locale,
                    ''
                  ),
                }))}
                theme="dark"
              />
            )}
          </div>
        )}
      </DrawerContent>
    </Drawer>
  );
};

CategoryDrawer.propTypes = {
  category: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  handleCreate: PropTypes.func.isRequired,
  handleCreateEnded: PropTypes.bool,
  handleUpdate: PropTypes.func.isRequired,
  handleUpdateEnded: PropTypes.bool,
  open: PropTypes.bool,
  showError: PropTypes.bool,
};

export default CategoryDrawer;

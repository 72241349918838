import { firstLetterCaps, formatDate, logger } from 'shared/utils';
import { TIMESTAMP_FORMATS } from 'shared/const';

export const mapAggregatedWatchDataGraph = (apiData, locale) => {
  const data = [];
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < apiData.length / 2; index++) {
    const point1 = apiData[index + apiData.length / 2];
    const point2 = apiData[index];
    const month = point1.date.split('-')[1];
    const date = new Date();
    date.setMonth(month - 1, 1);

    data.push({
      name: firstLetterCaps(
        formatDate({
          date,
          locale,
          type: TIMESTAMP_FORMATS.MONTH,
        })
      ).replace('.', ''),
      current: point1.count,
      past: point2.count,
    });
  }
  return data;
};
export const callApi = async ({
  setLoading,
  setData,
  setError,
  apiMethod,
  type,
  params,
}) => {
  const handleError = (error) => {
    setLoading(false);
    setError(true);
    logger.error(
      `Some error happened calling aggregated watch data ${type}`,
      error
    );
  };
  try {
    setLoading(true);
    const variables = { watchData: type, ...params };
    const { data, error } = await apiMethod(variables);
    if (error) return handleError(error);
    setLoading(false);
    setData(data.listAggregatedWatchData);
  } catch (error) {
    handleError(error);
  }
};

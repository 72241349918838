import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import useMediaQuery from '@mui/material/useMediaQuery';

import Button from 'shared/atoms/Button';
import T from 'shared/atoms/Typography';
import theme from 'shared/themes/default';
import Logo from 'shared/atoms/Logo';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacing.xl};

  ${theme.breakpoints.tablet} {
    gap: ${theme.spacing.l};
  }

  ${theme.breakpoints.mobile} {
    gap: ${theme.spacing.m2};
    width: 100%;
  }
`;

const FirstBlock = styled.div`
  display: flex;
  gap: ${theme.spacing.l};
  align-items: center;

  ${theme.breakpoints.mobile} {
    flex-direction: column;
    gap: ${theme.spacing.m1};
    width: 100%;
  }
`;

const TextWrapper = styled.div`
  background-color: ${theme.palette.lightPure};
  padding: ${theme.spacing.s};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m3};
  position: relative;
  border-radius: 0.4em;
  white-space: pre-line;
  max-width: 344px;

  ${theme.breakpoints.tablet} {
    gap: ${theme.spacing.m2};
  }

  ${theme.breakpoints.mobile} {
    align-self: stretch;
    gap: ${theme.spacing.m1};
    max-width: none;
  }

  &:after {
    border: 8px solid transparent;
    border-top-color: ${theme.palette.lightPure};
    content: '';
    margin-left: -1em;
    position: absolute;
    top: 43%;
    left: 0%;
    width: 0;
    height: 0;
    rotate: 90deg;

    ${theme.breakpoints.mobile} {
      margin-left: 0;
      margin-top: -1em;
      top: 0;
      left: 45%;
      rotate: 180deg;
    }
  }
`;

const ErrorView = ({
  buttonText,
  buttonOnClick,
  domain,
  firstSentence,
  secondSentence,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.mobile);
  const isTablet = useMediaQuery(theme.breakpoints.tablet);

  let fontVariant;
  if (isMobile) {
    fontVariant = 'paragraph';
  } else if (isTablet) {
    fontVariant = 'headingXs';
  } else {
    fontVariant = 'headingS';
  }

  return (
    <Wrapper>
      <FirstBlock>
        <Logo domain={domain} />

        <TextWrapper>
          <T
            variant={fontVariant}
            color={theme.palette.mainDark}
            fontWeight="semibold"
          >
            {firstSentence}
          </T>
          <T
            variant={fontVariant}
            color={theme.palette.mainDark}
            fontWeight="semibold"
          >
            {secondSentence}
          </T>
        </TextWrapper>
      </FirstBlock>
      <Button
        ariaLabel={buttonText}
        onClick={buttonOnClick}
        theme="dark"
        iconLeft={ChevronLeftRoundedIcon}
      >
        {buttonText}
      </Button>
    </Wrapper>
  );
};

ErrorView.propTypes = {
  buttonText: PropTypes.string.isRequired,
  buttonOnClick: PropTypes.func.isRequired,
  domain: PropTypes.string.isRequired,
  firstSentence: PropTypes.string.isRequired,
  secondSentence: PropTypes.string.isRequired,
};

export default ErrorView;

import theme from 'shared/themes/default';

export const ORGANIZATION = 'ORGANIZATION';
export const USER = 'USER';
export const GROUP = 'GROUP';
export const NOT_SHARED = 'NOT_SHARED';

export const shareTypeMapping = {
  internal: ORGANIZATION,
  selection: USER,
  GROUP,
  'not-shared': NOT_SHARED,
};

export const PLAYLIST_SHARE_TYPE = {
  SELECTION: 'selection',
  INTERNAL: 'internal',
};

export const invitationStatus = {
  pending: 'PENDING',
  invited: 'INVITED',
  bounced: 'BOUNCED',
};

export const invitationSource = {
  client: 'CLIENT',
  backoffice: 'BACKOFFICE',
  import: 'IMPORT',
};

export const registeredMembersRoles = {
  admin: 'organizationAdministrator',
  manager: 'organizationManager',
  member: 'organizationMember',
};

export const actionsOnContentOwnership = {
  delete: 'DELETE',
  transfer: 'TRANSFER',
};

export const backgroundTheme = [
  { name: 'videocation-light', value: theme.palette.lightPure },
  { name: 'videocation-dark', value: theme.palette.mainDark },
];

export const BACKGROUND_THEMES = ['light', 'dark'];

export const ORGANIZATION_GLOBAL = 'global';

export const TYPE_WATCH_DATA = {
  ORGANIZATIONS: 'ORGANIZATIONS',
  USERS: 'USERS',
  COURSE: 'COURSE',
  COURSES: 'COURSES',
  COURSE_GLOBAL: 'COURSE_GLOBAL_WATCHDATA',
  COURSES_GLOBAL: 'COURSES_GLOBAL_WATCHDATA',
  GROUPS: 'GROUPS',
  GROUP: 'GROUP_USERS',
  NANODEGREE_GLOBAL: 'NANODEGREE_GLOBAL',
  NANODEGREES: 'NANODEGREES',
  NANODEGREES_GLOBAL: 'NANODEGREES_GLOBAL',
  PLAYLISTS: 'PLAYLISTS',
  PLAYLIST_USERS: 'PLAYLIST_USERS',
  PLAYLIST_GROUPS: 'PLAYLIST_GROUPS',
};

export const DOMAIN = ['no', 'es'];

export const QUIZ_STATUS = {
  PASSED: 'PASSED',
  FAILED: 'FAILED',
  MAX_NUMBERS_OF_RETAKE_EXCEEDED: 'MAX_NUMBERS_OF_RETAKE_EXCEEDED',
  EXPIRED: 'EXPIRED',
};

export const QUIZ_TYPE = {
  NO_QUIZ: 'NO_QUIZ',
  TEST: 'TEST',
  GAME: 'GAME',
};

export const CERTIFICATE_TYPE = {
  COURSE: 'COURSE',
  NANO_DEGREE: 'NANO_DEGREE',
};

export const PLAYLIST_STATUS = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
  PAUSED: 'PAUSED',
  ARCHIVED: 'ARCHIVED',
};

export const PLAYLIST_TYPE = {
  PLAYLIST: 'PLAYLIST',
  NANO_DEGREE: 'NANO_DEGREE',
};

export const PLAYLIST_ITEM_TYPE = {
  COURSE: 'COURSE',
  EPISODE: 'EPISODE',
  EXAM: 'EXAM',
  GAME: 'GAME',
  PLACEHOLDER: 'PLACEHOLDER',
  QUIZ: 'QUIZ',
  TRAILER: 'TRAILER',
};

export const stripeLocaleMapping = {
  no: 'nb',
  es: 'es',
  en: 'en',
};

export const AUTHENTICATION_FLOWS = {
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  LOGIN: 'LOGIN',
  PURCHASE: 'PURCHASE',
  REGISTER: 'REGISTER',
  NELFO: 'NELFO',
  VERIFY: 'VERIFY',
};

export const PAYMENT_TYPE = {
  CARD: 'CARD',
  INVOICE: 'INVOICE',
};

export const courseStatus = {
  ARCHIVED: 'ARCHIVED',
  DRAFT: 'DRAFT',
  HIDDEN: 'HIDDEN',
  PUBLISHED: 'PUBLISHED',
};

export const courseType = {
  FREE: 'FREE',
  PREMIUM: 'PREMIUM',
  SELFPUBLISH: 'SELFPUBLISH',
};

export const TAB_STATUS = {
  COMPLETED: 'COMPLETED',
  IN_PROGRESS: 'IN_PROGRESS',
  DISABLED: 'DISABLED',
};

export const SUBSCRIPTION_PLAN_TYPES = {
  INDIVIDUAL: 'INDIVIDUAL',
  GROUP: 'GROUP',
};

export const LOCALES = ['no', 'es', 'en'];

// Default locale must be first
export const DOMAIN_LOCALES = {
  no: ['no', 'en'],
  es: ['es', 'en'],
};

export const LANGUAGE_NAMES = {
  no: 'Norsk',
  en: 'English',
  es: 'Español',
};

export const PLAYER_CONTENT_TYPE = {
  COURSE: 'COURSE',
  NANODEGREE: 'NANODEGREE',
  PLAYLIST: 'PLAYLIST',
  TRAILER: 'TRAILER',
};

export const PLAYER_CONTENT_SUB_TYPE = {
  COURSE: 'COURSE',
  LESSON: 'LESSON',
  NANODEGREE: 'NANODEGREE',
  TRAILER: 'TRAILER',
};

export const PLAYER_NEXT_CONTENT_TYPE = {
  COURSE: 'COURSE',
  EXAM: 'EXAM',
  GAME: 'GAME',
  LESSON: 'LESSON',
  NANODEGREE: 'NANODEGREE',
  SUBSCRIPTION: 'SUBSCRIPTION',
  TEST: 'TEST',
};

export const PLAYER_TRANSITION_TYPE = {
  COURSE: 'COURSE',
  EMPTY: 'EMPTY',
  ERROR: 'ERROR',
  EXAM: 'EXAM',
  GAME: 'GAME',
  TEST: 'TEST',
};

export const PLAYER_EVENTS = {
  CAN_PLAY_THROUGH: 'canplaythrough',
  CLICK: 'click',
  ENDED: 'ended',
  ERROR: 'error',
  FULLSCREEN_CHANGE: 'fullscreenchange',
  HIDE_CONTROLS: 'HIDE_CONTROLS',
  HIDE_TRANSITION: 'HIDE_TRANSITION',
  HIDE_UPNEXT: 'HIDE_UPNEXT',
  LOADED_METADATA: 'loadedmetadata',
  LOAD_START: 'loadstart',
  MOUSE_MOVE: 'mousemove',
  PLAY: 'play',
  PAUSE: 'pause',
  RESTART: 'restart',
  SEEKED: 'seeked',
  SHOW_CONTROLS: 'SHOW_CONTROLS',
  SHOW_TRANSITION: 'SHOW_TRANSITION',
  SHOW_UPNEXT: 'SHOW_UPNEXT',
  UPDATE_BANNER_TYPE: 'UPDATE_BANNER_TYPE',
  UPDATE_ERROR: 'UPDATE_ERROR',
  UPDATE_LOADING: 'UPDATE_LOADING',
  UPDATE_LOCALE: 'UPDATE_LOCALE',
  UPDATE_LOCKED: 'UPDATE_LOCKED',
  UPDATE_NEXT: 'UPDATE_NEXT',
  UPDATE_PREVIOUS: 'UPDATE_PREVIOUS',
  UPDATE_SUBTITLES: 'UPDATE_SUBTITLES',
  UPDATE_SUBTYPE: 'UPDATE_SUBTYPE',
  UPDATE_VIDEO_QUALITIES: 'UPDATE_VIDEO_QUALITIES',
  USER_ACTIVE: 'useractive',
  USER_INACTIVE: 'userinactive',
  TIME_UPDATE: 'timeupdate',
  CONTEXT_MENU: 'contextmenu',
};

export const PLAYER_CALLBACKS = {
  RETRY: 'RETRY',
};

export const PLAYER_DURATIONS = {
  CLOSE_CONTROLBAR: 500,
  OPEN_CONTROLBAR: 100,
  USER_INACTIVE: 2000,
};

export const AUTOCOMPLETEWITHLIST_TYPE = {
  FORM: 'FORM',
};

export const PROCESSING_STATUS = {
  TO_SCHEDULE: 'TO_SCHEDULE',
  PROCESSING: 'PROCESSING',
  PROCESSED: 'PROCESSED',
  ERROR: 'ERROR',
};

export const PROCESSING_STATUS_PRIORITY = [
  PROCESSING_STATUS.PROCESSED,
  PROCESSING_STATUS.ERROR,
  PROCESSING_STATUS.PROCESSING,
  PROCESSING_STATUS.TO_SCHEDULE,
];

export const COURSE_WATCH_TYPE = {
  COURSE: 'COURSE',
  EPISODE: 'EPISODE',
  QUIZ: 'QUIZ',
  GAME: 'GAME',
  TRAILER: 'TRAILER',
};

export const PLAYLIST_WATCH_TYPE = {
  COURSE: 'COURSE',
  EPISODE: 'EPISODE',
  EXAM: 'EXAM',
  GAME: 'GAME',
  PLAYLIST: 'PLAYLIST',
  QUIZ: 'QUIZ',
  TRAILER: 'TRAILER',
  PLACEHOLDER: 'PLACEHOLDER',
};

export const PLAYER_LOCAL_STORAGE = {
  AUTOPLAY: 'videocation-player-autoplay',
  MUTED: 'videocation-player-muted',
  SPEED: 'videocation-player-speed',
  SUBTITLE: 'videocation-player-subtitle',
  VOLUME: 'videocation-player-volume',
  QUALITY: 'videocation-player-quality',
};

export const COURSE_VIEW_TABS = {
  INFORMATION: 'information',
  ATTACHMENTS: 'attachments',
  LESSONS: 'lessons',
};

export const BANNER_LOCKED_TYPE = {
  ORGANIZATION_ADMIN: 'ORGANIZATION_ADMIN',
  ORGANIZATION_CUSTOM: 'ORGANIZATION_CUSTOM',
  ORGANIZATION_USER: 'ORGANIZATION_USER',
  ORGANIZATION_LOCKED_USER: 'ORGANIZATION_LOCKED_USER',
  SINGLE_SUBSCRIBER: 'SINGLE_SUBSCRIBER',
};

export const MAX_COURSE = {
  DESCRIPTION_LENGTH: 6000,
  NAME_LENGTH: 70,
  PREREQUISITES_TYPICAL_LENGTH: 200,
  SHORT_DESCRIPTION_LENGTH: 300,
  SEO_DESCRIPTION_LENGTH: 150,
};

export const MAX_PLAYLIST = {
  DESCRIPTION_LENGTH: 6000,
  NAME_LENGTH: 70,
};

export const TIMESTAMP_FORMATS = {
  DATE: 'DATE',
  DATE_DEFAULT: 'DATE_DEFAULT',
  DATE_TIME: 'DATE_TIME',
  MONTH: 'MONTH',
  MONTH_YEAR: 'MONTH_YEAR',
  TIME: 'TIME',
};

export const CATEGORY_TYPE = {
  CATEGORY: 'category',
  PLAYLIST: 'playlist',
  NANO_DEGREE: 'nanodegree',
};

export const SOCIAL_TYPE = {
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  TWITTER: 'twitter',
  YOUTUBE: 'youtube',
  LINKEDIN: 'linkedin',
  SNAPCHAT: 'snapchat',
};

export const TAX_DISPLAY_SETTING = {
  EXCLUSIVE: 'EXCLUSIVE',
  INCLUSIVE: 'INCLUSIVE',
};

export const COURSE_LEVELS = {
  FOUNDATIONAL: 'foundational',
  INTEMEDIATE: 'intermediate',
  ADVANCED: 'advanced',
};

export const SUBSCRIPTION_PLAN_STATUS = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  INVOICE: 'INVOICE',
};

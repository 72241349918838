import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';

import withStyles from '@mui/styles/withStyles';

import Dialog from 'shared/molecules/Dialog';
import FullWidthTabs from 'shared/atoms/FullWidthTabs';
import T from 'shared/atoms/Typography';
import SearchField from 'shared/atoms/SearchField';
import ConfirmationDialog from 'shared/molecules/ConfirmationDialog';
import Alert from 'shared/atoms/Alert';
import Button from 'shared/atoms/Button';
import ListItems from 'shared/molecules/ListItems';

import theme from 'shared/themes/default';
import { itemIsIncluded } from 'shared/utils';

const Spacer = styled.div`
  height: ${({ px }) => px}px;
`;

const SideSpaceWrapper = styled.div`
  padding: 0 1rem;
`;

const styles = {
  dialogPaper: {
    maxHeight: '88vh',
    margin: '0',
  },
};

const SelectGroupMembersAndPreview = ({
  groupName,
  initialSelected,
  onClose,
  onSubmit,
  allMembers,
  loading,
  isMobile,
  formStatus,
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [newSelectedUsers, setNewSelectedUsers] = useState([]);
  const [showConfirmationDialogue, setShowConfirmationDialogue] =
    useState(false);
  const { t } = useTranslation();

  const sortedMembers =
    allMembers && allMembers.sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    if (formStatus) {
      setShowConfirmationDialogue(false);
    }
  }, [formStatus]);

  useEffect(() => {
    if (!loading) {
      setNewSelectedUsers(initialSelected);
    }
  }, [initialSelected, loading]);

  const memberParse = (member, preview) => {
    const {
      disabled,
      email: subtitle,
      isNew: showSecondaryAction,
      name: title,
    } = member;

    let newMember = {
      key: subtitle,
      subtitle,
      title,
    };

    if (preview) {
      newMember = {
        ...newMember,
        showBorder: showSecondaryAction,
        showSecondaryAction,
      };
    } else {
      newMember = {
        ...newMember,
        disabled,
      };
    }

    return newMember;
  };

  const toggleUser = (userEmail) => {
    const user = allMembers.find((member) => member.email === userEmail);
    const { id: userId } = user;
    const userData = user;
    if (itemIsIncluded(userId, newSelectedUsers)) {
      if (!isMobile || !itemIsIncluded(userId, initialSelected)) {
        setNewSelectedUsers(
          newSelectedUsers.filter((user) => user.id !== userId)
        );
      }
    } else {
      delete userData.selected;
      delete userData.isNew;
      const newValue = [...newSelectedUsers, userData];
      setNewSelectedUsers(newValue);
    }
  };
  const modifiedItems =
    !!initialSelected.filter(
      (user) =>
        !newSelectedUsers.some((newUser) => newUser.email === user.email)
    ).length || newSelectedUsers.length !== initialSelected.length;
  const onCloseHandler = (value) => {
    if (value === 'discard' || !modifiedItems || !!formStatus) {
      onClose();
    } else {
      setShowConfirmationDialogue(true);
    }
  };

  const removeUserFromList = (userEmail) =>
    setNewSelectedUsers(
      newSelectedUsers.filter((user) => user.email !== userEmail)
    );

  const membersToRender =
    sortedMembers &&
    sortedMembers
      .filter(
        (member) =>
          member.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          member.email.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .map((user) => ({
        ...user,
        disabled: itemIsIncluded(user.id, initialSelected) && isMobile,
        isNew: !itemIsIncluded(user.id, initialSelected),
        selected: itemIsIncluded(user.id, newSelectedUsers),
      }))
      .map((member) => memberParse(member));

  const membersInPreview =
    sortedMembers &&
    sortedMembers
      .map((user) => ({
        ...user,
        isNew: !itemIsIncluded(user.id, initialSelected),
        selected: itemIsIncluded(user.id, newSelectedUsers),
      }))
      .filter((member) => member.selected)
      .sort((a, b) => b.isNew - a.isNew);

  const previewMembersCount = membersInPreview?.length || 0;
  const filteredMembersInPreview =
    membersInPreview &&
    membersInPreview
      .filter(
        (member) =>
          member.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          member.email.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .map((member) => memberParse(member, true));

  const tab1Component = (
    <ListItems
      items={membersToRender || []}
      onSelect={toggleUser}
      selected={newSelectedUsers.map((member) => member.email)}
    />
  );
  const tab2Component = (
    <ListItems
      items={filteredMembersInPreview || []}
      onRemove={removeUserFromList}
      secondaryActionLabel={t('generic.justAddedText')}
      showBorder
      minHeight="390px"
    />
  );

  const tabsToSelect = [
    { label: t('administrateUsers.addMembers'), component: tab1Component },
    {
      label: `${t('administrateUsers.previewGroup')} (${previewMembersCount})`,
      component: tab2Component,
    },
  ];

  const onSearchHandler = ({ value }) => {
    setSearchTerm(value);
  };

  const actions = [
    {
      ariaLabel: t('generic.cancel'),
      handler: onCloseHandler,
      label: t('generic.cancel'),
      type: 'secondaryOutline',
    },
    {
      disabled: !newSelectedUsers?.length || !modifiedItems,
      handler: () => onSubmit(newSelectedUsers),
      label: t('administrateUsers.addMembersToGroup'),
    },
  ];

  const onChangeTabHandler = () => {
    setSearchTerm('');
    setSelectedTabIndex(selectedTabIndex === 0 ? 1 : 0);
  };

  const TopperText = () => {
    if (formStatus) {
      return null;
    }
    return (
      <Button
        ariaLabel={t('administrateUsers.backToViewMembersText')}
        iconLeft={ChevronLeftRoundedIcon}
        onClick={onCloseHandler}
        sx={{ marginTop: theme.spacing.m1 }}
        theme="light"
        type="textWhite"
      >
        {t('administrateUsers.backToViewMembersText')}
      </Button>
    );
  };

  const tabView = () => {
    const child = (
      <>
        <T color={theme.palette.darkDistinct} fontWeight="semibold">
          {t('administrateUsers.searchToAddLabel')}
        </T>
        <Spacer px={12} />
        <SearchField
          theme="light"
          ariaLabel="add-members-search-box"
          placeholder={t('administrateUsers.searchMembersPlaceholder')}
          value={searchTerm}
          onClear={() => setSearchTerm('')}
          onChange={onSearchHandler}
          disabled={loading}
        />
        <Spacer px={28} />
        <T color={theme.palette.darkDistinct}>
          {t('administrateUsers.groupHasXMembers', {
            count: newSelectedUsers.length,
          })}
        </T>
      </>
    );

    if (isMobile) {
      return (
        <>
          <SideSpaceWrapper>{child}</SideSpaceWrapper>
          {tab1Component}
        </>
      );
    }
    return (
      <FullWidthTabs
        tabsToSelect={tabsToSelect}
        onChangeTabHandler={onChangeTabHandler}
        selectedTabIndex={selectedTabIndex}
      >
        {child}
      </FullWidthTabs>
    );
  };

  return (
    <Dialog
      open
      title={t('administrateUsers.addMembersToGroup')}
      onClose={!isMobile || formStatus ? onCloseHandler : null}
      ariaLabel="add-members-to-group-dialog"
      actions={actions}
      scrollType="body"
      loading={loading}
      contentPadding="0"
      topHeaderNode={isMobile ? <TopperText /> : null}
      formStatus={formStatus}
    >
      <SideSpaceWrapper>
        <T color={theme.palette.darkDistinct}>{groupName}</T>
      </SideSpaceWrapper>
      <Spacer px={30} />
      {showConfirmationDialogue && (
        <ConfirmationDialog
          open={showConfirmationDialogue}
          title={t('administrateUsers.unAddedMembers')}
          description={t('administrateUsers.confirmAddMembers')}
          ariaLabel={t('administrateUsers.unAddedMembers')}
          cancelText={t('administrateUsers.discard')}
          confirmText={t('administrateUsers.add')}
          onCancel={() => onCloseHandler('discard')}
          onConfirm={() => onSubmit(newSelectedUsers)}
          onClose={() => setShowConfirmationDialogue(false)}
        />
      )}
      {formStatus && (
        <Alert
          message={
            formStatus.message ||
            t(
              formStatus.success
                ? 'administrateUsers.groupUpdatedSuccessMessage'
                : 'administrateUsers.groupUpdatedFailureMessage'
            )
          }
          severity={formStatus.success ? 'success' : 'error'}
          theme="light"
        />
      )}
      {!formStatus && <>{tabView()}</>}
    </Dialog>
  );
};

SelectGroupMembersAndPreview.propTypes = {
  groupName: PropTypes.string.isRequired,
  initialSelected: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  allMembers: PropTypes.array,
  loading: PropTypes.bool,
  isMobile: PropTypes.bool,
  formStatus: PropTypes.object,
};

SelectGroupMembersAndPreview.defaultProps = {
  allMembers: [],
  initialSelected: [],
  loading: true,
  isMobile: false,
  formStatus: null,
};

export default withStyles(styles)(SelectGroupMembersAndPreview);

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import Chip from '@shared/atoms/Chip';
import CircularSpinner from '@shared/atoms/CircularSpinner';
import Checkbox from '@shared/atoms/Checkbox';
import IconButton from '@shared/atoms/IconButton';
import HorizontalRule from '@shared/atoms/HorizontalRule';
import SearchField from '@shared/atoms/SearchField';
import Snackbar from '@shared/atoms/Snackbar';
import T from '@shared/atoms/Typography';
import Menu from '@shared/molecules/Menu';
import Table, { CELL_TYPES, ORDER_TYPES } from '@shared/organisms/Table';
import theme from '@shared/themes/default';

import { logger } from '@shared/utils';
import { useAppGlobals } from '../../contexts/AppContext';

import CreateOrganizationDialog from './components/CreateOrganizationDialog';
import ExportAllUsersDialog from './components/ExportAllUsersDialog';
import OrganizationsTableRowItem from './components/OrganizationsTableRowItem';
import { listOrganizations, listSubscriptionPlans } from './graphql/queries';
import { useGraphQLQuery } from '../../../utils';

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m1};
`;

const SearchWrapper = styled.div`
  display: flex;
  gap: ${theme.spacing.m1};
`;

const CheckboxWrapper = styled.div`
  flex: 1 0 auto;
  height: 40px;
`;

const InfoWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${theme.spacing.s};
`;

const OrganizationsView = () => {
  const { domain } = useAppGlobals();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [detailedErrors, setDetailedErrors] = useState([]);
  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);
  const [listArchived, setListArchived] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [nextToken, setNextToken] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [searchedOrganizations, setSearchedOrganizations] = useState([]);
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');

  const customMenuHandler = (event) => {
    setAnchorEl(anchorEl === null ? event.currentTarget : null);
  };

  const { callQuery, data, errors, hasEnded, hasErrors, loading } =
    useGraphQLQuery();

  useEffect(() => {
    if (hasEnded) {
      if (hasErrors) {
        logger.error('Error: ', errors);
        setErrorMessage(
          t('backoffice.organizations.failedToLoadOrganizations')
        );
        setHasError(true);
      } else {
        const {
          listOrganizations: { items, nextToken: newNextToken },
        } = data;
        setNextToken(newNextToken);
        setOrganizations((prev) => [...prev, ...items]);
      }
    }
  }, [hasEnded]);

  useEffect(() => {
    if (nextToken) {
      callQuery(listOrganizations(domain, nextToken));
    }
  }, [nextToken]);

  const {
    callQuery: callPlansQuery,
    data: plansData,
    errors: plansErrors,
    hasEnded: plansHasEnded,
    hasErrors: plansHasErrors,
    loading: plansLoading,
  } = useGraphQLQuery();

  useEffect(() => {
    if (plansHasEnded) {
      if (plansHasErrors) {
        logger.error('Error: ', plansErrors);
        setErrorMessage(t('backoffice.organizations.failedToLoadPlans'));
        setHasError(true);
      } else {
        const {
          listSubscriptionPlans: { items },
        } = plansData;

        setSubscriptionPlans(
          items
            .filter((plan) => !!plan.backoffice)
            .map((plan) => ({ value: plan.id, key: plan.id, label: plan.name }))
        );
      }
    }
  }, [plansHasEnded]);

  useEffect(() => {
    callQuery(listOrganizations(domain));
    callPlansQuery(
      listSubscriptionPlans({
        limit: 50,
        filter: {
          domain: {
            eq: domain,
          },
        },
      })
    );
  }, []);

  useEffect(() => {
    const filteredOrganizations = organizations.filter((organization) => {
      const { archived, name } = organization;
      return (
        (listArchived || !archived) &&
        (searchValue.length === 0 ||
          (name || '').toLowerCase().includes(searchValue.toLowerCase()))
      );
    });
    setSearchedOrganizations(filteredOrganizations);
  }, [listArchived, organizations, searchValue]);

  useEffect(() => {
    setSelectedItemIds([]);
  }, [listArchived, searchValue]);

  const onCreateOrganization = (organization) => {
    setOrganizations([...organizations, organization]);
  };

  const onUpdateOrganization = (organization) => {
    const updatedOrganizations = organizations.map((org) => {
      if (org.id === organization.id) {
        return {
          ...org,
          ...organization,
        };
      }
      return org;
    });

    setOrganizations(updatedOrganizations);
  };

  const toggleShowExpandedOrganization = (id) => {
    if (selectedItemIds.includes(id)) {
      setSelectedItemIds((items) => items.filter((itemId) => id !== itemId));
    } else {
      setSelectedItemIds((items) => [...items, id]);
    }
  };

  return (
    <>
      <OuterWrapper>
        <T fontWeight="medium" variant="headingS">
          {t('backoffice.organizations.organizations')}
        </T>
        <HorizontalRule />
        <SearchWrapper>
          <SearchField
            ariaLabel={t('backoffice.organizations.search')}
            disabled={loading}
            onChange={({ value }) => setSearchValue(value)}
            onClear={() => setSearchValue('')}
            placeholder={t('backoffice.organizations.search')}
            theme="dark"
            type="primary"
            value={searchValue}
          />
          <CheckboxWrapper>
            <Checkbox
              checked={listArchived}
              disabled={loading}
              label={t('backoffice.organizations.listArchived')}
              onChange={() => setListArchived(!listArchived)}
              theme="dark"
            />
          </CheckboxWrapper>
          <IconButton
            ariaLabel={t('generic.more')}
            icon={MoreHorizIcon}
            onClick={customMenuHandler}
            theme="dark"
            type="primary"
          />
          <Menu
            freeMenu
            initAnchorEl={anchorEl}
            menuItems={[
              {
                label: t('backoffice.organizations.createOrganization.title'),
                handler: () => setCreateDialogOpen(true),
                disabled: subscriptionPlans.length === 0,
              },
              {
                label: t('backoffice.organizations.exportAllUsers.title'),
                handler: () => setExportDialogOpen(true),
              },
            ]}
            setInitAnchorEl={setAnchorEl}
            theme="dark"
          />
        </SearchWrapper>
        {loading && <CircularSpinner />}
        {searchedOrganizations.length > 0 && !loading && (
          <Table
            ariaLabel="Organizations table"
            columns={[
              {
                key: 'name',
                label: t('backoffice.organizations.table.name'),
                type: CELL_TYPES.COMPONENT,
                render: (row) => {
                  return (
                    <InfoWrapper>
                      {row.name}
                      {row.archived && (
                        <Chip
                          color={theme.palette.errorLight}
                          label={t('backoffice.organizations.table.archived')}
                          small
                          theme="dark"
                        />
                      )}
                    </InfoWrapper>
                  );
                },
              },
              {
                disableSorting: true,
                key: 'subscriptions',
                label: t('backoffice.organizations.table.info'),
                type: CELL_TYPES.COMPONENT,
                render: (row) => {
                  return (
                    <InfoWrapper>
                      {row.subscriptions &&
                        row.subscriptions.map(
                          (subscription) =>
                            !subscription.organizationId && (
                              <Chip
                                color={theme.palette.brand}
                                key={`${row.id}-${subscription.planId}-${subscription.validFrom}`}
                                label={
                                  row.invoiceSendVia
                                    ? t(
                                        'backoffice.organizations.table.planInvoice',
                                        { plan: subscription.planName }
                                      )
                                    : subscription.planName
                                }
                                small
                                theme="dark"
                              />
                            )
                        )}
                      {(!row.subscriptions || row.subscriptions.length === 0) &&
                        t('backoffice.organizations.table.stopped')}
                    </InfoWrapper>
                  );
                },
              },
              {
                key: 'createdFrom',
                label: t('backoffice.organizations.table.createdBy'),
              },
              {
                key: 'registeredMembers',
                label: t('backoffice.organizations.table.registeredMembers'),
              },
              {
                key: 'createdAt',
                label: t('backoffice.organizations.table.createdAt'),
                type: CELL_TYPES.DATE,
              },
            ]}
            items={searchedOrganizations}
            keyField="id"
            defaultOrder={ORDER_TYPES.ASC}
            defaultOrderBy="createdAt"
            rowIsClickable={() => true}
            selectedItemIds={selectedItemIds}
            onRowClick={(row) => toggleShowExpandedOrganization(row.id)}
            ExpandedItemComponent={OrganizationsTableRowItem}
            expandedItemComponentProps={{
              onUpdateOrganization,
              setErrorMessage,
              setDetailedErrors,
              setHasError,
              setHasSuccess,
              setSuccessMessage,
              subscriptionPlans,
              subscriptionPlansLoading: plansLoading,
            }}
          />
        )}
        {searchedOrganizations.length === 0 && !loading && (
          <T fontWeight="medium" variant="subtitle">
            {t('backoffice.organizations.noOrganizationsFound')}
          </T>
        )}
      </OuterWrapper>
      <CreateOrganizationDialog
        onClose={() => setCreateDialogOpen(false)}
        onCreateOrganization={onCreateOrganization}
        open={createDialogOpen}
        setErrorMessage={setErrorMessage}
        setHasError={setHasError}
        setHasSuccess={setHasSuccess}
        setSuccessMessage={setSuccessMessage}
        subscriptionPlans={subscriptionPlans}
        subscriptionPlansLoading={plansLoading}
      />
      <ExportAllUsersDialog
        onClose={() => setExportDialogOpen(false)}
        open={exportDialogOpen}
        setErrorMessage={setErrorMessage}
        setHasError={setHasError}
        setHasSuccess={setHasSuccess}
        setSuccessMessage={setSuccessMessage}
      />
      <Snackbar
        type="success"
        message={successMessage}
        open={hasSuccess}
        onClose={() => setHasSuccess(false)}
      />
      <Snackbar
        type="error"
        message={errorMessage}
        detailedErrors={detailedErrors}
        open={hasError}
        onClose={() => setHasError(false)}
      />
    </>
  );
};

export default OrganizationsView;

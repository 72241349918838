import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import T from 'shared/atoms/Typography';
import TableWithSearchAndExport from 'shared/organisms/WatchData/TableWithSearchAndExport';
import AllOrganizationsOverViewTable from 'shared/organisms/WatchData/AllOrganizationsOverViewTable';
import EpisodesSeenGraph from 'shared/organisms/WatchData/EpisodesSeenGraph';
import { TYPE_WATCH_DATA } from 'shared/const';
import theme from 'shared/themes/default';
import { mapAggregatedWatchDataGraph, callApi } from '../utils';
import { useWatchData } from '../Context';

const Wrapper = styled.div`
  max-width: 100%;
`;

const ShowOrganizationsData = ({ selectOrganizationHandler }) => {
  const [aggregatedWatchData, setAggregatedWatchData] = useState([]);
  const [loadingAggregated, setLoadingAggregated] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.mobile);

  const { t, i18n } = useTranslation();
  const { language: locale } = i18n;
  const {
    downloadWatchData,
    fetchOrganizationsWatchData,
    listAggregatedWatchData,
    loadingOrganizationsWatchData,
    organizationsWatchData,
    setWatchDataError,
    user,
  } = useWatchData();

  useEffect(() => {
    fetchOrganizationsWatchData();
  }, []);

  const organizations =
    organizationsWatchData
      ?.map((item) => ({
        ...item,
        value: item.id,
      }))
      .filter((item) => item.name)
      .sort((a, b) => {
        return a.name?.localeCompare(b.name);
      }) || [];

  useEffect(() => {
    callApi({
      setLoading: setLoadingAggregated,
      setData: setAggregatedWatchData,
      apiMethod: listAggregatedWatchData,
      setError: setWatchDataError,
      type: TYPE_WATCH_DATA.ORGANIZATIONS,
    });
  }, []);

  return (
    <Wrapper>
      <T
        color={theme.palette.brand}
        fontWeight="medium"
        phoneVariant="headingXs"
        sx={{
          marginBottom: theme.spacing.m3,
          marginTop: isMobile ? theme.spacing.xl : theme.spacing['2xl'],
        }}
        variant="headingS"
      >
        {t('watchData.titleAllOrganizations')}
      </T>
      <EpisodesSeenGraph
        data={mapAggregatedWatchDataGraph(aggregatedWatchData, locale)}
        loading={loadingAggregated}
        spinnerDuration={10}
      />
      <TableWithSearchAndExport
        itemsToDisplay={organizations}
        downloadItems={() =>
          downloadWatchData({ watchData: TYPE_WATCH_DATA.ORGANIZATIONS })
        }
        user={user}
        tableTitle={t('watchData.activityOfOrganizations')}
        searchPlaceHolder={t('watchData.searchForOrganizations')}
        TableToDisplay={AllOrganizationsOverViewTable}
        tableProps={{ selectOrganizationHandler }}
        showRowsAreClickableMessage
        loading={loadingOrganizationsWatchData || !organizations.length}
        spinnerDuration={20}
        emptyMessage={t('watchData.noActivity')}
      />
    </Wrapper>
  );
};

ShowOrganizationsData.propTypes = {
  selectOrganizationHandler: PropTypes.func.isRequired,
};

export default ShowOrganizationsData;

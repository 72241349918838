import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from 'shared/themes/default';
import TooltipDesktop from './components/TooltipDesktop';
import TooltipMobile from './components/TooltipMobile';

export default function Tooltip(props) {
  const isMobile = useMediaQuery(theme.breakpoints.mobile);
  if (!isMobile) {
    return <TooltipDesktop {...props} />;
  }
  return <TooltipMobile {...props} />;
}

import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import Select from '@shared/atoms/Select';
import FormInput from '@shared/atoms/FormFields/FormInput';
import FullWidthTabs from '@shared/atoms/FullWidthTabs';
import LanguageSelect from '@shared/molecules/LanguageSelect';
import T from '@shared/atoms/Typography';

import { PROCESSING_STATUS } from '@shared/const';
import { selectTranslation } from '@shared/utils';
import theme from '@shared/themes/default';
import { useCourse } from '../../../../../contexts/CourseContext';

import UploadWithList from '../../Shared/UploadWithList';

import GameInput from './GameInput';
import { DRAWER_ACTIONS } from '../const';

const SectionStyled = styled.section`
  background: ${theme.palette.darkWrapper};
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: ${theme.spacing.s};
  padding: ${theme.spacing.m1};
  row-gap: ${theme.spacing.m2};
  width: 506px;
`;

const TitleWrapper = styled.div`
  display: flex;
  column-gap: ${theme.spacing.s};
`;

const LanguageWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  flex: 0 0 auto;
`;

const TabWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.s};
`;

const ErrorWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.s};
`;

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${theme.spacing.m3};
`;

const TAB_NAMES = {
  CONTENT: 'content',
  SUBTITLES: 'subtitles',
  ATTACHMENTS: 'attachments',
};

const LESSON_TYPES = {
  NOT_CHOSEN: 'NOT_CHOSEN',
  VIDEO: 'VIDEO',
  GAME: 'GAME',
};

const DrawerLesson = ({
  course,
  errors,
  item: propsItem,
  locked,
  onChange,
  setChangedDrawerContent,
  submitting,
  type,
}) => {
  const { t } = useTranslation();
  const { getLanguages, hasChanged, setHasChanged, setUploadingFiles } =
    useCourse();
  const initialLanguages = getLanguages(course);
  const languages = Object.keys(initialLanguages).reduce(
    (acc, lang) => (initialLanguages[lang] ? [...acc, lang] : acc),
    []
  );

  const isTrailer =
    type === DRAWER_ACTIONS.ADD_TRAILER || type === DRAWER_ACTIONS.EDIT_TRAILER;

  const [lessonType, setLessonType] = useState(
    !isTrailer ? LESSON_TYPES.NOT_CHOSEN : LESSON_TYPES.VIDEO
  );
  const [item, setItem] = useState(propsItem || {});
  const [language, setLanguage] = useState(languages[0]);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (type === DRAWER_ACTIONS.EDIT_LESSON) {
      if (propsItem?.game?.id) {
        setLessonType(LESSON_TYPES.GAME);
      } else if (propsItem?.nameTranslations) {
        setLessonType(LESSON_TYPES.VIDEO);
      }
    }
  }, [propsItem]);

  useEffect(() => {
    if (JSON.stringify(propsItem || {}) !== JSON.stringify(item))
      onChange(
        item,
        (lessonType === LESSON_TYPES.GAME && item.game) ||
          lessonType === LESSON_TYPES.VIDEO
      );
  }, [item]);

  useEffect(() => {
    setSelectedTab(0);
    if (lessonType === LESSON_TYPES.VIDEO) {
      setItem({ ...item, ...(isTrailer ? {} : { game: null }) });
    } else {
      setChangedDrawerContent(false);
    }
  }, [lessonType]);

  const handleUpload = (tab, files) => {
    if (files?.length > 0) {
      setItem((prevItem) => {
        let newItem;
        switch (tab) {
          case TAB_NAMES.CONTENT:
            if (
              type === DRAWER_ACTIONS.ADD_TRAILER ||
              type === DRAWER_ACTIONS.EDIT_TRAILER
            ) {
              newItem = {
                ...prevItem,
                trailerVideoFiles: [],
                trailerVideoProcessingStatus: {
                  file: files[0].s3File,
                  status: PROCESSING_STATUS.TO_SCHEDULE,
                },
              };
            } else {
              newItem = {
                ...prevItem,
                videoFiles: [],
                videoProcessingStatus: {
                  file: files[0].s3File,
                  status: PROCESSING_STATUS.TO_SCHEDULE,
                },
              };
            }
            break;
          case TAB_NAMES.SUBTITLES:
            newItem = {
              ...prevItem,
              subtitle: null,
              subtitleProcessingStatus: {
                file: files[0].s3File,
                status: PROCESSING_STATUS.TO_SCHEDULE,
              },
            };
            break;
          case TAB_NAMES.ATTACHMENTS:
            newItem = {
              ...prevItem,
              attachments: [
                ...(prevItem.attachments || []),
                ...files.map((file) => ({
                  name: file.name,
                  processingStatus: {
                    file: file.s3File,
                    status: PROCESSING_STATUS.TO_SCHEDULE,
                  },
                })),
              ],
            };
            break;
          default:
            break;
        }
        return newItem;
      });
    }
    setUploadingFiles(false);
  };

  const handleDelete = (tab, index) => {
    setItem((prevItem) => {
      let newItem;
      switch (tab) {
        case TAB_NAMES.CONTENT:
          if (
            type === DRAWER_ACTIONS.ADD_TRAILER ||
            type === DRAWER_ACTIONS.EDIT_TRAILER
          ) {
            newItem = {
              ...prevItem,
              trailerVideoFiles: null,
              trailerVideoProcessingStatus: null,
            };
          } else {
            newItem = {
              ...prevItem,
              videoFiles: null,
              videoProcessingStatus: null,
            };
          }
          break;
        case TAB_NAMES.SUBTITLES:
          newItem = {
            ...prevItem,
            subtitle: null,
            subtitleProcessingStatus: null,
          };
          break;
        case TAB_NAMES.ATTACHMENTS:
          newItem = {
            ...prevItem,
            attachments: prevItem.attachments.filter((_, idx) => index !== idx),
          };
          break;
        default:
          break;
      }
      return newItem;
    });
  };

  const handleChange = (field, value) => {
    setItem((prevItem) => {
      const newItem = { ...prevItem, [field]: value };
      if (!newItem[field]) {
        delete newItem[field];
      }

      return newItem;
    });
  };

  const handleTitleChange = ({ value }) => {
    const nameTranslationsParsed = JSON.parse(item.nameTranslations || '{}');
    if (value) {
      nameTranslationsParsed[language] = value;
    } else {
      delete nameTranslationsParsed[language];
    }
    let nameTranslations = JSON.stringify(nameTranslationsParsed);
    if (nameTranslations === '{}') nameTranslations = null;
    handleChange('nameTranslations', nameTranslations);
  };

  const onStartUpload = () => {
    if (!hasChanged) setHasChanged(true);
    setChangedDrawerContent(true);
    setUploadingFiles(true);
  };

  const getTabItems = (tab) => {
    let value;
    switch (tab) {
      case TAB_NAMES.CONTENT:
        if (
          type === DRAWER_ACTIONS.ADD_TRAILER ||
          type === DRAWER_ACTIONS.EDIT_TRAILER
        ) {
          value =
            item.trailerVideoFiles?.length > 0 ||
            item.trailerVideoProcessingStatus?.status
              ? [
                  {
                    id: item.id || 'trailer-1',
                    name: t('backoffice.courses.lessonsTab.videoFile'),
                    processingStatus: item.trailerVideoProcessingStatus,
                  },
                ]
              : [];
        } else if (lessonType === LESSON_TYPES.VIDEO) {
          value =
            item.videoFiles?.length > 0 || item.videoProcessingStatus?.status
              ? [
                  {
                    id: item.id || 'video-1',
                    name: t('backoffice.courses.lessonsTab.videoFile'),
                    processingStatus: item.videoProcessingStatus,
                  },
                ]
              : [];
        } else if (lessonType === LESSON_TYPES.GAME) {
          value = [];
        } else {
          value = [];
        }
        break;
      case TAB_NAMES.SUBTITLES:
        value =
          item.subtitle || item.subtitleProcessingStatus?.status
            ? [
                {
                  id: item.id || 'subtitle-1',
                  name: t('backoffice.courses.lessonsTab.subtitles'),
                  processingStatus: item.subtitleProcessingStatus,
                },
              ]
            : [];
        break;
      case TAB_NAMES.ATTACHMENTS:
        value = item.attachments
          ? item.attachments.map(({ name, processingStatus }, index) => ({
              id: `attachment-${index}`,
              name,
              processingStatus,
            }))
          : [];
        break;
      default:
        value = [];
        break;
    }
    return value;
  };

  let tabs = [TAB_NAMES.CONTENT, TAB_NAMES.SUBTITLES, TAB_NAMES.ATTACHMENTS];
  if (type === DRAWER_ACTIONS.ADD_TRAILER) {
    tabs = [TAB_NAMES.CONTENT];
  }
  if (lessonType === LESSON_TYPES.GAME) {
    tabs = [TAB_NAMES.CONTENT, TAB_NAMES.ATTACHMENTS];
  }

  const getTabs = () => {
    return tabs.map((tab) => {
      const listItemsActions = [
        {
          ariaLabel: t('generic.delete'),
          icon: DeleteOutlineIcon,
          color: theme.palette.pureDark,
          handler: (idx) => handleDelete(tab, Number.parseInt(idx, 10)),
        },
      ];
      const tabItems = getTabItems(tab);
      let maxFiles;
      let description;
      if (tab === TAB_NAMES.CONTENT) {
        maxFiles = 1;
        if (
          type === DRAWER_ACTIONS.ADD_TRAILER ||
          type === DRAWER_ACTIONS.EDIT_TRAILER
        ) {
          description = [t('backoffice.courses.lessonsTab.trailerUpload')];
        } else {
          description = [t('backoffice.courses.lessonsTab.contentUpload')];
        }
      } else if (tab === TAB_NAMES.SUBTITLES) {
        maxFiles = 1;
        description = [
          t('backoffice.courses.lessonsTab.subtitlesUpload'),
          t('backoffice.courses.lessonsTab.or'),
          t('backoffice.courses.lessonsTab.subtitlesUpload2'),
        ];
      } else if (tab === TAB_NAMES.ATTACHMENTS) {
        description = [t('backoffice.courses.lessonsTab.attachmentsUpload')];
      }

      // this tab won't be using the Upload component
      if (tab === TAB_NAMES.CONTENT && lessonType === LESSON_TYPES.GAME) {
        return {
          label: t('backoffice.courses.lessonsTab.content'),
          component: (
            <GameInput
              onSubmit={(game) =>
                setItem((prevItem) => ({ ...prevItem, game }))
              }
              currentGame={propsItem?.game || {}}
            />
          ),
        };
      }

      return {
        label: `${t(`backoffice.courses.lessonsTab.${tab}`)}${
          tabItems.length > 0 ? ` (${tabItems.length})` : ''
        }`,
        component: (
          <UploadWithList
            actions={listItemsActions}
            description={description}
            disabled={locked || submitting}
            height="100%"
            items={tabItems}
            key={tabItems.length}
            maxFiles={maxFiles}
            onStartUpload={onStartUpload}
            onUpload={(files) => handleUpload(tab, files)}
            uploadHeight="100%"
          />
        ),
      };
    });
  };

  const getLessonTypes = () => [
    {
      value: LESSON_TYPES.NOT_CHOSEN,
      key: LESSON_TYPES.NOT_CHOSEN,
      label: t('backoffice.courses.lessonsTab.notChosen'),
    },
    {
      value: LESSON_TYPES.VIDEO,
      key: LESSON_TYPES.VIDEO,
      label: t('backoffice.courses.lessonsTab.video'),
    },
    {
      value: LESSON_TYPES.GAME,
      key: LESSON_TYPES.GAME,
      label: t('backoffice.courses.lessonsTab.game'),
    },
  ];

  return (
    <SectionStyled>
      {type !== DRAWER_ACTIONS.ADD_TRAILER &&
        type !== DRAWER_ACTIONS.EDIT_TRAILER && (
          <HeaderWrapper>
            <TitleWrapper>
              {languages.length > 1 && (
                <LanguageWrapper>
                  <LanguageSelect
                    languages={languages}
                    onChange={({ value }) => setLanguage(value)}
                    required
                    value={language}
                  />
                </LanguageWrapper>
              )}
              <FormInput
                disabled={locked || submitting}
                id="title"
                key="title"
                label={t('backoffice.courses.lessonsTab.title')}
                name="title"
                handleChange={handleTitleChange}
                placeholder={t(
                  'backoffice.courses.lessonsTab.titlePlaceholder'
                )}
                properties={{
                  title: {
                    maxLength: 70,
                  },
                }}
                required
                showMaxLength
                theme="dark"
                value={selectTranslation(
                  item.nameTranslations,
                  '',
                  language,
                  ''
                )}
              />
            </TitleWrapper>
            {errors.length > 0 && (
              <ErrorWrapper>
                {errors.map((lang) => (
                  <T
                    color={theme.palette.errorDark}
                    fontWeight="semibold"
                    key={lang}
                    variant="paragraphS"
                  >
                    {t(`backoffice.courses.lessonsTab.${lang}Missing`)}
                  </T>
                ))}
              </ErrorWrapper>
            )}
          </HeaderWrapper>
        )}
      <TabWrapper>
        {type !== DRAWER_ACTIONS.ADD_TRAILER &&
        type !== DRAWER_ACTIONS.EDIT_TRAILER ? (
          <>
            {(type === DRAWER_ACTIONS.ADD_LESSON ||
              lessonType === LESSON_TYPES.NOT_CHOSEN) && (
              <SelectWrapper>
                <Select
                  fullWidth={false}
                  id="contentTypeSelect"
                  items={getLessonTypes()}
                  name="contentTypeSelect"
                  onChange={({ value }) => setLessonType(value)}
                  value={lessonType}
                />
              </SelectWrapper>
            )}
            {lessonType !== LESSON_TYPES.NOT_CHOSEN && (
              <FullWidthTabs
                centerTabs
                contentHeight="100%"
                onChangeTabHandler={(index) => setSelectedTab(index)}
                selectedTabIndex={selectedTab}
                tabsToSelect={getTabs()}
                theme="dark"
                wrapperStyle={`
                  display: flex;
                  flex-direction: column;
                `}
              />
            )}
          </>
        ) : (
          getTabs()[0].component
        )}
      </TabWrapper>
    </SectionStyled>
  );
};

DrawerLesson.propTypes = {
  course: PropTypes.object.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  item: PropTypes.shape({
    attachments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    game: PropTypes.object,
    id: PropTypes.string,
    nameTranslations: PropTypes.string,
    subtitleProcessingStatus: PropTypes.object,
    videoFiles: PropTypes.array,
    videoProcessingStatus: PropTypes.object,
  }),
  locked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  setChangedDrawerContent: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(DRAWER_ACTIONS)),
};

DrawerLesson.defaultProps = {
  locked: false,
  submitting: false,
};

export default DrawerLesson;

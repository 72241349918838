import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function FilterIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M14.7877 0H1.21167C0.810007 0 0.484375 0.325632 0.484375 0.72729V3.63645C0.484375 3.8414 0.570826 4.0368 0.722441 4.17465L5.81784 8.80642V15.2727C5.81784 15.5248 5.94831 15.7589 6.16272 15.8914C6.27957 15.9635 6.41213 16 6.54513 16C6.6563 16 6.76777 15.9745 6.87037 15.9232L9.77953 14.4686C10.0259 14.3454 10.1816 14.0936 10.1816 13.8181V8.80642L15.277 4.17465C15.4286 4.0368 15.515 3.8414 15.515 3.63645V0.72729C15.515 0.325632 15.1894 0 14.7877 0ZM8.96506 7.94652C8.81345 8.08437 8.727 8.27976 8.727 8.48471V13.3687L7.27242 14.096V8.48471C7.27242 8.27976 7.18597 8.08437 7.03435 7.94652L3.09297 4.36374H12.9064L8.96506 7.94652ZM14.0605 2.90916H1.93896V1.45458H14.0605V2.90916Z"
        fill="black"
      />
    </SvgIcon>
  );
}

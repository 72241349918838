import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { CalendarMonth } from '@mui/icons-material';

import FormInput from '@shared/atoms/FormFields/FormInput';
import FormSelect from '@shared/atoms/FormFields/FormSelect';
import IconButton from '@shared/atoms/IconButton';
import { BACKGROUND_THEMES } from '@shared/const';
import theme from '@shared/themes/default';

import DatePicker from './DatePicker';

const LineWrapper = styled.div`
  display: flex;
  column-gap: ${theme.spacing.m1};
  width: 100%;
`;

const PostCodeWrapper = styled.div`
  flex-basis: 20%;
`;

const CityWrapper = styled.div`
  flex-basis: 80%;
`;

const StartDateWrapper = styled.div`
  display: flex;
  column-gap: ${theme.spacing.m1};
  width: 100%;
  align-items: end;
`;

const InvoiceForm = ({
  errors,
  handleBlur,
  handleChange,
  properties,
  subscriptionPlans,
  theme: themeProp,
  values,
  showPurchasedLicenses,
  showStartDate,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleCloseDatePicker = () => setAnchorEl(null);
  const handleOpenDatePicker = (event) => setAnchorEl(event.currentTarget);

  const { t } = useTranslation();

  const periodTypes = [
    {
      value: 'none',
      key: 'none',
      label: t('generic.none'),
    },
    {
      value: 'Month',
      key: 'monthly',
      label: t('backoffice.organizations.createOrganization.invoiceMonthly'),
    },
    {
      value: 'Year',
      key: 'yearly',
      label: t('backoffice.organizations.createOrganization.invoiceYearly'),
    },
  ];

  const invoiceTypes = [
    {
      value: 'none',
      key: 'none',
      label: t('generic.none'),
    },
    {
      value: 'EHF',
      key: 'ehf',
      label: t('backoffice.organizations.createOrganization.invoiceEhf'),
    },
    {
      value: 'Email',
      key: 'email',
      label: t('backoffice.organizations.createOrganization.invoiceEmailSend'),
    },
    {
      value: 'Post',
      key: 'post',
      label: t('backoffice.organizations.createOrganization.invoicePost'),
    },
  ];

  return (
    <>
      {showStartDate && (
        <>
          <DatePicker
            anchorEl={anchorEl}
            handleClose={handleCloseDatePicker}
            handleSubmit={(value) => {
              handleChange({ name: 'startDate', value: value.toISOString() });
              handleCloseDatePicker();
            }}
            value={values.startDate}
          />
          <StartDateWrapper>
            <FormInput
              label={t('backoffice.organizations.subscription.startDate')}
              placeholder={t(
                'backoffice.organizations.subscription.startDatePlaceholder'
              )}
              id="startDate"
              name="startDate"
              properties={properties}
              theme={themeProp}
              disabled
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
              value={
                values.startDate
                  ? new Date(values.startDate).toDateString()
                  : ''
              }
            />
            <IconButton
              ariaLabel={t('backoffice.organizations.subscription.startDate')}
              onClick={handleOpenDatePicker}
              icon={CalendarMonth}
              sxIcon={{
                color: anchorEl ? theme.palette.brand : undefined,
              }}
              theme="dark"
              type="icon"
            />
          </StartDateWrapper>
        </>
      )}
      {showPurchasedLicenses && (
        <FormInput
          label={t('backoffice.organizations.subscription.purchasedLicenses')}
          placeholder={t(
            'backoffice.organizations.subscription.purchasedLicensesPlaceholder'
          )}
          id="purchasedLicenses"
          name="purchasedLicenses"
          properties={properties}
          theme={themeProp}
          errors={errors}
          handleBlur={handleBlur}
          handleChange={handleChange}
          values={values}
        />
      )}
      <FormInput
        label={t(
          'backoffice.organizations.createOrganization.organizationNumber'
        )}
        id="organizationNumber"
        name="organizationNumber"
        placeholder={t(
          'backoffice.organizations.createOrganization.organizationNumberPlaceholder'
        )}
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        properties={properties}
        values={values}
        theme={themeProp}
      />
      <FormInput
        label={t('backoffice.organizations.createOrganization.invoiceName')}
        id="invoiceName"
        name="invoiceName"
        placeholder={t(
          'backoffice.organizations.createOrganization.invoiceNamePlaceholder'
        )}
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        properties={properties}
        values={values}
        theme={themeProp}
      />
      <FormInput
        label={t('backoffice.organizations.createOrganization.invoiceEmail')}
        id="invoiceEmail"
        name="invoiceEmail"
        placeholder={t(
          'backoffice.organizations.createOrganization.invoiceEmailPlaceholder'
        )}
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        properties={properties}
        values={values}
        theme={themeProp}
      />
      <FormInput
        label={t('backoffice.organizations.createOrganization.invoiceAddress')}
        id="invoiceAddress"
        name="invoiceAddress"
        placeholder={t(
          'backoffice.organizations.createOrganization.invoiceAddressPlaceholder'
        )}
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        properties={properties}
        values={values}
        theme={themeProp}
      />
      <LineWrapper>
        <PostCodeWrapper>
          <FormInput
            label={t(
              'backoffice.organizations.createOrganization.invoicePostcode'
            )}
            id="invoiceZip"
            name="invoiceZip"
            placeholder={t(
              'backoffice.organizations.createOrganization.invoicePostcodePlaceholder'
            )}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            properties={properties}
            values={values}
            theme={themeProp}
          />
        </PostCodeWrapper>
        <CityWrapper>
          <FormInput
            label={t('backoffice.organizations.createOrganization.invoiceCity')}
            id="invoiceCity"
            name="invoiceCity"
            placeholder={t(
              'backoffice.organizations.createOrganization.invoiceCity'
            )}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            properties={properties}
            values={values}
            theme={themeProp}
          />
        </CityWrapper>
      </LineWrapper>
      <LineWrapper>
        <FormSelect
          label={t('backoffice.organizations.createOrganization.invoiceBy')}
          id="invoiceSendVia"
          name="invoiceSendVia"
          items={invoiceTypes}
          handleChange={handleChange}
          properties={properties}
          values={values}
          theme={themeProp}
        />
        <FormSelect
          label={t('backoffice.organizations.createOrganization.invoicePeriod')}
          id="invoicePeriod"
          name="invoicePeriod"
          items={periodTypes}
          handleChange={handleChange}
          properties={properties}
          values={values}
          theme={themeProp}
        />
      </LineWrapper>
      <FormInput
        label={t('backoffice.organizations.createOrganization.invoiceNote')}
        id="invoiceNote"
        name="invoiceNote"
        placeholder={t(
          'backoffice.organizations.createOrganization.invoiceNotePlaceholder'
        )}
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        maxRows={3}
        minRows={3}
        multiline
        properties={properties}
        values={values}
        theme={themeProp}
      />
      {subscriptionPlans.length > 0 && (
        <FormSelect
          label={t(
            'backoffice.organizations.createOrganization.subscriptionPlan'
          )}
          id="subscriptionPlan"
          name="subscriptionPlan"
          items={subscriptionPlans}
          handleChange={handleChange}
          properties={properties}
          values={values}
          theme={themeProp}
        />
      )}
    </>
  );
};

InvoiceForm.propTypes = {
  errors: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  properties: PropTypes.object.isRequired,
  subscriptionPlans: PropTypes.array,
  theme: PropTypes.oneOf(BACKGROUND_THEMES).isRequired,
  values: PropTypes.object.isRequired,
  showPurchasedLicenses: PropTypes.bool,
  showStartDate: PropTypes.bool,
};

InvoiceForm.defaultProps = {
  subscriptionPlans: [],
};

export default InvoiceForm;

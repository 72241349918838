import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useFormControl } from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import Icon from 'shared/atoms/Icon';
import T from 'shared/atoms/Typography';
import Tooltip from 'shared/atoms/Tooltip';
import { BACKGROUND_THEMES } from 'shared/const';
import theme from 'shared/themes/default';

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xs};
  justify-content: start;
  margin-bottom: ${theme.spacing.xs};
`;

const FormLabel = ({
  id,
  infoText,
  label,
  length,
  maxLength,
  theme: themeLayout,
}) => {
  const { t } = useTranslation();
  const { required } = useFormControl() || {};

  const sx = {
    color:
      themeLayout === 'light'
        ? theme.palette.mainDark
        : theme.palette.lightPure,
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '140%',
    position: 'relative',
    transform: 'none',
    '.MuiInputLabel-asterisk': {
      display: 'none',
    },
    '&.Mui-disabled': {
      color:
        themeLayout === 'light'
          ? theme.palette.mainDark
          : theme.palette.lightPure,
    },
    '&.Mui-focused': {
      color:
        themeLayout === 'light'
          ? theme.palette.mainDark
          : theme.palette.lightPure,
    },
    '&.Mui-error': {
      color:
        themeLayout === 'light'
          ? theme.palette.mainDark
          : theme.palette.lightPure,
    },
  };

  const getCharCounterColor = (count) => {
    if (count <= 0) {
      return themeLayout === 'light'
        ? theme.palette.errorDark
        : theme.palette.errorLight;
    }
    return themeLayout === 'light'
      ? theme.palette.disabledDark
      : theme.palette.disabledLight;
  };

  return (
    <LabelWrapper>
      {label && (
        <InputLabel htmlFor={id} sx={sx} shrink>
          {label}
          {!required && (
            <T
              color={
                themeLayout === 'light'
                  ? theme.palette.mainDark
                  : theme.palette.lightPure
              }
              component="span"
              fontWeight="medium"
              italic
            >
              {` (${t('generic.optional')})`}
            </T>
          )}
        </InputLabel>
      )}
      {infoText && (
        <Tooltip title={infoText} maxWidth="200px" arrow>
          <Icon
            icon={InfoOutlinedIcon}
            color={
              themeLayout === 'light'
                ? theme.palette.mainDark
                : theme.palette.lightPure
            }
            size="19px"
            sx={{
              cursor: 'pointer',
            }}
          />
        </Tooltip>
      )}
      {maxLength && (
        <T
          color={getCharCounterColor(maxLength - length)}
          fontWeight="medium"
          sx={{ marginLeft: 'auto' }}
          variant="helperText"
        >
          {t('generic.charactersLeft', { count: maxLength - length })}
        </T>
      )}
    </LabelWrapper>
  );
};

FormLabel.propTypes = {
  id: PropTypes.string.isRequired,
  infoText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  length: PropTypes.number,
  maxLength: PropTypes.number,
  theme: PropTypes.oneOf(BACKGROUND_THEMES),
};

export default FormLabel;

export const listCourses = (domain, nextToken) => ({
  query: `query listCourses(
    $filter: CourseFilterInput
    $ownerOrganizationId: String
    $showOrganizationCourses: Boolean
    $limit: Int
    $nextToken: String
  ) {
    listCourses(
      filter: $filter
      ownerOrganizationId: $ownerOrganizationId
      showOrganizationCourses: $showOrganizationCourses
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        nameTranslations
        archivedScheduledAt
      }
      nextToken
    }
  }`,
  variables: {
    ownerOrganizationId: '',
    showOrganizationCourses: false,
    limit: 500,
    nextToken,
    filter: {
      domain: {
        contains: domain,
      },
      status: {
        eq: 'PUBLISHED',
      },
    },
  },
});

export const listDomains = (nextToken) => ({
  query: `query listDomains(
    $limit: Int
    $nextToken: String
  ) {
    listDomains(limit: $limit, nextToken: $nextToken) {
      items {
        domain
        name
        metatag
        languages
        currency
        defaultLanguage
      }
      nextToken
    }
  }`,
  variables: {
    nextToken,
  },
});

export const listFeaturedItems = (domain) => ({
  query: `query listFeaturedItems(
    $domain: String
  ) {
    listFeaturedItems(domain: $domain) {
      id
      course {
        id
        name
        nameTranslations
      }
    }
  }`,
  variables: {
    domain,
  },
});

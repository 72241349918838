import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';

import List from './components/List';
import Panel from './components/Panel';

const StyledTabHeader = styled.nav`
  display: flex;
  padding: 0;
  list-style: none;
  margin: 1rem 0rem 1rem 0;
  flex-wrap: wrap;
  align-items: center;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: start;
  width: 100%;
  z-index: 9;
`;

const SimpleTabView = ({ children, matchUrl, onSwitch, selectedIndex }) => {
  const [filteredChildren, setFilteredChildren] = useState([]);
  const [activeTab, setActiveTab] = useState();
  const [headerButtons, setHeaderButtons] = useState([]);

  useEffect(() => {
    onSwitch && onSwitch({ index: selectedIndex });
  }, []);

  useEffect(() => {
    setFilteredChildren(
      children.filter((item) => !!item && !item.props.hidden)
    );
  }, [children]);

  useEffect(() => {
    const currentIndex = Math.max(
      filteredChildren.findIndex((item) => item.props.matchUrl === matchUrl),
      selectedIndex
    );

    setActiveTab(filteredChildren[currentIndex]);
    setHeaderButtons(
      filteredChildren.map((child, index) => ({
        key: child.props.title,
        component: child.props.component,
        url: child.props.matchUrl,
        index,
        active: index === currentIndex,
        title: child.props.title,
        disabled: child.props.disabled,
      }))
    );
  }, [filteredChildren, selectedIndex]);

  const showTab = (selection) => {
    onSwitch && onSwitch(selection);
  };

  const createButton = (item) => (
    <List
      key={item.title}
      onClick={(event) => {
        event.preventDefault();
        showTab({ url: item.url, index: item.index });
      }}
      active={item.active}
      title={item.title}
      disabled={item.disabled}
    />
  );

  return (
    <StyledWrapper>
      <FlexWrapper>
        <StyledTabHeader>
          {headerButtons.map((item) => createButton(item))}
        </StyledTabHeader>
        {activeTab}
      </FlexWrapper>
    </StyledWrapper>
  );
};

SimpleTabView.propTypes = {
  matchUrl: PropTypes.string,
  children: PropTypes.node.isRequired,
  selectedIndex: PropTypes.number,
  onSwitch: PropTypes.func,
};

SimpleTabView.defaultProps = {
  matchUrl: null,
  selectedIndex: 0,
};

SimpleTabView.Panel = Panel;

export default SimpleTabView;

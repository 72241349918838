import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

import Chip from '@shared/atoms/Chip';
import CircularSpinner from '@shared/atoms/CircularSpinner';
import HorizontalRule from '@shared/atoms/HorizontalRule';
import SearchField from '@shared/atoms/SearchField';
import Snackbar from '@shared/atoms/Snackbar';
import T from '@shared/atoms/Typography';
import Table, { CELL_TYPES, ORDER_TYPES } from '@shared/organisms/Table';
import theme from '@shared/themes/default';
import { logger } from '@shared/utils';
import { useAppGlobals } from '../../contexts/AppContext';

import UsersTableRowItem from './components/UsersTableRowItem';
import { listUsers } from './graphql/queries';
import { useGraphQLQuery } from '../../../utils';

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m1};
`;

const UsersView = () => {
  const { domain } = useAppGlobals();
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [users, setUsers] = useState([]);

  const { callQuery, data, errors, hasEnded, hasErrors, loading } =
    useGraphQLQuery();

  useEffect(() => {
    if (hasEnded) {
      if (hasErrors) {
        logger.error('Error: ', errors);
        setErrorMessage('Failed to load users');
        setHasError(true);
      } else {
        const {
          listUsers: { items },
        } = data;
        setUsers(items);
      }
    }
  }, [hasEnded]);

  const debouncedCall = useCallback(
    debounce((value) => {
      callQuery(listUsers(value, domain));
    }, 500),
    []
  );

  useEffect(() => {
    if (searchValue && searchValue.length > 2) {
      debouncedCall(searchValue);
    } else {
      setSelectedItemIds([]);
      setUsers([]);
    }
  }, [searchValue]);

  const toggleShowExpandedUser = (id) => {
    if (selectedItemIds.includes(id)) {
      setSelectedItemIds((items) => items.filter((itemId) => id !== itemId));
    } else {
      setSelectedItemIds((items) => [...items, id]);
    }
  };

  const onDeleteUser = (id) => {
    setSelectedItemIds((items) => items.filter((itemId) => id !== itemId));
    setUsers([...users.filter((user) => user.userSub !== id)]);
  };

  return (
    <OuterWrapper>
      <T fontWeight="medium" variant="headingS">
        {t('backoffice.users.users')}
      </T>
      <HorizontalRule />
      <SearchField
        ariaLabel={t('backoffice.users.search')}
        disabled={loading}
        onChange={({ value }) => setSearchValue(value)}
        onClear={() => setSearchValue('')}
        placeholder={t('backoffice.users.search')}
        theme="dark"
        type="primary"
        value={searchValue}
      />
      {loading && <CircularSpinner />}
      {users.length > 0 && !loading && (
        <Table
          ariaLabel="Users table"
          columns={[
            {
              key: 'name',
              label: t('backoffice.users.name'),
              type: CELL_TYPES.COMPONENT,
              render: (row) => (
                <>
                  {row.name}
                  {!row.userSub && row.invitation && (
                    <Chip
                      label={t('backoffice.users.notRegistered')}
                      small
                      sx={{ marginLeft: theme.spacing.m1 }}
                      theme="dark"
                    />
                  )}
                </>
              ),
            },
            {
              key: 'email',
              label: t('backoffice.users.email'),
            },
          ]}
          items={users}
          keyField="userSub"
          defaultOrder={ORDER_TYPES.ASC}
          defaultOrderBy="name"
          rowIsClickable={() => true}
          selectedItemIds={selectedItemIds}
          onRowClick={(row) => toggleShowExpandedUser(row.userSub)}
          ExpandedItemComponent={UsersTableRowItem}
          expandedItemComponentProps={{
            onDeleteUser,
            setErrorMessage,
            setHasError,
            setHasSuccess,
            setSuccessMessage,
          }}
        />
      )}
      {users.length === 0 && !loading && (
        <T fontWeight="medium" variant="subtitle">
          {t('backoffice.users.noUsersFound')}
        </T>
      )}
      <Snackbar
        type="success"
        message={successMessage}
        open={hasSuccess}
        onClose={() => setHasSuccess(false)}
      />
      <Snackbar
        type="error"
        message={errorMessage}
        open={hasError}
        onClose={() => setHasError(false)}
      />
    </OuterWrapper>
  );
};

export default UsersView;

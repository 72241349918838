import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Form from '@shared/atoms/Form';
import FormInput from '@shared/atoms/FormFields/FormInput';
import Snackbar from '@shared/atoms/Snackbar';
import Dialog from '@shared/molecules/Dialog';
import { useForm, logger } from '@shared/utils';
import theme from '@shared/themes/default';

const CreateCourseDialog = ({
  open,
  onClose,
  createCourseRequest,
  onCreate,
}) => {
  const [hasError, setHasError] = useState(false);

  const { t } = useTranslation();

  const handleError = (error) => {
    logger.error('Some error happened while creating course', error);
    setHasError(true);
  };

  const onSubmitHandler = async () => {
    try {
      const { data, error } = await createCourseRequest({
        // eslint-disable-next-line no-use-before-define
        name: values.courseName,
      });
      if (error) {
        return handleError(error);
      }
      if (data?.createCourse) {
        onCreate(data.createCourse.id);
      }
    } catch (error) {
      handleError(error);
    }
    return null;
  };

  const {
    disableSubmit,
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
    properties,
    submitting,
    values,
  } = useForm({
    fields: {
      courseName: {
        default: '',
        disableTrim: true,
        required: true,
        type: 'text',
        maxLength: 70,
      },
    },
    onSubmit: onSubmitHandler,
  });

  const actions = [
    {
      label: t('generic.cancel'),
      ariaLabel: t('generic.cancel'),
      type: 'secondaryOutline',
      handler: onClose,
    },
    {
      label: t('backoffice.courses.createDraft'),
      ariaLabel: t('backoffice.courses.createDraft'),
      handler: handleSubmit,
      disabled: disableSubmit,
    },
  ];

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        actions={actions}
        title={t('backoffice.courses.createCourse')}
        ariaLabel={t('backoffice.courses.createCourse')}
        contentPadding={theme.spacing.m1}
        loading={submitting}
      >
        <Form id="create-course-form" onSubmit={handleSubmit}>
          <FormInput
            id="courseName"
            name="courseName"
            placeholder={t('backoffice.courses.courseTitlePlaceholder')}
            label={t('backoffice.courses.courseTitle')}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            properties={properties}
            showMaxLength
            theme="light"
            values={values}
            required
          />
        </Form>
      </Dialog>
      <Snackbar
        type="error"
        message={t('backoffice.courses.creationFailed')}
        open={hasError}
        onClose={() => setHasError(false)}
      />
    </>
  );
};

CreateCourseDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  createCourseRequest: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};

export default CreateCourseDialog;

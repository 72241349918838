import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isValidEmail } from './emailValidator';

const compareValue = (valueA, valueB) => {
  if (valueA === valueB) return true;

  const typeA = typeof valueA;
  const typeB = typeof valueB;

  if (typeA !== typeB) return false;

  if (typeA === 'object')
    return JSON.stringify(valueA) === JSON.stringify(valueB);

  return valueA === valueB;
};

const getProcessedFields = (fields, locales, requiredLocales) =>
  Object.entries(fields).reduce((acc, [key, value]) => {
    if (value.localised && locales?.length > 0) {
      locales.forEach((locale) => {
        if (
          value.required &&
          requiredLocales &&
          !requiredLocales.includes(locale)
        ) {
          acc[`${key}#${locale}`] = {
            ...value,
            required: false,
          };
        } else {
          acc[`${key}#${locale}`] = value;
        }
      });
    } else {
      acc[key] = value;
    }
    return acc;
  }, {});

const getProperties = (processedFields) =>
  Object.entries(processedFields).reduce((acc, [key, value]) => {
    acc[key] = {
      maxLength: value.maxLength,
      mode: value.mode,
      pattern: value.pattern,
      required: value.required,
      type: value.type,
    };
    return acc;
  }, {});

const normalize = (value, field) => {
  let processedValue = value;

  if (typeof value === 'string') {
    if (field.lowerCase) {
      processedValue = processedValue.toLowerCase();
    }
    if (field.upperCase) {
      processedValue = processedValue.toUpperCase();
    }

    if (!field.disableTrim) {
      processedValue = processedValue.trim();
    }
  }

  return processedValue;
};

const useForm = ({
  changesRequired,
  fields = {},
  locales,
  onSubmit,
  onSubmitHook, // Alternative for onSubmit to be used for hook based queries
  onSubmitHookEnded, // hasEnded variable that comes with the hook
  requiredLocales,
}) => {
  const [processedFields, setProcessedFields] = useState(
    getProcessedFields(fields, locales, requiredLocales)
  );
  const [defaultValues, setDefaultValues] = useState(
    Object.entries(processedFields).reduce((acc, [key, value]) => {
      // TODO: Differentiate between inputs and checkboxes/switches
      acc[key] = 'default' in value ? value.default : '';
      return acc;
    }, {})
  );
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [edited, setEdited] = useState(false);
  const [errors, setErrors] = useState({});
  const [missingRequired, setMissingRequired] = useState(true);
  const [properties, setProperties] = useState(getProperties(processedFields));
  const [submitting, setSubmitting] = useState(false);
  const [values, setValues] = useState(defaultValues);

  const { t } = useTranslation();

  useEffect(() => {
    setProperties(getProperties(processedFields));
  }, [processedFields]);

  useEffect(() => {
    const { missingRequired, noModified } = Object.entries(
      processedFields
    ).reduce(
      (acc, [key, value]) => {
        if (value.required && !values[key]) {
          acc.missingRequired = true;
        }
        if (!compareValue(values[key], defaultValues[key])) {
          acc.noModified = false;
        }
        return acc;
      },
      { missingRequired: false, noModified: true }
    );

    setEdited(!noModified);
    setMissingRequired(missingRequired);
    setDisableSubmit(missingRequired || (changesRequired && noModified));
  }, [processedFields, values]);

  const enableSubmit = () => {
    setSubmitting(false);
    setProperties((prevProperties) =>
      Object.entries(prevProperties).reduce((acc, [key, value]) => {
        const { disabled, ...other } = value;
        acc[key] = {
          ...other,
        };
        return acc;
      }, {})
    );
  };

  useEffect(() => {
    if (onSubmitHookEnded) {
      enableSubmit();
    }
  }, [onSubmitHookEnded]);

  const validateField = (field, value) => {
    if (!field.required && !value) {
      return;
    }

    const validationFunction =
      field.validation || (field.type === 'email' && isValidEmail);

    if (validationFunction && !validationFunction(value)) {
      return field.validationMessage || t('errors.formValidationError');
    }
  };

  const validate = () => {
    const { errors, hasErrors, processedValues } = Object.entries(
      processedFields
    ).reduce(
      (acc, [name, field]) => {
        const processedValue = normalize(values[name], field);

        if (name in fields) {
          acc.processedValues[name] = processedValue;
        } else {
          const [originalName, locale] = name.split('#');
          if (originalName in fields && locales.includes(locale)) {
            if (!acc.processedValues[originalName]) {
              acc.processedValues[originalName] = {};
            }
            acc.processedValues[originalName][locale] = processedValue;
          }
        }

        const error = validateField(field, processedValue);
        if (error) {
          acc.errors[name] = error;
          acc.hasErrors = true;
        }

        return acc;
      },
      { errors: {}, hasErrors: false, processedValues: {} }
    );

    setErrors(errors);
    return { valid: !hasErrors, processedValues };
  };

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }

    const { valid, processedValues } = validate();

    if (valid && !disableSubmit) {
      setSubmitting(true);
      // Disable all fields while the form is being submitted
      setProperties((prevProperties) =>
        Object.entries(prevProperties).reduce((acc, [key, value]) => {
          acc[key] = {
            ...value,
            disabled: true,
          };
          return acc;
        }, {})
      );

      if (onSubmitHook) {
        onSubmitHook(processedValues);
      } else {
        try {
          await onSubmit(processedValues);
        } finally {
          enableSubmit();
        }
      }
    }
  };

  const handleBlur = ({ name }) => {
    const field = processedFields[name];
    const processedValue = normalize(values[name], field);

    if (processedValue) {
      const error = validateField(field, processedValue);
      if (error) {
        setErrors({ ...errors, [name]: error });
      }
    }
  };

  const handleChange = ({ name, value }) => {
    const { [name]: error, ...otherErrors } = errors;
    setErrors({ ...otherErrors });
    setValues({ ...values, [name]: value });
  };

  const changeValues = (newValues) => {
    setValues((values) => {
      const changedValues = Object.entries(newValues).reduce(
        (acc, [key, value]) => {
          if (key in fields) {
            if (fields[key].localised) {
              Object.entries(value).forEach(([locale, newValue]) => {
                const newKey = `${key}#${locale}`;
                if (newKey in values) {
                  acc[newKey] = newValue;
                }
              });
            } else {
              acc[key] = value;
            }
          }
          return acc;
        },
        {}
      );
      return { ...values, ...changedValues };
    });
    setErrors({});
  };

  const setDefaults = (newValues) => {
    setDefaultValues((values) => {
      const changedValues = Object.entries(newValues).reduce(
        (acc, [key, value]) => {
          if (key in fields) {
            if (fields[key].localised) {
              Object.entries(value).forEach(([locale, newValue]) => {
                const newKey = `${key}#${locale}`;
                if (newKey in values) {
                  acc[newKey] = newValue;
                }
              });
            } else {
              acc[key] = value;
            }
          }
          return acc;
        },
        {}
      );
      return { ...values, ...changedValues };
    });
    changeValues(newValues);
  };

  const setRequiredLocales = (newRequiredLocales) => {
    setProcessedFields(getProcessedFields(fields, locales, newRequiredLocales));
  };

  return {
    changeValues,
    disableSubmit,
    edited,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    missingRequired,
    properties,
    setDefaults,
    setRequiredLocales,
    submitting,
    values,
  };
};

export default useForm;

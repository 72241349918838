import styled from 'styled-components';

import theme from 'shared/themes/default';

export const Spacer = styled.div`
  height: ${theme.spacing.m1};
`;

export const SideSpaceWrapper = styled.div`
  padding: 0 1rem;
  display: block !important;
`;

export const EmptyListWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ListWrapper = styled.div`
  margin-top: ${theme.spacing.m1};
  display: flex;
  flex-direction: column;
`;

export const NotSharedWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 450px;
`;

export const NotSharedInnerWrapper = styled.div`
  max-width: 250px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ShareIconWrapper = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: ${theme.palette.brand};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
`;

export const SharingOptionsList = styled.ul`
  margin-top: 1.5rem;
  padding: 0;
`;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const AnimationWrapper = styled.div`
  animation: ${(props) => (props.show ? fadeIn : fadeOut)} 1s;
  height: 100%;
  ${(props) =>
    !props.show &&
    `
    position: absolute;
    width: 100%;
  `}
`;

const Fade = ({ show, children }) => {
  const [shouldRender, setRender] = useState(show);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };

  return (
    shouldRender && (
      <AnimationWrapper show={show} onAnimationEnd={onAnimationEnd}>
        {children}
      </AnimationWrapper>
    )
  );
};

Fade.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default Fade;

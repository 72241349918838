import React from 'react';

import PageTemplate from '../components/templates/PageTemplate';
import ExpertsView from '../components/organisms/ExpertsView';

const ExpertsContainer = () => (
  <PageTemplate>
    <ExpertsView />
  </PageTemplate>
);

export default ExpertsContainer;

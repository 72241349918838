export const getOrganization = `query getOrganization(
  $id: ID!
) {
  getOrganization(id: $id) {
    id
    name
  }
}`;

export const listUsersWatchData = (organizationId, nextToken) => ({
  query: `query listUsersWatchData(
    $organizationId: ID!
    $limit: Int
    $nextToken: String
  ) {
    listUsersWatchData(
      organizationId: $organizationId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        playlistsCompleted
        coursesCompleted
        episodesCompleted
        certificatesEarned
      }
      nextToken
    }
  }`,
  variables: {
    organizationId,
    limit: 500,
    nextToken,
  },
});

export const getUserWatchData = (organizationId, userId, nextToken) => ({
  query: `query getUserWatchData(
    $organizationId: ID!
    $userId: ID!
    $limit: Int
    $nextToken: String
  ) {
    getUserWatchData(
      organizationId: $organizationId
      userId: $userId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nameTranslations
        episodesCompleted
        episodesCount
        firstTimeWatched
        completedTime
        certificationTime
        status
      }
      nextToken
    }
  }`,
  variables: {
    organizationId,
    userId,
    limit: 500,
    nextToken,
  },
});

export const listCoursesWatchData = (organizationId, nextToken) => ({
  query: `query listCoursesWatchData(
    $organizationId: ID!
    $limit: Int
    $nextToken: String
  ) {
    listCoursesWatchData(
      organizationId: $organizationId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        nameTranslations
        viewsCount
        completedCount
        averageCompletion
        certificatesIssued
        status
      }
      nextToken
    }
  }`,
  variables: {
    organizationId,
    limit: 100,
    nextToken,
  },
});

export const getCourseWatchData = (organizationId, courseId, nextToken) => ({
  query: `query getCourseWatchData(
    $organizationId: ID!
    $courseId: ID!
    $limit: Int
    $nextToken: String
  ) {
    getCourseWatchData(
      organizationId: $organizationId
      courseId: $courseId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        episodesCompleted
        episodesCount
        firstTimeWatched
        completedTime
        certificationTime
      }
      nextToken
    }
  }`,
  variables: {
    organizationId,
    courseId,
    limit: 500,
    nextToken,
  },
});

export const listPlaylistsWatchData = (organizationId, nextToken) => ({
  query: `query listPlaylistsWatchData(
      $organizationId: ID!
      $limit: Int
      $nextToken: String
    ) {
      listPlaylistsWatchData(
        organizationId: $organizationId
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          titleTranslations
          ownerId
          ownerName
          coursesCount
          viewsCount
          completedCount
          averageCompletion
          shareType
          lastReminderAt
        }
        nextToken
      }
    }`,
  variables: {
    organizationId,
    limit: 2,
    nextToken,
  },
});

export const listNanodegreesWatchData = (organizationId) => ({
  query: `query listNanodegreesWatchData(
      $organizationId: ID
    ) {
      listNanodegreesWatchData(
        organizationId: $organizationId
      ) {
        id
        titleTranslations
        coursesCount
        started
        graduated
        averageCompletion
        status
      }
    }`,
  variables: {
    organizationId,
  },
});

export const listNanodegreeStatsWatchData = (playlistId) => ({
  query: `query listNanodegreeStatsWatchData(
      $playlistId: ID!
    ) {
      listNanodegreeStatsWatchData(
        playlistId: $playlistId
      ) {
        id
        name
        started
        graduated
        averageCompletion
      }
    }`,
  variables: {
    playlistId,
  },
});

export const listPlaylistUsersWatchData = (
  organizationId,
  playlistId,
  groupId,
  nextToken
) => ({
  query: `query listPlaylistUsersWatchData(
      $playlistId: ID!
      $organizationId: ID!
      $groupId: ID
      $limit: Int
      $nextToken: String
    ) {
      listPlaylistUsersWatchData(
        playlistId: $playlistId
        organizationId: $organizationId
        groupId: $groupId
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          name
          email
          progress
          invitedAt
          lastReminderAt
        }
        nextToken
      }
    }`,
  variables: {
    playlistId,
    organizationId,
    groupId,
    limit: 100,
    nextToken,
  },
});

export const listPlaylistGroupsWatchData = (
  organizationId,
  playlistId,
  nextToken
) => ({
  query: `query listPlaylistGroupsWatchData(
    $playlistId: ID!
    $organizationId: ID!
    $limit: Int
    $nextToken: String
  ) {
    listPlaylistGroupsWatchData(
      playlistId: $playlistId
      organizationId: $organizationId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        ownedBy
        ownedByName
        sharedAt
        membersCount
      }
      nextToken
    }
  }
  `,
  variables: {
    playlistId,
    organizationId,
    limit: 2,
    nextToken,
  },
});

export const getUserPlaylistWatchData = (
  organizationId,
  userId,
  playlistId
) => ({
  query: `query getUserPlaylistWatchData(
    $userId: ID!
    $playlistId: ID!
    $organizationId: ID!
  ) {
    getUserPlaylistWatchData(
      userId: $userId
      playlistId: $playlistId
      organizationId: $organizationId
    ) {
      items {
        nameTranslations
        episodesCompleted
        episodesCount
        firstTimeWatched
        completedTime
        certificationTime
        id
        status
      }
    }
  }`,
  variables: {
    userId,
    playlistId,
    organizationId,
  },
});

export const downloadWatchData = (organizationId, input) => ({
  query: `query downloadWatchData($input: ExportWatchDataInput!) {
    downloadWatchData(input: $input) {
      cloudfrontUrl
      fileName
    }
  }`,
  variables: {
    input: {
      organizationId,
      ...input,
    },
  },
});

export const listGroupsWatchData = (organizationId, nextToken) => ({
  query: `query listGroupsWatchData(
    $organizationId: ID!
    $limit: Int
    $nextToken: String
  ) {
    listGroupsWatchData(
      organizationId: $organizationId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        ownedBy
        ownedByName
        membersCount
        playlistsCompleted
        coursesCompleted
        episodesCompleted
        certificatesEarned
      }
      nextToken
    }
  }`,
  variables: {
    organizationId,
    limit: 500,
    nextToken,
  },
});

export const listGroupUsersWatchData = (
  organizationId,
  groupId,
  nextToken
) => ({
  query: `query listGroupUsersWatchData(
    $organizationId: ID!
    $groupId: ID!
    $limit: Int
    $nextToken: String
  ) {
    listGroupUsersWatchData(
      organizationId: $organizationId
      groupId: $groupId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        playlistsCompleted
        coursesCompleted
        episodesCompleted
        certificatesEarned
      }
      nextToken
    }
  }`,
  variables: {
    organizationId,
    groupId,
    limit: 500,
    nextToken,
  },
});

export const listOrganizationsWatchData = (nextToken) => ({
  query: `query listOrganizationsWatchData(
    $limit: Int
    $nextToken: String
  ) {
    listOrganizationsWatchData(
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        registeredMembers
        playlistsCompleted
        coursesCompleted
        episodesCompleted
        certificatesEarned
      }
      nextToken
    }
  }`,
  variables: {
    limit: 500,
    nextToken,
  },
});

export const listCoursesWithGlobalWatchData = (nextToken) => ({
  query: `query listCoursesWithGlobalWatchData(
    $limit: Int
    $nextToken: String
  ) {
    listCoursesWithGlobalWatchData(
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        nameTranslations
        viewsCount
        completedCount
        averageCompletion
        certificatesIssued
        ownerOrganizationName
        status
      }
      nextToken
    }
  }`,
  variables: {
    limit: 500,
    nextToken,
  },
});

export const getCourseWithGlobalWatchData = (courseId, nextToken) => ({
  query: `query getCourseWithGlobalWatchData(
    $courseId: ID!
    $limit: Int
    $nextToken: String
  ) {
    getCourseWithGlobalWatchData(
      courseId: $courseId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        episodesCompleted
        episodesCount
        firstTimeWatched
        completedTime
        certificationTime
      }
      nextToken
    }
  }`,
  variables: {
    courseId,
    limit: 500,
    nextToken,
  },
});

export const listAggregatedWatchData = (input) => ({
  query: `query listAggregatedWatchData($input: AggregatedWatchDataInput!) {
    listAggregatedWatchData(input: $input) {
      date
      count
    }
  }`,
  variables: {
    input: {
      ...input,
    },
  },
});

export const listOrganizationsNames = `query listOrganizations(
  $domain: String!
  $nextToken: String
) {
  listOrganizations (domain: $domain, limit: 1000, nextToken: $nextToken) {
    items {
      id
      domain
      name
      archived
    }
    nextToken
  }
}`;

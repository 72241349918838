import React from 'react';
import { useLocation } from 'react-router-dom';

import { Auth } from 'aws-amplify';
import { logger } from '@shared/utils';

import SharedHeader from '@shared/organisms/Header';
import { useAppGlobals } from '../../contexts/AppContext';
import { headerOptions } from '../../../utils';

const Header = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const redirectParam = searchParams.get('redirect');

  const { domain, user } = useAppGlobals();

  const getLoginUrl = () => {
    if (redirectParam) {
      return `/login?redirect=${encodeURIComponent(redirectParam)}`;
    }
    return '/login';
  };

  const getRedirectionUrl = (redirectionDomain) => {
    const { protocol, hostname, port, pathname } = window.location;
    const splitDomain = hostname.split('.');

    // Will redirect to itself for localhost - useful to verify it works
    if (splitDomain.length > 1) {
      splitDomain.pop();
      splitDomain.push(redirectionDomain);
    }
    return `${protocol}//${splitDomain.join('.')}${
      port ? `:${port}` : ''
    }${pathname}`;
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
    } catch (e) {
      logger.error('There was an error when trying to sign user out: ', e);
    }
  };

  const options = headerOptions({ user });

  return (
    <SharedHeader
      adminOptions={[]}
      domain={domain}
      homepageUrl="/"
      languages={['no', 'es']}
      loginUrl={getLoginUrl()}
      onLanguageChange={({ value }) =>
        window.location.assign(getRedirectionUrl(value))
      }
      onSignOut={signOut}
      options={options.filter((o) => o.conditionalRender)}
      showLanguage
      showProfile
      selectedLanguage={domain}
      user={user}
    />
  );
};

export default Header;

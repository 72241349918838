import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MuiRadio from '@mui/material/Radio';
import MuiRadioGroup from '@mui/material/RadioGroup';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import MuiFormControl from '@mui/material/FormControl';
import MuiFormLabel from '@mui/material/FormLabel';
import { BACKGROUND_THEMES } from 'shared/const';
import theme from 'shared/themes/default';
import T from 'shared/atoms/Typography';

const RadioAndLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RadioGroup = ({
  defaultValue,
  labelText,
  onChange,
  options,
  outlined,
  sx,
  theme: themeLayout,
  visibility,
  ...props
}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const handleChange = (event) => {
    const { value } = event.target;
    setValue(value);
    onChange(value);
  };

  let selectedColor;

  if (themeLayout === 'light') {
    selectedColor = theme.palette.mainDark;
  } else if (visibility === 'high') {
    selectedColor = theme.palette.brand;
  } else {
    selectedColor = theme.palette.lightPure;
  }

  const sxLabel = {
    color:
      themeLayout === 'dark' ? theme.palette.lightPure : theme.palette.mainDark,
    marginBottom: theme.spacing.s,
  };

  const sxRadioLabel = {
    marginLeft: outlined ? 0 : `-${theme.spacing.xs}`,
    color:
      themeLayout === 'dark' ? theme.palette.lightPure : theme.palette.mainDark,
  };

  const sxCheckedLabel = {
    '& .MuiFormControlLabel-label': {
      fontWeight: 600,
      color: selectedColor,
    },
  };

  if (outlined) {
    sxRadioLabel.borderRadius = '6px';
    sxRadioLabel.border = `1px solid ${theme.palette.disabledLight}`;
    sxRadioLabel.marginRight = 0;
    sxRadioLabel.padding = theme.spacing.m1;
    sxRadioLabel.width = '100%';
    sxCheckedLabel.backgroundColor =
      themeLayout === 'dark'
        ? theme.palette.darkWrapper
        : theme.palette.lightDistinct;
  }

  const sxRadio = {
    color:
      themeLayout === 'dark' ? theme.palette.lightPure : theme.palette.mainDark,
    marginRight: outlined ? theme.spacing.m1 : theme.spacing.xs,
    padding: theme.spacing.xs,
    '&.Mui-checked': {
      color: selectedColor,
    },
    '&:hover, &:focus, &:active': {
      color:
        themeLayout === 'dark' ? theme.palette.brand : theme.palette.mainDark,
    },
  };

  return (
    <MuiFormControl sx={{ width: '100%' }}>
      {labelText && (
        <MuiFormLabel id="radio-buttons-group-label" sx={sxLabel}>
          {labelText}
        </MuiFormLabel>
      )}
      <MuiRadioGroup
        aria-labelledby="radio-buttons-group-label"
        name="radio-buttons-group"
        onChange={handleChange}
        sx={{ ...sx, ...(outlined ? { gap: theme.spacing.s } : {}) }}
        value={value}
        {...props}
      >
        {options.map((option) => (
          <RadioAndLabelWrapper key={option.value}>
            <MuiFormControlLabel
              sx={{
                ...sxRadioLabel,
                ...(option.value === value ? sxCheckedLabel : {}),
              }}
              value={option.value}
              control={<MuiRadio sx={sxRadio} />}
              label={option.label}
            />
            {option.secondaryLabel && (
              <T variant="paragraphS" color={theme.palette.disabledLight}>
                {option.secondaryLabel}
              </T>
            )}
          </RadioAndLabelWrapper>
        ))}
      </MuiRadioGroup>
    </MuiFormControl>
  );
};

RadioGroup.propTypes = {
  defaultValue: PropTypes.string,
  labelText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  outlined: PropTypes.bool,
  sx: PropTypes.object,
  theme: PropTypes.oneOf(BACKGROUND_THEMES),
  visibility: PropTypes.oneOf(['high', 'low']),
};

RadioGroup.defaultProps = {
  outlined: false,
  sx: {},
  theme: 'dark',
  visibility: 'high',
};

export default RadioGroup;

export const listCategories = ({ domain, nextToken }) => ({
  name: 'listCategories',
  query: `query listCategories(
    $filter: CategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ordinal
        titleTranslations
      }
      nextToken
    }
  }`,
  variables: {
    filter: {
      domain: {
        contains: domain,
      },
    },
    limit: 500,
    nextToken,
  },
});

export const listExperts = ({ domain, nextToken }) => ({
  name: 'listExperts',
  query: `query listExperts(
    $filter: ExpertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExperts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstname
        lastname
      }
      nextToken
    }
  }`,
  variables: {
    filter: {
      domain: {
        contains: domain,
      },
    },
    limit: 500,
    nextToken,
  },
});

import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';

import SnapChatIcon from '@shared/atoms/Icon/svg/snapchat.svg?react';
import theme from '@shared/themes/default';

import DynamicInputList from '../DynamicInputList';

const Wrapper = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m3};
  padding: ${theme.spacing.s};
`;

const LinksTab = ({ handleChange, values }) => {
  const [websites, setWebsites] = useState([]);
  const [socialLinks, setSocialLinks] = useState([]);

  const { t } = useTranslation();

  const types = [
    {
      type: 'facebook',
      label: 'Facebook',
      icon: FacebookIcon,
    },
    {
      type: 'instagram',
      label: 'Instagram',
      icon: InstagramIcon,
    },
    {
      type: 'linkedin',
      label: 'Linkedin',
      icon: LinkedInIcon,
    },
    {
      type: 'snapchat',
      label: 'Snapchat',
      svgIcon: SnapChatIcon,
    },
    {
      type: 'twitter',
      label: 'X',
      icon: XIcon,
    },
    {
      type: 'youtube',
      label: 'Youtube',
      icon: YouTubeIcon,
    },
  ].map((item) => ({
    ...item,
    placeholder: t('backoffice.experts.linkPlaceholder', {
      website: item.label,
    }),
  }));

  const handleWebsitesChange = (newWebsites) => {
    handleChange({
      name: 'homePage',
      value: newWebsites
        .reduce((acc, curr) => {
          if (curr.value) acc.push(curr.value);
          return acc;
        }, [])
        .join(' ; '),
    });
    setWebsites(newWebsites);
  };

  const handleSocialLinksChange = (newSocialLinks) => {
    handleChange({
      name: 'socialLinks',
      value: newSocialLinks,
    });
    setSocialLinks(newSocialLinks);
  };

  useEffect(() => {
    if (values.homePage) {
      const pages = values.homePage.split(';').map((page) => page.trim());
      setWebsites(pages.map((page) => ({ id: page, value: page })));
    }

    if (values.socialLinks) {
      const socialLinks = values.socialLinks.map((link) => ({
        ...link,
        type: link.type.toLowerCase(),
      }));
      setSocialLinks(socialLinks);
    }
  }, []);

  return (
    <Wrapper>
      <DynamicInputList
        title={t('backoffice.experts.websites')}
        emptyLabel={t('backoffice.experts.noWebsites')}
        types={[
          {
            type: 'website',
            placeholder: t('backoffice.experts.websitePlaceholder'),
          },
        ]}
        inputs={websites}
        onChange={handleWebsitesChange}
      />
      <DynamicInputList
        title={t('backoffice.experts.socialMedia')}
        emptyLabel={t('backoffice.experts.noSocialMedia')}
        types={types}
        inputs={socialLinks.map((link) => ({
          ...link,
          label: types.find((t) => t.type === link.type).label,
        }))}
        onChange={handleSocialLinksChange}
      />
    </Wrapper>
  );
};

LinksTab.propTypes = {
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default LinksTab;

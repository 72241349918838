import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';

import LoginPassword from '@shared/organisms/Registration/LoginPassword';
import LoginUsername from '@shared/organisms/Registration/LoginUsername';
import Modal from '@shared/organisms/Registration/Modal';

import { logger } from '@shared/utils';

const authErrorToTranslationKey = (error) => {
  switch (error.code) {
    case 'UserNotFoundException':
    case 'NotAuthorizedException':
      return 'registration.authenticationErrors.emailOrPasswordIncorrect';
    default:
      return 'registration.authenticationErrors.errorOccured';
  }
};

const AUTHENTICATION_STATUS = {
  LOGIN_PASSWORD: 'LOGIN_PASSWORD',
  LOGIN_USERNAME: 'LOGIN_USERNAME',
};

// Reference for the flow (apart from Amplify login and registration components):
// https://docs.aws.amazon.com/cognito/latest/developerguide/signing-up-users-in-your-app.html

const Authentication = ({ redirect }) => {
  const [authData, setAuthData] = useState({});
  const [authState, setAuthState] = useState(
    AUTHENTICATION_STATUS.LOGIN_USERNAME
  );
  const [errorMessage, setErrorMessage] = useState();

  const { t } = useTranslation();

  const onLoginUsername = ({ email }) => {
    setAuthState(AUTHENTICATION_STATUS.LOGIN_PASSWORD);
    setAuthData({ email });
  };

  const onLogin = async ({ password }) => {
    try {
      const user = await Auth.signIn(authData.email, password);

      if (user.challengeName) {
        setErrorMessage(t('registration.authenticationErrors.errorOccured'));
      } else {
        // Copy of checkContact from Amplify sign in component
        const verificationData = await Auth.verifiedContact(user);

        if (Object.keys(verificationData.verified).length === 0) {
          // Users should only get there if they are confirmed by an administrator, as confirming by themselves
          // verifies the email - should never happen
          setErrorMessage(t('registration.authenticationErrors.notVerified'));
        } else {
          redirect();
        }
      }
    } catch (error) {
      // TODO: Errors after the actual login action should be handled differently
      logger.error('Error while logging in', error);
      setErrorMessage(t(authErrorToTranslationKey(error)));
    }
  };

  useEffect(() => {
    setErrorMessage();
  }, [authState]);

  const component = () => {
    switch (authState) {
      case AUTHENTICATION_STATUS.LOGIN_USERNAME:
        return (
          <LoginUsername
            errorMessage={errorMessage}
            onSubmit={onLoginUsername}
          />
        );
      case AUTHENTICATION_STATUS.LOGIN_PASSWORD:
        return (
          <LoginPassword
            errorMessage={errorMessage}
            onCancel={() => {
              setAuthState(AUTHENTICATION_STATUS.LOGIN_USERNAME);
              setAuthData({});
            }}
            onSubmit={onLogin}
          />
        );
      default:
        return null;
    }
  };

  return <Modal body={component()} />;
};

Authentication.propTypes = {
  redirect: PropTypes.func.isRequired,
};

export default Authentication;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@shared/themes/default';
import Header from '../../organisms/Header';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  width: 100%;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
`;

const Content = styled.section`
  flex-grow: 1;
  max-width: 1532px;
  margin: ${theme.spacing.m1} auto 0;
  padding: 0 ${theme.spacing.m1};
  width: 100%;
`;

const Footer = styled.div`
  min-height: ${theme.spacing.m1};
`;

const PageTemplate = ({ children }) => {
  return (
    <Wrapper>
      <Header />
      <InnerWrapper>
        <Content>{children}</Content>
        <Footer />
      </InnerWrapper>
    </Wrapper>
  );
};

PageTemplate.propTypes = {
  children: PropTypes.any.isRequired,
};

export default PageTemplate;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useUsers from './useUsers';
import usePlaylists from './usePlaylists';
import useNanodegrees from './useNanodegrees';
import useGroups from './useGroups';
import useCourses from './useCourses';
import useOrganizations from './useOrganizations';

const WatchDataContext = React.createContext();

function WatchDataProvider({
  downloadWatchData,
  downloadWatchDataManualOrganization,
  getCourseWatchData,
  getUserPlaylistWatchData,
  getUserWatchData,
  listCoursesWatchData,
  listGroupsWatchData,
  listGroupUsersWatchData,
  listNanodegreeStatsWatchData,
  listNanodegreesWatchData,
  listOrganizationsWatchData,
  listPlaylistGroupsWatchData,
  listPlaylistsWatchData,
  listPlaylistUsersWatchData,
  listUsersWatchData,
  listAggregatedWatchData,
  locale,
  organizationSelectedId,
  remindAllEventHandler,
  sendPlaylistReminder,
  user,
  ...props
}) {
  // Global object for error handling
  const [watchDataError, setWatchDataError] = useState(false);
  // Tab selections data
  const [courseSelected, setCourseSelected] = useState(null);
  const [groupSelected, setGroupSelected] = useState(null);
  const [playlistSelected, setPlaylistSelected] = useState(null);
  const [nanodegreeSelected, setNanodegreeSelected] = useState(null);

  // Global tab data
  const { usersWatchData, loadingUsersWatchData, fetchUsersWatchData } =
    useUsers({
      listUsersWatchData,
      setWatchDataError,
    });
  const { allCoursesStats, loadingCoursesWatchData, fetchCoursesWatchData } =
    useCourses({
      listCoursesWatchData,
      organizationSelectedId,
      locale,
      setWatchDataError,
    });
  const { organizationGroupsStats, loadingGroups, fetchGroups } = useGroups({
    listGroupsWatchData,
    setWatchDataError,
  });
  const {
    allPlaylistsStats,
    loadingPlaylistsWatchData,
    fetchPlaylistsWatchData,
  } = usePlaylists({
    listPlaylistsWatchData,
    locale,
    setWatchDataError,
  });
  const {
    allNanodegreesStats,
    fetchNanodegreesWatchData,
    loadingNanodegreeStatsWatchData,
    loadingNanodegreesWatchData,
    nanodegreeStats,
  } = useNanodegrees({
    listNanodegreeStatsWatchData,
    listNanodegreesWatchData,
    locale,
    nanodegreeSelected,
    organizationSelectedId,
    setWatchDataError,
  });
  const {
    organizationsWatchData,
    fetchOrganizationsWatchData,
    loadingOrganizationsWatchData,
  } = useOrganizations({
    listOrganizationsWatchData,
    setWatchDataError,
  });

  const contextValue = {
    // Constant variables
    user,
    locale,
    downloadWatchData,
    downloadWatchDataManualOrganization,
    organizationSelectedId,
    sendPlaylistReminder,
    watchDataError,
    setWatchDataError,
    // Global fetching methods and state (longer loading times, potentially catched)
    users: {
      usersWatchData,
      loadingUsersWatchData,
      fetchUsersWatchData,
    },
    courses: {
      allCoursesStats,
      fetchCoursesWatchData,
      loadingCoursesWatchData,
    },
    groups: {
      organizationGroupsStats,
      loadingGroups,
      fetchGroups,
    },
    playlists: {
      allPlaylistsStats,
      loadingPlaylistsWatchData,
      fetchPlaylistsWatchData,
      remindAllEventHandler,
    },
    nanodegrees: {
      allNanodegreesStats,
      fetchNanodegreesWatchData,
      loadingNanodegreeStatsWatchData,
      loadingNanodegreesWatchData,
      nanodegreeStats,
    },
    organizations: {
      organizationsWatchData,
      fetchOrganizationsWatchData,
      loadingOrganizationsWatchData,
    },
    // Local fetching methods (depending on id's)
    getUserWatchData,
    listGroupUsersWatchData,
    listPlaylistUsersWatchData,
    getUserPlaylistWatchData,
    listPlaylistGroupsWatchData,
    getCourseWatchData,
    listAggregatedWatchData,
    // Tab specific state that needs to be accessed
    courseSelected,
    setCourseSelected,
    groupSelected,
    setGroupSelected,
    playlistSelected,
    setPlaylistSelected,
    nanodegreeSelected,
    setNanodegreeSelected,
  };

  return <WatchDataContext.Provider value={contextValue} {...props} />;
}

WatchDataProvider.propTypes = {
  downloadWatchData: PropTypes.func.isRequired,
  downloadWatchDataManualOrganization: PropTypes.func,
  getCourseWatchData: PropTypes.func.isRequired,
  getUserPlaylistWatchData: PropTypes.func.isRequired,
  getUserWatchData: PropTypes.func.isRequired,
  listAggregatedWatchData: PropTypes.func.isRequired,
  listCoursesWatchData: PropTypes.func.isRequired,
  listGroupsWatchData: PropTypes.func.isRequired,
  listGroupUsersWatchData: PropTypes.func.isRequired,
  listNanodegreeStatsWatchData: PropTypes.func,
  listNanodegreesWatchData: PropTypes.func,
  listOrganizationsWatchData: PropTypes.func,
  listPlaylistsWatchData: PropTypes.func.isRequired,
  listPlaylistUsersWatchData: PropTypes.func.isRequired,
  listPlaylistGroupsWatchData: PropTypes.func.isRequired,
  listUsersWatchData: PropTypes.func.isRequired,
  locale: PropTypes.oneOf(['no', 'en', 'es']),
  organizationSelectedId: PropTypes.string, // Just used in BO
  remindAllEventHandler: PropTypes.func, // Just used in client
  sendPlaylistReminder: PropTypes.func.isRequired,
  user: PropTypes.shape({
    signInUserSession: PropTypes.shape({
      accessToken: PropTypes.shape({
        jwtToken: PropTypes.string.isRequired,
      }),
    }),
    attributes: PropTypes.shape({
      sub: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

WatchDataProvider.defaultProps = {
  downloadWatchDataManualOrganization: () => {},
  listNanodegreeStatsWatchData: () => {},
  listNanodegreesWatchData: () => {},
  organizationSelectedId: null,
};

function useWatchData() {
  const context = React.useContext(WatchDataContext);
  if (!context) {
    throw new Error('useWatchData must be used within a WatchDataProvider');
  }

  const {
    users,
    courses,
    groups,
    playlists,
    nanodegrees,
    organizations,
    setCourseSelected,
    setGroupSelected,
    setPlaylistSelected,
    setNanodegreeSelected,
    ...contextRest
  } = context;

  const resetSelections = () => {
    setCourseSelected(null);
    setGroupSelected(null);
    setPlaylistSelected(null);
    setNanodegreeSelected(null);
  };

  return {
    resetSelections,
    setCourseSelected,
    setGroupSelected,
    setPlaylistSelected,
    setNanodegreeSelected,
    ...users,
    ...courses,
    ...groups,
    ...playlists,
    ...nanodegrees,
    ...organizations,
    ...contextRest,
  };
}

export { WatchDataProvider, useWatchData };

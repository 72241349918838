import PropTypes from 'prop-types';

export const commonProps = {
  title: PropTypes.string,
  tabsConfig: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  selectedTab: PropTypes.string.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  onClearSearch: PropTypes.func.isRequired,
  searchOptions: PropTypes.array,
  searchPlaceholder: PropTypes.string.isRequired,
  preSelectedValue: PropTypes.object,
  getOptionKey: PropTypes.func,
  searchDisabled: PropTypes.bool,
  searchLoading: PropTypes.bool,
};

export const defaultProps = {
  title: null,
  searchOptions: [],
  searchTerm: '',
  searchDisabled: false,
  searchLoading: false,
};

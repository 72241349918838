import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';

import AddIcon from '@mui/icons-material/Add';

import Drawer from '@shared/atoms/Drawer';
import Button from '@shared/atoms/Button';
import Toggle from '@shared/atoms/Toggle';
import FormInput from '@shared/atoms/FormFields/FormInput';
import T from '@shared/atoms/Typography';
import ConfirmationDialog from '@shared/molecules/ConfirmationDialog';
import DragAndDrop from '@shared/molecules/DragAndDrop';
import ItemBox from '@shared/molecules/ItemBox';

import { selectTranslation } from '@shared/utils';
import theme from '@shared/themes/default';

import { DOMAIN_LOCALES, courseStatus, MAX_COURSE } from '@shared/const';

import EditField from './EditField';

import { useAppGlobals } from '../../../../../contexts/AppContext';
import { useCourse } from '../../../../../contexts/CourseContext';

import { LEVEL_FIELD_TYPE } from '../const';

const ArticleStyled = styled.article`
  background: ${theme.palette.darkDistinct};
  padding: ${theme.spacing.s};
`;

const EnableLanguageWrapper = styled.div`
  column-gap: ${theme.spacing.s};
  display: flex;
  justify-content: flex-end;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.m1};
`;

const LearningPointsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing.m2};
  row-gap: ${theme.spacing.s};
`;

const LearningPointsHeader = styled.div`
  align-items: center;
  column-gap: ${theme.spacing.m1};
  display: flex;
`;

const PointsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.xs};
`;

const DrawerContent = styled.div`
  background: ${theme.palette.darkDistinct};
  margin: 0 ${theme.spacing.s};
  padding: ${theme.spacing.s} ${theme.spacing.s} ${theme.spacing['2xl']};
  width: 496px;
`;

const DescriptionDetails = ({
  course,
  enabledLanguages,
  setCourse,
  selectedLanguage,
  setEnabledLanguages,
}) => {
  const { t } = useTranslation();
  const { domain } = useAppGlobals();
  const languages = DOMAIN_LOCALES[domain];

  const { locked, submitting } = useCourse();

  const [learningPoints, setLearningPoints] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [newLearningPoint, setNewLearningPoint] = useState('');
  const [selectedLearningPoint, setSelectedLearningPoint] = useState('');
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  useEffect(() => {
    const originalLearningPoints =
      JSON.parse(course.keyLearningPointsTranslations || '{}')[
        selectedLanguage
      ] || [];
    const newLearningPoints = originalLearningPoints.map((point) => ({
      id: uuid(),
      label: point,
    }));
    setLearningPoints(newLearningPoints);
  }, [course, selectedLanguage]);

  const isPublished = course.status === courseStatus.PUBLISHED;
  const isLocked =
    submitting ||
    (isPublished && locked) ||
    !enabledLanguages[selectedLanguage];

  const controlledFields = [
    {
      label: t('backoffice.courses.descriptionTab.name'),
      field: 'nameTranslations',
      maxLength: MAX_COURSE.NAME_LENGTH,
      placeholder: t('backoffice.courses.descriptionTab.namePlaceholder'),
    },
    {
      label: t('backoffice.courses.descriptionTab.shortDescription'),
      field: 'shortDescriptionTranslations',
      maxLength: MAX_COURSE.SHORT_DESCRIPTION_LENGTH,
      minRows: 2,
      placeholder: t(
        'backoffice.courses.descriptionTab.shortDescriptionPlaceholder'
      ),
    },
    {
      label: t('backoffice.courses.descriptionTab.description'),
      field: 'descriptionTranslations',
      maxLength: MAX_COURSE.DESCRIPTION_LENGTH,
      minRows: 4,
      placeholder: t(
        'backoffice.courses.descriptionTab.descriptionPlaceholder'
      ),
    },
    {
      label: t('backoffice.courses.descriptionTab.seoDescription'),
      field: 'seoDescriptionTranslations',
      infoText: t('backoffice.courses.descriptionTab.seoDescriptionInfo'),
      maxLength: MAX_COURSE.SEO_DESCRIPTION_LENGTH,
      placeholder: t(
        'backoffice.courses.descriptionTab.seoDescriptionPlaceholder'
      ),
    },
    {
      field: 'keyLearningPointsTranslations',
      hide: true,
    },
  ];

  const handleInputChange = (field, value) => {
    const currentValue = JSON.parse(course[field]) || {};
    if (value) {
      currentValue[selectedLanguage] = value;
    } else {
      delete currentValue[selectedLanguage];
    }

    const finalValue = {
      ...course,
      ...{
        [field]:
          Object.keys(currentValue).length > 0
            ? JSON.stringify(currentValue)
            : null,
      },
    };
    setCourse(finalValue);
  };

  const handleDisableLanguage = () => {
    let newCourse = { ...course };
    controlledFields.forEach(({ field }) => {
      if (course[field]) {
        const currentValue = JSON.parse(course[field] || '{}');
        if (
          selectedLanguage in currentValue &&
          currentValue[selectedLanguage]
        ) {
          delete currentValue[selectedLanguage];
          newCourse = {
            ...newCourse,
            [field]:
              Object.keys(currentValue).length > 0
                ? JSON.stringify(currentValue)
                : null,
          };
        }
      }
    });
    setCourse(newCourse);
    setEnabledLanguages({ ...enabledLanguages, [selectedLanguage]: false });
    setShowConfirmationDialog(false);
  };

  const handleToggleLanguage = (value) => {
    if (!value) {
      setShowConfirmationDialog(true);
    } else {
      setEnabledLanguages({ ...enabledLanguages, [selectedLanguage]: value });
    }
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setNewLearningPoint('');
    setSelectedLearningPoint('');
  };

  const handleSaveDrawer = () => {
    const newKeyLearningPoints = JSON.parse(
      course.keyLearningPointsTranslations || '{}'
    );
    if (selectedLearningPoint) {
      const newLearningPoints = [...learningPoints];
      const learningPointIndex = newLearningPoints.findIndex(
        (point) => point.id === selectedLearningPoint
      );
      newLearningPoints[learningPointIndex].label = newLearningPoint;
      newKeyLearningPoints[selectedLanguage][learningPointIndex] =
        newLearningPoint;
      setLearningPoints(newLearningPoints);
    } else {
      if (!(selectedLanguage in newKeyLearningPoints))
        newKeyLearningPoints[selectedLanguage] = [];
      newKeyLearningPoints[selectedLanguage].push(newLearningPoint);
    }

    setCourse({
      ...course,
      ...{
        keyLearningPointsTranslations: JSON.stringify(newKeyLearningPoints),
      },
    });
    handleCloseDrawer(true);
  };

  const handleAddKeyPoint = () => {
    setNewLearningPoint('');
    setSelectedLearningPoint('');
    setDrawerOpen(true);
  };

  const handleKeyDelete = (id) => {
    const index = learningPoints.findIndex((point) => point.id === id);
    const newTranslatedLearningPoints = [
      ...learningPoints.slice(0, index),
      ...learningPoints.slice(index + 1),
    ].map((point) => point.label);

    const newKeyLearningPoints = JSON.parse(
      course.keyLearningPointsTranslations || '{}'
    );

    if (newTranslatedLearningPoints.length === 0) {
      delete newKeyLearningPoints[selectedLanguage];
    } else {
      newKeyLearningPoints[selectedLanguage] = newTranslatedLearningPoints;
    }

    setCourse({
      ...course,
      ...{
        keyLearningPointsTranslations:
          Object.keys(newKeyLearningPoints).length > 0
            ? JSON.stringify(newKeyLearningPoints)
            : null,
      },
    });
  };

  const handleKeyDrag = (newPointsRaw) => {
    const newKeyLearningPoints = JSON.parse(
      course.keyLearningPointsTranslations || '{}'
    );
    newKeyLearningPoints[selectedLanguage] = newPointsRaw.map(
      (point) => point.label
    );

    setCourse({
      ...course,
      ...{
        keyLearningPointsTranslations: JSON.stringify(newKeyLearningPoints),
      },
    });
  };

  const handleKeyEdit = (id) => {
    const keyPoint = learningPoints.find((point) => point.id === id);

    setNewLearningPoint(keyPoint.label);
    setSelectedLearningPoint(id);
    setDrawerOpen(true);
  };

  const keyButtons = [
    {
      label: t('backoffice.courses.descriptionTab.edit'),
      handler: handleKeyEdit,
    },
    {
      label: t('generic.delete'),
      handler: handleKeyDelete,
      textStyle: true,
    },
  ];
  const renderItem = (item) => (
    <ItemBox
      animated
      buttons={!isLocked ? keyButtons : null}
      draggable={!isLocked}
      id={item.id}
      menuItems={[]}
      theme="dark"
      title={item.label}
    />
  );

  const keyPoint =
    selectedLearningPoint &&
    learningPoints.find((point) => point.id === selectedLearningPoint);
  const drawerContentIsEdited = newLearningPoint !== keyPoint?.label;

  return (
    <ArticleStyled>
      <Form>
        {selectedLanguage !== languages[0] && (!isPublished || !locked) && (
          <EnableLanguageWrapper>
            <T color={theme.palette.lightPure} variant="paragraph">
              {t(
                `backoffice.courses.descriptionTab.${selectedLanguage}EnableLanguage`
              )}
            </T>
            <Toggle
              disabled={submitting || (isPublished && locked)}
              checked={enabledLanguages[selectedLanguage]}
              onChange={handleToggleLanguage}
            />
          </EnableLanguageWrapper>
        )}
        {controlledFields
          .filter(({ hide }) => !hide)
          .map((field) => (
            <FormInput
              disabled={
                submitting ||
                (isPublished && locked) ||
                !enabledLanguages[selectedLanguage]
              }
              id={`${selectedLanguage}-${field.field}`}
              infoText={field.infoText}
              key={`${selectedLanguage}-${field.field}`}
              label={field.label}
              minRows={field.minRows || 1}
              multiline={'minRows' in field}
              name={`${selectedLanguage}-${field.field}`}
              handleChange={({ value }) =>
                handleInputChange(field.field, value)
              }
              placeholder={field.placeholder}
              properties={{
                [`${selectedLanguage}-${field.field}`]: {
                  maxLength: field.maxLength,
                },
              }}
              required
              showMaxLength
              theme="dark"
              value={selectTranslation(
                course[field.field],
                '',
                selectedLanguage,
                ''
              )}
            />
          ))}
        <EditField
          course={course}
          enabledLanguages={enabledLanguages}
          setCourse={setCourse}
          selectedLanguage={selectedLanguage}
          type={LEVEL_FIELD_TYPE.PREREQUISITES}
        />
        <EditField
          course={course}
          enabledLanguages={enabledLanguages}
          setCourse={setCourse}
          selectedLanguage={selectedLanguage}
          type={LEVEL_FIELD_TYPE.TYPICAL_LEARNER}
        />
      </Form>
      <LearningPointsWrapper>
        <LearningPointsHeader>
          <T color={theme.palette.lightPure} variant="headingS">
            {t('backoffice.courses.descriptionTab.learningPointsCount', {
              max: 6,
              total: (
                JSON.parse(course.keyLearningPointsTranslations || '{}')[
                  selectedLanguage
                ] || []
              ).length,
            })}
          </T>
          <Button
            disabled={
              learningPoints.length >= 6 ||
              submitting ||
              (isPublished && locked) ||
              !enabledLanguages[selectedLanguage]
            }
            iconRight={AddIcon}
            onClick={handleAddKeyPoint}
            sx={{
              textTransform: 'uppercase',
            }}
            type="secondaryOutline"
          >
            {t('backoffice.courses.add')}
          </Button>
        </LearningPointsHeader>
        <PointsWrapper>
          <DragAndDrop
            isLocked={isLocked}
            items={learningPoints}
            onDragEnd={handleKeyDrag}
            renderItem={renderItem}
          />
        </PointsWrapper>
      </LearningPointsWrapper>
      {showConfirmationDialog && (
        <ConfirmationDialog
          open={showConfirmationDialog}
          title={t(
            `backoffice.courses.descriptionTab.${selectedLanguage}DialogTitle`
          )}
          ariaLabel={t(
            `backoffice.courses.descriptionTab.${selectedLanguage}DialogTitle`
          )}
          description={t(
            `backoffice.courses.descriptionTab.${selectedLanguage}DialogDescription`
          )}
          cancelText={t('generic.cancel')}
          confirmText={t('backoffice.courses.descriptionTab.disable')}
          onConfirm={handleDisableLanguage}
          onCancel={() => setShowConfirmationDialog(false)}
          onClose={() => setShowConfirmationDialog(false)}
        />
      )}
      <Drawer
        anchor="right"
        open={drawerOpen}
        edited={drawerContentIsEdited && !!newLearningPoint}
        onClose={() => handleCloseDrawer()}
        onSave={handleSaveDrawer}
        saveDisabled={!newLearningPoint}
        title={t('backoffice.courses.descriptionTab.learningPointEdit')}
      >
        <DrawerContent>
          <FormInput
            autoFocus
            id={`${selectedLanguage}-newLearningPoint`}
            key={`${selectedLanguage}-newLearningPoint`}
            label={t('backoffice.courses.descriptionTab.learningPoint')}
            maxRows={0}
            multiline
            name={`${selectedLanguage}-newLearningPoint`}
            handleChange={({ value }) =>
              setNewLearningPoint(value.replace(/\n/g, ''))
            }
            placeholder={t(
              'backoffice.courses.descriptionTab.learningPointPlaceholder'
            )}
            properties={{
              [`${selectedLanguage}-newLearningPoint`]: {
                maxLength: 100,
              },
            }}
            required
            showMaxLength
            theme="dark"
            value={newLearningPoint}
          />
        </DrawerContent>
      </Drawer>
    </ArticleStyled>
  );
};

DescriptionDetails.propTypes = {
  course: PropTypes.object.isRequired,
  enabledLanguages: PropTypes.object.isRequired,
  setCourse: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  setEnabledLanguages: PropTypes.func.isRequired,
};

export default DescriptionDetails;

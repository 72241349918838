import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import withStyles from '@mui/styles/withStyles';

import theme from 'shared/themes/default';

const styles = {
  indicator: {
    backgroundColor: theme.palette.brand,
  },
};

const Wrapper = styled.div`
  background-color: ${(props) =>
    props.theme === 'light' ? theme.palette.lightPure : 'transparent'};
  width: 100%;
  ${({ height }) =>
    height
      ? `
    height: ${height};
  `
      : ''}
  ${({ wrapperStyle }) => wrapperStyle}
  .MuiAppBar-root {
    background-color: transparent;
  }

  .MuiTabs-root {
    min-height: 32px;
    background-color: transparent;
  }

  .MuiTab-textColorPrimary {
    color: ${(props) =>
      props.theme === 'light'
        ? theme.palette.pureDark
        : theme.palette.lightPure};
    font-weight: 600;
    text-transform: none;
    padding: 0;
    font-size: 16px;
    margin-right: 20px;
    min-width: auto;
    min-height: auto;
  }

  .MuiTab-textColorPrimary.Mui-selected {
    font-weight: 700;
  }

  .MuiTab-root.Mui-disabled {
    color: ${theme.palette.disabledLight};
  }
`;

const SideSpaceWrapper = styled.div`
  padding: 0 1rem;
`;

const Spacer = styled.div`
  height: ${theme.spacing.m1};
`;

const TabWrapper = styled.div`
  height: 100%;
  color: ${(props) =>
    props.theme === 'light' ? theme.palette.pureDark : theme.palette.lightPure};
`;

const SwipeableViewsWrapper = styled.div`
  ${({ height }) =>
    height
      ? `
    height: ${height};
    .react-swipeable-view-container {
      height: ${height};
    }
  `
      : ''}
`;

const FullWidthTabs = ({
  centerTabs,
  children,
  classes,
  contentHeight,
  onChangeTabHandler,
  selectedTabIndex,
  tabsToSelect,
  theme: themeLayout,
  wrapperStyle,
}) => {
  const theme = useTheme();
  const [value, setValue] = useState(selectedTabIndex);

  useEffect(() => {
    setValue(selectedTabIndex);
  }, [selectedTabIndex]);

  const handleChange = (_, newValue) => {
    onChangeTabHandler(newValue);
  };

  const handleChangeIndex = (index) => {
    onChangeTabHandler(index);
  };

  const swipeableHeight = contentHeight
    ? {
        height: contentHeight,
        '.reactSwipeableViewContainer': {
          height: contentHeight,
        },
      }
    : null;

  return (
    <Wrapper
      theme={themeLayout}
      height={contentHeight}
      wrapperStyle={wrapperStyle}
    >
      <SideSpaceWrapper>
        <AppBar position="static" color="inherit" elevation={0}>
          <Tabs
            aria-label="full-width-tabs"
            centered={centerTabs}
            classes={{
              indicator: classes.indicator,
            }}
            onChange={handleChange}
            textColor="primary"
            value={value}
          >
            {tabsToSelect.map((tab, index) => (
              <Tab
                disabled={tab.disabled}
                label={tab.label}
                key={tab.label}
                id={`full-width-tab-${index}`}
              />
            ))}
          </Tabs>
        </AppBar>
        <Spacer />
        {children && (
          <>
            {children}
            <Spacer />
          </>
        )}
      </SideSpaceWrapper>
      <SwipeableViewsWrapper height={contentHeight}>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
          style={swipeableHeight}
        >
          {tabsToSelect.map((tab) => (
            <TabWrapper key={tab.label} theme={themeLayout}>
              {tab.component}
            </TabWrapper>
          ))}
        </SwipeableViews>
      </SwipeableViewsWrapper>
    </Wrapper>
  );
};

FullWidthTabs.propTypes = {
  centerTabs: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  contentHeight: PropTypes.string,
  tabsToSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      component: PropTypes.node,
      disabled: PropTypes.bool,
    })
  ),
  onChangeTabHandler: PropTypes.func.isRequired,
  children: PropTypes.node,
  selectedTabIndex: PropTypes.number,
  theme: PropTypes.string,
  wrapperStyle: PropTypes.string,
};

FullWidthTabs.defaultProps = {
  centerTabs: false,
  selectedTabIndex: 0,
  theme: 'light',
};

export default withStyles(styles)(FullWidthTabs);

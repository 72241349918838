import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import Alert from 'shared/atoms/Alert';
import FullWidthTabs from 'shared/atoms/FullWidthTabs';
import SearchField from 'shared/atoms/SearchField';
import T from 'shared/atoms/Typography';
import Dialog from 'shared/molecules/Dialog';
import { selectTranslation } from 'shared/utils';
import theme from 'shared/themes/default';
import { usePlaylist } from 'shared/sections/PlaylistAdministration/context';

import CourseAndPreviewPanel from './CourseAndPreviewPanel';

const SideSpaceWrapper = styled.div`
  padding: 0 1rem;
`;

const SubtitleWrapper = styled.div`
  padding: 0 1rem 1rem 1rem;
`;

const styles = {
  dialogPaper: {
    maxHeight: '88vh',
    margin: '0',
  },
};

function SelectCoursesAndPreview({
  classes,
  formStatus,
  handleSubmit,
  onClose,
  playlistCourses,
  playlistName,
}) {
  const {
    courses: { allCourses, loadingCourses, fetchCourses },
    playlists: { isNanodegree },
    locale,
  } = usePlaylist();

  const [coursesToSelect, setCoursesToSelect] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [coursesSearched, setCoursesSearched] = useState(null);
  const [searchBox, setSearchBox] = useState('course');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchActivated, setSearchActivated] = useState(false);
  const [coursesSelected, setCoursesSelected] = useState(playlistCourses || []);
  const [initPage, setInitPage] = useState(1);
  const [submitting, setSubmitting] = useState();

  const { t } = useTranslation();

  const onSubmit = (value) => {
    setSubmitting(true);
    handleSubmit(value);
  };

  const toggleCourseHandler = (id, page) => {
    setInitPage(page);
    const courseAlreadySelected = coursesSelected.find(
      ({ id: courseId }) => courseId === id
    );
    if (courseAlreadySelected) {
      setCoursesSelected(
        coursesSelected
          .map((course) => {
            const modifiedCourse = course;
            if (course.id === id) {
              modifiedCourse.selected = !modifiedCourse.selected;
            }
            return modifiedCourse;
          })
          .filter((course) => course.selected)
      );
    } else {
      const course = coursesToSelect.find(
        ({ id: courseId }) => courseId === id
      );
      const newCourse = { ...course };
      newCourse.selected = true;
      setCoursesSelected([...coursesSelected, newCourse]);
    }
  };

  const removeCourseFromPreviewHandler = (id, page) => {
    setInitPage(page);
    const course = coursesToSelect.find(({ id: courseId }) => courseId === id);
    if (course) {
      const newCourse = { ...course };
      newCourse.selected = !newCourse.selected;
      setCoursesSelected(
        [...coursesSelected, newCourse].filter((course) => course.id !== id)
      );
    } else {
      setCoursesSelected(coursesSelected.filter((course) => course.id !== id));
    }
  };

  useEffect(() => {
    setSubmitting(false);
  }, [formStatus]);

  useEffect(() => {
    if (!loadingCourses) {
      const cts = allCourses.map((course) => {
        const modifiedCourse = course;
        const courseSelected = playlistCourses.find(
          (selectedCourse) => selectedCourse.id === course.id
        );
        modifiedCourse.selected = !!courseSelected;

        return modifiedCourse;
      });
      setCoursesToSelect(cts);
    }
  }, [allCourses, loadingCourses]);

  useEffect(() => {
    fetchCourses();
  }, []);

  const tab1Handler = (
    <CourseAndPreviewPanel
      coursesSelected={coursesSelected}
      coursesToSelect={
        searchActivated && searchTerm !== '' ? coursesSearched : coursesToSelect
      }
      initPage={initPage}
      locale={locale}
      onCourseClickHandler={toggleCourseHandler}
      loading={loadingCourses}
    />
  );

  const tab2Handler = (
    <CourseAndPreviewPanel
      coursesSelected={coursesSelected}
      coursesToSelect={
        searchActivated && searchTerm !== '' ? coursesSearched : coursesSelected
      }
      initPage={initPage}
      locale={locale}
      onCourseClickHandler={removeCourseFromPreviewHandler}
      loading={loadingCourses}
    />
  );

  const onChangeTabHandler = () => {
    setInitPage(1);
    setSearchTerm('');
    if (selectedTabIndex === 0) {
      setSearchBox('preview');
      setSearchActivated(false);
    } else {
      setSearchBox('course');
      setSearchActivated(false);
    }

    setSelectedTabIndex(selectedTabIndex === 0 ? 1 : 0);
  };

  const tabsToSelect = [
    { label: t('playlist.pickCourses'), component: tab1Handler },
    {
      label: `${t(
        isNanodegree
          ? 'nanodegree.previewNanodegrees'
          : 'playlist.previewPlaylists'
      )} (${coursesSelected.length})`,
      component: tab2Handler,
    },
  ];

  const onSearchHandler = ({ value }) => {
    setSearchActivated(true);
    setSearchTerm(value);
    if (searchBox === 'course') {
      setCoursesSearched(
        coursesToSelect.filter((course) =>
          selectTranslation(course.nameTranslations, course.name, locale, '')
            .toLowerCase()
            .includes(value.toLowerCase())
        )
      );
    } else {
      setCoursesSearched(
        coursesSelected.filter((course) =>
          selectTranslation(course.nameTranslations, course.name, locale, '')
            .toLowerCase()
            .includes(value.toLowerCase())
        )
      );
    }
  };

  const actions = [
    {
      disabled: !coursesSelected.length,
      handler: () => onSubmit(coursesSelected),
      label: t('generic.save'),
    },
  ];

  return (
    <Dialog
      open
      classes={{ paper: classes.dialogPaper }}
      onClose={onClose}
      ariaLabel={t(
        isNanodegree
          ? t('nanodegree.selectNanodegreeCourses')
          : 'playlist.selectPlaylistCourses'
      )}
      title={t(
        isNanodegree
          ? t('nanodegree.selectNanodegreeCourses')
          : 'playlist.selectPlaylistCourses'
      )}
      loading={submitting}
      actions={actions}
      contentPadding="0"
      formStatus={formStatus}
    >
      <SubtitleWrapper>
        <T
          color={theme.palette.darkDistinct}
          variant="paragraph"
        >{`"${playlistName}"`}</T>
      </SubtitleWrapper>
      {formStatus && (
        <SideSpaceWrapper>
          <Alert
            message={formStatus.message}
            severity={formStatus.success ? 'success' : 'error'}
            theme="light"
          />
        </SideSpaceWrapper>
      )}
      {!formStatus && (
        <FullWidthTabs
          tabsToSelect={tabsToSelect}
          onChangeTabHandler={onChangeTabHandler}
          selectedTabIndex={selectedTabIndex}
        >
          <SearchField
            theme="light"
            ariaLabel={t('playlist.searchCourses')}
            placeholder={t('playlist.searchCourses')}
            value={searchTerm}
            onClear={() => setSearchTerm('')}
            onChange={onSearchHandler}
          />
        </FullWidthTabs>
      )}
    </Dialog>
  );
}

SelectCoursesAndPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  formStatus: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  playlistCourses: PropTypes.array.isRequired,
  playlistName: PropTypes.string.isRequired,
};

export default withStyles(styles)(SelectCoursesAndPreview);

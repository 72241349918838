import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Icon from 'shared/atoms/Icon';
import Select from 'shared/atoms/Select';
import { BACKGROUND_THEMES } from 'shared/const';
import { langSelectIcons } from 'shared/utils';

const LanguageSelect = ({
  disabled,
  languages,
  onChange,
  required,
  theme,
  value,
}) => {
  const { t } = useTranslation();
  const getItems = () =>
    languages.map((language) => ({
      value: language,
      key: language,
      label: t(`backoffice.courses.${language}Name`),
      icon: <Icon svgContent={langSelectIcons[language]} viewBox="0 0 32 16" />,
    }));

  return (
    <Select
      disabled={disabled}
      fullWidth={false}
      iconOnly
      id="languageSelect"
      items={getItems()}
      name="languageSelect"
      onChange={onChange}
      required={required}
      theme={theme}
      value={value}
    />
  );
};

LanguageSelect.propTypes = {
  disabled: PropTypes.bool,
  languages: PropTypes.arrayOf(PropTypes.oneOf(['no', 'en', 'es'])),
  onChange: PropTypes.func,
  required: PropTypes.bool,
  theme: PropTypes.oneOf(BACKGROUND_THEMES),
  value: PropTypes.oneOf(['no', 'en', 'es']),
};

LanguageSelect.defaultProps = {
  disabled: false,
  languages: [],
  onChange: () => {},
  required: false,
};

export default LanguageSelect;

import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import theme from 'shared/themes/default';
import NativeSelect from './NativeSelect';
import CustomSelect from './CustomSelect';

const Select = (props) => {
  const isPhone = useMediaQuery(theme.breakpoints.mobile);

  return isPhone ? <NativeSelect {...props} /> : <CustomSelect {...props} />;
};

export default Select;

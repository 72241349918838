import React from 'react';

import PageTemplate from '../components/templates/PageTemplate';
import MembersView from '../components/organisms/MembersView';

const MembersContainer = () => {
  return (
    <PageTemplate>
      <MembersView />
    </PageTemplate>
  );
};

export default MembersContainer;

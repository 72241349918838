import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import BusinessIcon from '@mui/icons-material/Business';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import Icon from '@shared/atoms/Icon';
import IconButton from '@shared/atoms/IconButton';
import FullWidthTabs from '@shared/atoms/FullWidthTabs';
import T from '@shared/atoms/Typography';
import Dialog from '@shared/molecules/Dialog';
import Menu from '@shared/molecules/Menu';
import theme from '@shared/themes/default';

import { logger } from '@shared/utils';

import FeaturedItemTab from './tabs/FeaturedItemTab';
import SettingsTab from './tabs/SettingsTab';
import SubscriptionTab from './tabs/SubscriptionTab';
import { setOrganizationArchived } from '../graphql/mutations';
import { useGraphQLQuery } from '../../../../utils';

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${theme.spacing.m1} ${theme.spacing.m1} 0 ${theme.spacing.m1};
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${theme.spacing.s};
`;

const LeftTitleWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: ${theme.spacing.s};
`;

const OrganizationsTableRowItem = ({
  item,
  onUpdateOrganization,
  setErrorMessage,
  setDetailedErrors,
  setHasError,
  setHasSuccess,
  setSuccessMessage,
  subscriptionPlans,
  subscriptionPlansLoading,
}) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const customMenuHandler = (event) => {
    setAnchorEl(anchorEl === null ? event.currentTarget : null);
  };

  const { callQuery, data, errors, hasEnded, hasErrors, loading } =
    useGraphQLQuery();

  useEffect(() => {
    if (hasEnded) {
      if (hasErrors) {
        logger.error('Error: ', errors);
        setErrorMessage(
          item.archived
            ? t('backoffice.organizations.archive.failedToUnarchive', {
                name: item.name,
              })
            : t('backoffice.organizations.archive.failedToArchive', {
                name: item.name,
              })
        );
        setHasError(true);
      } else {
        const { setOrganizationArchived } = data;

        onUpdateOrganization(setOrganizationArchived);
        setSuccessMessage(
          setOrganizationArchived.archived
            ? t('backoffice.organizations.archive.successfullyArchived', {
                name: item.name,
              })
            : t('backoffice.organizations.archive.successfullyUnarchived', {
                name: item.name,
              })
        );
        setHasSuccess(true);
      }

      setArchiveDialogOpen(false);
    }
  }, [hasEnded]);

  return (
    <>
      <OuterWrapper>
        <TitleWrapper>
          <LeftTitleWrapper>
            <Icon icon={BusinessIcon} />
            <T fontWeight="semibold" variant="subtitle">
              {item.name}
            </T>
          </LeftTitleWrapper>
          <IconButton
            ariaLabel={t('generic.more')}
            icon={MoreHorizIcon}
            onClick={customMenuHandler}
            theme="dark"
            type="primary"
          />
          <Menu
            freeMenu
            initAnchorEl={anchorEl}
            menuItems={[
              {
                label: item.archived
                  ? t('backoffice.organizations.archive.unarchive')
                  : t('backoffice.organizations.archive.archive'),
                handler: () => setArchiveDialogOpen(true),
              },
            ]}
            setInitAnchorEl={setAnchorEl}
            theme="dark"
          />
        </TitleWrapper>
        <FullWidthTabs
          onChangeTabHandler={(index) => setSelectedTab(index)}
          selectedTabIndex={selectedTab}
          tabsToSelect={[
            {
              label: t('backoffice.organizations.subscription.title'),
              component: (
                <SubscriptionTab
                  item={item}
                  onUpdateOrganization={onUpdateOrganization}
                  setErrorMessage={setErrorMessage}
                  setHasError={setHasError}
                  setHasSuccess={setHasSuccess}
                  setSuccessMessage={setSuccessMessage}
                  subscriptionPlans={subscriptionPlans}
                  subscriptionPlansLoading={subscriptionPlansLoading}
                />
              ),
            },
            {
              label: t('backoffice.organizations.settings.title'),
              component: (
                <SettingsTab
                  item={item}
                  onUpdateOrganization={onUpdateOrganization}
                  setErrorMessage={setErrorMessage}
                  setDetailedErrors={setDetailedErrors}
                  setHasError={setHasError}
                  setHasSuccess={setHasSuccess}
                  setSuccessMessage={setSuccessMessage}
                />
              ),
            },
            {
              label: t('backoffice.organizations.featuredItem.tabTitle'),
              component: (
                <FeaturedItemTab
                  item={item}
                  onUpdateOrganization={onUpdateOrganization}
                  setErrorMessage={setErrorMessage}
                  setHasError={setHasError}
                  setHasSuccess={setHasSuccess}
                  setSuccessMessage={setSuccessMessage}
                />
              ),
            },
          ]}
          theme="dark"
        />
      </OuterWrapper>
      <Dialog
        ariaLabel={
          item.archived
            ? t('backoffice.organizations.archive.unarchive')
            : t('backoffice.organizations.archive.archive')
        }
        actions={[
          {
            label: t('generic.cancel'),
            handler: () => setArchiveDialogOpen(false),
            type: 'secondaryOutline',
          },
          {
            label: t('generic.confirm'),
            handler: () =>
              callQuery(setOrganizationArchived(item.id, !item.archived)),
          },
        ]}
        loading={loading}
        onClose={() => setArchiveDialogOpen(false)}
        open={archiveDialogOpen}
        title={
          item.archived
            ? t('backoffice.organizations.archive.unarchive')
            : t('backoffice.organizations.archive.archive')
        }
      >
        <T color={theme.palette.mainDark}>
          {item.archived
            ? t('backoffice.organizations.archive.wantToUnarchive', {
                name: item.name,
              })
            : t('backoffice.organizations.archive.wantToArchive', {
                name: item.name,
              })}
        </T>
      </Dialog>
    </>
  );
};

OrganizationsTableRowItem.propTypes = {
  item: PropTypes.object.isRequired,
  onUpdateOrganization: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  setDetailedErrors: PropTypes.func.isRequired,
  setHasError: PropTypes.func.isRequired,
  setHasSuccess: PropTypes.func.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  subscriptionPlans: PropTypes.array.isRequired,
  subscriptionPlansLoading: PropTypes.bool.isRequired,
};

export default OrganizationsTableRowItem;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import MuiMenu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import IconButton from 'shared/atoms/IconButton';

import { BACKGROUND_THEMES } from 'shared/const';
import theme from 'shared/themes/default';
import Icon from 'shared/atoms/Icon';

const Menu = ({
  freeMenu,
  icon,
  initAnchorEl,
  menuItems,
  onClose,
  setInitAnchorEl,
  theme: themeLayout,
}) => {
  const [anchorEl, setAnchorEl] = useState(initAnchorEl || null);
  const { t } = useTranslation();

  useEffect(() => {
    setAnchorEl(initAnchorEl || anchorEl);
  }, [initAnchorEl]);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (setInitAnchorEl) {
      setInitAnchorEl(null);
    }
    if (onClose) {
      onClose();
    }
  };

  const menuStyles = {
    '.MuiMenu-paper': {
      borderRadius: '6px',
    },
  };

  const menuItemStyles = (label, withIcon) => ({
    fontSize: '0.875rem',
    color: `${theme.palette.mainDark}`,
    flexDirection: withIcon ? 'row' : 'column',
    alignItems: withIcon ? 'center' : 'start',
    '&.Mui-disabled': {
      color: `${theme.palette.disabledDark}`,
      opacity: 1,
    },
    '&.Mui-focusVisible': {
      backgroundColor: `${theme.palette.lightDistinct}`,
    },
    '&:hover': {
      backgroundColor: 'inherit',
      fontWeight: 600,
      textDecoration: 'underline',
      '&.Mui-focusVisible': {
        backgroundColor: `${theme.palette.lightDistinct}`,
      },
    },
    '&:after': {
      content: `'${label}'`,
      height: 0,
      visibility: 'hidden',
      overflow: 'hidden',
      pointerEvents: 'none',
      userSelect: 'none',
      fontWeight: 600,
    },
  });

  return (
    <div>
      {!freeMenu && (
        <IconButton
          ariaLabel={t('generic.more')}
          icon={icon || MoreHorizIcon}
          onClick={handleClick}
          small
          theme={themeLayout}
          type="transparent"
        />
      )}

      <MuiMenu
        id="menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        elevation={5}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={menuStyles}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {menuItems.map((menu) => (
          <MuiMenuItem
            key={menu.label}
            disabled={!!menu.disabled}
            onClick={(e) => {
              menu.handler(e);
              handleClose();
              e.stopPropagation();
            }}
            sx={menuItemStyles(menu.label, menu.icon || menu.svgIcon)}
          >
            {(menu.icon || menu.svgIcon) && (
              <Icon
                sx={{ mr: theme.spacing.s }}
                icon={menu.icon || undefined}
                svgContent={menu.svgIcon || undefined}
              />
            )}
            {menu.label}
          </MuiMenuItem>
        ))}
      </MuiMenu>
    </div>
  );
};

Menu.propTypes = {
  freeMenu: PropTypes.bool,
  icon: PropTypes.object,
  initAnchorEl: PropTypes.object,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      handler: PropTypes.func.isRequired,
      icon: PropTypes.object,
      svgIcon: PropTypes.func,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onClose: PropTypes.func,
  setInitAnchorEl: PropTypes.func,
  theme: PropTypes.oneOf(BACKGROUND_THEMES),
};

Menu.defaultProps = {
  freeMenu: false,
  theme: 'dark',
};

export default Menu;

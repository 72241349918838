/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from 'shared/themes/default';
import QuestionAccordion from '../components/QuestionAccordion';

const EditQuizTabStyle = styled.div`
  max-height: 30rem;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: ${theme.spacing.m1};
  background: transparent;
  ::-webkit-scrollbar {
    width: 10px;
    height: 30px;
  }
  ::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    background-color: ${theme.palette.disabledLight};
    border-radius: 10px;
  }
`;

const EditQuiz = ({
  alwaysExpanded,
  disabled,
  isEditing,
  questions,
  setQuestions,
  theme: themeLayout,
}) => {
  const [openQuestion, setOpenQuestion] = useState(-1);

  const handleChangeQuestion = (index, question) => {
    const newQuestions = [...questions];
    newQuestions[index] = question;
    setQuestions(newQuestions);
  };

  return (
    <EditQuizTabStyle>
      {questions.map((question, index) => (
        <QuestionAccordion
          disabled={disabled}
          index={index}
          isEditing={!alwaysExpanded && isEditing}
          key={index}
          onChangeQuestion={(question) => handleChangeQuestion(index, question)}
          openQuestion={openQuestion}
          question={question}
          setOpenQuestion={setOpenQuestion}
          theme={themeLayout}
        />
      ))}
    </EditQuizTabStyle>
  );
};

EditQuiz.propTypes = {
  disabled: PropTypes.bool,
  alwaysExpanded: PropTypes.bool,
  isEditing: PropTypes.bool,
  questions: PropTypes.array.isRequired,
  setQuestions: PropTypes.func.isRequired,
  theme: PropTypes.string,
};

EditQuiz.defaultProps = {
  theme: 'light',
};

export default EditQuiz;

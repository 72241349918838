import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import ConfirmationDialog from 'shared/molecules/ConfirmationDialog';
import Table, { CELL_TYPES, ORDER_TYPES } from 'shared/organisms/Table';

import theme from 'shared/themes/default';
import { formatDate } from 'shared/utils';
import {
  invitationStatus,
  invitationSource,
  registeredMembersRoles,
} from 'shared/const';

import { useOrganizationMembers } from '../../context';

import ActionDropdown from '../ActionDropDown';
import MultipleFeedback from '../MultipleFeedback';

const ActionDropdownWrapper = styled.div`
  display: inline-block;
  width: 30%;

  ${theme.breakpoints.mobile} {
    width: 100%;
  }
`;

const SelectedMembersCounterText = styled.div`
  font-family: ${theme.fonts.primary};
  color: ${theme.palette.lightPure};
  font-size: 1rem;
  margin-bottom: 15px;
`;

const InvitedMembersTable = ({
  invitedMembers,
  selectedAction,
  selectedActionText,
  actionOptions,
  onActionChange,
  onActionApplyHandler,
  formStatus,
  onConfirmationDialogCloseHandler,
  successList,
  failureList,
  showMultipleFeedback,
}) => {
  const { t } = useTranslation();
  const { locale } = useOrganizationMembers();

  const [selectedEmailIDs, setSelectedEmailIDs] = useState([]);
  const [allItemsSelected, setAllItemsSelected] = useState(false);
  const [showConfirmActionDialog, setShowConfirmActionDialog] = useState(false);
  const [resetSelection, setResetSelection] = useState(false);
  const [columns, setColumns] = useState([]);

  const onApplyHandler = () => {
    if (onActionApplyHandler) {
      const status = onActionApplyHandler(selectedEmailIDs);
      if (status) {
        setResetSelection(true);
      }
    }
  };

  const onDialogClosehandler = () => {
    if (onConfirmationDialogCloseHandler) {
      onConfirmationDialogCloseHandler();
    }

    if (resetSelection) {
      setSelectedEmailIDs([]);
      setAllItemsSelected(false);
    }

    setShowConfirmActionDialog(false);
  };

  const getRoleText = (role) => {
    switch (role) {
      case registeredMembersRoles.admin:
        return t('administrateUsers.administrator');
      case registeredMembersRoles.manager:
        return t('administrateUsers.manager');
      case registeredMembersRoles.member:
        return t('administrateUsers.member');
      default:
        return role;
    }
  };

  const getSourceText = (source) => {
    switch (source) {
      case invitationSource.client:
        return t('administrateUsers.client');
      case invitationSource.backoffice:
        return t('administrateUsers.backoffice');
      case invitationSource.import:
        return t('administrateUsers.import');
      default:
        return source;
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case invitationStatus.pending:
        return t('administrateUsers.queued');
      case invitationStatus.invited:
        return t('administrateUsers.unanswered');
      case invitationStatus.bounced:
        return t('administrateUsers.bounced');
      default:
        return status;
    }
  };

  const onSelectAll = ({ value }) => {
    if (value) {
      setSelectedEmailIDs(invitedMembers.map((n) => n.email));
      setAllItemsSelected(true);
    } else {
      setSelectedEmailIDs([]);
      setAllItemsSelected(false);
    }
  };

  const onSelectItem = (email, value) => {
    setAllItemsSelected(false);
    if (value) {
      return setSelectedEmailIDs([...selectedEmailIDs, email]);
    }
    return setSelectedEmailIDs(
      selectedEmailIDs.filter((currentEmail) => currentEmail !== email)
    );
  };

  useEffect(() => {
    setColumns([
      {
        key: 'name',
        label: t('administrateUsers.name'),
        sticky: true,
        stickySecondColumn: true,
        maskValue: true,
      },
      {
        key: 'email',
        label: t('administrateUsers.email'),
        maskValue: true,
      },
      {
        customSort: (a, b, order) => {
          const keyA = getRoleText(a.role);
          const keyB = getRoleText(b.role);

          return order === ORDER_TYPES.ASC
            ? keyA.localeCompare(keyB, locale, { sensitivity: 'base' })
            : keyB.localeCompare(keyA, locale, { sensitivity: 'base' });
        },
        key: 'role',
        label: t('administrateUsers.role'),
        type: CELL_TYPES.COMPONENT,
        render: (row) => getRoleText(row.role),
      },
      {
        key: 'status',
        label: t('administrateUsers.status'),
        type: CELL_TYPES.COMPONENT,
        render: (row) => getStatusText(row.status),
      },
      {
        key: 'source',
        label: t('administrateUsers.invitedSource'),
        type: CELL_TYPES.COMPONENT,
        render: (row) => getSourceText(row.source),
      },
      {
        key: 'invitedDate',
        label: t('administrateUsers.invitedDate'),
        type: CELL_TYPES.DATE,
      },
      {
        key: 'lastReminderAt',
        label: t('administrateUsers.lastReminder'),
        type: CELL_TYPES.COMPONENT,
        render: (row) => (
          <>
            {row.lastReminderAt &&
              `${formatDate({
                date: row.lastReminderAt,
              })} - `}
            {`${t('administrateUsers.total')}: ${row.numberOfReminder || 0}`}
          </>
        ),
      },
    ]);
  }, []);

  return (
    <>
      <Table
        allItemsSelected={allItemsSelected}
        ariaLabel="Invited members table"
        columns={columns}
        defaultOrderBy="invitedDate"
        items={invitedMembers}
        keyField="email"
        onSelectAll={onSelectAll}
        onSelectItem={onSelectItem}
        selectable
        selectedItemIds={selectedEmailIDs}
      />
      <SelectedMembersCounterText>
        {t('administrateUsers.selectedMemberCounterText', {
          count: selectedEmailIDs.length,
        })}
      </SelectedMembersCounterText>
      <ActionDropdownWrapper>
        <ActionDropdown
          titleText={t('administrateUsers.action')}
          applyButtonText={t('administrateUsers.apply')}
          options={actionOptions}
          selectedValue={selectedAction}
          onChange={onActionChange}
          onApplyHandler={(event) => {
            if (selectedEmailIDs.length > 0) {
              setShowConfirmActionDialog(true);
            }
            setResetSelection(false);
            event.preventDefault();
          }}
          disabled={selectedEmailIDs.length <= 0}
          showApplyButton={!!selectedAction}
        />
      </ActionDropdownWrapper>
      {selectedAction && !showMultipleFeedback && (
        <ConfirmationDialog
          open={showConfirmActionDialog}
          ariaLabel={selectedActionText}
          title={selectedActionText}
          description={t('administrateUsers.confirmationActionMessage', {
            actionText: selectedActionText.toLowerCase(),
          })}
          cancelText={t('generic.cancel')}
          confirmText={t('generic.confirm')}
          onConfirm={() => onApplyHandler(false)}
          onCancel={() => onDialogClosehandler()}
          onClose={() => onDialogClosehandler()}
          formStatus={formStatus}
        />
      )}
      {showMultipleFeedback && (
        <MultipleFeedback
          formStatus={formStatus}
          onClose={() => onDialogClosehandler()}
          closeText={t('generic.close')}
          actionSuccessText={(count) =>
            t(`administrateUsers.${selectedAction}invitationSentSuccessfully`, {
              count,
            })
          }
          noSuccessText={t(
            `administrateUsers.${selectedAction}noSucceededInvitations`
          )}
          actionFailureText={(count) =>
            t(`administrateUsers.${selectedAction}invitationSentFailure`, {
              count,
            })
          }
          noFailureText={t('administrateUsers.noFailedInvitations')}
          succeededText={t('administrateUsers.succeeded')}
          failedText={t('administrateUsers.failed')}
          headerText={t(`administrateUsers.${selectedAction}invitationResults`)}
          failureList={failureList}
          successList={successList}
          notExistingInvitationText={t(
            'administrateUsers.notExistingInvitationText'
          )}
          recentlyAddedText={t('administrateUsers.recentlyAddedText')}
        />
      )}
    </>
  );
};

InvitedMembersTable.propTypes = {
  formStatus: PropTypes.shape({
    success: PropTypes.bool,
    message: PropTypes.string,
  }),
  invitedMembers: PropTypes.array.isRequired,
  selectedAction: PropTypes.string,
  selectedActionText: PropTypes.string,
  actionOptions: PropTypes.array,
  onActionChange: PropTypes.func,
  onActionApplyHandler: PropTypes.func,
  onConfirmationDialogCloseHandler: PropTypes.func,
  showMultipleFeedback: PropTypes.bool,
  successList: PropTypes.array,
  failureList: PropTypes.array,
};

InvitedMembersTable.defaultProps = {
  formStatus: null,
  selectedAction: null,
  actionOptions: [],
  onActionChange: () => {},
  onActionApplyHandler: () => {},
  onConfirmationDialogCloseHandler: () => {},
};

export default InvitedMembersTable;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import useMediaQuery from '@mui/material/useMediaQuery';

import CircularSpinner from 'shared/atoms/CircularSpinner';
import Alert from 'shared/atoms/Alert';
import Button from 'shared/atoms/Button';
import Form from 'shared/atoms/Form';
import FormInput from 'shared/atoms/FormFields/FormInput';
import T from 'shared/atoms/Typography';
import theme from 'shared/themes/default';
import { useForm } from 'shared/utils';

const StyledButtonWrapper = styled.div`
  display: flex;
  gap: ${theme.spacing.m1};
  justify-content: end;
  margin-top: ${theme.spacing.m3};

  ${theme.breakpoints.mobile} {
    flex-direction: column-reverse;
    margin-top: ${theme.spacing.m1};
  }
`;

const LoginUsername = ({
  email,
  errorMessage,
  loading,
  onGoBack,
  onRegister,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.mobile);

  const {
    changeValues,
    disableSubmit,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    properties,
    submitting,
    values,
  } = useForm({
    fields: {
      email: {
        default: '',
        lowerCase: true,
        required: true,
        type: 'email',
        validationMessage: t('registration.emailValidation'),
      },
    },
    onSubmit,
  });

  useEffect(() => {
    if (email) {
      changeValues({ email });
    }
  }, [email]);

  const sxInput = {
    marginBottom: theme.spacing.m2,
  };

  if (loading) {
    return <CircularSpinner static />;
  }

  return (
    <Form onSubmit={handleSubmit}>
      <T
        align="center"
        fontWeight="bold"
        sx={{ mb: theme.spacing.s }}
        phoneVariant="headingS"
        variant="headingM"
      >
        {t('registration.login')}
      </T>
      <T align="center" sx={{ mb: theme.spacing.l }}>
        {t('registration.andYouWillBeReadyToLearn')}
      </T>
      {errorMessage && (
        <Alert
          message={errorMessage}
          severity="error"
          sx={{ mb: theme.spacing[isMobile ? 'm2' : 'l'] }}
        />
      )}
      <FormInput
        id="email"
        name="email"
        label={t('registration.email')}
        placeholder={t('registration.emailPlaceholder')}
        autoFocus
        sx={sxInput}
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        properties={properties}
        values={values}
      />
      <StyledButtonWrapper>
        {onGoBack && (
          <Button
            disabled={submitting}
            onClick={onGoBack}
            type="secondaryOutline"
          >
            {t('generic.goBack')}
          </Button>
        )}
        {onRegister && (
          <Button
            disabled={submitting}
            onClick={onRegister}
            type="secondaryOutline"
          >
            {t('registration.register')}
          </Button>
        )}
        <Button disabled={disableSubmit} loading={submitting} submit>
          {t('registration.login')}
        </Button>
      </StyledButtonWrapper>
    </Form>
  );
};

LoginUsername.propTypes = {
  email: PropTypes.string,
  errorMessage: PropTypes.string,
  loading: PropTypes.bool,
  onGoBack: PropTypes.func,
  onRegister: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

export default LoginUsername;

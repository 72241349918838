import { useEffect, useState } from 'react';

export default ({ listAllCourses }) => {
  const [allCourses, setAllCourses] = useState([]);
  const [nextToken, setNextToken] = useState('');
  const [loadingCourses, setLoadingCourses] = useState(true);
  const [errorCourses, setErrorCourses] = useState(false);

  const handleError = (error) => {
    console.error('Error calling listAllCourses', error);
    setErrorCourses(true);
    setNextToken(null);
    setAllCourses([]);
    setLoadingCourses(false);
  };

  const fetch = async (nextToken) => {
    try {
      const { data, error } = await listAllCourses(nextToken);
      if (error) {
        handleError(error);
      }
      if (data) {
        const items = data.listCourses.items.filter(
          (course) => !course.archivedScheduledAt
        );
        setAllCourses([...allCourses, ...items]);
        const { nextToken } = data.listCourses;
        setNextToken(nextToken);
        if (nextToken === null) {
          setLoadingCourses(false);
        }
      }
    } catch (error) {
      handleError(error);
    }
  };

  const fetchCourses = () => {
    setLoadingCourses(true);
    setNextToken(null);
    setAllCourses([]);
    setErrorCourses(false);
  };

  useEffect(() => {
    if (loadingCourses) {
      fetch();
    }
  }, [loadingCourses]);

  useEffect(() => {
    if (nextToken) {
      fetch(nextToken);
    }
  }, [nextToken]);

  return {
    allCourses,
    loadingCourses,
    fetchCourses,
    errorCourses,
  };
};

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import CircularSpinner from 'shared/atoms/CircularSpinner';
import HorizontalRule from 'shared/atoms/HorizontalRule';
import Button from 'shared/atoms/Button';
import Info from 'shared/molecules/Info';
import Select from 'shared/atoms/Select';
import T from 'shared/atoms/Typography';
import theme from 'shared/themes/default';
import { PLAYLIST_TYPE } from 'shared/const';
import { usePlaylist } from './context';
import PlaylistOverview from './components/PlaylistOverview';

const BlockWrapper = styled.div`
  padding: 10px 0px 10px 0px;
`;

const InfoLine = styled.div`
  align-items: center;
  column-gap: ${theme.spacing.s};
  display: flex;
`;

const ManagerSelectWrapper = styled.div`
  align-items: center;
  display: flex;
  column-gap: ${theme.spacing.s};
  ${theme.breakpoints.mobile} {
    width: 100%;
  }
`;

const PlaylistAdministration = ({ onClickSubscribe, isBackOffice }) => {
  const { t } = useTranslation();

  const {
    playlists: { allPlaylists, loadingPlaylists },
    handleSelectManager,
    handleSelectType,
    hasSubscription,
    isSubscriptionAdmin,
    managerSelected,
    organization,
    playlists,
    typeSelected,
    userId,
  } = usePlaylist();

  const { playlistManagers } = playlists;

  const { id: organizationId } = organization || {};
  const isGlobalOrganization = organizationId === 'global';

  const isMobile = useMediaQuery(theme.breakpoints.mobile);

  const managers = [];

  if (userId) {
    managers.push({
      id: userId,
      name: t('playlist.myPlaylists'),
    });
  }

  managers.push(
    { id: 'all', name: t('playlist.allPlaylists') },
    ...playlistManagers.filter(
      (manager) => isSubscriptionAdmin && (!userId || manager.id !== userId)
    )
  );

  const managerOptions = managers.map((manager) => ({
    value: manager.id,
    label: manager.name,
    key: manager.id,
  }));

  const defaultValue = managerOptions.find(
    ({ value }) => value === managerSelected
  )
    ? managerSelected
    : 'all';

  const typeOptions = [
    {
      value: 'ALL',
      label: t('playlist.seeAll'),
      key: 'ALL',
    },
    {
      value: PLAYLIST_TYPE.PLAYLIST,
      label: t('playlist.allPlaylists'),
      key: PLAYLIST_TYPE.PLAYLIST,
    },
    {
      value: PLAYLIST_TYPE.NANO_DEGREE,
      label: t('playlist.allNanodegrees'),
      key: PLAYLIST_TYPE.NANO_DEGREE,
    },
  ];
  return (
    <>
      {!hasSubscription && (
        <>
          <T
            color={theme.palette.lightPure}
            phoneVariant="headingXs"
            sx={{
              marginTop: isMobile ? undefined : theme.spacing.m2,
              marginBottom: isMobile ? theme.spacing.m1 : theme.spacing.m1,
            }}
            variant="headingS"
          >
            {t('administrateUsers.noActiveSubscription')}
          </T>
          {isSubscriptionAdmin ? (
            <>
              <T>{t('administrateUsers.suggestSubscriptionPlaylist')}</T>
              <T>{t('administrateUsers.subscribeAgain')}</T>
              <BlockWrapper>
                <Button
                  ariaLabel={t('administrateUsers.subscribe')}
                  onClick={() => onClickSubscribe()}
                  theme="dark"
                  type="primary"
                >
                  {t('administrateUsers.subscribe')}
                </Button>
              </BlockWrapper>
            </>
          ) : (
            <T>{t('administrateUsers.contactYourAdmin')}</T>
          )}
          <HorizontalRule />
        </>
      )}
      <T
        color={theme.palette.lightPure}
        id="see-playlist-text"
        phoneVariant="headingXs"
        sx={{
          marginTop: isMobile ? theme.spacing.m3 : theme.spacing.xl,
        }}
        variant="headingS"
      >
        {isGlobalOrganization
          ? t('playlist.playlistsAndNanodegrees')
          : t('playlist.playlists')}
      </T>
      {isSubscriptionAdmin && isGlobalOrganization && (
        <>
          <T
            color={theme.palette.lightInteracting}
            sx={{
              marginTop: theme.spacing.xs,
              marginBottom: isMobile ? theme.spacing.m2 : theme.spacing.m3,
            }}
            variant="paragraph"
          >
            {t('playlist.seeAllOrFilterByType')}
          </T>
          <InfoLine>
            <Select
              id="type-picker"
              fullWidth={false}
              items={typeOptions}
              name="type-picker"
              onChange={({ value }) => handleSelectType(value)}
              hasAction
              hasBackground
              value={typeSelected}
            />
            {(typeSelected === PLAYLIST_TYPE.PLAYLIST ||
              typeSelected === PLAYLIST_TYPE.NANO_DEGREE) && (
              <Info theme="dark">
                {typeSelected === PLAYLIST_TYPE.NANO_DEGREE
                  ? t('nanodegree.sortDescription')
                  : t('playlist.sortDescription')}
              </Info>
            )}
          </InfoLine>
        </>
      )}
      {isSubscriptionAdmin && !isGlobalOrganization && (
        <>
          <T
            color={theme.palette.lightInteracting}
            sx={{
              marginTop: theme.spacing.xs,
              marginBottom: isMobile ? theme.spacing.m2 : theme.spacing.m3,
            }}
            variant="paragraph"
          >
            {t('playlist.seeAllOrFilter')}
          </T>
          <ManagerSelectWrapper>
            <Select
              id="manager-picker"
              fullWidth={isMobile}
              items={managerOptions}
              name="manager-picker"
              onChange={({ value }) => handleSelectManager(value)}
              hasAction
              hasBackground
              theme="light"
              sx={{
                flex: isMobile ? '1 1 auto' : 'none',
              }}
              value={defaultValue}
            />
            {defaultValue === 'all' && (
              <Info theme="dark">{t('playlist.sortDescription')}</Info>
            )}
          </ManagerSelectWrapper>
        </>
      )}
      {allPlaylists && !loadingPlaylists ? (
        <PlaylistOverview isBackOffice={isBackOffice} />
      ) : (
        <CircularSpinner static />
      )}
    </>
  );
};

PlaylistAdministration.propTypes = {
  onClickSubscribe: PropTypes.func,
  isBackOffice: PropTypes.bool,
};

export default PlaylistAdministration;

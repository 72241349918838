import React from 'react';
import PropTypes from 'prop-types';

import FormControlLabel from '@mui/material/FormControlLabel';

import Toggle from 'shared/atoms/Toggle';
import T from 'shared/atoms/Typography';
import { BACKGROUND_THEMES } from 'shared/const';
import theme from 'shared/themes/default';

const FormToggle = ({
  disabled: disabledProp,
  id,
  handleChange,
  label,
  name,
  properties,
  theme: themeLayout,
  values,
}) => {
  const { disabled } = properties[name] || {};

  const onChangeHandler = (checked) => {
    handleChange({ name, value: checked });
  };

  const getColor = () => {
    if (disabledProp === undefined ? disabled : disabledProp) {
      return themeLayout === 'light'
        ? theme.palette.disabledLight
        : theme.palette.disabledDark;
    }
    return themeLayout === 'light'
      ? theme.palette.mainDark
      : theme.palette.lightPure;
  };

  return (
    <FormControlLabel
      control={
        <Toggle
          id={id}
          checked={values[name]}
          disabled={disabledProp === undefined ? disabled : disabledProp}
          onChange={onChangeHandler}
          theme={themeLayout}
        />
      }
      label={
        <T
          color={getColor()}
          component="span"
          fontWeight="medium"
          sx={{ marginLeft: theme.spacing.s }}
        >
          {label}
        </T>
      }
      sx={{ margin: 0 }}
    />
  );
};

FormToggle.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  properties: PropTypes.object,
  theme: PropTypes.oneOf(BACKGROUND_THEMES),
  values: PropTypes.object,
};

FormToggle.defaultProps = {
  handleChange: () => {},
  properties: {},
  theme: 'dark',
  values: {},
};

export default FormToggle;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from 'shared/atoms/Icon';
import T from 'shared/atoms/Typography';
import theme from 'shared/themes/default';

const TabsWrapper = styled.div`
  display: flex;
  gap: ${theme.spacing.s};
`;

const TabWrapper = styled.div`
  align-items: center;
  background-color: ${theme.palette.darkWrapper};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border: 1px solid
    ${({ selected }) => (selected ? theme.palette.brand : 'transparent')};
  cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};
  display: flex;
  gap: ${theme.spacing.s};
  padding: ${theme.spacing.s} ${theme.spacing.m1};
`;

const TabsMenu = ({ selectedTab, setSelectedTab, tabsConfig, disabled }) => (
  <TabsWrapper>
    {tabsConfig.map(({ key, icon, label }) => {
      const selected = key === selectedTab;

      let mainColor = theme.palette.disabledDark;
      if (!disabled) {
        mainColor = selected ? theme.palette.brand : theme.palette.lightPure;
      } else if (selected) {
        mainColor = theme.palette.brand;
      }

      return (
        <TabWrapper
          key={key}
          onClick={() => !disabled && setSelectedTab(key)}
          selected={selected}
          disabled={disabled}
        >
          {icon && <Icon color={mainColor} icon={icon} />}
          <T
            color={mainColor}
            fontWeight={selected ? 'semibold' : 'regular'}
            sx={{ textTransform: 'uppercase' }}
          >
            {label}
          </T>
        </TabWrapper>
      );
    })}
  </TabsWrapper>
);

TabsMenu.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  tabsConfig: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.object,
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  disabled: PropTypes.bool,
};

export default TabsMenu;

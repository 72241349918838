import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { actionsOnContentOwnership } from 'shared/const';

export default function useUsers({
  invitedMembers,
  organizationId,
  registeredMembers,
  requestToDeleteUserGroups,
  requestToDeleteUserPlaylists,
  requestToTransferUserGroups,
  requestToTransferUserPlaylists,
  removeFromOrganization,
  setFormStatus,
  setRegisteredMembers,
}) {
  const { t } = useTranslation();

  const [deleteUser, setDeleteUser] = useState(false);
  const [manageContentOwnership, setManageContentOwnership] = useState(false);
  const [transferContentOwnershipFor, setTransferContentOwnershipFor] =
    useState(null);
  const [userToDelete, setUserToDelete] = useState(null);

  const transferContentChoiceOptions = [
    {
      value: actionsOnContentOwnership.delete,
      label: t('administrateUserRole.deleteContent'),
    },
    {
      value: actionsOnContentOwnership.transfer,
      label: t('administrateUserRole.transferOwnershipOfContent'),
      checked: true,
    },
  ];

  const isUserInOrganization = (email) => {
    return [...invitedMembers, ...registeredMembers].some(
      (user) => user.email === email
    );
  };

  const onDeleteUser = (user) => {
    const ownedContent = user.ownedGroupsNumber || user.ownedPlaylistsNumber;
    if (ownedContent) {
      setTransferContentOwnershipFor(user);
      setManageContentOwnership(true);
    } else {
      setDeleteUser(true);
    }

    setUserToDelete(user);
  };

  const onDeleteUserConfirm = async () => {
    const { id } = userToDelete;
    const { data, error } = await removeFromOrganization(organizationId)({
      input: { userId: id },
    });

    if (error) {
      setFormStatus({
        success: false,
        message: t('administrateUsers.deletedAccessFailure'),
      });
    }

    if (data) {
      setFormStatus({
        success: true,
        message: t('administrateUsers.deletedAccessSuccess'),
      });
      const filteredRegisteredMembers = registeredMembers.filter(
        (member) => member.id !== id
      );
      setRegisteredMembers(filteredRegisteredMembers);
    }
  };

  const onSubmitManageContentOwnership = async (values) => {
    const { userSub, actionOnContent } = values;

    let userContentDeleted = false;
    if (actionOnContent === actionsOnContentOwnership.delete) {
      userContentDeleted =
        (await requestToDeleteUserGroups(userSub)) &&
        (await requestToDeleteUserPlaylists(userSub));
      if (!userContentDeleted) {
        setFormStatus({
          success: false,
          message: t('administrateUsers.deletedAccessFailure'),
        });
      }
    }

    let userContentTransferred = false;
    if (actionOnContent === actionsOnContentOwnership.transfer) {
      userContentTransferred =
        (await requestToTransferUserGroups(values)) &&
        (await requestToTransferUserPlaylists(values));
      if (!userContentTransferred) {
        setFormStatus({
          success: false,
          message: t('administrateUsers.deletedAccessFailure'),
        });
      }
    }

    if (userContentDeleted || userContentTransferred) {
      const { data, error } = await removeFromOrganization(organizationId)({
        input: { userId: userSub },
      });
      if (error) {
        setFormStatus({
          success: false,
          message: t('administrateUsers.deletedAccessFailure'),
        });
      }

      if (data) {
        const { id: deletedUserSub } = data.removeFromOrganization;
        setRegisteredMembers(
          registeredMembers.filter((member) => member.id !== deletedUserSub)
        );

        setFormStatus({
          success: true,
          message: t('administrateUsers.deletedAccessSuccess'),
        });
      }
    } else {
      setFormStatus({
        success: false,
        message: t('administrateUsers.deletedAccessFailure'),
      });
    }
  };

  return {
    deleteUser,
    isUserInOrganization,
    manageContentOwnership,
    onDeleteUser,
    onDeleteUserConfirm,
    onSubmitManageContentOwnership,
    setDeleteUser,
    setManageContentOwnership,
    setUserToDelete,
    transferContentChoiceOptions,
    transferContentOwnershipFor,
  };
}

import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Tooltip from 'shared/atoms/Tooltip';
import Menu from 'shared/molecules/Menu';
import Table, { CELL_TYPES, ORDER_TYPES } from 'shared/organisms/Table';

import theme from 'shared/themes/default';

import { useOrganizationMembers } from '../../context';

const ToolTipTextWrapper = styled.div`
  font-weight: bold;
  width: fit-content;
  &:hover {
    cursor: pointer;
    color: ${theme.palette.brand};
  }
`;

const GroupsOverviewTable = ({ groupsInfo, onTransferOwnership }) => {
  const { t } = useTranslation();

  const {
    groups: { onDeleteGroup },
    isManager,
    registeredMembers,
  } = useOrganizationMembers();

  const [columns, setColumns] = useState([]);

  useEffect(() => {
    let newColumns = [
      {
        key: 'name',
        label: t('administrateUsers.groupName'),
        sticky: true,
        type: CELL_TYPES.COMPONENT,
        render: (row) => (
          <Tooltip
            title={row.description}
            maxWidth="140px"
            placement="bottom"
            arrow
          >
            <ToolTipTextWrapper>{row.name}</ToolTipTextWrapper>
          </Tooltip>
        ),
      },
      { key: 'ownedByName', label: t('administrateUsers.owner') },
      {
        key: 'createdAt',
        label: t('administrateUsers.createdOn'),
        type: CELL_TYPES.DATE,
      },
      {
        key: 'updatedAt',
        label: t('administrateUsers.editedOn'),
        type: CELL_TYPES.DATE,
      },
      {
        key: 'membersCount',
        label: t('administrateUsers.members'),
        type: CELL_TYPES.COMPONENT,
        render: (row) => {
          const members = registeredMembers.reduce((acc, member) => {
            return [
              ...acc,
              ...(member.groups.length &&
              member.groups.filter((group) => group.id === row.id).length
                ? [member.name]
                : []),
            ];
          }, []);

          return (
            <Tooltip
              title={members.join(', ')}
              maxWidth="220px"
              placement="bottom"
              arrow
            >
              <ToolTipTextWrapper>{row.membersCount}</ToolTipTextWrapper>
            </Tooltip>
          );
        },
      },
      {
        key: 'delete',
        disableSorting: true,
        type: CELL_TYPES.COMPONENT,
        render: (row) => (
          <Menu
            menuItems={[
              { label: t('generic.delete'), handler: () => onDeleteGroup(row) },
              {
                label: t('administrateUsers.transferOwnership'),
                handler: () => onTransferOwnership(row),
              },
            ]}
            theme="dark"
          />
        ),
      },
    ];

    if (isManager) {
      newColumns = newColumns.filter(
        (item) => item.key !== 'ownedBy' && item.key !== 'delete'
      );
    }

    setColumns(newColumns);
  }, [isManager, onDeleteGroup, onTransferOwnership, registeredMembers]);

  return (
    <Table
      ariaLabel="groups overview table"
      columns={columns}
      items={groupsInfo}
      defaultOrder={ORDER_TYPES.ASC}
      defaultOrderBy="name"
    />
  );
};

GroupsOverviewTable.propTypes = {
  groupsInfo: PropTypes.array.isRequired,
  onTransferOwnership: PropTypes.func.isRequired,
};

export default GroupsOverviewTable;

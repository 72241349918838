export const validateCourseFiles = (
  files,
  locale,
  isSubtitles,
  numEpisodes
) => {
  const regex = /^([0-9]+)_(?:(.+)\.mp4|([a-z]{2}_)?(.+)\.(srt|vtt))$/i;

  const episodes = {};
  const validatedFiles = {};
  const subtitleData = {};
  const ignored = [];
  const errors = [];

  for (let i = 0; i < Object.keys(files).length; i += 1) {
    const uuid = Object.keys(files)[i];
    const file = { ...files[uuid], error: null };

    if (!file.aborted) {
      const fileName = file.name;
      const matches = fileName.match(regex);

      if (matches) {
        const prefix = parseInt(matches[1], 10);

        if (Number.isNaN(prefix) || prefix < 0) {
          ignored.push(file);
        } else if (matches[2]) {
          if (!episodes[prefix]) {
            if (!isSubtitles) {
              episodes[prefix] = { file, name: matches[2] };
            } else {
              file.error = `Only subtitles allowed`;
              errors.push(file.error);
            }
          } else {
            file.error = `Repeated episode number: ${prefix}`;
            errors.push(file.error);
          }
        } else if (!subtitleData[prefix]) {
          subtitleData[prefix] = {
            file,
            locale: matches[3]?.slice(0, 2).toLowerCase(),
            name: matches[4],
          };
        } else {
          file.error = `Repeated subtitle number: ${prefix}`;
          errors.push(file.error);
        }
      } else {
        file.error = `Invalid file name`;
        errors.push(file.error);
      }
    }
    validatedFiles[uuid] = file;
  }

  const videoKeys = Object.keys(episodes)
    .map((key) => parseInt(key, 10))
    .sort((a, b) => a - b);
  const keys = Array.from({ length: Math.max(...videoKeys) }, (_, i) => i + 1);
  if (videoKeys.includes(0)) {
    keys.unshift(0);
  }

  keys.forEach((key) => {
    if (key === 0) {
      episodes[key].trailer = true;
    }
    if (!videoKeys.includes(key)) {
      episodes[key] = { file: null, name: `Placeholder for episode ${key}` };
    }
  });

  const subtitleEntries = Object.entries(subtitleData);

  for (let i = 0; i < subtitleEntries.length; i += 1) {
    const [key, value] = subtitleEntries[i];

    if (isSubtitles && key > numEpisodes) {
      value.file.error = `Subtitle for episode ${key} does not match with any episode`;
      errors.push(value.file.error);
    } else {
      if (isSubtitles) {
        episodes[key] = { file: null };
      }

      if (key in episodes) {
        if (value.locale !== locale) {
          if (value.locale) {
            value.file.error = `Subtitle for episode ${key} has an unsupported locale, ${value.locale}`;
            errors.push(value.file.error);
          } else {
            value.file.error = `Subtitle for episode ${key} has no locale`;
            errors.push(value.file.error);
          }
        } else if (
          episodes[key].file &&
          value.name.toLowerCase() !== episodes[key].name.toLowerCase()
        ) {
          value.file.error = `Subtitle for episode ${key} does not match the name of the corresponding episode`;
          errors.push(value.file.error);
        } else {
          episodes[key].subtitles = value.file;
          episodes[key].name = value.name;
        }
      } else if (key > numEpisodes) {
        value.file.error = `Subtitle for episode ${key} does not match with a video file`;
        errors.push(value.file.error);
      }
    }
  }

  return { episodes, validatedFiles, ignored, errors };
};

export const parseCourseFiles = (files, locale, isSubtitles) => {
  const regex = /^([0-9]+)_(?:(.+)\.mp4|([a-z]{2}_)?(.+)\.(srt|vtt))$/i;

  const videoData = {};
  const subtitleData = {};
  const ignored = [];

  for (let i = 0; i < files.length; i += 1) {
    const file = files[i];
    const matches = file.match(regex);

    if (matches) {
      const prefix = parseInt(matches[1], 10);

      if (Number.isNaN(prefix) || prefix < 0) {
        ignored.push(file);
      } else if (matches[2]) {
        if (!videoData[prefix]) {
          if (!isSubtitles) {
            videoData[prefix] = { file, name: matches[2] };
          } else {
            ignored.push(file);
          }
        } else {
          throw new Error(`Repeated episode number: ${prefix}`);
        }
      } else if (!subtitleData[prefix]) {
        subtitleData[prefix] = {
          file,
          locale: matches[3]?.slice(0, 2).toLowerCase(),
          name: matches[4],
        };
      } else {
        throw new Error(`Repeated subtitle number: ${prefix}`);
      }
    } else {
      ignored.push(file);
    }
  }

  const videoKeys = Object.keys(videoData)
    .map((key) => parseInt(key, 10))
    .sort((a, b) => a - b);
  const keys = Array.from({ length: Math.max(...videoKeys) }, (_, i) => i + 1);
  if (videoKeys.includes(0)) {
    keys.unshift(0);
  }

  keys.forEach((key) => {
    if (key === 0) {
      videoData[key].trailer = true;
    }
    if (!videoKeys.includes(key)) {
      videoData[key] = { file: null, name: `Placeholder for episode ${key}` };
    }
  });

  const subtitleEntries = Object.entries(subtitleData);

  for (let i = 0; i < subtitleEntries.length; i += 1) {
    const [key, value] = subtitleEntries[i];

    if (isSubtitles) {
      videoData[key] = { file: null };
    }

    if (key in videoData) {
      if (value.locale !== locale) {
        if (value.locale) {
          throw new Error(
            `Subtitle for episode ${key} has an unsupported locale, ${value.locale}`
          );
        } else {
          throw new Error(`Subtitle for episode ${key} has no locale`);
        }
      } else if (
        videoData[key].file &&
        value.name.toLowerCase() !== videoData[key].name.toLowerCase()
      ) {
        throw new Error(
          `Subtitle for episode ${key} does not match the name of the corresponding episode`
        );
      } else {
        videoData[key].subtitles = value.file;
        videoData[key].name = value.name;
      }
    } else {
      ignored.push(value.file);
    }
  }

  return { episodes: videoData, ignored };
};

export const getUploadUrl = (variables) => ({
  query: `query getUploadUrl(
    $fileName: String!,
    $partNumber: Int!,
    $uploadId: ID!
  ) {
    getUploadUrl(fileName: $fileName, partNumber: $partNumber, uploadId: $uploadId)
  }`,
  variables,
});

export const startUpload = (variables) => ({
  query: `query startUpload(
    $fileName: String!,
    $contentType: String!
  ) {
    startUpload(fileName: $fileName, contentType: $contentType) {
      fileName
      id
    }
  }`,
  variables,
});

export const completeUpload = (variables) => ({
  query: `query completeUpload(
    $fileName: String!,
    $parts: [UploadPart]!,
    $uploadId: ID!
  ) {
    completeUpload(fileName: $fileName, parts: $parts, uploadId: $uploadId) {
      bucket
      key
      region
    }
  }`,
  variables,
});

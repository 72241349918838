import { useEffect, useState } from 'react';
import { Auth, Hub } from 'aws-amplify';

import { getLocale, getDomainName } from '../../../config';

export default () => {
  const [loadingUser, setLoadingUser] = useState(true);
  const [loadingSettings, setLoadingSettings] = useState(true);
  const [user, setUser] = useState();

  // TODO: Fetch settings from the backend, cannot be done at the moment as the whole API requires a user
  const domain = getDomainName();
  const locale = getLocale();

  const fetchUser = async () => {
    // TODO: userInfo gives the same data currentAuthenticatedUser gives, but does not fail if there is no user
    // Check if the flow is correct with respect to token expiration
    const userInfo = await Auth.currentUserInfo();

    if (userInfo) {
      // TODO: Try to figure out how to periodically refresh the token
      // Optimise the user object, we probably don't need that much information
      const { attributes, signInUserSession } =
        await Auth.currentAuthenticatedUser();
      const { name, email } = attributes;

      setUser({
        attributes: { attributes, ...userInfo.attributes },
        email,
        name,
        signInUserSession,
      });
    } else {
      setUser();
    }

    setLoadingUser(false);
  };

  const fetchSettings = () => {
    setTimeout(() => setLoadingSettings(false), 3000);
  };

  useEffect(() => {
    fetchUser();
    fetchSettings();

    Hub.listen('auth', async ({ payload: { event } }) => {
      if (event === 'signIn') {
        fetchUser();
      } else if (event === 'signOut') {
        setUser();
      }
    });
  }, []);

  return {
    domain,
    loadingSettings,
    loadingUser,
    locale,
    user,
  };
};

import whiteEsLogo from './assets/VC-logo-es_hvit.png';
import whiteNoLogo from './assets/VC-logo-no_hvit.png';
import esLogo from './assets/VC-es-logo.png';
import noLogo from './assets/VC-no-logo.png';

export const whiteVCLogo = (domain) => {
  let logo;
  switch (domain) {
    case 'es':
      logo = whiteEsLogo;
      break;
    default:
      logo = whiteNoLogo;
      break;
  }
  return logo;
};

export const videocationLogo = (domain) => {
  let logo;
  switch (domain) {
    case 'es':
      logo = esLogo;
      break;
    default:
      logo = noLogo;
      break;
  }

  return logo;
};

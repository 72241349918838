import React from 'react';

import PageTemplate from '../components/templates/PageTemplate';
import PlaylistView from '../components/organisms/PlaylistView';

const PlaylistContainer = () => {
  return (
    <PageTemplate>
      <PlaylistView />
    </PageTemplate>
  );
};

export default PlaylistContainer;

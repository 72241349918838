export const listInvitations = (organizationId, nextToken) => ({
  query: `query listInvitations($organizationId: String!, $limit: Int, $nextToken: String) {
    listInvitations(organizationId: $organizationId, limit: $limit, nextToken: $nextToken) {
      items {
        email
        invitedDate
        lastReminderAt
        name
        numberOfReminder
        role
        source
        status
      }
      nextToken
    }
  }`,
  variables: {
    organizationId,
    limit: 1000,
    nextToken,
  },
});

export const getOrganizationMembers = (id, nextToken) => ({
  query: `query getOrganizationMembers($id: ID!, $limit: Int, $nextToken: String) {
    getOrganizationMembers(id: $id, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        roleId
        ownedPlaylistsNumber
        ownedGroupsNumber
        createdAt
        lastLoginAt
        groups {
          id
          name
        }
      }
      nextToken
    }
  }`,
  variables: {
    id,
    limit: 1000,
    nextToken,
  },
});

export const downloadInvitations = (organizationId) => ({
  query: `query downloadInvitations($organizationId: String!, $limit: Int, $locale: String) {
    downloadInvitations(organizationId: $organizationId, limit: $limit, locale: $locale) {
      cloudfrontUrl
      fileName
    }
  }`,
  variables: {
    organizationId,
    limit: 1000,
    locale: 'en',
  },
});

export const downloadOrganizationMembers = (organizationId) => ({
  query: `query downloadOrganizationMembers($organizationId: String!, $limit: Int, $locale: String) {
    downloadOrganizationMembers(organizationId: $organizationId, limit: $limit, locale: $locale) {
      cloudfrontUrl
      fileName
    }
  }`,
  variables: {
    organizationId,
    limit: 1000,
    locale: 'en',
  },
});

export const getActiveSubscriptionByOrganization = `query getActiveSubscriptionByOrganization
  ($organizationId: ID!) {
    getActiveSubscriptionByOrganization(organizationId: $organizationId) {
      id
      type
      name
      nameTranslations
      description
      descriptionTranslations
      licenses
      unlimited
    }
  }
`;

export const listGroups = (organizationId, nextToken) => ({
  query: `query listGroups($organizationId: ID!, $limit: Int, $nextToken: String) {
    listGroups(organizationId: $organizationId, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        ownedBy
        ownedByName
        updatedAt
        membersCount
      }
      nextToken
    }
  }`,
  variables: {
    organizationId,
    limit: 1000,
    nextToken,
  },
});

export const downloadOrganizationGroups = (organizationId) => ({
  query: `query downloadOrganizationGroups($organizationId: ID!) {
    downloadOrganizationGroups(organizationId: $organizationId) {
      cloudfrontUrl
      fileName
    }
  }`,
  variables: {
    organizationId,
  },
});

export const listUsersByGroup = (nextToken, groupId) => ({
  name: 'listUsersByGroup',
  query: `query listUsersByGroup($id: ID!, $limit: Int, $nextToken: String) {
    listUsersByGroup(id: $id, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
      }
      nextToken
    }
  }`,
  variables: {
    id: groupId,
    limit: 1000,
    nextToken,
  },
  initialize: true,
});

export const getOrganization = `query getOrganization(
  $id: ID!
) {
  getOrganization(id: $id) {
    id
    name
  }
}`;

export const listOrganizationsNames = `query listOrganizations(
  $domain: String!
  $nextToken: String
) {
  listOrganizations (domain: $domain, limit: 1000, nextToken: $nextToken) {
    items {
      id
      domain
      name
      archived
    }
    nextToken
  }
}`;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PersonIcon from '@mui/icons-material/Person';

import Chip from '@shared/atoms/Chip';
import Icon from '@shared/atoms/Icon';
import IconButton from '@shared/atoms/IconButton';
import FormAutocomplete from '@shared/atoms/FormFields/FormAutocomplete';
import FormSelect from '@shared/atoms/FormFields/FormSelect';
import FullWidthTabs from '@shared/atoms/FullWidthTabs';
import T from '@shared/atoms/Typography';
import { registeredMembersRoles } from '@shared/const';
import Dialog from '@shared/molecules/Dialog';
import Menu from '@shared/molecules/Menu';
import theme from '@shared/themes/default';
import { logger } from '@shared/utils';

import CircularSpinner from '@shared/atoms/CircularSpinner';
import { useAppGlobals } from '../../../contexts/AppContext';
import InvitationsTab from './tabs/InvitationsTab';
import MembershipsTab from './tabs/MembershipsTab';
import SubscriptionsTab from './tabs/SubscriptionsTab';

import { deleteUser, moveUser } from '../graphql/mutations';
import { getCognitoUser, listOrganizationsNames } from '../graphql/queries';
import { useGraphQLQuery } from '../../../../utils';

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${theme.spacing.m1} ${theme.spacing.m1} 0 ${theme.spacing.m1};
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${theme.spacing.s};
`;

const LeftTitleWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.s};
`;

const RightTitleWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.m1};
`;

const ChipWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.s};
`;

const UsersTableRowItem = ({
  item,
  onDeleteUser,
  setErrorMessage,
  setHasError,
  setHasSuccess,
  setSuccessMessage,
}) => {
  const { domain } = useAppGlobals();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [cognitoUser, setCognitoUser] = useState();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [moveOrganizationDialogOpen, setMoveOrganizationDialogOpen] =
    useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedOrganization, setSelectedOrganization] = useState();
  const [selectedRole, setSelectedRole] = useState();

  const customMenuHandler = (event) => {
    setAnchorEl(anchorEl === null ? event.currentTarget : null);
  };

  const isInvitation = !item.userSub && item.invitation;

  const { callQuery, data, errors, hasEnded, hasErrors, loading } =
    useGraphQLQuery();

  useEffect(() => {
    if (hasEnded) {
      if (hasErrors) {
        logger.error('Error: ', errors);
      } else {
        const { getCognitoUser } = data;
        setCognitoUser(getCognitoUser);
      }
    }
  }, [hasEnded]);

  useEffect(() => {
    if (!isInvitation) {
      callQuery(getCognitoUser(item.userSub));
    }
  }, [isInvitation]);

  const {
    callQuery: callOrganizationsQuery,
    data: organizationsData,
    errors: organizationsErrors,
    hasEnded: hasOrganizationsEnded,
    hasErrors: hasOrganizationsErrors,
    loading: loadingOrganizations,
  } = useGraphQLQuery();

  useEffect(() => {
    if (moveOrganizationDialogOpen && organizations.length === 0) {
      callOrganizationsQuery(listOrganizationsNames(domain));
    }
  }, [moveOrganizationDialogOpen]);

  useEffect(() => {
    if (hasOrganizationsEnded) {
      if (hasOrganizationsErrors) {
        logger.error('Error: ', organizationsErrors);
      } else {
        const {
          listOrganizations: { items },
        } = organizationsData;
        setOrganizations(
          items
            .map((item) => ({
              name: item.name || `No name (ID: ${item.id})`,
              value: item.id,
            }))
            .sort((a, b) => {
              return a.name?.localeCompare(b.name);
            })
        );
      }
    }
  }, [hasOrganizationsEnded]);

  const {
    callQuery: callDeleteQuery,
    data: deleteData,
    errors: deleteErrors,
    hasEnded: hasDeleteEnded,
    hasErrors: hasDeleteErrors,
    loading: loadingDelete,
  } = useGraphQLQuery();

  useEffect(() => {
    if (hasDeleteEnded) {
      if (hasDeleteErrors) {
        logger.error('Error: ', deleteErrors);
        setErrorMessage(
          t('backoffice.users.failedToDelete', {
            name: item.name,
            email: item.email,
          })
        );
        setHasError(true);
      } else {
        const {
          deleteUser: { userSub },
        } = deleteData;

        onDeleteUser(userSub);
        setSuccessMessage(
          t('backoffice.users.successfullyDeleted', {
            name: item.name,
            email: item.email,
          })
        );
        setHasSuccess(true);
      }

      setDeleteDialogOpen(false);
    }
  }, [hasDeleteEnded]);

  const {
    callQuery: callMoveQuery,
    data: moveData,
    errors: moveErrors,
    hasEnded: hasMoveEnded,
    hasErrors: hasMoveErrors,
    loading: loadingMove,
  } = useGraphQLQuery();

  useEffect(() => {
    if (hasMoveEnded) {
      if (hasMoveErrors) {
        logger.error('Error: ', moveErrors);
        setErrorMessage(
          t('backoffice.users.failedToMove', {
            name: item.name,
            email: item.email,
            organization: selectedOrganization.name,
          })
        );
        setHasError(true);
      } else {
        const {
          moveUser: { success },
        } = moveData;

        if (success) {
          setSuccessMessage(
            t('backoffice.users.successfullyMoved', {
              name: item.name,
              email: item.email,
              organization: selectedOrganization.name,
            })
          );
          setHasSuccess(true);
        } else {
          logger.error('Error');
          setErrorMessage(
            t('backoffice.users.failedToMove', {
              name: item.name,
              email: item.email,
              organization: selectedOrganization.name,
            })
          );
          setHasError(true);
        }
      }

      setMoveOrganizationDialogOpen(false);
    }
  }, [hasMoveEnded]);

  return (
    <>
      <OuterWrapper>
        <TitleWrapper>
          <LeftTitleWrapper>
            <Icon icon={PersonIcon} />
            <T fontWeight="semibold" variant="subtitle">
              {item.name}
            </T>
          </LeftTitleWrapper>
          {!isInvitation && (
            <RightTitleWrapper>
              <ChipWrapper>
                {!cognitoUser || loading ? (
                  <CircularSpinner static size={25} />
                ) : (
                  <>
                    <Chip
                      label={
                        cognitoUser.enabled
                          ? t('backoffice.users.active')
                          : t('backoffice.users.inactive')
                      }
                      small
                      theme="dark"
                    />
                    <Chip
                      color={
                        cognitoUser.status === 'CONFIRMED'
                          ? undefined
                          : theme.palette.errorLight
                      }
                      label={cognitoUser.status}
                      small
                      theme="dark"
                    />
                  </>
                )}
              </ChipWrapper>
              <IconButton
                ariaLabel="More"
                icon={MoreHorizIcon}
                onClick={customMenuHandler}
                theme="dark"
                type="primary"
              />
              <Menu
                freeMenu
                initAnchorEl={anchorEl}
                menuItems={[
                  {
                    label: t('backoffice.users.deleteUser'),
                    handler: () => setDeleteDialogOpen(true),
                  },
                  {
                    label: t('backoffice.users.moveToOrganization'),
                    handler: () => {
                      setSelectedRole(registeredMembersRoles.member);
                      setSelectedOrganization();
                      setMoveOrganizationDialogOpen(true);
                    },
                  },
                ]}
                setInitAnchorEl={setAnchorEl}
                theme="dark"
              />
            </RightTitleWrapper>
          )}
        </TitleWrapper>
        {isInvitation ? (
          <T>
            {item.invitation.invitedBy
              ? t('backoffice.users.userInvitedBy', {
                  organization: item.invitation.organization.name,
                  user: item.invitation.invitedBy.name,
                })
              : t('backoffice.users.userInvited', {
                  organization: item.invitation.organization.name,
                })}
          </T>
        ) : (
          <FullWidthTabs
            onChangeTabHandler={(index) => setSelectedTab(index)}
            selectedTabIndex={selectedTab}
            tabsToSelect={[
              {
                label: t('backoffice.users.memberships'),
                component: (
                  <MembershipsTab
                    loadingMove={loadingMove}
                    userSub={item.userSub}
                  />
                ),
              },
              {
                label: t('backoffice.users.invitations'),
                component: (
                  <InvitationsTab
                    email={item.email}
                    loadingMove={loadingMove}
                  />
                ),
              },
              {
                label: t('backoffice.users.subscriptions'),
                component: (
                  <SubscriptionsTab
                    loadingMove={loadingMove}
                    userSub={item.userSub}
                  />
                ),
              },
            ]}
            theme="dark"
          />
        )}
      </OuterWrapper>
      <Dialog
        ariaLabel={t('backoffice.users.deleteUser')}
        actions={[
          {
            label: t('backoffice.users.cancel'),
            handler: () => setDeleteDialogOpen(false),
            type: 'secondaryOutline',
          },
          {
            label: t('backoffice.users.confirm'),
            handler: () => callDeleteQuery(deleteUser(item.userSub)),
          },
        ]}
        loading={loadingDelete}
        onClose={() => setDeleteDialogOpen(false)}
        open={deleteDialogOpen}
        title={t('backoffice.users.deleteUser')}
      >
        <T color={theme.palette.mainDark}>
          {t('backoffice.users.confirmDelete', {
            name: item.name,
            email: item.email,
          })}
        </T>
        <br />
        <T color={theme.palette.pureDark} fontWeight="bold">
          {t('backoffice.users.cannotBeUndone')}
        </T>
      </Dialog>
      <Dialog
        ariaLabel={t('backoffice.users.moveToOrganization')}
        actions={[
          {
            label: t('backoffice.users.cancel'),
            handler: () => setMoveOrganizationDialogOpen(false),
            type: 'secondaryOutline',
          },
          {
            label: t('backoffice.users.confirm'),
            disabled: !selectedOrganization || !selectedRole,
            handler: () =>
              callMoveQuery(
                moveUser({
                  organizationId: selectedOrganization.value,
                  role: selectedRole,
                  userSub: item.userSub,
                })
              ),
          },
        ]}
        loading={loadingMove}
        onClose={() => setMoveOrganizationDialogOpen(false)}
        open={moveOrganizationDialogOpen}
        title={t('backoffice.users.moveToOrganization')}
      >
        <FormAutocomplete
          id="organization"
          name="organization"
          filterOptions
          getOptionKey={(option) => option.value}
          getOptionLabel={(option) => option.name}
          handleChange={({ value }) => setSelectedOrganization(value)}
          label={t('backoffice.users.organization')}
          loading={loadingOrganizations}
          options={organizations}
          placeholder={t('backoffice.users.selectOrganization')}
          required
          sx={{ marginBottom: theme.spacing.m1 }}
          theme="light"
        />
        <FormSelect
          id="role"
          name="role"
          items={[
            {
              value: registeredMembersRoles.member,
              key: registeredMembersRoles.member,
              label: t('administrateUsers.member'),
            },
            {
              value: registeredMembersRoles.manager,
              key: registeredMembersRoles.manager,
              label: t('administrateUsers.manager'),
            },
            {
              value: registeredMembersRoles.admin,
              key: registeredMembersRoles.admin,
              label: t('administrateUsers.administrator'),
            },
          ]}
          handleChange={({ value }) => setSelectedRole(value)}
          label={t('backoffice.users.role')}
          required
          theme="light"
          value={selectedRole}
        />
      </Dialog>
    </>
  );
};

UsersTableRowItem.propTypes = {
  item: PropTypes.object.isRequired,
  onDeleteUser: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  setHasError: PropTypes.func.isRequired,
  setHasSuccess: PropTypes.func.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
};

export default UsersTableRowItem;

import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Check from '@mui/icons-material/Check';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Icon from 'shared/atoms/Icon';
import T from 'shared/atoms/Typography';
import { TAB_STATUS } from 'shared/const';

import theme from 'shared/themes/default';

const sxVerticalTabs = {
  backgroundColor: theme.palette.darkWrapper,
  '& .MuiTabs-root': {
    width: '106px',
  },
  '& .MuiTab-textColorPrimary': {
    border: `1px solid ${theme.palette.darkDistinct}`,
    borderBottom: `1px solid ${theme.palette.oddDark}`,
    backgroundColor: theme.palette.darkDistinct,
    minWidth: '106px',
    height: '66px',
    '&:hover': {
      border: `1px solid ${theme.palette.lightInteracting}`,
      backgroundColor: theme.palette.lightInteracting,
      '& .MuiTypography-root': {
        color: theme.palette.darkDistinct,
      },
    },
  },
  '& .MuiTab-textColorPrimary.Mui-selected ': {
    border: `1px solid ${theme.palette.lightDistinct}`,
    borderRight: `1px solid ${theme.palette.darkDistinct}`,
  },
  '& .Mui-disabled': {
    backgroundColor: theme.palette.disabledDark,
    color: theme.palette.darkDistinct,
  },
};

const VerticalTabsWrapper = styled.div`
  display: flex;
`;
const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PanelWrapper = styled.div`
  width: 100%;
`;

const getIcon = (status) => {
  let icon = null;
  let color = null;
  switch (status) {
    case TAB_STATUS.COMPLETED:
      icon = Check;
      color = theme.palette.successLight;
      break;
    case TAB_STATUS.IN_PROGRESS:
      icon = ErrorOutlineIcon;
      color = theme.palette.errorDark;
      break;
    case TAB_STATUS.DISABLED:
      icon = VisibilityOff;
      color = theme.palette.lightPure;
      break;
    default:
      break;
  }

  return (
    <Icon
      icon={icon}
      color={color}
      size="1.5rem"
      sx={{
        marginLeft: theme.spacing.xs,
      }}
    />
  );
};

const getLabel = (label, status) => {
  return (
    <LabelWrapper>
      <T color={theme.palette.lightPure} variant="subtitle">
        {label}
      </T>
      {status && getIcon(status)}
    </LabelWrapper>
  );
};

const VerticalTabs = ({ tabsToSelect, setSelectedTab }) => {
  const [index, setIndex] = useState(0);

  const handleChange = (_, newValue) => {
    setIndex(newValue);
    if (setSelectedTab) {
      setSelectedTab(tabsToSelect[newValue].label);
    }
  };

  const tabToRender = tabsToSelect[index] || { component: null };

  useEffect(() => {
    if (tabsToSelect.length - 1 < index) {
      handleChange(null, 0);
    }
  }, [tabsToSelect]);

  return (
    <VerticalTabsWrapper>
      <Tabs
        orientation="vertical"
        value={index}
        onChange={handleChange}
        aria-label="vertical-tabs"
        TabIndicatorProps={{
          style: { display: 'none' },
        }}
        sx={sxVerticalTabs}
      >
        {tabsToSelect.map((tab) => (
          <Tab
            disabled={tab.disabled}
            label={getLabel(tab.label, tab.status)}
            key={tab.label}
            id={`vertical-tab-${index}`}
            aria-controls={`vertical-tabpanel-${index}`}
            disableRipple
          />
        ))}
      </Tabs>
      <PanelWrapper>{tabToRender.component}</PanelWrapper>
    </VerticalTabsWrapper>
  );
};

VerticalTabs.propTypes = {
  tabsToSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      component: PropTypes.node,
      disabled: PropTypes.bool,
      status: PropTypes.string,
    })
  ).isRequired,
  setSelectedTab: PropTypes.func,
};

export default VerticalTabs;

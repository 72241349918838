import React, { useState } from 'react';
import PropTypes from 'prop-types';

import useCourses from './useCourses';
import useDialogs from './useDialogs';
import useExperts from './useExperts';
import usePlaylists from './usePlaylists';

const PlaylistContext = React.createContext();

function PlaylistProvider({
  addNanoDegreeExam,
  completeUpload,
  copyPlaylist,
  createPlaylist,
  debug,
  deletePlaylist,
  getNanoDegreeExam,
  getOrganizationMembers,
  getOrganizationMembersCount,
  getPlaylist,
  getPlaylistContent,
  getUploadUrl,
  graphql,
  handleHistory,
  hasSubscription,
  isSubscriptionAdmin,
  listAllCourses,
  listExperts,
  listGroups,
  listGroupsByPromotedPlaylist,
  listOrganizationPlaylistCreators,
  listPlaylists,
  listUsersByPromotedPlaylist,
  locale,
  managerId,
  organization,
  playlistId,
  previewBaseUrl,
  remindAllEventHandler,
  removePlaylistContent,
  seeActivityHandler,
  sendPlaylistReminder,
  sortPlaylistContent,
  startUpload,
  staticImagesHostname,
  transferPlaylist,
  type,
  updatePlaylist,
  updatePlaylistAccess,
  updatePlaylistContent,
  userId,
  ...props
}) {
  // Global states
  const [playlistError, setPlaylistError] = useState(false);
  const [managerSelected, setManagerSelected] = useState(managerId || userId);
  const [typeSelected, setTypeSelected] = useState(
    (type && type.toUpperCase()) || 'ALL'
  );

  const { id: organizationId } = organization || {};
  const isGlobalOrganization = organizationId === 'global';

  const handleSelectType = (type) => {
    setTypeSelected(type);
    if (isGlobalOrganization) {
      handleHistory(`type=${type.toLowerCase()}`);
    } else {
      handleHistory('');
    }
  };

  const handleSelectManager = (managerId) => {
    setManagerSelected(managerId);
    if (managerId !== userId) {
      handleHistory(`manager=${managerId}`);
    } else {
      handleHistory('');
    }
  };

  const playlistContext = usePlaylists({
    handleHistory,
    isGlobalOrganization,
    listOrganizationPlaylistCreators,
    listPlaylists,
    locale,
    managerSelected,
    organizationId,
    setPlaylistError,
    sortPlaylistContent,
    typeSelected,
    userId,
    getPlaylistContent,
    updatePlaylist,
  });

  const coursesContext = useCourses({
    listAllCourses,
  });

  const expertsContext = useExperts({
    listExperts,
  });

  const {
    isNanodegree,
    listOwnedPlaylists,
    loadPlaylistContent,
    playlistContent,
    playlistName,
    selectedPlaylist,
    setListOwnedPlaylists,
    setPlaylistContent,
    setSelectedPlaylist,
    decreasePlaylistCounter,
    increasePlaylistCounter,
  } = playlistContext;

  const dialogsContext = useDialogs({
    addNanoDegreeExam,
    createPlaylist,
    decreasePlaylistCounter,
    deletePlaylist,
    getNanoDegreeExam,
    getPlaylist,
    getPlaylistContent,
    handleHistory,
    increasePlaylistCounter,
    isGlobalOrganization,
    isNanodegree,
    listOwnedPlaylists,
    loadPlaylistContent,
    locale,
    managerSelected,
    organization,
    organizationId,
    playlistContent,
    playlistName,
    remindAllEventHandler,
    removePlaylistContent,
    selectedPlaylist,
    sendPlaylistReminder,
    setListOwnedPlaylists,
    setPlaylistContent,
    setSelectedPlaylist,
    transferPlaylist,
    updatePlaylist,
  });

  const contextValue = {
    // shared variables
    debug,
    getOrganizationMembers,
    getOrganizationMembersCount,
    handleHistory,
    handleSelectManager,
    handleSelectType,
    hasSubscription,
    isSubscriptionAdmin,
    locale,
    managerId,
    managerSelected,
    organization,
    playlistId,
    playlistError,
    previewBaseUrl,
    typeSelected,
    userId,
    // context uses
    playlists: {
      ...playlistContext,
      // fetching methods
      listPlaylists,
      getPlaylist,
      getPlaylistContent,
      // mutations
      copyPlaylist,
      createPlaylist,
      deletePlaylist,
      getNanoDegreeExam,
      updatePlaylist,
      updatePlaylistAccess,
      updatePlaylistContent,
      removePlaylistContent,
      sortPlaylistContent,
      transferPlaylist,
    },
    courses: coursesContext,
    dialogs: dialogsContext,
    experts: expertsContext,
    // fetching methods
    listGroups,
    listGroupsByPromotedPlaylist,
    listOrganizationPlaylistCreators,
    listUsersByPromotedPlaylist,
    seeActivityHandler,
    sendPlaylistReminder,
    // file upload calls
    completeUpload,
    getUploadUrl,
    graphql,
    startUpload,
    staticImagesHostname,
    // mutations
    addNanoDegreeExam,
  };

  return <PlaylistContext.Provider value={contextValue} {...props} />;
}

function usePlaylist() {
  const context = React.useContext(PlaylistContext);
  if (!context) {
    throw new Error('usePlaylist must be used within a PlaylistProvider');
  }

  return context;
}

PlaylistProvider.propTypes = {
  addNanoDegreeExam: PropTypes.func,
  completeUpload: PropTypes.func,
  copyPlaylist: PropTypes.func.isRequired,
  createPlaylist: PropTypes.func.isRequired,
  debug: PropTypes.bool,
  deletePlaylist: PropTypes.func.isRequired,
  getNanoDegreeExam: PropTypes.func,
  getOrganizationMembers: PropTypes.func.isRequired,
  getOrganizationMembersCount: PropTypes.func.isRequired,
  getPlaylist: PropTypes.func.isRequired,
  getPlaylistContent: PropTypes.func.isRequired,
  getUploadUrl: PropTypes.func,
  graphql: PropTypes.func,
  handleHistory: PropTypes.func.isRequired,
  hasSubscription: PropTypes.bool.isRequired,
  isSubscriptionAdmin: PropTypes.bool.isRequired,
  listAllCourses: PropTypes.func.isRequired,
  listExperts: PropTypes.func,
  listGroups: PropTypes.func.isRequired,
  listGroupsByPromotedPlaylist: PropTypes.func.isRequired,
  listOrganizationPlaylistCreators: PropTypes.func.isRequired,
  listPlaylists: PropTypes.func.isRequired,
  listUsersByPromotedPlaylist: PropTypes.func.isRequired,
  locale: PropTypes.oneOf(['no', 'en', 'es']),
  managerId: PropTypes.string,
  playlistId: PropTypes.string,
  previewBaseUrl: PropTypes.string,
  organization: PropTypes.shape({
    id: PropTypes.string,
    lockVcCourses: PropTypes.bool,
    hideVcCourses: PropTypes.bool,
  }),
  remindAllEventHandler: PropTypes.func,
  removePlaylistContent: PropTypes.func.isRequired,
  seeActivityHandler: PropTypes.func.isRequired,
  sendPlaylistReminder: PropTypes.func.isRequired,
  sortPlaylistContent: PropTypes.func.isRequired,
  startUpload: PropTypes.func,
  staticImagesHostname: PropTypes.string,
  transferPlaylist: PropTypes.func.isRequired,
  type: PropTypes.string,
  updatePlaylist: PropTypes.func.isRequired,
  updatePlaylistAccess: PropTypes.func.isRequired,
  updatePlaylistContent: PropTypes.func.isRequired,
  userId: PropTypes.string,
};

PlaylistProvider.defaultProps = {
  debug: false,
};

export { PlaylistProvider, usePlaylist };

import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Collapse from '@mui/material/Collapse';
import useMediaQuery from '@mui/material/useMediaQuery';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Checkbox from 'shared/atoms/Checkbox';
import Select from 'shared/atoms/Select';
import Icon from 'shared/atoms/Icon';
import Pagination from 'shared/molecules/Pagination';
import T from 'shared/atoms/Typography';
import { formatDate, getComparator } from 'shared/utils';
import { TIMESTAMP_FORMATS } from 'shared/const';
import theme from 'shared/themes/default';

import TableComponent from './components/TableComponent';
import TableContainer from './components/TableContainer';
import TableBody from './components/TableBody';
import TableCell from './components/TableCell';
import TableRow from './components/TableRow';
import SortHeader, { ORDER } from './components/SortHeader';

export const CELL_TYPES = {
  DATE: 'DATE',
  DATE_TIME: 'DATE_TIME',
  TEXT: 'TEXT',
  COMPONENT: 'COMPONENT',
};

export const ORDER_TYPES = ORDER;

const ALLOWED_ROWS_PER_PAGE = [10, 20, 50, 100];

const formatCell = ({ column, row }) => {
  const { key, type, render } = column;
  const value = row[key];
  if (!value && type !== CELL_TYPES.COMPONENT) return '';
  switch (type) {
    case CELL_TYPES.DATE:
      return formatDate({
        date: value,
      });
    case CELL_TYPES.DATE_TIME:
      return formatDate({
        date: value,
        type: TIMESTAMP_FORMATS.DATE_TIME,
      }).replace(',', '');
    case CELL_TYPES.COMPONENT:
      return render && render(row);
    case CELL_TYPES.TEXT:
    default:
      return value;
  }
};

const StyledCollapse = styled(Collapse)`
  padding-bottom: 1.5rem;
  background-color: ${theme.palette.oddDark};
  max-height: ${(props) => (props.$isMobile ? '35rem' : 'auto')};
  overflow: ${(props) => (props.$isMobile ? 'scroll' : 'visible')};
  box-shadow: inset 2px 0 8px rgba(0, 0, 0, 0.4);
  border-left: solid ${theme.palette.brand} 10px;
  ${(props) => props.$isMobile && 'max-width: 100vw;'};
`;

const FooterContainer = styled.div`
  display: flex;
`;

const ColumnWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 0;
  justify-content: center;
`;

const PerPageWrapper = styled.div`
  display: flex;
  flex: 1 1 0;
  justify-content: end;
`;

const PerPageContainer = styled.div`
  align-items: center;
  display: inline-flex;
`;

const Table = ({
  allItemsSelected,
  ariaLabel,
  columns,
  defaultOrder,
  defaultOrderBy,
  disablePagination,
  displayBorder,
  displayHeader,
  ExpandedItemComponent,
  expandedItemComponentProps,
  items,
  isExpandedOpen,
  isExpandedType,
  keyField,
  onRowClick,
  onSelectAll,
  onSelectItem,
  rowIsClickable,
  selectable,
  selectedItemIds,
  showAllOption,
}) => {
  const { t } = useTranslation();

  const [order, setOrder] = useState(defaultOrder || ORDER.ASC);
  const [orderByInitial, setOrderByInitial] = useState(
    defaultOrderBy
      ? columns.find((column) => column.key === defaultOrderBy)
      : columns[0]
  );
  const [orderBy, setOrderBy] = useState(orderByInitial);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(ALLOWED_ROWS_PER_PAGE[0]);
  const [rowsPerPageValue, setRowsPerPageValue] = useState(
    ALLOWED_ROWS_PER_PAGE[0]
  );

  const isMobile = useMediaQuery(theme.breakpoints.mobile);
  const isExpandable = !!ExpandedItemComponent;
  // Following is a small trick to generate a pseudo random id in case we display several tables on same page at some point
  // This way we can avoid passing a specific containerId prop and it will keep scrolling expandable tables in mobile
  const tableContainerId = Math.random().toString(36).slice(2, 7);

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy.key === property && order === ORDER.ASC;
    setOrder(isAsc ? ORDER.DESC : ORDER.ASC);
    setOrderBy(columns.find((column) => column.key === property));
    setPage(1);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangePageSize = ({ value }) => {
    setRowsPerPage(value === 'all' ? items.length : value);
    setRowsPerPageValue(value);
    setPage(1);
  };

  const handleRowClick = (row) => {
    if (isMobile) {
      const scrollableElement = document.getElementById(tableContainerId);
      if (scrollableElement) scrollableElement.scrollLeft = 0;
    }
    onRowClick(row);
  };

  const noOfPages = Math.ceil((items && items.length / rowsPerPage) || 1);
  const sortedItems = [...items].sort(
    orderBy?.customSort
      ? (a, b) => orderBy.customSort(a, b, order)
      : getComparator(order, orderBy?.key)
  );
  const itemsToRender =
    isMobile || disablePagination
      ? sortedItems
      : sortedItems.slice((page - 1) * rowsPerPage, page * rowsPerPage);
  const rowsPerPageOptions = ALLOWED_ROWS_PER_PAGE.map((n) => ({
    key: n,
    value: n,
    label: n,
  }));

  if (showAllOption) {
    rowsPerPageOptions.push({
      key: 'all',
      value: 'all',
      label: t('generic.all'),
    });
  }

  useEffect(() => {
    if (page > noOfPages) {
      setPage(1);
    }
  }, [items]);

  useEffect(() => {
    setOrder(defaultOrder || ORDER.ASC);
  }, [defaultOrder]);

  useEffect(() => {
    setOrderByInitial(
      defaultOrderBy
        ? columns.find((column) => column.key === defaultOrderBy)
        : columns[0]
    );
  }, [columns, defaultOrderBy]);

  useEffect(() => {
    if (!orderBy) {
      setOrderBy(
        defaultOrderBy
          ? columns.find((column) => column.key === defaultOrderBy)
          : columns[0]
      );
    }
  }, [orderByInitial]);

  return (
    <>
      <TableContainer
        displayBorder={displayBorder}
        id={tableContainerId}
        isExpandedType={isExpandedType}
        isExpandedOpen={isExpandedOpen}
      >
        <TableComponent aria-label={ariaLabel} stickyHeader={isExpandedType}>
          {displayHeader && (
            <SortHeader
              allItemsSelected={allItemsSelected}
              order={order}
              orderBy={orderBy?.key}
              columns={columns}
              onRequestSort={handleRequestSort}
              onSelectAll={onSelectAll}
              selectable={selectable}
              isExpandedType={isExpandedType}
            />
          )}
          <TableBody
            zebraStripeEffect={!isExpandedType}
            isExpandable={isExpandable}
          >
            {itemsToRender.map((row) => {
              const isItemSelected =
                selectedItemIds && selectedItemIds.includes(row[keyField]);
              let isClickable = !!onRowClick;
              if (rowIsClickable)
                isClickable = isClickable && !!rowIsClickable(row);
              const onClick = isClickable
                ? () => handleRowClick(row)
                : undefined;

              const rowKey = row[keyField];

              return (
                <Fragment key={rowKey}>
                  <TableRow
                    aria-checked={isItemSelected}
                    isMobile={isMobile}
                    onClick={onClick}
                    selected={isItemSelected}
                    isExpandable={isExpandable}
                  >
                    {selectable && (
                      <TableCell
                        sticky
                        style={{ maxWidth: '30px', minWidth: '30px' }}
                      >
                        <Checkbox
                          checked={allItemsSelected || isItemSelected}
                          onChange={() =>
                            onSelectItem(
                              row[keyField],
                              !(allItemsSelected || isItemSelected)
                            )
                          }
                          theme="dark"
                        />
                      </TableCell>
                    )}
                    {columns.map((column) => {
                      const formattedCell = formatCell({ column, row });
                      const {
                        key,
                        sticky,
                        stickySecondColumn,
                        fixedWidth,
                        expandedSticky,
                        maskValue,
                      } = column;
                      return (
                        <TableCell
                          key={`row-${rowKey}-column-${key}`}
                          selected={isItemSelected}
                          sticky={!!sticky}
                          stickySecondColumn={!!stickySecondColumn}
                          fixedWidth={fixedWidth}
                          expandedSticky={expandedSticky}
                          data-matomo-mask={maskValue}
                        >
                          {formattedCell}
                        </TableCell>
                      );
                    })}
                    {!isMobile && isExpandable && (
                      <TableCell key={`expand-icon-cell-${rowKey}`}>
                        {isClickable && (
                          <Icon
                            color={
                              isItemSelected
                                ? theme.palette.brand
                                : theme.palette.lightPure
                            }
                            icon={
                              isItemSelected
                                ? KeyboardArrowUpIcon
                                : KeyboardArrowDownIcon
                            }
                          />
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                  {isExpandable && (
                    <TableRow key={`expanded-${rowKey}`}>
                      <TableCell colSpan={columns.length + 1} fullExpandedCell>
                        <StyledCollapse
                          $isMobile={isMobile}
                          in={isItemSelected}
                          timeout="auto"
                          unmountOnExit
                        >
                          <ExpandedItemComponent
                            item={row}
                            itemId={rowKey}
                            open={isItemSelected}
                            {...expandedItemComponentProps}
                          />
                        </StyledCollapse>
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              );
            })}
          </TableBody>
        </TableComponent>
      </TableContainer>
      {!disablePagination &&
        !isMobile &&
        items.length > ALLOWED_ROWS_PER_PAGE[0] && (
          <FooterContainer>
            <ColumnWrapper />
            <ColumnWrapper>
              {noOfPages > 1 && (
                <Pagination
                  page={page}
                  onChange={handleChangePage}
                  totalPages={noOfPages}
                  initialPage={1}
                  theme="dark"
                />
              )}
            </ColumnWrapper>
            <PerPageWrapper>
              <PerPageContainer>
                <T
                  color={theme.palette.disabledLight}
                  sx={{
                    marginRight: theme.spacing.m2,
                    whiteSpace: 'nowrap',
                  }}
                  variant="helperText"
                >
                  {t('generic.showingItems', {
                    minimum: rowsPerPage * (page - 1) + 1,
                    maximum: Math.min(rowsPerPage * page, items.length),
                    total: items.length,
                  })}
                </T>
                <T
                  color={theme.palette.lightPure}
                  sx={{
                    marginRight: theme.spacing.s,
                    whiteSpace: 'nowrap',
                  }}
                  variant="helperText"
                >
                  {t('generic.itemsPerPage')}
                </T>
                <Select
                  id="rows-per-page-select"
                  items={rowsPerPageOptions}
                  name="rows-per-page-select"
                  onChange={handleChangePageSize}
                  hasAction
                  theme="dark"
                  value={rowsPerPageValue}
                />
              </PerPageContainer>
            </PerPageWrapper>
          </FooterContainer>
        )}
    </>
  );
};

Table.propTypes = {
  allItemsSelected: PropTypes.bool,
  ariaLabel: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      customSort: PropTypes.func,
      disableSorting: PropTypes.bool,
      fixedWidth: PropTypes.string,
      key: PropTypes.string.isRequired,
      label: PropTypes.string,
      sticky: PropTypes.bool,
      type: PropTypes.oneOf(Object.values(CELL_TYPES)),
    })
  ).isRequired,
  defaultOrder: PropTypes.oneOf(Object.values(ORDER_TYPES)),
  defaultOrderBy: PropTypes.string,
  disablePagination: PropTypes.bool,
  displayBorder: PropTypes.bool,
  displayHeader: PropTypes.bool,
  ExpandedItemComponent: PropTypes.func,
  expandedItemComponentProps: PropTypes.object,
  items: (props, propName, componentName) => {
    if (!Array.isArray(props[propName])) {
      return new Error(
        `Invalid prop \`${propName}\` of type \`${typeof props[
          propName
        ]}\` supplied to \`${componentName}\`, expected \`array\`.`
      );
    }
    if (
      props[propName].length > 0 &&
      props[propName].some((elem) => !elem[props.keyField])
    ) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`, expected \`array\` containing of objects with at least a \`${props.keyField}\`.`
      );
    }
    return null;
  },
  isExpandedOpen: PropTypes.bool,
  isExpandedType: PropTypes.bool,
  keyField: PropTypes.string,
  onRowClick: PropTypes.func,
  onSelectAll: PropTypes.func,
  onSelectItem: PropTypes.func,
  rowIsClickable: PropTypes.func,
  selectable: PropTypes.bool,
  selectedItemIds: PropTypes.arrayOf(PropTypes.string),
  showAllOption: PropTypes.bool,
};

Table.defaultProps = {
  displayBorder: true,
  displayHeader: true,
  ExpandedItemComponent: null,
  items: [],
  isExpandedOpen: false,
  isExpandedType: false,
  keyField: 'id',
  selectable: false,
  showAllOption: false,
};

export default Table;

import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Input, InputAdornment, MenuItem, Select } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Chip from 'shared/atoms/Chip';
import Checkbox from 'shared/atoms/Checkbox';
import theme from 'shared/themes/default';

import FilterIcon from './FilterIcon';

const Wrapper = styled.div`
  display: flex;
  gap: ${theme.spacing.m1};
`;

const Container = styled.div`
  width: 210px;
`;

const StyledMenuItem = styled(MenuItem)`
  padding-top: 0;
  padding-bottom: 0;
  text-wrap: wrap;
`;

const ChipsBlock = styled.div`
  display: flex;
  gap: ${theme.spacing.s};
`;

const menuProps = {
  anchorOrigin: {
    vertical: 34,
    horizontal: 65,
  },
  PaperProps: {
    style: {
      borderRadius: '10px',
      width: '205px',
      marginTop: '0.3rem',
    },
  },
};

export const useStyles = makeStyles(() => ({
  inputRoot: {
    fontFamily: theme.fonts.primary,
    backgroundColor: theme.palette.lightPure,
    borderRadius: '20px',
    paddingLeft: '6px',
    height: (props) => (props.isPhone ? 'auto' : '40px'),
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.brand,
      '&.MuiSelect-select': {
        backgroundColor: `${theme.palette.brand}, !important`,
      },
    },
  },
  inputAdornmentRoot: {
    margin: '5px 0 0 10px',
  },
  select: {
    borderRadius: '16px',
    paddingRight: '0px !important',
    fontWeight: 600,
    maxWidth: '75%',
  },
  selectIcon: {
    marginRight: '10px',
  },
}));

const FilterDropdown = ({ onChange, options, selectedFilters }) => {
  const { t } = useTranslation();
  const isPhone = useMediaQuery(theme.breakpoints.mobile);
  const classes = useStyles({ isPhone });

  const handleSelect = (value) => {
    const selected = selectedFilters.includes(value);
    if (selected) {
      const updatedFilters = selectedFilters.filter((item) => item !== value);
      onChange(updatedFilters);
      return;
    }
    onChange([...selectedFilters, value]);
  };

  const selectedFilterChips = options
    .filter((item) => selectedFilters.includes(item.id))
    .map((item) => (
      <Chip
        key={item.id}
        label={item.label}
        onDelete={() => handleSelect(item.id)}
        theme="dark"
        type="secondary"
      />
    ));

  return (
    <Wrapper>
      <Container>
        <Select
          id="filter-dropdown"
          multiple
          displayEmpty
          classes={{ select: classes.select, icon: classes.selectIcon }}
          MenuProps={menuProps}
          input={
            <Input
              className={classes.inputRoot}
              disableUnderline
              startAdornment={
                <InputAdornment
                  position="start"
                  className={classes.inputAdornmentRoot}
                >
                  <FilterIcon />
                </InputAdornment>
              }
            />
          }
          value={selectedFilters}
          renderValue={(selected) =>
            t('generic.filter', { count: selected?.length || 0 })
          }
        >
          {options.map((op) => (
            <StyledMenuItem key={op.id} value={op.id} disableRipple dense>
              <Checkbox
                checked={selectedFilters.includes(op.id)}
                label={op.label}
                onChange={() => {
                  handleSelect(op.id);
                }}
                theme="light"
              />
            </StyledMenuItem>
          ))}
        </Select>
      </Container>
      <ChipsBlock>{selectedFilterChips}</ChipsBlock>
    </Wrapper>
  );
};

FilterDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  selectedFilters: PropTypes.arrayOf(PropTypes.string.isRequired),
};

FilterDropdown.defaultProps = {
  options: [],
  selectedFilters: [],
};

export default FilterDropdown;

import React from 'react';

import PageTemplate from '../components/templates/PageTemplate';
import CategoriesView from '../components/organisms/CategoriesView';

const CategoriesContainer = () => (
  <PageTemplate>
    <CategoriesView />
  </PageTemplate>
);

export default CategoriesContainer;

export const listOrganizationsNames = (domain) => ({
  query: `query listOrganizations(
    $domain: String!
    $nextToken: String
  ) {
    listOrganizations (domain: $domain, limit: 1000, nextToken: $nextToken) {
      items {
        id
        domain
        name
        archived
      }
      nextToken
    }
  }`,
  variables: {
    domain,
  },
});

export const getOrganization = (id) => ({
  query: `query getOrganization(
    $id: ID!
  ) {
    getOrganization(id: $id) {
      id
      name
      email
      hideVcCourses
      lockVcCourses
    }
  }`,
  variables: {
    id,
  },
});

export const getPlaylistContentQuery = (courseIds) => ({
  name: 'getPlaylistContent',
  query: `query getPlaylistContent(
    $courseIds: [ID]!
  ) {
    getCourses(ids: $courseIds) {
      id
      name
      nameTranslations
      thumbnailUrl
      quizId
      ownerOrganizationId
      experts {
        items {
          id
        }
      }
      daysLeft
    }
  }`,
  variables: {
    courseIds,
  },
});

export const listAllCoursesQuery = (
  organizationId,
  hideVcCourses,
  domain,
  nextToken
) => ({
  name: 'listCourses',
  query: `query listCourses(
    $filter: CourseFilterInput
    $limit: Int
    $nextToken: String
    $ownerOrganizationId: String
    $hideVcCourses: Boolean
  ) {
    listCourses(filter: $filter, limit: $limit, nextToken: $nextToken, ownerOrganizationId: $ownerOrganizationId, hideVcCourses: $hideVcCourses) {
      items {
        id
        name
        nameTranslations
        thumbnailUrl
        quizId
        archivedScheduledAt
      }
      nextToken
    }
  }`,
  variables: {
    filter: { domain: { contains: domain }, status: { eq: 'PUBLISHED' } },
    limit: 500,
    nextToken,
    ownerOrganizationId: organizationId,
    hideVcCourses,
  },
});

export const listPlaylists = (
  organizationId,
  managerId,
  type,
  removeHidden
) => ({
  name: 'listPlaylists',
  query: `query listPlaylists(
    $organizationId: ID,
    $managerId: ID,
    $type: PlaylistType,
    $removeHidden: Boolean,
    $limit: Int,
    $nextToken: String
  ) {
    listPlaylists(
      organizationId: $organizationId,
      managerId: $managerId,
      type: $type,
      removeHidden: $removeHidden,
      limit: $limit,
      nextToken: $nextToken
    ) {
      items {
        id
        shortId
        titleTranslations
        descriptionTranslations
        shortDescriptionTranslations
        shareType
        publishedAt
        refId
        domain
        status
        children
        ownerId
        thumbnail {
          key
          bucket
          region
        }
        thumbnailProcessingStatus {
          status
        }
        trailerVideoFiles {
          file {
            Key
            Bucket
            region
          }
        }
        trailerVideoProcessingStatus {
          status
        }
        type
        quizId
        editorId
        archivedScheduledAt
        daysLeft
        archiveDate
        lastReminderAt
      }
      nextToken
    }
  }`,
  variables: {
    organizationId,
    managerId,
    type,
    removeHidden,
    limit: 50,
  },
});

export const listOrganizationPlaylistCreators = (
  organizationId,
  limit,
  nextToken
) => ({
  query: `query listOrganizationPlaylistCreators(
    $organizationId: String!,
    $limit: Int,
    $nextToken: String
  ) {
    listOrganizationPlaylistCreators(
      organizationId: $organizationId,
      limit: $limit,
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        ownedPlaylistsNumber
      }
      nextToken
    }
  }`,
  variables: {
    organizationId,
    limit,
    nextToken,
  },
});

export const listUsersByPromotedPlaylist = (playlistId, organizationId) => ({
  initialize: true,
  name: 'listUsersByPromotedPlaylist',
  query: `query listUsersByPromotedPlaylist(
    $id: String, $organizationId: String, $limit: Int, $nextToken: String
  ) {
    listUsersByPromotedPlaylist(id: $id, organizationId: $organizationId, limit: $limit, nextToken: $nextToken) {
      items {
        id
        roleId
        name
        email
      }
      nextToken
    }
  }`,
  variables: {
    id: playlistId,
    organizationId,
    limit: 500,
  },
});

export const listGroupsByPromotedPlaylist = (organizationId, playlistId) => ({
  initialize: true,
  name: 'listGroupsByPromotedPlaylist',
  query: `query listGroupsByPromotedPlaylist(
    $id: ID!, $organizationId: ID, $limit: Int, $nextToken: String
  ) {
    listGroupsByPromotedPlaylist(id: $id, organizationId: $organizationId, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        membersCount
      }
      nextToken
    }
  }`,
  variables: {
    id: playlistId,
    organizationId,
    limit: 500,
  },
});

export const getOrganizationMembers = (
  organizationId,
  searchTerm,
  nextToken
) => ({
  initialize: false,
  name: 'getOrganizationMembers',
  query: `query getOrganizationMembers(
    $id: ID!, $name: String, $limit: Int, $nextToken: String
  ) {
    getOrganizationMembers(id: $id, name: $name, limit: $limit, nextToken: $nextToken) {
      items {
        id
        roleId
        name
        email
      }
      nextToken
    }
  }`,
  variables: {
    id: organizationId,
    name: searchTerm,
    limit: 1000,
    nextToken,
  },
});

export const getPlaylist = (playlistId) => ({
  initialize: false,
  name: 'getPlaylist',
  query: `query getPlaylist(
    $id: ID!
  ) {
      getPlaylist(id: $id) {
        id
        titleTranslations
        ownerId
        shareType
    }
  }`,
  variables: {
    id: playlistId,
  },
});

export const getOrganizationMembersCount = (organizationId) => ({
  query: `query getOrganizationMembersCount(
    $id: ID!
  ) {
    getOrganizationMembersCount(id: $id)
  }`,
  variables: {
    id: organizationId,
  },
});

export const listGroups = (organizationId, nextToken) => ({
  query: `query listGroups($organizationId: ID!, $limit: Int, $nextToken: String) {
    listGroups(organizationId: $organizationId, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        ownedBy
        ownedByName
        updatedAt
        membersCount
      }
      nextToken
    }
  }`,
  variables: {
    organizationId,
    limit: 1000,
    nextToken,
  },
});

export const listExperts = (domain, nextToken) => ({
  query: `query listExperts(
    $filter: ExpertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExperts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstname
        lastname
      }
      nextToken
    }
  }`,
  variables: {
    filter: {
      domain: {
        contains: domain,
      },
    },
    limit: 1000,
    nextToken,
  },
});

export const getQuiz = (quizId) => ({
  query: `query getQuizzes(
    $ids: [ID]!
  ) {
    getQuizzes(ids: $ids) {
      questions {
        text
        options {
          answer
          correct
        }
      }
    }
  }`,
  variables: {
    ids: [quizId],
  },
});

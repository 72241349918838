import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Table, { ORDER_TYPES } from 'shared/organisms/Table';

const AllOrganizationsOverViewTable = ({
  itemsToDisplay,
  selectOrganizationHandler,
}) => {
  const { t } = useTranslation();

  const columns = [
    {
      key: 'name',
      label: t('watchData.organizationName'),
      sticky: true,
      fixedWidth: '45%',
      maskValue: true,
    },
    { key: 'registeredMembers', label: t('watchData.registeredMembers') },
    { key: 'playlistsCompleted', label: t('watchData.completedPlaylist') },
    { key: 'coursesCompleted', label: t('watchData.completedCourses') },
    { key: 'episodesCompleted', label: t('watchData.completedEpisodes') },
    { key: 'certificatesEarned', label: t('watchData.certificatesEarned') },
  ];

  return (
    <Table
      ariaLabel={t('watchData.activityOfOrganizations')}
      columns={columns}
      items={itemsToDisplay}
      defaultOrder={ORDER_TYPES.DESC}
      defaultOrderBy="episodesCompleted"
      onRowClick={(row) => selectOrganizationHandler({ ...row, value: row.id })}
    />
  );
};

AllOrganizationsOverViewTable.propTypes = {
  itemsToDisplay: PropTypes.array.isRequired,
  selectOrganizationHandler: PropTypes.func.isRequired,
};

export default AllOrganizationsOverViewTable;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import theme from '@shared/themes/default';
import ErrorView from '@shared/organisms/ErrorView';
import PageTemplate from '../../templates/PageTemplate';
import { useAppGlobals } from '../../contexts/AppContext';

const PageWrapper = styled.div`
  padding: ${theme.spacing.xl} ${theme.spacing.m1} 0 ${theme.spacing.m1};
`;

const NoBackofficeAccessPage = () => {
  const { domain } = useAppGlobals();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <PageTemplate>
      <PageWrapper>
        <ErrorView
          domain={domain}
          firstSentence={t('backoffice.errors.unauthorizedError')}
          secondSentence={t('backoffice.errors.noBackofficeAccess')}
          buttonText={t('backoffice.errors.backToMain')}
          buttonOnClick={() => navigate('/')}
        />
      </PageWrapper>
    </PageTemplate>
  );
};

export default NoBackofficeAccessPage;

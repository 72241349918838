import React from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';

import theme from 'shared/themes/default';

const StyledPanel = styled.div`
  border-top: 1px ${({ hideBorder }) => (hideBorder ? 'none' : 'solid')}
    ${theme.palette.disabledDark};
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
`;

const Panel = ({ children, hideBorder }) => {
  return (
    <StyledPanel fullWidth hideBorder={hideBorder}>
      {children}
    </StyledPanel>
  );
};

Panel.propTypes = {
  children: PropTypes.node,
  hideBorder: PropTypes.bool,
};

Panel.defaultProps = {
  hideBorder: false,
};

export default Panel;

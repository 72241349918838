import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import theme from 'shared/themes/default';

import Typography from '../Typography';

const StyledBlock = styled.div`
  position: relative;
  width: 100%;
`;

const StyledProgress = styled.div`
  height: ${(props) => (props.small ? theme.spacing.xs : '1.25rem')};
  background: ${theme.palette.brand};
  text-align: center;
  overflow: hidden;
  width: ${(props) => props.progress}%;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  border-radius: ${(props) => (props.small ? '6px' : '0')};
`;

const StyledBackground = styled.div`
  height: ${(props) => (props.small ? theme.spacing.xs : '1.25rem')};
  background-color: ${theme.palette.disabledDark};
  border-radius: ${(props) => (props.small ? '6px' : '0')};
`;

const ProgressBar = ({ small, progress }) => {
  const { t } = useTranslation();

  const validatedProgress = Math.max(0, Math.min(100, progress)); // Ensure progress is between 0 and 100

  return (
    <StyledBlock>
      <StyledBackground small={small} />
      <StyledProgress small={small} progress={validatedProgress}>
        {validatedProgress === 100 && !small && (
          <Typography
            color={theme.palette.pureDark}
            variant="helperText"
            fontWeight="semibold"
          >
            {t('generic.complete')}
          </Typography>
        )}
      </StyledProgress>
    </StyledBlock>
  );
};

ProgressBar.propTypes = {
  small: PropTypes.bool,
  progress: PropTypes.number.isRequired,
};

export default ProgressBar;

export const getCourse = ({ id }) => ({
  query: `query GetCourse(
    $id: ID!
  ) {
    getCourse(id: $id) {
      id
      shortId
      level
      domain
      name
      nameTranslations
      episodeOrder
      shortDescription
      shortDescriptionTranslations
      seoDescriptionTranslations
      keyLearningPoints
      keyLearningPointsTranslations
      trailerVideoFiles {
        file {
          Key
          Bucket
          region
        }
        seconds
      }
      trailerVideoProcessingStatus {
        status
      }
      type
      status
      description
      descriptionTranslations
      categories {
        items {
          id
          title
          titleTranslations
        }
      }
      experts {
        items {
          id
          firstname
          lastname
          isDisabled
        }
      }
      episodes {
        id
        name
        nameTranslations
        subtitle {
          key
          bucket
          region
        }
        subtitleProcessingStatus {
          status
        }
        videoFiles {
          file {
            Key
            Bucket
            region
          }
          seconds
        }
        videoProcessingStatus {
          status
        }
        game {
          id
          length
        }
        attachments {
          id
          episodeId
          name
          file {
            key
            bucket
            region
          }
          processingStatus {
            status
          }
        }
      }
      language
      level
      prerequisitesTranslations
      typicalLearnerTranslations
      thumbnail {
        key
        bucket
        region
      }
      thumbnailProcessingStatus {
        status
      }
      promopicture {
        key
        bucket
        region
      }
      promopictureProcessingStatus {
        status
      }
      attachments {
        id
        courseId
        name
        file {
          key
          bucket
          region
        }
        processingStatus {
          status
        }
      }
      ownerOrganization {
        id
        name
        stripe_customer_id
        email
      }
      quiz {
        id
        courseId
        gameId
        time {
          minutes
          seconds
        }
        questions {
          id
          text
          options {
            id
            answer
            correct
          }
        }
        retakeHours
      }
      archivedScheduledAt
      archiveDate
    }
  }`,
  variables: {
    id,
  },
});

export const getLessonsProcessingStatus = ({ id }) => ({
  query: `query GetCourse(
    $id: ID!
  ) {
    getCourse(id: $id) {
      id
      shortId
      trailerVideoProcessingStatus {
        status
      }
      episodes {
        id
        shortId
        subtitleProcessingStatus {
          status
        }
        videoProcessingStatus {
          status
        }
        attachments {
          id
          processingStatus {
            status
          }
        }
      }
    }
  }`,
  variables: {
    id,
  },
});

export const getGameName = (gameId) => ({
  query: `query getGameName(
    $gameId: String!
  ) {
    getGameName(gameId: $gameId)
  }
  `,
  variables: {
    gameId,
  },
});

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from 'shared/atoms/Button';
import ProgressBar from 'shared/atoms/ProgressBar';

import Table, { CELL_TYPES, ORDER_TYPES } from 'shared/organisms/Table';

import PlaylistWatchDataTableRowItem from './PlaylistWatchDataTableRowItem';

const PlaylistWatchDataTable = ({
  itemsToDisplay,
  handleReminder,
  playlistId,
  showReminder,
}) => {
  const { t } = useTranslation();
  const [selectedItemIds, setSelectedItemIds] = useState([]);

  const showInvitedAt = itemsToDisplay.some((item) => item.invitedAt);

  const toggleShowExpandedUser = (id) => {
    if (selectedItemIds.includes(id)) {
      setSelectedItemIds((items) => items.filter((itemId) => id !== itemId));
    } else {
      setSelectedItemIds((items) => [...items, id]);
    }
  };

  const onClickHandleReminder = (event, userId, playlistId, lastReminderAt) => {
    event.stopPropagation();
    handleReminder(userId, playlistId, lastReminderAt);
  };

  const columns = [
    {
      key: 'name',
      label: t('watchData.name'),
      sticky: true,
      fixedWidth: '21%',
      maskValue: true,
    },
    {
      key: 'email',
      label: t('watchData.email'),
      fixedWidth: '22%',
      maskValue: true,
    },
    {
      key: 'progress',
      label: t('watchData.progress'),
      type: CELL_TYPES.COMPONENT,
      render: (row) => <ProgressBar progress={row.progress} />,
    },
    {
      key: 'lastReminderAt',
      label: t('watchData.lastReminder'),
      type: CELL_TYPES.DATE_TIME,
    },
  ];

  if (showInvitedAt) {
    columns.splice(2, 0, {
      key: 'invitedAt',
      label: t('watchData.shared'),
      type: CELL_TYPES.DATE,
    });
  }

  if (showReminder) {
    columns.push({
      key: 'sendReminder',
      label: t('watchData.sendNewReminder'),
      disableSorting: true,
      type: CELL_TYPES.COMPONENT,
      render: (row) => (
        <Button
          ariaLabel={t('watchData.sendReminder')}
          type="text"
          onClick={(e) =>
            onClickHandleReminder(e, row.id, playlistId, row.lastReminderAt)
          }
          small
        >
          {t('watchData.sendReminder')}
        </Button>
      ),
    });
  }

  return (
    <Table
      ariaLabel={t('watchData.activityOfYourUsers')}
      columns={columns}
      items={itemsToDisplay}
      defaultOrder={ORDER_TYPES.DESC}
      defaultOrderBy="progress"
      rowIsClickable={() => true}
      selectedItemIds={selectedItemIds}
      onRowClick={(row) => toggleShowExpandedUser(row.id)}
      ExpandedItemComponent={PlaylistWatchDataTableRowItem}
      expandedItemComponentProps={{ playlistId }}
    />
  );
};

PlaylistWatchDataTable.propTypes = {
  itemsToDisplay: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      sharedAt: PropTypes.string,
      progress: PropTypes.number.isRequired,
      lastReminderAt: PropTypes.string,
    })
  ).isRequired,
  handleReminder: PropTypes.func.isRequired,
  playlistId: PropTypes.string.isRequired,
  showReminder: PropTypes.bool,
};

export default PlaylistWatchDataTable;

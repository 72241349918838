import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import AddIcon from '@mui/icons-material/Add';
import ReplayIcon from '@mui/icons-material/Replay';

import Chip from '@shared/atoms/Chip';
import DragAndDrop from '@shared/molecules/DragAndDrop';
import Drawer from '@shared/atoms/Drawer';
import Button from '@shared/atoms/Button';
import T from '@shared/atoms/Typography';
import Tooltip from '@shared/atoms/Tooltip';
import ItemBox from '@shared/molecules/ItemBox';
import Dialog from '@shared/molecules/Dialog';

import { selectTranslation, logger } from '@shared/utils';
import theme from '@shared/themes/default';

import {
  courseStatus,
  DOMAIN_LOCALES,
  PROCESSING_STATUS,
  PROCESSING_STATUS_PRIORITY,
} from '@shared/const';

import { useAppGlobals } from '../../../../contexts/AppContext';
import { TABS, useCourse } from '../../../../contexts/CourseContext';

import Box from './components/Box';
import DrawerBatch from './components/DrawerBatch';
import DrawerLesson from './components/DrawerLesson';
import { DRAWER_ACTIONS } from './const';
import LessonCustomHeader from './components/LessonCustomHeader';
import ValidationList from '../../components/ValidationList';

const SectionStyled = styled.section`
  background: ${theme.palette.darkWrapper};
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.s};
  row-gap: ${theme.spacing.m1};
`;

const HeaderStyled = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacing.s};
`;

const ButtonGroupContainer = styled.div`
  align-items: center;
  column-gap: ${theme.spacing.m1};
  display: flex;
`;

const ReplaceLessonsList = styled.div`
  margin-top: ${theme.spacing.m1};
  max-height: 200px;
  overflow-y: auto;
`;

const LESSON_CONTENT = {
  SUBTITLE: 'subtitle',
  VIDEO: 'video',
};

const Lessons = () => {
  const { domain, locale } = useAppGlobals();
  const { t } = useTranslation();
  const languages = DOMAIN_LOCALES[domain];

  const {
    course: originalCourse,
    getLanguages,
    hasChanged,
    fetchLessonsProcessingStatus,
    locked,
    saveLessons,
    setHandleSave,
    setHasChanged,
    setHasError,
    setSubmitting,
    setUploadingFiles,
    submitting,
    submittingRefresh,
    uploadingFiles,
    validateCourse,
  } = useCourse();

  const [course, setCourse] = useState(originalCourse || {});
  const [episodes, setEpisodes] = useState([]);
  const [drawer, setDrawer] = useState('');
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [errorsDrawerLesson, setErrorsDrawerLesson] = useState([]);
  const [selectedEpisode, setSelectedEpisode] = useState();
  const [originalSelectedEpisode, setOriginalSelectedEpisode] = useState();
  const [batchEpisodes, setBatchEpisodes] = useState({});
  const [errorEpisodes, setErrorEpisodes] = useState({});
  const [enabledLanguages, setEnabledLanguages] = useState([languages[0]]);
  const [changedDrawerContent, setChangedDrawerContent] = useState(false);
  const [errorDrawer, setErrorDrawer] = useState(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [batchOverwrite, setBatchOverwrite] = useState([]);
  const [confirmationDialogData, setConfirmationDialogData] = useState();
  const [canSave, setCanSave] = useState(false);
  const [tabValidation, setTabValidation] = useState({});

  const isPublished = course?.status === courseStatus.PUBLISHED;
  const isLocked = submitting || (isPublished && locked);
  const hasTrailer =
    course.trailerVideoFiles?.length > 0 ||
    course.trailerVideoProcessingStatus?.status;
  const initialLanguages = getLanguages(course);
  const presentLanguages = Object.keys(initialLanguages).reduce(
    (acc, lang) => (initialLanguages[lang] ? [...acc, lang] : acc),
    []
  );
  const hasSubtitlesOriginal = originalCourse.episodes.some(
    (episode) => episode.subtitle !== null
  );

  const sortEpisodes = () =>
    (course.episodeOrder || []).map((orderedEpisode) =>
      course.episodes.find((episode) => episode.id === orderedEpisode)
    );
  const findEnabledLanguages = () =>
    languages.reduce((acc, language) => {
      const hasDescription =
        course?.descriptionTranslations &&
        selectTranslation(course.descriptionTranslations, '', language, '');
      const hasName =
        course?.nameTranslations &&
        selectTranslation(course.nameTranslations, '', language, '');
      const hasSeoDescription =
        course?.seoDescriptionTranslations &&
        selectTranslation(course.seoDescriptionTranslations, '', language, '');
      const hasShortDescription =
        course?.shortDescriptionTranslations &&
        selectTranslation(
          course.shortDescriptionTranslations,
          '',
          language,
          ''
        );
      const hasLearningPoints =
        course?.keyLearningPointsTranslations &&
        selectTranslation(
          course.keyLearningPointsTranslations,
          [],
          language,
          []
        ).length > 0;

      const isActive =
        hasDescription ||
        hasName ||
        hasSeoDescription ||
        hasShortDescription ||
        hasLearningPoints;

      return isActive ? [...acc, language] : acc;
    }, []);

  const hasEpisodeError = (episode) => {
    enabledLanguages.reduce((acc, language) => {
      const hasName =
        episode.nameTranslations &&
        selectTranslation(course.nameTranslations, '', language, '');

      const isActive = hasName;

      return acc || isActive;
    }, false);
  };

  const handleSave = async (newCourse) => {
    try {
      await saveLessons(newCourse);
      setHasChanged(false);
    } catch (error) {
      logger.error(error);
      setHasError(true);
      setSubmitting(false);
    }
  };

  /**
   * Return true if there are episodes without subtitles or no episode has subtitle
   *
   * @param modifiedEpisodes
   * @param originalEpisodes
   * @return {boolean}
   */
  const hasEpisodesWithoutSubtitles = (modifiedEpisodes, originalEpisodes) => {
    const modifiedEpisodeIdsWithoutSubtitle = modifiedEpisodes
      .filter(
        (episode) =>
          !episode.game &&
          episode.subtitle === null &&
          episode.subtitleProcessingStatus === null
      )
      .map((episode) => episode.id);
    const originalEpisodeIdsWithoutSubtitle = originalEpisodes
      .filter(
        (episode) =>
          !episode.game &&
          episode.subtitle === null &&
          episode.subtitleProcessingStatus === null
      )
      .map((episode) => episode.id);
    const remainingEpisodeIdsWithoutSubtitle =
      originalEpisodeIdsWithoutSubtitle.filter((origEpisodeId) =>
        modifiedEpisodeIdsWithoutSubtitle.includes(origEpisodeId)
      );

    return (
      remainingEpisodeIdsWithoutSubtitle.length > 0 &&
      remainingEpisodeIdsWithoutSubtitle.length < originalEpisodes.length
    );
  };

  const hasMixedSubtitles = (modifiedEpisodes) => {
    const modifiedEpisodesWithoutGames = modifiedEpisodes.filter(
      (episode) => !episode.game
    );
    const episodesWithoutSubtitles = modifiedEpisodesWithoutGames.filter(
      (episode) => {
        return (
          !episode.subtitle &&
          episode.subtitleProcessingStatus?.status !==
            PROCESSING_STATUS.TO_SCHEDULE
        );
      }
    );

    return (
      modifiedEpisodesWithoutGames.length !== episodesWithoutSubtitles.length &&
      episodesWithoutSubtitles.length !== 0
    );
  };

  const hasAllEpisodesWithoutSubtitles = (episodes) =>
    episodes.some(
      (episode) =>
        !episode.game &&
        !episode.subtitle &&
        episode.subtitleProcessingStatus?.status !==
          PROCESSING_STATUS.TO_SCHEDULE
    );

  const handlePreSave = async () => {
    const newCourse = { id: course.id };
    let changedVideoFiles = 0;

    if (
      course.trailerVideoProcessingStatus?.status ===
      PROCESSING_STATUS.TO_SCHEDULE
    ) {
      newCourse.trailerVideo = course.trailerVideoProcessingStatus.file;
      if (originalCourse.trailerVideoProcessingStatus) changedVideoFiles += 1;
    } else if (
      (course.trailerVideoFiles || []).length === 0 &&
      !course.trailerVideoProcessingStatus
    ) {
      newCourse.trailerVideo = null;
    }

    newCourse.episodes = (course.episodeOrder || []).reduce((acc, uuid) => {
      const originalEpisode = originalCourse?.episodes.find(
        (episode) => episode.id === uuid
      );
      const episode = course.episodes.find((episode) => episode.id === uuid);
      if (episode.hasChanged || episode.isNew) {
        const newEpisode = { nameTranslations: episode.nameTranslations };
        if (!episode.isNew) {
          newEpisode.id = episode.id;
        }

        if (
          episode.videoProcessingStatus?.status ===
          PROCESSING_STATUS.TO_SCHEDULE
        ) {
          newEpisode.video = episode.videoProcessingStatus.file;
          if (originalEpisode?.videoProcessingStatus) changedVideoFiles += 1;
        } else if (
          (episode.videoFiles || []).length === 0 &&
          !episode.videoProcessingStatus
        ) {
          newEpisode.video = null;
        }

        if (
          episode.subtitleProcessingStatus?.status ===
          PROCESSING_STATUS.TO_SCHEDULE
        ) {
          newEpisode.subtitle = episode.subtitleProcessingStatus.file;
        } else if (!episode.subtitle && !episode.subtitleProcessingStatus) {
          newEpisode.subtitle = null;
        }

        if (episode.game) {
          newEpisode.game = episode.game;
          newEpisode.video = null;
          newEpisode.subtitle = null;
        }

        newEpisode.attachments = (episode.attachments || []).map(
          ({ id, file, processingStatus, ...rest }) => {
            const result = id ? { id } : { ...rest };

            if (processingStatus?.status === PROCESSING_STATUS.TO_SCHEDULE) {
              result.file = processingStatus.file;
            }

            return result;
          }
        );

        return [...acc, newEpisode];
      }
      return [...acc, { id: episode.id }];
    }, []);

    if (isPublished && changedVideoFiles > 0) {
      setConfirmationDialogData({
        newCourse,
        changedVideoFiles,
      });
      setConfirmationDialogOpen(true);
      return;
    }
    await handleSave(newCourse);
  };

  const handlerHasChanged = () => {
    const originalCourseParsed = JSON.stringify(originalCourse);
    const courseParsed = JSON.stringify(course);

    if (!hasChanged && originalCourseParsed !== courseParsed) {
      setHasChanged(true);
    }
    if (hasChanged && originalCourseParsed === courseParsed) {
      setHasChanged(false);
    }
  };

  useEffect(() => {
    setHandleSave(() => handlePreSave);
  }, []);

  useEffect(() => {
    setEpisodes(sortEpisodes());
    setEnabledLanguages(findEnabledLanguages());
    setErrorEpisodes(
      episodes.reduce(
        (acc, episode) => ({ ...acc, [episode.id]: hasEpisodeError(episode) }),
        {}
      )
    );

    if (course?.status === courseStatus.PUBLISHED) {
      const validationStatus = validateCourse(
        course,
        languages,
        hasAllEpisodesWithoutSubtitles(originalCourse?.episodes)
      );
      if (validationStatus[TABS.LESSONS]?.episodes?.length) {
        const episodesValidation = validationStatus[
          TABS.LESSONS
        ].episodes.reduce((acc, { subtitle, index }) => {
          const episode = course.episodes.find(
            (ep) => ep.id === course.episodeOrder[index - 1]
          );
          if (subtitle && !episode?.subtitleProcessingStatus) {
            // Leave out attachment checking for published courses
            acc.push({ subtitle, index });
          }
          return acc;
        }, []);

        if (episodesValidation.length > 0) {
          validationStatus[TABS.LESSONS] = {
            episodes: episodesValidation,
          };
        } else {
          delete validationStatus[TABS.LESSONS];
        }
      }

      setTabValidation(validationStatus);
    }

    handlerHasChanged();

    setHandleSave(() => handlePreSave);
  }, [course]);

  useEffect(() => {
    setCourse(originalCourse || {});
  }, [originalCourse]);

  useEffect(() => {
    if (
      selectedEpisode &&
      selectedEpisode.id !== 'trailer' &&
      !selectedEpisode.items
    ) {
      setErrorsDrawerLesson(
        presentLanguages.filter(
          (lang) =>
            !selectTranslation(selectedEpisode.nameTranslations, '', lang)
        )
      );
    } else {
      setErrorsDrawerLesson([]);
    }
  }, [selectedEpisode]);

  useEffect(() => {
    const hasEpisodeError = Object.keys(errorEpisodes).reduce(
      (acc, id) => acc || errorEpisodes[id],
      false
    );

    if (hasChanged && !isLocked && !hasEpisodeError && !uploadingFiles) {
      if (isPublished) {
        const episodesWithoutSubtitle = hasEpisodesWithoutSubtitles(
          course.episodes,
          originalCourse.episodes
        );
        const mixedSubtitles = hasMixedSubtitles(course.episodes);
        const allEpisodesWithoutSubtitles = hasAllEpisodesWithoutSubtitles(
          course.episodes
        );

        const canSave =
          (!allEpisodesWithoutSubtitles || !hasSubtitlesOriginal) &&
          !mixedSubtitles &&
          !episodesWithoutSubtitle &&
          course.episodes.reduce((acc, episode) => {
            const status = episode.videoProcessingStatus?.status;
            return (
              acc &&
              (episode.game ||
                status === PROCESSING_STATUS.TO_SCHEDULE ||
                status === PROCESSING_STATUS.PROCESSING ||
                status === PROCESSING_STATUS.PROCESSED ||
                (episode.videoFiles || []).length > 0)
            );
          }, true);
        setCanSave(canSave);
      } else {
        setCanSave(true);
      }
    } else {
      setCanSave(false);
    }
  }, [hasChanged, isLocked, errorEpisodes, course]);

  const stringify = (object) => {
    if (!object) return '';
    return JSON.stringify(
      Object.keys(object)
        .filter((key) => key !== 'hasChanged')
        .sort()
        .reduce(
          (acc, key) => ({
            ...acc,
            [key]: object[key],
          }),
          {}
        )
    );
  };

  const handleOpenDrawer = (type) => {
    setDrawer(type);
    setIsOpenDrawer(true);
    setChangedDrawerContent(false);
  };

  const handleCloseDrawer = () => {
    setIsOpenDrawer(false);
    setDrawer('');
    setSelectedEpisode();
    setOriginalSelectedEpisode();
    setErrorDrawer(null);
    setUploadingFiles(false);
    handlerHasChanged();
  };

  const handleDeleteTrailer = () => {
    setCourse({
      ...course,
      trailerVideoFiles: [],
      trailerVideoProcessingStatus: null,
    });
  };

  const handleDeleteEpisode = (id) => {
    const filteredEpisodes = episodes.filter((episode) => episode.id !== id);
    setCourse({
      ...course,
      episodes: filteredEpisodes,
      episodeOrder: filteredEpisodes.map((episode) => episode.id),
    });
  };

  const handleDragEndEpisode = (newEpisodes) => {
    setCourse({
      ...course,
      episodeOrder: newEpisodes.map((episode) => episode.id),
    });
  };

  const handleChangeBatchDrawer = (newItems) => {
    setBatchEpisodes(newItems);
    setChangedDrawerContent(newItems && Object.keys(newItems)?.length > 0);
  };

  const handleChangeDrawer = (newItem, saveEnabled = true) => {
    const hasChangedDrawer =
      stringify(newItem) !== stringify(originalSelectedEpisode);

    const originalEpisode = originalCourse.episodes.find(
      (e) => e.id === newItem.id
    );
    const hasChanged = stringify(newItem) !== stringify(originalEpisode);

    const newSelectedEpisode = newItem;
    if (saveEnabled && hasChanged) {
      newSelectedEpisode.hasChanged = true;
    }

    setSelectedEpisode(newSelectedEpisode);
    setChangedDrawerContent(saveEnabled && hasChangedDrawer);
  };

  const handleSaveLessonDrawer = () => {
    let newCourse;
    if (selectedEpisode.id === 'trailer') {
      newCourse = {
        ...course,
        trailerVideoFiles: selectedEpisode.trailerVideoFiles,
        trailerVideoProcessingStatus:
          selectedEpisode.trailerVideoProcessingStatus,
      };
    } else if (selectedEpisode.id) {
      const idx = course.episodes.findIndex((e) => e.id === selectedEpisode.id);
      if (idx >= 0) {
        const cpyEpisodes = [...course.episodes];
        cpyEpisodes[idx] = selectedEpisode;
        newCourse = { ...course, episodes: cpyEpisodes };
      }
    } else {
      const newEpisode = { ...selectedEpisode, id: uuid(), isNew: true };
      const cpyEpisodes = [...course.episodes];
      cpyEpisodes.push(newEpisode);
      newCourse = {
        ...course,
        episodeOrder: [...(course.episodeOrder || []), newEpisode.id],
        episodes: cpyEpisodes,
      };
    }
    setCourse(newCourse);
    handleCloseDrawer();
  };

  const handleSaveBatchDrawer = () => {
    const episodeMap = {};
    course.episodeOrder?.forEach((id, index) => {
      const ep = course.episodes?.find((e) => e.id === id);
      episodeMap[index + 1] = ep;
    });

    const episodeKeys = [
      ...new Set(
        Object.keys(batchEpisodes).concat(Object.keys(episodeMap) || [])
      ),
    ].sort((a, b) => a - b);
    const newEpisodes = [];
    const newEpisodeOrder = [];
    const trailer = Object.values(batchEpisodes).find(
      (episode) => episode.id === 'trailer'
    );

    episodeKeys.forEach((key) => {
      const batchEpisode = batchEpisodes[key];
      const episode = episodeMap[key];
      if (batchEpisode?.id === 'trailer') {
        return;
      }
      if (batchEpisode && episode && !episode.game) {
        newEpisodes.push({
          ...episode,
          ...batchEpisode,
          ...(episode.game ? { game: null } : {}),
          nameTranslations: episode.nameTranslations,
          hasChanged: true,
        });
        newEpisodeOrder.push(episode.id);
      } else if (batchEpisode && !isPublished) {
        const newId = uuid();
        newEpisodes.push({
          ...batchEpisode,
          id: newId,
          isNew: true,
        });
        newEpisodeOrder.push(newId);
      } else if (episode) {
        newEpisodes.push(episode);
        newEpisodeOrder.push(episode.id);
      }
    });

    if (newEpisodes.find((ep) => ep.isNew || ep.hasChanged) || trailer) {
      const newCourse = {
        ...course,
        episodes: newEpisodes,
        episodeOrder: newEpisodeOrder,
        ...(trailer
          ? {
              trailerVideoFiles: trailer.trailerVideoFiles,
              trailerVideoProcessingStatus:
                trailer.trailerVideoProcessingStatus,
            }
          : {}),
      };
      setCourse(newCourse);
    }
    setBatchEpisodes({});
    handleCloseDrawer();
  };

  const handlePreSaveBatchDrawer = () => {
    const existingEpisodes = Object.keys(batchEpisodes).reduce(
      (acc, episodeKey) => {
        const episodeId = (course.episodeOrder || [])[episodeKey - 1];
        const batchEpisode = batchEpisodes[episodeKey];
        if (episodeId && batchEpisode) {
          const episode = course.episodes.find(
            (episode) => episode.id === episodeId
          );
          const types = [];
          if (
            episode.videoFiles &&
            batchEpisode.videoProcessingStatus?.status ===
              PROCESSING_STATUS.TO_SCHEDULE
          ) {
            types.push(LESSON_CONTENT.VIDEO);
          }
          if (
            episode.subtitle &&
            batchEpisode.subtitleProcessingStatus?.status ===
              PROCESSING_STATUS.TO_SCHEDULE
          ) {
            types.push(LESSON_CONTENT.SUBTITLE);
          }
          if (types.length) {
            return [
              ...acc,
              {
                item: course.episodes.find(
                  (episode) => episode.id === episodeId
                ),
                types,
              },
            ];
          }
        } else if (batchEpisode?.id === 'trailer') {
          const types = [];
          if (
            course.trailerVideoFiles &&
            batchEpisode.trailerVideoProcessingStatus?.status ===
              PROCESSING_STATUS.TO_SCHEDULE
          ) {
            types.push(LESSON_CONTENT.VIDEO);
          }
          if (types.length) {
            return [
              ...acc,
              {
                item: {
                  id: 'trailer',
                },
                types,
              },
            ];
          }
        }
        return acc;
      },
      []
    );

    if (existingEpisodes.length > 0) {
      setBatchOverwrite(existingEpisodes);
    } else {
      setBatchOverwrite([]);
      handleSaveBatchDrawer();
    }
  };

  const handleConfirmDialog = () => {
    const { newCourse, changedVideoFiles } = confirmationDialogData;
    handleSave(newCourse, changedVideoFiles);
    setConfirmationDialogData();
    setConfirmationDialogOpen(false);
  };
  const handleCancelDialog = () => {
    setConfirmationDialogData();
    setConfirmationDialogOpen(false);
  };
  const handleOpenBatch = () => {
    handleOpenDrawer(DRAWER_ACTIONS.BATCH);
  };
  const handleCancelOverwriteDialog = () => {
    setBatchOverwrite([]);
  };
  const handleConfirmOverwriteDialog = () => {
    setBatchOverwrite([]);
    handleSaveBatchDrawer();
  };

  const getProcessingStatus = (status) => {
    const processingStatus = {};

    switch (status) {
      case PROCESSING_STATUS.TO_SCHEDULE:
        Object.assign(processingStatus, {
          color: theme.palette.brand,
          label: t('backoffice.courses.lessonsTab.processingStatusToSchedule'),
        });
        break;
      case PROCESSING_STATUS.PROCESSING:
        Object.assign(processingStatus, {
          color: theme.palette.brand,
          label: t('backoffice.courses.lessonsTab.processingStatusProcessing'),
        });
        break;
      case PROCESSING_STATUS.ERROR:
        Object.assign(processingStatus, {
          color: theme.palette.errorDark,
          label: t('backoffice.courses.lessonsTab.processingStatusError'),
        });
        break;
      default:
        return undefined;
    }

    return processingStatus;
  };

  const processingStatusChip = (status) => {
    const processingStatus = getProcessingStatus(status);
    if (processingStatus) {
      return (
        <Chip
          color={processingStatus.color}
          label={processingStatus.label}
          theme="dark"
          small
        />
      );
    }
    return null;
  };

  const getTimeVideo = (videoFiles) => {
    if (!videoFiles || videoFiles.length === 0) return null;

    const { seconds } = videoFiles[0];
    if (!seconds) return null;

    const minutes = Math.floor(seconds / 60);
    const secondsLeft = `0${seconds % 60}`.slice(-2);
    return `${t(
      'backoffice.courses.lessonsTab.video'
    )}  ${minutes}:${secondsLeft}`;
  };

  const getTimeGame = (game) => {
    const { length } = game;
    return `${t('backoffice.courses.lessonsTab.game')}  ${length}:00`;
  };

  const getAttachmentStatus = (attachments) => {
    let status = PROCESSING_STATUS_PRIORITY[0];

    attachments.forEach(({ processingStatus }) => {
      const attachmentStatus =
        processingStatus?.status || PROCESSING_STATUS_PRIORITY[0];
      if (
        PROCESSING_STATUS_PRIORITY.indexOf(attachmentStatus) >
        PROCESSING_STATUS_PRIORITY.indexOf(status)
      ) {
        status = attachmentStatus;
      }
    });

    return status;
  };

  const getAllStatus = (episode = {}) => {
    const { attachments, videoProcessingStatus, subtitleProcessingStatus } =
      episode;

    let statusAttachments = PROCESSING_STATUS_PRIORITY[0];
    if (attachments && attachments.length > 0) {
      statusAttachments = getAttachmentStatus(attachments);
    }

    const statusVideo =
      videoProcessingStatus?.status || PROCESSING_STATUS_PRIORITY[0];
    const statusSubtitle =
      subtitleProcessingStatus?.status || PROCESSING_STATUS_PRIORITY[0];

    let finalStatus = statusVideo;
    if (
      PROCESSING_STATUS_PRIORITY.indexOf(statusSubtitle) >
      PROCESSING_STATUS_PRIORITY.indexOf(finalStatus)
    ) {
      finalStatus = statusSubtitle;
    }
    if (
      PROCESSING_STATUS_PRIORITY.indexOf(statusAttachments) >
      PROCESSING_STATUS_PRIORITY.indexOf(finalStatus)
    ) {
      finalStatus = statusAttachments;
    }

    return finalStatus;
  };

  const renderItem = (item, index) => {
    const { videoFiles, game } = item;
    const topText = [
      t('backoffice.courses.lessonsTab.lessonTitle', { noLesson: index + 1 }),
    ];

    const timeText = getTimeVideo(videoFiles);
    if (timeText) {
      topText.push(timeText);
    } else if (game) {
      topText.push(getTimeGame(game));
    }

    return (
      <ItemBox
        buttons={
          !isPublished
            ? [
                {
                  handler: () => {
                    handleDeleteEpisode(item.id);
                  },
                  label: t('backoffice.courses.delete'),
                  textStyle: true,
                },
              ]
            : []
        }
        draggable={!isPublished}
        error={presentLanguages.reduce(
          (acc, lang) =>
            acc || !selectTranslation(item.nameTranslations, '', lang),
          false
        )}
        id={item.id}
        key={item.id}
        menuItems={[]}
        onClickHandler={(id) => {
          const clickedEpisode = episodes.find((episode) => episode.id === id);
          setSelectedEpisode(clickedEpisode);
          setOriginalSelectedEpisode(clickedEpisode);
          handleOpenDrawer(DRAWER_ACTIONS.EDIT_LESSON);
        }}
        selected={item.id === originalSelectedEpisode?.id}
        theme="dark"
        title={selectTranslation(item.nameTranslations, '', locale)}
        topText={topText}
        topTextUppercase={false}
        topTag={processingStatusChip(getAllStatus(episodes[index]))}
      />
    );
  };

  const trailerButton = [];
  if (!isLocked && !hasTrailer) {
    trailerButton.push({
      handler: () => {
        const newEpisode = {
          id: 'trailer',
          trailerVideoFiles: null,
          trailerVideoProcessingStatus: null,
        };
        setSelectedEpisode(newEpisode);
        setOriginalSelectedEpisode(newEpisode);
        handleOpenDrawer(DRAWER_ACTIONS.ADD_TRAILER);
      },
      label: t('backoffice.courses.add'),
      textStyle: true,
    });
  }
  if (!isPublished && hasTrailer) {
    trailerButton.push({
      handler: handleDeleteTrailer,
      label: t('backoffice.courses.delete'),
      textStyle: true,
    });
  }

  let drawerTitle;
  const { hasChanged: _, ...cleanSelectedEpisode } = selectedEpisode || {};
  let drawerContent = (
    <DrawerLesson
      course={course}
      errors={errorsDrawerLesson}
      item={cleanSelectedEpisode}
      locked={isLocked}
      onChange={handleChangeDrawer}
      setChangedDrawerContent={setChangedDrawerContent}
      submitting={submitting}
      type={drawer || null}
    />
  );
  let drawerSave;
  switch (drawer) {
    case DRAWER_ACTIONS.ADD_LESSON:
      drawerTitle = t('backoffice.courses.lessonsTab.lessonsNew');
      drawerSave = handleSaveLessonDrawer;
      break;
    case DRAWER_ACTIONS.EDIT_TRAILER:
    case DRAWER_ACTIONS.ADD_TRAILER:
      drawerTitle = `${t(
        'backoffice.courses.lessonsTab.trailer'
      )} - ${selectTranslation(course.nameTranslations, '', locale)}`;
      drawerSave = handleSaveLessonDrawer;
      break;
    case DRAWER_ACTIONS.BATCH:
      drawerTitle = isPublished
        ? t('backoffice.courses.lessonsTab.batchSubtitlesUpload')
        : t('backoffice.courses.lessonsTab.batchUpload');
      drawerSave = handlePreSaveBatchDrawer;
      drawerContent = (
        <DrawerBatch
          onChange={(items) => handleChangeBatchDrawer(items)}
          setChangedDrawerContent={setChangedDrawerContent}
          setError={setErrorDrawer}
        />
      );
      break;
    case DRAWER_ACTIONS.EDIT_LESSON:
      drawerTitle = selectedEpisode
        ? selectTranslation(selectedEpisode.nameTranslations, '', locale)
        : '';
      drawerSave = handleSaveLessonDrawer;
      break;
    default:
      drawerTitle = '';
      break;
  }

  const isSaveDrawerDisabled =
    errorDrawer !== null ||
    errorsDrawerLesson.length > 0 ||
    uploadingFiles ||
    (isPublished &&
      drawer === DRAWER_ACTIONS.EDIT_LESSON &&
      !selectedEpisode?.game &&
      selectedEpisode?.videoFiles === null) ||
    (isPublished &&
      drawer === DRAWER_ACTIONS.EDIT_TRAILER &&
      selectedEpisode?.trailerVideoFiles === null);

  const topTextTrailer = [];
  const timeText = getTimeVideo(course.trailerVideoFiles);
  if (timeText) {
    topTextTrailer.push(timeText);
  }

  return (
    <SectionStyled>
      <Dialog
        actions={[
          {
            ariaLabel: t(
              'backoffice.courses.lessonsTab.confirmationDialogCancel'
            ),
            handler: handleCancelDialog,
            label: t('backoffice.courses.lessonsTab.confirmationDialogCancel'),
            type: 'secondaryOutline',
          },
          {
            ariaLabel: t(
              'backoffice.courses.lessonsTab.confirmationDialogConfirm'
            ),
            disabled: uploadingFiles,
            handler: handleConfirmDialog,
            label: t('backoffice.courses.lessonsTab.confirmationDialogConfirm'),
          },
        ]}
        ariaLabel={t(
          'backoffice.courses.lessonsTab.confirmationDialogArialabel'
        )}
        confirmationTitle={t(
          'backoffice.courses.lessonsTab.confirmationDialogTitle'
        )}
        onClose={handleCancelDialog}
        open={confirmationDialogOpen}
      >
        <T color={theme.palette.pureDark}>
          {t('backoffice.courses.lessonsTab.confirmationDialogMessage', {
            channel: t(
              `backoffice.courses.lessonsTab.processingSlack_${domain}`
            ),
          })}
        </T>
        <T color={theme.palette.pureDark} sx={{ marginTop: theme.spacing.m1 }}>
          {t('backoffice.courses.lessonsTab.confirmationDialogWarning')}
        </T>
      </Dialog>
      <Dialog
        actions={[
          {
            ariaLabel: t('backoffice.courses.lessonsTab.batchDialogCancel'),
            handler: handleCancelOverwriteDialog,
            label: t('backoffice.courses.lessonsTab.batchDialogCancel'),
            type: 'secondaryOutline',
          },
          {
            ariaLabel: t('backoffice.courses.lessonsTab.batchDialogConfirm'),
            disabled: uploadingFiles,
            handler: handleConfirmOverwriteDialog,
            label: t('backoffice.courses.lessonsTab.batchDialogConfirm'),
          },
        ]}
        ariaLabel={t(
          `backoffice.courses.lessonsTab.${
            isPublished
              ? 'batchSubtitleDialogArialabel'
              : 'batchDialogArialabel'
          }`
        )}
        onClose={handleCancelOverwriteDialog}
        open={batchOverwrite.length > 0}
      >
        <T color={theme.palette.pureDark}>
          {t(
            `backoffice.courses.lessonsTab.${
              isPublished ? 'batchSubtitlesDialogMessage' : 'batchDialogMessage'
            }`,
            {
              count: batchOverwrite.length,
            }
          )}
        </T>
        <ReplaceLessonsList>
          {batchOverwrite.map(({ item, types }) => (
            <T
              color={theme.palette.pureDark}
              key={item.id}
              fontWeight="semibold"
            >
              {item.id === 'trailer'
                ? `00 - ${t('backoffice.courses.lessonsTab.trailer')}`
                : `${`0${
                    (course?.episodeOrder?.findIndex(
                      (episodeId) => episodeId === item.id
                    ) || 0) + 1
                  }`.slice(-2)} - ${selectTranslation(
                    item.nameTranslations,
                    '',
                    locale,
                    ''
                  )}`}
              {!isPublished
                ? ` (${types
                    .map((type) =>
                      t(`backoffice.courses.lessonsTab.batchDialog.${type}`)
                    )
                    .join(', ')})`
                : ''}
            </T>
          ))}
        </ReplaceLessonsList>
      </Dialog>
      <HeaderStyled>
        <ButtonGroupContainer>
          {!isPublished && (
            <Button
              iconRight={AddIcon}
              onClick={() => {
                const newEpisode = {
                  videoFiles: null,
                  videoProcessingStatus: null,
                };
                setSelectedEpisode(newEpisode);
                setOriginalSelectedEpisode(newEpisode);
                handleOpenDrawer(DRAWER_ACTIONS.ADD_LESSON);
              }}
              sx={{ textTransform: 'uppercase' }}
              type={
                isOpenDrawer && drawer === DRAWER_ACTIONS.ADD_LESSON
                  ? 'secondary'
                  : 'secondaryOutline'
              }
            >
              {t('backoffice.courses.lessonsTab.lessonAdd')}
            </Button>
          )}
          {!isLocked && (
            <Button
              iconRight={AddIcon}
              onClick={handleOpenBatch}
              sx={{ textTransform: 'uppercase' }}
              type={
                isOpenDrawer && drawer === DRAWER_ACTIONS.BATCH
                  ? 'secondary'
                  : 'secondaryOutline'
              }
            >
              {isPublished
                ? t('backoffice.courses.lessonsTab.batchSubtitlesUpload')
                : t('backoffice.courses.lessonsTab.batch')}
            </Button>
          )}
        </ButtonGroupContainer>
        <ButtonGroupContainer>
          <Button
            disabled={canSave || submitting}
            iconLeft={ReplayIcon}
            loading={submittingRefresh}
            onClick={fetchLessonsProcessingStatus}
            type="secondaryOutline"
          >
            {t('backoffice.courses.refresh')}
          </Button>
          <Tooltip
            arrow
            title={
              course.status === courseStatus.PUBLISHED &&
              !canSave &&
              !submitting &&
              !locked &&
              Object.keys(tabValidation)?.length ? (
                <>
                  <T color={theme.palette.mainDark}>
                    {t('backoffice.courses.publishing.save')}
                  </T>
                  <ValidationList details={tabValidation} />
                </>
              ) : (
                ''
              )
            }
          >
            <div>
              <Button
                disabled={!canSave}
                loading={submitting}
                onClick={handlePreSave}
                type="secondary"
              >
                {t('generic.save')}
              </Button>
            </div>
          </Tooltip>
        </ButtonGroupContainer>
      </HeaderStyled>
      <Box>
        <ItemBox
          buttons={trailerButton}
          id={course.id}
          key={course.id}
          menuItems={[]}
          onClickHandler={
            hasTrailer
              ? () => {
                  const newEpisode = {
                    id: 'trailer',
                    trailerVideoFiles: course.trailerVideoFiles,
                    trailerVideoProcessingStatus:
                      course.trailerVideoProcessingStatus,
                  };
                  setSelectedEpisode(newEpisode);
                  setOriginalSelectedEpisode(newEpisode);
                  handleOpenDrawer(DRAWER_ACTIONS.EDIT_TRAILER);
                }
              : null
          }
          selected={originalSelectedEpisode?.id === 'trailer'}
          theme="dark"
          title={
            hasTrailer
              ? t('backoffice.courses.lessonsTab.trailer')
              : t('backoffice.courses.lessonsTab.trailerTitleEmpty')
          }
          topTextUppercase={false}
          topText={topTextTrailer}
          topTag={processingStatusChip(
            course.trailerVideoProcessingStatus?.status
          )}
        />
      </Box>
      <Box height="600px" header={<LessonCustomHeader episodes={episodes} />}>
        {(episodes.length > 0 && (
          <DragAndDrop
            isLocked={isLocked}
            items={episodes}
            onDragEnd={handleDragEndEpisode}
            renderItem={renderItem}
          />
        )) ||
          null}
      </Box>
      <Drawer
        anchor="right"
        open={isOpenDrawer}
        onClose={handleCloseDrawer}
        onSave={drawerSave}
        errorMessage={errorDrawer}
        saveDisabled={isSaveDrawerDisabled}
        sx={{
          '.MuiDrawer-paperAnchorRight': {
            width: '522px',
          },
        }}
        title={drawerTitle}
        edited={changedDrawerContent && errorDrawer === null}
      >
        {drawerContent}
      </Drawer>
    </SectionStyled>
  );
};

export default Lessons;

import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button as ButtonMui } from '@mui/material';

import theme from 'shared/themes/default';
import CircularSpinner from 'shared/atoms/CircularSpinner';

import { BACKGROUND_THEMES } from 'shared/const';
import { BUTTON_TYPE_ENUM } from './const';

const SpinnerWrapper = styled.div`
  height: 22px;
  width: 22px;
`;

const Button = ({
  ariaLabel,
  children,
  disabled,
  fullWidth,
  iconLeft: IconLeft,
  iconRight: IconRight,
  loading,
  loadingOnIcon,
  small,
  submit,
  sx: sxButton,
  theme: themeLayout,
  to,
  type,
  ...props
}) => {
  const typeSplit = type.split(/(?=[A-Z])/).map((s) => s.toLowerCase());
  const typePrimary = typeSplit[0];
  const typeSecondary = typeSplit[1];
  let colorLoadingOnIcon;
  let paramChildren = children;
  let ParamIconLeft = IconLeft;
  let ParamIconRight = IconRight;
  const sxGeneric = {
    borderRadius: '1.25rem',
    boxSizing: 'border-box',
    color: theme.palette.mainDark,
    fontWeight: '600',
    fontSize: '1rem',
    lineHeight: '1rem',
    padding: '0.75rem 1rem',
    textTransform: 'none',
    '&:focus': {
      outline: `3px solid ${theme.palette.focus}`,
      outlineOffset: '1px',
    },
    '.MuiButton-startIcon': {
      marginRight: '0.5rem',
    },
    '.MuiButton-endIcon': {
      marginLeft: '0.5rem',
    },
  };

  let sxSmall = {};
  let sxIcons = {};
  if (ParamIconLeft || ParamIconRight || (loading && loadingOnIcon)) {
    sxIcons = {
      padding: '0.625rem 1rem',
    };
  }
  if (small) {
    sxSmall = {
      fontSize: '0.875rem',
      minWidth: '46px',
      padding: '0.25rem 1rem',
      lineHeight: '1.25rem',
      '.MuiButton-startIcon, .MuiButton-endIcon': {
        '.MuiSvgIcon-root': {
          fontSize: '18px',
        },
      },
    };
    if (ParamIconLeft || ParamIconRight || (loading && loadingOnIcon)) {
      sxIcons = {
        padding: '0.25rem 1.25rem',
      };
    }
  }

  let sxLoading = {};
  if (loading && !loadingOnIcon && typePrimary !== 'text') {
    sxLoading = {
      padding: small ? '0.1875rem 0.5rem' : '0.5rem 1.5rem',
    };
    ParamIconLeft = null;
    ParamIconRight = null;
    paramChildren = (
      <SpinnerWrapper>
        <CircularSpinner
          size={small ? 14 : 22}
          color={theme.palette.mainDark}
          thickness={6}
        />
      </SpinnerWrapper>
    );
  }

  let sxType;
  switch (typePrimary) {
    case 'primary':
      sxType = {
        background: theme.palette.brand,
        '&:active': {
          background:
            themeLayout === 'dark'
              ? theme.palette.lightPure
              : theme.palette.mainDark,
          color:
            themeLayout === 'dark'
              ? theme.palette.mainDark
              : theme.palette.lightPure,
        },
        '&:hover': {
          background:
            themeLayout === 'dark'
              ? theme.palette.lightInteracting
              : theme.palette.darkDistinct,
          color:
            themeLayout === 'dark'
              ? theme.palette.mainDark
              : theme.palette.lightPure,
        },
        '&.Mui-disabled': {
          background:
            themeLayout === 'dark'
              ? theme.palette.disabledDark
              : theme.palette.disabledLight,
          color:
            themeLayout === 'dark'
              ? theme.palette.darkWrapper
              : theme.palette.darkDistinct,
        },
      };
      colorLoadingOnIcon =
        themeLayout === 'dark'
          ? theme.palette.darkWrapper
          : theme.palette.darkDistinct;
      if (loading && !loadingOnIcon) {
        sxLoading = {
          ...sxLoading,
          ...{
            '&.Mui-disabled': {
              background: theme.palette.brand,
            },
            svg: {
              color: theme.palette.mainDark,
            },
          },
        };
      }
      break;
    case 'secondary':
      sxType = {
        background: theme.palette.lightPure,
        boxShadow:
          themeLayout === 'light'
            ? `inset 0 0 0 1px ${theme.palette.mainDark}`
            : 'none',
        '&:active': {
          background:
            themeLayout === 'dark'
              ? theme.palette.brand
              : theme.palette.mainDark,
          color:
            themeLayout === 'dark'
              ? theme.palette.mainDark
              : theme.palette.lightPure,
        },
        '&:hover': {
          background: theme.palette.lightInteracting,
          color: theme.palette.mainDark,
        },
        '&.Mui-disabled': {
          background:
            themeLayout === 'dark'
              ? theme.palette.disabledDark
              : theme.palette.lightPure,
          color:
            themeLayout === 'dark'
              ? theme.palette.darkWrapper
              : theme.palette.disabledLight,
          boxShadow:
            themeLayout === 'light'
              ? `inset 0 0 0 1px ${theme.palette.disabledLight}`
              : 'none',
        },
      };
      colorLoadingOnIcon =
        themeLayout === 'dark'
          ? theme.palette.darkWrapper
          : theme.palette.disabledLight;
      if (loading && !loadingOnIcon) {
        sxLoading = {
          ...sxLoading,
          ...{
            '&.Mui-disabled': {
              background: theme.palette.lightPure,
            },
            svg: {
              color: theme.palette.pureDark,
            },
          },
        };
      }
      if (typeSecondary === 'outline' && themeLayout === 'dark') {
        sxType = {
          background: 'transparent',
          boxShadow: `inset 0 0 0 1px ${theme.palette.lightPure}`,
          color: theme.palette.lightPure,
          '&:active': {
            background: theme.palette.lightPure,
            color: theme.palette.mainDark,
          },
          '&:hover': {
            background: theme.palette.lightInteracting,
            boxShadow: 'none',
            color: theme.palette.mainDark,
          },
          '&.Mui-disabled': {
            boxShadow: `inset 0 0 0 1px ${theme.palette.disabledDark}`,
            color: theme.palette.disabledDark,
          },
        };
        colorLoadingOnIcon = theme.palette.disabledDark;
        if (loading && !loadingOnIcon) {
          sxLoading = {
            ...sxLoading,
            ...{
              '&.Mui-disabled': {
                background: 'transparent',
              },
              svg: {
                color: theme.palette.lightPure,
              },
            },
          };
        }
      }
      break;
    case 'text': {
      sxType = {
        background: 'transparent',
        color:
          themeLayout === 'dark' ? theme.palette.brand : theme.palette.mainDark,
        letterSpacing: '-0.1px',
        padding: '0.375rem 0.75rem',
        textDecoration: 'underline',
        textDecorationColor: `${
          themeLayout === 'dark' ? theme.palette.brand : theme.palette.mainDark
        }75`,
        '&:active': {
          background: 'transparent !important',
          color:
            themeLayout === 'dark'
              ? theme.palette.lightPure
              : theme.palette.mainDark,
          textDecoration: 'underline',
          textDecorationColor: `${
            themeLayout === 'dark'
              ? theme.palette.lightPure
              : theme.palette.mainDark
          }75`,
          fontWeight: themeLayout === 'dark' ? 600 : 700,
        },
        '&:hover': {
          background:
            themeLayout === 'dark'
              ? theme.palette.darkDistinct
              : theme.palette.brand,
          textDecoration: 'underline',
          textDecorationColor: `${
            themeLayout === 'dark'
              ? theme.palette.brand
              : theme.palette.mainDark
          }75`,
        },
        '&.Mui-disabled': {
          color: theme.palette.disabledDark,
          textDecoration: 'underline',
          textDecorationColor: `${
            themeLayout === 'dark'
              ? theme.palette.disabledDark
              : theme.palette.mainDark
          }75`,
        },
      };
      if (typeSecondary === 'white' && themeLayout === 'dark') {
        sxType = {
          ...sxType,
          ...{
            color: theme.palette.lightPure,
            textDecorationColor: `${theme.palette.lightPure}75`,
            '&:active': {
              color: theme.palette.brand,
              textDecoration: 'underline',
              textDecorationColor: `${theme.palette.brand}75`,
            },
            '&:hover': {
              background: theme.palette.darkDistinct,
              textDecoration: 'underline',
              textDecorationColor: `${theme.palette.lightPure}75`,
            },
          },
        };
      }
      if (ParamIconLeft || ParamIconRight || (loading && loadingOnIcon)) {
        sxIcons = {
          padding: '0.25rem 0.75rem',
        };
      }
      break;
    }
    default:
      sxType = {};
      break;
  }

  const sx = {
    ...sxGeneric,
    ...sxType,
    ...sxSmall,
    ...sxButton,
    ...sxIcons,
    ...sxLoading,
  };

  let IconLeftComponent =
    React.isValidElement(ParamIconLeft) || !ParamIconLeft ? (
      ParamIconLeft
    ) : (
      <ParamIconLeft />
    );
  let IconRightComponent =
    React.isValidElement(ParamIconRight) || !ParamIconRight ? (
      ParamIconRight
    ) : (
      <ParamIconRight />
    );
  const loadingOnIconComponent = (
    <CircularSpinner static size={20} color={colorLoadingOnIcon} />
  );

  if (loadingOnIcon && loading) {
    IconLeftComponent = IconLeftComponent
      ? loadingOnIconComponent
      : IconLeftComponent;
    IconRightComponent = IconRightComponent
      ? loadingOnIconComponent
      : IconRightComponent;

    if (!IconLeftComponent && !IconRightComponent) {
      IconRightComponent = loadingOnIconComponent;
    }
  }

  const extraProps = {};

  if (to) {
    extraProps.to = to;
    extraProps.component = Link;
  }

  return (
    <ButtonMui
      aria-label={ariaLabel}
      disabled={
        disabled || (loading && !(typePrimary === 'text' && !loadingOnIcon))
      }
      disableRipple
      endIcon={IconRightComponent}
      fullWidth={fullWidth}
      startIcon={IconLeftComponent}
      sx={sx}
      type={submit ? 'submit' : 'button'}
      {...extraProps}
      {...props}
    >
      {paramChildren}
    </ButtonMui>
  );
};

Button.propTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  iconLeft: PropTypes.object,
  iconRight: PropTypes.object,
  loading: PropTypes.bool,
  loadingOnIcon: PropTypes.bool,
  small: PropTypes.bool,
  submit: PropTypes.bool,
  sx: PropTypes.object,
  theme: PropTypes.oneOf(BACKGROUND_THEMES),
  to: PropTypes.string,
  type: PropTypes.oneOf(BUTTON_TYPE_ENUM),
};

Button.defaultProps = {
  disabled: false,
  fullWidth: false,
  loading: false,
  loadingOnIcon: false,
  small: false,
  submit: false,
  sx: {},
  theme: 'dark',
  type: 'primary',
};

export default Button;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { graphqlOperation } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import { OrganizationMembersProvider } from '@shared/sections/OrganizationMembers/context';

import Autocomplete from '@shared/atoms/Autocomplete';
import T from '@shared/atoms/Typography';
import OrganizationMembers from '@shared/sections/OrganizationMembers';
import theme from '@shared/themes/default';

import { useAppGlobals } from '../../contexts/AppContext';
import { graphql, listOrganizationOptions } from '../../../utils';
import {
  downloadInvitations,
  downloadOrganizationGroups,
  downloadOrganizationMembers,
  getOrganizationMembers,
  listGroups,
  listInvitations,
  getActiveSubscriptionByOrganization,
  listUsersByGroup,
  getOrganization,
  listOrganizationsNames,
} from './graphql/queries';
import {
  deleteUserPlaylists,
  deleteUserGroups,
  removeFromOrganization,
  removeInvitations,
  resendInvitations,
  sendInvitations,
  transferUserPlaylists,
  transferUserGroups,
  changeUserRole,
  createGroup,
  updateGroup,
  deleteGroup,
  removeGroupAccess,
  updateGroupAccess,
  transferGroup,
} from './graphql/mutations';

const AutocompleteWrapper = styled.div`
  max-width: 400px;
`;

const MembersView = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { domain, locale, user } = useAppGlobals();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const preSelectedOrganizationId = searchParams.get('organization');
  const preSelectedUserIdToManageRole = searchParams.get('user');
  const paramsView = searchParams.get('view');
  const paramsManage = searchParams.get('manage');

  const [organizationSelected, setOrganizationSelected] = useState(null);
  const [totalLicences, setTotalLicences] = useState(null);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [organizationIdUrl, setOrganizationIdUrl] = useState();

  const [getOrganizationMembersQuery, setGetOrganizationMembersQuery] =
    useState(null);
  const [listGroupsQuery, setListGroupsQuery] = useState(null);
  const [listInvitationsQuery, setListInvitationsQuery] = useState(null);

  const fetchOrganizations = async () => {
    const response = await graphql(
      graphqlOperation(listOrganizationsNames, { domain })
    );
    return listOrganizationOptions(
      response?.data?.listOrganizations.items || []
    );
  };

  const getLicencesForOrganization = async (organizationSelected) => {
    if (!organizationSelected) return;
    const { value } = organizationSelected;
    const response = await graphql(
      graphqlOperation(getActiveSubscriptionByOrganization, {
        organizationId: value,
      })
    );
    if (response?.data) {
      const {
        data: { getActiveSubscriptionByOrganization: subscription },
      } = response;
      if (subscription) {
        setTotalLicences(
          subscription.unlimited ? 'unlimited' : subscription.licenses
        );
        setHasSubscription(true);
      } else {
        setTotalLicences(null);
        setHasSubscription(false);
      }
    }
  };

  const fetchOrganization = async (organizationId) => {
    const response = await graphql(
      graphqlOperation(getOrganization, { id: organizationId })
    );
    const selectedOrganizationInfo = response.data.getOrganization;

    if (selectedOrganizationInfo) {
      const { name, id: value } = selectedOrganizationInfo;
      setOrganizationSelected({
        name,
        value,
      });
    }
  };

  const getGraphQlQuery = (query) => {
    const graphQlQuery = async (...params) => graphql(query(...params));
    return graphQlQuery;
  };

  const getGraphQlQueryWithOrganization = (query, organizationId) => {
    const graphQlQuery = async (...params) =>
      graphql(query(organizationId, ...params));
    return graphQlQuery;
  };

  const selectOrganizationHandler = (organization) => {
    if (organization) {
      const { value: organizationId } = organization;
      navigate(`/members?organization=${organizationId}&view=members`);
    } else {
      navigate('/members');
    }
  };

  const onViewGroupActivity = (organizationId, groupId) => {
    navigate(
      `/activity?organization=${organizationId}&view=groups&id=${groupId}`
    );
  };

  const handleHistory = (queryParams) => {
    if (organizationIdUrl) {
      navigate(`/members?organization=${organizationIdUrl}&${queryParams}`);
    } else {
      navigate('/members');
    }
  };

  useEffect(() => {
    if (organizationIdUrl) {
      fetchOrganization(organizationIdUrl);
      getLicencesForOrganization({ value: organizationIdUrl });
    }
  }, [organizationIdUrl]);

  useEffect(() => {
    if (preSelectedOrganizationId !== organizationIdUrl) {
      setGetOrganizationMembersQuery(() =>
        getGraphQlQueryWithOrganization(
          getOrganizationMembers,
          preSelectedOrganizationId
        )
      );
      setListGroupsQuery(() =>
        getGraphQlQueryWithOrganization(listGroups, preSelectedOrganizationId)
      );
      setListInvitationsQuery(() =>
        getGraphQlQueryWithOrganization(
          listInvitations,
          preSelectedOrganizationId
        )
      );
      setOrganizationIdUrl(preSelectedOrganizationId);
    }
  }, [preSelectedOrganizationId]);

  return (
    <div>
      <T
        color={theme.palette.lightPure}
        sx={{
          marginBottom: theme.spacing.m1,
        }}
        variant="subtitle"
      >
        {t('backoffice.general.findOrganization')}
      </T>
      <AutocompleteWrapper>
        <Autocomplete
          getAllOptions={fetchOrganizations}
          getOptionKey={(option) => option.value}
          getOptionLabel={(option) => option.name}
          onChange={selectOrganizationHandler}
          preSelectedValue={organizationSelected}
        />
      </AutocompleteWrapper>
      {organizationIdUrl &&
        getOrganizationMembersQuery &&
        listGroupsQuery &&
        listInvitationsQuery && (
          <OrganizationMembersProvider
            changeUserRole={(organizationId) =>
              getGraphQlQueryWithOrganization(changeUserRole, organizationId)
            }
            createGroup={(organizationId) =>
              getGraphQlQueryWithOrganization(createGroup, organizationId)
            }
            deleteOwnUser
            deleteUserGroups={(organizationId) =>
              getGraphQlQueryWithOrganization(deleteUserGroups, organizationId)
            }
            deleteUserPlaylists={(organizationId) =>
              getGraphQlQueryWithOrganization(
                deleteUserPlaylists,
                organizationId
              )
            }
            downloadInvitations={(organizationId) =>
              getGraphQlQueryWithOrganization(
                downloadInvitations,
                organizationId
              )
            }
            downloadOrganizationGroups={(organizationId) =>
              getGraphQlQueryWithOrganization(
                downloadOrganizationGroups,
                organizationId
              )
            }
            downloadOrganizationMembers={(organizationId) =>
              getGraphQlQueryWithOrganization(
                downloadOrganizationMembers,
                organizationId
              )
            }
            getOrganizationMembersQuery={(organizationId) =>
              getGraphQlQueryWithOrganization(
                getOrganizationMembers,
                organizationId
              )
            }
            handleHistory={handleHistory}
            hasSubscription={hasSubscription}
            isBackOffice
            listGroupMembers={getGraphQlQuery(listUsersByGroup)}
            listGroups={(organizationId) =>
              getGraphQlQueryWithOrganization(listGroups, organizationId)
            }
            listInvitations={(organizationId) =>
              getGraphQlQueryWithOrganization(listInvitations, organizationId)
            }
            listView={paramsView}
            locale={locale}
            manageGroups={paramsManage === 'groups'}
            manageRoles={paramsManage === 'role'}
            onSubmitGroupMembers={getGraphQlQuery(updateGroupAccess)}
            onViewGroupActivity={(organizationId, groupId) =>
              onViewGroupActivity(organizationId, groupId)
            }
            organizationId={organizationIdUrl}
            preSelectedUserIdToManageRole={preSelectedUserIdToManageRole}
            removeFromGroup={getGraphQlQuery(removeGroupAccess)}
            removeFromOrganization={(organizationId) =>
              getGraphQlQueryWithOrganization(
                removeFromOrganization,
                organizationId
              )
            }
            removeInvitations={(organizationId) =>
              getGraphQlQueryWithOrganization(removeInvitations, organizationId)
            }
            requestToDeleteGroup={getGraphQlQuery(deleteGroup)}
            resendInvitations={(organizationId) =>
              getGraphQlQueryWithOrganization(resendInvitations, organizationId)
            }
            sendInvitationsQuery={(organizationId) =>
              getGraphQlQueryWithOrganization(sendInvitations, organizationId)
            }
            totalLicences={totalLicences}
            transferGroup={(organizationId) =>
              getGraphQlQueryWithOrganization(transferGroup, organizationId)
            }
            transferUserGroups={(organizationId) =>
              getGraphQlQueryWithOrganization(
                transferUserGroups,
                organizationId
              )
            }
            transferUserPlaylists={(organizationId) =>
              getGraphQlQueryWithOrganization(
                transferUserPlaylists,
                organizationId
              )
            }
            updateGroup={getGraphQlQuery(updateGroup)}
            user={user}
          >
            <OrganizationMembers />
          </OrganizationMembersProvider>
        )}
    </div>
  );
};

export default MembersView;

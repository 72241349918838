import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Chip from 'shared/atoms/Chip';
import CircularSpinner from 'shared/atoms/CircularSpinner';
import T from 'shared/atoms/Typography';

import { courseStatus } from 'shared/const';

import Table, { CELL_TYPES, ORDER_TYPES } from 'shared/organisms/Table';

import { useWatchData } from 'shared/sections/WatchData/Context';

import { selectTranslation } from 'shared/utils';
import theme from 'shared/themes/default';

const SpinnerWrapper = styled.div`
  padding-top: ${theme.spacing.m2};
`;

const InfoWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${theme.spacing.s};
`;

const UsersWatchDataTableRowItem = ({ itemId: userId, open }) => {
  const { t } = useTranslation();
  const [watchHistory, setWatchHistory] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [loading, setLoading] = useState(false);

  const { getUserWatchData, locale, setWatchDataError } = useWatchData();

  const handleError = (error) => {
    console.error('Error calling getUserWatchData', error);
    setNextToken(null);
    setWatchHistory(false);
    setWatchDataError(true);
  };

  const fetchUsersWatchHistory = async (userId, nextToken) => {
    try {
      const { data, error } = await getUserWatchData(userId, nextToken);
      if (error) return handleError(error);

      const {
        getUserWatchData: { items, nextToken: newNextToken },
      } = data;
      if (!nextToken) {
        setWatchHistory(items);
      } else {
        setWatchHistory([...watchHistory, ...items]);
      }

      if (newNextToken) {
        setNextToken(newNextToken);
      } else {
        setLoading(false);
      }
    } catch (error) {
      handleError(error);
    }
    return true;
  };

  useEffect(() => {
    if (open) {
      setLoading(true);
      fetchUsersWatchHistory(userId);
    }
  }, [open]);

  useEffect(() => {
    if (nextToken && open) {
      fetchUsersWatchHistory(userId, nextToken);
    }
  }, [nextToken, open]);

  const columns = [
    {
      key: 'courseTitle',
      label: t('watchData.courses'),
      fixedWidth: '30%',
      expandedSticky: true,
      type: CELL_TYPES.COMPONENT,
      render: (row) => (
        <InfoWrapper>
          {row.courseTitle}
          {row.status === courseStatus.ARCHIVED && (
            <Chip
              color={theme.palette.errorLight}
              label={t('watchData.deleted')}
              small
              theme="dark"
            />
          )}
        </InfoWrapper>
      ),
    },
    {
      key: 'episodesCompleted',
      label: t('watchData.completedEpisodes'),
      type: CELL_TYPES.COMPONENT,
      render: (row) =>
        t('watchData.iOutOfJ', {
          i: row.episodesCompleted,
          j: row.episodesCount,
        }),
    },
    {
      key: 'firstTimeWatched',
      label: t('watchData.courseStart'),
      type: CELL_TYPES.DATE,
    },
    {
      key: 'completedTime',
      label: t('watchData.courseCompleted'),
      type: CELL_TYPES.DATE,
    },
    {
      key: 'certificationTime',
      label: t('watchData.certificationDate'),
      type: CELL_TYPES.DATE,
    },
  ];

  const hasWatchHistory = watchHistory.length > 0;

  const enrichedWatchData =
    hasWatchHistory &&
    watchHistory.map((historyRow) => ({
      ...historyRow,
      courseTitle: selectTranslation(
        historyRow.nameTranslations,
        '',
        locale,
        ''
      ),
    }));

  if (loading) {
    return (
      <SpinnerWrapper>
        <CircularSpinner static />
      </SpinnerWrapper>
    );
  }

  return hasWatchHistory ? (
    <Table
      ariaLabel="Expandable watch data row inner content"
      columns={columns}
      items={enrichedWatchData}
      keyField="nameTranslations" // We don't receive the ID from the backend, although it's in the Schema
      defaultOrder={ORDER_TYPES.DESC}
      defaultOrderBy="episodesCompleted"
      isExpandedType
      isExpandedOpen={open}
    />
  ) : (
    <T color={theme.palette.lightPure} pt={theme.spacing.m2} align="center">
      {t('watchData.userHaventStartAnyCourseMessage')}
    </T>
  );
};

UsersWatchDataTableRowItem.propTypes = {
  itemId: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

UsersWatchDataTableRowItem.defaultProps = {
  open: false,
};

export default UsersWatchDataTableRowItem;

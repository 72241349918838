import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import FormImage from '@shared/atoms/FormFields/FormImage';
import theme from '@shared/themes/default';
import { PROCESSING_STATUS } from '@shared/const';

import {
  getUploadUrl,
  startUpload,
  completeUpload,
} from '../../../../../graphql/mutations';

import { getStaticImagesHostname } from '../../../../../config';
import { graphql } from '../../../../../utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const UploadsTab = ({ handleChange, values, properties }) => {
  const { t } = useTranslation();
  const { picture, verticalImage, signature } = values;

  const handleAddImage = (name, file) => {
    handleChange({
      name,
      value: {
        value: { base64: file.base64 },
        processingStatus: {
          file: file.s3File,
          status: PROCESSING_STATUS.TO_SCHEDULE,
        },
      },
    });
  };

  const handleDeleteImage = (name) => {
    handleChange({
      name,
      value: null,
    });
  };

  return (
    <Wrapper>
      <FormImage
        completeUpload={completeUpload}
        getUploadUrl={getUploadUrl}
        graphql={graphql}
        handleAdd={(file) => handleAddImage('picture', file)}
        handleDelete={() => handleDeleteImage('picture')}
        id="picture"
        infoText={t('backoffice.experts.uploadTab.horizontalInfo')}
        imgUrl={
          picture?.value &&
          (picture.value.base64 ||
            `${getStaticImagesHostname()}/${picture.value.key}`)
        }
        name="picture"
        processingStatus={picture?.processingStatus?.status}
        properties={properties}
        small
        startUpload={startUpload}
        title={t('backoffice.experts.uploadTab.horizontal')}
      />
      <FormImage
        completeUpload={completeUpload}
        getUploadUrl={getUploadUrl}
        graphql={graphql}
        handleAdd={(file) => handleAddImage('verticalImage', file)}
        handleDelete={() => handleDeleteImage('verticalImage')}
        id="verticalImage"
        infoText={t('backoffice.experts.uploadTab.verticalInfo')}
        imgUrl={
          verticalImage?.value &&
          (verticalImage.value.base64 ||
            `${getStaticImagesHostname()}/${verticalImage.value.key}`)
        }
        name="verticalImage"
        processingStatus={verticalImage?.processingStatus?.status}
        properties={properties}
        small
        startUpload={startUpload}
        title={t('backoffice.experts.uploadTab.vertical')}
      />
      <FormImage
        colorBackground={signature && theme.palette.lightInteracting}
        completeUpload={completeUpload}
        getUploadUrl={getUploadUrl}
        graphql={graphql}
        handleAdd={(file) => handleAddImage('signature', file)}
        handleDelete={() => handleDeleteImage('signature')}
        id="signature"
        infoText={t('backoffice.experts.uploadTab.signatureInfo')}
        imgUrl={
          signature?.value &&
          (signature.value.base64 ||
            `${getStaticImagesHostname()}/${signature.value.key}`)
        }
        name="signature"
        processingStatus={signature?.processingStatus?.status}
        properties={properties}
        small
        startUpload={startUpload}
        title={t('backoffice.experts.uploadTab.signature')}
      />
    </Wrapper>
  );
};

UploadsTab.propTypes = {
  handleChange: PropTypes.func.isRequired,
  properties: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

export default UploadsTab;

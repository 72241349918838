export const listCategories = (domain, nextToken) => ({
  name: 'listCategories',
  query: `query listCategories(
    $filter: CategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        domain
        title
        titleTranslations
        ordinal
        expand
        description
        descriptionTranslations
        courses(limit: 100) {
          items {
            id
            shortId
            name
            nameTranslations
          }
        }
      }
    }
  }`,
  variables: {
    filter: {
      domain: {
        contains: domain,
      },
    },
    limit: 500,
    nextToken,
  },
});

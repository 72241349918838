import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Chip from 'shared/atoms/Chip';
import { PLAYLIST_STATUS } from 'shared/const';
import Table, { CELL_TYPES, ORDER_TYPES } from 'shared/organisms/Table';
import theme from 'shared/themes/default';

const InfoWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${theme.spacing.s};
`;

const NanodegreesWatchDataTable = ({
  itemsToDisplay,
  selectNanodegreeHandler,
}) => {
  const { t } = useTranslation();

  const columns = [
    {
      fixedWidth: '67%',
      key: 'name',
      label: t('nanodegree.nanodegree'),
      sticky: true,
      type: CELL_TYPES.COMPONENT,
      render: (row) => (
        <InfoWrapper>
          {row.name}
          {row.status === PLAYLIST_STATUS.ARCHIVED && (
            <Chip
              color={theme.palette.errorLight}
              label={t('watchData.deleted')}
              small
              theme="dark"
            />
          )}
        </InfoWrapper>
      ),
    },
    {
      fixedWidth: '9%',
      key: 'coursesCount',
      label: t('watchData.courses'),
      type: CELL_TYPES.TEXT,
    },
    {
      fixedWidth: '9%',
      key: 'started',
      label: t('watchData.started'),
      type: CELL_TYPES.TEXT,
    },
    {
      fixedWidth: '9%',
      key: 'graduated',
      label: t('watchData.graduated'),
      type: CELL_TYPES.TEXT,
    },
    {
      fixedWidth: '7%',
      key: 'averageCompletion',
      label: t('watchData.averageCompletion'),
      type: CELL_TYPES.COMPONENT,
      render: (row) =>
        t('watchData.percentage', { percentage: row.averageCompletion }),
    },
  ];

  return (
    <Table
      ariaLabel={t('watchData.activitiesOfYourNanodegrees')}
      columns={columns}
      defaultOrder={ORDER_TYPES.DESC}
      defaultOrderBy="averageCompletion"
      items={itemsToDisplay}
      onRowClick={
        selectNanodegreeHandler ? (row) => selectNanodegreeHandler(row) : null
      }
      rowIsClickable={(row) => !!row.started || !!selectNanodegreeHandler}
    />
  );
};

NanodegreesWatchDataTable.propTypes = {
  itemsToDisplay: PropTypes.array.isRequired,
  selectNanodegreeHandler: PropTypes.func,
};

export default NanodegreesWatchDataTable;

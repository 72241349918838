import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Table, { CELL_TYPES, ORDER_TYPES } from 'shared/organisms/Table';

const CourseWatchDataTable = ({ itemsToDisplay }) => {
  const { t } = useTranslation();

  const columns = [
    {
      key: 'name',
      label: t('watchData.name'),
      sticky: true,
      fixedWidth: '20%',
      maskValue: true,
    },
    {
      key: 'email',
      label: t('watchData.email'),
      fixedWidth: '36%',
      maskValue: true,
    },
    {
      key: 'episodesCompleted',
      label: t('watchData.completedEpisodes'),
      type: CELL_TYPES.COMPONENT,
      render: (row) =>
        t('watchData.iOutOfJ', {
          i: row.episodesCompleted,
          j: row.episodesCount,
        }),
    },
    {
      key: 'firstTimeWatched',
      label: t('watchData.courseStart'),
      type: CELL_TYPES.DATE,
    },
    {
      key: 'completedTime',
      label: t('watchData.courseCompleted'),
      type: CELL_TYPES.DATE,
    },
    {
      key: 'certificationTime',
      label: t('watchData.certificationDate'),
      type: CELL_TYPES.DATE,
    },
  ];

  return (
    <Table
      ariaLabel={t('watchData.activitiesOnYourCourse')}
      columns={columns}
      items={itemsToDisplay}
      defaultOrder={ORDER_TYPES.DESC}
      defaultOrderBy="episodesCompleted"
    />
  );
};

CourseWatchDataTable.propTypes = {
  itemsToDisplay: PropTypes.array.isRequired,
};

export default CourseWatchDataTable;

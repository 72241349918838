import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Table, { CELL_TYPES, ORDER_TYPES } from 'shared/organisms/Table';

const NanodegreeStatsWatchDataTable = ({ itemsToDisplay }) => {
  const { t } = useTranslation();

  const columns = [
    {
      fixedWidth: '76%',
      key: 'name',
      label: t('watchData.organizationName'),
      sticky: true,
      maskValue: true,
    },
    {
      fixedWidth: '9%',
      key: 'started',
      label: t('watchData.started'),
      type: CELL_TYPES.TEXT,
    },
    {
      fixedWidth: '9%',
      key: 'graduated',
      label: t('watchData.graduated'),
      type: CELL_TYPES.TEXT,
    },
    {
      fixedWidth: '6%',
      key: 'averageCompletion',
      label: t('watchData.averageCompletion'),
      render: (row) =>
        t('watchData.percentage', { percentage: row.averageCompletion }),
      type: CELL_TYPES.COMPONENT,
    },
  ];

  return (
    <Table
      ariaLabel={t('watchData.activitiesOnYourNanodegree')}
      columns={columns}
      items={itemsToDisplay}
      defaultOrder={ORDER_TYPES.DESC}
      defaultOrderBy="averageCompletion"
    />
  );
};

NanodegreeStatsWatchDataTable.propTypes = {
  itemsToDisplay: PropTypes.array.isRequired,
};

export default NanodegreeStatsWatchDataTable;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import useMediaQuery from '@mui/material/useMediaQuery';
import Form from 'shared/atoms/Form';
import FormInput from 'shared/atoms/FormFields/FormInput';
import HorizontalRule from 'shared/atoms/HorizontalRule';
import Alert from 'shared/atoms/Alert';
import Button from 'shared/atoms/Button';
import Select from 'shared/atoms/Select';
import T from 'shared/atoms/Typography';
import theme from 'shared/themes/default';
import GroupsOverview from 'shared/organisms/GroupsOverview';
import Snackbar from 'shared/atoms/Snackbar';
import { registeredMembersRoles } from 'shared/const';
import { useForm } from 'shared/utils';

const StyledBlock = styled.div`
  text-align: left;
  width: 25%;
  ${theme.breakpoints.tablet} {
    width: 50%;
  }
  ${theme.breakpoints.mobile} {
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  ${theme.breakpoints.mobile} {
    align-self: flex-start;
    margin-top: 10px;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${theme.breakpoints.mobile} {
    align-items: start;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
`;

const StyledHorizontalRule = styled(HorizontalRule)`
  margin-top: 30px;
`;

const MessageBoxWrapper = styled.div`
  margin-top: 10px;
`;

const ManageOrCreateGroups = ({
  maxNameLength,
  setManageGroupsOpen,
  onSubmitHandler,
  groups,
  loadingGroupsInfo,
  listGroupMembers,
  registeredMembers,
  onSubmitGroupMembers,
  onSaveEditGroup,
  onDeleteGroupMember,
  onDeleteGroup,
  removeFromGroup,
  createGroupFormStatus,
  loadingGroupMembers,
  selectedGroupId,
  setSelectedGroupId,
  groupMembers,
  userId,
  setCreateGroupFormStatus,
  isManager,
  isBackOffice,
  onTransferGroup,
  onViewGroupActivity,
}) => {
  const isDesktopView = useMediaQuery(theme.breakpoints.desktop);
  const [openMessageBox, setOpenMessagebox] = useState(false);
  const [selectedManagerId, setSelectedManagerId] = useState(
    isBackOffice ? 'all' : userId
  );

  const { t } = useTranslation();

  const managerOptions = [];

  if (!isBackOffice) {
    managerOptions.push({
      value: userId,
      key: userId,
      label: t('administrateUsers.myGroups'),
    });
  }

  managerOptions.push({
    value: 'all',
    key: 'all',
    label: t('administrateUsers.allGroups'),
  });

  registeredMembers
    .filter(
      (member) =>
        (isBackOffice || member.id !== userId) &&
        (member.roleId === registeredMembersRoles.admin ||
          member.roleId === registeredMembersRoles.manager)
    )
    .map((manager) => ({
      value: manager.id,
      label: manager.name,
      key: manager.id,
    }))
    .forEach((manager) => managerOptions.push(manager));

  const {
    changeValues,
    disableSubmit,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    properties,
    submitting,
    values,
  } = useForm({
    changesRequired: true,
    fields: {
      name: {
        default: '',
        maxLength: maxNameLength,
        required: true,
      },
      description: {
        default: '',
      },
    },
    onSubmit: async (values) => {
      const newValues = { ...values };
      if (isBackOffice) {
        newValues.groupAdministratorId = selectedManagerId;
      }
      await onSubmitHandler(newValues);
    },
  });

  useEffect(() => {
    if (createGroupFormStatus) {
      setOpenMessagebox(true);

      // hide the message after 10 sec for desktop
      if (isDesktopView) {
        setTimeout(() => {
          setOpenMessagebox(false);
          setCreateGroupFormStatus(null);
        }, 10000);
      }

      if (createGroupFormStatus.success) {
        changeValues({ name: '', description: '' });
      }
    }
  }, [createGroupFormStatus]);

  const snackbarType = createGroupFormStatus?.success ? 'success' : 'error';
  const notificationMessage = createGroupFormStatus?.success
    ? t('administrateUsers.createGroupSuccess')
    : t('administrateUsers.createGroupfailed');

  const isSubmitDisabled =
    disableSubmit || (isBackOffice && selectedManagerId === 'all');
  return (
    <>
      <HeaderWrapper>
        {isBackOffice ? (
          <div>
            <T
              color={theme.palette.lightPure}
              fontWeight="medium"
              sx={{ mt: theme.spacing.m3 }}
              variant="subtitle"
            >
              {t('administrateUsers.groups')}
            </T>
            <T
              color={theme.palette.disabledLight}
              fontWeight="medium"
              sx={{ mb: theme.spacing.m3, mt: theme.spacing.m1 }}
              variant="paragraph"
            >
              {t('administrateUsers.filterGroups')}
            </T>
            <Select
              id="manager-picker"
              name="manager-picker"
              value={selectedManagerId}
              onChange={({ value }) => {
                setSelectedManagerId(value);
              }}
              items={managerOptions}
              theme="dark"
              sx={{ width: '300px' }}
              hasAction
              hasBackground
            />
          </div>
        ) : (
          <div>
            <T
              color={theme.palette.lightPure}
              fontWeight="medium"
              sx={{ mt: theme.spacing.m3 }}
              variant="headingS"
            >
              {t('administrateUsers.createNewGroup')}
            </T>
            <T
              color={theme.palette.disabledLight}
              fontWeight="medium"
              sx={{ mb: theme.spacing.m3, mt: theme.spacing.m1 }}
              variant="paragraph"
            >
              {t('administrateUsers.createNewGroupSubTitle')}
            </T>
          </div>
        )}
        <ButtonWrapper>
          <Button
            ariaLabel={t('administrateUsers.returnToMembers')}
            iconLeft={ChevronLeftRoundedIcon}
            onClick={() => setManageGroupsOpen(false)}
            theme="dark"
            type="secondary"
          >
            {t('administrateUsers.returnToMembers')}
          </Button>
        </ButtonWrapper>
      </HeaderWrapper>
      {isBackOffice && (
        <div>
          <StyledHorizontalRule />
          <T
            color={theme.palette.lightPure}
            fontWeight="medium"
            sx={{ mt: theme.spacing.m3 }}
            variant="headingS"
          >
            {t('administrateUsers.createNewGroup')}
          </T>
          <T
            color={theme.palette.disabledLight}
            fontWeight="medium"
            sx={{ mb: theme.spacing.m3, mt: theme.spacing.m1 }}
            variant="paragraph"
          >
            {t('administrateUsers.createNewGroupSubTitle')}
          </T>
          <T
            color={theme.palette.disabledLight}
            fontWeight="medium"
            sx={{ mb: theme.spacing.m3, mt: theme.spacing.m1 }}
            variant="paragraph"
          >
            {t('administrateUsers.selectManagerToCreateGroup')}
          </T>
        </div>
      )}
      <StyledBlock>
        <Form onSubmit={handleSubmit}>
          <FormInput
            id="name"
            name="name"
            theme="dark"
            label={t('administrateUsers.groupName')}
            placeholder={t('administrateUsers.groupNamePlaceHolder')}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            properties={properties}
            sx={{ mb: theme.spacing.m2 }}
            values={values}
            disabled={selectedManagerId === 'all' ? true : undefined}
          />
          <FormInput
            id="description"
            name="description"
            theme="dark"
            label={t('administrateUsers.description')}
            minRows={4}
            multiline
            placeholder={t('administrateUsers.descriptionPlaceHolder')}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            properties={properties}
            sx={{ mb: theme.spacing.m2 }}
            values={values}
            disabled={selectedManagerId === 'all' ? true : undefined}
          />
          <Button
            ariaLabel="create-group-button"
            disabled={isSubmitDisabled}
            loading={submitting}
            loadingOnIcon={submitting}
            submit
            theme="dark"
            type="primary"
          >
            {t('administrateUsers.createGroup')}
          </Button>
        </Form>
      </StyledBlock>
      {openMessageBox &&
        (isDesktopView ? (
          <MessageBoxWrapper>
            <Alert
              message={notificationMessage}
              severity={snackbarType}
              theme="light"
            />
          </MessageBoxWrapper>
        ) : (
          <Snackbar
            type={snackbarType}
            message={notificationMessage}
            open={openMessageBox}
            onClose={() => {
              setCreateGroupFormStatus(null);
              setOpenMessagebox(false);
            }}
          />
        ))}
      <GroupsOverview
        groups={groups}
        loadingGroupsInfo={loadingGroupsInfo}
        listGroupMembers={listGroupMembers}
        registeredMembers={registeredMembers}
        onSaveEditGroup={onSaveEditGroup}
        onSubmitGroupMembers={onSubmitGroupMembers}
        onDeleteGroupMember={onDeleteGroupMember}
        onDeleteGroup={onDeleteGroup}
        removeFromGroup={removeFromGroup}
        selectedGroupId={selectedGroupId}
        setSelectedGroupId={setSelectedGroupId}
        loadingGroupMembers={loadingGroupMembers}
        groupMembers={groupMembers}
        userId={userId}
        isManager={isManager}
        isBackOffice={isBackOffice}
        groupOwners={registeredMembers.filter(
          (member) =>
            member.roleId === registeredMembersRoles.admin ||
            member.roleId === registeredMembersRoles.manager
        )}
        selectedManagerId={selectedManagerId}
        onTransferGroup={onTransferGroup}
        onViewGroupActivity={onViewGroupActivity}
      />
    </>
  );
};

ManageOrCreateGroups.propTypes = {
  onSubmitHandler: PropTypes.func.isRequired,
  groups: PropTypes.array,
  loadingGroupsInfo: PropTypes.bool,
  setManageGroupsOpen: PropTypes.func.isRequired,
  maxNameLength: PropTypes.number,
  listGroupMembers: PropTypes.func,
  onSaveEditGroup: PropTypes.func.isRequired,
  onSubmitGroupMembers: PropTypes.func.isRequired,
  onDeleteGroupMember: PropTypes.func.isRequired,
  registeredMembers: PropTypes.array,
  onDeleteGroup: PropTypes.func.isRequired,
  removeFromGroup: PropTypes.func.isRequired,
  createGroupFormStatus: PropTypes.object,
  loadingGroupMembers: PropTypes.bool,
  selectedGroupId: PropTypes.string,
  setSelectedGroupId: PropTypes.func.isRequired,
  groupMembers: PropTypes.array,
  userId: PropTypes.string.isRequired,
  setCreateGroupFormStatus: PropTypes.func,
  isManager: PropTypes.bool,
  isBackOffice: PropTypes.bool,
  onTransferGroup: PropTypes.func.isRequired,
  onViewGroupActivity: PropTypes.func.isRequired,
};

ManageOrCreateGroups.defaultProps = {
  maxNameLength: 70,
  isManager: false,
  groupMembers: [],
};

export default ManageOrCreateGroups;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import CircularSpinner from 'shared/atoms/CircularSpinner';
import Button from 'shared/atoms/Button';
import ProgressBar from 'shared/atoms/ProgressBar';
import Tooltip from 'shared/atoms/Tooltip';

import Table, { CELL_TYPES, ORDER_TYPES } from 'shared/organisms/Table';

import { useWatchData } from 'shared/sections/WatchData/Context';
import theme from 'shared/themes/default';

import PlaylistActivityList from './PlaylistActivityList';

const SpinnerWrapper = styled.div`
  padding-top: ${theme.spacing.m2};
`;

export const StyledProgressWrapper = styled.div`
  cursor: pointer;
`;

const PlaylistGroupsWatchDataTableRowItem = ({
  itemId: groupId,
  playlistId,
  handleReminder,
  open,
  showReminder,
}) => {
  const { t } = useTranslation();
  const {
    getUserPlaylistWatchData,
    listPlaylistUsersWatchData,
    setWatchDataError,
    locale,
  } = useWatchData();
  const [watchHistory, setWatchHistory] = useState([]);
  const [nextToken, setNextToken] = useState('');
  const [loading, setLoading] = useState(false);
  // Hover over member's progress
  const [groupMemberId, setGroupMemberId] = useState(null);
  const [groupMemberWatchHistory, setGroupMemberWatchHistory] = useState([]);
  const [loadingGroupMemberWatchHistory, setLoadingGroupMemberWatchHistory] =
    useState(false);

  const fetchGroupsPlaylistWatchHistory = async (groupId, nextToken) => {
    const handleError = (error) => {
      console.error('Error calling listPlaylistUsersWatchData', error);
      setLoading(false);
      setWatchDataError(true);
    };
    try {
      setLoading(true);
      const { data, error } = await listPlaylistUsersWatchData(
        playlistId,
        groupId,
        nextToken
      );
      if (error) return handleError(error);
      const {
        listPlaylistUsersWatchData: { items, nextToken: newNextToken },
      } = data;
      if (!nextToken) {
        setWatchHistory(items);
      } else {
        setWatchHistory([...watchHistory, ...items]);
      }
      if (newNextToken) {
        setNextToken(newNextToken);
      } else {
        setLoading(false);
      }
    } catch (error) {
      handleError(error);
    }
    return true;
  };

  useEffect(() => {
    if (open && watchHistory?.length === 0) {
      setLoading(true);
      fetchGroupsPlaylistWatchHistory(groupId);
    }
  }, [open]);

  useEffect(() => {
    if (nextToken) {
      fetchGroupsPlaylistWatchHistory(groupId, nextToken);
    }
  }, [nextToken]);

  const fetchUsersPlaylistWatchHistory = async (userId, playlistId) => {
    const handleError = (error) => {
      console.error('Error calling getUserPlaylistWatchData', error);
      setGroupMemberWatchHistory([]);
      setLoadingGroupMemberWatchHistory(false);
      setWatchDataError(true);
    };
    try {
      setLoadingGroupMemberWatchHistory(true);
      const { data, error } = await getUserPlaylistWatchData(
        userId,
        playlistId
      );
      if (error) return handleError(error);
      const {
        getUserPlaylistWatchData: { items },
      } = data;
      setGroupMemberWatchHistory(items);
      setLoadingGroupMemberWatchHistory(false);
    } catch (error) {
      handleError(error);
    }
    return true;
  };

  const onHoverProgress = (userId) => {
    if (userId !== groupMemberId) {
      setLoadingGroupMemberWatchHistory(true);
      setGroupMemberId(userId);
    }
  };

  useEffect(() => {
    if (groupMemberId) {
      fetchUsersPlaylistWatchHistory(groupMemberId, playlistId);
    }
  }, [groupMemberId]);

  const columns = [
    {
      key: 'name',
      label: t('watchData.name'),
      expandedSticky: true,
      fixedWidth: '23%',
      maskValue: true,
    },
    {
      key: 'email',
      label: t('watchData.email'),
      fixedWidth: '28%',
      maskValue: true,
    },
    {
      key: 'progress',
      label: t('watchData.progress'),
      type: CELL_TYPES.COMPONENT,
      render: (row) => (
        <Tooltip
          title={
            <PlaylistActivityList
              items={groupMemberWatchHistory}
              loading={loadingGroupMemberWatchHistory}
              locale={locale}
              onHoverProgress={() => onHoverProgress(row.id)}
            />
          }
          placement="top"
          arrow
        >
          <StyledProgressWrapper>
            <ProgressBar progress={row.progress} />
          </StyledProgressWrapper>
        </Tooltip>
      ),
    },
    {
      key: 'lastReminderAt',
      label: t('watchData.lastReminder'),
      type: CELL_TYPES.DATE_TIME,
    },
  ];

  if (showReminder) {
    columns.push({
      key: 'sendReminder',
      label: t('watchData.sendNewReminder'),
      disableSorting: true,
      type: CELL_TYPES.COMPONENT,
      render: (row) => (
        <Button
          ariaLabel={t('watchData.sendReminder')}
          type="text"
          onClick={async () => {
            const reminderStatus = await handleReminder(
              row.id,
              playlistId,
              row.lastReminderAt
            );
            if (reminderStatus && groupId) {
              fetchGroupsPlaylistWatchHistory(groupId);
            }
          }}
          small
        >
          {t('watchData.sendReminder')}
        </Button>
      ),
    });
  }

  return loading ? (
    <SpinnerWrapper>
      <CircularSpinner static />
    </SpinnerWrapper>
  ) : (
    watchHistory.length > 0 && (
      <Table
        ariaLabel="Expandable watch data row inner content"
        columns={columns}
        items={watchHistory}
        defaultOrder={ORDER_TYPES.DESC}
        defaultOrderBy="progress"
        isExpandedType
        isExpandedOpen={open}
      />
    )
  );
};

PlaylistGroupsWatchDataTableRowItem.propTypes = {
  itemId: PropTypes.string.isRequired,
  handleReminder: PropTypes.func.isRequired,
  open: PropTypes.bool,
  playlistId: PropTypes.string.isRequired,
  showReminder: PropTypes.bool,
};

PlaylistGroupsWatchDataTableRowItem.defaultProps = {
  open: false,
  showReminder: false,
};

export default PlaylistGroupsWatchDataTableRowItem;

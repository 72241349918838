export const createExpert = (input) => ({
  name: 'createExpert',
  query: `mutation createExpert(
    $input: CreateExpertInput!
  ) {
    createExpert(input: $input) {
      id
      domain
      firstname
      lastname
      description
      descriptionTranslations
      shortDescription
      shortDescriptionTranslations
      homePage
      isDisabled
      tagline
      socialLinks {
        type
        link
      }
      picture {
        key
        bucket
        region
      }
      pictureProcessingStatus {
        status
      }
      verticalImage {
        key
        bucket
        region
      }
      verticalImageProcessingStatus {
        status
      }
      signature {
        key
        bucket
        region
      }
      signatureProcessingStatus {
        status
      }
    }
  }`,
  variables: {
    input,
  },
});

export const updateExpert = (input) => ({
  name: 'updateExpert',
  query: `mutation updateExpert(
    $input: UpdateExpertInput!
  ) {
    updateExpert(input: $input) {
      id
      domain
      firstname
      lastname
      description
      descriptionTranslations
      shortDescription
      shortDescriptionTranslations
      homePage
      isDisabled
      tagline
      socialLinks {
        type
        link
      }
      picture {
        key
        bucket
        region
      }
      pictureProcessingStatus {
        status
      }
      verticalImage {
        key
        bucket
        region
      }
      verticalImageProcessingStatus {
        status
      }
      signature {
        key
        bucket
        region
      }
      signatureProcessingStatus {
        status
      }
    }
  }`,
  variables: {
    input,
  },
});

export const deleteExpert = (id) => ({
  name: 'deleteExpert',
  query: `mutation DeleteExpert(
    $input: DeleteExpertInput!
  ) {
    deleteExpert(input: $input) {
      id
    }
  }`,
  variables: {
    input: {
      id,
    },
  },
});

import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Button from '@shared/atoms/Button';
import FileUpload from '@shared/molecules/FileUpload';
import T from '@shared/atoms/Typography';
import Tooltip from '@shared/atoms/Tooltip';

import { selectTranslation, logger } from '@shared/utils';
import theme from '@shared/themes/default';

import { courseStatus, DOMAIN_LOCALES, PROCESSING_STATUS } from '@shared/const';

import { useAppGlobals } from '../../../../contexts/AppContext';
import { TABS, useCourse } from '../../../../contexts/CourseContext';
import { getStaticImagesHostname } from '../../../../../config';

import SearchableList from './components/SearchableList';
import ValidationList from '../../components/ValidationList';
import { listCategories, listExperts } from './graphql/queries';
import { graphql } from '../../../../../utils';

const SectionStyled = styled.section`
  background: ${theme.palette.darkWrapper};
  padding: ${theme.spacing.s};
`;

const HeaderStyled = styled.header`
  display: flex;
  justify-content: flex-end;
  padding: ${theme.spacing.s};
`;

const CatalogWrapper = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 418px;
  row-gap: ${theme.spacing.m2};
`;

const Catalog = () => {
  const { t } = useTranslation();
  const { domain, locale } = useAppGlobals();
  const languages = DOMAIN_LOCALES[domain];

  const {
    completeUpload,
    course: originalCourse,
    getUploadUrl,
    hasChanged,
    locked,
    saveCatalog,
    setHandleSave,
    setHasChanged,
    setHasError,
    setSubmitting,
    setUploadingFiles,
    startUpload,
    submitting,
    uploadingFiles,
    validateCourse,
  } = useCourse();

  const [categories, setCategories] = useState([]);
  const [categoriesError, setCategoriesError] = useState(false);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [course, setCourse] = useState(originalCourse || {});
  const [thumbnail, setThumbnail] = useState();
  const [promopicture, setPromopicture] = useState();
  const [experts, setExperts] = useState([]);
  const [expertsError, setExpertsError] = useState(false);
  const [expertsLoading, setExpertsLoading] = useState(false);
  const [canPublish, setCanPublish] = useState(false);
  const [tabValidation, setTabValidation] = useState({});

  const isPublished = course?.status === courseStatus.PUBLISHED;

  useEffect(() => {
    setCourse(originalCourse);
  }, [originalCourse]);

  const fetchCategories = async (nextToken) => {
    setCategoriesLoading(true);
    try {
      const {
        error,
        data: {
          listCategories: { items, nextToken: newNextToken },
        },
      } = await graphql(listCategories({ domain, nextToken }));
      if (error) throw new Error(error);
      setCategories([...categories, ...items]);
      if (newNextToken) fetchCategories(newNextToken);
    } catch (error) {
      logger.error('Error occured while fetching categories', error);
      setCategoriesError(true);
    }
    setCategoriesLoading(false);
  };

  const fetchExperts = async (nextToken) => {
    setExpertsLoading(true);
    try {
      const {
        error,
        data: {
          listExperts: { items, nextToken: newNextToken },
        },
      } = await graphql(listExperts({ domain, nextToken }));
      if (error) throw new Error(error);
      setExperts([...experts, ...items.filter((expert) => !expert.isDisabled)]);
      if (newNextToken) fetchExperts(newNextToken);
    } catch (error) {
      logger.error('Error occured while fetching experts', error);
      setExpertsError(true);
    }
    setExpertsLoading(false);
  };

  const handleSave = async () => {
    try {
      await saveCatalog(course);
    } catch (e) {
      logger.error(e);
      setHasError(true);
      setSubmitting(false);
    }
  };

  const isValidCatalog = () => {
    if (!course.ownerOrganization && !(course.experts?.items?.length > 0)) {
      return false;
    }

    if (course.experts?.items?.length > 1 && !course.promopicture) {
      return false;
    }

    if (!course.thumbnail) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const newCanPublish = isValidCatalog();
    setCanPublish(newCanPublish);

    if (course?.status === courseStatus.PUBLISHED) {
      const validationStatus = validateCourse(course, languages);
      // Check the catalog validation and include the images that are ready for processing
      let processedCatalog = {};
      if (validationStatus[TABS.CATALOG]) {
        const { promo, thumbnail, ...status } = validationStatus[TABS.CATALOG];
        delete validationStatus[TABS.CATALOG];
        processedCatalog = { ...status };
        if (promo && !course.promopictureProcessingStatus) {
          processedCatalog.promo = true;
        }
        if (thumbnail && !course.thumbnailProcessingStatus) {
          processedCatalog.thumbnail = true;
        }
      }
      setTabValidation({
        ...validationStatus,
        ...(Object.keys(processedCatalog).length
          ? { [TABS.CATALOG]: processedCatalog }
          : {}),
      });
    }

    const trimCourse = ({
      id,
      promopicture,
      promopictureProcessingStatus,
      thumbnail,
      thumbnailProcessingStatus,
    }) => ({
      id,
      promopicture,
      promopictureProcessingStatus,
      thumbnail,
      thumbnailProcessingStatus,
    });
    const originalCourseParsed = JSON.stringify(trimCourse(originalCourse));
    const courseParsed = JSON.stringify(trimCourse(course));

    const sameCategories =
      originalCourse.categories.items.length ===
        course.categories.items.length &&
      originalCourse.categories.items.reduce(
        (prev, originalCategory) =>
          prev &&
          course.categories.items.find(
            (category) => category?.id === originalCategory?.id
          ),
        true
      );
    const sameExperts =
      originalCourse.experts.items.length === course.experts.items.length &&
      originalCourse.experts.items.reduce(
        (prev, originalExpert) =>
          prev &&
          course.experts.items.find(
            (expert) => expert.id === originalExpert.id
          ),
        true
      );

    if (
      !hasChanged &&
      (originalCourseParsed !== courseParsed ||
        !sameCategories ||
        !sameExperts) &&
      (!isPublished || newCanPublish)
    )
      setHasChanged(true);
    if (
      (hasChanged &&
        originalCourseParsed === courseParsed &&
        sameExperts &&
        sameCategories) ||
      (isPublished && !newCanPublish)
    )
      setHasChanged(false);
    setHandleSave(() => handleSave);
  }, [course]);

  useEffect(() => {
    fetchCategories();
    fetchExperts();
    setHandleSave(() => handleSave);
  }, []);

  const handleAddCategory = (categoryId) => {
    const { id, titleTranslations } = categories.find(
      (category) => category.id === categoryId
    );
    const newCourseCategories = [
      ...course.categories.items,
      { id, titleTranslations },
    ];

    setCourse({ ...course, ...{ categories: { items: newCourseCategories } } });
  };
  const handleAddExpert = (expertId) => {
    const { id, firstname, lastname } = experts.find(
      (expert) => expert.id === expertId
    );
    const newCourseExperts = [
      ...course.experts.items,
      { id, firstname, lastname },
    ];

    setCourse({ ...course, ...{ experts: { items: newCourseExperts } } });
  };
  const handleDeleteCategory = (categoryId) => {
    const categoryIndex = course.categories.items.findIndex(
      (category) => category.id === categoryId
    );
    const newCourseCategories = [
      ...course.categories.items.slice(0, categoryIndex),
      ...course.categories.items.slice(categoryIndex + 1),
    ];

    setCourse({ ...course, ...{ categories: { items: newCourseCategories } } });
  };
  const handleDeleteExpert = (expertId) => {
    const expertIndex = course.experts.items.findIndex(
      (expert) => expert.id === expertId
    );
    const newCourseExperts = [
      ...course.experts.items.slice(0, expertIndex),
      ...course.experts.items.slice(expertIndex + 1),
    ];

    setCourse({ ...course, ...{ experts: { items: newCourseExperts } } });
  };

  const handleAddThumbnail = (file) => {
    setThumbnail(file.base64);
    setCourse({
      ...course,
      thumbnail: {
        base64: file.base64,
      },
      thumbnailProcessingStatus: {
        file: file.s3File,
        status: PROCESSING_STATUS.TO_SCHEDULE,
      },
    });
  };
  const handleDeleteThumbnail = () => {
    setThumbnail(null);
    setCourse({
      ...course,
      thumbnail: null,
      thumbnailProcessingStatus: null,
    });
  };
  const handleAddPromo = (file) => {
    setPromopicture(file.base64);
    setCourse({
      ...course,
      promopicture: {
        base64: file.base64,
      },
      promopictureProcessingStatus: {
        file: file.s3File,
        status: PROCESSING_STATUS.TO_SCHEDULE,
      },
    });
  };
  const handleDeletePromo = () => {
    setPromopicture(null);
    setCourse({
      ...course,
      promopicture: null,
      promopictureProcessingStatus: null,
    });
  };

  const onStartUpload = () => {
    if (!hasChanged) setHasChanged(true);
    setUploadingFiles(true);
  };

  const saveDisabled =
    !hasChanged ||
    submitting ||
    uploadingFiles ||
    (isPublished && (locked || !canPublish));

  return (
    <SectionStyled>
      <HeaderStyled>
        <Tooltip
          arrow
          title={
            course.status === courseStatus.PUBLISHED &&
            saveDisabled &&
            !locked &&
            !submitting &&
            Object.keys(tabValidation)?.length ? (
              <>
                <T color={theme.palette.mainDark}>
                  {t('backoffice.courses.publishing.save')}
                </T>
                <ValidationList details={tabValidation} />
              </>
            ) : (
              ''
            )
          }
        >
          <div>
            <Button
              disabled={saveDisabled}
              loading={submitting}
              onClick={handleSave}
              theme="dark"
              type="secondary"
            >
              {t('generic.save')}
            </Button>
          </div>
        </Tooltip>
      </HeaderStyled>
      <CatalogWrapper>
        <SearchableList
          disabled={
            (isPublished && locked) ||
            submitting ||
            categoriesLoading ||
            categoriesError
          }
          emptyText={t('backoffice.courses.catalogTab.categoriesEmpty')}
          error={
            (categoriesError &&
              t('backoffice.courses.catalogTab.errorCategories')) ||
            ''
          }
          loading={categoriesLoading}
          loadingText={t('backoffice.courses.catalogTab.categoriesLoading')}
          onAdd={handleAddCategory}
          onDelete={handleDeleteCategory}
          options={course.categories.items.filter(Boolean).map((category) => ({
            key: category.id,
            title: selectTranslation(category.titleTranslations, '', locale),
          }))}
          searchOptions={categories.filter(Boolean).map((category) => ({
            key: category.id,
            title: selectTranslation(category.titleTranslations, '', locale),
          }))}
          title={t('backoffice.courses.catalogTab.categories')}
        />
        <SearchableList
          disabled={
            (isPublished && locked) ||
            submitting ||
            expertsLoading ||
            expertsError
          }
          emptyText={t('backoffice.courses.catalogTab.expertsEmpty')}
          error={
            (expertsError && t('backoffice.courses.catalogTab.errorExperts')) ||
            ''
          }
          limit={2}
          loading={expertsLoading}
          loadingText={t('backoffice.courses.catalogTab.expertsLoading')}
          onAdd={handleAddExpert}
          onDelete={handleDeleteExpert}
          options={course.experts.items.map((expert) => ({
            key: expert.id,
            title: `${expert.firstname} ${expert.lastname}`,
          }))}
          searchInfoText={t('backoffice.courses.catalogTab.expertsInfo')}
          searchOptions={experts.map((expert) => ({
            key: expert.id,
            title: `${expert.firstname} ${expert.lastname}`,
          }))}
          title={t('backoffice.courses.catalogTab.experts')}
        />
        <FileUpload
          completeUpload={completeUpload}
          descriptionText={[t('backoffice.courses.catalogTab.image')]}
          disabled={(isPublished && locked) || submitting}
          getUploadUrl={getUploadUrl}
          graphql={graphql}
          handleAdd={handleAddThumbnail}
          handleDelete={handleDeleteThumbnail}
          id={course.id}
          imgUrl={
            (course.thumbnail &&
              (course.thumbnail.base64 ||
                `${getStaticImagesHostname()}/${course.thumbnail.key}`)) ||
            thumbnail
          }
          infoText={t('backoffice.courses.catalogTab.thumbnailInfo')}
          onCompleteUpload={() => setUploadingFiles(false)}
          onStartUpload={onStartUpload}
          processingStatus={course.thumbnailProcessingStatus?.status}
          startUpload={startUpload}
          isRequired
          title={t('backoffice.courses.catalogTab.thumbnail')}
        />
        <FileUpload
          completeUpload={completeUpload}
          descriptionText={[t('backoffice.courses.catalogTab.image')]}
          disabled={(isPublished && locked) || submitting}
          getUploadUrl={getUploadUrl}
          graphql={graphql}
          handleAdd={handleAddPromo}
          handleDelete={handleDeletePromo}
          id={course.id}
          imgUrl={
            (course.promopicture &&
              (course.promopicture.base64 ||
                `${getStaticImagesHostname()}/${course.promopicture.key}`)) ||
            promopicture
          }
          onCompleteUpload={() => setUploadingFiles(false)}
          onStartUpload={onStartUpload}
          infoText={t('backoffice.courses.catalogTab.promoInfo')}
          processingStatus={course.promopictureProcessingStatus?.status}
          startUpload={startUpload}
          isRequired
          title={t('backoffice.courses.catalogTab.promo')}
        />
      </CatalogWrapper>
    </SectionStyled>
  );
};

export default Catalog;

import React from 'react';
import PropTypes from 'prop-types';
import MuiTableCell from '@mui/material/TableCell';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import theme from 'shared/themes/default';

const getBackgroundColor = (transparentHead, expandedSticky) => {
  if (transparentHead) return 'none';
  if (expandedSticky) return theme.palette.evenDark;
  return theme.palette.pureDark;
};

const getBoxShadow = (isMobile, sticky, stickySecondColumn, expandedSticky) => {
  if (!isMobile) return 'none';
  if (sticky || stickySecondColumn || expandedSticky)
    return `inset -10px 0 5px -7px ${theme.palette.mainDark}`;
  return 'none';
};

const getWidth = (expandedSticky, fixedWidth, sticky, isMobile) => {
  if ((expandedSticky || sticky) && isMobile) return '45vw';
  if (fixedWidth) return fixedWidth;
  return 'auto';
};

const useStyles = makeStyles(() => ({
  head: {
    backgroundColor: (props) =>
      getBackgroundColor(props.transparentHead, props.expandedSticky),
    color: `${theme.palette.lightPure}`,
    borderBottom: theme.palette.pureDark,
    fontWeight: '600 !important',
    position: (props) => (props.sticky || props.expandedSticky ? 'sticky' : ''),
    left: (props) => (props.stickySecondColumn ? '50px' : '0px'),
    boxShadow: (props) =>
      props.stickySecondColumn
        ? `inset -10px 0 5px -7px ${theme.palette.mainDark}`
        : '',
  },
  body: {
    backgroundColor: 'inherit',
    margin: '0',
    padding: (props) => (props.fullExpandedCell ? '0' : '1em 1em'),
    borderBottom: 'none',
    color: (props) =>
      props.selected ? `${theme.palette.brand}` : `${theme.palette.lightPure}`,
    left: (props) => (props.stickySecondColumn ? '50px' : '0px'),
    position: (props) => (props.sticky || props.expandedSticky ? 'sticky' : ''),
    boxShadow: ({ isMobile, sticky, stickySecondColumn, expandedSticky }) =>
      getBoxShadow(isMobile, sticky, stickySecondColumn, expandedSticky),
    width: ({ expandedSticky, fixedWidth, sticky, isMobile }) =>
      getWidth(expandedSticky, fixedWidth, sticky, isMobile),
    maxWidth: (props) => (props.fullExpandedCell ? '100vW' : 'none'), // Expanded rows are contained in one TableCell
  },
}));

const TableCell = ({
  children,
  fullExpandedCell,
  expandedSticky,
  fixedWidth,
  selected,
  sticky,
  stickySecondColumn,
  transparentHead,
  ...props
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.mobile);
  const classes = useStyles({
    fullExpandedCell,
    expandedSticky,
    fixedWidth,
    isMobile,
    selected,
    sticky,
    stickySecondColumn,
    transparentHead,
  });
  return (
    <MuiTableCell {...props} classes={classes}>
      {children}
    </MuiTableCell>
  );
};

TableCell.propTypes = {
  children: PropTypes.node,
  fullExpandedCell: PropTypes.bool,
  expandedSticky: PropTypes.bool,
  fixedWidth: PropTypes.string,
  selected: PropTypes.bool,
  sticky: PropTypes.bool,
  stickySecondColumn: PropTypes.bool,
  transparentHead: PropTypes.bool,
};

TableCell.defaultProps = {
  children: null,
  fullExpandedCell: false,
  expandedSticky: false,
  selected: false,
  sticky: false,
  stickySecondColumn: false,
  transparentHead: false,
};

export default TableCell;

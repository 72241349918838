export const parseCsvContent = (csvContent, t) => {
  let quizQuestions = [];
  let error = null;

  try {
    const lines = csvContent
      .split('\n')
      .map((line) => line.trim())
      .filter((line) => line);
    if (lines.length > 0) {
      quizQuestions = lines.map((line) => {
        const splitedLine = line.split(/[\t]+/);
        if (splitedLine.length === 6) {
          const correctAnswer = Number(splitedLine[splitedLine.length - 1]);

          if (
            Number.isNaN(correctAnswer) ||
            correctAnswer < 0 ||
            correctAnswer > 3
          ) {
            throw new Error(t('quizzes.correctAnswerNotAsNumber'));
          }

          return {
            text: splitedLine[1],
            options: splitedLine.slice(2, -1).map((option, index) => {
              return {
                answer: option,
                correct: index + 1 === correctAnswer,
              };
            }),
          };
        }
        throw new Error();
      });
    }
  } catch (e) {
    quizQuestions = [];
    error = e.message || t ? t('quizzes.malformedPasted') : e;
  }

  return { quizQuestions, error };
};

import React from 'react';
import PropTypes from 'prop-types';

import useApp from './useApp';

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const { domain, loadingSettings, loadingUser, locale, user } = useApp();

  const contextValue = {
    domain,
    loadingSettings,
    loadingUser,
    locale,
    user,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useAppGlobals = () => {
  const context = React.useContext(AppContext);
  if (!context) {
    throw new Error('useAppGlobals must be used within a AppProvider');
  }
  return context;
};

export { AppProvider, useAppGlobals };

import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import Autocomplete from '@shared/atoms/Autocomplete';
import Chip from '@shared/atoms/Chip';

import theme from '@shared/themes/default';
import { DOMAIN } from '@shared/const';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.m1};
`;

const AutocompleteWrapper = styled.div`
  width: 232px;
`;

const ChipWrapper = styled.div`
  display: inline-flex;
  column-gap: ${theme.spacing.s};
  padding-left: ${theme.spacing.s};
`;

const DomainsTab = ({ domain: currentDomain, domains, onChange }) => {
  const { t } = useTranslation();

  const [domainsParsed, setDomainsParsed] = useState([]);

  useEffect(() => {
    setDomainsParsed(
      DOMAIN.filter((domain) => !domains.includes(domain)).map((domain) => ({
        label: domain,
        value: domain,
      }))
    );
  }, [domains]);

  const handleChange = ({ value }) => {
    onChange({ name: 'domain', value: [...domains, value] });
  };

  const handleDelete = (value) => {
    onChange({
      name: 'domain',
      value: domains.filter((domain) => domain !== value),
    });
  };

  return (
    <Wrapper>
      <AutocompleteWrapper>
        <Autocomplete
          clearOnSelect
          filterOptions
          onChange={handleChange}
          options={domainsParsed}
          placeholder={t('backoffice.experts.domains')}
          theme="dark"
        />
      </AutocompleteWrapper>
      <ChipWrapper>
        {domains.map((domain) => (
          <Chip
            icon={currentDomain === domain ? <LockOutlinedIcon /> : undefined}
            key={domain}
            label={domain}
            onDelete={
              currentDomain !== domain ? () => handleDelete(domain) : undefined
            }
            theme="dark"
            type="secondary"
          />
        ))}
      </ChipWrapper>
    </Wrapper>
  );
};

DomainsTab.propTypes = {
  domain: PropTypes.oneOf(DOMAIN).isRequired,
  domains: PropTypes.arrayOf(PropTypes.oneOf(DOMAIN)),
  onChange: PropTypes.func.isRequired,
};

DomainsTab.defaultProps = {
  domains: [],
};

export default DomainsTab;

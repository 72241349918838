import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import FormInput from 'shared/atoms/FormFields/FormInput';

export const TabContentWrapper = styled.div`
  padding: 0.7rem 1rem;
  display: flex;
  flex-direction: column;
`;

const AddQuestionsCsv = ({
  disabled,
  pastedCsvContent,
  setPastedCsvContent,
  setQuizQuestions,
  theme: themeLayout,
}) => {
  const { t } = useTranslation();
  return (
    <TabContentWrapper>
      <FormInput
        disabled={disabled}
        id="pasteFromFile"
        name="pasteFromFile"
        theme={themeLayout}
        label={t('quizzes.pasteFromFile')}
        placeholder={t('quizzes.columnsInExcel')}
        value={pastedCsvContent}
        onChange={({ target: { value } }) => {
          if (!value) {
            setQuizQuestions([]);
          }

          setPastedCsvContent(value);
        }}
        minRows={12}
        maxRows={24}
        multiline
        fullWidth
        required
      />
    </TabContentWrapper>
  );
};

AddQuestionsCsv.propTypes = {
  disabled: PropTypes.bool,
  pastedCsvContent: PropTypes.string.isRequired,
  setPastedCsvContent: PropTypes.func.isRequired,
  setQuizQuestions: PropTypes.func.isRequired,
  theme: PropTypes.string,
};

AddQuestionsCsv.defaultProps = {
  theme: 'light',
};

export default AddQuestionsCsv;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Button from '@shared/atoms/Button';
import Dialog from '@shared/molecules/Dialog';
import LanguageSelect from '@shared/molecules/LanguageSelect';
import Select from '@shared/atoms/Select';
import T from '@shared/atoms/Typography';
import Tooltip from '@shared/atoms/Tooltip';
import VerticalTabs from '@shared/atoms/VerticalTabs';

import theme from '@shared/themes/default';

import {
  COURSE_LEVELS,
  courseStatus,
  DOMAIN_LOCALES,
  LANGUAGE_NAMES,
  TAB_STATUS,
} from '@shared/const';
import { logger, selectTranslation } from '@shared/utils';

import { useAppGlobals } from '../../../../contexts/AppContext';
import { TABS, useCourse } from '../../../../contexts/CourseContext';

import DescriptionDetails from './components/DescriptionDetails';
import ValidationList from '../../components/ValidationList';

const SectionStyled = styled.section`
  background: ${theme.palette.darkWrapper};
  padding: ${theme.spacing.s};
`;

const HeaderStyled = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: ${theme.spacing.s};
`;

const LanguageWrapper = styled.div`
  align-items: center;
  display: flex;
  column-gap: ${theme.spacing.m1};
`;

const WrapperSelect = styled.div`
  display: flex;
  column-gap: ${theme.spacing.xl};
`;

const COURSE_LEVEL_NOT_SET = 'levelNoSet';

const Description = () => {
  const { t } = useTranslation();
  const { domain } = useAppGlobals();
  const languages = DOMAIN_LOCALES[domain];

  const {
    course: originalCourse,
    getFormattedTranslations,
    getLanguages,
    getLanguageStatus,
    hasChanged,
    locked,
    saveDescription,
    setHandleSave,
    setHasChanged,
    setHasError,
    setSubmitting,
    submitting,
    validateCourse,
  } = useCourse();

  const filterUnusedLanguages = (field, enabledLanguages, optional) => {
    if (!field && optional) return null;
    const currentValue = JSON.parse(field || '{}');

    const filteredValue = Object.keys(currentValue).reduce((acc, lang) => {
      if (enabledLanguages[lang]) {
        if (Array.isArray(currentValue[lang])) {
          acc[lang] = currentValue[lang]
            .map((value) => value.trim())
            .filter((value) => value);
        } else {
          acc[lang] = currentValue[lang].trim();
        }
      }

      return acc;
    }, {});

    return JSON.stringify(filteredValue);
  };

  const trimCourse = (
    {
      id,
      nameTranslations,
      shortDescriptionTranslations,
      seoDescriptionTranslations,
      keyLearningPointsTranslations,
      descriptionTranslations,
      language,
      level,
      prerequisitesTranslations,
      typicalLearnerTranslations,
    },
    enabledLanguages
  ) => ({
    id,
    nameTranslations: filterUnusedLanguages(nameTranslations, enabledLanguages),
    shortDescriptionTranslations: filterUnusedLanguages(
      shortDescriptionTranslations,
      enabledLanguages
    ),
    seoDescriptionTranslations: filterUnusedLanguages(
      seoDescriptionTranslations,
      enabledLanguages
    ),
    keyLearningPointsTranslations: filterUnusedLanguages(
      keyLearningPointsTranslations,
      enabledLanguages
    ),
    descriptionTranslations: filterUnusedLanguages(
      descriptionTranslations,
      enabledLanguages
    ),
    prerequisitesTranslations: filterUnusedLanguages(
      prerequisitesTranslations,
      enabledLanguages,
      true
    ),
    typicalLearnerTranslations: filterUnusedLanguages(
      typicalLearnerTranslations,
      enabledLanguages,
      true
    ),
    language,
    level,
  });

  const [canPublish, setCanPublish] = useState(false);
  const [course, setCourse] = useState(originalCourse || {});
  const [enabledLanguages, setEnabledLanguages] = useState({});
  const [openLevelDialog, setOpenLevelDialog] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);
  const [tabValidation, setTabValidation] = useState({});
  const [valueChangeLevel, setValueChangeLevel] = useState();

  const languageNamesReversed = Object.keys(LANGUAGE_NAMES).reduce(
    (acc, lang) => ({ ...acc, [LANGUAGE_NAMES[lang]]: lang }),
    {}
  );

  const isPublished = course?.status === courseStatus.PUBLISHED;

  const handleSave = async () => {
    try {
      await saveDescription(trimCourse(course, enabledLanguages));
      setHasChanged(false);
    } catch (e) {
      logger.error(e);
      setHasError(true);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const validationStatus = validateCourse(course, languages);
    setTabValidation(validationStatus);
    const newCanPublish = Object.keys(validationStatus).length === 0;
    setCanPublish(newCanPublish);

    const originalCourseParsed = JSON.stringify(originalCourse);
    const courseParsed = JSON.stringify(course);

    if (
      !hasChanged &&
      originalCourseParsed !== courseParsed &&
      (!isPublished || newCanPublish)
    ) {
      setHasChanged(true);
    }
    if (
      (hasChanged && originalCourseParsed === courseParsed) ||
      (isPublished && !newCanPublish)
    ) {
      setHasChanged(false);
    }
    setHandleSave(() => handleSave);
  }, [course]);

  useEffect(() => {
    if (originalCourse) {
      setCourse(originalCourse);
    }
  }, [originalCourse]);

  useEffect(() => {
    setEnabledLanguages(getLanguages());
  }, []);

  const details = () => (
    <DescriptionDetails
      course={course}
      enabledLanguages={enabledLanguages}
      selectedLanguage={selectedLanguage}
      setCourse={setCourse}
      setEnabledLanguages={setEnabledLanguages}
    />
  );

  const verticalTabs = languages.reduce((prev, language) => {
    const languageStatus = getLanguageStatus(language, course);

    if (isPublished && languageStatus === TAB_STATUS.DISABLED) {
      return prev;
    }

    return [
      ...prev,
      {
        label: language,
        component: details(),
        status: languageStatus,
      },
    ];
  }, []);

  const descriptionValidation = tabValidation?.[TABS.DESCRIPTION] || {};

  const prerequisitesFormatted = getFormattedTranslations(
    course.prerequisitesTranslations
  );
  const typicalLearnerFormatted = getFormattedTranslations(
    course.typicalLearnerTranslations
  );

  const [emptyTranslations, errorTranslations] = Object.keys(
    descriptionValidation
  ).reduce(
    ([accEmpty, accError], key) => {
      const hasError = !!Object.keys(descriptionValidation[key]).find(
        (value) =>
          ['prerequisitesTranslations', 'typicalLearnerTranslations'].includes(
            value
          ) && descriptionValidation[key][value] === true
      );

      const prerequisites = selectTranslation(
        course.prerequisitesTranslations,
        '',
        key,
        ''
      );
      const typicalLearner = selectTranslation(
        course.typicalLearnerTranslations,
        '',
        key,
        ''
      );

      const isEmpty =
        hasError &&
        ((key in prerequisitesFormatted && !prerequisites) ||
          (key in typicalLearnerFormatted && !typicalLearner));

      return [accEmpty || isEmpty, accError || hasError];
    },
    [false, false]
  );

  const detailsSave = () => {
    return {
      [TABS.DESCRIPTION]: Object.keys(
        tabValidation?.[TABS.DESCRIPTION] || {}
      ).reduce((acc, key) => {
        const detailsLanguage = tabValidation[TABS.DESCRIPTION][key];
        const { prerequisitesTranslations, typicalLearnerTranslations } =
          detailsLanguage;
        return {
          ...acc,
          ...(prerequisitesTranslations || typicalLearnerTranslations
            ? {
                [key]: {
                  ...(prerequisitesTranslations
                    ? { prerequisitesTranslations }
                    : {}),
                  ...(typicalLearnerTranslations
                    ? { typicalLearnerTranslations }
                    : {}),
                },
              }
            : {}),
        };
      }, {}),
    };
  };

  const closeLevelChange = () => {
    setOpenLevelDialog(false);
    setValueChangeLevel();
  };

  const confirmLevelChange = (value = valueChangeLevel) => {
    setCourse({
      ...course,
      level: value,
      typicalLearnerTranslations: null,
      prerequisitesTranslations: null,
    });
    closeLevelChange();
  };

  const handleLevelChange = ({ value }) => {
    if (course.typicalLearnerTranslations || course.prerequisitesTranslations) {
      setValueChangeLevel(value);
      setOpenLevelDialog(true);
    } else {
      confirmLevelChange(value);
    }
  };

  const saveDisabled =
    !hasChanged ||
    submitting ||
    (isPublished && (locked || !canPublish)) ||
    (isPublished && errorTranslations) ||
    (!isPublished && emptyTranslations);

  let tooltipTitle = '';
  let tooltipDetails = detailsSave();

  if (!isPublished && emptyTranslations) {
    tooltipTitle = t('backoffice.courses.saving.save');
  }

  if (
    course.status === courseStatus.PUBLISHED &&
    saveDisabled &&
    !submitting &&
    !locked &&
    Object.keys(tabValidation)?.length
  ) {
    tooltipTitle = t('backoffice.courses.publishing.save');
    tooltipDetails = tabValidation;
  }

  let courseLevels = Object.values(COURSE_LEVELS).map((level) => ({
    value: level,
    key: level,
    label: t(`course.levels.${level}.title`),
  }));

  if (!course.level) {
    courseLevels = [
      {
        value: COURSE_LEVEL_NOT_SET,
        key: COURSE_LEVEL_NOT_SET,
        label: t(`backoffice.courses.descriptionTab.${COURSE_LEVEL_NOT_SET}`),
      },
      ...courseLevels,
    ];
  }

  return (
    <>
      <Dialog
        actions={[
          {
            ariaLabel: t('generic.cancel'),
            handler: closeLevelChange,
            label: t('generic.cancel'),
            type: 'secondaryOutline',
          },
          {
            ariaLabel: t(
              'backoffice.courses.descriptionTab.changeLevel.changeLevel'
            ),
            handler: () => confirmLevelChange(),
            label: t(
              'backoffice.courses.descriptionTab.changeLevel.changeLevel'
            ),
          },
        ]}
        ariaLabel="level dialog"
        open={openLevelDialog}
        onClose={closeLevelChange}
        title={t('backoffice.courses.descriptionTab.changeLevel.title')}
      >
        <T color={theme.palette.pureDark} variant="paragraph">
          {t(`backoffice.courses.descriptionTab.changeLevel.text`)}
        </T>
      </Dialog>
      <SectionStyled>
        <HeaderStyled>
          <WrapperSelect>
            <LanguageWrapper>
              <T
                color={theme.palette.lightPure}
                fontWeight="semibold"
                variant="paragraph"
              >
                {t('backoffice.courses.descriptionTab.courseLanguage')}
              </T>
              <LanguageSelect
                disabled={(isPublished && locked) || submitting}
                languages={languages}
                onChange={({ value }) =>
                  setCourse({ ...course, language: LANGUAGE_NAMES[value] })
                }
                required
                theme="dark"
                value={languageNamesReversed[course.language]}
              />
            </LanguageWrapper>
            <LanguageWrapper>
              <T
                color={theme.palette.lightPure}
                fontWeight="semibold"
                variant="paragraph"
              >
                {t('backoffice.courses.descriptionTab.levelLabel')}
              </T>
              <Select
                id="level"
                disabled={(isPublished && locked) || submitting}
                items={courseLevels}
                name="level"
                onChange={handleLevelChange}
                theme="dark"
                value={course.level || COURSE_LEVEL_NOT_SET}
              />
            </LanguageWrapper>
          </WrapperSelect>
          <Tooltip
            arrow
            title={
              tooltipTitle ? (
                <>
                  <T color={theme.palette.mainDark}>{tooltipTitle}</T>
                  <ValidationList details={tooltipDetails} />
                </>
              ) : (
                ''
              )
            }
          >
            <div>
              <Button
                ariaLabel={t('generic.save')}
                disabled={saveDisabled}
                loading={submitting}
                onClick={handleSave}
                type="secondary"
              >
                {t('generic.save')}
              </Button>
            </div>
          </Tooltip>
        </HeaderStyled>
        <nav>
          <VerticalTabs
            tabsToSelect={verticalTabs}
            setSelectedTab={(language) => setSelectedLanguage(language)}
          />
        </nav>
      </SectionStyled>
    </>
  );
};

export default Description;

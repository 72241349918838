export const getCognitoUser = (userSub) => ({
  query: `query getCognitoUser($userSub: ID!) {
    getCognitoUser(userSub: $userSub) {
      name
      email
      userSub
      enabled
      status
    }
  }`,
  variables: {
    userSub,
  },
});

export const listUsers = (search, domain) => ({
  query: `query listUsers(
    $search: String
    $domain: String
    $limit: Int
    $nextToken: String
  ) {
    listUsers(
      search: $search
      domain: $domain
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        name
        email
        userSub
        cognitoUsername
        status
        invitation {
          name
          email
          invitedDate
          role
          status
          organization {
            name
          }
          invitedBy {
            name
          }
        }
      }
    }
  }`,
  variables: {
    search,
    domain,
  },
});

export const listUserMemberships = (userSub) => ({
  query: `query listUserMemberships(
    $userSub: ID!,
    $limit: Int
    $nextToken: String
  ) {
    listUserMemberships(userSub: $userSub, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        email
        id
        grantedBy
        invitedAt
        name
        organizationId
        roleId
        organization {
          name
        }
      }
      nextToken
    }
  }`,
  variables: {
    userSub,
  },
});

export const listUserSubscriptions = (userSub) => ({
  query: `query listUserSubscriptions(
    $userSub: ID!,
    $limit: Int
    $nextToken: String
  ) {
    listUserSubscriptions(userSub: $userSub, limit: $limit, nextToken: $nextToken) {
      items {
        planId
        validFrom
        expireDate
        organizationId
        subscriptionPlan {
          name
        }
        organization {
          name
        }
      }
      nextToken
    }
  }`,
  variables: {
    userSub,
  },
});

export const listUserInvitations = (email) => ({
  query: `query listUserInvitations(
    $email: String!
  ) {
    listUserInvitations(email: $email) {
      items {
        name
        email
        role
        status
        invitedDate
        organization {
          name
          id
        }
        invitedBy {
          userSub
          name
          email
        }
      }
    }
  }`,
  variables: {
    email,
  },
});

export const listOrganizationsNames = (domain) => ({
  query: `query listOrganizations(
    $domain: String!
    $nextToken: String
  ) {
    listOrganizations (domain: $domain, limit: 1000, nextToken: $nextToken) {
      items {
        id
        domain
        name
      }
      nextToken
    }
  }`,
  variables: {
    domain,
  },
});

import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from 'shared/themes/default';

const HorizontalRule = styled.hr.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    prop !== 'color' && defaultValidatorFn(prop),
})`
  border: 1px solid ${(props) => props.color};
  border-width: 0 0 1px;
  width: 100%;
  display: inline-block;
  padding-top: ${(props) => props.paddingTop};
`;

HorizontalRule.propTypes = {
  color: PropTypes.oneOf(Object.values(theme.palette)),
};

HorizontalRule.defaultProps = {
  color: theme.palette.disabledDark,
};

export default HorizontalRule;

import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import CircularSpinner from 'shared/atoms/CircularSpinner';
import TooltipGraphBasic from 'shared/atoms/LineGraph/components/TooltipGraphBasic';

import theme from 'shared/themes/default';
import T from 'shared/atoms/Typography';
import Tooltip from 'shared/atoms/Tooltip';
import Icon from 'shared/atoms/Icon';

import WatchDataEpisodesSeen from './components/WatchDataEpisodesSeen';
import {
  OuterWrapper,
  GraphOuterWrapper,
  TotalEpisodesLabelWrapper,
  StyledIconWrapper,
  SpinnerWrapper,
} from './styled';

const LineGraph = React.lazy(() => import('shared/atoms/LineGraph'));

const computeTotals = (data, field) => {
  let total = 0;
  data.forEach((element) => {
    if (element[field]) {
      total += element[field];
    }
  });
  return total;
};

const EpisodesSeenGraph = ({ data, loading, spinnerDuration }) => {
  const { t } = useTranslation();

  const period = data.length;
  const currentSeen = computeTotals(data, 'current');
  const pastSeen = computeTotals(data, 'past');

  const config = {
    colors: [theme.palette.brand, theme.palette.disabledLight],
    dataKeys: ['current', 'past'],
    nameKey: 'name',
    labels: [
      { key: 'watchData.lastXMonths', info: { months: period } },
      { key: 'watchData.samePeriodYearBefore' },
    ],
  };

  const customTooltip = {
    Component: TooltipGraphBasic,
    labels: [
      { key: 'watchData.tooltipCurrent' },
      { key: 'watchData.tooltipPast' },
    ],
  };

  return (
    <>
      <OuterWrapper>
        <WatchDataEpisodesSeen
          episodesSeen={currentSeen}
          episodesSeenPreviousYear={pastSeen}
          timePeriodText={t('watchData.lastXMonths', { months: period })}
        />
      </OuterWrapper>
      <GraphOuterWrapper>
        {loading ? (
          <SpinnerWrapper>
            <CircularSpinner
              static
              showProgress
              duration={spinnerDuration}
              size={98}
            />
          </SpinnerWrapper>
        ) : (
          <>
            <TotalEpisodesLabelWrapper>
              <T fontWeight="semibold" color={theme.palette.lightInteracting}>
                {t('watchData.titleEpisodesSeen')}
              </T>
              <Tooltip title={t('watchData.totalTooltip')} arrow>
                <StyledIconWrapper>
                  <Icon
                    color={theme.palette.lightPure}
                    colorHover={theme.palette.brand}
                    icon={InfoOutlinedIcon}
                    size={theme.spacing.m2}
                    sx={{
                      cursor: 'pointer',
                    }}
                  />
                </StyledIconWrapper>
              </Tooltip>
            </TotalEpisodesLabelWrapper>
            <React.Suspense fallback={<CircularSpinner static />}>
              <LineGraph
                data={data}
                config={config}
                customTooltip={customTooltip}
              />
            </React.Suspense>
          </>
        )}
      </GraphOuterWrapper>
    </>
  );
};

EpisodesSeenGraph.propTypes = {
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  spinnerDuration: PropTypes.number,
};

EpisodesSeenGraph.defaultProps = {
  spinnerDuration: 10,
};

export default EpisodesSeenGraph;

import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import Dialog from 'shared/molecules/Dialog';
import Icon from 'shared/atoms/Icon';
import T from 'shared/atoms/Typography';
import Pill from 'shared/atoms/Pill';
import Tooltip from 'shared/atoms/Tooltip';
import AllPlaylistsOverviewTable from 'shared/organisms/WatchData/AllPlaylistsOverviewTable';
import EpisodesSeenGraph from 'shared/organisms/WatchData/EpisodesSeenGraph';
import PlaylistGroupsWatchDataTable from 'shared/organisms/WatchData/PlaylistGroupsWatchDataTable';
import PlaylistWatchDataTable from 'shared/organisms/WatchData/PlaylistWatchDataTable';
import TableWithSearchAndExport from 'shared/organisms/WatchData/TableWithSearchAndExport';

import theme from 'shared/themes/default';
import { TYPE_WATCH_DATA, PLAYLIST_SHARE_TYPE } from 'shared/const';
import Snackbar from 'shared/atoms/Snackbar';
import { mapAggregatedWatchDataGraph, callApi } from '../utils';
import { useWatchData } from '../Context';

const Wrapper = styled.div`
  max-width: 100%;
`;

const WrapperPill = styled.div`
  display: flex;
  margin-top: 108px;
  ${theme.breakpoints.mobile} {
    margin-top: 64px;
  }
`;

const StyledIconWrapper = styled.div`
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
`;

const ShowPlaylistData = ({ id, onSelectItem, isBackOffice }) => {
  const [aggregatedWatchData, setAggregatedWatchData] = useState([]);
  const [loadingAggregated, setLoadingAggregated] = useState(false);
  const [playlistWatchData, setPlaylistWatchData] = useState([]);
  const [playlistWatchDataNextToken, setPlaylistWatchDataNextToken] =
    useState('');
  const [showGroups, setShowGroups] = useState(false);
  const [playlistsGroupsWatchData, setPlaylistsGroupsWatchData] =
    useState(null);
  const [
    playlistsGroupsWatchDataNextToken,
    setPlaylistsGroupsWatchDataNextToken,
  ] = useState('');
  const [loadingPlaylistWatchData, setLoadingPlaylistWatchData] =
    useState(true);
  const [formStatus, setFormStatus] = useState({});
  const [submittingReminder, setSubmittingReminder] = useState(false);
  const [remindAllDisabled, setRemindAllDisabled] = useState(false);
  const [reminderDialogOpen, setReminderDialogOpen] = useState(false);
  const [userReminderCount, setUserReminderCount] = useState(null);
  const { t, i18n } = useTranslation();

  const {
    fetchPlaylistsWatchData,
    allPlaylistsStats,
    loadingPlaylistsWatchData,
    listPlaylistUsersWatchData,
    listPlaylistGroupsWatchData,
    downloadWatchData,
    user,
    playlistSelected,
    setPlaylistSelected,
    organizationSelectedId,
    sendPlaylistReminder,
    listAggregatedWatchData,
    setWatchDataError,
    locale,
    remindAllEventHandler,
  } = useWatchData();

  const selectPlaylistHandler = (playlist) => {
    setLoadingPlaylistWatchData(true);
    setPlaylistSelected(playlist);
  };

  const fetchPlaylistWatchData = async (selectedPlaylistId, nextToken) => {
    const handleError = (error) => {
      console.error('Error calling listPlaylistUsersWatchData', error);
      setLoadingPlaylistWatchData(false);
      setWatchDataError(true);
    };

    try {
      setLoadingPlaylistWatchData(true);
      const { data, error } = await listPlaylistUsersWatchData(
        selectedPlaylistId,
        null,
        nextToken
      );
      if (error) return handleError(error);
      const {
        listPlaylistUsersWatchData: { items, nextToken: newNextToken },
      } = data;
      if (!nextToken) {
        setPlaylistWatchData(items);
      } else {
        setPlaylistWatchData([...playlistWatchData, ...items]);
      }
      if (newNextToken) {
        setPlaylistWatchDataNextToken(newNextToken);
      } else {
        setLoadingPlaylistWatchData(false);
      }
    } catch (error) {
      handleError(error);
    }
    return true;
  };

  const fetchPlaylistsGroupsWatchData = async (
    selectedPlaylistId,
    nextToken
  ) => {
    const handleError = (error) => {
      console.error('Error calling listPlaylistGroupsWatchData', error);
      setLoadingPlaylistWatchData(false);
      setWatchDataError(true);
    };

    try {
      setLoadingPlaylistWatchData(true);
      const { data, error } = await listPlaylistGroupsWatchData(
        selectedPlaylistId,
        nextToken
      );
      if (error) return handleError(error);
      const {
        listPlaylistGroupsWatchData: { items, nextToken: newNextToken },
      } = data;
      if (!nextToken) {
        setPlaylistsGroupsWatchData(items);
      } else {
        setPlaylistsGroupsWatchData([...playlistWatchData, ...items]);
      }
      if (newNextToken) {
        setPlaylistsGroupsWatchDataNextToken(newNextToken);
      } else {
        setLoadingPlaylistWatchData(false);
      }
    } catch (error) {
      handleError(error);
    }
    return true;
  };

  const fetchAggregatedWatchData = async (type) => {
    const params = { organizationId: organizationSelectedId };
    if (playlistSelected) {
      params.playlistId = playlistSelected.id;
    }
    callApi({
      setLoading: setLoadingAggregated,
      setData: setAggregatedWatchData,
      apiMethod: listAggregatedWatchData,
      setError: setWatchDataError,
      type,
      params,
    });
  };

  useEffect(() => {
    if (organizationSelectedId) {
      setPlaylistSelected(null);
    }

    fetchPlaylistsWatchData();
    if (!id) {
      fetchAggregatedWatchData(TYPE_WATCH_DATA.PLAYLISTS);
    }
  }, [organizationSelectedId]);

  useEffect(() => {
    if (id && !loadingPlaylistsWatchData) {
      const playlist = allPlaylistsStats.find((item) => item.id === id);
      if (playlist) {
        setPlaylistSelected(playlist);
      } else {
        onSelectItem();
      }
    }
  }, [allPlaylistsStats, loadingPlaylistsWatchData]);

  useEffect(() => {
    if (playlistSelected) {
      if (playlistSelected.shareType === PLAYLIST_SHARE_TYPE.SELECTION) {
        fetchPlaylistsGroupsWatchData(playlistSelected.id);
        fetchAggregatedWatchData(TYPE_WATCH_DATA.PLAYLIST_GROUPS);
      } else {
        fetchPlaylistWatchData(playlistSelected.id);
        fetchAggregatedWatchData(TYPE_WATCH_DATA.PLAYLIST_USERS);
        setShowGroups(false);
      }
      onSelectItem(playlistSelected.id);
    } else if (aggregatedWatchData.length) {
      fetchAggregatedWatchData(TYPE_WATCH_DATA.PLAYLISTS);
    }
  }, [playlistSelected]);

  useEffect(() => {
    // To differentiate the selection types we show the single selection table if groups yields no results
    if (playlistsGroupsWatchData) {
      setUserReminderCount(null);
      if (playlistsGroupsWatchData.length) {
        setShowGroups(true);
      } else {
        setLoadingPlaylistWatchData(true);
        setShowGroups(false);
        fetchPlaylistWatchData(playlistSelected.id);
        fetchAggregatedWatchData(TYPE_WATCH_DATA.PLAYLIST_USERS);
      }
    }
  }, [playlistsGroupsWatchData]);

  useEffect(() => {
    if (playlistWatchDataNextToken) {
      fetchPlaylistWatchData(playlistSelected.id, playlistWatchDataNextToken);
    }
  }, [playlistWatchDataNextToken]);

  useEffect(() => {
    if (playlistsGroupsWatchDataNextToken) {
      fetchPlaylistsGroupsWatchData(
        playlistSelected.id,
        playlistsGroupsWatchDataNextToken
      );
    }
  }, [playlistsGroupsWatchDataNextToken]);

  useEffect(() => {
    if (playlistSelected) {
      const now = new Date();
      const lastReminderAt = new Date(playlistSelected.lastReminderAt);
      // Disable button if last reminder was sent less than 48 hours ago
      setRemindAllDisabled(now - lastReminderAt < 48 * 60 * 60 * 1000);
    }
  }, [playlistSelected]);

  useEffect(() => {
    if (playlistWatchData?.length) {
      const now = new Date();
      const count = playlistWatchData.filter(
        (user) =>
          user.progress < 100 &&
          now - new Date(user.lastReminderAt) > 48 * 60 * 60 * 1000
      ).length;
      setUserReminderCount(count);
      if (count === 0) {
        setRemindAllDisabled(true);
      }
    }
  }, [playlistWatchData]);

  const handleReminder = async (userId, playlistId, lastReminderAt) => {
    setSubmittingReminder(true);
    if (userId) {
      const currentDate = new Date().toISOString();
      const milliseconds = Math.abs(
        new Date(currentDate) - new Date(lastReminderAt)
      );
      const hours = milliseconds / 36e5;
      if (hours < 48) {
        setFormStatus({
          showSnackBar: true,
          success: false,
          message: t('playlist.reminderSentAlready'),
        });
        return setSubmittingReminder(false);
      }
    } else if (remindAllEventHandler) {
      remindAllEventHandler();
    }
    let reminderStatus = false;
    try {
      const { data } = await sendPlaylistReminder(userId, playlistId);

      reminderStatus = data.sendPlaylistReminder?.success;
    } catch (e) {
      console.log('error', e);
    }
    if (reminderStatus) {
      setFormStatus({
        showSnackBar: true,
        success: true,
        message: t('playlist.reminderSentSuccessfully'),
      });

      if (playlistSelected) {
        if (playlistSelected.shareType === PLAYLIST_SHARE_TYPE.SELECTION) {
          fetchPlaylistsGroupsWatchData(playlistSelected.id);
        } else {
          fetchPlaylistWatchData(playlistSelected.id);
        }
      }
      if (!userId) {
        setRemindAllDisabled(true);
      }
      setReminderDialogOpen(false);
      return setSubmittingReminder(false);
    }
    setFormStatus({
      showSnackBar: true,
      success: false,
      message: t('playlist.reminderSendingFailed'),
    });
    setReminderDialogOpen(false);
    return setSubmittingReminder(false);
  };

  const playlistName = playlistSelected?.id
    ? playlistSelected.name
    : t('playlist.allPlaylists');

  const showReminder =
    isBackOffice || playlistSelected?.ownerId === user.attributes.sub;

  return (
    <Wrapper>
      <>
        <WrapperPill>
          <Pill
            color={theme.palette.lightPure}
            fontColor={theme.palette.mainDark}
          >
            {t('playlist.playlists').toUpperCase()}
          </Pill>
          <Tooltip title={t('watchData.titleTooltip')} arrow>
            <StyledIconWrapper>
              <Icon
                color={theme.palette.lightPure}
                colorHover={theme.palette.brand}
                icon={InfoOutlinedIcon}
                size="2rem"
                sx={{
                  cursor: 'pointer',
                }}
              />
            </StyledIconWrapper>
          </Tooltip>
        </WrapperPill>
        <T
          color={theme.palette.brand}
          fontWeight="medium"
          phoneVariant="headingXs"
          sx={{
            marginBottom: theme.spacing.m3,
            marginTop: theme.spacing.m2,
          }}
          variant="headingS"
        >
          {playlistName}
        </T>
        <EpisodesSeenGraph
          data={mapAggregatedWatchDataGraph(aggregatedWatchData, i18n.language)}
          loading={loadingAggregated}
        />
        {!playlistSelected && (
          <TableWithSearchAndExport
            itemsToDisplay={allPlaylistsStats}
            downloadItems={() =>
              downloadWatchData({ watchData: TYPE_WATCH_DATA.PLAYLISTS })
            }
            user={user}
            tableTitle={t('watchData.activitiesOfYourPlaylists')}
            searchPlaceHolder={t('watchData.searchForPlaylists')}
            TableToDisplay={AllPlaylistsOverviewTable}
            tableProps={{ selectPlaylistHandler }}
            showRowsAreClickableMessage
            loading={loadingPlaylistsWatchData}
            emptyMessage={t('watchData.noActivity')}
            spinnerDuration={90}
          />
        )}
        {playlistSelected?.id && (
          <>
            {showGroups && (
              <TableWithSearchAndExport
                itemsToDisplay={playlistsGroupsWatchData}
                downloadItems={() =>
                  downloadWatchData({
                    watchData: TYPE_WATCH_DATA.PLAYLIST_GROUPS,
                    playlistId: playlistSelected.id,
                  })
                }
                user={user}
                tableTitle={t('watchData.activitiesOfYourPlaylists')}
                searchPlaceHolder={t('watchData.searchForPlaylists')}
                TableToDisplay={PlaylistGroupsWatchDataTable}
                tableProps={{
                  playlistId: playlistSelected.id,
                  locale,
                  handleReminder,
                  showReminder,
                }}
                showRowsAreClickableMessage
                loading={loadingPlaylistWatchData}
                emptyMessage={t('watchData.noPlaylistActivity')}
                secondaryButton={{
                  props: {
                    onClick: () => setReminderDialogOpen(true),
                    disabled: remindAllDisabled,
                    loading: submittingReminder,
                  },
                  text: t('watchData.remindAll'),
                }}
              />
            )}
            {!showGroups && (
              <TableWithSearchAndExport
                itemsToDisplay={playlistWatchData}
                downloadItems={() =>
                  downloadWatchData({
                    watchData: TYPE_WATCH_DATA.PLAYLIST_USERS,
                    playlistId: playlistSelected.id,
                  })
                }
                user={user}
                tableTitle={t('watchData.activitiesOfYourPlaylists')}
                searchPlaceHolder={t('watchData.searchForPlaylists')}
                TableToDisplay={PlaylistWatchDataTable}
                tableProps={{
                  playlistId: playlistSelected.id,
                  locale,
                  handleReminder,
                  showReminder,
                }}
                showRowsAreClickableMessage
                loading={loadingPlaylistWatchData || submittingReminder}
                emptyMessage={t('watchData.noPlaylistActivity')}
                secondaryButton={{
                  props: {
                    onClick: () => setReminderDialogOpen(true),
                    disabled: remindAllDisabled,
                    loading: submittingReminder,
                  },
                  text: t('watchData.remindAll'),
                }}
              />
            )}
          </>
        )}
      </>
      <Dialog
        actions={[
          {
            ariaLabel: t('generic.cancel'),
            handler: () => setReminderDialogOpen(false),
            label: t('generic.cancel'),
            type: 'secondaryOutline',
          },
          {
            ariaLabel: t('watchData.sendReminder'),
            handler: () => handleReminder(null, playlistSelected.id),
            label: t('watchData.sendReminder'),
            loading: submittingReminder,
          },
        ]}
        ariaLabel={t('watchData.remindAll')}
        onClose={() => setReminderDialogOpen(false)}
        open={reminderDialogOpen}
        title={t('watchData.sendReminder')}
      >
        <T color={theme.palette.pureDark}>
          {userReminderCount === null
            ? t('watchData.remindAllNoCountText')
            : t('watchData.remindAllText', { count: userReminderCount })}
        </T>
      </Dialog>
      <Snackbar
        type={formStatus.success ? 'success' : 'error'}
        message={formStatus.message}
        open={formStatus.showSnackBar}
        onClose={() => {
          setFormStatus({
            showSnackBar: false,
            success: null,
            message: '',
          });
        }}
      />
    </Wrapper>
  );
};

ShowPlaylistData.propTypes = {
  id: PropTypes.string,
  onSelectItem: PropTypes.func.isRequired,
  isBackOffice: PropTypes.bool,
};

export default ShowPlaylistData;

import { courseStatus } from '@shared/const';

export const createOrUpdateQuizMutation = (input) => ({
  query: `mutation CreateOrUpdateQuiz(
    $input: CreateOrUpdateQuizInput!
  ) {
    createOrUpdateQuiz(input: $input) {
      id
      courseId
      gameId
      questions {
        id
        text
        options {
          id
          answer
          correct
        }
      }
    }
  }`,
  variables: {
    input,
  },
});

export const deleteCourseMutation = (id) => ({
  query: `mutation deleteCourse(
    $id: ID!
  ) {
    deleteCourse(id: $id) {
      id
    }
  }`,
  variables: {
    id,
  },
});

export const deleteQuizMutation = (input) => ({
  query: `mutation deleteQuiz(
  $input: DeleteQuizInput!
  ) {
    deleteQuiz(input: $input) {
      id
    }
  }`,
  variables: {
    input,
  },
});

export const updateCourseDescription = (input) => ({
  query: `mutation updateCourse(
    $input: UpdateCourseInput!
  ) {
    updateCourse(input: $input) {
      id
      nameTranslations
      shortDescriptionTranslations
      seoDescriptionTranslations
      keyLearningPointsTranslations
      descriptionTranslations
      language
      level
      prerequisitesTranslations
      typicalLearnerTranslations
    }
  }`,
  variables: {
    input,
  },
});

export const updateAttachments = (input) => ({
  query: `mutation updateAttachments(
    $input: UpdateCourseInput!
  ) {
    updateCourse(input: $input) {
      id
      attachments {
        id
        courseId
        name
        file {
          key
          bucket
          region
        }
        processingStatus {
          status
        }
      }
    }
  }`,
  variables: {
    input,
  },
});

export const updateAvailability = (input) => ({
  query: `mutation updateCourse(
    $input: UpdateCourseInput!
  ) {
    updateCourse(input: $input) {
      id
      type
      ownerOrganization {
        id
      }
    }
  }`,
  variables: {
    input,
  },
});

export const updateCourseCatalog = (input) => ({
  query: `mutation updateCourseCatalog(
    $input: UpdateCourseInput!
  ) {
    updateCourse(input: $input) {
      id
      categories {
        items {
          id
          title
          titleTranslations
        }
      }
      experts {
        items {
          id
          firstname
          lastname
          isDisabled
        }
      }
      promopicture {
        bucket
        key
        region
      }
      promopictureProcessingStatus {
        status
      }
      thumbnail {
        bucket
        key
        region
      }
      thumbnailProcessingStatus {
        status
      }
    }
  }`,
  variables: {
    input,
  },
});

export const updateLessons = (input) => ({
  query: `mutation updateCourse(
    $input: UpdateCourseInput!
  ) {
    updateCourse(input: $input) {
      id
      trailerVideoFiles {
        file {
          Key
          Bucket
          region
        }
      }
      trailerVideoProcessingStatus {
        status
      }
      episodeOrder
      episodes {
        id
        name
        nameTranslations
        subtitle {
          key
          bucket
          region
        }
        subtitleProcessingStatus {
          status
        }
        videoFiles {
          file {
            Key
            Bucket
            region
          }
        }
        videoProcessingStatus {
          status
        }
        game {
          id
          length
        }
        attachments {
          id
          episodeId
          name
          file {
            key
            bucket
            region
          }
          processingStatus {
            status
          }
        }
      }
    }
  }`,
  variables: {
    input,
  },
});

export const addCategoryToCourse = ({ categoryId, courseId }) => ({
  query: `mutation AddCategoryToCourse(
    $input: AddCategoryToCourse!
  ) {
    addCategoryToCourse(input: $input) {
      courseId
      categoryId
    }
  }`,
  variables: {
    input: {
      categoryId,
      courseId,
    },
  },
});

export const removeCategoryFromCourse = ({ categoryId, courseId }) => ({
  query: `mutation RemoveCategoryFromCourse(
    $input: RemoveCategoryFromCourse!
  ) {
    removeCategoryFromCourse(input: $input) {
      courseId
      categoryId
    }
  }`,
  variables: {
    input: {
      categoryId,
      courseId,
    },
  },
});

export const addExpertToCourse = ({ expertId, courseId }) => ({
  query: `mutation AddExpertToCourse(
    $input: AddExpertToCourse!
  ) {
    addExpertToCourse(input: $input) {
      courseId
      expertId
    }
  }`,
  variables: {
    input: {
      expertId,
      courseId,
    },
  },
});

export const removeExpertFromCourse = ({ expertId, courseId }) => ({
  query: `mutation RemoveExpertFromCourse(
    $input: RemoveExpertFromCourse!
  ) {
    removeExpertFromCourse(input: $input) {
      courseId
      expertId
    }
  }`,
  variables: {
    input: {
      expertId,
      courseId,
    },
  },
});

export const publishCourse = (id) => ({
  query: `mutation publishCourse(
    $input: UpdateCourseInput!
  ) {
    updateCourse(input: $input) {
      id
      status
    }
  }`,
  variables: {
    input: {
      id,
      status: courseStatus.PUBLISHED,
    },
  },
});

export const deletePublishedCourseMutation = (id) => ({
  query: `mutation deletePublishedCourse(
    $input: UpdateCourseInput!
  ) {
    updateCourse(input: $input) {
      id
      status
      archivedScheduledAt
      archiveDate
    }
  }`,
  variables: {
    input: {
      id,
      status: courseStatus.ARCHIVED,
    },
  },
});

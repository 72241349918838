export const listOrganizationOptions = (organizations) => {
  return organizations
    .sort((a, b) => {
      if (a.archived && !b.archived) {
        return 1;
      }
      if (b.archived && !a.archived) {
        return -1;
      }
      return a.name?.localeCompare(b.name);
    })
    .map((organization) => ({
      name: organization.name
        ? `${organization.archived ? '(Archived) ' : ''}${organization.name}`
        : `No name (ID: ${organization.id})`,
      value: organization.id,
    }));
};

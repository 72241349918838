import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

import T from 'shared/atoms/Typography';
import Icon from 'shared/atoms/Icon';
import IconButton from 'shared/atoms/IconButton';
import Menu from 'shared/molecules/Menu';
import Tooltip from 'shared/atoms/Tooltip';

import theme from 'shared/themes/default';

import { PROCESSING_STATUS } from 'shared/const';

const OuterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing.s} ${theme.spacing.m1};
  background: ${theme.palette.lightPure};
  border: ${(props) =>
    props.theme === 'light' ? `1px solid ${theme.palette.mainDark}` : 'none'};
  border-radius: 54px;
  min-height: 44px;
  ${({ outlineColor }) =>
    outlineColor &&
    `
    border: 1px solid ${outlineColor};
  `}
`;

const LeftWrapper = styled.div`
  display: flex;
  gap: ${theme.spacing.xs};
`;

const MenuBlock = styled.div`
  align-self: center;
`;

const FileItemBox = ({
  actions,
  disabled,
  icon: iconProp,
  id,
  processingStatus,
  text,
  theme: themeLayout,
}) => {
  const [color, setColor] = useState();
  const [icon, setIcon] = useState();
  const [tooltip, setTooltip] = useState('');

  const { t } = useTranslation();

  let ActionsBlock = () => null;
  if (!disabled && actions.length === 1 && actions[0].icon) {
    ActionsBlock = () => (
      <IconButton
        ariaLabel={actions[0].ariaLabel}
        icon={actions[0].icon}
        type="icon"
        theme="light"
        onClick={() => actions[0].handler(id)}
        small
        sx={actions[0].color ? { color: actions[0].color } : null}
      />
    );
  } else if (!disabled && actions.length > 0) {
    const adaptedMenuItems = actions.map(({ label, handler, disabled }) => ({
      label,
      handler: () => {
        handler(id);
      },
      disabled,
    }));

    ActionsBlock = () => (
      <MenuBlock onClick={(event) => event.stopPropagation()}>
        <Menu menuItems={adaptedMenuItems} theme="light" />
      </MenuBlock>
    );
  }

  useEffect(() => {
    if (processingStatus) {
      let color;
      let icon;
      let tooltip;
      switch (processingStatus) {
        case PROCESSING_STATUS.ERROR:
          color = theme.palette.errorDark;
          icon = { icon: ErrorOutlineIcon, color };
          tooltip = t('backoffice.courses.processingError');
          break;
        case PROCESSING_STATUS.PROCESSING:
          color = theme.palette.warning;
          icon = { icon: HourglassEmptyIcon, color };
          tooltip = t('backoffice.courses.processing');
          break;
        case PROCESSING_STATUS.TO_SCHEDULE:
          color = theme.palette.warning;
          icon = { icon: CloudUploadOutlinedIcon, color };
          tooltip = t('backoffice.courses.processingNotScheduled');
          break;
        default:
          break;
      }
      setColor(color);
      setIcon(icon);
      setTooltip(tooltip);
    } else {
      setIcon(iconProp);
    }
  }, [iconProp, processingStatus]);

  return (
    <OuterWrapper outlineColor={color} theme={themeLayout}>
      <LeftWrapper>
        {icon && (
          <Tooltip title={tooltip}>
            <Icon {...icon} />
          </Tooltip>
        )}
        <T color={theme.palette.pureDark}>{text}</T>
      </LeftWrapper>
      <ActionsBlock />
    </OuterWrapper>
  );
};

FileItemBox.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      ariaLabel: PropTypes.string,
      label: PropTypes.string,
      handler: PropTypes.func,
      icon: PropTypes.object,
      color: PropTypes.string,
    })
  ),
  disabled: PropTypes.bool,
  icon: PropTypes.object,
  id: PropTypes.string.isRequired,
  processingStatus: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  theme: PropTypes.string,
};

FileItemBox.defaultProps = {
  actions: [],
  disabled: false,
  theme: 'dark',
};

export default FileItemBox;

const getUserInitials = (user) => {
  let initials =
    user?.name
      ?.split(' ')
      .map((word) => word[0])
      .join('') || '';
  if (initials && initials.length > 2) {
    initials = initials.substring(0, 1) + initials.slice(-1);
  }
  return initials;
};

export default getUserInitials;

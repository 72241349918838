import { TIMESTAMP_FORMATS } from 'shared/const';

export const formatDate = ({
  date = new Date(),
  locale = 'nb-NO',
  type = TIMESTAMP_FORMATS.DATE,
}) => {
  let options = {};

  switch (type) {
    case TIMESTAMP_FORMATS.DATE:
      options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      };
      break;
    case TIMESTAMP_FORMATS.DATE_DEFAULT:
      options = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      };
      break;
    case TIMESTAMP_FORMATS.DATE_TIME:
      options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      };
      break;
    case TIMESTAMP_FORMATS.MONTH:
      options = {
        month: 'short',
      };
      break;
    case TIMESTAMP_FORMATS.MONTH_YEAR:
      options = {
        month: 'long',
        year: 'numeric',
      };
      break;
    case TIMESTAMP_FORMATS.TIME:
      options = {
        minute: '2-digit',
        second: '2-digit',
        timeZone: 'UTC',
      };
      break;
    default:
      break;
  }
  if (!date) return null;
  return new Intl.DateTimeFormat(locale, options).format(
    typeof date === 'string' ? new Date(date) : date
  );
};

import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import Chip from 'shared/atoms/Chip';
import DragAndDrop from 'shared/molecules/DragAndDrop';
import ItemBox from 'shared/molecules/ItemBox';
import theme from 'shared/themes/default';
import { selectTranslation } from 'shared/utils';

const DragAndDropWrapper = styled.div`
  max-height: 600px;
  overflow-y: auto;
`;

const PlaylistContentItemList = ({
  isSortable,
  locale,
  menuItems,
  playlistContent,
  playlistContentOrderHandler,
}) => {
  const [playlistContentItems, setPlaylistContentItems] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    setPlaylistContentItems(playlistContent);
  }, [playlistContent]);

  const handleDragEnd = (array, id, newIndex) => {
    setPlaylistContentItems(array);
    if (playlistContentOrderHandler) {
      playlistContentOrderHandler(newIndex, id, array);
    }
  };

  const renderItem = (item) => (
    <ItemBox
      id={item.id}
      draggable={isSortable}
      menuItems={menuItems}
      theme="light"
      title={selectTranslation(item.nameTranslations, item.name, locale, '')}
      thumbnailIcon={!item.thumbnailUrl ? PlayArrowIcon : null}
      thumbnailImgSrc={item.thumbnailUrl}
      topTag={
        (item.daysLeft || item.daysLeft === 0) && (
          <Chip
            color={theme.palette.errorLight}
            icon={<AccessAlarmIcon />}
            label={t('courses.daysLeft', { count: item.daysLeft })}
            small
            theme="dark"
          />
        )
      }
    />
  );

  return (
    <DragAndDropWrapper>
      <DragAndDrop
        isLocked={!isSortable}
        items={playlistContentItems}
        onDragEnd={handleDragEnd}
        renderItem={renderItem}
      />
    </DragAndDropWrapper>
  );
};

PlaylistContentItemList.propTypes = {
  isSortable: PropTypes.bool,
  locale: PropTypes.oneOf(['no', 'en', 'es']).isRequired,
  menuItems: PropTypes.array.isRequired,
  playlistContent: PropTypes.array.isRequired,
  playlistContentOrderHandler: PropTypes.func,
};

export default PlaylistContentItemList;

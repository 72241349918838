export const listOrganizations = (domain, nextToken) => ({
  query: `query listOrganizations(
    $domain: String!
    $nextToken: String
  ) {
    listOrganizations(domain: $domain, limit: 100, nextToken: $nextToken) {
      items {
        id
        domain
        name
        organizationNumber
        stripe_customer_id
        email
        archived
        hideVcCourses
        lockVcCourses
        internalCoursesOnTop
        hasCustomPlans
        subscriptions {
          planId
          planName
          validFrom
          expireDate
          planStatus
          type
        }
        invoiceAddress
        invoiceCity
        invoiceEmail
        invoiceName
        invoiceNote
        invoicePeriod
        invoiceSendVia
        invoiceZip
        featuredItem {
          id
          courseId
          organizationId
          domain
          course {
            id
            name
            nameTranslations
          }
          image {
            key
            bucket
          }
          imageProcessingStatus {
            status
          }
          description
          title
        }
        registeredMembers
        createdBy
        createdAt
        createdFrom
        hidePriceList
        hubspotId
        hubspotSyncedId
        invitationLanguage
        purchasedLicenses
        startDate
      }
      nextToken
    }
  }`,
  variables: {
    domain,
    nextToken,
  },
});

export const listCourses = ({
  domain,
  hideVcCourses,
  nextToken,
  ownerOrganizationId,
}) => ({
  query: `query listCourses(
    $filter: CourseFilterInput
    $hideVcCourses: Boolean
    $limit: Int
    $nextToken: String
    $ownerOrganizationId: String
  ) {
    listCourses(
      filter: $filter
      hideVcCourses: $hideVcCourses
      limit: $limit
      nextToken: $nextToken
      ownerOrganizationId: $ownerOrganizationId
    ) {
      items {
        id
        archivedScheduledAt
        name
        nameTranslations
      }
      nextToken
    }
  }`,
  variables: {
    filter: {
      domain: {
        contains: domain,
      },
      status: {
        eq: 'PUBLISHED',
      },
    },
    hideVcCourses,
    limit: 1000,
    nextToken,
    ownerOrganizationId,
  },
});

export const listCustomerSubscriptionPlans = (domain, customerId) => ({
  query: `query listCustomerSubscriptionPlans($domain: String!, $customerId: ID!) {
    listCustomerSubscriptionPlans(domain: $domain, customerId: $customerId) {
      active
      nickname
      subscriptionId
      cancel_at
      cancel_at_period_end
      canceled_at
      current_period_start
      current_period_end
      ended_at
    }
  }`,
  variables: {
    domain,
    customerId,
  },
});

export const listSubscriptionPlans = (input) => ({
  query: `query listSubscriptionPlans(
    $filter: SubscriptionPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscriptionPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        nameTranslations
        price
        stripePlanId
        backoffice
        organizationId
      }
      nextToken
    }
  }`,
  variables: {
    ...input,
  },
});

export const checkHideVcCourses = (organizationId) => ({
  query: `query checkHideVcCourses($organizationId: ID!) {
    checkHideVcCourses(organizationId: $organizationId) {
      allowed
      hasPlaylistsWithVcCourses
      hasVcCoursesFeatured
      hasFeaturedCourse
    }
  }`,
  variables: {
    organizationId,
  },
});

import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Button from 'shared/atoms/Button';
import CircularSpinner from 'shared/atoms/CircularSpinner';
import SearchField from 'shared/atoms/SearchField';
import TransferOwnershipView from 'shared/organisms/TransferOwnershipView';

import theme from 'shared/themes/default';
import { downloadFile } from 'shared/utils';
import { registeredMembersRoles } from 'shared/const';

import { useOrganizationMembers } from '../../context';

import GroupsOverviewTable from '../GroupsOverviewTable';

const StyledBlock = styled.div`
  min-width: 25%;
  ${theme.breakpoints.mobile} {
    width: 100%;
  }
`;

const StyledFilterWrapper = styled.div`
  margin-top: ${theme.spacing.m1};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${theme.spacing.m1};
  ${theme.breakpoints.mobile} {
    flex-direction: column;
    align-items: end;
  }
`;

const StyledRegisteredTableWrapper = styled.div`
  margin: 1rem 0 1rem 0 1rem;
`;
const StyledLoadingWrapper = styled.div`
  margin: 50px 0;
`;

const GroupsWrapper = styled.div`
  width: 100%;
`;

const Groups = () => {
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState('');
  const [searchedGroupInfo, setSearchedGroupInfo] = useState();
  const [formStatus, setFormStatus] = useState(null);
  const [loadingExport, setLoadingExport] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [groupId, setGroupId] = useState();
  const [groupOwnedBy, setGroupOwnedBy] = useState();
  const [groupOwners, setGroupOwners] = useState([]);
  const [showTransferOwnership, setShowTransferOwnership] = useState(false);

  const {
    downloadOrganizationGroups,
    groups: { groupsInfo, loadingGroupsInfo, onTransferGroup },
    registeredMembers,
    user,
  } = useOrganizationMembers();

  useEffect(() => {
    setGroupOwners(
      registeredMembers.filter(
        (item) =>
          item.roleId === registeredMembersRoles.manager ||
          item.roleId === registeredMembersRoles.admin
      )
    );
  }, [registeredMembers]);

  const downloadReport = async () => {
    try {
      setLoadingExport(true);

      // Call backend to generate the file and return the required info for building the download URL
      const { data, error } = await downloadOrganizationGroups();
      if (error) {
        console.error('Error calling downloadOrganizationGroups', error);
        setLoadingExport(false);
        return;
      }
      const {
        downloadOrganizationGroups: { cloudfrontUrl, fileName },
      } = data;

      await downloadFile(
        user?.signInUserSession?.accessToken?.jwtToken,
        cloudfrontUrl,
        fileName
      );
      setLoadingExport(false);
    } catch (error) {
      setLoadingExport(false);
      console.error('Some error happened when building the export file', error);
    }
  };

  const onSearchHandler = ({ value }) => {
    setSearchTerm(value);
    if (value) {
      const searchedGroup = groupsInfo.filter((group) => {
        return (
          group.name.toLowerCase().includes(value.toLowerCase()) ||
          group.ownedByName.toLowerCase().includes(value.toLowerCase())
        );
      });
      setSearchedGroupInfo(searchedGroup);
    } else {
      setSearchedGroupInfo(null);
    }
  };

  const onTransferOwnership = (group) => {
    setShowTransferOwnership(true);
    setGroupName(group.name);
    setGroupId(group.id);
    setGroupOwnedBy(group.ownedBy);
  };

  const onCloseTransferHandler = () => {
    setShowTransferOwnership(false);
    setFormStatus(null);
  };

  const onTransferOwnershipHandler = async (user) => {
    const input = {
      groupId,
      currentOwnerId: groupOwnedBy,
      newOwnerId: user[0].id,
    };
    const formStatus = await onTransferGroup(input);

    setFormStatus(formStatus);
  };

  return (
    <GroupsWrapper>
      {loadingGroupsInfo ? (
        <StyledLoadingWrapper>
          <CircularSpinner
            thickness={3}
            size={125}
            static
            bottomText={t('generic.pleaseWait')}
          />
        </StyledLoadingWrapper>
      ) : (
        <>
          <StyledFilterWrapper>
            <Button
              ariaLabel={t('administrateUsers.exportAll')}
              onClick={downloadReport}
              loading={loadingExport}
              theme="dark"
              type="primary"
            >
              {t('administrateUsers.exportAll')}
            </Button>
            <StyledBlock>
              <SearchField
                type="secondary"
                placeholder={t('administrateUsers.searchForGroup')}
                ariaLabel={t('administrateUsers.searchForGroup')}
                onChange={onSearchHandler}
                value={searchTerm}
              />
            </StyledBlock>
          </StyledFilterWrapper>
          <StyledRegisteredTableWrapper>
            <GroupsOverviewTable
              groupsInfo={searchedGroupInfo || groupsInfo}
              onTransferOwnership={onTransferOwnership}
            />
          </StyledRegisteredTableWrapper>
          {showTransferOwnership && (
            <TransferOwnershipView
              onSubmitHandler={onTransferOwnershipHandler}
              transferItemName={groupName}
              usersToTransfer={groupOwners.filter(
                (manager) => manager.id !== groupOwnedBy
              )}
              onClose={onCloseTransferHandler}
              formStatus={formStatus}
            />
          )}
        </>
      )}
    </GroupsWrapper>
  );
};

export default Groups;

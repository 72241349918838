import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';

import NoBackofficeAccessPage from '../../pages/NoBackofficeAccessPage';
import { useAppGlobals } from '../../contexts/AppContext';

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const { loadingUser, user } = useAppGlobals();

  if (!loadingUser) {
    if (user) {
      // check if current user has access to backoffice
      const cognitoGroup =
        user.signInUserSession.accessToken.payload['cognito:groups'];
      if (cognitoGroup && cognitoGroup.includes('Admin')) {
        return children;
      }
      return <NoBackofficeAccessPage />;
    }
    return (
      <Navigate
        to={`/login?redirect=${encodeURIComponent(location.pathname)}`}
        replace
      />
    );
  }

  return null;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node,
};
export default ProtectedRoute;

import React from 'react';
import PropTypes from 'prop-types';
import MuiTableBody from '@mui/material/TableBody';
import theme from 'shared/themes/default';

const TableBody = ({ children, isExpandable, zebraStripeEffect }) => {
  const sxTableBody = {
    '&.MuiTableBody-root': {
      '&>tr': {
        backgroundColor: theme.palette.oddDark,
      },
    },
  };

  if (zebraStripeEffect) {
    sxTableBody['&.MuiTableBody-root']['&>tr'] = {
      [`&:nth-of-type(${isExpandable ? '4n-1' : 'even'})`]: {
        backgroundColor: theme.palette.evenDark,
      },
    };
  }

  return <MuiTableBody sx={sxTableBody}>{children}</MuiTableBody>;
};

TableBody.propTypes = {
  children: PropTypes.node.isRequired,
  isExpandable: PropTypes.bool,
  zebraStripeEffect: PropTypes.bool,
};

TableBody.defaultProps = {
  isExpandable: false,
  zebraStripeEffect: true,
};

export default TableBody;

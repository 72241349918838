export const listCourses = `query ListCourses(
  $filter: CourseFilterInput
  $ownerOrganizationId: String
  $showOrganizationCourses: Boolean
  $limit: Int
  $nextToken: String
) {
  listCourses(
    filter: $filter
    ownerOrganizationId: $ownerOrganizationId
    showOrganizationCourses: $showOrganizationCourses
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      shortId
      domain
      name
      nameTranslations
      createdAt
      language
      categories {
        items {
          id
          title,
          titleTranslations
        }
      }
      experts {
        items {
          id
          firstname
          lastname
        }
      }
      episodes {
        id
        name
        nameTranslations
      }
      ownerOrganization {
        id
        name
        stripe_customer_id
        email
      }
      type
      status
      publishedAt
      archivedScheduledAt
      archiveDate
    }
    nextToken
  }
}`;

export const listFeaturedItems = `query ListFeaturedItems (
  $domain: String
) {
  listFeaturedItems(domain: $domain) {
    id
    course {
      id
      name
      nameTranslations
    }
  }
}`;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';

import CloseIcon from '@mui/icons-material/Close';

import Chip from 'shared/atoms/Chip';
import Checkbox from 'shared/atoms/Checkbox';
import IconButton from 'shared/atoms/IconButton';
import Info from 'shared/molecules/Info';
import Pagination from 'shared/molecules/Pagination';
import { BACKGROUND_THEMES } from 'shared/const';

import theme from 'shared/themes/default';

const PaginationWrapper = styled.div`
  justify-content: center;
`;

const PrimaryWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.s};
`;

const ListItems = ({
  items,
  itemsPerPage,
  minHeight,
  onRemove,
  onSelect,
  secondaryActionLabel,
  selected,
  showBorder,
  showLabel,
  theme: themeLayout,
}) => {
  const [page, setPage] = useState(1);
  const noOfPages = Math.ceil(items.length / itemsPerPage);

  const handlePageChange = (e, value) => {
    e.preventDefault();
    setPage(value);
  };

  const listedItems = items
    .slice((page - 1) * itemsPerPage, page * itemsPerPage)
    .map((item, index) => {
      const sxListItem = {
        margin: '0',
        paddingLeft: theme.spacing.s,
        borderLeft: `${theme.spacing.s} solid transparent`,
      };
      if (themeLayout === 'light') {
        sxListItem.backgroundColor =
          index % 2 === 0
            ? theme.palette.lightInteracting
            : theme.palette.lightPure;
      } else {
        sxListItem.backgroundColor =
          index % 2 === 0 ? theme.palette.oddDark : theme.palette.evenDark;
      }
      if (showBorder && item.showBorder) {
        sxListItem.borderLeftColor = theme.palette.brand;
      }
      if (onSelect) {
        sxListItem.cursor = item.disabled ? 'default' : 'pointer';
        sxListItem.userSelect = 'none';
        sxListItem['&:hover'] = {
          '&:nth-of-type(even)': {
            backgroundColor: theme.palette.lightDistinct,
          },
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.disabledLight,
          },
        };
      }

      const textColor =
        themeLayout === 'light'
          ? theme.palette.pureDark
          : theme.palette.lightInteracting;
      const textDisabledColor = item.disabled
        ? theme.palette.disabledLight
        : textColor;
      const errorColor =
        themeLayout === 'light'
          ? theme.palette.errorDark
          : theme.palette.errorLight;

      const sxListItemText = {
        '.MuiListItemText-primary': {
          fontSize: '1rem',
          fontWeight: '600',
          color: textDisabledColor,
        },
        '.MuiListItemText-secondary': {
          fontSize: '1rem',
          color: item.error ? errorColor : textDisabledColor,
        },
      };

      const sxSecondaryAction = {
        marginRight: '57px',
        marginTop: '-2px',
      };

      return (
        <ListItem
          sx={sxListItem}
          key={item.key}
          {...(onSelect && !item.disabled
            ? { onClick: () => onSelect(item.key) }
            : {})}
        >
          {onSelect && (
            <Checkbox
              checked={selected.includes(item.key)}
              disabled={item.disabled}
              theme="light"
            />
          )}
          <ListItemText
            primary={
              <PrimaryWrapper>
                {item.title}
                {showLabel && (
                  <Chip
                    background={item.error ? undefined : theme.palette.brand}
                    color={item.error ? theme.palette.errorDark : undefined}
                    label={item.label}
                    theme={themeLayout}
                    small
                    sx={{
                      ...(!item.error && {
                        border: 'none',
                        color: theme.palette.pureDark,
                      }),
                    }}
                  />
                )}
                {item.errorTooltip && (
                  <Info theme={themeLayout}>{item.errorTooltip}</Info>
                )}
              </PrimaryWrapper>
            }
            secondary={item.subtitle}
            sx={sxListItemText}
          />
          {item.showSecondaryAction && (
            <ListItemSecondaryAction sx={sxSecondaryAction}>
              <Chip label={secondaryActionLabel} theme={themeLayout} small />
            </ListItemSecondaryAction>
          )}
          {onRemove && (
            <ListItemSecondaryAction>
              <IconButton
                ariaLabel="remove"
                icon={CloseIcon}
                onClick={() => onRemove(item.key)}
                theme={themeLayout}
                type="transparent"
              />
            </ListItemSecondaryAction>
          )}
        </ListItem>
      );
    });

  return (
    <>
      <List sx={{ minHeight }}>{listedItems}</List>
      <PaginationWrapper>
        {noOfPages > 1 && (
          <Pagination
            initialPage={1}
            onChange={handlePageChange}
            theme={themeLayout}
            totalPages={noOfPages}
          />
        )}
      </PaginationWrapper>
    </>
  );
};

ListItems.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      error: PropTypes.bool,
      errorTooltip: PropTypes.string,
      key: PropTypes.string.isRequired,
      label: PropTypes.string,
      showBorder: PropTypes.bool,
      showSecondaryAction: PropTypes.bool,
      subtitle: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
  itemsPerPage: PropTypes.number,
  minHeight: PropTypes.string,
  onRemove: PropTypes.func,
  onSelect: PropTypes.func,
  secondaryActionLabel: PropTypes.string,
  selected: PropTypes.arrayOf(PropTypes.string),
  showBorder: PropTypes.bool,
  showLabel: PropTypes.bool,
  theme: PropTypes.oneOf(BACKGROUND_THEMES),
};

ListItems.defaultProps = {
  itemsPerPage: 5,
  minHeight: 'unset',
  theme: 'light',
};

export default ListItems;

import { useEffect, useState } from 'react';

export default function useGroups({ listGroupsWatchData, setWatchDataError }) {
  const [groupsData, setGroupsData] = useState([]);
  const [loadingGroups, setLoadingGroups] = useState(false);
  const [groupsNextToken, setGroupsNextToken] = useState();

  const handleError = (error) => {
    console.error('Error retrieving groups: ', error);
    setWatchDataError(true);
    setGroupsNextToken(null);
    setGroupsData([]);
    setLoadingGroups(false);
  };

  const fetchGroups = async (nextToken = null) => {
    setLoadingGroups(true);
    try {
      const { data, error } = await listGroupsWatchData(nextToken);
      if (error) return handleError(error);
      if (data) {
        const {
          listGroupsWatchData: { items, nextToken: newNextToken },
        } = data;
        if (!nextToken) {
          setGroupsData(items);
        } else {
          setGroupsData((groups) => [...groups, ...items]);
        }
        if (newNextToken) {
          setGroupsNextToken(newNextToken);
        } else {
          setLoadingGroups(false);
          setGroupsNextToken(null);
        }
      }
    } catch (error) {
      handleError(error);
    }

    return true;
  };

  useEffect(() => {
    if (groupsNextToken) {
      fetchGroups(groupsNextToken);
    }
  }, [groupsNextToken]);

  return {
    organizationGroupsStats: groupsData,
    loadingGroups,
    fetchGroups,
  };
}

import React from 'react';
import styled from 'styled-components';

import { useAppGlobals } from '../../contexts/AppContext';
import AnimationNo from './assets/videocationLogo_no.svg';
import AnimationEs from './assets/videocationLogo_es.svg';

const SvgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

const Svg = styled.img`
  width: 200px;
  height: 200px;
`;

const InitialLoading = () => {
  const { domain } = useAppGlobals();

  const animationAsset = domain === 'no' ? AnimationNo : AnimationEs;

  return (
    <SvgWrapper>
      <Svg src={animationAsset} alt="Videocation loading animation" />
    </SvgWrapper>
  );
};

export default InitialLoading;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Button from '@shared/atoms/Button';
import Input from '@shared/atoms/Input';
import Select from '@shared/atoms/Select';
import T from '@shared/atoms/Typography';

import theme from '@shared/themes/default';
import { QUIZ_TYPE, courseStatus } from '@shared/const';
import { logger } from '@shared/utils';

import { useCourse } from '../../../../contexts/CourseContext';

import QuizContent from './components/QuizContent';

const SectionStyled = styled.section`
  background: ${theme.palette.darkWrapper};
  padding: ${theme.spacing.m1} ${theme.spacing.s};
`;
const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LeftWrapper = styled.div`
  display: flex;
  gap: ${theme.spacing.m1};
`;

const ContentWrapper = styled.div`
  padding: ${theme.spacing.m1} 0 0;
`;

const Message = styled.div`
  align-self: end;
`;

const InputButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${theme.spacing.s};
  width: fit-content;
`;

const Quiz = () => {
  const {
    course,
    createOrUpdateQuiz,
    deleteQuiz,
    getGameName,
    hasChanged,
    locked,
    setHandleSave,
    setHasChanged,
    setSubmitting,
    submitting,
  } = useCourse();

  const { quiz, status } = course;

  let defaultType = QUIZ_TYPE.NO_QUIZ;
  if (quiz) {
    defaultType = quiz.gameId ? QUIZ_TYPE.GAME : QUIZ_TYPE.TEST;
  }

  const { t } = useTranslation();

  const [selectedTypeQuiz, setSelectedTypeQuiz] = useState(defaultType);
  const [currentQuiz, setCurrentQuiz] = useState(quiz || {});
  const [hasError, setHasError] = useState(false);
  const [submittingGameId, setSubmittingGameId] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [gameName, setGameName] = useState(false);
  const [isEditing, setIsEditing] = useState(defaultType === QUIZ_TYPE.TEST);

  let options = [
    {
      value: QUIZ_TYPE.TEST,
      key: QUIZ_TYPE.TEST,
      label: t('backoffice.courses.quizTab.multipleType'),
    },
    {
      value: QUIZ_TYPE.GAME,
      key: QUIZ_TYPE.GAME,
      label: t('backoffice.courses.quizTab.gameType'),
    },
  ];

  if (defaultType === QUIZ_TYPE.NO_QUIZ || status === courseStatus.DRAFT) {
    options = [
      {
        value: QUIZ_TYPE.NO_QUIZ,
        key: QUIZ_TYPE.NO_QUIZ,
        label: t('backoffice.courses.quizTab.noQuizType'),
      },
      ...options,
    ];
  }

  const onChange = ({ value }) => {
    setSelectedTypeQuiz(value);
    setCurrentQuiz(quiz || {});
    setHasChanged(value !== defaultType && value === QUIZ_TYPE.NO_QUIZ);
    setHasError(false);
  };

  const onChangeGameId = (gameId) => {
    setCurrentQuiz({ ...(quiz || {}), gameId });
    setIsValidated(false);
  };

  const handleSave = async () => {
    if (
      selectedTypeQuiz === QUIZ_TYPE.NO_QUIZ ||
      (selectedTypeQuiz === QUIZ_TYPE.TEST &&
        status === courseStatus.DRAFT &&
        currentQuiz.questions?.length === 0)
    ) {
      if (currentQuiz?.id) {
        try {
          await deleteQuiz({ quizId: currentQuiz.id, courseId: course.id });
          setHasChanged(false);
          setSelectedTypeQuiz(QUIZ_TYPE.NO_QUIZ);
        } catch (e) {
          logger.error(e);
          setHasError(true);
          setSubmitting(false);
        }
        return;
      }
    }

    const newQuiz = {
      ...currentQuiz,
      courseId: course.id,
      questions:
        selectedTypeQuiz === QUIZ_TYPE.TEST ? currentQuiz.questions : null,
      gameId: selectedTypeQuiz === QUIZ_TYPE.TEST ? null : currentQuiz.gameId,
    };

    try {
      await createOrUpdateQuiz(newQuiz);
      setHasChanged(false);
      setIsEditing(selectedTypeQuiz === QUIZ_TYPE.TEST);
    } catch (e) {
      logger.error(e);
      setHasError(true);
      setSubmitting(false);
    }
  };

  const validateGameId = async () => {
    if (currentQuiz.gameId.length > 0) {
      try {
        setSubmittingGameId(true);
        const res = await getGameName(currentQuiz.gameId);
        setGameName(res);
        setHasChanged(res && quiz?.gameId !== currentQuiz.gameId);
      } catch (e) {
        logger.error(e);
      }
      setSubmittingGameId(false);
      setIsValidated(true);
    }
  };

  useEffect(() => {
    setHandleSave(() => handleSave);
    setGameName(null);
  }, [selectedTypeQuiz, currentQuiz]);

  useEffect(() => {
    if (selectedTypeQuiz === QUIZ_TYPE.GAME && !!currentQuiz.gameId) {
      validateGameId(currentQuiz.gameId);
    } else {
      setIsValidated(false);
    }
  }, [selectedTypeQuiz]);

  const disableSave =
    locked ||
    !hasChanged ||
    hasError ||
    (selectedTypeQuiz === QUIZ_TYPE.TEST &&
      currentQuiz.questions?.length === 0 &&
      status !== courseStatus.DRAFT) ||
    (selectedTypeQuiz === QUIZ_TYPE.GAME &&
      (currentQuiz.gameId?.length === 0 || !isValidated || gameName === null));

  return (
    <SectionStyled>
      <TopWrapper>
        <LeftWrapper>
          <div>
            <T
              color={theme.palette.lightPure}
              sx={{ marginBottom: theme.spacing.xs }}
            >
              {t('backoffice.courses.quizTab.quizTypeLabel')}
            </T>
            <Select
              disabled={locked || submitting || submittingGameId}
              id="typeQuizSelect"
              items={options}
              name="typeQuizSelect"
              onChange={onChange}
              value={selectedTypeQuiz}
              sx={{ width: '240px' }}
              theme="dark"
              required
            />
          </div>
          {selectedTypeQuiz === QUIZ_TYPE.NO_QUIZ && (
            <Message>
              <T>
                <T component="span" fontWeight="semibold">
                  {t('backoffice.courses.quizTab.noQuizMessage1')}
                </T>{' '}
                {t('backoffice.courses.quizTab.noQuizMessage2')}
              </T>
              <T>
                {t('backoffice.courses.quizTab.noQuizMessage3')}{' '}
                <T component="span" fontWeight="semibold">
                  {t('backoffice.courses.quizTab.noQuizMessage1')}
                </T>{' '}
                {t('backoffice.courses.quizTab.noQuizMessage4')}
              </T>
            </Message>
          )}
        </LeftWrapper>
        <Button
          ariaLabel={t('generic.save')}
          disabled={disableSave}
          loading={submitting}
          onClick={handleSave}
          sx={{ height: '40px' }}
          type="secondary"
        >
          {t('generic.save')}
        </Button>
      </TopWrapper>
      {selectedTypeQuiz === QUIZ_TYPE.TEST && (
        <ContentWrapper>
          <QuizContent
            defaultQuiz={quiz}
            disabled={locked}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            modifyQuiz={setCurrentQuiz}
            quiz={currentQuiz}
            setHasError={setHasError}
            setHasModified={setHasChanged}
          />
        </ContentWrapper>
      )}
      {selectedTypeQuiz === QUIZ_TYPE.GAME && (
        <ContentWrapper>
          <T
            color={theme.palette.lightPure}
            sx={{ marginBottom: theme.spacing.xs }}
          >
            {t('backoffice.courses.quizTab.gameLabel')}
          </T>
          <InputButtonWrapper>
            <Input
              disabled={locked}
              onChange={(e) => onChangeGameId(e.target.value)}
              onClick={(e) => e.stopPropagation()}
              onBlur={validateGameId}
              placeholder={t('backoffice.courses.quizTab.gamePlaceHolder')}
              sx={{ width: '240px' }}
              value={currentQuiz?.gameId || ''}
            />
            <Button
              ariaLabel={t('backoffice.courses.quizTab.validateGameId')}
              loading={submittingGameId}
              disabled={
                !currentQuiz?.gameId ||
                currentQuiz?.gameId?.length === 0 ||
                isValidated
              }
              onClick={validateGameId}
              sx={{ height: '40px' }}
              type="secondary"
            >
              {t('backoffice.courses.quizTab.validateGameId')}
            </Button>
          </InputButtonWrapper>
          {isValidated && gameName && (
            <T
              color={theme.palette.lightPure}
              sx={{ marginTop: theme.spacing.s }}
            >
              {gameName}
            </T>
          )}
          {isValidated && !gameName && (
            <T
              color={theme.palette.errorDark}
              sx={{ marginTop: theme.spacing.s }}
            >
              {t('backoffice.courses.quizTab.checkError')}
            </T>
          )}
        </ContentWrapper>
      )}
    </SectionStyled>
  );
};

export default Quiz;

import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import SearchIcon from '@mui/icons-material/Search';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';

import Alert from 'shared/atoms/Alert';
import CircularSpinner from 'shared/atoms/CircularSpinner';
import Form from 'shared/atoms/Form';
import FormSelect from 'shared/atoms/FormFields/FormSelect';
import FormToggle from 'shared/atoms/FormFields/FormToggle';
import Icon from 'shared/atoms/Icon';
import SearchField from 'shared/atoms/SearchField';
import T from 'shared/atoms/Typography';
import ConfirmationDialog from 'shared/molecules/ConfirmationDialog';
import Dialog from 'shared/molecules/Dialog';
import ListItems from 'shared/molecules/ListItems';

import { itemIsIncluded } from 'shared/utils';
import { NOT_SHARED, ORGANIZATION, USER, GROUP } from 'shared/const';
import theme from 'shared/themes/default';

import {
  Spacer,
  SideSpaceWrapper,
  EmptyListWrapper,
  ListWrapper,
  NotSharedWrapper,
  NotSharedInnerWrapper,
  ShareIconWrapper,
  SharingOptionsList,
} from './index.styles';

const getSearchItemsArray = (
  searchItemsResult,
  newSharedList,
  newAddedItems
) => {
  return searchItemsResult.map((item) => ({
    ...item,
    selected: itemIsIncluded(item.id, newSharedList),
    new: itemIsIncluded(item.id, newAddedItems),
  }));
};

const getPreviewList = (newSharedList, newAddedItems) => {
  const remainingOldItems = newSharedList.filter(
    (user) => !itemIsIncluded(user.id, newAddedItems)
  );
  return [
    ...newAddedItems.map((item) => ({ ...item, new: true, selected: true })),
    ...remainingOldItems.map((item) => ({ ...item, selected: true })),
  ];
};

function SharePlaylistAndManage({
  formStatus,
  hasGroups,
  initialShareType,
  initialSharedWith,
  loading,
  searchLoading,
  onClose,
  onSubmitHandler,
  playlistName,
  searchGroups,
  searchUsers,
  searchUsersResult,
  searchGroupsResult,
  totalOrganizationMembers,
}) {
  const [submitting, setSubmitting] = useState(false);
  const [newSharedList, setNewSharedList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [shareType, setShareType] = useState(null);
  const [showDeleteSharingConfirm, setShowDeleteSharingConfirm] =
    useState(false);
  const [notifyNew, setNotifyNew] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setSubmitting(false);
    setNewSharedList(initialSharedWith);
    setShareType(initialShareType || NOT_SHARED);
  }, [formStatus, initialSharedWith, initialShareType]);

  const handleShareTypeChange = ({ value }) => {
    setShareType(value);
    setNewSharedList([]);
    setSearchTerm('');
  };

  const onSubmit = ({ removeAll = false }) => {
    setSubmitting(true);
    onSubmitHandler({
      shareType,
      newSharedList,
      removeAll,
      notifyNew,
    });
  };

  const memberParse = (member) => {
    const {
      email: subtitle,
      id: key,
      membersCount,
      name: title,
      new: showSecondaryAction,
    } = member;

    return {
      key,
      showSecondaryAction,
      subtitle: subtitle || t('playlist.membersText', { count: membersCount }),
      title,
    };
  };

  const onChangeSearch = ({ value }) => {
    setSearchTerm(value);
    if (shareType === USER) {
      searchUsers(value);
    } else if (shareType === GROUP) {
      searchGroups(value);
    }
  };

  const toggleUser = (userId) => {
    const user = [
      ...newSharedList,
      ...(shareType === USER ? searchUsersResult : searchGroupsResult),
    ].find((member) => member.id === userId);
    const toggleUserId = user.id;
    const userDetails = user;
    if (itemIsIncluded(toggleUserId, newSharedList)) {
      const newValue = newSharedList.filter((user) => user.id !== toggleUserId);
      setNewSharedList(newValue);
    } else {
      delete userDetails.shared;
      delete userDetails.new;
      const newValue = [...newSharedList, userDetails];
      setNewSharedList(newValue);
    }
  };

  const onConfirmRemoveAllSharing = () => {
    setShowDeleteSharingConfirm(false);
    onSubmit({ removeAll: true });
  };

  const shareWithType = [
    {
      value: ORGANIZATION,
      key: ORGANIZATION,
      label: t('playlist.shareWithWholeOrganization'),
    },
    { value: USER, key: USER, label: t('playlist.shareWithSelectedMembers') },
    {
      value: GROUP,
      key: GROUP,
      label: t('playlist.shareWithGroups'),
      disabled: !hasGroups,
    },
  ];

  if (initialShareType === NOT_SHARED) {
    shareWithType.push({
      value: NOT_SHARED,
      key: NOT_SHARED,
      label: t('playlist.notShared'),
    });
  }

  const newAddedItems = newSharedList.filter(
    (item) => !itemIsIncluded(item.id, initialSharedWith)
  );

  const itemsToDisplay = (
    searchTerm
      ? getSearchItemsArray(
          shareType === USER ? searchUsersResult : searchGroupsResult,
          newSharedList,
          newAddedItems
        )
      : getPreviewList(newSharedList, newAddedItems)
  ).map((member) => memberParse(member));

  const sharedWithNumber = newSharedList.length;
  const searchToSelectLabel =
    shareType === USER
      ? t('playlist.searchToSelectMembers')
      : t('playlist.searchToSelectGroups');
  const searchPlaceHolder =
    shareType === USER
      ? t('playlist.searchMembersPlaceholder')
      : t('playlist.searchGroupsPlaceholder');
  const sharedWithLabel =
    shareType === USER
      ? t('playlist.sharedWithNMembers', { count: sharedWithNumber })
      : t('playlist.sharedWithNGroups', { count: sharedWithNumber });

  const actions = [
    {
      ariaLabel: t('playlist.removeAllSharing'),
      disabled: shareType === NOT_SHARED || submitting || loading,
      handler: () => setShowDeleteSharingConfirm(true),
      label: t('playlist.removeAllSharing'),
      type: 'secondaryOutline',
    },
    {
      ariaLabel: t('generic.save'),
      handler: onSubmit,
      label: t('generic.save'),
      submit: true,
      disabled: submitting || loading,
    },
  ];

  const sharingOptionsListContent = [
    t('playlist.sharingOptionsSelectedMembers'),
    t('playlist.sharingOptionsGroups'),
    t('playlist.sharingOptionsOrganization'),
  ];

  return (
    <Dialog
      open
      onClose={onClose}
      ariaLabel="share-playlist-dialog-title"
      title={t('playlist.titleSharePlaylist')}
      loading={submitting}
      actions={actions}
      contentPadding="0"
      scroll="body"
      formStatus={formStatus}
    >
      {formStatus && (
        <SideSpaceWrapper>
          <Alert
            message={formStatus.message}
            severity={formStatus.success ? 'success' : 'error'}
            theme="light"
          />
        </SideSpaceWrapper>
      )}
      {loading && <CircularSpinner static />}
      {!formStatus && !loading && (
        <>
          <SideSpaceWrapper>
            <T color={theme.palette.darkDistinct}>{`"${playlistName}"`}</T>
          </SideSpaceWrapper>
          <Spacer />
          <Form onSubmit={onSubmit}>
            <SideSpaceWrapper>
              <FormSelect
                name="shareType"
                id="shareType"
                label={t('playlist.sharingOptions')}
                hasAction
                hasBackground
                items={shareWithType}
                onChange={handleShareTypeChange}
                value={shareType}
                disabled={submitting}
                required
                theme="light"
              />
            </SideSpaceWrapper>
            <Spacer />
            {shareType === ORGANIZATION && (
              <SideSpaceWrapper>
                <T
                  color={theme.palette.darkDistinct}
                >{`${totalOrganizationMembers} ${t(
                  'playlist.membersInTheOrganization'
                )}`}</T>
                <Spacer />
              </SideSpaceWrapper>
            )}
            {shareType !== NOT_SHARED && (
              <SideSpaceWrapper>
                <FormToggle
                  id="notifyNewMembers"
                  label={t('playlist.notifyNewMembers')}
                  name="notifyNewMembers"
                  handleChange={({ value }) => setNotifyNew(value)}
                  theme="light"
                  values={{ notifyNewMembers: notifyNew }}
                />
              </SideSpaceWrapper>
            )}
            {(shareType === USER || shareType === GROUP) && (
              <>
                <Spacer />
                <SideSpaceWrapper>
                  <T color={theme.palette.darkDistinct} fontWeight="semibold">
                    {searchToSelectLabel}
                  </T>
                  <SearchField
                    theme="light"
                    ariaLabel={searchPlaceHolder}
                    onChange={onChangeSearch}
                    placeholder={searchPlaceHolder}
                    value={searchTerm}
                    onClear={() => setSearchTerm('')}
                    disabled={submitting || searchLoading}
                  />
                  <Spacer />
                </SideSpaceWrapper>
                <SideSpaceWrapper>
                  <T color={theme.palette.darkDistinct}>{sharedWithLabel}</T>
                </SideSpaceWrapper>
                {itemsToDisplay.length === 0 ? (
                  <EmptyListWrapper height="300px">
                    <Icon
                      color={theme.palette.disabledLight}
                      icon={SearchIcon}
                      size="8rem"
                      sx={{
                        transform: 'rotate(90deg)',
                      }}
                    />
                    <T color={theme.palette.disabledLight}>
                      {searchToSelectLabel}
                    </T>
                  </EmptyListWrapper>
                ) : (
                  <ListWrapper>
                    <ListItems
                      items={itemsToDisplay}
                      onSelect={toggleUser}
                      secondaryActionLabel={t('generic.justAddedText')}
                      selected={newSharedList.map((member) => member.id)}
                    />
                  </ListWrapper>
                )}
              </>
            )}
            {shareType === NOT_SHARED && (
              <NotSharedWrapper>
                <NotSharedInnerWrapper>
                  <ShareIconWrapper>
                    <Icon
                      color={theme.palette.lightPure}
                      icon={ShareOutlinedIcon}
                      size="3.5rem"
                    />
                  </ShareIconWrapper>
                  <T
                    color={theme.palette.darkDistinct}
                    gutterBottom
                    align="center"
                  >
                    {t('playlist.playlistIsntSharedText')}
                  </T>
                  <Spacer />
                  <T
                    color={theme.palette.darkDistinct}
                    align="center"
                    fontWeight="semibold"
                  >
                    {t('playlist.sharingOptionsText')}
                  </T>
                  <SharingOptionsList>
                    {sharingOptionsListContent.map((item) => (
                      <li key={item}>
                        <T color={theme.palette.darkDistinct} component="span">
                          {item}
                        </T>
                      </li>
                    ))}
                  </SharingOptionsList>
                </NotSharedInnerWrapper>
              </NotSharedWrapper>
            )}
          </Form>
        </>
      )}
      <ConfirmationDialog
        ariaLabel={t('playlist.removeAllSharing')}
        open={showDeleteSharingConfirm}
        title={t('playlist.removeAllSharing')}
        description={t('playlist.removeAllSharingQuestion')}
        cancelText={t('generic.cancel')}
        confirmText={t('playlist.remove')}
        onCancel={() => setShowDeleteSharingConfirm(false)}
        onClose={() => setShowDeleteSharingConfirm(false)}
        onConfirm={onConfirmRemoveAllSharing}
      />
    </Dialog>
  );
}

SharePlaylistAndManage.propTypes = {
  formStatus: PropTypes.shape({
    success: PropTypes.bool,
    message: PropTypes.string,
  }),
  hasGroups: PropTypes.bool,
  initialSharedWith: PropTypes.array,
  initialShareType: PropTypes.oneOf([USER, ORGANIZATION, NOT_SHARED, GROUP]),
  loading: PropTypes.bool,
  searchLoading: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmitHandler: PropTypes.func.isRequired,
  playlistName: PropTypes.string,
  searchGroups: PropTypes.func.isRequired,
  searchGroupsResult: PropTypes.array.isRequired,
  searchUsers: PropTypes.func.isRequired,
  searchUsersResult: PropTypes.array.isRequired,
  totalOrganizationMembers: PropTypes.number.isRequired,
};

SharePlaylistAndManage.defaultProps = {
  formStatus: null,
  hasGroups: true,
  initialShareType: NOT_SHARED,
  initialSharedWith: [],
  playlistName: '',
  onClose: () => {},
};

export default SharePlaylistAndManage;

import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from 'shared/themes/default';
import MobileTabs from './components/MobileTabs';
import DesktopTabs from './components/DesktopTabs';

const WatchDataTabs = ({ children, ...props }) => {
  const isMobile = useMediaQuery(theme.breakpoints.mobile);
  return (
    <>
      {isMobile ? <MobileTabs {...props} /> : <DesktopTabs {...props} />}
      {children}
    </>
  );
};

WatchDataTabs.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WatchDataTabs;

import { useEffect, useState } from 'react';
import { selectTranslation } from 'shared/utils';

export default function usePlaylists({
  listPlaylistsWatchData,
  locale,
  setWatchDataError,
}) {
  const [playlistsData, setPlaylistsData] = useState([]);
  const [loadingPlaylists, setLoadingPlaylists] = useState(true);
  const [playlistsNextToken, setPlaylistsNextToken] = useState();

  const handleError = (error) => {
    console.error('Error calling listPlaylistsWatchData', error);
    setWatchDataError(true);
    setPlaylistsData([]);
    setLoadingPlaylists(false);
  };

  const fetchPlaylists = async (nextToken = null) => {
    setLoadingPlaylists(true);
    try {
      const { data, error } = await listPlaylistsWatchData(nextToken);
      if (error) return handleError(error);
      if (data) {
        const {
          listPlaylistsWatchData: { items, nextToken: newNextToken },
        } = data;

        const mappedItems = items.map((item) => ({
          ...item,
          name: selectTranslation(item.titleTranslations, '', locale, ''),
        }));

        if (!nextToken) {
          setPlaylistsData(mappedItems);
        } else {
          setPlaylistsData((playlists) => [...playlists, ...mappedItems]);
        }
        if (newNextToken) {
          setPlaylistsNextToken(newNextToken);
        } else {
          setLoadingPlaylists(false);
          setPlaylistsNextToken(null);
        }
      }
    } catch (error) {
      handleError(error);
    }

    return true;
  };

  useEffect(() => {
    if (playlistsNextToken) {
      fetchPlaylists(playlistsNextToken);
    }
  }, [playlistsNextToken]);

  return {
    allPlaylistsStats: playlistsData,
    loadingPlaylistsWatchData: loadingPlaylists,
    fetchPlaylistsWatchData: fetchPlaylists,
  };
}

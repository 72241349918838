import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from 'shared/themes/default';
import VcLogoES from 'shared/assets/VideocationLogo_es.png';
import VcLogoNO from 'shared/assets/VideocationLogo_no.png';

const LogoContainer = styled.img`
  width: 100%;
  height: 100%;
  max-width: 307px;

  ${theme.breakpoints.mobile} {
    max-width: 170px;
  }
`;

const Logo = ({ domain }) => {
  const logoAsset = domain === 'no' ? VcLogoNO : VcLogoES;

  return <LogoContainer src={logoAsset} alt="Videocation logo" />;
};
Logo.propTypes = {
  domain: PropTypes.string,
};
Logo.defaultProps = {
  domain: 'no',
};
export default Logo;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MuiAutocomplete from '@mui/material/Autocomplete';
import { useFormControl } from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMore from '@mui/icons-material/ExpandMore';

import Icon from 'shared/atoms/Icon';
import IconButton from 'shared/atoms/IconButton';
import SearchField from 'shared/atoms/SearchField';
import { BACKGROUND_THEMES } from 'shared/const';

import theme from 'shared/themes/default';

const Autocomplete = ({
  autoFocus,
  blurOnSelect,
  clearOnSelect,
  disabled: disabledProp,
  filterOptions,
  freeSolo,
  getAllOptions,
  getOptionKey,
  getOptionLabel,
  iconActions,
  isOptionEqualToValue,
  loading,
  loadingText,
  noOptionsText,
  onClear,
  onChange,
  onCreateOption,
  onExpand,
  onInputChange,
  optionProps,
  options,
  placeholder,
  preInputValue,
  preSelectedValue,
  shrinked,
  theme: themeLayout,
  type,
}) => {
  const [inputValue, setInputValue] = useState(preInputValue || '');
  const [value, setValue] = useState(preSelectedValue || null);
  const [open, setOpen] = useState(false);
  const [optionsToSelect, setOptionsToSelect] = useState(options);
  const [isShrinked, setIsShrinked] = useState(shrinked);

  const { t } = useTranslation();

  // Input component uses FormControl internally, but autocomplete does not, so this is needed for autocomplete in forms
  // The prop gets priority
  const { disabled: disabledFormControl } = useFormControl() || {};

  const disabled =
    disabledProp === undefined ? disabledFormControl : disabledProp;

  const sx = {
    '.MuiAutocomplete-list': {
      borderRadius: '6px',
    },
    '.MuiOutlinedInput-root': {
      paddingBottom: 0,
      paddingTop: 0,
    },
    '.MuiAutocomplete-clearIndicator': {
      color: 'inherit',
    },
    '.MuiAutocomplete-popupIndicator': {
      color: 'inherit',
    },
  };

  let sxPaper = {
    mt: '2px',
    boxShadow: '0px 0px 10px 2px rgba(0, 0, 0, 0.15)',
  };
  let sxListBox = {
    p: '0.5rem 0',
    fontWeight: 400,
  };

  if (type === 'primary' || (type === 'secondary' && themeLayout === 'light')) {
    sxPaper = {
      ...sxPaper,
      '.MuiAutocomplete-loading, .MuiAutocomplete-noOptions': {
        color: theme.palette.disabledDark,
      },
    };

    sxListBox = {
      ...sxListBox,
      color: `${theme.palette.mainDark} !important`,
      font: `1rem ${theme.fonts.primary}`,
      '.MuiAutocomplete-option': {
        gap: '0.5rem',
        p: '0.25rem 0.75rem',
        minHeight: 0,
        '&.Mui-focused': {
          backgroundColor: `${theme.palette.darkDistinct}`,
          color: theme.palette.lightPure,
          fontWeight: 600,
        },
      },
      '.MuiAutocomplete-option[aria-disabled="true"]': {
        color: theme.palette.disabledDark,
        opacity: 1,
      },
      '.MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: `${theme.palette.lightInteracting} !important`,
        color: theme.palette.darkDistinct,
        '&.Mui-focused': {
          backgroundColor: `${theme.palette.disabledLight} !important`,
          color: theme.palette.darkDistinct,
          fontWeight: 600,
        },
      },
    };

    if (freeSolo) {
      sxListBox.border = `1px solid ${theme.palette.disabledLight}`;
      sxListBox.borderRadius = '4px';
    } else {
      sxPaper.border = `1px solid ${theme.palette.disabledLight}`;
    }
  } else if (type === 'secondary') {
    sxPaper = {
      ...sxPaper,
      background: theme.palette.mainDark,
      '.MuiAutocomplete-loading, .MuiAutocomplete-noOptions': {
        color: theme.palette.disabledLight,
      },
    };

    sxListBox = {
      ...sxListBox,
      font: `1rem ${theme.fonts.primary}`,
      color: theme.palette.lightPure,
      '.MuiAutocomplete-option': {
        p: '0.25rem 0.75rem',
        minHeight: 0,
        '&.Mui-focused': {
          backgroundColor: `${theme.palette.lightInteracting}`,
          color: theme.palette.mainDark,
          fontWeight: 600,
        },
      },
      '.MuiAutocomplete-option[aria-disabled="true"]': {
        color: theme.palette.disabledDark,
        opacity: 1,
      },
      '.MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: `${theme.palette.darkDistinct} !important`,
        color: theme.palette.lightPure,
        '&.Mui-focused': {
          backgroundColor: `${theme.palette.disabledLight} !important`,
          color: theme.palette.darkDistinct,
          fontWeight: 600,
        },
      },
    };

    if (freeSolo) {
      sxListBox.border = `1px solid ${theme.palette.lightInteracting}`;
      sxListBox.borderRadius = '4px';
    } else {
      sxPaper.border = `1px solid ${theme.palette.lightInteracting}`;
    }
  }

  useEffect(() => {
    const loadOptions = async () => {
      const loadedOptions = await getAllOptions();
      setOptionsToSelect(loadedOptions || []);
    };

    if (open && getAllOptions && !optionsToSelect) {
      loadOptions();
    }
  }, [open]);

  useEffect(() => {
    setOptionsToSelect(options);
  }, [options]);

  useEffect(() => {
    setValue(preSelectedValue);
  }, [preSelectedValue]);

  const triggerExpanding = () => {
    setIsShrinked(false);
    onExpand && onExpand();
  };

  const triggerShrinking = () => {
    setIsShrinked(true);
    onClear && onClear();
  };

  const isLoading = loading || (open && !optionsToSelect);

  if (shrinked && isShrinked) {
    return (
      <IconButton
        onClick={triggerExpanding}
        disabled={disabled}
        icon={SearchIcon}
        sxIcon={{
          transform: 'rotate(90deg)',
        }}
        theme="light"
        type="secondary"
      />
    );
  }

  return (
    <MuiAutocomplete
      componentsProps={{ paper: { sx: sxPaper } }}
      blurOnSelect={blurOnSelect}
      disabled={disabled}
      filterOptions={
        getAllOptions || filterOptions ? undefined : (option) => option
      }
      freeSolo={freeSolo}
      fullWidth
      getOptionDisabled={(option) => option.disabled}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      inputValue={inputValue}
      ListboxProps={{ sx: sxListBox }}
      loading={isLoading}
      loadingText={loadingText || t('generic.loadingText')}
      noOptionsText={noOptionsText || t('generic.noSearchResults')}
      onChange={(event, value, reason) => {
        if (event) {
          event.preventDefault();
        }

        if (reason === 'createOption') {
          onCreateOption(value);
        } else {
          if (reason === 'clear') {
            triggerShrinking();
          }

          onChange(value);
          setValue(value);
          if (clearOnSelect) {
            setValue(null);
            setInputValue('');
          }
        }
      }}
      onClose={(e, reason) => {
        setOpen(false);
        if (shrinked && reason === 'blur') {
          triggerShrinking();
        }
      }}
      onInputChange={(event, value) => {
        if (event) {
          event.preventDefault();
        }
        onInputChange(value);
        setInputValue(value);
      }}
      onOpen={() => setOpen(true)}
      open={open}
      options={optionsToSelect || []}
      popupIcon={<ExpandMore />}
      renderInput={(params) => {
        const { inputProps, InputProps } = params;
        const { className, ...otherInputProps } = inputProps; // Avoid autocomplete classes overriding input classes
        const { endAdornment } = InputProps;
        return (
          <div ref={InputProps.ref}>
            <SearchField
              autoFocus={autoFocus}
              disabled={disabled}
              endAdornment={endAdornment}
              iconActions={iconActions}
              inputProps={otherInputProps}
              loading={isLoading}
              placeholder={placeholder || t('generic.search')}
              theme={themeLayout}
              type={type}
            />
          </div>
        );
      }}
      renderOption={(props, option) => (
        <MenuItem
          {...props}
          {...optionProps(option)}
          key={getOptionKey ? getOptionKey(option) : getOptionLabel(option)}
        >
          {option.icon && <Icon icon={option.icon} />}
          {getOptionLabel(option)}
        </MenuItem>
      )}
      value={value}
      sx={sx}
    />
  );
};

Autocomplete.propTypes = {
  autoFocus: PropTypes.bool,
  blurOnSelect: PropTypes.bool,
  clearOnSelect: PropTypes.bool,
  disabled: PropTypes.bool,
  filterOptions: PropTypes.bool,
  freeSolo: PropTypes.bool,
  getAllOptions: PropTypes.func,
  getOptionKey: PropTypes.func,
  getOptionLabel: PropTypes.func,
  iconActions: PropTypes.object,
  isOptionEqualToValue: PropTypes.func,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  noOptionsText: PropTypes.string,
  onClear: PropTypes.func,
  onChange: PropTypes.func,
  onCreateOption: PropTypes.func,
  onExpand: PropTypes.func,
  onInputChange: PropTypes.func,
  options: PropTypes.array,
  optionProps: PropTypes.func,
  placeholder: PropTypes.string,
  preInputValue: PropTypes.string,
  preSelectedValue: PropTypes.object,
  shrinked: PropTypes.bool,
  theme: PropTypes.oneOf(BACKGROUND_THEMES),
  type: PropTypes.string,
};

Autocomplete.defaultProps = {
  blurOnSelect: false,
  freeSolo: false,
  getOptionLabel: (option) => option.label,
  isOptionEqualToValue: (option, value) => option.value === value.value,
  onClear: () => {},
  onChange: () => {},
  onCreateOption: () => {},
  onExpand: () => {},
  onInputChange: () => {},
  optionProps: () => {},
  preSelectedValue: null,
  theme: 'dark',
  type: 'primary',
};

export default Autocomplete;

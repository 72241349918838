import React from 'react';
import PropTypes from 'prop-types';

import ERRORS from './errors';
import TABS from './tabs';
import useCourseLogic from './useCourseLogic';
import {
  getUploadUrl,
  startUpload,
  completeUpload,
} from '../../../graphql/mutations';

const CourseContext = React.createContext();

function CourseProvider({ children, courseId, navigate }) {
  const {
    canPublish,
    clearError,
    course,
    createOrUpdateQuiz,
    deleteCourse,
    deletePublishedCourse,
    deleteQuiz,
    errorMessage,
    errorType,
    fetchLessonsProcessingStatus,
    getFormattedTranslations,
    getGameName,
    getLanguages,
    getLanguageStatus,
    handleSave,
    hasChanged,
    hasError,
    hasDeleteError,
    hasQuiz,
    loading,
    locked,
    publish,
    saveAttachments,
    saveAvailability,
    saveCatalog,
    saveDescription,
    saveLessons,
    selectedTab,
    setHandleSave,
    setHasChanged,
    setHasError,
    setLocked,
    setSelectedTab,
    setSubmitting,
    setUploadingFiles,
    submitting,
    submittingRefresh,
    uploadingFiles,
    validationDetails,
    validateCourse,
  } = useCourseLogic({
    courseId,
    navigate,
  });

  const contextValue = {
    canPublish,
    clearError,
    completeUpload,
    course,
    createOrUpdateQuiz,
    deleteCourse,
    deletePublishedCourse,
    deleteQuiz,
    errorMessage,
    errorType,
    fetchLessonsProcessingStatus,
    getFormattedTranslations,
    getGameName,
    getLanguages,
    getLanguageStatus,
    getUploadUrl,
    handleSave,
    hasChanged,
    hasDeleteError,
    hasError,
    hasQuiz,
    loading,
    locked,
    publish,
    saveAttachments,
    saveAvailability,
    saveCatalog,
    saveDescription,
    saveLessons,
    selectedTab,
    setHandleSave,
    setHasChanged,
    setHasError,
    setLocked,
    setSelectedTab,
    setSubmitting,
    setUploadingFiles,
    startUpload,
    submitting,
    submittingRefresh,
    uploadingFiles,
    validationDetails,
    validateCourse,
  };

  return (
    <CourseContext.Provider value={contextValue}>
      {children}
    </CourseContext.Provider>
  );
}

CourseProvider.propTypes = {
  children: PropTypes.node.isRequired,
  courseId: PropTypes.string.isRequired,
  navigate: PropTypes.func.isRequired,
};

function useCourse() {
  const context = React.useContext(CourseContext);
  if (!context) {
    throw new Error('useCourse must be used within a CourseProvider');
  }
  return context;
}

export { CourseProvider, ERRORS, TABS, useCourse };

import { useEffect, useState } from 'react';

const useExperts = ({ listExperts }) => {
  const [allExperts, setAllExperts] = useState([]);
  const [nextToken, setNextToken] = useState();
  const [loadingExperts, setLoadingExperts] = useState(true);
  const [errorExperts, setErrorExperts] = useState(false);

  const handleError = (error) => {
    console.error('Error calling listAllExperts', error);
    setErrorExperts(true);
    setNextToken(null);
    setAllExperts([]);
    setLoadingExperts(false);
  };

  const fetch = async (nextToken = null) => {
    try {
      const { data, error } = await listExperts(nextToken);

      if (error) {
        console.log('Error');
      }

      const { items, nextToken: newNextToken } = data.listExperts;

      const mappedItems = items.map((item) => ({
        ...item,
        value: item.id,
        name: `${item.firstname} ${item.lastname}`,
      }));

      if (!nextToken) {
        setAllExperts(mappedItems);
      } else {
        setAllExperts([...allExperts, ...mappedItems]);
      }

      if (newNextToken) {
        setNextToken(newNextToken);
      } else {
        setLoadingExperts(false);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const fetchExperts = () => {
    setLoadingExperts(true);
    setNextToken(null);
    setAllExperts([]);
    setErrorExperts(false);
  };

  useEffect(() => {
    if (nextToken) {
      fetch(nextToken);
    }
  }, [nextToken]);

  useEffect(() => {
    listExperts && fetch();
  }, []);

  return { allExperts, loadingExperts, fetchExperts, errorExperts };
};

export default useExperts;

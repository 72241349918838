import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from 'shared/themes/default';

const Pill = styled.div`
  display: inline-block;
  font-family: ${theme.fonts.primary};
  background: ${(props) => props.color};
  color: ${(props) => props.fontColor};
  font-size: 20px;
  font-weight: 600;
  border-radius: 6px;
  padding: 10px 16px;
  ${theme.breakpoints.mobile} {
    padding: 6px 12px;
    font-size: 14px;
  }
`;

Pill.propTypes = {
  color: PropTypes.oneOf(Object.values(theme.palette)),
  fontColor: PropTypes.oneOf(Object.values(theme.palette)),
};

Pill.defaultProps = {
  color: theme.palette.mainDark,
  fontColor: theme.palette.lightPure,
};

export default Pill;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Table, { CELL_TYPES, ORDER_TYPES } from 'shared/organisms/Table';

const AllPlaylistsOverviewTable = ({
  itemsToDisplay,
  selectPlaylistHandler,
}) => {
  const { t } = useTranslation();

  const columns = [
    {
      key: 'name',
      label: t('watchData.playlistName'),
      sticky: true,
      fixedWidth: '30%',
    },
    {
      key: 'ownerName',
      label: t('watchData.owner'),
      fixedWidth: '25%',
      maskValue: true,
    },
    { key: 'coursesCount', label: t('watchData.courses') },
    { key: 'viewsCount', label: t('watchData.views') },
    { key: 'completedCount', label: t('watchData.completed') },
    {
      key: 'averageCompletion',
      label: t('watchData.averageCompletion'),
      type: CELL_TYPES.COMPONENT,
      render: (row) =>
        t('watchData.percentage', { percentage: row.averageCompletion }),
    },
  ];

  return (
    <Table
      ariaLabel={t('watchData.activitiesOfYourPlaylists')}
      columns={columns}
      items={itemsToDisplay}
      defaultOrder={ORDER_TYPES.DESC}
      defaultOrderBy="averageCompletion"
      onRowClick={(row) => selectPlaylistHandler(row)}
    />
  );
};

AllPlaylistsOverviewTable.propTypes = {
  itemsToDisplay: PropTypes.array.isRequired,
  selectPlaylistHandler: PropTypes.func.isRequired,
};

export default AllPlaylistsOverviewTable;

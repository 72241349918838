import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ReplayIcon from '@mui/icons-material/Replay';

import Dialog from '@shared/molecules/Dialog';
import FormInput from '@shared/atoms/FormFields/FormInput';
import IconButton from '@shared/atoms/IconButton';
import Tooltip from '@shared/atoms/Tooltip';
import T from '@shared/atoms/Typography';

import { selectTranslation } from '@shared/utils';
import theme from '@shared/themes/default';

import { COURSE_LEVELS, courseStatus, MAX_COURSE } from '@shared/const';

import { LEVEL_FIELD_TYPE } from '../const';

import { useCourse } from '../../../../../contexts/CourseContext';

const EditFieldWrapper = styled.div`
  column-gap: ${theme.spacing.s};
  display: flex;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
  row-gap: ${theme.spacing.s};
`;
const IconsWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 28px;
  padding-top: 28px;
`;

const EditField = ({
  course,
  enabledLanguages,
  setCourse,
  selectedLanguage,
  type,
}) => {
  const { getFormattedTranslations, locked, submitting } = useCourse();

  const getField = () => {
    let field;
    switch (type) {
      case LEVEL_FIELD_TYPE.PREREQUISITES:
        field = 'prerequisitesTranslations';
        break;
      case LEVEL_FIELD_TYPE.TYPICAL_LEARNER:
        field = 'typicalLearnerTranslations';
        break;
      default:
        break;
    }

    return field;
  };

  const { t } = useTranslation();
  const field = getField();
  const isPublished = course.status === courseStatus.PUBLISHED;
  const isLocked =
    submitting ||
    (isPublished && locked) ||
    !enabledLanguages[selectedLanguage];

  const [fieldContent, setFieldContent] = useState('');
  const [isInput, setIsInput] = useState(false);
  const [inputValue, setInputValue] = useState(
    selectTranslation(course[field], '', selectedLanguage, '')
  );
  const [text, setText] = useState('');
  const [title, setTitle] = useState('');
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const field = getField();
    setFieldContent(course[field]);
  }, [course, type]);

  useEffect(() => {
    const formattedField = getFormattedTranslations(fieldContent);
    setIsInput(selectedLanguage in formattedField);
  }, [fieldContent, selectedLanguage]);

  useEffect(() => {
    let title;
    let text;
    let levelType;

    switch (type) {
      case LEVEL_FIELD_TYPE.PREREQUISITES:
        title = t('backoffice.courses.descriptionTab.prerequisites');
        levelType = 'prerequisites';
        break;
      case LEVEL_FIELD_TYPE.TYPICAL_LEARNER:
        title = t('backoffice.courses.descriptionTab.typicalLearner');
        levelType = 'typicalLearners';
        break;
      default:
        break;
    }

    if (!course.level) {
      text = t('backoffice.courses.descriptionTab.noCourseLevel');
    } else if (isInput) {
      text = selectTranslation(fieldContent, '', selectedLanguage, '');
    } else {
      text = t(`course.levels.${course.level}.${levelType}`);
    }

    setTitle(title);
    setText(text);
  }, [
    course.level,
    course.prerequisites,
    course.typicalLearner,
    fieldContent,
    isInput,
    isLocked,
    selectedLanguage,
    type,
  ]);

  const editHandler = () => {
    const formattedField = getFormattedTranslations(fieldContent);
    formattedField[selectedLanguage] = '';

    setCourse({
      ...course,
      [field]: JSON.stringify(formattedField),
    });
  };

  const restoreHandler = () => {
    setOpenDialog(true);
  };

  const restoreConfirmHandler = () => {
    setInputValue('');
    setCourse({
      ...course,
      [field]: null,
    });
    setOpenDialog(false);
  };

  const restoreCancelHandler = () => {
    setOpenDialog(false);
  };

  const changeHandler = ({ value }) => {
    const formattedField = getFormattedTranslations(fieldContent);
    formattedField[selectedLanguage] = value;

    setInputValue(value);

    setCourse({
      ...course,
      [field]: JSON.stringify(formattedField),
    });
  };

  return (
    <>
      <EditFieldWrapper>
        {isInput ? (
          <>
            <ContentWrapper>
              <FormInput
                disabled={isLocked}
                handleChange={changeHandler}
                id={`levelText-${field}`}
                label={title}
                minRows={2}
                multiline
                name="levelText"
                properties={{
                  levelText: {
                    maxLength: MAX_COURSE.PREREQUISITES_TYPICAL_LENGTH,
                  },
                }}
                required
                showMaxLength
                theme="dark"
                type="primary"
                value={inputValue}
              />
            </ContentWrapper>
            <IconsWrapper>
              {!isLocked && course.level && (
                <Tooltip
                  placement="right"
                  title={t('backoffice.courses.descriptionTab.restoreDefault')}
                >
                  <IconContainer>
                    <IconButton
                      ariaLabel={t(
                        'backoffice.courses.descriptionTab.restoreDefault'
                      )}
                      icon={ReplayIcon}
                      onClick={restoreHandler}
                      small
                      theme="dark"
                      type="icon"
                    />
                  </IconContainer>
                </Tooltip>
              )}
            </IconsWrapper>
          </>
        ) : (
          <>
            <ContentWrapper>
              <T
                color={theme.palette.lightPure}
                fontWeight="medium"
                variant="paragraph"
              >
                {title}
              </T>
              <T
                color={theme.palette.lightPure}
                fontWeight="regular"
                sx={{ minHeight: '56px' }}
                variant="paragraph"
              >
                {text}
              </T>
            </ContentWrapper>
            <IconsWrapper>
              {!isLocked && course.level && (
                <Tooltip
                  placement="right"
                  title={t('backoffice.courses.descriptionTab.changeDefault')}
                >
                  <IconContainer>
                    <IconButton
                      ariaLabel={t(
                        'backoffice.courses.descriptionTab.changeDefault'
                      )}
                      icon={ModeEditIcon}
                      onClick={editHandler}
                      small
                      theme="dark"
                      type="icon"
                    />
                  </IconContainer>
                </Tooltip>
              )}
            </IconsWrapper>
          </>
        )}
      </EditFieldWrapper>
      <Dialog
        actions={[
          {
            ariaLabel: t('generic.cancel'),
            handler: restoreCancelHandler,
            label: t('generic.cancel'),
            type: 'secondaryOutline',
          },
          {
            ariaLabel: t(
              'backoffice.courses.descriptionTab.setToDefault.setToDefault'
            ),
            handler: restoreConfirmHandler,
            label: t(
              'backoffice.courses.descriptionTab.setToDefault.setToDefault'
            ),
          },
        ]}
        ariaLabel="level dialog"
        open={openDialog}
        onClose={restoreCancelHandler}
        title={t('backoffice.courses.descriptionTab.setToDefault.title')}
      >
        <T color={theme.palette.pureDark} variant="paragraph">
          {t(`backoffice.courses.descriptionTab.setToDefault.text`, {
            type: LEVEL_FIELD_TYPE.PREREQUISITES
              ? t(
                  'backoffice.courses.descriptionTab.setToDefault.prerequisites'
                )
              : t(
                  'backoffice.courses.descriptionTab.setToDefault.typicalLearner'
                ),
          })}
        </T>
      </Dialog>
    </>
  );
};

EditField.propTypes = {
  course: PropTypes.shape({
    level: PropTypes.oneOf(Object.values(COURSE_LEVELS)),
    prerequisites: PropTypes.string,
    status: PropTypes.oneOf(Object.values(courseStatus)),
    typicalLearner: PropTypes.string,
  }).isRequired,
  enabledLanguages: PropTypes.object.isRequired,
  setCourse: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(LEVEL_FIELD_TYPE)).isRequired,
};

export default EditField;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Alert from '@shared/atoms/Alert';
import T from '@shared/atoms/Typography';
import Dialog from '@shared/molecules/Dialog';
import { formatDate, selectTranslation } from '@shared/utils';
import theme from '@shared/themes/default';

import CircularSpinner from '@shared/atoms/CircularSpinner';
import { useAppGlobals } from '../../../contexts/AppContext';
import { useCourse } from '../../../contexts/CourseContext';

import { validateCourseForArchiving } from './queries';
import { useGraphQLQuery } from '../../../../utils';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m1};
`;

const PublishedDeleteDialog = ({ handleDelete, open, setOpen }) => {
  const { locale } = useAppGlobals();
  const { course } = useCourse();
  const { t } = useTranslation();

  const { callQuery, data, hasEnded, hasErrors, loading } = useGraphQLQuery();

  useEffect(() => {
    if (open) {
      callQuery(validateCourseForArchiving(course.id));
    }
  }, [open]);

  let actions;
  let content;

  if (loading) {
    actions = [
      {
        ariaLabel: t('backoffice.courses.deleting.cancel'),
        handler: () => setOpen(false),
        label: t('backoffice.courses.deleting.cancel'),
        sx: { width: '100%' },
        type: 'secondaryOutline',
      },
    ];
    content = (
      <ContentWrapper>
        <T
          color={theme.palette.mainDark}
          fontWeight="semibold"
          sx={{ textAlign: 'center' }}
          variant="headingXs"
        >
          {t('backoffice.courses.deleting.checkUsedCourse')}
        </T>
        <CircularSpinner static size={50} />
      </ContentWrapper>
    );
  } else if (hasEnded) {
    if (hasErrors) {
      actions = [
        {
          ariaLabel: t('backoffice.courses.deleting.back'),
          handler: () => setOpen(false),
          label: t('backoffice.courses.deleting.back'),
        },
      ];
      content = (
        <Alert
          message={t('backoffice.courses.deleting.errorCheckUsedCourse')}
          severity="error"
          theme="light"
        />
      );
    } else {
      const {
        validateCourseForArchiving: {
          archiveDate,
          nanodegrees,
          numberOfPlaylists,
          isGloballyFeatured,
          isValidForArchiving,
        },
      } = data;

      if (isValidForArchiving) {
        actions = [
          {
            ariaLabel: t('backoffice.courses.deleting.confirm'),
            handler: handleDelete,
            label: t('backoffice.courses.deleting.confirm'),
            type: 'secondaryOutline',
          },
          {
            ariaLabel: t('backoffice.courses.deleting.discard'),
            handler: () => setOpen(false),
            label: t('backoffice.courses.deleting.discard'),
          },
        ];

        if (numberOfPlaylists > 0) {
          content = (
            <ContentWrapper>
              <T
                color={theme.palette.mainDark}
                fontWeight="semibold"
                sx={{ textAlign: 'center' }}
                variant="headingXs"
              >
                {t('backoffice.courses.deleting.deleteInPlaylists', {
                  courseName: selectTranslation(
                    course.nameTranslations,
                    course.name,
                    locale,
                    ''
                  ),
                  count: numberOfPlaylists,
                })}
              </T>
              <div>
                <T color={theme.palette.mainDark}>
                  {t('backoffice.courses.deleting.partOfPlaylists', {
                    count: numberOfPlaylists,
                  })}
                </T>
              </div>
              <div>
                <T color={theme.palette.mainDark}>
                  {t(
                    'backoffice.courses.deleting.deleteInPlaylistsDescription',
                    {
                      count: numberOfPlaylists,
                      date: formatDate({
                        date: archiveDate,
                      }),
                    }
                  )}
                </T>
              </div>
              <div>
                <T color={theme.palette.mainDark}>
                  {t('backoffice.courses.deleting.countdown')}
                </T>
              </div>
              <div>
                <T color={theme.palette.mainDark} fontWeight="bold">
                  {t('backoffice.courses.deleting.cannotBeUndone')}
                </T>
                <T color={theme.palette.mainDark} fontWeight="bold">
                  {t('backoffice.courses.deleting.areYouSure')}
                </T>
              </div>
            </ContentWrapper>
          );
        } else {
          content = (
            <ContentWrapper>
              <T
                color={theme.palette.mainDark}
                fontWeight="semibold"
                sx={{ textAlign: 'center' }}
                variant="headingXs"
              >
                {t('backoffice.courses.deleting.deletePublished', {
                  courseName: selectTranslation(
                    course.nameTranslations,
                    course.name,
                    locale,
                    ''
                  ),
                })}
              </T>
              <div>
                <T color={theme.palette.mainDark}>
                  {t('backoffice.courses.deleting.deletePublishedDescription', {
                    date: formatDate({
                      date: archiveDate,
                    }),
                  })}
                </T>
              </div>
              <div>
                <T color={theme.palette.mainDark} fontWeight="bold">
                  {t('backoffice.courses.deleting.cannotBeUndone')}
                </T>
                <T color={theme.palette.mainDark} fontWeight="bold">
                  {t('backoffice.courses.deleting.areYouSure')}
                </T>
              </div>
            </ContentWrapper>
          );
        }
      } else {
        actions = [
          {
            ariaLabel: t('backoffice.courses.deleting.back'),
            handler: () => setOpen(false),
            label: t('backoffice.courses.deleting.back'),
          },
        ];

        if (nanodegrees.length > 0 && isGloballyFeatured) {
          content = (
            <ContentWrapper>
              <T
                color={theme.palette.mainDark}
                fontWeight="semibold"
                sx={{ textAlign: 'center' }}
                variant="headingXs"
              >
                {t('backoffice.courses.deleting.cannotDelete')}
              </T>
              <div>
                <T color={theme.palette.mainDark}>
                  {t('backoffice.courses.deleting.removedFrom')}
                </T>
                <ul>
                  <li>
                    <T color={theme.palette.mainDark}>
                      {t('backoffice.courses.deleting.promotedCourse')}
                    </T>
                  </li>
                  <li>
                    <T color={theme.palette.mainDark}>
                      {t('backoffice.courses.deleting.inNanodegrees', {
                        count: nanodegrees.length,
                      })}
                    </T>
                    <ul>
                      {nanodegrees.map((nanodegree) => (
                        <li>
                          <T color={theme.palette.mainDark}>
                            {selectTranslation(
                              nanodegree.titleTranslations,
                              null,
                              locale,
                              ''
                            )}
                          </T>
                        </li>
                      ))}
                    </ul>
                  </li>
                </ul>
                <T color={theme.palette.mainDark}>
                  {t('backoffice.courses.deleting.removedFromBefore')}
                </T>
              </div>
            </ContentWrapper>
          );
        } else if (nanodegrees.length > 0) {
          content = (
            <ContentWrapper>
              <div>
                <T
                  color={theme.palette.mainDark}
                  fontWeight="semibold"
                  sx={{ textAlign: 'center' }}
                  variant="headingXs"
                >
                  {t('backoffice.courses.deleting.cannotDelete')}
                </T>
                <T
                  color={theme.palette.mainDark}
                  fontWeight="semibold"
                  sx={{ textAlign: 'center' }}
                  variant="headingXs"
                >
                  {t('backoffice.courses.deleting.cannotDeleteNanodegrees')}
                </T>
              </div>
              <div>
                <T color={theme.palette.mainDark}>
                  {t('backoffice.courses.deleting.partOfNanodegrees', {
                    count: nanodegrees.length,
                  })}
                </T>
                <ul>
                  {nanodegrees.map((nanodegree) => (
                    <li>
                      <T color={theme.palette.mainDark}>
                        {selectTranslation(
                          nanodegree.titleTranslations,
                          null,
                          locale,
                          ''
                        )}
                      </T>
                    </li>
                  ))}
                </ul>
                <T color={theme.palette.mainDark}>
                  {t('backoffice.courses.deleting.removeNanodegrees', {
                    count: nanodegrees.length,
                  })}
                </T>
              </div>
            </ContentWrapper>
          );
        } else if (isGloballyFeatured) {
          content = (
            <ContentWrapper>
              <div>
                <T
                  color={theme.palette.mainDark}
                  fontWeight="semibold"
                  sx={{ textAlign: 'center' }}
                  variant="headingXs"
                >
                  {t('backoffice.courses.deleting.cannotDelete')}
                </T>
                <T
                  color={theme.palette.mainDark}
                  fontWeight="semibold"
                  sx={{ textAlign: 'center' }}
                  variant="headingXs"
                >
                  {t('backoffice.courses.deleting.cannotDeletePromoted')}
                </T>
              </div>
              <T color={theme.palette.mainDark}>
                {t('backoffice.courses.deleting.removedFromPromoted')}
              </T>
            </ContentWrapper>
          );
        }
      }
    }
  }

  return (
    <Dialog
      actions={actions}
      ariaLabel={t('backoffice.courses.deleting.deleteCourse')}
      onClose={() => setOpen(false)}
      open={open}
      sx={{ '.MuiDialogContent-root': { overflowY: 'unset' } }}
      title={loading ? null : t('backoffice.courses.deleting.deleteCourse')}
    >
      {content}
    </Dialog>
  );
};

PublishedDeleteDialog.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default PublishedDeleteDialog;

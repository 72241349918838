export const addFeaturedItem = (input) => ({
  query: `mutation addFeaturedItem(
    $input: AddFeaturedItemInput!
  ) {
    addFeaturedItem(input: $input){
      id
      organizationId
      domain
      course {
        id
        name
        nameTranslations
      }
    }
  }`,
  variables: {
    input,
  },
});

export const removeFeaturedItem = (input) => ({
  query: `mutation removeFeaturedItem(
    $input: RemoveFeaturedItemInput!
  ) {
    removeFeaturedItem(input: $input) {
      id
    }
  }`,
  variables: {
    input,
  },
});

export const updateDomain = (input) => ({
  query: `mutation updateDomain(
    $input: DomainInput!
  ) {
    updateDomain(input: $input) {
      domain
      name
      metatag
      currency
      languages
      defaultLanguage
    }
  }`,
  variables: {
    input,
  },
});

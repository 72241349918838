import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Chip from 'shared/atoms/Chip';
import CircularSpinner from 'shared/atoms/CircularSpinner';

import { courseStatus } from 'shared/const';

import Table, { CELL_TYPES, ORDER_TYPES } from 'shared/organisms/Table';

import { useWatchData } from 'shared/sections/WatchData/Context';

import { selectTranslation } from 'shared/utils';
import theme from 'shared/themes/default';

const SpinnerWrapper = styled.div`
  padding-top: ${theme.spacing.m2};
`;

const InfoWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${theme.spacing.s};
`;

const PlaylistWatchDataTableRowItem = ({
  itemId: userId,
  open,
  playlistId,
}) => {
  const { t } = useTranslation();
  const [watchHistory, setWatchHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  const { getUserPlaylistWatchData, locale, setWatchDataError } =
    useWatchData();

  const hasWatchHistory = watchHistory?.length > 0;

  const handleError = (error) => {
    console.error('Error calling getUserPlaylistWatchData', error);
    setWatchHistory([]);
    setLoading(false);
    setWatchDataError(true);
  };

  const fetchUsersPlaylistWatchHistory = async (userId, playlistId) => {
    try {
      const { data, error } = await getUserPlaylistWatchData(
        userId,
        playlistId
      );
      if (error) return handleError(error);
      const {
        getUserPlaylistWatchData: { items },
      } = data;
      setWatchHistory(
        items.map((course) => ({
          ...course,
          courseTitle: selectTranslation(
            course.nameTranslations,
            '',
            locale,
            ''
          ),
        }))
      );
      setLoading(false);
    } catch (error) {
      handleError(error);
    }
    return true;
  };

  useEffect(() => {
    if (open && !hasWatchHistory) {
      setLoading(true);
      fetchUsersPlaylistWatchHistory(userId, playlistId);
    }
  }, [open]);

  const columns = [
    {
      key: 'courseTitle',
      label: t('watchData.courses'),
      expandedSticky: true,
      fixedWidth: '43%',
      type: CELL_TYPES.COMPONENT,
      render: (row) => (
        <InfoWrapper>
          {row.courseTitle}
          {row.status === courseStatus.ARCHIVED && (
            <Chip
              color={theme.palette.errorLight}
              label={t('watchData.deleted')}
              small
              theme="dark"
            />
          )}
        </InfoWrapper>
      ),
    },
    {
      key: 'episodesCompleted',
      label: t('watchData.completedEpisodes'),
      type: CELL_TYPES.COMPONENT,
      render: (row) =>
        t('watchData.iOutOfJ', {
          i: row.episodesCompleted,
          j: row.episodesCount,
        }),
    },
    {
      key: 'firstTimeWatched',
      label: t('watchData.courseStart'),
      type: CELL_TYPES.DATE,
    },
    {
      key: 'completedTime',
      label: t('watchData.completionDate'),
      type: CELL_TYPES.DATE,
    },
    {
      key: 'certificationTime',
      label: t('watchData.certificationDate'),
      type: CELL_TYPES.DATE,
    },
  ];

  return loading ? (
    <SpinnerWrapper>
      <CircularSpinner static />
    </SpinnerWrapper>
  ) : (
    hasWatchHistory && (
      <Table
        ariaLabel="Expandable watch data row inner content"
        columns={columns}
        items={watchHistory}
        keyField="nameTranslations" // We don't receive the ID from the backend, although it's in the Schema
        defaultOrder={ORDER_TYPES.ASC}
        defaultOrderBy="courseTitle"
        isExpandedType
        isExpandedOpen={open}
      />
    )
  );
};

PlaylistWatchDataTableRowItem.propTypes = {
  itemId: PropTypes.string.isRequired,
  playlistId: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

PlaylistWatchDataTableRowItem.defaultProps = {
  open: false,
};

export default PlaylistWatchDataTableRowItem;

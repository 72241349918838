import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Table, { CELL_TYPES, ORDER_TYPES } from 'shared/organisms/Table';

import PlaylistGroupsWatchDataTableRowItem from './components/PlaylistGroupsWatchDataTableRowItem';

const PlaylistGroupsWatchDataTable = ({
  itemsToDisplay,
  handleReminder,
  playlistId,
  isManager,
  showReminder,
}) => {
  const { t } = useTranslation();
  const [selectedItemIds, setSelectedItemIds] = useState([]);

  const columns = [
    {
      key: 'name',
      label: t('administrateUsers.groupName'),
      sticky: true,
      fixedWidth: '23%',
    },
    {
      key: 'sharedAt',
      label: t('watchData.shared'),
      type: CELL_TYPES.DATE,
    },
    {
      key: 'membersCount',
      label: t('watchData.users'),
    },
  ];

  if (!isManager) {
    columns.splice(1, 0, {
      key: 'ownedByName',
      label: t('watchData.owner'),
      fixedWidth: '28%',
      maskValue: true,
    });
  }

  const toggleShowExpandedGroup = (id) => {
    if (selectedItemIds.includes(id)) {
      setSelectedItemIds((items) => items.filter((itemId) => id !== itemId));
    } else {
      setSelectedItemIds((items) => [...items, id]);
    }
  };

  return (
    <Table
      ariaLabel={t('watchData.activitiesOfYourGroups')}
      columns={columns}
      items={itemsToDisplay}
      defaultOrder={ORDER_TYPES.ASC}
      defaultOrderBy="name"
      rowIsClickable={() => true}
      selectedItemIds={selectedItemIds}
      onRowClick={(row) => toggleShowExpandedGroup(row.id)}
      ExpandedItemComponent={PlaylistGroupsWatchDataTableRowItem}
      expandedItemComponentProps={{ playlistId, handleReminder, showReminder }}
    />
  );
};

PlaylistGroupsWatchDataTable.propTypes = {
  itemsToDisplay: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      ownedByName: PropTypes.string.isRequired,
      sharedAt: PropTypes.string,
      membersCount: PropTypes.number.isRequired,
    })
  ).isRequired,
  handleReminder: PropTypes.func.isRequired,
  playlistId: PropTypes.string.isRequired,
  isManager: PropTypes.bool,
  showReminder: PropTypes.bool,
};

export default PlaylistGroupsWatchDataTable;

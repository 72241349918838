import palette from './colorPalette';

const theme = {};

const hexToRgb = (hex) => {
  // http://stackoverflow.com/a/5624139
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const replacedHex = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(replacedHex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

theme.utils = {
  rgba: (hex, alpha) => {
    const color = hexToRgb(hex);
    return `rgba(${color.r}, ${color.g}, ${color.b}, ${alpha})`;
  },
};

theme.palette = palette;

theme.fonts = {
  primary: 'proxima-nova, sans-serif',
  pre: 'Consolas, Liberation Mono, Menlo, Courier, monospace',
  quote: 'Georgia, serif',
};

theme.sizes = {
  DSMaxWidth: '80rem' /* 1280px */,
  header: '3.875rem' /* 62px */,
};

theme.spacing = {
  xs: '0.25rem', // 4px
  s: '0.5rem', // 8px
  m1: '1rem', // 16px
  m2: '1.5rem', // 24px
  m3: '2rem', // 32px
  l: '3rem', // 48px
  xl: '4rem', // 64px
  '2xl': '6rem', // 96px
  '3xl': '8rem', // 128px
};

theme.breakpoints = {
  mobile: '@media screen and (max-width: 767px)',
  tablet: '@media screen and (max-width: 1023px)',
  tabletOnly: '@media screen and (min-width: 768px) and (max-width: 1023px)',
  desktop: '@media screen and (min-width: 1024px)',
};

theme.media = {
  narrowPhone: '@media (max-width: 321px)',
  w500: '@media (max-width: 500px)',
};

export default theme;

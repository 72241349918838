import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import 'react-day-picker/style.css';

import { DayPicker } from 'react-day-picker';
import Popover from '@mui/material/Popover';

import Button from '@shared/atoms/Button';
import theme from '@shared/themes/default';

const Wrapper = styled.div`
  border-radius: 6px;
`;

const InnerWrapper = styled.div`
  padding: ${theme.spacing.l};
  display: flex;
  flex-direction: column;
  align-items: end;
`;

const StyledDayPicker = styled(DayPicker)`
  font-family: ${theme.fonts.primary};
  .rdp-weeks {
    font-family: ${theme.fonts.primary};
  }

  .rdp-chevron {
    fill: ${theme.palette.mainDark};
  }

  .rdp-day_button {
    &:hover {
      border-radius: 8px;
      background: ${theme.palette.disabledLight};
    }
  }

  .rdp-selected > .rdp-day_button {
    background: ${theme.palette.brand};
    border: 1px solid ${theme.palette.disabledDark};
    border-radius: 8px;
    color: ${theme.palette.mainDark};
    font-weight: 400;
    font-family: ${theme.fonts.primary};
    font-size: ${theme.spacing.m1};
    &:focus {
      border: 2px solid ${theme.palette.focus};
    }
  }

  .rdp-outside > .rdp-day_button {
    color: ${theme.palette.disabledLight};
  }

  .rdp-today > .rdp-day_button {
    border: 1px solid ${theme.palette.disabledDark};
    border-radius: 8px;
    color: ${theme.palette.mainDark};
    font-weight: 600;
  }
`;

const DatePicker = ({ anchorEl, handleClose, handleSubmit, value }) => {
  const [selected, setSelected] = useState(value);

  const { t } = useTranslation();

  useEffect(() => {
    if (value) {
      setSelected(new Date(value));
    }
  }, [value]);

  const onSelect = (date) => {
    setSelected(date ?? undefined);
  };

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      onClose={handleClose}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Wrapper>
        <InnerWrapper>
          <StyledDayPicker
            mode="single"
            onSelect={onSelect}
            selected={selected}
            showOutsideDays
            timeZone="UTC"
            weekStartsOn={1}
          />
          <Button
            theme="light"
            onClick={() => handleSubmit(selected)}
            disabled={!selected}
          >
            {t('backoffice.organizations.subscription.selectDate')}
          </Button>
        </InnerWrapper>
      </Wrapper>
    </Popover>
  );
};

DatePicker.propTypes = {
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  value: PropTypes.any,
};

export default DatePicker;

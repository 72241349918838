export const createCategory = (input) => ({
  name: 'createCategory',
  query: `mutation createCategory(
    $input: CreateCategoryInput!
  ) {
    createCategory(input: $input) {
      id
      shortId
      domain
      title
      titleTranslations
      description
      descriptionTranslations
      ordinal
      expand
    }
  }`,
  variables: {
    input,
  },
});

export const updateCategory = (input) => ({
  name: 'updateCategory',
  query: `mutation updateCategory(
    $input: UpdateCategoryInput!
  ) {
    updateCategory(input: $input) {
      id
      domain
      title
      titleTranslations
      description
      descriptionTranslations
      ordinal
      expand
    }
  }`,
  variables: {
    input,
  },
});

export const deleteCategory = (id) => ({
  name: 'deleteCategory',
  query: `mutation deleteCategory(
    $input: DeleteCategoryInput!
  ) {
    deleteCategory(input: $input) {
      id
    }
  }`,
  variables: {
    input: {
      id,
    },
  },
});

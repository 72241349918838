import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CircularSpinner from '@shared/atoms/CircularSpinner';
import Table, { ORDER_TYPES } from '@shared/organisms/Table';
import T from '@shared/atoms/Typography';
import { logger } from '@shared/utils';

import { listUserMemberships } from '../../graphql/queries';
import { useGraphQLQuery } from '../../../../../utils';

const MembershipsTab = ({ loadingMove, userSub }) => {
  const { t } = useTranslation();

  const [memberships, setMemberships] = useState([]);

  const { callQuery, data, errors, hasEnded, hasErrors, loading } =
    useGraphQLQuery();

  useEffect(() => {
    if (hasEnded) {
      if (hasErrors) {
        logger.error('Error: ', errors);
      } else {
        const {
          listUserMemberships: { items },
        } = data;
        setMemberships(
          items.map(({ organization, ...item }) => ({
            ...item,
            organizationName: organization.name,
          }))
        );
      }
    }
  }, [hasEnded]);

  useEffect(() => {
    if (!loadingMove) {
      callQuery(listUserMemberships(userSub));
    }
  }, [loadingMove]);

  return (
    <>
      {loading && <CircularSpinner static />}
      {memberships.length > 0 && !loading && (
        <Table
          ariaLabel="Memberships table"
          columns={[
            {
              key: 'organizationName',
              label: t('backoffice.users.organization'),
            },
            {
              key: 'roleId',
              label: t('backoffice.users.role'),
            },
          ]}
          items={memberships}
          defaultOrder={ORDER_TYPES.ASC}
          defaultOrderBy="organizationName"
        />
      )}
      {memberships.length === 0 && !loading && (
        <T>{t('backoffice.users.noMembershipsFound')}</T>
      )}
    </>
  );
};

MembershipsTab.propTypes = {
  loadingMove: PropTypes.bool.isRequired,
  userSub: PropTypes.string.isRequired,
};

export default MembershipsTab;

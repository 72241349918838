import { DOMAIN_LOCALES } from '@shared/const';

export const environment = import.meta.env.MODE;

export const getDomainName = () => {
  const { hostname } = window.location;

  const domain = hostname.split('.').pop();

  return domain in DOMAIN_LOCALES ? domain : 'no';
};

export const getLocale = () => {
  // Not allowing locale change in backoffice for now
  const domain = getDomainName();

  return DOMAIN_LOCALES[domain][0];
};

export const getStaticImagesHostname = () => {
  const domain = getDomainName();

  switch (domain) {
    case 'es':
      return import.meta.env.VITE_STATIC_IMAGES_HOSTNAME_ES;
    case 'no':
    default:
      return import.meta.env.VITE_STATIC_IMAGES_HOSTNAME_NO;
  }
};

// Feature flags (empty)

// Which features are enabled on which environment

const DEVELOPMENT_FEATURES = [];
const PRODUCTION_FEATURES = [];

export const isFeatureEnabled = (featureName) => {
  switch (environment) {
    case 'production':
      return PRODUCTION_FEATURES.includes(featureName);
    default:
      return DEVELOPMENT_FEATURES.includes(featureName);
  }
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Portal from '@mui/material/Portal';
import { firstLetterCaps } from 'shared/utils';
import Snackbar from 'shared/atoms/Snackbar';
import TabsMenu from 'shared/organisms/WatchData/WatchDataTabs';
import theme from 'shared/themes/default';
import ShowOrganizationsData from './components/ShowOrganizationsData';
import ShowCourseData from './components/ShowCourseData';
import ShowUsersData from './components/ShowUsersData';
import ShowPlaylistData from './components/ShowPlaylistData';
import ShowNanodegreesData from './components/ShowNanodegreesData';
import ShowGroupsData from './components/ShowGroupsData';
import { useWatchData } from './Context';

const DEFAULT_MANAGER_VIEW = 'groups';
const DEFAULT_VIEW_BO_AND_SA = 'users';

const StyledBlock = styled.div`
  max-width: 100%;
  margin-top: ${theme.spacing.m2};
  ${theme.breakpoints.mobile} {
    margin-top: 0;
  }
`;

const getDefaultView = (isManager) => {
  if (isManager) return DEFAULT_MANAGER_VIEW;
  return DEFAULT_VIEW_BO_AND_SA;
};

const mapToOption = (item) => ({
  value: item.id,
  label: item.name,
});

const WatchData = ({
  handleHistory,
  id,
  isBackOffice,
  isOrganizationManager: isManager,
  selectOrganizationHandler,
  view,
}) => {
  const { t } = useTranslation();
  const {
    allCoursesStats,
    allPlaylistsStats,
    allNanodegreesStats,
    courseSelected,
    groupSelected,
    loadingCoursesWatchData,
    loadingGroups,
    loadingNanodegreeStatsWatchData,
    loadingNanodegreesWatchData,
    loadingPlaylistsWatchData,
    loadingUsersWatchData,
    nanodegreeSelected,
    organizationGroupsStats,
    organizationSelectedId,
    organizationsWatchData,
    playlistSelected,
    resetSelections,
    setCourseSelected,
    setGroupSelected,
    setNanodegreeSelected,
    setPlaylistSelected,
    setWatchDataError,
    watchDataError,
  } = useWatchData();

  const [selectedTab, setSelectedTab] = useState(view || 'users');
  const [optionsToSelect, setOptionsToSelect] = useState([]);
  const [initValue, setInitValue] = useState(null);

  const isGlobal = organizationSelectedId === 'global';
  const globalTabs = [
    { key: 'users', label: t('watchData.users') },
    { key: 'courses', label: t('watchData.courses') },
  ];
  const nanodegreeTabs = [
    { key: 'nanodegrees', label: t('nanodegree.nanodegrees') },
  ];
  const managerTabs = [
    { key: 'groups', label: t('administrateUsers.groups') },
    { key: 'playlists', label: t('playlist.playlists') },
  ];

  let tabsConfig = [];
  if (isGlobal && !isBackOffice) {
    tabsConfig = [...globalTabs];
  } else if (isGlobal) {
    // is global and is not backOffice
    tabsConfig = [...globalTabs, ...nanodegreeTabs];
  } else if (isManager) {
    tabsConfig = [...managerTabs];
  } else if (isBackOffice) {
    // isAdmin + Org selected in BO
    tabsConfig = [...globalTabs, ...managerTabs, ...nanodegreeTabs];
  } else {
    // isAdmin + Org selected is in client
    tabsConfig = [...globalTabs, ...managerTabs];
  }

  const viewIsAllowed = (view) => {
    if (
      ((!isBackOffice || isManager) && view === 'nanodegrees') ||
      (isManager && (view === 'users' || view === 'courses')) ||
      (isGlobal && (view === 'groups' || view === 'playlists'))
    )
      return false;
    return true;
  };

  const sendToDefaultView = () => {
    handleHistory(`view=${getDefaultView(isManager)}`);
    setSelectedTab(getDefaultView(isManager));
  };

  useEffect(() => {
    if (view && viewIsAllowed(view)) {
      let newHistory = `view=${view}`;
      if (id) newHistory += `&id=${id}`;
      return handleHistory(newHistory);
    }
    return sendToDefaultView();
  }, [organizationSelectedId]);

  useEffect(() => {
    if (selectedTab === 'courses') {
      setOptionsToSelect(
        allCoursesStats?.filter((item) => item.viewsCount).map(mapToOption)
      );
      setInitValue(courseSelected && mapToOption(courseSelected));
    } else if (selectedTab === 'groups') {
      setOptionsToSelect(organizationGroupsStats?.map(mapToOption));
      setInitValue(groupSelected && mapToOption(groupSelected));
    } else if (selectedTab === 'playlists') {
      setOptionsToSelect(allPlaylistsStats?.map(mapToOption));
      setInitValue(playlistSelected && mapToOption(playlistSelected));
    } else if (selectedTab === 'nanodegrees') {
      setOptionsToSelect(allNanodegreesStats?.map(mapToOption));
      setInitValue(nanodegreeSelected && mapToOption(nanodegreeSelected));
    } else {
      setOptionsToSelect(optionsToSelect);
      setInitValue(null);
    }
  }, [
    allCoursesStats,
    allPlaylistsStats,
    allNanodegreesStats,
    courseSelected,
    groupSelected,
    loadingCoursesWatchData,
    loadingGroups,
    loadingNanodegreesWatchData,
    loadingPlaylistsWatchData,
    nanodegreeSelected,
    organizationGroupsStats,
    playlistSelected,
    selectedTab,
  ]);

  const searchLoading =
    (view === 'users' && loadingUsersWatchData) ||
    (view === 'courses' && loadingCoursesWatchData) ||
    (view === 'groups' && loadingGroups) ||
    (view === 'playlists' && loadingPlaylistsWatchData) ||
    (view === 'nanodegrees' &&
      (loadingNanodegreesWatchData || loadingNanodegreeStatsWatchData)) ||
    false;

  const onSelectItem = (itemId) => {
    if (itemId) {
      handleHistory(`view=${view}&id=${itemId}`);
    } else {
      handleHistory(`view=${view}`);
    }
  };

  const onSearchHandler = (view) => {
    if (view) {
      const { value } = view;
      onSelectItem(value);
      if (selectedTab === 'courses') {
        setCourseSelected(allCoursesStats?.find((item) => item.id === value));
      } else if (selectedTab === 'groups') {
        setGroupSelected(
          organizationGroupsStats?.find((item) => item.id === value)
        );
      } else if (selectedTab === 'playlists') {
        setPlaylistSelected(
          allPlaylistsStats?.find((item) => item.id === value)
        );
      } else if (selectedTab === 'nanodegrees') {
        setNanodegreeSelected(
          allNanodegreesStats?.find((item) => item.id === value)
        );
      }
    }
  };

  const selectedTabHandler = (tab) => {
    resetSelections();
    if (view !== tab) {
      setSelectedTab(tab);
      handleHistory(`view=${tab}`);
    }
  };

  const showOrganizationsWatchData =
    view === DEFAULT_VIEW_BO_AND_SA && isBackOffice && isGlobal;

  return (
    <StyledBlock>
      <TabsMenu
        title={t('watchData.showActivityFor')}
        tabsConfig={tabsConfig}
        selectedTab={selectedTab}
        setSelectedTab={selectedTabHandler}
        searchPlaceholder={t(
          `watchData.searchFor${firstLetterCaps(selectedTab)}`
        )}
        searchingInTitle={t(
          `watchData.searchingIn${firstLetterCaps(selectedTab)}`
        )}
        onChangeSearch={onSearchHandler}
        onClearSearch={() => {
          resetSelections();
          onSelectItem();
        }}
        searchOptions={optionsToSelect
          ?.filter((item) => item.label)
          .sort((a, b) => a.label.localeCompare(b.label))}
        preSelectedValue={initValue}
        getOptionKey={(option) => option.value}
        searchDisabled={
          selectedTab === 'users' ||
          (selectedTab === 'nanodegrees' && organizationSelectedId !== 'global')
        }
        searchLoading={searchLoading}
      >
        {selectedTab === 'courses' && (
          <ShowCourseData
            id={id}
            onSelectItem={onSelectItem}
            isBackOffice={isBackOffice}
          />
        )}
        {selectedTab === 'users' &&
          (showOrganizationsWatchData ? (
            <ShowOrganizationsData
              organizations={organizationsWatchData}
              selectOrganizationHandler={selectOrganizationHandler}
            />
          ) : (
            <ShowUsersData isBackOffice={isBackOffice} />
          ))}
        {selectedTab === 'groups' && (
          <ShowGroupsData
            id={id}
            isManager={isManager}
            onSelectItem={onSelectItem}
          />
        )}
        {selectedTab === 'playlists' && (
          <ShowPlaylistData id={id} onSelectItem={onSelectItem} />
        )}
        {selectedTab === 'nanodegrees' && (
          <ShowNanodegreesData id={id} onSelectItem={onSelectItem} />
        )}
      </TabsMenu>
      <Portal>
        <Snackbar
          type="error"
          message={t('errors.requestFailed')}
          open={watchDataError}
          onClose={() => setWatchDataError(false)}
        />
      </Portal>
    </StyledBlock>
  );
};

WatchData.propTypes = {
  handleHistory: PropTypes.func,
  view: PropTypes.string,
  isBackOffice: PropTypes.bool,
  isOrganizationManager: PropTypes.bool,
  id: PropTypes.string,
  selectOrganizationHandler: PropTypes.func,
};

export default WatchData;

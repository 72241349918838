import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import makeStyles from '@mui/styles/makeStyles';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MuiTableSortLabel from '@mui/material/TableSortLabel';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import Checkbox from 'shared/atoms/Checkbox';
import theme from 'shared/themes/default';

import TableCell from '../TableCell';

export const ORDER = {
  ASC: 'asc',
  DESC: 'desc',
};

const StyledSortingDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 30px;
  div {
    height: 10px;
    width: 4px;
  }
`;

const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)`
  color: ${({ styled: { order, setOrderby } }) => {
    if (setOrderby && order === ORDER.DESC) {
      return theme.palette.brand;
    }
    if (setOrderby && order === ORDER.ASC) {
      return theme.palette.disabledDark;
    }
    return theme.palette.lightPure;
  }};
`;
const StyledArrowDropUpIcon = styled(ArrowDropUpIcon)`
  color: ${({ styled: { order, setOrderby } }) => {
    if (setOrderby && order === ORDER.DESC) {
      return theme.palette.disabledDark;
    }
    if (setOrderby && order === ORDER.ASC) {
      return theme.palette.brand;
    }
    return theme.palette.lightPure;
  }};
`;

const useStyles = makeStyles(() => ({
  root: {
    color: theme.palette.lightPure,
    backgroundColor: (props) =>
      props.isExpandedType ? theme.palette.evenDark : '',
    textTransform: (props) => (props.isExpandedType ? 'none' : 'uppercase'),
    '&:hover': {
      color: theme.palette.lightPure,
    },
    '&:not(:hover)': {
      color: theme.palette.lightPure,
    },
  },
  stickyClass: {
    position: 'sticky',
    left: 0,
    textTransform: (props) => (props.isExpandedType ? 'none' : 'uppercase'),
    zIndex: 1,
  },
  stickyClassSecondColumn: {
    position: 'sticky',
    left: '50px',
    textTransform: (props) => (props.isExpandedType ? 'none' : 'uppercase'),
    zIndex: 1,
  },
  stickyClassExpanded: {
    position: 'sticky',
    left: 0,
    textTransform: (props) => (props.isExpandedType ? 'none' : 'uppercase'),
    zIndex: '3', // stickyHeader is at zIndex 2
  },
}));

const SortHeader = ({
  columns,
  isExpandedType,
  onRequestSort,
  onSelectAll,
  order,
  orderBy,
  allItemsSelected,
  selectable,
}) => {
  const classes = useStyles({ isExpandedType });

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const getIcon = (key) => {
    // TODO: Do it like in mobile certificates table (check)
    const setOrderBy = key === orderBy;
    return (
      <StyledSortingDiv>
        <div>
          <StyledArrowDropUpIcon styled={{ setOrderby: setOrderBy, order }} />
        </div>
        <div>
          <StyledArrowDropDownIcon styled={{ setOrderby: setOrderBy, order }} />
        </div>
      </StyledSortingDiv>
    );
  };

  return (
    <TableHead>
      <TableRow>
        {selectable && (
          <TableCell
            style={{ maxWidth: '50px' }}
            className={classes.stickyClass}
          >
            <Checkbox
              onChange={() => onSelectAll({ value: !allItemsSelected })}
              checked={allItemsSelected}
              theme="dark"
            />
          </TableCell>
        )}
        {columns.map((headCell) => {
          let tableCellClass = classes.root;
          if (headCell.sticky) {
            if (selectable) {
              tableCellClass = classes.stickyClassSecondColumn;
            } else {
              tableCellClass = classes.stickyClass;
            }
          }
          if (headCell.expandedSticky) {
            tableCellClass = classes.stickyClassExpanded;
          }
          if (headCell.disableSorting) {
            return (
              <TableCell key={headCell.key} className={tableCellClass}>
                {headCell.label}
              </TableCell>
            );
          }
          return (
            <TableCell
              key={headCell.key}
              className={tableCellClass}
              sortDirection={orderBy === headCell.key ? order : false}
              expandedSticky={headCell.expandedSticky}
            >
              <MuiTableSortLabel
                className={classes.root}
                active={orderBy === headCell.key}
                direction={orderBy === headCell.key ? order : ORDER.ASC}
                onClick={createSortHandler(headCell.key)}
                IconComponent={() => getIcon(headCell.key)}
              >
                {headCell.label}
              </MuiTableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

SortHeader.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(Object.values(ORDER)).isRequired,
  orderBy: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      disableSorting: PropTypes.bool,
      expandedSticky: PropTypes.bool,
      fixedWidth: PropTypes.string,
      key: PropTypes.string.isRequired,
      label: PropTypes.string,
      render: PropTypes.func,
      sticky: PropTypes.bool,
      type: PropTypes.string,
    })
  ).isRequired,
  onSelectAll: PropTypes.func,
  selectable: PropTypes.bool,
  allItemsSelected: PropTypes.bool,
  isExpandedType: PropTypes.bool,
};

SortHeader.defaultProps = {
  selectable: false,
  onSelectAll: () => {},
  allItemsSelected: false,
  isExpandedType: false,
};

export default SortHeader;

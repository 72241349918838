import React, { isValidElement, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import MuiSnackbar from '@mui/material/Snackbar';
import useMediaQuery from '@mui/material/useMediaQuery';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import Icon from 'shared/atoms/Icon';
import T from 'shared/atoms/Typography';

import theme from 'shared/themes/default';

import HideAfterCounter from './components/HideAfterCounter';

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;
`;

const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  gap: ${theme.spacing.m1};
  padding: ${(props) =>
    props.isTablet ? `${theme.spacing.m1} 0` : '1.25rem 0'};
`;

const ActionWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${theme.spacing.m1};
  margin-right: 0;
  padding-top: ${(props) => (props.hasCounter ? '1.125rem' : '0')};
  padding-right: ${(props) =>
    props.isTablet ? theme.spacing.m1 : theme.spacing.m3};
  padding-left: ${(props) =>
    props.isTablet ? theme.spacing.m1 : theme.spacing.m3};
`;

const Snackbar = ({
  detailedErrors,
  hideAfter,
  message,
  onClose,
  open,
  position,
  top,
  type,
}) => {
  const [expanded, setExpanded] = useState(false);

  const { t } = useTranslation();

  const isMobile = useMediaQuery(theme.breakpoints.mobile);
  const isTablet = useMediaQuery(theme.breakpoints.tablet);
  const { vertical, horizontal } = position;

  const sx = {
    width: '100%',
    top:
      top === undefined
        ? `${theme.sizes.header} !important`
        : `${top}px !important`,
    left: isMobile ? '0' : 'none',
    color: theme.palette.mainDark,
    '& .MuiSnackbarContent-root': {
      alignItems: 'start',
      borderRadius: '0 0 0.375rem 0.375rem',
      color: theme.palette.mainDark,
      padding: isTablet
        ? `0 0 0 ${theme.spacing.m1}`
        : `0 0 0 ${theme.spacing.m3}`,
      width: '100%',
    },
    '& .MuiSnackbarContent-message': {
      display: 'flex',
      flexDirection: 'column',
      padding: '0',
      width: '100%',
    },
  };

  let iconColor;

  const buttonSx = {
    cursor: 'pointer',
  };

  let countdownValue;

  if (isMobile) {
    countdownValue = hideAfter.mobile || 5;
  } else {
    countdownValue = hideAfter.desktop || 10;
  }

  if (type === 'generic') {
    sx['& .MuiSnackbarContent-root'].backgroundColor =
      theme.palette.lightDistinct;
    iconColor = theme.palette.mainDark;
  } else if (type === 'success') {
    sx['& .MuiSnackbarContent-root'].backgroundColor =
      theme.palette.successTinted;
    iconColor = theme.palette.successDark;
  } else if (type === 'warning') {
    sx['& .MuiSnackbarContent-root'].backgroundColor =
      theme.palette.warningTinted;
    iconColor = theme.palette.mainDark;
  } else if (type === 'error') {
    sx['& .MuiSnackbarContent-root'].backgroundColor =
      theme.palette.errorTinted;
    iconColor = theme.palette.errorDark;
  }

  const hasCounter = type !== 'error';

  return (
    <MuiSnackbar
      anchorOrigin={{ vertical, horizontal }}
      key={vertical + horizontal}
      message={
        <>
          <ContentWrapper>
            <TitleWrapper isMobile={isMobile}>
              <Icon
                icon={
                  type === 'success' || type === 'generic'
                    ? CheckCircleIcon
                    : ErrorOutlineIcon
                }
                color={iconColor}
              />
              <T
                color={theme.palette.mainDark}
                variant={isMobile ? 'paragraph' : 'subtitle'}
                fontWeight="semibold"
              >
                {`${message}${
                  detailedErrors
                    ? ` (${t('generic.errorCount', {
                        count: detailedErrors.length,
                      })})`
                    : ''
                }`}
              </T>
            </TitleWrapper>
            <ActionWrapper>
              <ButtonWrapper isTablet={isTablet} hasCounter={hasCounter}>
                {detailedErrors &&
                  (expanded ? (
                    <ExpandLess
                      onClick={() => setExpanded(false)}
                      sx={buttonSx}
                    />
                  ) : (
                    <ExpandMore
                      onClick={() => setExpanded(true)}
                      sx={buttonSx}
                    />
                  ))}
                <CloseIcon onClick={onClose} sx={buttonSx} />
              </ButtonWrapper>
              {hasCounter && (
                <HideAfterCounter
                  startFrom={countdownValue}
                  onComplete={onClose}
                />
              )}
            </ActionWrapper>
          </ContentWrapper>
          {expanded && detailedErrors && (
            <DetailsWrapper>
              {detailedErrors.map(({ title, description, element }) => (
                <div key={title}>
                  <T color={theme.palette.mainDark}>
                    <T
                      color={theme.palette.mainDark}
                      component="span"
                      fontWeight="bold"
                    >{`${title}:`}</T>
                    {description ? ` ${description}` : ''}
                  </T>
                  {!description && isValidElement(element) ? element : null}
                </div>
              ))}
            </DetailsWrapper>
          )}
        </>
      }
      open={open}
      onClose={onClose}
      sx={sx}
    />
  );
};

Snackbar.propTypes = {
  detailedErrors: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      element: PropTypes.object,
    })
  ),
  hideAfter: PropTypes.shape({
    desktop: PropTypes.number,
    mobile: PropTypes.number,
  }),
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  position: PropTypes.object,
  top: PropTypes.number,
  type: PropTypes.oneOf(['generic', 'success', 'warning', 'error']).isRequired,
};

Snackbar.defaultProps = {
  hideAfter: {},
  position: {
    vertical: 'top',
    horizontal: 'center',
  },
};

export default Snackbar;

import React from 'react';

import PageTemplate from '../components/templates/PageTemplate';
import DomainsView from '../components/organisms/DomainsView';

const DomainsContainer = () => (
  <PageTemplate>
    <DomainsView />
  </PageTemplate>
);

export default DomainsContainer;

export const headerOptions = ({ user }) => [
  {
    ariaLabel: 'backoffice.header.categories',
    to: '/categories',
    text: 'backoffice.header.categories',
    conditionalRender: !!user,
  },
  {
    ariaLabel: 'backoffice.header.experts',
    to: '/experts',
    text: 'backoffice.header.experts',
    conditionalRender: !!user,
  },
  {
    ariaLabel: 'backoffice.header.courses',
    to: '/courses',
    text: 'backoffice.header.courses',
    conditionalRender: !!user,
  },
  {
    ariaLabel: 'backoffice.header.activity',
    to: '/activity',
    text: 'backoffice.header.activity',
    conditionalRender: !!user,
  },
  {
    ariaLabel: 'backoffice.header.organizations',
    to: '/organizations',
    text: 'backoffice.header.organizations',
    conditionalRender: !!user,
  },
  {
    ariaLabel: 'backoffice.header.users',
    to: '/users',
    text: 'backoffice.header.users',
    conditionalRender: !!user,
  },
  {
    ariaLabel: 'backoffice.header.lists',
    to: '/playlists',
    text: 'backoffice.header.lists',
    conditionalRender: !!user,
  },
  {
    ariaLabel: 'backoffice.header.members',
    to: '/members',
    text: 'backoffice.header.members',
    conditionalRender: !!user,
  },
  {
    ariaLabel: 'backoffice.header.domains',
    to: '/domains',
    text: 'backoffice.header.domains',
    conditionalRender: !!user,
  },
];

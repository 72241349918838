import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import Chip from 'shared/atoms/Chip';
import Icon from 'shared/atoms/Icon';
import EpisodesSeenGraph from 'shared/organisms/WatchData/EpisodesSeenGraph';
import NanodegreeStatsWatchDataTable from 'shared/organisms/WatchData/NanodegreeStatsWatchDataTable';
import NanodegreesWatchDataTable from 'shared/organisms/WatchData/NanodegreesWatchDataTable';
import Pill from 'shared/atoms/Pill';
import T from 'shared/atoms/Typography';
import TableWithSearchAndExport from 'shared/organisms/WatchData/TableWithSearchAndExport';
import Tooltip from 'shared/atoms/Tooltip';

import { TYPE_WATCH_DATA, PLAYLIST_STATUS } from 'shared/const';

import theme from 'shared/themes/default';

import { mapAggregatedWatchDataGraph, callApi } from '../utils';
import { useWatchData } from '../Context';

const Wrapper = styled.div`
  max-width: 100%;
`;

const WrapperPill = styled.div`
  display: flex;
  margin-top: 108px;
  ${theme.breakpoints.mobile} {
    margin-top: 64px;
  }
`;

const StyledIconWrapper = styled.div`
  margin-left: ${theme.spacing.s};
  display: flex;
  align-items: center;
`;

const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${theme.spacing.m1};
  margin-bottom: ${theme.spacing.m3};
  margin-top: ${theme.spacing.m2};
`;

const ShowNanodegreesData = ({ id: playlistId, onSelectItem }) => {
  const [aggregatedWatchData, setAggregatedWatchData] = useState([]);
  const [loadingAggregated, setLoadingAggregated] = useState(false);
  const { t, i18n } = useTranslation();

  const {
    allNanodegreesStats,
    downloadWatchDataManualOrganization,
    fetchNanodegreesWatchData,
    listAggregatedWatchData,
    loadingNanodegreeStatsWatchData,
    loadingNanodegreesWatchData,
    locale,
    nanodegreeSelected,
    nanodegreeStats,
    organizationSelectedId,
    setNanodegreeSelected,
    setWatchDataError,
    user,
  } = useWatchData();

  const organizationIdNoGlobal =
    organizationSelectedId && organizationSelectedId !== 'global'
      ? organizationSelectedId
      : undefined;

  const selectNanodegreeHandler = (nanodegree) => {
    onSelectItem(nanodegree.id);
    setNanodegreeSelected(nanodegree);
  };

  const fetchNanodegreeWatchData = async () => {
    const handleError = (error) => {
      console.error('Error calling fetchNanodegreesWatchData', error);
      setWatchDataError(true);
    };

    try {
      const { error } = await fetchNanodegreesWatchData(playlistId);
      if (error) return handleError(error);
    } catch (error) {
      handleError(error);
    }
    return true;
  };

  const fetchAggregatedWatchData = async (type) => {
    const params = {};
    if (organizationIdNoGlobal) {
      params.organizationId = organizationSelectedId;
    }
    if (playlistId) {
      params.playlistId = playlistId;
    }

    callApi({
      apiMethod: listAggregatedWatchData,
      setData: setAggregatedWatchData,
      setError: setWatchDataError,
      setLoading: setLoadingAggregated,
      type,
      params,
    });
  };

  useEffect(() => {
    if (organizationSelectedId) {
      setNanodegreeSelected(null);
    }

    fetchNanodegreeWatchData();
  }, [organizationSelectedId]);

  useEffect(() => {
    fetchNanodegreeWatchData();
    if (playlistId) {
      fetchAggregatedWatchData(
        organizationIdNoGlobal
          ? TYPE_WATCH_DATA.PLAYLIST_USERS
          : TYPE_WATCH_DATA.NANODEGREE_GLOBAL
      );
    } else {
      fetchAggregatedWatchData(
        organizationIdNoGlobal
          ? TYPE_WATCH_DATA.NANODEGREES
          : TYPE_WATCH_DATA.NANODEGREES_GLOBAL
      );
    }
  }, [organizationSelectedId, playlistId]);

  useEffect(() => {
    if (playlistId && !loadingNanodegreesWatchData) {
      const nanodegree = allNanodegreesStats.find(
        (item) => item.id === playlistId
      );
      if (nanodegree) {
        setNanodegreeSelected(nanodegree);
      } else {
        onSelectItem();
      }
    }
  }, [allNanodegreesStats, loadingNanodegreesWatchData]);

  const nanodegreeName = nanodegreeSelected
    ? nanodegreeSelected?.name
    : t('playlist.allNanodegrees');
  const tableProps = {
    locale,
  };
  if (!nanodegreeSelected) {
    if (organizationSelectedId === 'global')
      tableProps.selectNanodegreeHandler = selectNanodegreeHandler;
  } else {
    tableProps.playlistId = playlistId;
  }

  return (
    <Wrapper>
      <>
        <WrapperPill>
          <Pill
            color={theme.palette.lightPure}
            fontColor={theme.palette.mainDark}
          >
            {t('nanodegree.nanodegrees').toUpperCase()}
          </Pill>
          <Tooltip title={t('watchData.titleTooltip')} arrow>
            <StyledIconWrapper>
              <Icon
                color={theme.palette.lightPure}
                colorHover={theme.palette.brand}
                icon={InfoOutlinedIcon}
                size="2rem"
                sx={{
                  cursor: 'pointer',
                }}
              />
            </StyledIconWrapper>
          </Tooltip>
        </WrapperPill>
        <TitleWrapper>
          <T
            color={theme.palette.brand}
            fontWeight="medium"
            phoneVariant="headingXs"
            variant="headingS"
          >
            {nanodegreeName}
          </T>
          {nanodegreeSelected?.status === PLAYLIST_STATUS.ARCHIVED && (
            <Chip
              color={theme.palette.errorLight}
              label={t('watchData.deleted')}
              theme="dark"
            />
          )}
        </TitleWrapper>
        <EpisodesSeenGraph
          data={mapAggregatedWatchDataGraph(aggregatedWatchData, i18n.language)}
          loading={loadingAggregated}
        />
        {!nanodegreeSelected ? (
          <TableWithSearchAndExport
            downloadItems={() =>
              downloadWatchDataManualOrganization(organizationIdNoGlobal, {
                watchData: organizationIdNoGlobal
                  ? TYPE_WATCH_DATA.NANODEGREES
                  : TYPE_WATCH_DATA.NANODEGREES_GLOBAL,
              })
            }
            emptyMessage={t('watchData.noActivity')}
            itemsToDisplay={allNanodegreesStats}
            loading={loadingNanodegreesWatchData}
            searchPlaceHolder={t('watchData.searchForNanodegrees')}
            showRowsAreClickableMessage={organizationSelectedId === 'global'}
            spinnerDuration={90}
            tableProps={tableProps}
            tableTitle={t('watchData.activitiesOfYourNanodegrees')}
            TableToDisplay={NanodegreesWatchDataTable}
            user={user}
          />
        ) : (
          <TableWithSearchAndExport
            downloadItems={() =>
              downloadWatchDataManualOrganization(undefined, {
                watchData: TYPE_WATCH_DATA.NANODEGREE_GLOBAL,
                playlistId,
              })
            }
            emptyMessage={t('watchData.noNanodegreeActivity')}
            itemsToDisplay={nanodegreeStats}
            loading={loadingNanodegreeStatsWatchData}
            searchPlaceHolder={t('watchData.searchForOrganizations')}
            spinnerDuration={90}
            tableProps={tableProps}
            tableTitle={t('watchData.activitiesOnYourNanodegree')}
            TableToDisplay={NanodegreeStatsWatchDataTable}
            user={user}
          />
        )}
      </>
    </Wrapper>
  );
};

ShowNanodegreesData.propTypes = {
  id: PropTypes.string,
  onSelectItem: PropTypes.func.isRequired,
};

export default ShowNanodegreesData;

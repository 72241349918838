import { useState } from 'react';

const useQuery = (API, appsignal, headers) => {
  if (!API) throw new Error("any API set on 'useQuery'");
  if (typeof API !== 'object')
    throw new Error("API set on 'useQuery' is not an object");
  if (!('graphql' in API))
    throw new Error("API set on 'useQuery' is not a valid API object");

  const [data, setData] = useState({});
  const [errors, setErrors] = useState([]);
  const [hasEnded, setHasEnded] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleErrors = (query, errors) => {
    if (appsignal) {
      try {
        // Error instance has to be created as Amplify GraphQL API doesn't throw an error, just an object
        // AppSignal requires it to be an error
        appsignal.sendError(
          new Error(
            `Failed GraphQL call\nQuery:\n${JSON.stringify(
              query,
              null,
              2
            )}\nErrors:\n${errors
              .map(
                ({ errorType, message, path }) =>
                  `  Paths: ${path.join(',')}; ${errorType}: ${message}`
              )
              .join('\n')}`
          )
        );
      } catch (error) {
        // In local development, AppSignal rethrows the error
      }
    }

    setData({});
    setHasErrors(true);
    setErrors(errors);
    setLoading(false);
    setHasEnded(true);
    return {};
  };

  /* *
   * loading: will be true until the query does not return a nextToken. (loading statuses or handle the end of this queries)
   * hasEnded: will be reset after every call to callQuery. (can handle nextToken flow)
   * */
  const callQuery = async (query) => {
    try {
      setLoading(true);
      setHasEnded(false);
      setErrors([]);
      setHasErrors(false);

      const { data, error, errors } = await API.graphql(query, headers);

      if (error || errors) return handleErrors(query, error || errors);

      setData(data);
      setHasEnded(true);
      if (
        Object.keys(data).reduce(
          (prev, key) => prev && !data[key]?.nextToken,
          true
        )
      ) {
        setLoading(false);
      }

      return data;
    } catch (errors) {
      return handleErrors(query, errors.errors);
    }
  };

  return {
    callQuery,
    data,
    errors,
    hasEnded,
    hasErrors,
    loading,
  };
};

export default useQuery;

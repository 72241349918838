import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CircularSpinner from 'shared/atoms/CircularSpinner';

import { usePlaylist } from '../../../context';
import SelectCoursesAndPreview from './components/SelectCoursesAndPreview';

const PlaylistSelectCourse = () => {
  const [formStatus, setFormStatus] = useState();
  const [values, setValues] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();
  const {
    playlists: { isNanodegree, updatePlaylistContent, loadingContent },
    dialogs: {
      addCoursesValues,
      setOpenAddCoursesToPlaylist,
      updatePlaylistContentHandler,
    },
  } = usePlaylist();

  useEffect(() => {
    if (!loadingContent && addCoursesValues) {
      setValues(addCoursesValues);
      setIsLoading(false);
    }
  }, [loadingContent, addCoursesValues]);

  const { id, name, courses = [] } = values;

  const onCloseHandler = () => {
    setOpenAddCoursesToPlaylist(false);
    setFormStatus(null);
  };

  const handleSubmit = async (coursesSelected) => {
    const playlistContent = coursesSelected.map((course) => course.id);

    const input = { id, content: playlistContent };
    const { data, error } = await updatePlaylistContent({ input });

    if (error) {
      console.error('Error: ', error);
      setFormStatus({
        success: false,
        message: t(
          isNanodegree
            ? 'nanodegree.updatedFailureMessage'
            : 'playlist.playlistContentUpdatedFailureMessage'
        ),
      });
    }

    if (data) {
      updatePlaylistContentHandler(
        id,
        coursesSelected,
        data.updatePlaylistContent.children
      );
      setFormStatus({
        success: true,
        message: t(
          isNanodegree
            ? 'nanodegree.updatedSuccessMessage'
            : 'playlist.playlistContentUpdatedSuccessMessage'
        ),
      });
    }

    return true;
  };

  return isLoading ? (
    <CircularSpinner static />
  ) : (
    <SelectCoursesAndPreview
      formStatus={formStatus}
      handleSubmit={handleSubmit}
      onClose={onCloseHandler}
      playlistCourses={courses}
      playlistName={name}
    />
  );
};

export default PlaylistSelectCourse;

import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import Popover from '@mui/material/Popover';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link as RouterLink, NavLink } from 'react-router-dom';

import Button from 'shared/atoms/Button';
import IconButton from 'shared/atoms/IconButton';
import Link from 'shared/atoms/Link';
import LanguageSelect from 'shared/molecules/LanguageSelect';
import theme from 'shared/themes/default';
import { videocationLogo } from 'shared/utils';

import DesktopMenu from './components/DesktopMenu';
import MobileMenu from './components/MobileMenu';

const OuterWrapper = styled.header`
  background-color: ${theme.palette.mainDark};
  border-bottom: 1px solid ${theme.palette.disabledDark};
`;

const MaxWidthCenter = styled.div`
  max-width: 1500px;
  margin: 0 auto;
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.l};
  height: ${theme.sizes.header};
  justify-content: space-between;
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: ${theme.spacing.m3};
`;

const EndWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.m3};
  margin-right: ${theme.spacing.m1};
`;

const LogoLink = styled(RouterLink)`
  height: ${theme.sizes.header};
`;

const LogoExternalLink = styled.a`
  height: ${theme.sizes.header};
`;

const Logo = styled.img`
  height: ${theme.sizes.header};
  width: ${theme.sizes.header};
`;

const LinkStyles = css`
  color: ${theme.palette.lightPure};
  font-family: ${theme.fonts.primary};
  font-weight: 500;
  height: ${theme.sizes.header};
  line-height: ${theme.sizes.header};
  text-decoration: none;
  &.active {
    border-bottom: 2px solid ${theme.palette.brand};
    color: ${theme.palette.brand};
  }
  &:hover {
    border-bottom: 2px solid ${theme.palette.brand};
    color: ${theme.palette.lightPure};
    text-decoration: none;

    &.active {
      color: ${theme.palette.brand};
    }
  }
`;

const MenuLink = styled(NavLink)`
  ${LinkStyles}
`;

const MenuExternalLink = styled.a`
  ${LinkStyles}
`;

const MobileProfile = styled.div`
  width: 100%;
  z-index: 100;
  overflow: auto;
  max-height: 80vh;
  -webkit-overflow-scrolling: touch;
  display: block;
  border-radius: 0 0 0.5rem 0.5rem;
  border-top: 1px solid ${theme.palette.disabledDark};
  position: absolute;
`;

const Header = ({
  adminOptions,
  domain,
  homepageUrl,
  languages,
  loginUrl,
  onLanguageChange,
  onSearchToggle,
  onSignOut,
  options,
  profileOpen,
  registerUrl,
  searchBarOpen,
  selectedLanguage,
  showLanguage,
  showButtons,
  showProfile,
  showSearch,
  user,
}) => {
  const { t } = useTranslation();
  const isTablet = useMediaQuery(theme.breakpoints.tablet);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handlePopoverClose = () => setAnchorEl(null);
  const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget);

  return (
    <OuterWrapper>
      <MaxWidthCenter>
        <Wrapper>
          {user ? (
            <LogoLink to={homepageUrl} aria-label={t('header.homepage')}>
              <Logo alt="videocation logo" src={videocationLogo(domain)} />
            </LogoLink>
          ) : (
            <LogoExternalLink
              href={homepageUrl}
              aria-label={t('header.homepage')}
            >
              <Logo alt="videocation logo" src={videocationLogo(domain)} />
            </LogoExternalLink>
          )}
          {!isTablet && (
            <MenuWrapper>
              {options.map((o) =>
                o.to ? (
                  <MenuLink aria-label={t(o.ariaLabel)} key={o.to} to={o.to}>
                    {t(o.text)}
                  </MenuLink>
                ) : (
                  <MenuExternalLink
                    aria-label={t(o.ariaLabel)}
                    href={o.href}
                    key={o.href}
                  >
                    {t(o.text)}
                  </MenuExternalLink>
                )
              )}
            </MenuWrapper>
          )}
          <EndWrapper>
            {showLanguage && (
              <LanguageSelect
                languages={languages}
                onChange={onLanguageChange}
                value={selectedLanguage}
              />
            )}
            {!isTablet && !user && showButtons && (
              <Button
                ariaLabel={t('header.login')}
                theme="dark"
                to={loginUrl}
                type="secondary"
              >
                {t('header.login')}
              </Button>
            )}
            {!isTablet && !user && showButtons && (
              <Link
                ariaLabel={t('header.register')}
                paragraph
                theme="dark"
                to={registerUrl}
                visibility="low"
              >
                {t('header.register')}
              </Link>
            )}
            {!isTablet && user && showSearch && (
              <IconButton
                ariaLabel={t('header.search')}
                onClick={onSearchToggle}
                icon={SearchIcon}
                sxIcon={{
                  color: searchBarOpen ? theme.palette.brand : undefined,
                  transform: 'rotate(90deg)',
                }}
                theme="dark"
                type="icon"
              />
            )}
            {isTablet &&
              showSearch &&
              (searchBarOpen ? (
                <IconButton
                  ariaLabel={t('generic.close')}
                  onClick={onSearchToggle}
                  icon={CloseIcon}
                  sxIcon={{ color: theme.palette.brand }}
                  theme="dark"
                  type="icon"
                />
              ) : (
                <IconButton
                  ariaLabel={t('header.search')}
                  onClick={() => {
                    onSearchToggle();
                    setMobileMenuOpen(false);
                  }}
                  icon={SearchIcon}
                  sxIcon={{ transform: 'rotate(90deg)' }}
                  theme="dark"
                  type="icon"
                />
              ))}
            {isTablet && showProfile && (
              <IconButton
                ariaLabel={
                  mobileMenuOpen ? t('generic.close') : t('header.openMenu')
                }
                data-matomo-mask
                icon={mobileMenuOpen ? CloseIcon : MenuIcon}
                onClick={() => {
                  setMobileMenuOpen((mobileMenuOpen) => !mobileMenuOpen);
                  if (searchBarOpen) {
                    onSearchToggle();
                  }
                }}
                sxIcon={mobileMenuOpen ? { color: theme.palette.brand } : {}}
                theme="dark"
                type="icon"
              />
            )}
            {!isTablet && showProfile && user && (
              <>
                <IconButton
                  ariaLabel="user initials"
                  data-matomo-mask
                  onClick={handlePopoverOpen}
                  sx={
                    !!anchorEl || profileOpen
                      ? {
                          background: theme.palette.brand,
                        }
                      : {}
                  }
                  theme="light"
                  type="secondary"
                  user={user}
                />
                <Popover
                  open={!!anchorEl}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: -16,
                    horizontal: 'center',
                  }}
                >
                  <DesktopMenu
                    closePopover={handlePopoverClose}
                    options={adminOptions}
                    onSignOut={() => {
                      handlePopoverClose();
                      onSignOut();
                    }}
                    user={user}
                  />
                </Popover>
              </>
            )}
          </EndWrapper>
        </Wrapper>
      </MaxWidthCenter>
      {isTablet && mobileMenuOpen && (
        <MobileProfile>
          <MobileMenu
            adminOptions={adminOptions}
            closePopover={() => setMobileMenuOpen(false)}
            loginUrl={loginUrl}
            options={options}
            onSignOut={() => {
              setMobileMenuOpen(false);
              onSignOut();
            }}
            registerUrl={registerUrl}
            showButtons={showButtons}
            user={user}
          />
        </MobileProfile>
      )}
    </OuterWrapper>
  );
};

Header.propTypes = {
  adminOptions: PropTypes.array.isRequired,
  domain: PropTypes.string.isRequired,
  homepageUrl: PropTypes.string.isRequired,
  languages: PropTypes.array,
  loginUrl: PropTypes.string.isRequired,
  onLanguageChange: PropTypes.func,
  onSearchToggle: PropTypes.func,
  onSignOut: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  profileOpen: PropTypes.bool,
  registerUrl: PropTypes.string,
  searchBarOpen: PropTypes.bool,
  selectedLanguage: PropTypes.string,
  showLanguage: PropTypes.bool,
  showButtons: PropTypes.bool,
  showProfile: PropTypes.bool,
  showSearch: PropTypes.bool,
  user: PropTypes.object,
};

export default Header;

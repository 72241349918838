import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Table, { ORDER_TYPES } from 'shared/organisms/Table';

import UsersWatchDataTableRowItem from './UsersWatchDataTableRowItem';

const UsersWatchDataTable = ({ itemsToDisplay }) => {
  const { t } = useTranslation();
  const [selectedItemIds, setSelectedItemIds] = useState([]);

  const columns = [
    {
      key: 'name',
      label: t('watchData.name'),
      sticky: true,
      fixedWidth: '20%',
      maskValue: true,
    },
    {
      key: 'email',
      label: t('watchData.email'),
      fixedWidth: '33%',
      maskValue: true,
    },
    { key: 'playlistsCompleted', label: t('watchData.completedPlaylist') },
    { key: 'coursesCompleted', label: t('watchData.completedCourses') },
    { key: 'episodesCompleted', label: t('watchData.completedEpisodes') },
    { key: 'certificatesEarned', label: t('watchData.certificatesEarned') },
  ];

  const toggleShowExpandedUser = (id) => {
    if (selectedItemIds.includes(id)) {
      setSelectedItemIds((items) => items.filter((itemId) => id !== itemId));
    } else {
      setSelectedItemIds((items) => [...items, id]);
    }
  };

  return (
    <Table
      ariaLabel={t('watchData.activityOfYourUsers')}
      columns={columns}
      items={itemsToDisplay}
      defaultOrder={ORDER_TYPES.DESC}
      defaultOrderBy="episodesCompleted"
      rowIsClickable={(row) => row.episodesCompleted}
      selectedItemIds={selectedItemIds}
      onRowClick={(row) => toggleShowExpandedUser(row.id)}
      ExpandedItemComponent={UsersWatchDataTableRowItem}
    />
  );
};

UsersWatchDataTable.propTypes = {
  itemsToDisplay: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      playlistsCompleted: PropTypes.number.isRequired,
      coursesCompleted: PropTypes.number.isRequired,
      episodesCompleted: PropTypes.number.isRequired,
      certificatesEarned: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default UsersWatchDataTable;

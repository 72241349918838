import React from 'react';

import { useTranslation } from 'react-i18next';

import theme from 'shared/themes/default';

import T from 'shared/atoms/Typography';
import Button from 'shared/atoms/Button';

import { StyledButtonWrapper } from '../styles';

import { useOrganizationMembers } from '../context';

const NoSubscription = () => {
  const { t } = useTranslation();

  const { isManager, onClickSubscribe } = useOrganizationMembers();
  return (
    <>
      <T color={theme.palette.lightPure} variant="headingS">
        {t('administrateUsers.noActiveSubscription')}
      </T>
      {!isManager ? (
        <T color={theme.palette.lightInteracting}>
          {t('administrateUsers.suggestSubscription')}
        </T>
      ) : (
        <T color={theme.palette.lightInteracting}>
          {t('administrateUsers.contactYourAdmin')}
        </T>
      )}
      {!isManager && (
        <StyledButtonWrapper>
          <Button
            ariaLabel={t('administrateUsers.subscribe')}
            onClick={onClickSubscribe}
            theme="dark"
            type="primary"
          >
            {t('administrateUsers.subscribe')}
          </Button>
        </StyledButtonWrapper>
      )}
    </>
  );
};

export default NoSubscription;

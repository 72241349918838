import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import LinkIcon from '@mui/icons-material/Link';
import LockIcon from '@mui/icons-material/Lock';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import PsychologyIcon from '@mui/icons-material/Psychology';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import WarningIcon from '@mui/icons-material/Warning';

import CircularSpinner from '@shared/atoms/CircularSpinner';
import Button from '@shared/atoms/Button';
import Icon from '@shared/atoms/Icon';
import IconButton from '@shared/atoms/IconButton';
import Snackbar from '@shared/atoms/Snackbar';
import Tooltip from '@shared/atoms/Tooltip';
import T from '@shared/atoms/Typography';
import TabsMenu from '@shared/molecules/TabsMenu';
import ConfirmationDialog from '@shared/molecules/ConfirmationDialog';
import {
  selectTranslation,
  getCourseDetailUrl,
  formatDate,
} from '@shared/utils';
import { courseStatus } from '@shared/const';
import theme from '@shared/themes/default';

import { useAppGlobals } from '../../contexts/AppContext';
import { ERRORS, TABS, useCourse } from '../../contexts/CourseContext';
import { environment } from '../../../config';
import Availability from './tabs/Availability';
import Attachments from './tabs/Attachments';
import Catalog from './tabs/Catalog';
import Description from './tabs/Description';
import Quiz from './tabs/Quiz';
import Lessons from './tabs/Lessons';
import PublishDialog from './components/PublishDialog';
import PublishedDeleteDialog from './components/PublishedDeleteDialog';
import ValidationList from './components/ValidationList';

const ErrorWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m2};
  padding-top: ${theme.spacing.xl};
`;

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.s};
`;

const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.m2};
`;

const TabsMenuWrapper = styled.nav`
  margin-top: ${theme.spacing.m1};
`;

const COURSE_PAGE = 'COURSE_PAGE';

const statusColors = {
  [courseStatus.DRAFT]: theme.palette.warning,
  [courseStatus.ARCHIVED]: theme.palette.errorDark,
  [courseStatus.PUBLISHED]: theme.palette.lightPure,
  [courseStatus.HIDDEN]: theme.palette.lightPure,
};

const ManageCourseView = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const view = searchParams.get('view');
  const { t } = useTranslation();
  const {
    canPublish,
    clearError,
    course,
    deleteCourse,
    deletePublishedCourse,
    errorMessage,
    errorType,
    handleSave,
    hasChanged,
    hasError,
    loading,
    locked,
    publish,
    selectedTab,
    setHasChanged,
    setLocked,
    setSelectedTab,
    setUploadingFiles,
    submitting,
    uploadingFiles,
    validationDetails,
  } = useCourse();
  const { domain, locale } = useAppGlobals();
  const navigate = useNavigate();
  const webSiteBaseUri = `https://app${
    environment !== 'production' ? `.${environment}` : ''
  }.videocation.${domain}`;

  const [deleting, setDeleting] = useState(false);
  const [deletePublishedSuccessOpen, setDeletePublishedSuccessOpen] =
    useState(false);
  const [showConfirmationDeleteDialog, setShowConfirmationDeleteDialog] =
    useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showConfirmationUnsavedDialog, setShowConfirmationUnsavedDialog] =
    useState(false);
  const [showPublishDialog, setShowPublishDialog] = useState(false);
  const [showPublishedDeleteDialog, setShowPublishedDeleteDialog] =
    useState(false);
  const [nextTab, setNextTab] = useState(-1);
  const [initialValidation, setInitialValidation] = useState(true);
  const [validationSnackBarOpen, setValidationSnackBarOpen] = useState(false);

  const coursePreview = (c) => {
    return `${webSiteBaseUri}${getCourseDetailUrl(c, locale)}`;
  };

  useEffect(() => {
    if (view && view.toUpperCase() in TABS) {
      setSelectedTab(TABS[view.toUpperCase()]);
    }
  }, []);

  useEffect(() => {
    if (
      course?.status === courseStatus.PUBLISHED &&
      initialValidation &&
      validationDetails
    ) {
      setValidationSnackBarOpen(Object.keys(validationDetails).length > 0);
      setInitialValidation(false);
    }
  }, [course, validationDetails, initialValidation]);

  useEffect(() => {
    if (errorType === ERRORS.DELETE) {
      setDeleting(false);
    }
  }, [errorType]);

  useEffect(() => {
    navigate(`${window.location.pathname}?view=${selectedTab.toLowerCase()}`, {
      replace: true,
    });
    const shouldLock = course?.status === courseStatus.PUBLISHED;
    if (shouldLock !== locked) setLocked(shouldLock);
    setHasChanged(false);
  }, [selectedTab]);

  const contentTab = () => {
    let tab;
    switch (selectedTab) {
      case TABS.ATTACHMENTS:
        tab = Attachments;
        break;
      case TABS.AVAILABILITY:
        tab = Availability;
        break;
      case TABS.CATALOG:
        tab = Catalog;
        break;
      case TABS.DESCRIPTION:
        tab = Description;
        break;
      case TABS.LESSONS:
        tab = Lessons;
        break;
      case TABS.QUIZ:
        tab = Quiz;
        break;
      default:
        tab = () => <T>{`${selectedTab} tab selected`}</T>;
        break;
    }

    return tab;
  };

  const Tab = contentTab();

  const handleChangeLock = () => {
    if (locked) {
      setShowConfirmationDialog(true);
    } else {
      setLocked(true);
    }
  };

  const handleConfirmUnlock = () => {
    setLocked(false);
    setShowConfirmationDialog(false);
  };

  const handleChangeTab = (newTab) => {
    if (hasChanged) {
      setNextTab(newTab);
      setShowConfirmationUnsavedDialog(true);
    } else {
      setSelectedTab(newTab);
      setNextTab(-1);
      clearError();
      setUploadingFiles(false);
    }
  };

  const handleConfirmUnsave = async (handleSave) => {
    await handleSave();
    setShowConfirmationUnsavedDialog(false);
  };

  const handleConfirmDelete = async (course) => {
    setShowConfirmationDeleteDialog(false);
    setDeleting(true);
    await deleteCourse(course, locale);
  };

  const handlePublish = async (course) => {
    await publish(course);
    setSelectedTab(TABS.DESCRIPTION);
    setLocked(true);
    setShowPublishDialog(false);
  };

  const handlePublishedDelete = async () => {
    setShowPublishedDeleteDialog(false);
    await deletePublishedCourse();
    setLocked(true);
    setDeletePublishedSuccessOpen(true);
  };

  const dialogDelete = () => {
    setShowConfirmationDeleteDialog(true);
  };

  const getErrorMessage = () => {
    switch (errorType) {
      case ERRORS.DELETE:
        return t('backoffice.courses.deleting.error', {
          courseName: selectTranslation(
            course.nameTranslations,
            course.name,
            locale,
            ''
          ),
          message: errorMessage,
        });
      case ERRORS.DELETE_PUBLISHED:
        return t('backoffice.courses.deleting.errorPublished', {
          courseName: selectTranslation(
            course.nameTranslations,
            course.name,
            locale,
            ''
          ),
          message: errorMessage,
        });
      default:
        return t('backoffice.courses.errorProcessingCourse');
    }
  };

  useEffect(() => {
    if (nextTab !== -1 && !submitting && !hasError) {
      clearError();
      setUploadingFiles(false);
      if (nextTab === COURSE_PAGE) {
        navigate('/courses');
      } else {
        setSelectedTab(nextTab);
      }
    }
  }, [submitting]);

  if (loading || deleting) {
    return <CircularSpinner thickness={3} size={125} />;
  }

  if (hasError && !course) {
    return (
      <ErrorWrapper>
        <Icon icon={WarningIcon} color={theme.palette.errorLight} size="3rem" />
        <T variant="headingS">{t('backoffice.courses.errorLoadingCourse')}</T>
        <Button to="/courses">{t('backoffice.courses.backToCourses')}</Button>
      </ErrorWrapper>
    );
  }

  return (
    <>
      <OuterWrapper>
        <HeaderWrapper>
          <LeftWrapper>
            <IconButton
              ariaLabel={t('generic.back')}
              icon={ChevronLeftRoundedIcon}
              onClick={(event) => {
                if (hasChanged) {
                  event.preventDefault();
                  setNextTab(COURSE_PAGE);
                  setShowConfirmationUnsavedDialog(true);
                }
              }}
              to="/courses"
              type="transparent"
            />
            <T variant="headingS">
              {selectTranslation(
                course.nameTranslations,
                course.name,
                locale,
                ''
              )}
            </T>
          </LeftWrapper>
          <RightWrapper>
            <T
              color={
                statusColors[
                  course.archivedScheduledAt
                    ? courseStatus.ARCHIVED
                    : course.status
                ]
              }
              sx={{
                textTransform: 'uppercase',
              }}
              variant="subtitle"
            >
              {course.archivedScheduledAt
                ? t('backoffice.courses.deletingStatus', {
                    date: formatDate({
                      date: course.archiveDate,
                    }),
                  })
                : course.status}
            </T>
            <Tooltip arrow title={t('backoffice.courses.previewTooltip')}>
              <div>
                <IconButton
                  icon={LinkIcon}
                  href={coursePreview(course)}
                  ariaLabel={t('backoffice.courses.previewTooltip')}
                  sxIcon={{
                    transform: 'rotate(-45deg)',
                  }}
                  target="_blank"
                  type="transparent"
                />
              </div>
            </Tooltip>
            {course.status === courseStatus.DRAFT && (
              <Button
                ariaLabel={t('generic.delete')}
                iconRight={DeleteOutlineIcon}
                onClick={dialogDelete}
                type="secondaryOutline"
              >
                {t('generic.delete')}
              </Button>
            )}
            {course.status === courseStatus.PUBLISHED &&
              !course.archivedScheduledAt && (
                <Button
                  ariaLabel={t('generic.delete')}
                  disabled={submitting}
                  iconRight={DeleteOutlineIcon}
                  onClick={() => setShowPublishedDeleteDialog(true)}
                  sx={locked ? { visibility: 'hidden' } : undefined}
                  type="secondaryOutline"
                >
                  {t('generic.delete')}
                </Button>
              )}
            {course.status !== courseStatus.PUBLISHED ? (
              <Tooltip
                arrow
                title={
                  canPublish ? (
                    ''
                  ) : (
                    <>
                      <T color={theme.palette.mainDark}>
                        {t('backoffice.courses.publishing.missingTooltip')}
                      </T>
                      <ValidationList details={validationDetails} />
                    </>
                  )
                }
              >
                <div>
                  <Button
                    ariaLabel={t('backoffice.courses.publish')}
                    disabled={!canPublish}
                    onClick={() => setShowPublishDialog(true)}
                  >
                    {t('backoffice.courses.publish')}
                  </Button>
                </div>
              </Tooltip>
            ) : (
              <Button
                ariaLabel={
                  locked
                    ? t('backoffice.courses.locked')
                    : t('backoffice.courses.editing')
                }
                iconRight={locked ? LockIcon : ModeEditIcon}
                onClick={handleChangeLock}
                type="secondary"
                disabled={!locked}
              >
                {locked
                  ? t('backoffice.courses.locked')
                  : t('backoffice.courses.editing')}
              </Button>
            )}
          </RightWrapper>
        </HeaderWrapper>
        <ConfirmationDialog
          open={showConfirmationDialog}
          title={t('backoffice.courses.unlockDialogTitle')}
          ariaLabel={t('backoffice.courses.unlockDialogTitle')}
          description={t('backoffice.courses.unlockDialogDescription')}
          cancelText={t('generic.cancel')}
          confirmText={t('generic.confirm')}
          onConfirm={handleConfirmUnlock}
          onCancel={() => setShowConfirmationDialog(false)}
          onClose={() => setShowConfirmationDialog(false)}
        />
        <ConfirmationDialog
          open={showConfirmationUnsavedDialog}
          title={t('generic.unsavedChanges')}
          description={t('backoffice.courses.tabUnsavedDescription')}
          ariaLabel={t('generic.unsavedChanges')}
          cancelText={t('generic.discard')}
          confirmText={t('generic.confirm')}
          disabledConfirm={uploadingFiles}
          onConfirm={() => handleConfirmUnsave(handleSave)}
          onCancel={() => {
            setShowConfirmationUnsavedDialog(false);
            if (nextTab === COURSE_PAGE) {
              navigate('/courses');
            } else {
              setSelectedTab(nextTab);
            }
          }}
          onClose={() => setShowConfirmationUnsavedDialog(false)}
        />
        <ConfirmationDialog
          open={showConfirmationDeleteDialog}
          title={t('backoffice.courses.deleting.deleteDialog', {
            courseName: selectTranslation(
              course.nameTranslations,
              course.name,
              locale,
              ''
            ),
          })}
          description={t('backoffice.courses.deleting.tabDeleteDescription')}
          ariaLabel={t('backoffice.courses.deleting.deleteCourse')}
          cancelText={t('backoffice.courses.deleting.discard')}
          confirmText={t('backoffice.courses.deleting.confirm')}
          onConfirm={() => handleConfirmDelete(course)}
          onCancel={() => setShowConfirmationDeleteDialog(false)}
          onClose={() => setShowConfirmationDeleteDialog(false)}
        />
        <PublishDialog
          handlePublish={handlePublish}
          open={showPublishDialog}
          setOpen={setShowPublishDialog}
        />
        <PublishedDeleteDialog
          handleDelete={handlePublishedDelete}
          open={showPublishedDeleteDialog}
          setOpen={setShowPublishedDeleteDialog}
        />
        <TabsMenuWrapper>
          <TabsMenu
            tabsConfig={[
              {
                icon: FormatListBulletedIcon,
                key: TABS.DESCRIPTION,
                label: t('backoffice.courses.description'),
              },
              {
                icon: CorporateFareIcon,
                key: TABS.AVAILABILITY,
                label: t('backoffice.courses.availability'),
              },
              {
                icon: PsychologyIcon,
                key: TABS.LESSONS,
                label: t('backoffice.courses.lessons'),
              },
              {
                icon: QuizOutlinedIcon,
                key: TABS.QUIZ,
                label: t('backoffice.courses.quiz'),
              },
              {
                icon: ImageOutlinedIcon,
                key: TABS.CATALOG,
                label: t('backoffice.courses.catalog'),
              },
              {
                icon: InsertDriveFileOutlinedIcon,
                key: TABS.ATTACHMENTS,
                label: t('backoffice.courses.attachments'),
              },
            ]}
            selectedTab={selectedTab}
            setSelectedTab={handleChangeTab}
            disabled={submitting}
          />
        </TabsMenuWrapper>
        <Tab />
      </OuterWrapper>
      <Snackbar
        type="success"
        message={t('backoffice.courses.deleting.scheduledSuccess', {
          courseName: selectTranslation(
            course.nameTranslations,
            course.name,
            locale,
            ''
          ),
          date: formatDate({
            date: course.archiveDate,
          }),
        })}
        open={deletePublishedSuccessOpen}
        onClose={() => setDeletePublishedSuccessOpen(false)}
      />
      <Snackbar
        type="error"
        message={getErrorMessage()}
        open={hasError}
        onClose={clearError}
      />
      <Snackbar
        type="error"
        detailedErrors={[
          {
            title: t('backoffice.courses.publishing.missingTooltip'),
            element: <ValidationList details={validationDetails} />,
          },
        ]}
        message={t('backoffice.courses.validationErrorPublished')}
        open={validationSnackBarOpen}
        onClose={() => setValidationSnackBarOpen(false)}
      />
    </>
  );
};

export default ManageCourseView;

export const updatePlaylistContentMutation = (variables) => ({
  query: `mutation updatePlaylistContent($input: ModifyPlaylistContentInput!) {
    updatePlaylistContent(input: $input) {
      id
      children
    }
  }`,
  variables,
});

export const copyPlaylistMutation = (
  organizationId,
  { input, managerSelected }
) => ({
  query: `mutation copyPlaylist($input: CopyPlaylistInput!) {
    copyPlaylist(input: $input) {
      id
      titleTranslations
      descriptionTranslations
      ordinal
      copyOf
      refId
      ownerOrganizationId
      domain
      status
      children
      shareType
      publishedAt
      ownerId
      thumbnailUrl
      type
    }
  }`,
  variables: {
    input: {
      ...input,
      organizationId,
      managerId: managerSelected,
    },
  },
});

export const createPlaylistMutation = (organizationId, { input, ownerId }) => ({
  query: `mutation CreatePlaylist(
    $input: CreatePlaylistInput!,
    $organizationId: ID,
    $ownerId: ID
  ) {
    createPlaylist(
      input: $input,
      organizationId: $organizationId,
      ownerId: $ownerId
    ) {
      id
      shortId
      titleTranslations
      descriptionTranslations
      shortDescriptionTranslations
      ordinal
      refId
      status
      publishedAt
      domain
      shareType
      ownerId
      thumbnail {
        bucket
        key
        region
      }
      thumbnailProcessingStatus {
        status
      }
      trailerVideoFiles {
        file {
          Key
          Bucket
          region
        }
      }
      trailerVideoProcessingStatus {
        status
      }
      type
      editorId
    }
  }`,
  variables: {
    input,
    organizationId,
    ownerId,
  },
});

export const deletePlaylistMutation = (variables) => ({
  query: `mutation deletePlaylist($input: DeletePlaylistInput!) {
    deletePlaylist(input: $input) {
      id
    }
  }`,
  variables,
});

export const deleteQuizMutation = (input) => ({
  query: `mutation deleteQuiz($input: DeleteQuizInput!) {
    deleteQuiz(input: $input) {
      id
    }
  }`,
  variables: {
    input,
  },
});

export const removePlaylistContent = (variables) => ({
  query: `mutation removePlaylistContent($input: ModifyPlaylistContentInput!) {
    removePlaylistContent(input: $input) {
      id
      children
    }
  }`,
  variables,
});

export const sortPlaylistContent = (variables) => ({
  query: `mutation sortPlaylistContent($input: SortPlaylistContentInput!) {
    sortPlaylistContent(input: $input) {
      id
      children
    }
  }`,
  variables,
});

export const updatePlaylistMutation = (variables) => ({
  query: `mutation UpdatePlaylist($input: UpdatePlaylistInput!) {
    updatePlaylist(input:$input) {
      id
      shortId
      titleTranslations
      descriptionTranslations
      shortDescriptionTranslations
      ordinal
      refId
      status
      publishedAt
      domain
      thumbnail {
        bucket
        key
        region
      }
      thumbnailProcessingStatus {
        status
      }
      trailerVideoFiles {
        file {
          Key
          Bucket
          region
        }
      }
      trailerVideoProcessingStatus {
        status
      }
      type
      editorId
      archivedScheduledAt
      daysLeft
      archiveDate
    }
  }`,
  variables,
});

export const updatePlaylistAccessMutation = (variables) => ({
  query: `mutation UpdatePlaylistAccess($input: UpdatePlaylistAccessInput!) {
    updatePlaylistAccess(input:$input) {
      message
      success
      shareType
    }
  }`,
  variables,
});

export const transferPlaylist = (organizationId, { input }) => ({
  query: `mutation transferPlaylist($input: TransferPlaylistInput!) {
    transferPlaylist(input: $input) {
      id
    }
  }`,
  variables: {
    input: {
      ...input,
      organizationId,
    },
  },
});

export const createOrUpdateQuiz = ({ input }) => ({
  query: `mutation CreateOrUpdateQuiz(
    $input: CreateOrUpdateQuizInput!
  ) {
    createOrUpdateQuiz(input: $input) {
      id
      playlistId
      courseId
      retakeHours
      gameId
      time {
        minutes
        seconds
      }
      questions {
        id
        text
        options {
          id
          answer
          correct
        }
      }
    }
  }`,
  variables: {
    input: {
      ...input,
    },
  },
});

export const sendPlaylistReminder = (userId, playlistId) => ({
  query: `mutation sendPlaylistReminder(
    $userId: ID,
    $playlistId: ID!
  ) {
    sendPlaylistReminder(userId: $userId, playlistId: $playlistId) {
      success
      message
    }
  }`,
  variables: {
    userId,
    playlistId,
  },
});

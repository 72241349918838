export const selectTranslation = (
  fieldTranslations,
  fieldWithoutTranslation,
  locale,
  defaultValue
) => {
  const fallback = fieldWithoutTranslation || defaultValue;
  try {
    const fieldTranslationsObject = JSON.parse(fieldTranslations);

    return locale && locale in fieldTranslationsObject
      ? fieldTranslationsObject[locale] || fallback
      : fallback;
  } catch (e) {
    return fallback;
  }
};

export const localesEnum = ['no', 'en', 'es'];

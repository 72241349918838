import React from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';

import theme from 'shared/themes/default';

const StyledList = styled.li`
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'not-allowed')};
  margin: 0rem 2rem 0 0;
  position: relative;
  line-height: 1.2rem;
  height: 1.1rem;
  list-style: none;
  color: ${({ active, disabled }) => {
    if (disabled) return theme.palette.disabledDark;
    return active ? theme.palette.brand : theme.palette.lightPure;
  }};
  font-family: ${theme.fonts.primary};
  &:after {
    content: '';
    display: block;
    height: 0.16rem;
    width: 100%;
    position: absolute;
    bottom: -1.1rem;
    left: 0;
    background-color: ${(props) =>
      props.active ? theme.palette.brand : 'transparent'};
    border: 0.1rem solid
      ${(props) => (props.active ? theme.palette.brand : 'transparent')};
  }
  ${({ disabled }) =>
    !disabled &&
    `
    &:hover{
      &:after{
        background-color: ${theme.palette.brand};
        border: 0.1rem solid ${theme.palette.brand};
      }
    }
  `}

  ${theme.breakpoints.tablet} {
    margin: 0rem 1.5rem 1rem 0;
    &:after {
      bottom: -0.25rem;
    }
    &:hover {
      &:after {
        bottom: -0.25rem;
      }
    }
  }
`;

const List = ({ onClick, active, title, disabled, ...props }) => {
  return (
    <StyledList
      onClick={!disabled ? onClick : () => {}}
      active={active}
      disabled={disabled}
      {...props}
    >
      {title}
    </StyledList>
  );
};

List.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.bool,
  title: PropTypes.string,
  disabled: PropTypes.bool,
};

export default List;

import React from 'react';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import theme from 'shared/themes/default';

import Button from 'shared/atoms/Button';
import HorizontalRule from 'shared/atoms/HorizontalRule';
import T from 'shared/atoms/Typography';

import NoSubscription from './NoSubscription';

import { useOrganizationMembers } from '../context';

import { StyledButtonWrapper } from '../styles';

const StyledHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m1};
  margin-top: ${theme.spacing.m2};
`;

const MembersHeader = () => {
  const { t } = useTranslation();

  const {
    availableLicences,
    invitations: { setInviteMembersOpen },
    handleHistory,
    hasSubscription,
    isManager,
    onClickSubscribe,
    registeredMembers,
    setManageGroupsOpen,
    setSelectedAction,
    totalLicences,
    userRoles: { administrateUserRole },
  } = useOrganizationMembers();

  return (
    <StyledHeaderWrapper>
      {!!totalLicences && (
        <>
          <T color={theme.palette.lightPure} variant="headingS">
            {t('administrateUsers.users')}
          </T>
          <div>
            <T color={theme.palette.lightInteracting}>
              {isManager
                ? t('administrateUsers.createGroupsHelper')
                : totalLicences &&
                  totalLicences !== 'unlimited' &&
                  t('administrateUsers.headerDescription', {
                    totalLicences,
                    availableLicences,
                  })}
            </T>
            {isManager ? (
              <T color={theme.palette.lightInteracting}>
                {t('administrateUsers.sharePlaylistsHelper')}
              </T>
            ) : (
              <>
                <T color={theme.palette.lightInteracting}>
                  {t('administrateUsers.inviteMembersHelperGroups')}
                </T>
                <T color={theme.palette.lightInteracting}>
                  {t('administrateUsers.inviteMembersHelperGroupsText')}
                </T>
              </>
            )}
          </div>
          <StyledButtonWrapper>
            {!isManager && (
              <Button
                disabled={
                  totalLicences !== 'unlimited' && availableLicences === 0
                }
                ariaLabel="invite-members"
                onClick={() => {
                  setSelectedAction('');
                  setInviteMembersOpen(true);
                }}
                theme="dark"
                type="primary"
              >
                {t('administrateUsers.inviteMembers')}
              </Button>
            )}
            <Button
              ariaLabel="manage-groups"
              onClick={() => {
                if (handleHistory) {
                  handleHistory('manage=groups');
                }
                setManageGroupsOpen(true);
              }}
              disabled={!registeredMembers?.length}
              theme="dark"
              type="primary"
            >
              {t('administrateUsers.manageGroups')}
            </Button>
            {!isManager && (
              <Button
                ariaLabel="administrate-user-role"
                onClick={administrateUserRole}
                disabled={!registeredMembers?.length}
                theme="dark"
                type="primary"
              >
                {t('administrateUserRole.administerRole')}
              </Button>
            )}
          </StyledButtonWrapper>
        </>
      )}
      {!hasSubscription &&
        (!onClickSubscribe ? (
          <T color={theme.palette.lightPure} variant="headingS">
            {t('administrateUsers.noActiveSubscriptionForOrganization')}
          </T>
        ) : (
          <NoSubscription />
        ))}
      <HorizontalRule />
    </StyledHeaderWrapper>
  );
};

export default MembersHeader;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import T from 'shared/atoms/Typography';
import Autocomplete from 'shared/atoms/Autocomplete';
import Form from 'shared/atoms/Form';
import Dialog from 'shared/molecules/Dialog';
import Tooltip from 'shared/atoms/Tooltip';
import RadioGroup from 'shared/atoms/RadioGroup';
import ConfirmationDialog from 'shared/molecules/ConfirmationDialog';
import Alert from 'shared/atoms/Alert';
import { actionsOnContentOwnership } from 'shared/const';
import theme from 'shared/themes/default';

import {
  AutoCompleteWrapper,
  ContentOwnershipWrapper,
  ContentOwnershipHeader,
  StyledErrorOutlineIcon,
  ContentOwnershipSubHeader,
  TransferContentOptionWrapper,
  SearchManagerWrapper,
  DeleteMembershipAlertText,
} from './styles';

const ManageContentOwnershipDialog = ({
  open,
  selectedUser,
  organizationManagers,
  transferContentOptions,
  onSubmitHandler,
  onCloseHandler,
  formStatus,
}) => {
  const [managers, setManagers] = useState([]);
  const [transferTo, setTransferTo] = useState(null);
  const [selectedTransferOption, setSelectedTransferOption] = useState();
  const [transferOwnership, setTransferOwnership] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [showConfirmationDialogue, setShowConfirmationDialogue] =
    useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setSubmitting(false);
  }, [formStatus]);

  useEffect(() => {
    if (transferContentOptions.length > 0) {
      transferContentOptions.forEach((item) => {
        if (item?.checked) {
          setSelectedTransferOption(item.value);
          if (item.value === actionsOnContentOwnership.transfer) {
            setTransferOwnership(true);
          }
        }
      });
    }
  }, [transferContentOptions]);

  useEffect(() => {
    const sortedManagersByName = organizationManagers
      .sort((a, b) => {
        return a.name?.localeCompare(b.name);
      })
      .filter((user) => user.id !== selectedUser?.id);

    setManagers(sortedManagersByName);
  }, [organizationManagers]);

  const onChangeContentTransferOption = (selectedOption) => {
    if (selectedOption === actionsOnContentOwnership.transfer) {
      setTransferOwnership(true);
    } else {
      setTransferOwnership(false);
    }

    setSelectedTransferOption(selectedOption);
    setIsSubmitDisabled(selectedOption === actionsOnContentOwnership.transfer);
  };

  const onSelectManagerHandler = (manager) => {
    if (!manager) {
      setTransferTo(null);
      setIsSubmitDisabled(true);
      return;
    }

    setTransferTo(manager);
    setIsSubmitDisabled(false);
  };

  const onClose = () => {
    onCloseHandler(false);
  };

  const onSubmit = () => {
    if (selectedTransferOption || transferTo) {
      setShowConfirmationDialogue(true);
    }
  };

  const onConfirmHandler = () => {
    setShowConfirmationDialogue(false);
    setSubmitting(true);
    onSubmitHandler({
      userSub: selectedUser?.id,
      actionOnContent: selectedTransferOption,
      contentTransferTo: transferTo?.id,
    });
  };

  const submitButtonText =
    (selectedTransferOption === actionsOnContentOwnership.transfer &&
      t('administrateUserRole.deleteAccessAndTransferOwnership')) ||
    (selectedTransferOption === actionsOnContentOwnership.delete &&
      t('administrateUserRole.deleteAccessAndContent'));
  const confirmationDialogDescription =
    (selectedTransferOption === actionsOnContentOwnership.transfer &&
      t('administrateUserRole.confirmationDialogTransferAccessDescription')) ||
    (selectedTransferOption === actionsOnContentOwnership.delete &&
      t('administrateUserRole.confirmationDialogDeleteAccessDescription'));
  const confirmationButtonTitle =
    (selectedTransferOption === actionsOnContentOwnership.transfer &&
      t('administrateUserRole.transferAll')) ||
    (selectedTransferOption === actionsOnContentOwnership.delete &&
      t('administrateUserRole.deleteAll'));
  const actions = [
    {
      ariaLabel: submitButtonText,
      disabled: isSubmitDisabled,
      handler: onSubmit,
      label: submitButtonText,
      submit: true,
    },
  ];

  const hasActions = !formStatus?.success && managers.length > 0;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      ariaLabel={t('administrateUserRole.deleteAccessDialogTitle')}
      title={t('administrateUserRole.deleteAccessDialogTitle')}
      loading={submitting}
      actions={hasActions ? actions : null}
    >
      <>
        {showConfirmationDialogue && (
          <ConfirmationDialog
            open={showConfirmationDialogue}
            title={t('administrateUserRole.confirmationDialogTitle')}
            ariaLabel={t('administrateUserRole.confirmationDialogTitle')}
            description={confirmationDialogDescription}
            cancelText={t('generic.cancel')}
            confirmText={confirmationButtonTitle}
            onConfirm={onConfirmHandler}
            onCancel={() => setShowConfirmationDialogue(false)}
            onClose={() => setShowConfirmationDialogue(false)}
          />
        )}
        {!formStatus && (
          <>
            <T color={theme.palette.pureDark}>
              {t('administrateUserRole.deleteAccessDialogDescription')}
            </T>
            <ContentOwnershipWrapper>
              <ContentOwnershipHeader>
                {t('administrateUserRole.contentOwnershipHeader')}
                <Tooltip
                  title={t('administrateUserRole.contentOwnershipHelp')}
                  arrow
                >
                  <StyledErrorOutlineIcon />
                </Tooltip>
              </ContentOwnershipHeader>
              <ContentOwnershipSubHeader>
                {t('administrateUserRole.contentOwnershipSubHeader')}
              </ContentOwnershipSubHeader>

              <Form onSubmit={onSubmit}>
                <TransferContentOptionWrapper>
                  <RadioGroup
                    options={transferContentOptions}
                    onChange={onChangeContentTransferOption}
                    theme="light"
                  />
                </TransferContentOptionWrapper>
                {transferOwnership && (
                  <SearchManagerWrapper>
                    <AutoCompleteWrapper>
                      <Autocomplete
                        filterOptions
                        getOptionLabel={(option) =>
                          (option.name || '').toString()
                        }
                        noOptionsText={t('generic.noSearchResults')}
                        onChange={onSelectManagerHandler}
                        options={managers}
                        placeholder={t(
                          'administrateUserRole.searchPlaceHolder'
                        )}
                      />
                    </AutoCompleteWrapper>
                  </SearchManagerWrapper>
                )}
                <div>
                  <DeleteMembershipAlertText>
                    {t('administrateUserRole.deleteAccessDialogAlertText')}
                  </DeleteMembershipAlertText>
                </div>
              </Form>
            </ContentOwnershipWrapper>
          </>
        )}
        {formStatus && (
          <Alert
            message={formStatus.message}
            severity={formStatus.success ? 'success' : 'error'}
            theme="light"
          />
        )}
      </>
    </Dialog>
  );
};

ManageContentOwnershipDialog.propTypes = {
  open: PropTypes.bool,
  selectedUser: PropTypes.object,
  organizationManagers: PropTypes.array.isRequired,
  transferContentOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      checked: PropTypes.bool,
    })
  ).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  onCloseHandler: PropTypes.func.isRequired,
  formStatus: PropTypes.object,
};

ManageContentOwnershipDialog.defaultProps = {
  open: false,
  selectedUser: null,
  formStatus: null,
};

export default ManageContentOwnershipDialog;

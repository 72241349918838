import React from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import Chip from '@shared/atoms/Chip';
import CircularSpinner from '@shared/atoms/CircularSpinner';
import T from '@shared/atoms/Typography';
import ItemBox from '@shared/molecules/ItemBox';

import theme from '@shared/themes/default';
import { selectTranslation } from '@shared/utils';

import { useAppGlobals } from '../../../contexts/AppContext';

const CategoriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.s};
`;

const CategoriesList = ({
  categories,
  handleDeleteCategory,
  filter,
  isLocked,
  loading,
  selectedCategory,
  setSelectedCategory,
}) => {
  const { locale } = useAppGlobals();
  const { t } = useTranslation();

  if (loading) return <CircularSpinner static />;

  const titleList = (
    <T color={theme.palette.lightPure}>
      {t('backoffice.categories.listingCategories', {
        count: categories.length,
      })}
    </T>
  );

  if (categories.length === 0 && !filter) {
    return (
      <CategoriesWrapper>
        {titleList}
        <ItemBox
          key="empty-list"
          id="empty-list"
          menuItems={[]}
          theme="dark"
          title={t('backoffice.categories.noCategories')}
        />
      </CategoriesWrapper>
    );
  }

  if (categories.length === 0) {
    return (
      <CategoriesWrapper>
        {titleList}
        <ItemBox
          key="no-coincidences"
          id="no-coincidences"
          menuItems={[]}
          theme="dark"
          title={t('backoffice.categories.noFoundCategories', {
            string: filter,
          })}
        />
      </CategoriesWrapper>
    );
  }

  return (
    <CategoriesWrapper>
      {titleList}
      {categories.map(({ courses, id, ordinal, titleTranslations }) => {
        const coursesCount = (courses?.items || []).length;
        return (
          <ItemBox
            buttons={
              coursesCount === 0
                ? [
                    {
                      disabled: isLocked,
                      handler: handleDeleteCategory,
                      id: 'delete',
                      label: t('backoffice.categories.delete'),
                      textStyle: true,
                    },
                  ]
                : []
            }
            key={id}
            id={id}
            menuItems={[]}
            onClickHandler={
              !isLocked
                ? (id) =>
                    setSelectedCategory(
                      categories.find((category) => category.id === id)
                    )
                : null
            }
            selected={selectedCategory?.id === id}
            theme="dark"
            title={selectTranslation(titleTranslations, null, locale, '')}
            topTag={
              <Chip
                color={theme.palette.lightPure}
                icon={<FormatListBulletedIcon />}
                label={String(ordinal || 0)}
                small
                theme="dark"
              />
            }
            topText={t('backoffice.categories.courses', {
              count: coursesCount,
            })}
          />
        );
      })}
    </CategoriesWrapper>
  );
};

CategoriesList.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
  handleDeleteCategory: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  isLocked: PropTypes.bool,
  loading: PropTypes.bool,
  selectedCategory: PropTypes.object,
  setSelectedCategory: PropTypes.func.isRequired,
};

CategoriesList.defaultProps = {
  categories: [],
  isLocked: false,
  loading: false,
};

export default CategoriesList;

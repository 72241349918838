import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { graphqlOperation } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import WatchData from '@shared/sections/WatchData';
import { WatchDataProvider } from '@shared/sections/WatchData/Context';
import theme from '@shared/themes/default';
import Autocomplete from '@shared/atoms/Autocomplete';
import T from '@shared/atoms/Typography';

import { useAppGlobals } from '../../contexts/AppContext';
import { graphql, listOrganizationOptions } from '../../../utils';
import { sendPlaylistReminder } from './graphql/mutations';
import {
  downloadWatchData,
  getCourseWatchData,
  getCourseWithGlobalWatchData,
  getOrganization,
  getUserPlaylistWatchData,
  getUserWatchData,
  listAggregatedWatchData,
  listCoursesWatchData,
  listCoursesWithGlobalWatchData,
  listGroupsWatchData,
  listGroupUsersWatchData,
  listNanodegreeStatsWatchData,
  listNanodegreesWatchData,
  listOrganizationsNames,
  listOrganizationsWatchData,
  listPlaylistGroupsWatchData,
  listPlaylistsWatchData,
  listPlaylistUsersWatchData,
  listUsersWatchData,
} from './graphql/queries';

const AutocompleteWrapper = styled.div`
  max-width: 400px;
  margin-bottom: 1.5rem;
`;

const ORGANIZATION_GLOBAL = 'global';

const WatchDataStatisticsView = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { domain, locale, user } = useAppGlobals();
  const { t } = useTranslation();

  const searchParams = new URLSearchParams(search);
  const preSelectedOrganizationId = searchParams.get('organization');
  const view = searchParams.get('view');
  const id = searchParams.get('id');

  const [organizationId, setOrganizationId] = useState(
    preSelectedOrganizationId || ORGANIZATION_GLOBAL
  );
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [nextUrl, setNextUrl] = useState();

  const fetchOrganization = async (organizationId) => {
    const response = await graphql(
      graphqlOperation(getOrganization, { id: organizationId })
    );
    const selectedOrganizationInfo = response.data.getOrganization;

    if (selectedOrganizationInfo) {
      const { name, id: value } = selectedOrganizationInfo;
      setSelectedOrganization({
        name,
        value,
      });
    }
  };

  useEffect(() => {
    if (preSelectedOrganizationId) {
      fetchOrganization(preSelectedOrganizationId);
    }
  }, [preSelectedOrganizationId]);

  useEffect(() => {
    if (nextUrl) {
      navigate(nextUrl);
    }
  }, [nextUrl]);

  const fetchOrganizations = async () => {
    const response = await graphql(
      graphqlOperation(listOrganizationsNames, { domain })
    );
    return listOrganizationOptions(
      response?.data?.listOrganizations.items || []
    );
  };

  const handleHistory = (queryParams) => {
    if (preSelectedOrganizationId) {
      setNextUrl(
        `/activity?organization=${preSelectedOrganizationId}&${queryParams}`
      );
    } else if (queryParams) {
      setNextUrl(`/activity?${queryParams}`);
    } else {
      setNextUrl('/activity');
    }
  };

  const selectOrganizationHandler = (organization) => {
    const { value: organizationId } = organization || {};

    if (organizationId) {
      setOrganizationId(organizationId);
      setSelectedOrganization(organization);
      navigate(
        view
          ? `/activity?organization=${organizationId}&view=${view}`
          : `/activity?organization=${organizationId}`
      );
    } else {
      setOrganizationId(ORGANIZATION_GLOBAL);
      setSelectedOrganization(null);
      navigate(
        ['users', 'courses', 'nanodegrees'].includes(view)
          ? `/activity?view=${view}`
          : '/activity?view=users'
      );
    }
  };

  const getGraphQlQueryWithOrganization = (query, organizationId) => {
    const graphQlQuery = async (...params) =>
      graphql(query(organizationId, ...params));
    return graphQlQuery;
  };

  const getGraphQlQuery = (query) => {
    const graphQlQuery = async (...params) => graphql(query(...params));
    return graphQlQuery;
  };

  const isGlobalOrganization = organizationId === ORGANIZATION_GLOBAL;

  return (
    <div>
      <T
        color={theme.palette.lightPure}
        sx={{
          marginBottom: theme.spacing.m1,
        }}
        variant="subtitle"
      >
        {t('backoffice.general.findOrganization')}
      </T>
      <AutocompleteWrapper>
        <Autocomplete
          getAllOptions={fetchOrganizations}
          getOptionKey={(option) => option.value}
          getOptionLabel={(option) => option.name}
          onChange={selectOrganizationHandler}
          preSelectedValue={selectedOrganization}
        />
      </AutocompleteWrapper>
      <WatchDataProvider
        downloadWatchData={getGraphQlQueryWithOrganization(
          downloadWatchData,
          organizationId
        )}
        downloadWatchDataManualOrganization={getGraphQlQuery(downloadWatchData)}
        getCourseWatchData={
          isGlobalOrganization
            ? getGraphQlQuery(getCourseWithGlobalWatchData)
            : getGraphQlQueryWithOrganization(
                getCourseWatchData,
                organizationId
              )
        }
        getUserPlaylistWatchData={getGraphQlQueryWithOrganization(
          getUserPlaylistWatchData,
          organizationId
        )}
        getUserWatchData={getGraphQlQueryWithOrganization(
          getUserWatchData,
          organizationId
        )}
        listAggregatedWatchData={getGraphQlQuery(listAggregatedWatchData)}
        listCoursesWatchData={
          isGlobalOrganization
            ? getGraphQlQuery(listCoursesWithGlobalWatchData)
            : getGraphQlQueryWithOrganization(
                listCoursesWatchData,
                organizationId
              )
        }
        listGroupsWatchData={getGraphQlQueryWithOrganization(
          listGroupsWatchData,
          organizationId
        )}
        listGroupUsersWatchData={getGraphQlQueryWithOrganization(
          listGroupUsersWatchData,
          organizationId
        )}
        listNanodegreeStatsWatchData={getGraphQlQuery(
          listNanodegreeStatsWatchData
        )}
        listNanodegreesWatchData={getGraphQlQuery(listNanodegreesWatchData)}
        listOrganizationsWatchData={getGraphQlQuery(listOrganizationsWatchData)}
        listPlaylistGroupsWatchData={getGraphQlQueryWithOrganization(
          listPlaylistGroupsWatchData,
          organizationId
        )}
        listPlaylistsWatchData={getGraphQlQueryWithOrganization(
          listPlaylistsWatchData,
          organizationId
        )}
        listPlaylistUsersWatchData={getGraphQlQueryWithOrganization(
          listPlaylistUsersWatchData,
          organizationId
        )}
        listUsersWatchData={getGraphQlQueryWithOrganization(
          listUsersWatchData,
          organizationId
        )}
        locale={locale}
        organizationSelectedId={organizationId}
        sendPlaylistReminder={getGraphQlQuery(sendPlaylistReminder)}
        user={user}
      >
        <WatchData
          handleHistory={handleHistory}
          id={id}
          isBackOffice
          selectOrganizationHandler={selectOrganizationHandler}
          view={view}
        />
      </WatchDataProvider>
    </div>
  );
};

export default WatchDataStatisticsView;

export const firstLetterCaps = (str = null) =>
  !str ? '' : str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const toTitleCase = (str) =>
  str
    .toLowerCase()
    .split(' ')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');

export const textTruncate = (str, length = 100, ending = '...') => {
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  }
  return str;
};

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import T from 'shared/atoms/Typography';
import TableWithSearchAndExport from 'shared/organisms/WatchData/TableWithSearchAndExport';
import AllCoursesOverviewTable from 'shared/organisms/WatchData/AllCoursesOverviewTable';
import CourseWatchDataTable from 'shared/organisms/WatchData/CourseWatchDataTable';
import EpisodesSeenGraph from 'shared/organisms/WatchData/EpisodesSeenGraph';
import Chip from 'shared/atoms/Chip';
import Pill from 'shared/atoms/Pill';
import theme from 'shared/themes/default';
import {
  ORGANIZATION_GLOBAL,
  TYPE_WATCH_DATA,
  courseStatus,
} from 'shared/const';
import { useWatchData } from '../Context';
import { mapAggregatedWatchDataGraph, callApi } from '../utils';

const Wrapper = styled.div`
  max-width: 100%;
`;

const WrapperPill = styled.div`
  display: flex;
  margin-top: 108px;
  ${theme.breakpoints.mobile} {
    margin-top: 64px;
  }
`;

const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${theme.spacing.m1};
  margin-bottom: ${theme.spacing.m3};
  margin-top: ${theme.spacing.m2};
`;

const ShowCourseData = ({ id, onSelectItem, isBackOffice }) => {
  const { t, i18n } = useTranslation();
  const { language: locale } = i18n;
  const {
    organizationSelectedId,
    fetchCoursesWatchData,
    allCoursesStats,
    loadingCoursesWatchData,
    getCourseWatchData,
    user,
    downloadWatchData,
    courseSelected,
    setCourseSelected,
    listAggregatedWatchData,
    setWatchDataError,
  } = useWatchData();
  const [courseWatchData, setCourseWatchData] = useState([]);
  const [loadingCourseWatchData, setLoadingCourseWatchData] = useState(false);
  const [courseNextToken, setCourseNextToken] = useState();
  const [aggregatedWatchData, setAggregatedWatchData] = useState([]);
  const [loadingAggregated, setLoadingAggregated] = useState(false);

  const isGlobalOrganization = organizationSelectedId === ORGANIZATION_GLOBAL;

  const handleError = (error) => {
    console.error('Error calling getCourseWatchData', error);
    setLoadingCourseWatchData(false);
    setWatchDataError(true);
  };

  const fetchCourseWatchData = async (nextToken = null) => {
    try {
      const { data, error } = await getCourseWatchData(
        courseSelected.id,
        nextToken
      );

      if (error) return handleError(error);

      const { items, nextToken: newNextToken } = isGlobalOrganization
        ? data.getCourseWithGlobalWatchData
        : data.getCourseWatchData;

      if (!nextToken) {
        setCourseWatchData(items);
      } else {
        setCourseWatchData((users) => [...users, ...items]);
      }

      if (newNextToken) {
        setCourseNextToken(newNextToken);
      } else {
        setLoadingCourseWatchData(false);
      }
    } catch (error) {
      handleError(error);
    }
    return true;
  };

  useEffect(() => {
    if (organizationSelectedId) {
      setCourseSelected(null);
    }

    fetchCoursesWatchData();
  }, [organizationSelectedId]);

  useEffect(() => {
    const params = {};
    let type = TYPE_WATCH_DATA.COURSES;

    if (isGlobalOrganization) {
      type = TYPE_WATCH_DATA.COURSES_GLOBAL;
      if (courseSelected) {
        type = TYPE_WATCH_DATA.COURSE_GLOBAL;
        params.courseId = courseSelected.id;
      }
    } else {
      params.organizationId = organizationSelectedId;
      if (courseSelected) {
        type = TYPE_WATCH_DATA.COURSE;
        params.courseId = courseSelected.id;
      }
    }
    callApi({
      setLoading: setLoadingAggregated,
      setData: setAggregatedWatchData,
      apiMethod: listAggregatedWatchData,
      setError: setWatchDataError,
      type,
      params,
    });
  }, [organizationSelectedId, courseSelected]);

  useEffect(() => {
    if (id && !loadingCoursesWatchData) {
      const course = allCoursesStats.find((item) => item.id === id);
      if (course) {
        setCourseSelected(course);
      } else {
        onSelectItem();
      }
    }
  }, [allCoursesStats, loadingCoursesWatchData]);

  useEffect(() => {
    if (courseNextToken) {
      fetchCourseWatchData(courseNextToken);
    }
  }, [courseNextToken]);

  useEffect(() => {
    if (courseSelected) {
      setLoadingCourseWatchData(true);
      fetchCourseWatchData();
      onSelectItem(courseSelected.id);
    }
  }, [courseSelected]);

  let courseTitle = '';

  if (courseSelected) {
    courseTitle = courseSelected.name;
  } else {
    courseTitle =
      isBackOffice && organizationSelectedId !== ORGANIZATION_GLOBAL
        ? t('watchData.titleAllCoursesOrganization')
        : t('watchData.allCourses');
  }

  return (
    <Wrapper>
      <WrapperPill>
        <Pill
          color={theme.palette.lightPure}
          fontColor={theme.palette.mainDark}
        >
          {t('watchData.course').toUpperCase()}
        </Pill>
      </WrapperPill>
      <TitleWrapper>
        <T
          color={theme.palette.brand}
          fontWeight="medium"
          phoneVariant="headingXs"
          variant="headingS"
        >
          {courseTitle}
        </T>
        {courseSelected?.status === courseStatus.ARCHIVED && (
          <Chip
            color={theme.palette.errorLight}
            label={t('watchData.deleted')}
            theme="dark"
          />
        )}
      </TitleWrapper>
      <EpisodesSeenGraph
        data={mapAggregatedWatchDataGraph(aggregatedWatchData, locale)}
        loading={loadingAggregated}
      />
      {!courseSelected && (
        <TableWithSearchAndExport
          itemsToDisplay={allCoursesStats.filter((item) => item.name)}
          downloadItems={() =>
            downloadWatchData({
              watchData: isGlobalOrganization
                ? TYPE_WATCH_DATA.COURSES_GLOBAL
                : TYPE_WATCH_DATA.COURSES,
            })
          }
          user={user}
          tableTitle={t('watchData.activitiesOfYourCourses')}
          searchPlaceHolder={t('watchData.searchForCourses')}
          TableToDisplay={AllCoursesOverviewTable}
          showRowsAreClickableMessage
          tableProps={{ selectCourseHandler: setCourseSelected }}
          loading={loadingCoursesWatchData}
          spinnerDuration={isGlobalOrganization ? 10 : 45}
          emptyMessage={t('watchData.noActivity')}
        />
      )}
      {courseSelected && (
        <TableWithSearchAndExport
          itemsToDisplay={courseWatchData}
          downloadItems={() =>
            downloadWatchData({
              watchData: isGlobalOrganization
                ? TYPE_WATCH_DATA.COURSE_GLOBAL
                : TYPE_WATCH_DATA.COURSE,
              courseId: courseSelected.id,
            })
          }
          user={user}
          tableTitle={t('watchData.activityOfYourUsers')}
          searchPlaceHolder={t('watchData.searchForUsers')}
          TableToDisplay={CourseWatchDataTable}
          loading={loadingCourseWatchData}
          spinnerDuration={15}
          emptyMessage={t('watchData.noCourseActivity')}
        />
      )}
    </Wrapper>
  );
};

ShowCourseData.propTypes = {
  id: PropTypes.string,
  onSelectItem: PropTypes.func.isRequired,
  isBackOffice: PropTypes.bool,
};

export default ShowCourseData;

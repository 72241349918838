import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Alert from '@shared/atoms/Alert';
import FormAutocompleteWithList from '@shared/atoms/FormFields/FormAutocompleteWithList';

import theme from '@shared/themes/default';
import { AUTOCOMPLETEWITHLIST_TYPE } from '@shared/const';

const SearchableListWrapper = styled.article`
  display: flex;
  flex-direction: column;
`;

const HeaderStyled = styled.header`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.s};
`;

const SearchableList = ({
  disabled,
  emptyText,
  error,
  limit,
  loading,
  loadingText,
  onAdd,
  onDelete,
  options,
  searchInfoText,
  searchOptions,
  title,
}) => {
  const { t } = useTranslation();

  return (
    <SearchableListWrapper>
      <HeaderStyled>
        <FormAutocompleteWithList
          disabled={disabled}
          filterOptions
          id={`search-${title}`}
          infoText={searchInfoText}
          items={searchOptions}
          key={`search-${title}`}
          label={title}
          limit={limit}
          loading={loading}
          loadingText={loadingText}
          name={`search-${title}`}
          noOptionsText={emptyText}
          onAdd={onAdd}
          onDelete={onDelete}
          selectedItems={options}
          placeholder={t('backoffice.courses.catalogTab.search')}
          required
          theme="dark"
          type={AUTOCOMPLETEWITHLIST_TYPE.FORM}
        />
      </HeaderStyled>
      {error && (
        <Alert
          message={error}
          severity="error"
          sx={{
            marginTop: theme.spacing.s,
          }}
          theme="dark"
        />
      )}
    </SearchableListWrapper>
  );
};

SearchableList.propTypes = {
  disabled: PropTypes.bool,
  emptyText: PropTypes.string.isRequired,
  error: PropTypes.string,
  limit: PropTypes.number,
  loading: PropTypes.bool,
  loadingText: PropTypes.string.isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  searchInfoText: PropTypes.string,
  searchOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  title: PropTypes.string.isRequired,
};

SearchableList.defaultProps = {
  limit: 0,
};

export default SearchableList;

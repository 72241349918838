import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Button from 'shared/atoms/Button';
import CircularSpinner from 'shared/atoms/CircularSpinner';
import SearchField from 'shared/atoms/SearchField';
import Tooltip from 'shared/atoms/Tooltip';
import T from 'shared/atoms/Typography';

import Menu from 'shared/molecules/Menu';
import SimpleTabView from 'shared/molecules/SimpleTabView';

import Table, { CELL_TYPES, ORDER_TYPES } from 'shared/organisms/Table';

import theme from 'shared/themes/default';
import { registeredMembersRoles } from 'shared/const';
import { downloadFile } from 'shared/utils';

import FilterDropdown from '../FilterDropdown';
import Groups from '../Groups';
import Totals from '../Totals';

import { useOrganizationMembers } from '../../context';

const StyledExportBlock = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 75%;
  margin: 1rem 1rem 1rem 0;
  ${theme.breakpoints.mobile} {
    width: 100%;
  }
`;

const StyledBlock = styled.div`
  min-width: 25%;
  ${theme.breakpoints.mobile} {
    width: 100%;
  }
`;

const StyledFilterWrapper = styled.div`
  margin-top: 0.5rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  ${theme.breakpoints.mobile} {
    display: block;
  }
`;

const SimpleTabViewWrapper = styled.div`
  padding-top: 30px;
`;

const StyledRegisteredTableWrapper = styled.div`
  margin: 1rem 0 1rem 0 1rem;
  width: 100%;
`;
const StyledLoadingWrapper = styled.div`
  margin: ${theme.spacing.l} 0;
`;

const StyledList = styled.ul`
  margin: 8px 12px;
  padding-left: 16px;
`;

const MemberNameWrapper = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const GroupCountWrapper = styled.div`
  font-weight: bold;
  &:hover {
    cursor: pointer;
    color: ${theme.palette.brand};
  }
`;

const Registered = () => {
  const { t } = useTranslation();

  const {
    deleteOwnUser,
    downloadOrganizationMembers,
    groups: { groupsInfo, loadingGroupsInfo },
    handleHistory,
    isManager,
    listView,
    locale,
    organizations: { loadingRegisteredMembers },
    registeredMembers,
    user,
    userRoles: { administrateUserRole },
    users: { onDeleteUser },
  } = useOrganizationMembers();

  const [filteredRegisteredMembers, setFilteredRegisteredMembers] = useState();
  const [loadingExport, setLoadingExport] = useState(false);
  const [membersCount, setMembersCount] = useState({});
  const [searchedRegisteredMembers, setSearchedRegisteredMembers] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(
    listView === 'groups' || isManager ? 1 : 0
  );
  const [totalsData, setTotalsData] = useState({});
  const [totalGroups, setTotalGroups] = useState(0);
  const [userId, setUserId] = useState(0);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    const count = {
      admins: 0,
      managers: 0,
      members: 0,
      total: 0,
    };

    if (registeredMembers) {
      registeredMembers.forEach((member) => {
        if (member.roleId === registeredMembersRoles.admin) count.admins += 1;
        else if (member.roleId === registeredMembersRoles.manager)
          count.managers += 1;
        else count.members += 1;
        count.total += 1;
      });
    }
    setMembersCount(count);
  }, [registeredMembers]);

  useEffect(() => {
    const data = {};
    data[t('administrateUsers.members')] = membersCount.members;
    data[
      !isManager
        ? t('administrateUsers.groups')
        : t('administrateUsers.myGroups')
    ] = totalGroups;
    data[t('administrateUsers.admins')] = membersCount.admins;
    data[t('administrateUsers.playlistManagers')] = membersCount.managers;
    setTotalsData(data);
  }, [isManager, membersCount, totalGroups]);

  useEffect(() => {
    setUserId(user.attributes && user.attributes.sub);
  }, [user?.attributes]);

  useEffect(() => {
    setTotalGroups(groupsInfo.length);
  }, [groupsInfo]);

  useEffect(() => {
    if (!totalGroups && !loadingGroupsInfo && selectedIndex === 1) {
      setSelectedIndex(0);
    }
  }, [loadingGroupsInfo, selectedIndex, totalGroups]);

  const filterOptions = (t) => [
    { id: registeredMembersRoles.admin, label: t('administrateUsers.admins') },
    {
      id: registeredMembersRoles.manager,
      label: t('administrateUsers.playlistManagers'),
    },
    {
      id: registeredMembersRoles.member,
      label: t('administrateUsers.members'),
    },
  ];

  const getRoleText = (role) => {
    switch (role) {
      case registeredMembersRoles.admin:
        return t('administrateUsers.administrator');
      case registeredMembersRoles.manager:
        return t('administrateUsers.manager');
      case registeredMembersRoles.member:
        return t('administrateUsers.member');
      default:
        return role;
    }
  };

  useEffect(() => {
    setColumns([
      {
        key: 'name',
        label: t('administrateUsers.name'),
        sticky: true,
        type: CELL_TYPES.COMPONENT,
        render: (row) => (
          <Tooltip title={row.email}>
            <MemberNameWrapper>
              {`${row.name} ${userId === row.id ? ` (${t('administrateUsers.you')})` : ''}`}
            </MemberNameWrapper>
          </Tooltip>
        ),
        maskValue: true,
      },
      {
        customSort: (a, b, order) => {
          const keyA = getRoleText(a.roleId);
          const keyB = getRoleText(b.roleId);

          return order === ORDER_TYPES.ASC
            ? keyA.localeCompare(keyB, locale, { sensitivity: 'base' })
            : keyB.localeCompare(keyA, locale, { sensitivity: 'base' });
        },
        key: 'roleId',
        label: t('administrateUsers.role'),
        type: CELL_TYPES.COMPONENT,
        render: (row) => getRoleText(row.roleId),
      },
      {
        key: 'groups',
        label: t('administrateUsers.inGroups'),
        type: CELL_TYPES.COMPONENT,
        render: (row) =>
          row.groups.length && (
            <Tooltip
              title={
                <StyledList>
                  {row.groups.map((group) => (
                    <li key={group.id}>{group.name}</li>
                  ))}
                </StyledList>
              }
              placement="left"
              arrow
            >
              <GroupCountWrapper>{row.groups.length}</GroupCountWrapper>
            </Tooltip>
          ),
      },
      {
        key: 'lastLoginAt',
        label: t('administrateUsers.lastLogin'),
        type: CELL_TYPES.DATE,
      },
      {
        key: 'createdAt',
        label: t('administrateUsers.userSince'),
        type: CELL_TYPES.DATE,
      },
      ...(!isManager
        ? [
            {
              key: 'actions',
              disableSorting: true,
              type: CELL_TYPES.COMPONENT,
              render: (row) => {
                const menuItems = [
                  {
                    label: t('generic.delete'),
                    handler: () => onDeleteUser(row),
                  },
                  {
                    label: t('administrateUserRole.changeRole'),
                    handler: () => administrateUserRole(row),
                  },
                ];
                return (
                  (!(userId === row.id) || deleteOwnUser) && (
                    <Menu menuItems={menuItems} theme="dark" />
                  )
                );
              },
            },
          ]
        : []),
    ]);
  }, [administrateUserRole, deleteOwnUser, isManager, onDeleteUser, userId]);

  const downloadReport = async () => {
    try {
      setLoadingExport(true);

      // Call backend to generate the file and return the required info for building the download URL
      const { data, error } = await downloadOrganizationMembers();
      if (error) {
        console.error('Error calling downloadOrganizationMembers', error);
        setLoadingExport(false);
        return;
      }
      const {
        downloadOrganizationMembers: { cloudfrontUrl, fileName },
      } = data;

      await downloadFile(
        user?.signInUserSession?.accessToken?.jwtToken,
        cloudfrontUrl,
        fileName
      );
      setLoadingExport(false);
    } catch (error) {
      setLoadingExport(false);
      console.error('Some error happened when building the export file', error);
    }
  };

  const onSearchHandler = ({ value }) => {
    setSearchTerm(value);

    if (value) {
      const searchedRegisteredMembers = (
        filteredRegisteredMembers || registeredMembers
      ).filter((member) => {
        return (
          member.name.toLowerCase().includes(value.toLowerCase()) ||
          member.email.toLowerCase().includes(value.toLowerCase())
        );
      });
      setSearchedRegisteredMembers(searchedRegisteredMembers);
    } else {
      setSearchedRegisteredMembers(null);
    }
  };

  const onFilterChange = (values) => {
    if (values.length > 0) {
      let filteredRegisteredMembers = null;
      filteredRegisteredMembers = registeredMembers.filter(
        (item) => item.roleId && values.includes(item.roleId)
      );
      setFilteredRegisteredMembers(filteredRegisteredMembers);
    } else {
      setFilteredRegisteredMembers(null);
    }
    setSelectedFilters(values);
  };

  const onSwitchTab = ({ index }) => {
    setSelectedIndex(index);

    if (handleHistory && index === 1) {
      handleHistory('view=groups');
    }

    if (handleHistory && index === 0) {
      handleHistory('view=members');
    }
  };

  if (loadingRegisteredMembers) {
    return (
      <StyledLoadingWrapper>
        <CircularSpinner
          thickness={3}
          size={125}
          static
          bottomText={t('generic.pleaseWait')}
        />
      </StyledLoadingWrapper>
    );
  }

  return (
    <>
      <T
        color={theme.palette.lightPure}
        fontWeight="medium"
        sx={{ mb: theme.spacing.s, mt: theme.spacing.m1 }}
        variant="subtitle"
      >
        {t('administrateUsers.registeredMembersSummary', {
          count: membersCount.total,
        })}
      </T>
      {!!membersCount.total && (
        <>
          <Totals columns={2} data={totalsData} />
          <SimpleTabViewWrapper>
            <SimpleTabView onSwitch={onSwitchTab} selectedIndex={selectedIndex}>
              <SimpleTabView.Panel
                hideBorder
                title={t('administrateUsers.showMembers')}
              >
                {!!membersCount.total && (
                  <>
                    <StyledFilterWrapper>
                      <StyledExportBlock>
                        <FilterDropdown
                          options={filterOptions(t)}
                          selectedFilters={selectedFilters}
                          onChange={onFilterChange}
                        />
                        <Button
                          ariaLabel={t('administrateUsers.exportAll')}
                          onClick={downloadReport}
                          loading={loadingExport}
                          theme="dark"
                          type="primary"
                        >
                          {t('administrateUsers.exportAll')}
                        </Button>
                      </StyledExportBlock>
                      <StyledBlock>
                        <SearchField
                          type="secondary"
                          placeholder={t('administrateUsers.searchForPeople')}
                          ariaLabel={t('administrateUsers.searchForPeople')}
                          onChange={onSearchHandler}
                          value={searchTerm}
                        />
                      </StyledBlock>
                    </StyledFilterWrapper>
                    <StyledRegisteredTableWrapper>
                      <Table
                        ariaLabel="Registered members table"
                        columns={columns}
                        items={
                          searchedRegisteredMembers ||
                          filteredRegisteredMembers ||
                          registeredMembers
                        }
                      />
                    </StyledRegisteredTableWrapper>
                  </>
                )}
              </SimpleTabView.Panel>
              <SimpleTabView.Panel
                hideBorder
                title={t('administrateUsers.showGroups')}
                disabled={!totalGroups}
              >
                <Groups />
              </SimpleTabView.Panel>
            </SimpleTabView>
          </SimpleTabViewWrapper>
        </>
      )}
    </>
  );
};

export default Registered;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSortable, defaultAnimateLayoutChanges } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Chip from 'shared/atoms/Chip';
import Button from 'shared/atoms/Button';
import Icon from 'shared/atoms/Icon';
import T from 'shared/atoms/Typography';
import { BACKGROUND_THEMES } from 'shared/const';
import Menu from 'shared/molecules/Menu';
import theme from 'shared/themes/default';
import PlaceholderBackground from 'shared/assets/placeholder_background.png';

const COLOR_MAP = {
  dark: {
    background: theme.palette.darkWrapper,
    backgroundHover: theme.palette.darkDistinct,
    backgroundSelected: theme.palette.darkDistinct,
    border: theme.palette.mainDark,
    borderSelected: theme.palette.lightPure,
    titleIconBackground: theme.palette.pureDark,
  },
  light: {
    background: theme.palette.lightPure,
    backgroundHover: theme.palette.lightDistinct,
    backgroundSelected: theme.palette.disabledLight,
    border: theme.palette.mainDark,
    borderSelected: theme.palette.lightInteracting,
    titleIconBackground: theme.palette.lightInteracting,
  },
};

const OuterWrapper = styled.div`
  background-color: ${(props) =>
    props.selected
      ? COLOR_MAP[props.theme].backgroundSelected
      : COLOR_MAP[props.theme].background};
  border: ${(props) =>
    props.selected
      ? `2px solid ${COLOR_MAP[props.theme].borderSelected}`
      : `1px solid ${COLOR_MAP[props.theme].border}`};
  border-radius: 6px;
  box-sizing: border-box;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'auto')};
  display: flex;
  gap: ${theme.spacing.m1};
  min-height: 80px;
  padding: ${(props) => (props.selected ? '2px 14px' : '3px 15px')};
  &:hover {
    background-color: ${(props) => {
      if (props.selected) {
        return COLOR_MAP[props.theme].backgroundSelected;
      }
      return props.onClick
        ? COLOR_MAP[props.theme].backgroundHover
        : COLOR_MAP[props.theme].background;
    }};
  }
  ${theme.breakpoints.tablet} {
    min-height: 96px;
  }
`;

const DragIconWrapper = styled.div`
  cursor: grab;
  display: flex;
  flex-direction: column;
  justify-content: center;
  touch-action: none;
`;

const Thumbnail = styled.img`
  align-self: center;
  border-radius: 8px;
  height: 64px;
  object-fit: cover;
  width: 100px;
`;

const IconThumbnail = styled.div`
  align-items: center;
  align-self: center;
  background-image: url('${PlaceholderBackground}');
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  height: 64px;
  object-fit: cover;
  width: 100px;
`;

const MainContentBlock = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  max-height: 72px;
  ${theme.breakpoints.tablet} {
    max-height: 88px;
  }
`;

const TopBlock = styled.div`
  align-items: center;
  display: flex;
  gap: ${theme.spacing.s};
  min-height: 16px;
`;

const TitleBlock = styled.div`
  align-items: center;
  display: flex;
  gap: ${theme.spacing.s};
  width: 100%;
  overflow: hidden;
`;

const TitleIconWrapper = styled.div`
  align-items: center;
  background-color: ${(props) => COLOR_MAP[props.theme].titleIconBackground};
  border-radius: 16px;
  display: flex;
  justify-content: center;
  height: 32px;
  width: 32px;
`;

const TagsBlock = styled.div`
  display: flex;
  gap: ${theme.spacing.s};
  min-height: 16px;
`;

const ButtonsBlock = styled.div`
  align-self: center;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  gap: ${theme.spacing.m1};
  ${theme.breakpoints.tablet} {
    align-items: center;
    flex-direction: column;
    gap: ${theme.spacing.s};
  }
`;

const MenuBlock = styled.div`
  align-self: center;
`;

const ItemBox = ({
  animated,
  buttons,
  draggable,
  error,
  id,
  menuItems,
  onClickHandler,
  selected,
  tags,
  theme: themeLayout,
  thumbnailImgSrc,
  thumbnailIcon,
  title,
  titleIcon,
  topTag,
  topText,
  topTextUppercase,
}) => {
  const animateLayoutChanges = (args) => {
    const { isSorting, wasDragging } = args;

    if (isSorting || wasDragging) {
      return defaultAnimateLayoutChanges(args);
    }

    return true;
  };

  const useSortableOptions = {
    id,
  };
  if (animated) useSortableOptions.animateLayoutChanges = animateLayoutChanges;

  const { listeners, setNodeRef, transform, transition } =
    useSortable(useSortableOptions);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const adaptedMenuItems = menuItems.map(({ label, handler, disabled }) => ({
    label,
    handler: () => {
      handler(id, title);
    },
    disabled,
  }));

  let titleColor =
    themeLayout === 'dark' ? theme.palette.lightPure : theme.palette.pureDark;
  if (error)
    titleColor =
      themeLayout === 'dark'
        ? theme.palette.errorDark
        : theme.palette.errorLight;

  const renderTopText = () => {
    const topTextArray = Array.isArray(topText) ? topText : [topText];
    return topTextArray.map((text) => (
      <T
        key={text}
        color={
          themeLayout === 'dark'
            ? theme.palette.lightPure
            : theme.palette.mainDark
        }
        sx={topTextUppercase ? { textTransform: 'uppercase' } : {}}
        variant="helperTextS"
      >
        {text}
      </T>
    ));
  };

  return (
    <OuterWrapper
      onClick={onClickHandler ? () => onClickHandler(id) : undefined}
      ref={setNodeRef}
      selected={selected}
      style={style}
      theme={themeLayout}
    >
      {draggable && (
        <DragIconWrapper
          onClick={(event) => event.stopPropagation()}
          {...listeners}
        >
          <Icon
            color={
              themeLayout === 'dark'
                ? theme.palette.lightPure
                : theme.palette.pureDark
            }
            icon={DragIndicatorIcon}
          />
        </DragIconWrapper>
      )}
      {thumbnailImgSrc && <Thumbnail alt={title} src={thumbnailImgSrc} />}
      {thumbnailIcon && (
        <IconThumbnail>
          <Icon icon={thumbnailIcon} color={theme.palette.brand} />
        </IconThumbnail>
      )}
      <MainContentBlock>
        <TopBlock>
          {topTag}
          {renderTopText()}
        </TopBlock>
        <TitleBlock hasTag={tags && tags.length > 0}>
          {titleIcon && (
            <TitleIconWrapper theme={themeLayout}>
              <Icon
                icon={titleIcon}
                color={
                  themeLayout === 'dark'
                    ? theme.palette.lightPure
                    : theme.palette.mainDark
                }
              />
            </TitleIconWrapper>
          )}
          <T
            align="left"
            color={titleColor}
            fontWeight="bold"
            variant="paragraphS"
          >
            {title}
          </T>
        </TitleBlock>
        <TagsBlock>
          {tags &&
            tags.map((tagText) => (
              <Chip
                key={`tag-${tagText}`}
                label={tagText}
                theme="dark"
                small
                sx={{ cursor: 'inherit' }}
              />
            ))}
        </TagsBlock>
      </MainContentBlock>
      {buttons && buttons.length > 0 && (
        <ButtonsBlock onClick={(event) => event.stopPropagation()}>
          {buttons &&
            buttons.map(({ handler, label, textStyle, disabled, href }) => (
              <Button
                disabled={disabled}
                key={label}
                onClick={() => handler(id)}
                small
                type={textStyle ? 'textWhite' : 'secondary'}
                theme={textStyle ? themeLayout : 'light'}
                href={href}
                target={href ? '_blank' : '_self'}
              >
                {label}
              </Button>
            ))}
        </ButtonsBlock>
      )}
      {adaptedMenuItems.length > 0 && (
        <MenuBlock onClick={(event) => event.stopPropagation()}>
          <Menu menuItems={adaptedMenuItems} theme={themeLayout} />
        </MenuBlock>
      )}
    </OuterWrapper>
  );
};

ItemBox.propTypes = {
  animated: PropTypes.bool,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      handler: PropTypes.func.isRequired,
      textStyle: PropTypes.bool,
      disabled: PropTypes.bool,
    })
  ),
  draggable: PropTypes.bool,
  error: PropTypes.bool,
  id: PropTypes.string.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string.isRequired,
      handler: PropTypes.func,
    })
  ).isRequired,
  onClickHandler: PropTypes.func,
  selected: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.string),
  theme: PropTypes.oneOf(BACKGROUND_THEMES),
  thumbnailImgSrc: PropTypes.string,
  thumbnailIcon: PropTypes.object,
  title: PropTypes.string.isRequired,
  titleIcon: PropTypes.object,
  topTag: PropTypes.node,
  topText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  topTextUppercase: PropTypes.bool,
};

ItemBox.defaultProps = {
  animated: false,
  draggable: false,
  selected: false,
  theme: 'dark',
  topText: '',
  topTextUppercase: true,
};

export default ItemBox;

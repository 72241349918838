import { useState } from 'react';
import { selectTranslation } from 'shared/utils';

export default function useNanodegrees({
  listNanodegreeStatsWatchData,
  listNanodegreesWatchData,
  locale,
  nanodegreeSelected,
  organizationSelectedId,
  setWatchDataError,
}) {
  const TYPE_ALL = 'all';
  const [nanodegreesData, setNanodegreesData] = useState([]);
  const [nanodegreeStatsData, setNanodegreeStatsData] = useState([]);
  const [loadingNanodegrees, setLoadingNanodegrees] = useState(false);
  const [loadingNanodegreeStats, setLoadingNanodegreeStats] = useState(false);

  const handleError = (type, error) => {
    if (type === TYPE_ALL) {
      console.error('Error calling listNanodegreesWatchData', error);
      setWatchDataError(true);
      setNanodegreesData([]);
      setLoadingNanodegrees(false);
    } else {
      console.error('Error calling listNanodegreeStatsWatchData', error);
      setWatchDataError(true);
      setNanodegreeStatsData([]);
      setLoadingNanodegreeStats(false);
    }
  };

  const fetchNanodegreesAll = async () => {
    setLoadingNanodegrees(true);
    try {
      const organizationId =
        !organizationSelectedId || organizationSelectedId === 'global'
          ? undefined
          : organizationSelectedId;
      const { data, error } = await listNanodegreesWatchData(organizationId);
      if (error) return handleError(TYPE_ALL, error);
      if (data) {
        const { listNanodegreesWatchData: items } = data;

        const mappedItems = items.map((item) => ({
          ...item,
          name: selectTranslation(item.titleTranslations, '', locale, ''),
        }));

        setNanodegreesData(mappedItems);
        setLoadingNanodegrees(false);
      }
    } catch (error) {
      handleError(TYPE_ALL, error);
    }

    return true;
  };

  const fetchNanodegreeStats = async (playlistId) => {
    setLoadingNanodegreeStats(true);
    try {
      const { data, error } = await listNanodegreeStatsWatchData(
        playlistId || nanodegreeSelected.id
      );
      if (error) return handleError(null, error);
      if (data) {
        const { listNanodegreeStatsWatchData: items } = data;

        setNanodegreeStatsData(items);
        setLoadingNanodegreeStats(false);
      }
    } catch (error) {
      handleError(null, error);
    }

    return true;
  };

  const fetchNanodegrees = async (playlistId) => {
    if (playlistId) {
      const calls = [];
      if (nanodegreesData.length === 0) calls.unshift(fetchNanodegreesAll());
      return Promise.all([fetchNanodegreeStats(playlistId), ...calls]);
    }

    return fetchNanodegreesAll();
  };

  return {
    allNanodegreesStats: nanodegreesData,
    fetchNanodegreesWatchData: fetchNanodegrees,
    loadingNanodegreesWatchData: loadingNanodegrees,
    loadingNanodegreeStatsWatchData: loadingNanodegreeStats,
    nanodegreeStats: nanodegreeStatsData,
  };
}

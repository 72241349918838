import React from 'react';

import AdministrateRoles from './components/AdministrateRoles';
import Dialogs from './components/Dialogs';
import Invited from './components/Invited';
import ManageGroups from './components/ManageGroups';
import MembersHeader from './components/MembersHeader';
import Registered from './components/Registered';

import { useOrganizationMembers } from './context';

import { StyledBlock } from './styles';

const OrganizationMembers = () => {
  const {
    manageGroupsOpen,
    userRoles: { administrateUserRoleOpen },
  } = useOrganizationMembers();

  return (
    <StyledBlock>
      {manageGroupsOpen && <ManageGroups />}
      {administrateUserRoleOpen && <AdministrateRoles />}
      {!manageGroupsOpen && !administrateUserRoleOpen && (
        <>
          <MembersHeader />
          <Invited />
          <Registered />
        </>
      )}
      <Dialogs />
    </StyledBlock>
  );
};

export default OrganizationMembers;

/* eslint-disable no-nested-ternary */
import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import FormControl from '@mui/material/FormControl';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import FormLabel from 'shared/atoms/FormFields/components/FormLabel';
import T from 'shared/atoms/Typography';
import FileItemBox from 'shared/molecules/FileItemBox';
import Upload from 'shared/molecules/Upload';

import theme from 'shared/themes/default';
import { BACKGROUND_THEMES, PROCESSING_STATUS } from 'shared/const';
import ThumbnailPreview from './components/ThumbnailPreview';

const FileUploadWrapper = styled.article`
  display: flex;
  flex-direction: column;
`;

const HeaderStyled = styled.header`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.s};
`;

const FileWrapper = styled.div`
  max-width: ${({ small }) => (small ? 142 : 376)}px;
  height: ${({ small }) => (small ? 80 : 212)}px;
`;

const FileItemWrapper = styled.div`
  width: 251px;
`;

const UploadWrapper = styled.div`
  max-width: ${({ small }) => (small ? 142 : 376)}px;
  ${({ small }) => small && 'width: 142px;'}
  height: 100%;
`;

const ImageWrapper = styled.div`
  height: 100%;
`;

const FileUpload = ({
  colorBackground,
  completeUpload,
  debug,
  descriptionText,
  disabled,
  filename,
  getUploadUrl,
  graphql,
  handleAdd,
  handleDelete,
  id,
  imgUrl,
  infoText,
  onStartUpload,
  onCompleteUpload,
  preview,
  processingStatus,
  small,
  startUpload,
  theme: themeLayout,
  title,
  isRequired,
}) => {
  const { t } = useTranslation();
  const handleUpload = (files) => {
    handleAdd(files[0]);
    if (onCompleteUpload) onCompleteUpload();
  };

  const getGraphQlQuery = (query) => {
    const graphQlQuery = async (...params) => graphql(query(...params));
    return graphQlQuery;
  };

  return (
    <FileUploadWrapper>
      <HeaderStyled>
        <FormControl required>
          <FormLabel
            id={id}
            infoText={infoText}
            label={
              <>
                {title}
                {!isRequired && (
                  <T
                    color={
                      themeLayout === 'light'
                        ? theme.palette.pureDark
                        : theme.palette.lightPure
                    }
                    component="span"
                    fontWeight="medium"
                    italic
                  >
                    {` (${t('generic.optional')})`}
                  </T>
                )}
              </>
            }
            required
            theme={themeLayout}
          />
        </FormControl>
      </HeaderStyled>
      <FileWrapper small={small}>
        {imgUrl || processingStatus ? (
          preview ? (
            <ImageWrapper small={small}>
              <ThumbnailPreview
                colorBackground={colorBackground}
                imageSrc={imgUrl}
                onDelete={
                  !disabled &&
                  (imgUrl || processingStatus === PROCESSING_STATUS.ERROR)
                    ? handleDelete
                    : null
                }
                processingStatus={processingStatus}
              />
            </ImageWrapper>
          ) : (
            <FileItemWrapper>
              <FileItemBox
                actions={[
                  {
                    ariaLabel: t('generic.delete'),
                    icon: DeleteOutlineIcon,
                    color:
                      themeLayout === 'light'
                        ? theme.palette.pureDark
                        : theme.palette.lightPure,
                    handler: handleDelete,
                  },
                ]}
                disabled={disabled}
                id={id}
                small={small}
                processingStatus={processingStatus}
                text={filename}
                theme={themeLayout}
              />
            </FileItemWrapper>
          )
        ) : (
          <UploadWrapper small={small}>
            <Upload
              chunkSize={1024 * 1024 * 10}
              completeUpload={getGraphQlQuery(completeUpload)}
              debug={debug}
              deleteFiles
              description={descriptionText}
              disabled={disabled}
              getUploadUrl={getGraphQlQuery(getUploadUrl)}
              onCompleteUpload={handleUpload}
              onStartUpload={onStartUpload}
              returnFiles
              small={small}
              startUpload={getGraphQlQuery(startUpload)}
              theme={themeLayout}
            />
          </UploadWrapper>
        )}
      </FileWrapper>
    </FileUploadWrapper>
  );
};

FileUpload.propTypes = {
  colorBackground: PropTypes.string,
  completeUpload: PropTypes.func.isRequired,
  descriptionText: PropTypes.arrayOf(PropTypes.string),
  debug: PropTypes.bool,
  disabled: PropTypes.bool,
  filename: PropTypes.string,
  getUploadUrl: PropTypes.func.isRequired,
  graphql: PropTypes.func,
  handleAdd: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  imgUrl: PropTypes.string,
  infoText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onStartUpload: PropTypes.func,
  onCompleteUpload: PropTypes.func,
  preview: PropTypes.bool,
  processingStatus: PropTypes.string,
  small: PropTypes.bool,
  startUpload: PropTypes.func.isRequired,
  theme: PropTypes.oneOf(BACKGROUND_THEMES),
  title: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
};

FileUpload.defaultProps = {
  debug: false,
  descriptionText: [],
  filename: '',
  graphql: () => {},
  preview: true,
  small: false,
  theme: 'dark',
};

export default FileUpload;

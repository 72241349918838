import styled from 'styled-components';
import makeStyles from '@mui/styles/makeStyles';

import T from 'shared/atoms/Typography';
import theme from 'shared/themes/default';

export const useStyles = makeStyles(() => ({
  root: {
    '& .MuiDialog-paper': {
      margin: '0',
    },
  },
  mobileRoot: {
    '& .MuiDialog-paper': {
      margin: '0',
      minWidth: '100%',
    },
  },
  titleRoot: {
    opacity: (props) => (props.loading ? '0.6' : '1'),
    fontWeight: 'bold',
    padding: '1rem 3.5rem 1rem 1rem',
    minHeight: '3rem',
  },
  contentRoot: {
    margin: '0',
    padding: '0',
    opacity: (props) => (props.loading ? '0.6' : '1'),
  },
  actionsRoot: {
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem',
  },
  actionsOnRight: {
    display: 'flex',
    justifyContent: 'right',
    padding: '1rem',
  },
}));

export const ConfirmationTitleWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding: 0 1rem 0.5rem 1rem;
`;

export const ConfirmationTitle = styled(T)`
  font-weight: bold !important;
  opacity: ${(props) => (props.loading ? 0.6 : 1)};
`;

export const ContentBlock = styled.div`
  padding: ${(props) => props.contentPadding || '0 1rem'};
`;

export const Spacer = styled.div`
  height: ${theme.spacing.m1};
`;

// Border settings in ErrorWrapper and ErrorBlock required for Safari

export const NotificationWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: inherit;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
`;

export const NotificationBlock = styled.div`
  align-items: center;
  background-color: ${(props) =>
    props.notification ? theme.palette.brand : theme.palette.warningTinted};
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  padding: ${theme.spacing.m1};
`;

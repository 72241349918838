import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Drawer from '@shared/atoms/Drawer';
import FormInput from '@shared/atoms/FormFields/FormInput';
import FormToggle from '@shared/atoms/FormFields/FormToggle';
import FullWidthTabs from '@shared/atoms/FullWidthTabs';
import T from '@shared/atoms/Typography';
import Info from '@shared/molecules/Info';

import { LOCALES, DOMAIN_LOCALES, PROCESSING_STATUS } from '@shared/const';
import { useForm } from '@shared/utils';
import theme from '@shared/themes/default';

import { useAppGlobals } from '../../../contexts/AppContext';

import CoursesTab from './tabs/CoursesTab';
import DescriptionTab from './tabs/DescriptionTab';
import DomainsTab from './tabs/DomainsTab';
import LinksTab from './tabs/LinksTab';
import UploadsTab from './tabs/UploadsTab';

const DrawerContent = styled.div`
  background-color: ${theme.palette.darkWrapper};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m1};
  margin: 0 ${theme.spacing.m1} ${theme.spacing.m1} ${theme.spacing.m1};
  padding: ${theme.spacing.s};
  width: 496px;
`;

const BlockWrapper = styled.div`
  background-color: ${theme.palette.darkDistinct};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.s};
  padding: ${theme.spacing.s};
`;

const ToggleRow = styled.div`
  align-items: center;
  display: flex;
  gap: ${theme.spacing.s};
  justify-content: end;
`;

const ExpertDrawer = ({
  expert,
  handleClose,
  handleCreate,
  handleCreateEnded,
  handleUpdate,
  handleUpdateEnded,
  open,
  showError,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const { domain } = useAppGlobals();
  const { t } = useTranslation();

  const emptyTranslatableField = LOCALES.reduce((acc, curr) => {
    acc[curr] = '';
    return acc;
  }, {});

  const {
    edited,
    errors: formErrors,
    handleBlur,
    handleChange,
    handleSubmit,
    missingRequired,
    properties,
    submitting,
    setDefaults,
    setRequiredLocales,
    values,
  } = useForm({
    changesRequired: true,
    fields: {
      firstname: {
        required: true,
      },
      lastname: {
        required: true,
      },
      enabled: {},
      domain: {},
      tagline: {
        maxLength: 70, // Not localised in the database
      },
      shortDescription: {
        localised: true,
        maxLength: 250,
        required: true,
      },
      socialLinks: {},
      description: {
        localised: true,
        maxLength: 1500,
        required: true,
      },
      homePage: {},
      picture: {
        required: true,
      },
      verticalImage: {},
      signature: {
        required: true,
      },
    },
    locales: LOCALES,
    onSubmitHook: (newExpert) => {
      const input = {
        domain: newExpert.domain,
        firstname: newExpert.firstname,
        lastname: newExpert.lastname,
        descriptionTranslations: JSON.stringify(newExpert.description),
        shortDescriptionTranslations: JSON.stringify(
          newExpert.shortDescription
        ),
        homePage: newExpert.homePage,
        socialLinks: newExpert.socialLinks.map(({ type, value }) => ({
          type,
          link: value,
        })),
        tagline: newExpert.tagline,
        isDisabled: !newExpert.enabled,
      };

      const { picture, verticalImage, signature } = newExpert;

      if (picture?.processingStatus?.status === PROCESSING_STATUS.TO_SCHEDULE) {
        input.picture = picture.processingStatus.file;
      } else if (!picture?.value && !picture?.processingStatus) {
        input.picture = null;
      }

      if (
        verticalImage?.processingStatus?.status ===
        PROCESSING_STATUS.TO_SCHEDULE
      ) {
        input.verticalImage = verticalImage.processingStatus.file;
      } else if (!verticalImage?.value && !verticalImage?.processingStatus) {
        input.verticalImage = null;
      }

      if (
        signature?.processingStatus?.status === PROCESSING_STATUS.TO_SCHEDULE
      ) {
        input.signature = signature.processingStatus.file;
      } else if (!signature?.value && !signature?.processingStatus) {
        input.signature = null;
      }

      if (expert.id) {
        input.id = expert.id;
        handleUpdate(input);
      } else {
        handleCreate(input);
      }
    },
    onSubmitHookEnded: expert?.id ? handleUpdateEnded : handleCreateEnded,
  });

  const getProcessingStatus = (name) => {
    if (expert[`${name}ProcessingStatus`]) {
      return expert[`${name}ProcessingStatus`];
    }
    if (expert[name]) {
      return PROCESSING_STATUS.PROCESSED;
    }
    return null;
  };

  useEffect(() => {
    if (expert) {
      const expertSocialLinks =
        expert.socialLinks?.map((item) => ({
          ...item,
          id: item.type,
          value: item.link,
        })) || [];
      setDefaults({
        firstname: expert.firstname,
        lastname: expert.lastname,
        enabled: !expert.isDisabled,
        domain: expert.domain || [domain],
        tagline: expert.tagline || '',
        shortDescription: (expert.shortDescriptionTranslations &&
          JSON.parse(expert.shortDescriptionTranslations)) || {
          ...emptyTranslatableField,
        },
        socialLinks: expertSocialLinks,
        description: (expert.descriptionTranslations &&
          JSON.parse(expert.descriptionTranslations)) || {
          ...emptyTranslatableField,
        },
        homePage: expert.homePage?.trim(),
        picture: {
          value: expert.picture,
          processingStatus: getProcessingStatus('picture'),
        },
        verticalImage: {
          value: expert.verticalImage,
          processingStatus: getProcessingStatus('verticalImage'),
        },
        signature: {
          value: expert.signature,
          processingStatus: getProcessingStatus('signature'),
        },
      });
      setSelectedTab(0);
    }
  }, [expert]);

  useEffect(() => {
    if (values.domain) {
      setRequiredLocales(
        values.domain.map((domain) => DOMAIN_LOCALES[domain][0])
      );
    }
  }, [values.domain]);

  return (
    <Drawer
      anchor="right"
      edited={edited}
      errorMessage={showError ? t('backoffice.experts.errorSaving') : undefined}
      open={open}
      onClose={handleClose}
      onSave={handleSubmit}
      title={
        expert?.id
          ? `${expert.firstname} ${expert.lastname}`
          : t('backoffice.experts.new')
      }
      saveDisabled={missingRequired || !edited || submitting}
      saving={submitting}
    >
      <DrawerContent>
        <ToggleRow>
          <Info theme="dark">{t('backoffice.experts.listedExplanation')}</Info>
          <T fontWeight="medium">{t('backoffice.experts.unlisted')}</T>
          <FormToggle
            id="enabled"
            label={t('backoffice.experts.listed')}
            name="enabled"
            handleChange={handleChange}
            properties={properties}
            values={values}
          />
        </ToggleRow>
        <BlockWrapper>
          <FormInput
            id="firstname"
            label={t('backoffice.experts.firstname')}
            name="firstname"
            placeholder={t('backoffice.experts.firstnamePlaceholder')}
            theme="dark"
            errors={formErrors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            properties={properties}
            values={values}
          />
          <FormInput
            id="lastname"
            label={t('backoffice.experts.lastname')}
            name="lastname"
            placeholder={t('backoffice.experts.lastnamePlaceholder')}
            errors={formErrors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            properties={properties}
            values={values}
          />
        </BlockWrapper>
        <BlockWrapper>
          <FullWidthTabs
            onChangeTabHandler={(index) => setSelectedTab(index)}
            selectedTabIndex={selectedTab}
            tabsToSelect={[
              {
                label: t('backoffice.experts.description'),
                component: (
                  <DescriptionTab
                    formErrors={formErrors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    properties={properties}
                    submitting={submitting}
                    values={values}
                  />
                ),
              },
              {
                label: t('backoffice.experts.links'),
                component: (
                  <LinksTab handleChange={handleChange} values={values} />
                ),
              },
              {
                label: t('backoffice.experts.uploads'),
                component: (
                  <UploadsTab
                    handleChange={handleChange}
                    properties={properties}
                    values={values}
                  />
                ),
              },
              {
                label: t('backoffice.experts.courses'),
                component: <CoursesTab courses={expert?.courses?.items} />,
              },
              {
                label: t('backoffice.experts.domains'),
                component: (
                  <DomainsTab
                    domains={values?.domain || []}
                    domain={domain}
                    onChange={handleChange}
                  />
                ),
              },
            ]}
            theme="dark"
          />
        </BlockWrapper>
      </DrawerContent>
    </Drawer>
  );
};

ExpertDrawer.propTypes = {
  expert: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  handleCreate: PropTypes.func.isRequired,
  handleCreateEnded: PropTypes.bool,
  handleUpdate: PropTypes.func.isRequired,
  handleUpdateEnded: PropTypes.bool,
  open: PropTypes.bool,
  showError: PropTypes.bool,
};

export default ExpertDrawer;

import React from 'react';
import PropTypes from 'prop-types';
import MUITypography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import theme from 'shared/themes/default';

export const WEIGHTS = ['regular', 'medium', 'semibold', 'bold'];
const ALLOWED_COMPONENTS = ['h1', 'h2', 'h3', 'h4', 'h5', 'li', 'p', 'span'];

const getFontWeight = (weightKey) => {
  if (weightKey) {
    switch (weightKey) {
      case 'regular':
        return 'normal';
      case 'medium':
        return 500;
      case 'semibold':
        return 600;
      default:
        return weightKey;
    }
  }
};

export const VARIANTS_MAPPING = {
  headingXl: 'h1', // 3rem (48px)
  headingL: 'h2', // 2.5rem (40px)
  headingM: 'h3', // 2.25rem (36px)
  headingS: 'h4', // 1.875rem (30px)
  headingXs: 'h5', // 1.5rem (24px)
  subtitle: 'subtitle1', // 1.25rem (20px)
  paragraph: 'body1', // 1rem (16px)
  paragraphS: 'body2', // 0.875rem (14px)
  helperText: 'helperText', // 0.75rem (12px)
  helperTextS: 'helperTextS', // 0.625rem (10px)
};
export const VARIANTS = Object.keys(VARIANTS_MAPPING);

const Typography = ({
  children,
  color,
  fontWeight,
  italic,
  phoneVariant,
  phoneWeight,
  sx,
  variant,
  ...props
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.mobile);
  const appliedVariant = phoneVariant && isMobile ? phoneVariant : variant;
  const appliedWeight = phoneWeight && isMobile ? phoneWeight : fontWeight;

  const sxTypography = {
    color,
    ...sx,
  };
  if (italic) {
    sxTypography.fontStyle = 'italic';
  }
  if (appliedWeight) {
    // default is 400 / regular / normal
    sxTypography.fontWeight = getFontWeight(appliedWeight);
  }

  return (
    <MUITypography
      sx={sxTypography}
      variant={VARIANTS_MAPPING[appliedVariant]}
      {...props}
    >
      {children}
    </MUITypography>
  );
};

Typography.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  color: PropTypes.oneOf([...Object.values(theme.palette), 'inherit']),
  component: PropTypes.oneOf(ALLOWED_COMPONENTS),
  fontWeight: PropTypes.oneOf(WEIGHTS),
  italic: PropTypes.bool,
  phoneVariant: PropTypes.oneOf(VARIANTS),
  phoneWeight: PropTypes.oneOf(WEIGHTS),
  sx: PropTypes.object,
  variant: PropTypes.oneOf(VARIANTS),
};

Typography.defaultProps = {
  color: theme.palette.lightPure,
  italic: false,
  sx: {},
  variant: 'paragraph',
};

export default Typography;

import React, { useState } from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Box,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  Radio,
  RadioGroup,
  useMediaQuery,
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import ArrowDown from '@mui/icons-material/ArrowDropDown';
import ArrowRight from '@mui/icons-material/ArrowRight';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import CircularSpinner from 'shared/atoms/CircularSpinner';
import Icon from 'shared/atoms/Icon';
import IconButton from 'shared/atoms/IconButton';
import T from 'shared/atoms/Typography';
import Pagination from 'shared/molecules/Pagination';

import { selectTranslation } from 'shared/utils/selectTranslation';
import theme from 'shared/themes/default';

import PlaceholderBackground from 'shared/assets/placeholder_background.png';
import ExpandedCourses from './ExpandedCourses';

const StyledPagination = styled.div`
  margin-top: 16px;
`;

const StyledThumbnail = styled.div`
  align-self: stretch;
  object-fit: cover;
  margin: 0 6px;
  height: 48px;
  border-radius: 8px;
  min-width: 70px;
  float: left;
  width: 70px;
  background-image: url('${PlaceholderBackground}');
  background-size: cover;
  background-position: center;
  color: ${theme.palette.brand};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTitleName = styled.div`
  text-overflow: ellipsis;
  width: 100%;
  word-break: break-word;
  margin-left: 5px;
  align-self: center;
  font-weight: 600;
  font-size: 14px;
`;

const styles = {
  root: {
    fontFamily: theme.fonts.primary,
    margin: '0',
    display: 'grid',
    padding: '0',
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.lightPure,
    },
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.lightDistinct,
    },
    '& .MuiListItem-root': {
      paddingLeft: '0',
      paddingRight: '0',
      overflow: 'hidden',
    },
    '& .MuiRadio-colorPrimary': {
      color: theme.palette.darkDistinct,
    },
  },
};

const StyledOuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledBox = styled(Box)`
  ::-webkit-scrollbar {
    width: 10px;
    height: 30px;
  }
  ::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    background-color: ${theme.palette.brand};
    border-radius: 10px;
  }
  ${theme.breakpoints.desktop} {
    min-height: 320px;
  }
`;

const CopyPlaylistPanel = ({
  classes,
  existingPlaylists,
  setExpandedPlaylist,
  playlistCourses,
  setPlaylistSelected,
  locale,
}) => {
  const [selectedRadio, setSelectedRadio] = useState(null);
  const [expanded, setExpanded] = useState({ playlistId: '', expanded: false });
  const [page, setPage] = useState(1);
  const isPhone = useMediaQuery(theme.breakpoints.mobile);
  const { t } = useTranslation();

  const itemsPerPage = isPhone ? 4 : 5;
  const noOfPages = Math.ceil(
    (existingPlaylists && existingPlaylists.length / itemsPerPage) || 1
  );

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleRadioChange = (event) => {
    const { value } = event.target;
    setSelectedRadio(value);
    setPlaylistSelected(value);
  };

  return (
    <StyledOuterWrapper>
      {!existingPlaylists ? (
        <CircularSpinner />
      ) : (
        <>
          <StyledBox>
            {existingPlaylists.length === 0 && (
              <T
                align="center"
                color={theme.palette.disabledLight}
                sx={{ marginTop: '25%' }}
                variant="headingXs"
              >
                {t('playlist.noPlaylistSelectedText')}
              </T>
            )}
            {existingPlaylists
              .slice((page - 1) * itemsPerPage, page * itemsPerPage)
              .map((playlist) => {
                const expand = () => {
                  if (expanded.playlistId === playlist.id) {
                    setExpanded({ playlistId: '', expanded: false });
                  } else {
                    setExpanded({ playlistId: playlist.id, expanded: true });
                    setExpandedPlaylist(playlist.id);
                  }
                };
                return (
                  <List classes={classes} key={playlist.id}>
                    <ListItem>
                      <IconButton
                        icon={
                          expanded.playlistId === playlist.id &&
                          expanded.expanded
                            ? ArrowDown
                            : ArrowRight
                        }
                        onClick={() => expand()}
                        theme="light"
                        type="transparent"
                      />
                      <FormControl component="fieldset">
                        <RadioGroup
                          value={selectedRadio}
                          onChange={handleRadioChange}
                        >
                          <FormControlLabel
                            value={playlist.id}
                            control={<Radio color="primary" />}
                            label=""
                          />
                        </RadioGroup>
                      </FormControl>
                      <StyledThumbnail>
                        <Icon icon={PlayArrowIcon} size="35px" />
                      </StyledThumbnail>
                      <StyledTitleName>
                        {selectTranslation(
                          playlist.titleTranslations,
                          playlist.title,
                          locale,
                          ''
                        )}
                      </StyledTitleName>
                    </ListItem>
                    {expanded.playlistId === playlist.id &&
                      expanded.expanded && (
                        <ExpandedCourses
                          courses={playlistCourses}
                          locale={locale}
                        />
                      )}
                  </List>
                );
              })}
          </StyledBox>
          {noOfPages > 1 && (
            <StyledPagination>
              <Pagination
                page={page}
                totalPages={noOfPages}
                initialPage={1}
                onChange={handleChange}
              />
            </StyledPagination>
          )}
        </>
      )}
    </StyledOuterWrapper>
  );
};

CopyPlaylistPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  existingPlaylists: PropTypes.array,
  locale: PropTypes.string.isRequired,
  setPlaylistSelected: PropTypes.func,
  playlistCourses: PropTypes.array,
  setExpandedPlaylist: PropTypes.func,
};

export default withStyles(styles)(CopyPlaylistPanel);

const colorDefinitions = {
  vcYellow: '#FFD100',
  vcYellowTinted: '#FFF6CC',
  vcOrange: '#F38B00',
  vcBlue: '#00A7B5',
  vcTeal: '#69C9BA',
  vcWhite: '#FFFFFF',
  vcOffWhite: '#F5F5F5',
  vcLightGrey: '#EEEEEE',
  vcGrey: '#B3B3B3',
  vcDarkGrey: '#6C6C6C',
  vcBlack: '#000000',
  vcOffBlack: '#191919',
  vcTableGreyLight: '#212121',
  vcTableGreyDark: '#121212',
  vcShadowGrey: '#292929',
  vcIronGrey: '#333333',
  vcGreenLight: '#77BC1F',
  vcGreenDark: '#4E9D2D',
  vcGreenTinted: '#DCEBD5',
  vcRedLight: '#E94F06',
  vcRedDark: '#D44400',
  vcRedTinted: '#F4D0BF',
};

export default {
  // Main colors
  brand: colorDefinitions.vcYellow,
  warning: colorDefinitions.vcOrange,
  focus: colorDefinitions.vcBlue,
  team: colorDefinitions.vcTeal,
  // Status colors
  warningTinted: colorDefinitions.vcYellowTinted,
  successLight: colorDefinitions.vcGreenLight,
  successDark: colorDefinitions.vcGreenDark,
  successTinted: colorDefinitions.vcGreenTinted,
  errorLight: colorDefinitions.vcRedLight,
  errorDark: colorDefinitions.vcRedDark,
  errorTinted: colorDefinitions.vcRedTinted,
  // Whites
  lightPure: colorDefinitions.vcWhite,
  lightDistinct: colorDefinitions.vcOffWhite,
  lightInteracting: colorDefinitions.vcLightGrey,
  disabledLight: colorDefinitions.vcGrey,
  disabledDark: colorDefinitions.vcDarkGrey,
  // Blacks
  pureDark: colorDefinitions.vcBlack,
  mainDark: colorDefinitions.vcOffBlack,
  oddDark: colorDefinitions.vcTableGreyLight,
  evenDark: colorDefinitions.vcTableGreyDark,
  darkWrapper: colorDefinitions.vcShadowGrey,
  darkDistinct: colorDefinitions.vcIronGrey,
};

import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import Button from '@shared/atoms/Button';
import Icon from '@shared/atoms/Icon';
import IconButton from '@shared/atoms/IconButton';
import T from '@shared/atoms/Typography';
import ManageQuiz from '@shared/molecules/ManageQuiz';

import theme from '@shared/themes/default';

const ManageQuizWrapper = styled.div`
  position: relative;
  padding: ${theme.spacing.m1} 0;
  background: ${theme.palette.darkDistinct};
  display: flex;
  flex-direction: column;
`;
export const NotificationWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: inherit;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
`;

export const NotificationBlock = styled.div`
  align-items: center;
  background-color: ${(props) =>
    props.notification ? theme.palette.brand : theme.palette.warningTinted};
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  padding: ${theme.spacing.m1};
`;

const QuizContent = ({
  disabled,
  isEditing,
  setIsEditing,
  modifyQuiz,
  quiz,
  setHasError,
  setHasModified,
  defaultQuiz,
}) => {
  const [pastedCsvContent, setPastedCsvContent] = useState('');
  const [error, setError] = useState(null);

  const { t } = useTranslation();

  const handleSetQuizQuestions = (questions) => {
    setHasError(false);
    setError(null);
    modifyQuiz({ ...quiz, questions });
  };

  const handleError = (value) => {
    setHasError(!!value);
    setError(value);
  };

  const handleDeleteQuiz = () => {
    setPastedCsvContent('');
    handleSetQuizQuestions([]);
    setHasModified(isEditing);
    setIsEditing(false);
  };

  return (
    <ManageQuizWrapper>
      <T
        variant="subtitle"
        fontWeight="bold"
        sx={{ mb: theme.spacing.m1, ml: theme.spacing.m1 }}
      >
        {t('backoffice.courses.quizTab.quizTitle')}
      </T>
      {error && (
        <NotificationWrapper>
          <NotificationBlock>
            <Icon icon={ErrorOutlineIcon} size="35px" />
            <T
              color={theme.palette.pureDark}
              fontWeight="semibold"
              ml={theme.spacing.m1}
              mr={theme.spacing.m3}
            >
              {error}
            </T>
            <IconButton
              ariaLabel="close-error-message"
              icon={CloseIcon}
              onClick={() => setError(null)}
              sx={{
                position: 'absolute',
                top: theme.spacing.s,
                right: theme.spacing.s,
              }}
              theme="light"
              type="transparent"
            />
          </NotificationBlock>
        </NotificationWrapper>
      )}
      <ManageQuiz
        alwaysExpanded
        defaultQuizQuestions={defaultQuiz?.questions || []}
        disabled={disabled}
        isEditing={isEditing && quiz.questions?.length > 0}
        isExam={false}
        pastedCsvContent={pastedCsvContent}
        quizQuestions={quiz.questions || []}
        setErrorMessage={handleError}
        setHasModified={setHasModified}
        setPastedCsvContent={setPastedCsvContent}
        setQuizQuestions={handleSetQuizQuestions}
        theme="dark"
      />
      {isEditing && quiz.questions?.length > 0 && !disabled && (
        <Button
          ariaLabel={t('generic.delete')}
          iconRight={DeleteOutlineIcon}
          onClick={handleDeleteQuiz}
          type="secondary"
          sx={{
            margin: `${theme.spacing.m1} ${theme.spacing.m2} 0 0`,
            width: 'auto',
            alignSelf: 'end',
          }}
        >
          {t('generic.delete')}
        </Button>
      )}
    </ManageQuizWrapper>
  );
};

QuizContent.propTypes = {
  defaultQuiz: PropTypes.object,
  disabled: PropTypes.bool,
  isEditing: PropTypes.bool.isRequired,
  modifyQuiz: PropTypes.func.isRequired,
  quiz: PropTypes.object.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  setHasError: PropTypes.func.isRequired,
  setHasModified: PropTypes.func.isRequired,
};

export default QuizContent;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import T from '@shared/atoms/Typography';
import theme from '@shared/themes/default';

const LessonTitle = ({ lessons }) => {
  const { t } = useTranslation();

  return (
    <T color={theme.palette.lightPure} fontWeight="semibold">
      {t('backoffice.courses.lessonsTab.lessonsHeader', { count: lessons })}
    </T>
  );
};

LessonTitle.propTypes = {
  lessons: PropTypes.number,
};

export default LessonTitle;

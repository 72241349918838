import React from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';

import Authentication from '../components/organisms/Authentication';
import { useAppGlobals } from '../components/contexts/AppContext';

const AuthenticationContainer = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { user } = useAppGlobals();
  const searchParams = new URLSearchParams(search);
  const redirectPath = searchParams.get('redirect');

  const redirectTo = () => {
    if (redirectPath) {
      navigate(redirectPath);
    }
  };

  if (user) {
    // For logged in users, the login page is not available
    return <Navigate to="/" replace />;
  }

  return <Authentication redirect={redirectTo} user={user} />;
};

export default AuthenticationContainer;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDebouncedFunction } from 'shared/utils';
import { NOT_SHARED, shareTypeMapping, GROUP } from 'shared/const';

import SharePlaylistAndManage from './components/SharePlaylistAndManage';
import { usePlaylist } from '../../../context';

const SharePlaylistContainer = () => {
  const [playlistId, setPlaylistId] = useState();
  const [shareType, setShareType] = useState(NOT_SHARED);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [initialSharedWith, setInitialSharedWith] = useState([]);
  const [groupsThatHasAccess, setGroupsThatHasAccess] = useState([]);
  const [usersWhoHasAccess, setUsersWhoHasAccess] = useState([]);
  const [formStatus, setFormStatus] = useState();
  const [organizationMembersCount, setOrganizationMembersCount] = useState();
  const [organizationMembersCountLoading, setOrganizationMembersCountLoading] =
    useState(true);
  const [searchResult, setSearchResult] = useState([]);
  const [searchQueryResultLoading, setSearchQueryResultLoading] =
    useState(false);
  const [nextToken, setNextToken] = useState('');
  const [groups, setGroups] = useState([]);
  const [searchGroupsTerm, setSearchGroupsTerm] = useState('');
  const [groupsNextToken, setGroupsNextToken] = useState('');

  const { t } = useTranslation();
  const {
    getOrganizationMembers,
    getOrganizationMembersCount,
    listGroups,
    listGroupsByPromotedPlaylist,
    listUsersByPromotedPlaylist,
    playlists: { updatePlaylistAccess },
    dialogs: {
      closeShareDialogHandler: onCloseHandler,
      onSuccessSharing: onSuccessfulUpdate,
      shareValues,
    },
    organization,
  } = usePlaylist();

  const { id: organizationId } = organization || {};
  const { playlist, playlistName } = shareValues || {};

  const loadOrganizationMembers = async (searchTerm, nextToken) => {
    const { data } = await getOrganizationMembers(searchTerm, nextToken);

    if (!nextToken) {
      // It's first call
      setSearchResult(data.getOrganizationMembers.items);
    } else {
      setSearchResult([...searchResult, ...data.getOrganizationMembers.items]);
    }
    if (data.getOrganizationMembers.nextToken) {
      setNextToken(data.getOrganizationMembers.nextToken);
    } else {
      setSearchQueryResultLoading(false);
      setNextToken('');
    }
  };

  const getGroups = async (nextToken) => {
    const { data } = await listGroups(nextToken);

    if (!nextToken) {
      // It's first call
      setGroups(data.listGroups.items);
    } else {
      setGroups([...searchResult, ...data.listGroups.items]);
    }
    if (data.listGroups.nextToken) {
      setGroupsNextToken(data.listGroups.nextToken);
    } else {
      setGroupsNextToken('');
    }
  };

  const doQuery = async () => {
    if (searchTerm !== '') {
      setSearchQueryResultLoading(true);
      loadOrganizationMembers(searchTerm);
    }
  };

  useDebouncedFunction(doQuery, searchTerm, 500);

  useEffect(() => {
    if (nextToken) {
      loadOrganizationMembers(searchTerm, nextToken);
    }
  }, [searchTerm, nextToken]);

  useEffect(() => {
    getGroups();
  }, []);

  useEffect(() => {
    if (groupsNextToken) {
      getGroups(groupsNextToken);
    }
  }, [groupsNextToken]);

  const searchUsersResult = searchResult.filter(
    ({ name, email }) =>
      name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const searchGroupsResult = groups.filter(({ name }) =>
    name.toLowerCase().includes(searchGroupsTerm.toLowerCase())
  );

  const fetchAllUsersWhoHasAccess = async (playlistId) => {
    const { data } = await listUsersByPromotedPlaylist(playlistId);
    if (data && data.listUsersByPromotedPlaylist) {
      setUsersWhoHasAccess(data.listUsersByPromotedPlaylist.items);
    }
  };

  const fetchAllGroupsThatHasAccess = async (playlistId) => {
    const { data } = await listGroupsByPromotedPlaylist(playlistId);
    if (data && data.listGroupsByPromotedPlaylist) {
      setGroupsThatHasAccess(data.listGroupsByPromotedPlaylist.items);
    }
  };

  const onSubmitHandler = async ({
    shareType,
    newSharedList,
    removeAll,
    notifyNew,
  }) => {
    const input = {
      type: removeAll ? NOT_SHARED : shareType,
      ids: newSharedList.map(({ id }) => id) || [],
      organizationId,
      playlistId,
      notifyNew,
    };

    const { data, error } = await updatePlaylistAccess({ input });
    if (error) {
      console.error(error);
      setFormStatus({
        success: false,
        message: t('errors.errorOccured'),
      });
    } else {
      const {
        updatePlaylistAccess: { success, shareType },
      } = data;
      success && onSuccessfulUpdate(playlistId, shareType);
      setFormStatus({
        success,
        message: success
          ? t('playlist.updatePlaylistAccessMessage')
          : t('errors.errorOccured'),
      });
    }
  };

  useEffect(() => {
    const fetchData = async (playlistId) => {
      if (playlistId) {
        setIsLoading(true);
        await fetchAllUsersWhoHasAccess(playlistId);
        await fetchAllGroupsThatHasAccess(playlistId);
        setIsLoading(false);
      }
    };

    if (playlist) {
      setPlaylistId(playlist.id);
      setShareType(shareTypeMapping[playlist.shareType]);
      fetchData(playlist.id);
    }
  }, [playlist]);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getOrganizationMembersCount();

      setOrganizationMembersCount(data.getOrganizationMembersCount);
      setOrganizationMembersCountLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (groupsThatHasAccess.length) {
      setShareType(GROUP);
      setInitialSharedWith(groupsThatHasAccess);
    } else if (playlist) {
      setShareType(shareTypeMapping[playlist.shareType]);
      setInitialSharedWith(usersWhoHasAccess);
    }
  }, [usersWhoHasAccess, groupsThatHasAccess]);

  return (
    <SharePlaylistAndManage
      onClose={onCloseHandler}
      loading={isLoading || organizationMembersCountLoading}
      searchLoading={searchQueryResultLoading}
      playlistName={playlistName}
      initialShareType={shareType}
      searchGroups={setSearchGroupsTerm}
      searchGroupsResult={searchGroupsResult}
      searchUsers={setSearchTerm}
      searchUsersResult={searchUsersResult}
      onSubmitHandler={onSubmitHandler}
      initialSharedWith={initialSharedWith}
      formStatus={formStatus}
      totalOrganizationMembers={
        organizationMembersCountLoading ? 0 : organizationMembersCount
      }
      hasGroups={groups.length > 0}
    />
  );
};

export default SharePlaylistContainer;

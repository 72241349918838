import i18n from 'i18next';
import i18nBackend from 'i18next-resources-to-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(i18nBackend((lang) => import(`./languages/${lang}.json`)))
  .init({
    detection: {
      order: ['path'],
      lookupFromPathIndex: 0,
    },
    fallbackLng: 'en',
    returnObjects: true,
  });

export default i18n;

import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import T from 'shared/atoms/Typography';
import Autocomplete from 'shared/atoms/Autocomplete';
import TabsMenu from 'shared/molecules/TabsMenu';
import theme from 'shared/themes/default';

import { commonProps, defaultProps } from './props';

const SelectorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FirstRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${theme.spacing.s};
`;

const TypographyWrapper = styled.div`
  margin-right: ${theme.spacing.m2};
`;

const SearchFieldContainer = styled.div`
  background-color: ${theme.palette.darkWrapper};
  width: 100%;
  padding: ${(props) => (props.searchDisabled ? '0' : `${theme.spacing.m3} 0`)};
  height: ${(props) => (props.searchDisabled ? '0' : 'auto')};
  opacity: ${(props) => (props.searchDisabled ? '0' : '1')};
  transition: padding, height, opacity, 0.5s;
`;

const AutocompleteWrapper = styled.div`
  width: 75%;
  margin: 0 auto;
`;

const DesktopTabs = ({
  tabsConfig,
  selectedTab,
  setSelectedTab,
  onChangeSearch,
  onClearSearch,
  searchPlaceholder,
  searchOptions,
  preSelectedValue,
  getOptionKey,
  searchDisabled,
  searchLoading,
}) => {
  const { t } = useTranslation();
  return (
    <SelectorsWrapper>
      <FirstRowWrapper>
        <TypographyWrapper>
          <T variant="subtitle" fontWeight="semibold">
            {t('watchData.showActivityFor')}
          </T>
        </TypographyWrapper>
        <TabsMenu
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabsConfig={tabsConfig}
        />
      </FirstRowWrapper>
      <SearchFieldContainer searchDisabled={searchDisabled}>
        {!searchDisabled && (
          <AutocompleteWrapper>
            <Autocomplete
              options={searchOptions}
              preSelectedValue={preSelectedValue}
              filterOptions
              onChange={onChangeSearch}
              onClear={onClearSearch}
              placeholder={searchPlaceholder}
              getOptionKey={getOptionKey}
              loading={searchLoading}
              type="secondary"
            />
          </AutocompleteWrapper>
        )}
      </SearchFieldContainer>
    </SelectorsWrapper>
  );
};

DesktopTabs.propTypes = {
  ...commonProps,
};

DesktopTabs.defaultProps = {
  ...defaultProps,
};

export default DesktopTabs;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CircularSpinner from '@shared/atoms/CircularSpinner';
import Table, { ORDER_TYPES } from '@shared/organisms/Table';
import T from '@shared/atoms/Typography';
import { logger } from '@shared/utils';

import { listUserSubscriptions } from '../../graphql/queries';
import { useGraphQLQuery } from '../../../../../utils';

const SubscriptionsTab = ({ loadingMove, userSub }) => {
  const { t } = useTranslation();

  const [subscriptions, setSubscriptions] = useState([]);

  const { callQuery, data, errors, hasEnded, hasErrors, loading } =
    useGraphQLQuery();

  useEffect(() => {
    if (hasEnded) {
      if (hasErrors) {
        logger.error('Error: ', errors);
      } else {
        const {
          listUserSubscriptions: { items },
        } = data;
        setSubscriptions(
          items.map(({ subscriptionPlan, ...item }) => ({
            ...item,
            subscriptionPlanName: subscriptionPlan.name,
          }))
        );
      }
    }
  }, [hasEnded]);

  useEffect(() => {
    if (!loadingMove) {
      callQuery(listUserSubscriptions(userSub));
    }
  }, [loadingMove]);

  return (
    <>
      {loading && <CircularSpinner static />}
      {subscriptions.length > 0 && !loading && (
        <Table
          ariaLabel="Subscriptions table"
          columns={[
            {
              key: 'subscriptionPlanName',
              label: t('backoffice.users.subscription'),
            },
            {
              key: 'validFrom',
              label: t('backoffice.users.start'),
            },
            {
              key: 'expireDate',
              label: t('backoffice.users.end'),
            },
          ]}
          items={subscriptions}
          defaultOrder={ORDER_TYPES.DESC}
          defaultOrderBy="validFrom"
        />
      )}
      {subscriptions.length === 0 && !loading && (
        <T>{t('backoffice.users.noSubscriptionsFound')}</T>
      )}
    </>
  );
};

SubscriptionsTab.propTypes = {
  loadingMove: PropTypes.bool.isRequired,
  userSub: PropTypes.string.isRequired,
};

export default SubscriptionsTab;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import T from '@shared/atoms/Typography';
import theme from '@shared/themes/default';

import { TABS } from '../../../contexts/CourseContext';

const ValidationList = ({ details }) => {
  const { t } = useTranslation();

  if (!details) {
    return null;
  }

  return (
    <ul>
      {details[TABS.DESCRIPTION] && (
        <T color={theme.palette.mainDark} component="li" key="description">
          {t('backoffice.courses.description')}
          {Object.entries(details[TABS.DESCRIPTION]).map(([key, value]) => (
            <ul key={key}>
              <T color={theme.palette.mainDark} component="li" key={key}>
                {t(`backoffice.courses.${key}Name`)}
                <ul key={key}>
                  {Object.keys(value).map((key) => (
                    <T color={theme.palette.mainDark} component="li" key={key}>
                      {t(`backoffice.courses.descriptionTab.${key}`)}
                    </T>
                  ))}
                </ul>
              </T>
            </ul>
          ))}
        </T>
      )}
      {details[TABS.LESSONS] && (
        <T color={theme.palette.mainDark} component="li" key="lessons">
          {t('backoffice.courses.lessons')}
          <ul key="lessons">
            {details[TABS.LESSONS].trailer && (
              <T color={theme.palette.mainDark} component="li" key="trailer">
                {t('backoffice.courses.lessonsTab.trailer')}
                <ul key="trailer">
                  <T
                    color={theme.palette.mainDark}
                    component="li"
                    key="failing-trailer"
                  >
                    {t('backoffice.courses.publishing.failingVideo')}
                  </T>
                </ul>
              </T>
            )}
            {details[TABS.LESSONS].gameOrder && (
              <T color={theme.palette.mainDark} component="li" key="game-order">
                {t('backoffice.courses.publishing.gameOrder')}
              </T>
            )}
            {details[TABS.LESSONS].noEpisodes && (
              <T
                color={theme.palette.mainDark}
                component="li"
                key="no-episodes"
              >
                {t('backoffice.courses.publishing.noEpisodes')}
              </T>
            )}
            {details[TABS.LESSONS].episodes
              ?.sort((a, b) => a.index - b.index)
              .map(
                ({
                  attachments,
                  description,
                  gameId,
                  gameLength,
                  index,
                  subtitle,
                  video,
                }) => (
                  <T
                    color={theme.palette.mainDark}
                    component="li"
                    key={`episode-${index}`}
                  >
                    {t('backoffice.courses.lessonsTab.lessonTitle', {
                      noLesson: index,
                    })}
                    {attachments && (
                      <ul key="attachments">
                        <T
                          color={theme.palette.mainDark}
                          component="li"
                          key="attachments"
                        >
                          {t('backoffice.courses.lessonsTab.attachments')}
                          <ul key="attachments">
                            {Object.values(attachments).map((value) => (
                              <T
                                color={theme.palette.mainDark}
                                component="li"
                                key={value}
                              >
                                {value}
                                <ul key="failing-attachment">
                                  <T
                                    color={theme.palette.mainDark}
                                    component="li"
                                    key="failing-attachment"
                                  >
                                    {t(
                                      'backoffice.courses.publishing.failingFile'
                                    )}
                                  </T>
                                </ul>
                              </T>
                            ))}
                          </ul>
                        </T>
                      </ul>
                    )}
                    {description && (
                      <ul key="description">
                        {Object.entries(description).map(([key, value]) => (
                          <T
                            color={theme.palette.mainDark}
                            component="li"
                            key={key}
                          >
                            {t(`backoffice.courses.${key}Name`)}
                            <ul key={key}>
                              {Object.keys(value).map((key) => (
                                <T
                                  color={theme.palette.mainDark}
                                  component="li"
                                  key={key}
                                >
                                  {t(`backoffice.courses.lessonsTab.${key}`)}
                                </T>
                              ))}
                            </ul>
                          </T>
                        ))}
                      </ul>
                    )}
                    {video && (
                      <ul key="video">
                        <T
                          color={theme.palette.mainDark}
                          component="li"
                          key="failing-video"
                        >
                          {t('backoffice.courses.publishing.failingVideo')}
                        </T>
                      </ul>
                    )}
                    {subtitle && (
                      <ul key="subtitle">
                        <T
                          color={theme.palette.mainDark}
                          component="li"
                          key="failing-subtitle"
                        >
                          {t('backoffice.courses.publishing.failingSubtitle')}
                        </T>
                      </ul>
                    )}
                    {gameId && (
                      <ul key="game-id">
                        <T
                          color={theme.palette.mainDark}
                          component="li"
                          key="failing-game-id"
                        >
                          {t('backoffice.courses.publishing.failingGameId')}
                        </T>
                      </ul>
                    )}
                    {gameLength && (
                      <ul key="game-id">
                        <T
                          color={theme.palette.mainDark}
                          component="li"
                          key="failing-game-length"
                        >
                          {t('backoffice.courses.publishing.failingGameLength')}
                        </T>
                      </ul>
                    )}
                  </T>
                )
              )}
          </ul>
        </T>
      )}
      {details[TABS.CATALOG] && (
        <T color={theme.palette.mainDark} component="li" key="catalog">
          {t('backoffice.courses.catalog')}
          <ul key="catalog">
            {Object.keys(details[TABS.CATALOG]).map((key) => (
              <T color={theme.palette.mainDark} component="li" key={key}>
                {t(`backoffice.courses.catalogTab.${key}`)}
              </T>
            ))}
          </ul>
        </T>
      )}
      {details[TABS.ATTACHMENTS] && (
        <T color={theme.palette.mainDark} component="li" key="attachments">
          {t('backoffice.courses.attachments')}
          <ul key="attachments">
            {Object.values(details[TABS.ATTACHMENTS]).map((value) => (
              <T color={theme.palette.mainDark} component="li" key={value}>
                {value}
                <ul key="failing-attachment">
                  <T
                    color={theme.palette.mainDark}
                    component="li"
                    key="failing-attachment"
                  >
                    {t('backoffice.courses.publishing.failingFile')}
                  </T>
                </ul>
              </T>
            ))}
          </ul>
        </T>
      )}
    </ul>
  );
};

ValidationList.propTypes = {
  details: PropTypes.object,
};

export default ValidationList;

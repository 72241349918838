import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import FileItemBox from '@shared/molecules/FileItemBox';
import Upload from '@shared/molecules/Upload';

import theme from '@shared/themes/default';

import { useCourse } from '../../../../contexts/CourseContext';

import { graphql } from '../../../../../utils';

const UploadWithListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.l};
  ${({ height }) =>
    height &&
    `
    height: ${height};
  `}
`;

const WrapList = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  // this corresponds to 4 elements
  max-height: 200px;
  overflow-y: scroll;
  row-gap: ${theme.spacing.s};
  ::-webkit-scrollbar {
    width: 10px;
    height: 30px;
  }
  ::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    background-color: ${theme.palette.disabledLight};
    border-radius: 10px;
  }
`;

const UploadWrapper = styled.div`
  justify-content: center;
  ${({ height }) =>
    height &&
    `
    height: ${height};
  `}
`;

const UploadWithList = ({
  actions,
  deleteFiles,
  description,
  disabled,
  height,
  items,
  maxFiles,
  onStartUpload,
  onUpload,
  uploadHeight,
}) => {
  const { startUpload, getUploadUrl, completeUpload } = useCourse();

  const getGraphQlQuery = (query) => {
    const graphQlQuery = async (...params) => graphql(query(...params));
    return graphQlQuery;
  };

  return (
    <UploadWithListWrapper height={height}>
      {items.length > 0 && (
        <WrapList>
          {items.map(({ id, key, name, processingStatus }, index) => {
            return (
              <FileItemBox
                actions={actions}
                disabled={disabled}
                id={index.toString()}
                key={id || key}
                processingStatus={processingStatus?.status || undefined}
                text={name}
                theme="dark"
              />
            );
          })}
        </WrapList>
      )}
      {!disabled && (
        <UploadWrapper height={uploadHeight}>
          <Upload
            chunkSize={1024 * 1024 * 10}
            completeUpload={getGraphQlQuery(completeUpload)}
            deleteFiles={deleteFiles}
            description={description}
            disabled={items.length >= maxFiles}
            getUploadUrl={getGraphQlQuery(getUploadUrl)}
            startUpload={getGraphQlQuery(startUpload)}
            maxFiles={maxFiles}
            onCompleteUpload={onUpload}
            onStartUpload={onStartUpload}
          />
        </UploadWrapper>
      )}
    </UploadWithListWrapper>
  );
};

UploadWithList.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      ariaLabel: PropTypes.string,
      color: PropTypes.string,
      handler: PropTypes.func,
      icon: PropTypes.object,
      label: PropTypes.string,
    })
  ),
  deleteFiles: PropTypes.bool,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  disabled: PropTypes.bool,
  height: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  maxFiles: PropTypes.number,
  onStartUpload: PropTypes.func,
  onUpload: PropTypes.func,
  uploadHeight: PropTypes.string,
};

export default UploadWithList;

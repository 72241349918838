import React, { useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import DownloadIcon from '@mui/icons-material/Download';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import NotificationsIcon from '@mui/icons-material/Notifications';

import useMediaQuery from '@mui/material/useMediaQuery';

import Button from 'shared/atoms/Button';
import T from 'shared/atoms/Typography';
import CircularSpinner from 'shared/atoms/CircularSpinner';
import SearchField from 'shared/atoms/SearchField';

import theme from 'shared/themes/default';
import { downloadFile } from 'shared/utils';
import Menu from 'shared/molecules/Menu';
import IconButton from 'shared/atoms/IconButton';

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m1};
`;

const TableHeaderWrapper = styled.div`
  padding: ${theme.spacing.m1} ${theme.spacing.m1} 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${theme.breakpoints.mobile} {
    align-items: start;
    flex-direction: column;
    gap: ${theme.spacing.s};
    padding: ${theme.spacing.m1};
  }
`;

const SearchAndExportButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0.2;
  gap: ${theme.spacing.m1};
  ${theme.breakpoints.mobile} {
    flex-direction: row-reverse;
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${theme.spacing.m1};
`;

const StyledTableWrapper = styled.div`
  margin-bottom: ${theme.spacing.m1};
  width: 100%;
`;

const TableOuterWrapper = styled.div`
  background-color: ${theme.palette.darkWrapper};
  border-radius: 6px;
  width: 100%;
  margin-top: 20px;
`;

const SpinnerWrapper = styled.div`
  padding: 85px 0;
`;

const TableWithSearchAndExport = ({
  downloadItems,
  emptyMessage,
  itemsToDisplay,
  loading,
  searchPlaceHolder,
  secondaryButton,
  showRowsAreClickableMessage,
  spinnerDuration,
  tableProps,
  tableTitle,
  TableToDisplay,
  user,
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchedItem, setSearchedItem] = useState();
  const [loadingExport, setLoadingExport] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const customMenuHandler = (event) => {
    setAnchorEl(anchorEl === null ? event.currentTarget : null);
  };

  const downloadReport = async () => {
    try {
      setLoadingExport(true);
      const { data, error } = await downloadItems();
      if (error) {
        console.error('Error calling downloadItems', error);
        setLoadingExport(false);
        return;
      }
      const {
        downloadWatchData: { cloudfrontUrl, fileName },
      } = data;

      await downloadFile(
        user?.signInUserSession?.accessToken?.jwtToken,
        cloudfrontUrl,
        fileName
      );
      setLoadingExport(false);
    } catch (error) {
      setLoadingExport(false);
      console.error('Some error happened when building the export file', error);
    }
  };

  const onSearchHandler = (event) => {
    const { value } = event;

    setSearchTerm(value);
    if (value) {
      const filteredOrganizations = itemsToDisplay.filter((item) => {
        return (
          item?.name?.toLowerCase().includes(value.toLowerCase()) ||
          item?.email?.toLowerCase().includes(value.toLowerCase()) ||
          item?.owner?.toLowerCase().includes(value.toLowerCase())
        );
      });
      setSearchedItem(filteredOrganizations);
    } else {
      setSearchedItem(null);
    }
  };

  const empty = itemsToDisplay.length === 0;
  const isMobile = useMediaQuery(theme.breakpoints.mobile);
  return (
    <OuterWrapper>
      <TableOuterWrapper>
        {loading ? (
          <SpinnerWrapper>
            <CircularSpinner
              static
              showProgress
              duration={spinnerDuration}
              size={98}
            />
          </SpinnerWrapper>
        ) : (
          <>
            <TableHeaderWrapper>
              {tableTitle && (
                <T
                  color={theme.palette.lightPure}
                  fontWeight="medium"
                  phoneVariant="paragraph"
                  variant="subtitle"
                >
                  {tableTitle}
                </T>
              )}
              <SearchAndExportButtonWrapper>
                <ButtonWrapper>
                  {isMobile ? (
                    <>
                      <IconButton
                        ariaLabel={t('generic.more')}
                        icon={MoreHorizIcon}
                        onClick={customMenuHandler}
                        theme="dark"
                        type="secondary"
                      />
                      <Menu
                        freeMenu
                        initAnchorEl={anchorEl}
                        menuItems={[
                          {
                            label: t('watchData.csvFile'),
                            handler: downloadReport,
                            disabled: empty,
                            icon: DownloadIcon,
                          },
                          ...(secondaryButton
                            ? [
                                {
                                  label: secondaryButton.text,
                                  handler: secondaryButton.props.onClick,
                                  disabled: empty,
                                  icon: NotificationsIcon,
                                  ...secondaryButton.props,
                                },
                              ]
                            : []),
                        ]}
                        setInitAnchorEl={setAnchorEl}
                        theme="dark"
                      />
                    </>
                  ) : (
                    <>
                      {secondaryButton && (
                        <Button
                          disabled={empty}
                          iconLeft={NotificationsIcon}
                          type="secondaryOutline"
                          {...secondaryButton.props}
                        >
                          {secondaryButton.text}
                        </Button>
                      )}
                      <Button
                        ariaLabel="export-all-button"
                        onClick={downloadReport}
                        disabled={empty}
                        iconLeft={DownloadIcon}
                        loading={loadingExport}
                        theme="dark"
                        type="secondary"
                      >
                        {t('watchData.csvFile')}
                      </Button>
                    </>
                  )}
                </ButtonWrapper>
                <SearchField
                  type="secondary"
                  placeholder={searchPlaceHolder}
                  ariaLabel={searchPlaceHolder}
                  onChange={onSearchHandler}
                  value={searchTerm}
                  disabled={empty}
                />
              </SearchAndExportButtonWrapper>
            </TableHeaderWrapper>
            {!empty && (
              <>
                {showRowsAreClickableMessage && isMobile && (
                  <T
                    color={theme.palette.lightInteracting}
                    ml={theme.spacing.m1}
                    variant="helperText"
                  >
                    {t('watchData.pressTableRow')}
                  </T>
                )}
                <StyledTableWrapper>
                  <TableToDisplay
                    itemsToDisplay={searchedItem || itemsToDisplay}
                    {...tableProps}
                  />
                </StyledTableWrapper>
              </>
            )}
          </>
        )}
      </TableOuterWrapper>
      {!loading && empty && (
        <T color={theme.palette.lightInteracting}>{emptyMessage}</T>
      )}
    </OuterWrapper>
  );
};

TableWithSearchAndExport.propTypes = {
  downloadItems: PropTypes.func.isRequired,
  emptyMessage: PropTypes.string.isRequired,
  itemsToDisplay: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  searchPlaceHolder: PropTypes.string.isRequired,
  secondaryButton: PropTypes.shape({
    props: PropTypes.object.isRequired,
    text: PropTypes.string.isRequired,
  }),
  showRowsAreClickableMessage: PropTypes.bool,
  spinnerDuration: PropTypes.number,
  tableProps: PropTypes.object,
  tableTitle: PropTypes.string,
  TableToDisplay: PropTypes.func.isRequired,
  user: PropTypes.shape({
    signInUserSession: PropTypes.shape({
      accessToken: PropTypes.shape({
        jwtToken: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
};

TableWithSearchAndExport.defaultProps = {
  spinnerDuration: 10,
};

export default TableWithSearchAndExport;

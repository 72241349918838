import React from 'react';
import PropTypes from 'prop-types';
import MuiTooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import { commonStyles } from './sharedStyles';

const useDesktopStyles = makeStyles({
  ...commonStyles,
  tooltip: {
    ...commonStyles.tooltip,
    width: ({ maxWidth }) => maxWidth || 'inherit',
  },
});

const TooltipDesktop = ({
  arrow,
  children,
  maxWidth,
  placement,
  title,
  ...props
}) => {
  const classes = useDesktopStyles({ maxWidth });
  return (
    <MuiTooltip
      classes={classes}
      title={title}
      arrow={arrow}
      placement={placement}
      {...props}
    >
      {children}
    </MuiTooltip>
  );
};

TooltipDesktop.propTypes = {
  arrow: PropTypes.bool,
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.string,
  placement: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

TooltipDesktop.defaultProps = {
  arrow: false,
  placement: 'bottom',
};

export default TooltipDesktop;

import { customAlphabet } from 'nanoid';

export const getShortUUID = () => {
  // Generate id without _ & -
  // ~15 years needed, in order to have a 1% probability of at least one collision.
  // Ref: https://zelark.github.io/nano-id-cc
  const alphabet =
    'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz0123456789';
  const nanoid = customAlphabet(alphabet, 5);

  return nanoid();
};

/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import T from 'shared/atoms/Typography';
import Accordion from 'shared/molecules/Accordion';
import Input from 'shared/atoms/Input';

import theme from 'shared/themes/default';

import AnswerRadio from './AnswerRadio';

const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${theme.spacing.m1};
  background: transparent;
`;

const BodyStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${theme.spacing.s} ${theme.spacing.m2};
  background: ${(props) =>
    props.theme === 'light'
      ? theme.palette.lightDistinct
      : theme.palette.darkWrapper};
`;

const BodyHeaderStyled = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: ${theme.spacing.s};
`;

const QuestionAccordionHeader = ({
  disabled,
  index,
  expanded,
  question,
  onChangeQuestion,
  theme: themeLayout,
}) => {
  const sxTitle = {
    marginRight: theme.spacing.m1,
    alignSelf: 'baseline',
  };

  const mainColor =
    themeLayout === 'light' ? theme.palette.mainDark : theme.palette.lightPure;

  return (
    <HeaderStyled>
      <T sx={sxTitle} fontWeight="bold" color={mainColor}>
        {index + 1}
      </T>
      {expanded ? (
        <Input
          disabled={disabled}
          fullWidth
          maxRows={4}
          multiline
          onChange={(e) => onChangeQuestion(e.target.value)}
          onClick={(e) => e.stopPropagation()}
          theme={themeLayout}
          value={question}
        />
      ) : (
        <T color={mainColor}>{question}</T>
      )}
    </HeaderStyled>
  );
};

QuestionAccordionHeader.propTypes = {
  disabled: PropTypes.bool,
  expanded: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  onChangeQuestion: PropTypes.func.isRequired,
  question: PropTypes.string.isRequired,
  theme: PropTypes.string,
};

const QuestionAccordionBody = ({
  correctAnswer,
  disabled,
  index,
  onChangeOptions,
  onChangeSelected,
  options,
  theme: themeLayout,
}) => {
  const { t } = useTranslation();

  const handleChangeAnswers = (idxAnswer, answer) => {
    const newOptions = [...options];
    newOptions[idxAnswer] = { ...newOptions[idxAnswer], answer };
    onChangeOptions(newOptions);
  };

  const handleChangeSelected = (selected) => {
    const newOptions = [...options];
    newOptions[correctAnswer - 1] = {
      ...newOptions[correctAnswer - 1],
      correct: false,
    };
    newOptions[selected] = { ...newOptions[selected], correct: true };
    onChangeSelected(newOptions);
  };

  const mainColor =
    themeLayout === 'light' ? theme.palette.mainDark : theme.palette.lightPure;

  return (
    <BodyStyled theme={themeLayout}>
      <BodyHeaderStyled>
        <T
          /* Fix width to align with radios inside AnswerRadio */
          sx={{ width: '72px' }}
          variant="helperText"
          fontWeight="semibold"
          color={mainColor}
        >
          {t('quizzes.selectCorrect')}
        </T>
        <T
          sx={{ alignSelf: 'end' }}
          variant="paragraphS"
          fontWeight="semibold"
          color={mainColor}
        >
          {t('quizzes.choiceAnswers')}
        </T>
      </BodyHeaderStyled>
      {options.map(({ answer, correct }, indexOption) => (
        <AnswerRadio
          disabled={disabled}
          key={`${index}-${indexOption}`}
          index={indexOption}
          name={`question${index}`}
          answer={answer}
          onChangeAnswer={handleChangeAnswers}
          selected={correct}
          onChangeSelected={handleChangeSelected}
          theme={themeLayout}
        />
      ))}
    </BodyStyled>
  );
};

QuestionAccordionBody.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number.isRequired,
  options: PropTypes.array.isRequired,
  correctAnswer: PropTypes.number.isRequired,
  onChangeOptions: PropTypes.func.isRequired,
  onChangeSelected: PropTypes.func.isRequired,
  theme: PropTypes.string,
};

const QuestionAccordion = ({
  disabled,
  index,
  isEditing,
  onChangeQuestion,
  openQuestion,
  question,
  setOpenQuestion,
  theme: themeLayout,
}) => {
  const expanded = openQuestion === index;
  const [internalExpanded, setInternalExpanded] = useState(
    isEditing ? expanded : true
  );

  const setExpanded = (expanded) => {
    if (!isEditing) {
      setInternalExpanded(expanded);
    } else if (expanded) setOpenQuestion(index);
    else setOpenQuestion(-1);
  };

  const handleQuestionChange = (text) => {
    const newQuestion = { ...question, text };
    onChangeQuestion(newQuestion);
  };
  const handleAnswerChange = (options) => {
    const newQuestion = { ...question, options };
    onChangeQuestion(newQuestion);
  };
  const handleSelectedChange = (options) => {
    const newQuestion = { ...question, options };
    onChangeQuestion(newQuestion);
  };

  const getCorrectAnswer = (options) =>
    options.findIndex(({ correct }) => correct) + 1;

  const { text, options } = question;
  const sxAccordion = {
    marginBottom: theme.spacing.xs,
    boxShadow: 'none',
    backgroundColor: 'transparent',
  };

  const finalExpanded = !isEditing ? internalExpanded : expanded;
  return (
    <Accordion
      sx={sxAccordion}
      theme={themeLayout}
      expanded={finalExpanded}
      setExpanded={setExpanded}
      summary={
        <QuestionAccordionHeader
          disabled={disabled}
          index={index}
          expanded={finalExpanded}
          question={text}
          onChangeQuestion={handleQuestionChange}
          theme={themeLayout}
        />
      }
      details={
        <QuestionAccordionBody
          disabled={disabled}
          index={index}
          options={options}
          correctAnswer={getCorrectAnswer(options)}
          onChangeOptions={handleAnswerChange}
          onChangeSelected={handleSelectedChange}
          theme={themeLayout}
        />
      }
    />
  );
};

QuestionAccordion.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number.isRequired,
  isEditing: PropTypes.bool,
  onChangeQuestion: PropTypes.func.isRequired,
  openQuestion: PropTypes.number.isRequired,
  question: PropTypes.object.isRequired,
  setOpenQuestion: PropTypes.func.isRequired,
  theme: PropTypes.string,
};

QuestionAccordion.defaultProps = {
  theme: 'light',
};

export default QuestionAccordion;

import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import NanodegreeIcon from '@mui/icons-material/WorkspacePremium';

import {
  shareTypeMapping,
  NOT_SHARED,
  PLAYLIST_STATUS,
  PLAYLIST_TYPE,
} from 'shared/const';

import Chip from 'shared/atoms/Chip';
import DragAndDrop from 'shared/molecules/DragAndDrop';
import ItemBox from 'shared/molecules/ItemBox';

import theme from 'shared/themes/default';
import { getPlaylistDetailUrl, formatDate } from 'shared/utils';
import { usePlaylist } from '../../context';

const DragAndDropWrapper = styled.div`
  max-height: 600px;
  overflow-y: auto;
`;

const PlaylistTitleBoxList = ({
  isSortable,
  canManagePlaylists,
  selectedRef,
}) => {
  const { t } = useTranslation();
  const {
    locale,
    previewBaseUrl,
    seeActivityHandler,
    hasSubscription: canEditPlaylists,
    organization,
    dialogs: {
      addContentHandler,
      archiveHandler,
      deleteHandler,
      editPlaylistHandler,
      pauseHandler,
      publishHandler,
      setSelectedPlaylistForReminder,
      openAddExamDialogHandler,
      openSharePlaylistHandler,
      openTransferOwnershipHandler,
    },
    playlists: {
      listOwnedPlaylists,
      playlistOrderHandler,
      selectedPlaylist,
      selectPlaylistHandler,
    },
  } = usePlaylist();

  const { id: organizationId } = organization || {};
  const isGlobalOrganization = organizationId === 'global';

  const [ownedPlaylists, setOwnedPlaylist] = useState(listOwnedPlaylists);

  const getMenuItems = (
    playlistId,
    type,
    status,
    readOnly,
    disableActivity,
    archived,
    quizId,
    notShared,
    lastReminderAt
  ) => {
    const result = [
      {
        id: 'editSettings',
        label: t('playlist.editDetails'),
        handler: editPlaylistHandler,
        disabled: !canEditPlaylists || readOnly,
      },
      {
        id: 'editContent',
        label: t('playlist.editContent'),
        handler: addContentHandler,
        disabled:
          !canEditPlaylists ||
          readOnly ||
          (type === PLAYLIST_TYPE.NANO_DEGREE &&
            status !== PLAYLIST_STATUS.DRAFT),
      },
    ];

    if (type === PLAYLIST_TYPE.NANO_DEGREE) {
      result.push({
        id: 'addExam',
        label: quizId ? t('quizzes.editExam') : t('quizzes.addExam'),
        handler: openAddExamDialogHandler,
        disabled: !canEditPlaylists,
      });
    }

    if (
      type === PLAYLIST_TYPE.PLAYLIST &&
      !notShared &&
      status === PLAYLIST_STATUS.PUBLISHED
    ) {
      result.push({
        id: 'sendReminder',
        label: t('playlist.sendReminder'),
        handler: () => setSelectedPlaylistForReminder(playlistId),
        disabled: new Date() - new Date(lastReminderAt) < 48 * 60 * 60 * 1000,
      });
    }

    if (!isGlobalOrganization && type === PLAYLIST_TYPE.PLAYLIST) {
      result.push(
        ...[
          {
            id: 'sharePlaylist',
            label: t('playlist.share'),
            handler: openSharePlaylistHandler,
            disabled: !canEditPlaylists || status !== PLAYLIST_STATUS.PUBLISHED,
          },
          {
            id: 'viewActivity',
            label: t('playlist.seeActivity'),
            handler: seeActivityHandler,
            disabled: disableActivity,
          },
          {
            id: 'transferPlaylist',
            label: t('playlist.transferOwnership'),
            handler: openTransferOwnershipHandler,
            disabled: !canEditPlaylists,
          },
          {
            id: 'deletePlaylist',
            label: t('playlist.removePlaylist'),
            handler: (id) => deleteHandler(id, false),
          },
        ]
      );
    } else if (isGlobalOrganization && status === PLAYLIST_STATUS.DRAFT) {
      result.push({
        id: 'deletePlaylist',
        label:
          type === PLAYLIST_TYPE.PLAYLIST
            ? t('playlist.removePlaylist')
            : t('playlist.removeNanodegree'),
        handler: (id) => deleteHandler(id, type === PLAYLIST_TYPE.NANO_DEGREE),
      });
    }

    if (
      type === PLAYLIST_TYPE.NANO_DEGREE &&
      status !== PLAYLIST_STATUS.DRAFT
    ) {
      result.push({
        id: 'archivePlaylist',
        label: t('playlist.removeNanodegree'),
        handler: (id) => archiveHandler(id),
        disabled: archived,
      });
    }

    return result;
  };

  useEffect(() => {
    setOwnedPlaylist(listOwnedPlaylists);
  }, [listOwnedPlaylists]);

  const handleDragEnd = (array, id, index) => {
    setOwnedPlaylist(array);
    if (playlistOrderHandler) {
      // TODO: Handle errors in sorting properly
      playlistOrderHandler(index + 1, id, array);
    }
  };

  // TODO: Set comparison criteria for when there's no publishing date
  const playlists = isSortable
    ? ownedPlaylists
    : ownedPlaylists.sort((a, b) =>
        b.publishedAt?.localeCompare(a.publishedAt)
      );
  const renderItem = (playlist, idx, playlists) => {
    const readOnly = !!playlist.refId;
    const notShared =
      playlist?.shareType &&
      shareTypeMapping[playlist.shareType] === NOT_SHARED;
    const tags = [];

    if (notShared) {
      tags.push(t('playlist.notShared'));
    }

    if (readOnly) {
      tags.push(t('playlist.readOnly'));
    }

    const disableActivity =
      shareTypeMapping[playlist.shareType] === NOT_SHARED ||
      playlist.status !== PLAYLIST_STATUS.PUBLISHED ||
      (playlist.children || []).length === 0;

    const buttons = [];

    if (canManagePlaylists) {
      if (playlist.status === PLAYLIST_STATUS.PUBLISHED) {
        buttons.push({
          label: t('playlist.pause'),
          handler: () => pauseHandler(playlist.id),
          textStyle: true,
        });
      } else {
        buttons.push({
          label: t('playlist.publish'),
          handler: () => publishHandler(playlist.id, playlist.type),
        });
      }
      buttons.push({
        label: t('playlist.preview'),
        handler: () => {},
        href: `${previewBaseUrl || ''}${getPlaylistDetailUrl(
          playlist,
          locale
        )}`,
        textStyle: true,
        disabled:
          playlist.type === PLAYLIST_TYPE.PLAYLIST &&
          (playlist.children || []).length === 0,
      });
    }

    const title =
      playlist.titleTranslations &&
      typeof playlist.titleTranslations === 'string'
        ? JSON.parse(playlist.titleTranslations)
        : playlist.titleTranslations;
    return (
      <div ref={idx === playlists.length - 1 ? selectedRef : null}>
        <ItemBox
          animated
          buttons={buttons}
          draggable={isSortable}
          id={playlist.id}
          menuItems={
            canManagePlaylists
              ? getMenuItems(
                  playlist.id,
                  playlist.type,
                  playlist.status,
                  readOnly,
                  disableActivity,
                  !!playlist.archivedScheduledAt,
                  playlist.quizId,
                  notShared,
                  playlist.lastReminderAt
                )
              : []
          }
          onClickHandler={selectPlaylistHandler}
          selected={selectedPlaylist === playlist.id}
          tags={tags}
          theme="light"
          title={title[locale]}
          titleIcon={
            playlist.type === PLAYLIST_TYPE.NANO_DEGREE
              ? NanodegreeIcon
              : undefined
          }
          topTag={
            playlist.archiveDate && (
              <Chip
                color={theme.palette.errorLight}
                icon={<AccessAlarmIcon />}
                label={formatDate({
                  date: playlist.archiveDate,
                })}
                small
                theme="dark"
              />
            )
          }
          topText={
            playlist.status === PLAYLIST_STATUS.DRAFT
              ? t('playlist.notPublished')
              : t('playlist.published', {
                  publishedAt: playlist.publishedAt
                    ? formatDate({
                        date: playlist.publishedAt,
                      })
                    : '?',
                })
          }
        />
      </div>
    );
  };
  return (
    <DragAndDropWrapper>
      <DragAndDrop
        isLocked={!isSortable}
        items={playlists.filter(
          (playlist) => playlist.status !== PLAYLIST_STATUS.ARCHIVED
        )}
        onDragEnd={handleDragEnd}
        renderItem={renderItem}
      />
    </DragAndDropWrapper>
  );
};

PlaylistTitleBoxList.propTypes = {
  canManagePlaylists: PropTypes.bool,
  isSortable: PropTypes.bool,
  selectedRef: PropTypes.object,
};

PlaylistTitleBoxList.defaultProps = {
  isSortable: false,
};

export default PlaylistTitleBoxList;

import { useEffect, useState } from 'react';

import { registeredMembersRoles } from 'shared/const';

export default function useOrganizations({
  getOrganizationMembersQuery,
  organizationId,
  registeredMembers,
  setRegisteredMembers,
}) {
  const [registeredNextToken, setRegisteredNextToken] = useState('');
  const [loadingRegisteredMembers, setLoadingRegisteredMembers] =
    useState(true);

  const getOrganizationMembers = async (nextToken) => {
    setLoadingRegisteredMembers(true);
    const { data, error } =
      await getOrganizationMembersQuery(organizationId)(nextToken);
    if (error) {
      console.error('Error: ', error);
      setRegisteredNextToken(null);
      setLoadingRegisteredMembers(false);
    }

    if (data) {
      const {
        getOrganizationMembers: { nextToken: newNextToken },
      } = data;
      setRegisteredMembers((registeredMembers) => [
        ...registeredMembers,
        ...data.getOrganizationMembers.items,
      ]);
      if (newNextToken) {
        setRegisteredNextToken(data.getOrganizationMembers.nextToken);
      } else {
        setLoadingRegisteredMembers(false);
      }
    }
  };

  const getOrganizationManagers = () => {
    return registeredMembers.filter((user) =>
      [registeredMembersRoles.admin, registeredMembersRoles.manager].includes(
        user.roleId
      )
    );
  };

  useEffect(() => {
    if (registeredNextToken) {
      getOrganizationMembers(registeredNextToken);
    }
  }, [registeredNextToken]);

  useEffect(() => {
    if (organizationId) {
      setRegisteredMembers([]);
      getOrganizationMembers();
    }
  }, [organizationId]);

  return {
    getOrganizationManagers,
    loadingRegisteredMembers,
  };
}

import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
  registeredMembersRoles,
  actionsOnContentOwnership,
} from 'shared/const';

export default function useUserRoles({
  changeUserRole,
  handleHistory,
  isOrganizationAdmin,
  manageRoles,
  organizationId,
  registeredMembers,
  requestToDeleteUserGroups,
  requestToDeleteUserPlaylists,
  requestToTransferUserGroups,
  requestToTransferUserPlaylists,
  setRegisteredMembers,
}) {
  const { t } = useTranslation();

  const [administrateUserRoleTo, setAdministrateUserRoleTo] = useState(null);
  const [administrateUserRoleOpen, setAdministrateUserRoleOpen] = useState(
    isOrganizationAdmin && manageRoles
  );
  const [changeRoleFormStatus, setChangeRoleFormStatus] = useState(null);

  const administrateManagersRoleOptions = [
    {
      value: registeredMembersRoles.admin,
      key: registeredMembersRoles.admin,
      label: t('administrateUsers.administrator'),
    },
    {
      value: registeredMembersRoles.manager,
      key: registeredMembersRoles.manager,
      label: t('administrateUsers.manager'),
    },
    {
      value: registeredMembersRoles.member,
      key: registeredMembersRoles.member,
      label: t('administrateUsers.member'),
    },
  ];

  const administrateUserRole = (user) => {
    if (user) {
      setAdministrateUserRoleTo(user);
    }
    setAdministrateUserRoleOpen(true);
    if (handleHistory) {
      if (user?.id) {
        handleHistory(`user=${user.id}&manage=role`);
      } else {
        handleHistory('manage=role');
      }
    }
  };

  const onSubmitAdministrateUserRoleHandler = async (values) => {
    const { userSub, roleChangeTo, actionOnContent } = values;

    let userContentDeleted = true;
    if (actionOnContent === actionsOnContentOwnership.delete) {
      userContentDeleted =
        (await requestToDeleteUserGroups(userSub)) &&
        (await requestToDeleteUserPlaylists(userSub));
      if (!userContentDeleted) {
        setChangeRoleFormStatus({
          success: false,
          message: 'Failed to delete user content',
        });
      }
    }

    let userContentTransferred = true;
    if (actionOnContent === actionsOnContentOwnership.transfer) {
      userContentTransferred =
        (await requestToTransferUserGroups(values)) &&
        (await requestToTransferUserPlaylists(values));
      if (!userContentTransferred) {
        setChangeRoleFormStatus({
          success: false,
          message: 'Failed to transfer user content',
        });
      }
    }

    if (userContentDeleted && userContentTransferred && roleChangeTo) {
      const { data, error } = await changeUserRole(organizationId)({
        input: {
          userSub,
          newRoleId: roleChangeTo,
        },
      });

      if (error) {
        setChangeRoleFormStatus({
          success: false,
          message: t('administrateUserRole.userRoleChangeSuccess'),
        });
      }

      if (data?.changeUserRole) {
        setRegisteredMembers(
          registeredMembers.map((member) => {
            if (member.id === userSub) {
              if (roleChangeTo === registeredMembersRoles.member) {
                Object.assign(member, {
                  roleId: roleChangeTo,
                  ownedPlaylistsNumber: 0,
                  ownedGroupsNumber: 0,
                });
              } else {
                Object.assign(member, {
                  roleId: roleChangeTo,
                });
              }
            }

            return member;
          })
        );

        const selectedUser = registeredMembers.filter(
          (user) => user.id === userSub
        );
        if (selectedUser && selectedUser.length > 0) {
          setAdministrateUserRoleTo(selectedUser[0]);
        }

        setChangeRoleFormStatus({
          success: true,
          message: t('administrateUserRole.userRoleChangeFailed'),
        });
      }
    } else {
      setChangeRoleFormStatus({
        success: false,
        message: t('administrateUserRole.userRoleChangeFailed'),
      });
    }
  };

  return {
    administrateManagersRoleOptions,
    administrateUserRole,
    administrateUserRoleTo,
    administrateUserRoleOpen,
    changeRoleFormStatus,
    onSubmitAdministrateUserRoleHandler,
    setAdministrateUserRoleOpen,
    setChangeRoleFormStatus,
  };
}

import theme from 'shared/themes/default';

export const commonStyles = {
  arrow: {
    '&:before': {
      border: `1px solid ${theme.palette.mainDark}`,
    },
    color: theme.palette.lightPure,
  },
  tooltip: {
    backgroundColor: theme.palette.lightPure,
    border: `1px solid ${theme.palette.mainDark}`,
    color: theme.palette.mainDark,
    fontSize: '14px',
  },
};

import React from 'react';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import T from 'shared/atoms/Typography';

import theme from 'shared/themes/default';

import DescriptionBox from './components/DescriptionBox';

const OuterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${theme.spacing.m3};
  margin-top: ${theme.spacing.m1};

  ${theme.breakpoints.mobile} {
    gap: ${theme.spacing.m2};
    margin-top: ${theme.spacing.s};
  }
`;

const DescriptionBoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.m2};
  justify-content: space-evenly;
  ${theme.breakpoints.mobile} {
    flex-direction: column;
    gap: ${theme.spacing.m1};
  }
`;

const MembersDescription = () => {
  const { t } = useTranslation();

  const membersActivity = t('administrateUsers.membersDescription').split('-');
  const managerActivity = t('administrateUsers.managersDescription').split('-');
  const adminActivity = t('administrateUsers.administratorDescription').split(
    '-'
  );

  return (
    <OuterWrapper>
      <T
        align="center"
        color={theme.palette.brand}
        fontWeight="bold"
        phoneVariant="headingS"
        variant="headingL"
      >
        {t('administrateUsers.membersTypeDefinition')}
      </T>
      <DescriptionBoxWrapper>
        <DescriptionBox
          contentList={membersActivity}
          titleText={t('administrateUsers.member')}
        />
        <DescriptionBox
          contentList={managerActivity}
          titleText={t('administrateUsers.manager')}
        />
        <DescriptionBox
          contentList={adminActivity}
          titleText={t('administrateUsers.administrator')}
        />
      </DescriptionBoxWrapper>
    </OuterWrapper>
  );
};

export default MembersDescription;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MuiNativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import theme from 'shared/themes/default';
import { BACKGROUND_THEMES } from 'shared/const';

import { InputStyle, ButtonStyle } from './styles';

const Icon = styled(ExpandMoreIcon)`
  right: ${theme.spacing.s};
`;

const NativeSelect = ({
  hasAction,
  hasBackground,
  id,
  items,
  name,
  onChange,
  required,
  sx: sxProp,
  theme: themeLayout,
  value,
  ...props
}) => {
  const handleChange = (event) => {
    onChange(event.target);
  };

  const textColor =
    themeLayout === 'dark' ? theme.palette.lightPure : theme.palette.mainDark;
  const disabledColor =
    themeLayout === 'dark'
      ? theme.palette.disabledDark
      : theme.palette.disabledLight;
  let colorIcon = hasBackground ? theme.palette.mainDark : textColor;
  colorIcon = hasAction ? colorIcon : theme.palette.darkDistinct;
  colorIcon = props.disabled ? disabledColor : colorIcon;

  const sx = {
    width: '100%',
    '& .MuiNativeSelect-iconStandard': {
      color: colorIcon,
    },
    ...sxProp,
  };

  const sxInputStyle = hasAction
    ? ButtonStyle(hasBackground, themeLayout)
    : InputStyle(themeLayout);

  return (
    <MuiNativeSelect
      variant="standard"
      input={<InputBase />}
      inputProps={{
        id,
        name,
      }}
      sx={{ ...sx, ...sxInputStyle }}
      IconComponent={Icon}
      value={value}
      onChange={handleChange}
      required={required}
      {...props}
    >
      {items.map((item) => (
        <option value={item.value} key={item.key} disabled={item.disabled}>
          {item.label}
        </option>
      ))}
    </MuiNativeSelect>
  );
};

NativeSelect.propTypes = {
  disabled: PropTypes.bool,
  hasAction: PropTypes.bool,
  hasBackground: PropTypes.bool,
  id: PropTypes.string.isRequired,
  items: PropTypes.array,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  sx: PropTypes.object,
  theme: PropTypes.oneOf(BACKGROUND_THEMES),
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
};

NativeSelect.defaultProps = {
  sx: {},
};

export default NativeSelect;

import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import useMediaQuery from '@mui/material/useMediaQuery';

import Autocomplete from 'shared/atoms/Autocomplete';
import Alert from 'shared/atoms/Alert';
import Button from 'shared/atoms/Button';
import Form from 'shared/atoms/Form';
import FormSelect from 'shared/atoms/FormFields/FormSelect';
import RadioGroup from 'shared/atoms/RadioGroup';
import Snackbar from 'shared/atoms/Snackbar';
import Tooltip from 'shared/atoms/Tooltip';
import T from 'shared/atoms/Typography';

import {
  registeredMembersRoles,
  actionsOnContentOwnership,
} from 'shared/const';
import theme from 'shared/themes/default';

import MembersDescription from '../MembersDescription';

import { StyledBlock } from '../../styles';
import {
  AutoCompleteWrapper,
  ButtonWrapper,
  ContentOwnershipHeader,
  ContentOwnershipSubHeader,
  ContentOwnershipWrapper,
  FormSubmitButtonWrapper,
  HeaderWrapper,
  MessageBoxWrapper,
  SearchManagerWrapper,
  SearchMemberWrapper,
  SelectedMember,
  SelectedMemberName,
  SelectedMemberPreviewWrapper,
  SelectedMembersRole,
  SelectWrapper,
  Block,
  StyledErrorOutlineIcon,
  StyledHorizontalRule,
  TransferContentOptionWrapper,
} from './styles';

import { useOrganizationMembers } from '../../context';

const AdministrateRoles = () => {
  const { t } = useTranslation();
  const isPhone = useMediaQuery(theme.breakpoints.mobile);

  const [selectedMember, setSelectedMember] = useState(null);
  const [members, setMembers] = useState([]);
  const [managers, setManagers] = useState([]);
  const [transferTo, setTransferTo] = useState(null);
  const [ownContent, setOwnContent] = useState(false);
  const [selectedTransferOption, setSelectedTransferOption] = useState();
  const [transferOwnership, setTransferOwnership] = useState(false);
  const [selectedRole, setSelectedRole] = useState();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [openMessageBox, setOpenMessagebox] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [organizationManagers, setOrganizationManagers] = useState([]);
  const [organizationMembers, setOrganizationMembers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState('');

  const {
    handleHistory,
    organizationId,
    organizations: { getOrganizationManagers },
    preSelectedUserIdToManageRole,
    registeredMembers,
    user,
    userRoles: {
      administrateManagersRoleOptions,
      administrateUserRoleTo,
      changeRoleFormStatus,
      onSubmitAdministrateUserRoleHandler,
      setAdministrateUserRoleOpen,
      setChangeRoleFormStatus,
    },
    users: { transferContentChoiceOptions },
  } = useOrganizationMembers();

  useEffect(() => {
    setOrganizationManagers(
      getOrganizationManagers ? getOrganizationManagers() : []
    );
  }, [getOrganizationManagers, organizationId]);

  useEffect(() => {
    setSelectedUserId(
      preSelectedUserIdToManageRole || administrateUserRoleTo?.id
    );
  }, [
    administrateUserRoleTo?.id,
    preSelectedUserIdToManageRole,
    organizationId,
  ]);

  useEffect(() => {
    setOrganizationMembers(
      registeredMembers.filter((member) => user.username !== member.id)
    );
  }, [registeredMembers]);

  useEffect(() => {
    const sortedMembersByName = organizationMembers.sort((a, b) => {
      return a.name?.localeCompare(b.name);
    });

    setMembers(sortedMembersByName);

    if (sortedMembersByName && selectedUserId) {
      const selectedUser = sortedMembersByName.filter(
        (user) => user.id === selectedUserId
      );
      setSelectedMember(selectedUser[0] || null);
    }
  }, [organizationMembers, selectedUserId]);

  useEffect(() => {
    const sortedManagersByName = organizationManagers.sort((a, b) => {
      return a.name?.localeCompare(b.name);
    });

    setManagers(sortedManagersByName);
  }, [organizationManagers]);

  useEffect(() => {
    if (changeRoleFormStatus) {
      setOpenMessagebox(true);

      // hide the message after 5 sec for desktop
      if (!isPhone) {
        setTimeout(() => {
          setOpenMessagebox(false);
          setChangeRoleFormStatus(null);
        }, 5000);
      }

      setOwnContent(false);
      setTransferOwnership(false);
      setSelectedTransferOption(null);
      setIsSubmitting(false);
      setIsSubmitDisabled(true);
    }
  }, [changeRoleFormStatus]);

  const onSelectMemberHandler = (member) => {
    if (!member) {
      return;
    }

    setSelectedMember(member);
    setSelectedRole(member.roleId);
    setOwnContent(false);
    setSelectedTransferOption(null);
    setTransferOwnership(false);
    setIsSubmitDisabled(true);

    if (handleHistory) {
      handleHistory(`user=${member.id}&manage=role`);
    }
  };

  const onChangeRoleHandler = ({ value: changedRoleTo }) => {
    const roleChanged = changedRoleTo !== selectedMember.roleId;

    const downGradeToMember =
      [registeredMembersRoles.admin, registeredMembersRoles.manager].includes(
        selectedMember.roleId
      ) && changedRoleTo === registeredMembersRoles.member;

    if (!roleChanged || !downGradeToMember) {
      setOwnContent(false);
      setTransferOwnership(false);
      setSelectedTransferOption(null);
      setIsSubmitDisabled(true);
    }

    if (roleChanged) {
      setIsSubmitDisabled(false);
    }

    if (downGradeToMember) {
      if (
        selectedMember.ownedPlaylistsNumber ||
        selectedMember.ownedGroupsNumber
      ) {
        setOwnContent(true);
        setIsSubmitDisabled(true);
      } else {
        setOwnContent(false);
        setIsSubmitDisabled(false);
      }
    }

    setSelectedRole(changedRoleTo);
  };

  const onChangeContentTransferOption = (selectedOption) => {
    if (selectedOption === actionsOnContentOwnership.transfer) {
      setTransferOwnership(true);
    } else {
      setTransferOwnership(false);
    }

    setSelectedTransferOption(selectedOption);
    setIsSubmitDisabled(selectedOption === actionsOnContentOwnership.transfer);
  };

  const onSelectManagerHandler = (manager) => {
    if (!manager) {
      setTransferTo(null);
      setIsSubmitDisabled(true);
      return;
    }

    setTransferTo(manager);
    setIsSubmitDisabled(false);
  };

  const onFormSubmit = async (event) => {
    setIsSubmitting(true);
    onSubmitAdministrateUserRoleHandler({
      userSub: selectedMember?.id,
      roleChangeTo: selectedRole,
      actionOnContent: selectedTransferOption,
      contentTransferTo: transferTo?.id,
    });

    event.preventDefault();
  };

  const snackbarType = changeRoleFormStatus?.success ? 'success' : 'error';
  const notificationMessage = changeRoleFormStatus?.success
    ? t('administrateUserRole.userRoleChangeSuccess')
    : t('administrateUserRole.userRoleChangeFailed');

  return (
    <StyledBlock>
      <HeaderWrapper>
        <div>
          <T
            color={theme.palette.lightPure}
            fontWeight="medium"
            sx={{ mt: theme.spacing.m3 }}
            variant="headingS"
          >
            {t('administrateUserRole.administerRole')}
          </T>
          <T
            color={theme.palette.disabledLight}
            fontWeight="medium"
            sx={{ mb: theme.spacing.m3, mt: theme.spacing.m1 }}
            variant="paragraph"
          >
            {t('administrateUserRole.administerRolePageSubHeader')}
          </T>
        </div>
        <ButtonWrapper>
          <Button
            ariaLabel={t('administrateUsers.returnToMembers')}
            iconLeft={ChevronLeftRoundedIcon}
            onClick={() => setAdministrateUserRoleOpen(false)}
            theme="dark"
            type="secondary"
          >
            {t('administrateUsers.returnToMembers')}
          </Button>
        </ButtonWrapper>
      </HeaderWrapper>

      <SearchMemberWrapper>
        <Block>{t('administrateUserRole.findAMember')}</Block>
        <AutoCompleteWrapper>
          <Autocomplete
            clearOnSelect
            filterOptions
            getOptionLabel={(option) => (option.name || '').toString()}
            noOptionsText={t('generic.noSearchResults')}
            onChange={onSelectMemberHandler}
            options={members.map((item) => {
              return {
                ...item,
                name: `${item.name} <${item.email}>`,
              };
            })}
            placeholder={t('administrateUsers.searchMembersPlaceholder')}
          />
        </AutoCompleteWrapper>
      </SearchMemberWrapper>

      <StyledHorizontalRule />

      {selectedMember && (
        <>
          <SelectedMemberPreviewWrapper>
            <SelectedMember>{`${t('administrateUserRole.user')}:`}</SelectedMember>
            <SelectedMemberName>{selectedMember.name}</SelectedMemberName>
            <SelectedMembersRole>
              <SelectWrapper>
                <FormSelect
                  id="roles-dropdown"
                  name="roles-dropdown"
                  label={t('administrateUserRole.membershipRole')}
                  items={administrateManagersRoleOptions}
                  handleChange={onChangeRoleHandler}
                  required
                  theme="dark"
                  value={selectedRole || selectedMember?.roleId}
                />
              </SelectWrapper>
            </SelectedMembersRole>

            {ownContent && (
              <ContentOwnershipWrapper>
                <ContentOwnershipHeader>
                  {t('administrateUserRole.contentOwnershipHeader')}
                  <Tooltip
                    title={t('administrateUserRole.contentOwnershipHelp')}
                    arrow
                  >
                    <StyledErrorOutlineIcon />
                  </Tooltip>
                </ContentOwnershipHeader>
                <ContentOwnershipSubHeader>
                  {t('administrateUserRole.contentOwnershipSubHeader')}
                </ContentOwnershipSubHeader>

                <TransferContentOptionWrapper>
                  <RadioGroup
                    options={transferContentChoiceOptions.map(
                      ({ value, label }) => {
                        return {
                          value,
                          label,
                        };
                      }
                    )}
                    onChange={onChangeContentTransferOption}
                  />
                </TransferContentOptionWrapper>
                {transferOwnership && (
                  <SearchManagerWrapper>
                    <Block>
                      {t('administrateUserRole.transferOwnershipOfContentTo')}
                    </Block>
                    <AutoCompleteWrapper>
                      <Autocomplete
                        filterOptions
                        getOptionLabel={(option) =>
                          (option.name || '').toString()
                        }
                        noOptionsText={t('generic.noSearchResults')}
                        onChange={onSelectManagerHandler}
                        options={managers.filter(
                          (manager) => manager.id !== selectedMember?.id
                        )}
                        placeholder={t(
                          'administrateUserRole.searchPlaceHolder'
                        )}
                      />
                    </AutoCompleteWrapper>
                  </SearchManagerWrapper>
                )}
              </ContentOwnershipWrapper>
            )}

            <Form onSubmit={onFormSubmit}>
              <FormSubmitButtonWrapper>
                <Button
                  ariaLabel="change-role-button"
                  disabled={isSubmitDisabled}
                  loading={isSubmitting}
                  loadingOnIcon={isSubmitting}
                  submit
                  theme="dark"
                  type="primary"
                >
                  {(selectedTransferOption ===
                    actionsOnContentOwnership.transfer &&
                    t('administrateUserRole.changeRoleAndTransferOwnership')) ||
                    (selectedTransferOption ===
                      actionsOnContentOwnership.delete &&
                      t('administrateUserRole.changeRoleAndDeleteContent')) ||
                    t('administrateUserRole.changeRole')}
                </Button>
              </FormSubmitButtonWrapper>
            </Form>
          </SelectedMemberPreviewWrapper>

          {openMessageBox &&
            (!isPhone ? (
              <MessageBoxWrapper>
                <Alert
                  message={notificationMessage}
                  severity={snackbarType}
                  theme="light"
                />
              </MessageBoxWrapper>
            ) : (
              <Snackbar
                type={snackbarType}
                message={notificationMessage}
                open={openMessageBox}
                onClose={() => {
                  setChangeRoleFormStatus(null);
                  setOpenMessagebox(false);
                }}
              />
            ))}

          <StyledHorizontalRule />
        </>
      )}

      <MembersDescription />
    </StyledBlock>
  );
};

export default AdministrateRoles;

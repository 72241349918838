export const listOrganizations = `query listOrganizations(
  $domain: String!
  $nextToken: String
) {
  listOrganizations (domain: $domain, limit: 1000, nextToken: $nextToken) {
    items {
      id
      name
    }
    nextToken
  }
}`;

import React from 'react';
import PropTypes from 'prop-types';
import MUISwitch from '@mui/material/Switch';

import theme from 'shared/themes/default';
import { BACKGROUND_THEMES } from 'shared/const';

const outlineStyle = {
  outline: `3px solid ${theme.palette.focus}`,
  outlineOffset: '1px',
  borderRadius: 24 / 2,
};

const getOutlineSx = (disabled) => {
  if (disabled) return null;
  return outlineStyle;
};

const switchSx = (layoutTheme, disabled) => ({
  width: 48,
  height: 24,
  padding: 0,
  '&:focus, &:active': getOutlineSx(disabled),
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: '2px',
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(23px)',
      color: theme.palette.lightPure,
      '& + .MuiSwitch-track': {
        backgroundColor:
          layoutTheme === 'dark'
            ? theme.palette.brand
            : theme.palette.successLight,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: theme.palette.disabledLight,
        opacity: 1,
      },
    },
    '&:not(.Mui-checked)': {
      '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: theme.palette.disabledLight,
        opacity: 1,
      },
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.lightPure,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 20,
    height: 20,
  },
  '& .MuiSwitch-track': {
    borderRadius: 24 / 2,
    backgroundColor: theme.palette.disabledDark,
    opacity: 1,
    transition: 'background-color 500ms cubic-bezier(0.4, 0, 0.2, 1)',
  },
});

const Toggle = ({ checked, disabled, id, onChange, theme: layoutTheme }) => {
  const handleChange = ({ target }) => onChange(target.checked);

  return (
    <MUISwitch
      checked={checked}
      disabled={disabled}
      disableRipple
      id={id}
      onChange={handleChange}
      onClick={(e) => e.stopPropagation()}
      sx={() => switchSx(layoutTheme, disabled)}
    />
  );
};

Toggle.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  theme: PropTypes.oneOf(BACKGROUND_THEMES),
};

Toggle.defaultProps = {
  disabled: false,
};

export default Toggle;

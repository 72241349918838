import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import AddIcon from '@mui/icons-material/Add';

import Dialog from '@shared/molecules/Dialog';
import Button from '@shared/atoms/Button';
import SearchField from '@shared/atoms/SearchField';
import Snackbar from '@shared/atoms/Snackbar';
import T from '@shared/atoms/Typography';

import theme from '@shared/themes/default';
import { logger } from '@shared/utils';

import { useAppGlobals } from '../../contexts/AppContext';

import ExpertsList from './components/ExpertsList';
import ExpertDrawer from './components/ExpertDrawer';
import { useGraphQLQuery } from '../../../utils';
import { createExpert, deleteExpert, updateExpert } from './graphql/mutations';
import { listExperts } from './graphql/queries';

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m1};
  max-width: 650px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: ${theme.spacing.s};
`;

const ExpertsView = () => {
  const [experts, setExperts] = useState([]);
  const [expertsFiltered, setExpertsFiltered] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedExpert, setSelectedExpert] = useState();
  const [toDeleteExpert, setToDeleteExpert] = useState();
  const [deleteExpertDialog, setDeleteExpertDialog] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [displayError, setDisplayError] = useState('');

  const { domain, locale } = useAppGlobals();
  const { t } = useTranslation();

  const { callQuery, data, errors, hasEnded, hasErrors, loading } =
    useGraphQLQuery();

  const {
    callQuery: callCreateQuery,
    data: createData,
    errors: errorsCreate,
    hasEnded: hasEndedCreate,
    hasErrors: hasErrorsCreate,
  } = useGraphQLQuery();

  const {
    callQuery: callDeleteQuery,
    errors: errorsDelete,
    loading: loadingDelete,
    hasEnded: hasEndedDelete,
    hasErrors: hasErrorsDelete,
  } = useGraphQLQuery();

  const {
    callQuery: callEditQuery,
    data: editData,
    errors: errorsEdit,
    hasEnded: hasEndedEdit,
    hasErrors: hasErrorsEdit,
  } = useGraphQLQuery();

  const loadExperts = async (nextToken) => {
    callQuery(listExperts(domain, nextToken));
  };

  const handleCreateExpert = () => {
    setSelectedExpert({});
  };

  const handleDeleteExpertDialog = (id) => {
    setToDeleteExpert(experts.find((expert) => expert.id === id));
    setDeleteExpertDialog(true);
  };

  const handleDeleteExpert = () => {
    callDeleteQuery(deleteExpert(toDeleteExpert.id));
    setDeleteExpertDialog(false);
  };

  const handleCloseDrawer = () => {
    setSelectedExpert();
  };

  useEffect(() => {
    setDrawerOpen(!!selectedExpert);
  }, [selectedExpert]);

  useEffect(() => {
    if (searchValue) {
      setExpertsFiltered(
        experts.filter(
          (expert) =>
            expert.firstname
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            expert.lastname.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    } else {
      setExpertsFiltered(experts);
    }
  }, [experts, searchValue]);

  useEffect(() => {
    if (hasEnded) {
      if (hasErrors) {
        setDisplayError(t('backoffice.experts.errorFetching'));
        logger.error('Error: ', errors);
      } else {
        const {
          listExperts: { items, nextToken },
        } = data;
        setExperts(
          [...experts, ...items].sort((a, b) =>
            a.lastname.localeCompare(b.lastname, locale)
          )
        );
        if (nextToken) {
          loadExperts(nextToken);
        }
      }
    }
  }, [hasEnded]);

  useEffect(() => {
    if (hasEndedCreate) {
      if (hasErrorsCreate) {
        logger.error('Error: ', errorsCreate);
      } else {
        const { createExpert } = createData;

        setExperts(
          [...experts, { ...createExpert, courses: { items: [] } }].sort(
            (a, b) => a.lastname.localeCompare(b.lastname, locale)
          )
        );
        handleCloseDrawer();
      }
    }
  }, [hasEndedCreate]);

  useEffect(() => {
    if (hasEndedDelete) {
      if (hasErrorsDelete) {
        setDisplayError(t('backoffice.experts.errorDeleting'));
        logger.error('Error: ', errorsDelete);
      } else {
        setExperts(experts.filter((expert) => expert.id !== toDeleteExpert.id));
      }
    }
  }, [hasEndedDelete]);

  useEffect(() => {
    if (hasEndedEdit) {
      if (hasErrorsEdit) {
        logger.error('Error: ', errorsEdit);
      } else {
        const { updateExpert } = editData;

        setExperts(
          experts
            .map((expert) =>
              expert.id === updateExpert.id
                ? { ...updateExpert, courses: expert.courses }
                : expert
            )
            .sort((a, b) => a.lastname.localeCompare(b.lastname, locale))
        );

        handleCloseDrawer();
      }
    }
  }, [hasEndedEdit]);

  useEffect(() => {
    loadExperts();
  }, []);

  const isLocked = loading || loadingDelete;

  return (
    <OuterWrapper>
      <Snackbar
        type="error"
        message={displayError}
        open={!!displayError}
        onClose={() => setDisplayError('')}
      />
      {deleteExpertDialog && (
        <Dialog
          actions={[
            {
              ariaLabel: t('generic.cancel'),
              handler: () => {
                setDeleteExpertDialog(false);
              },
              label: t('generic.cancel'),
              type: 'secondaryOutline',
            },
            {
              ariaLabel: t('backoffice.experts.delete'),
              handler: handleDeleteExpert,
              label: t('backoffice.experts.delete'),
            },
          ]}
          ariaLabel={t('backoffice.experts.deleteTitle')}
          onClose={() => setDeleteExpertDialog(false)}
          open={deleteExpertDialog}
          sx={{ '.MuiDialogContent-root': { overflowY: 'unset' } }}
          title={t('backoffice.experts.deleteTitle')}
        >
          <T
            color={theme.palette.mainDark}
            fontWeight="semibold"
            sx={{ textAlign: 'center' }}
            variant="subtitle"
          >
            {t('backoffice.experts.deleteText', {
              name: `${toDeleteExpert.firstname} ${toDeleteExpert.lastname}`,
            })}
          </T>
        </Dialog>
      )}
      <HeaderWrapper>
        <SearchField
          ariaLabel={t('backoffice.experts.search')}
          disabled={isLocked}
          onChange={({ value }) => setSearchValue(value)}
          onClear={() => setSearchValue('')}
          placeholder={t('backoffice.experts.search')}
          theme="dark"
          type="primary"
          value={searchValue}
        />
        <Button
          ariaLabel={t('backoffice.experts.add')}
          iconRight={AddIcon}
          onClick={handleCreateExpert}
          sx={{ flex: '0 0 auto' }}
        >
          {t('backoffice.experts.add')}
        </Button>
      </HeaderWrapper>
      <ExpertsList
        experts={expertsFiltered}
        filter={searchValue}
        handleDeleteExperts={handleDeleteExpertDialog}
        isLocked={isLocked}
        loading={loading}
        selectedExpert={selectedExpert}
        setSelectedExpert={setSelectedExpert}
      />
      <ExpertDrawer
        expert={selectedExpert}
        handleClose={handleCloseDrawer}
        handleCreate={(input) => callCreateQuery(createExpert(input))}
        handleCreateEnded={hasEndedCreate}
        handleUpdate={(input) => callEditQuery(updateExpert(input))}
        handleUpdateEnded={hasEndedEdit}
        open={drawerOpen}
        showError={hasErrorsCreate || hasErrorsEdit}
      />
    </OuterWrapper>
  );
};

export default ExpertsView;

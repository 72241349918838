import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FormControlLabel, Checkbox as MuiCheckbox } from '@mui/material';

import { BACKGROUND_THEMES } from 'shared/const';
import theme from 'shared/themes/default';

import IconUnchecked from './components/IconUnchecked';

const Checkbox = ({
  attention,
  checked,
  disabled,
  label,
  onChange,
  partialCheck,
  theme: themeLayout,
  ...props
}) => {
  const [focus, setFocus] = useState(false);

  const getCheckedColor = () => {
    if (themeLayout === 'light') {
      return theme.palette.darkDistinct;
    }
    return attention ? theme.palette.brand : theme.palette.lightPure;
  };

  const sxGenericFormLabel = {
    color:
      themeLayout === 'light'
        ? theme.palette.mainDark
        : theme.palette.lightPure,
    display: 'flex',
    alignItems: 'start',
    '.MuiFormControlLabel-label': {
      width: '100%',
      marginTop: '0.6rem',
      '&.Mui-disabled': {
        color:
          themeLayout === 'light'
            ? theme.palette.disabledLight
            : theme.palette.disabledDark,
      },
    },
  };

  const sxGenericCheckbox = {
    color:
      themeLayout === 'light'
        ? theme.palette.darkDistinct
        : theme.palette.lightPure,
    marginRight: theme.spacing.xs,
    svg: {
      borderRadius: '3px',
      boxShadow: focus ? `0px 0px 0px 2px ${theme.palette.focus}` : 'unset',
      '.IconUncheckedStroke': {
        fill:
          themeLayout === 'light'
            ? theme.palette.darkDistinct
            : theme.palette.lightPure,
      },
      '.IconUncheckedBackground': {
        fill: 'transparent',
      },
    },
    '&.Mui-checked': {
      color: getCheckedColor(),
    },
    '&.Mui-disabled': {
      '&.Mui-checked': {
        color:
          themeLayout === 'light'
            ? theme.palette.disabledLight
            : theme.palette.disabledDark,
      },
      svg: {
        '.IconUncheckedStroke': {
          fill:
            themeLayout === 'light'
              ? theme.palette.disabledLight
              : theme.palette.disabledDark,
        },
      },
    },
    '&:hover': {
      svg: {
        '.IconUncheckedBackground': {
          fill:
            themeLayout === 'light'
              ? theme.palette.lightInteracting
              : theme.palette.darkWrapper,
        },
      },
    },
  };

  return (
    <FormControlLabel
      sx={sxGenericFormLabel}
      control={
        <MuiCheckbox
          checked={checked}
          disabled={disabled}
          disableRipple
          icon={<IconUnchecked />}
          indeterminate={checked && partialCheck}
          onChange={onChange}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          sx={sxGenericCheckbox}
          {...props}
        />
      }
      label={label}
      required={false}
    />
  );
};

Checkbox.propTypes = {
  attention: PropTypes.bool,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func,
  partialCheck: PropTypes.bool,
  theme: PropTypes.oneOf(BACKGROUND_THEMES),
};

Checkbox.defaultProps = {
  theme: 'dark',
};

export default Checkbox;

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Button from 'shared/atoms/Button';
import Alert from 'shared/atoms/Alert';
import Form from 'shared/atoms/Form';
import FormInput from 'shared/atoms/FormFields/FormInput';
import FormSelect from 'shared/atoms/FormFields/FormSelect';
import FullWidthTabs from 'shared/atoms/FullWidthTabs';
import T from 'shared/atoms/Typography';
import ConfirmationDialog from 'shared/molecules/ConfirmationDialog';
import Dialog from 'shared/molecules/Dialog';
import ListItems from 'shared/molecules/ListItems';
import { registeredMembersRoles } from 'shared/const';
import { isValidEmail, sanitize, useForm } from 'shared/utils';
import theme from 'shared/themes/default';

const PreviewTitleWrapper = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.s};
  padding: 0 ${theme.spacing.m1} ${theme.spacing.m1};
`;

const SideSpaceWrapper = styled.div`
  padding: 0 ${theme.spacing.m1};
  display: flex;
  flex-direction: column;
`;

// Bottom margin is a fix for preview button being cut in Chrome
const FullHeightSideSpaceWrapper = styled(SideSpaceWrapper)`
  height: 100%;
  margin-bottom: 1px;
`;

const Spacer = styled.div`
  height: ${theme.spacing.m1};
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m1};
  padding: 0 ${theme.spacing.m1};
`;

const InviteMembers = ({
  formStatus,
  isUserInOrganization,
  onClose,
  onSubmitHandler,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [previewing, setPreviewing] = useState(false);
  const [showDiscardMembersDialog, setShowDiscardMembersDialog] =
    useState(false);
  const [showSendInvitationsDialog, setShowSendInvitationsDialog] =
    useState(false);
  const [addedMembers, setAddedMembers] = useState([]);
  const [addedMembersParsed, setAddedMembersParsed] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [discardMembersDialogStatus, setDiscardMembersDialogStatus] =
    useState(null);
  const [pastedCsv, setPastedCsv] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [batchMembers, setBatchMembers] = useState([]);
  const [failedUpload, setFailedUpload] = useState(false);
  const inputRef = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    setSubmitting(false);
  }, [formStatus]);

  useEffect(() => {
    const roleParser = (role) => {
      if (role === registeredMembersRoles.admin) {
        return t('administrateUsers.administrator');
      }
      if (role === registeredMembersRoles.manager) {
        return t('administrateUsers.manager');
      }
      if (role === registeredMembersRoles.member) {
        return t('administrateUsers.member');
      }
    };
    setAddedMembersParsed(
      (addedMembers || []).map((member) => {
        const {
          alreadyAdded: showSecondaryAction,
          email: subtitle,
          name: title,
          role,
        } = member;

        return {
          key: subtitle,
          label: roleParser(role),
          showSecondaryAction,
          subtitle,
          title,
        };
      })
    );
  }, [addedMembers]);

  const onDialogSubmit = () => {
    setShowSendInvitationsDialog(false);
    setSubmitting(true);
    if (addedMembers.length > 0) {
      onSubmitHandler(addedMembers);
    } else if (batchMembers.length > 0) {
      onSubmitHandler(batchMembers);
    }
  };

  const onAddMember = (member) => {
    const updateMember = member;
    updateMember.name = sanitize(updateMember.name);
    const updatedMembers = [updateMember, ...addedMembers];
    setAddedMembers(updatedMembers);
  };

  const onRemoveMember = (email) => {
    const newAddedMembers = addedMembers.filter((user) => user.email !== email);
    setAddedMembers(newAddedMembers);
    if (newAddedMembers.length === 0) {
      setPreviewing(false);
    }
  };

  const onChangeTabHandler = () => {
    if (addedMembers.length > 0 || batchMembers.length > 0) {
      setDiscardMembersDialogStatus(null);
      setShowDiscardMembersDialog(true);
    }
    setSelectedTabIndex(selectedTabIndex === 0 ? 1 : 0);
    setFailedUpload(false);
  };

  const onNotDiscardMembers = () => {
    setShowDiscardMembersDialog(false);
    setSelectedTabIndex(selectedTabIndex === 0 ? 1 : 0);
  };

  const onFormSubmit = (values) => {
    if (addedMembers.some((member) => member.email === values.email)) {
      setErrorMessage(t('administrateUsers.alreadyInList'));
    } else if (isUserInOrganization(values.email)) {
      setErrorMessage(t('administrateUsers.alreadyInOrganization'));
    } else {
      onAddMember(values);
      // eslint-disable-next-line no-use-before-define
      changeValues({
        name: '',
        email: '',
        role: registeredMembersRoles.member,
      });
    }
  };

  const {
    changeValues,
    disableSubmit,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    properties,
    values,
  } = useForm({
    fields: {
      name: {
        default: '',
        required: true,
      },
      email: {
        default: '',
        lowerCase: true,
        required: true,
        type: 'email',
      },
      role: {
        default: registeredMembersRoles.member,
        required: true,
      },
    },
    onSubmit: onFormSubmit,
  });

  const parseCsv = (csv, fromFile) => {
    try {
      let invalidEmailError = false;
      let duplicateError = false;
      let alreadyAddedError = false;
      const lines = csv
        .split('\n')
        .map((line) => line.trim())
        .filter((line) => line);
      const members = [];
      const emails = new Set();
      for (let i = 0; i < lines.length; i += 1) {
        const splitLine = lines[i].split(',');
        if (splitLine.length === 2) {
          const member = {
            name: sanitize(splitLine[0]),
            email: splitLine[1].toLowerCase().trim(),
            role: registeredMembersRoles.member,
          };
          if (!isValidEmail(member.email)) {
            member.error = true;
            invalidEmailError = true;
          }
          if (emails.has(member.email)) {
            duplicateError = true;
          } else {
            if (isUserInOrganization(member.email)) {
              member.error = true;
              member.alreadyAdded = true;
              alreadyAddedError = true;
            }
            // Show errors at the start of the list
            if (member.error) {
              members.unshift(member);
            } else {
              members.push(member);
            }
            emails.add(member.email);
          }
        } else {
          throw new Error();
        }
      }
      if (fromFile) {
        setBatchMembers(members);
        setFailedUpload(false);
        if (invalidEmailError) {
          setErrorMessage(t('administrateUsers.invalidEmailFile'));
        } else if (duplicateError) {
          setErrorMessage(t('administrateUsers.duplicateMembers'));
        } else if (alreadyAddedError) {
          setErrorMessage(t('administrateUsers.alreadyInOrganizationFile'));
        }
      } else {
        if (duplicateError) {
          setErrorMessage(t('administrateUsers.duplicateMembers'));
        }
        setAddedMembers(members);
        setPreviewing(true);
      }
    } catch (error) {
      if (fromFile) {
        setBatchMembers([]);
        setFailedUpload(true);
        setErrorMessage(t('administrateUsers.malformedFile'));
      } else {
        setErrorMessage(t('administrateUsers.malformedPasted'));
      }
    }
    setSubmitting(false);
  };

  const onFileUploadChange = (event) => {
    setSubmitting(true);
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      parseCsv(event.target.result, true);
      inputRef.current.value = null;
    };
    reader.readAsText(file);
  };

  const onBackClick = () => {
    setPreviewing(false);
    setAddedMembers([]);
  };

  const getMembersAddedText = (members) => {
    const errorMembers = members.filter((member) => member.error).length;
    let text = t('administrateUsers.membersAdded', {
      count: members.length - errorMembers,
    });
    if (errorMembers) {
      text += t('administrateUsers.membersFailed', { count: errorMembers });
    }
    return text;
  };

  const isSendAllowed = () => {
    return (
      (addedMembers.length > 0 &&
        addedMembers.filter((member) => member.error).length === 0) ||
      (batchMembers.length > 0 &&
        batchMembers.filter((member) => member.error).length === 0)
    );
  };

  const actions = [
    {
      ariaLabel: t('administrateUsers.send'),
      disabled: !isSendAllowed(),
      handler: onDialogSubmit,
      label: t('administrateUsers.send'),
    },
  ];

  const addNewTab = (
    <>
      <Form onSubmit={handleSubmit}>
        <FormWrapper>
          <FormInput
            id="name"
            name="name"
            label={t('administrateUsers.name')}
            placeholder={t('administrateUsers.namePlaceholder')}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            properties={properties}
            theme="light"
            values={values}
          />
          <FormInput
            id="email"
            name="email"
            label={t('administrateUsers.email')}
            placeholder={t('administrateUsers.emailPlaceholder')}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            properties={properties}
            theme="light"
            values={values}
          />
          <FormSelect
            id="role"
            name="role"
            label={t('administrateUsers.role')}
            items={[
              {
                value: registeredMembersRoles.member,
                key: registeredMembersRoles.member,
                label: t('administrateUsers.member'),
              },
              {
                value: registeredMembersRoles.manager,
                key: registeredMembersRoles.manager,
                label: t('administrateUsers.manager'),
              },
              {
                value: registeredMembersRoles.admin,
                key: registeredMembersRoles.admin,
                label: t('administrateUsers.administrator'),
              },
            ]}
            handleChange={handleChange}
            properties={properties}
            theme="light"
            values={values}
          />
          <Button
            ariaLabel={t('administrateUsers.add')}
            disabled={disableSubmit}
            fullWidth
            submit
            theme="light"
            type="primary"
          >
            {t('administrateUsers.add')}
          </Button>
        </FormWrapper>
      </Form>
      <Spacer />
      {addedMembersParsed.length > 0 ? (
        <ListItems
          items={addedMembersParsed}
          itemsPerPage={4}
          onRemove={onRemoveMember}
          secondaryActionLabel={t('administrateUsers.alreadyAdded')}
          showLabel
        />
      ) : (
        <T color={theme.palette.darkDistinct} align="center">
          {t('administrateUsers.noMembersAdded')}
        </T>
      )}
    </>
  );

  const batchImportTab = () => {
    let uploadButtonLabel;
    if (batchMembers.length === 0) {
      uploadButtonLabel = failedUpload
        ? t('administrateUsers.uploadFailed')
        : t('administrateUsers.upload');
    } else {
      uploadButtonLabel = t('administrateUsers.uploadSuccessful');
    }
    return (
      <FullHeightSideSpaceWrapper>
        <T color={theme.palette.darkDistinct}>
          {t('administrateUsers.batchImportFirstHelper')}
        </T>
        <T
          color={theme.palette.darkDistinct}
          fontWeight="semibold"
          sx={{ mb: theme.spacing.s, mt: theme.spacing.m1 }}
        >
          {t('administrateUsers.upload')}
        </T>
        <T color={theme.palette.darkDistinct}>
          {t('administrateUsers.batchImportSecondHelper')}
        </T>
        <T color={theme.palette.darkDistinct}>
          {t('administrateUsers.batchImportThirdHelper')}
        </T>
        <Spacer />
        <label htmlFor="user-file-upload">
          <input
            type="file"
            accept="text/csv"
            id="user-file-upload"
            hidden
            onChange={onFileUploadChange}
            ref={inputRef}
          />
          <Button
            ariaLabel={uploadButtonLabel}
            component="span"
            fullWidth
            theme="light"
            type="primary"
          >
            {uploadButtonLabel}
          </Button>
        </label>
        <Spacer />
        {batchMembers.length > 0 && (
          <T color={theme.palette.darkDistinct}>{`${getMembersAddedText(
            batchMembers
          )}. ${t('administrateUsers.previewMessage')}`}</T>
        )}
        <FormInput
          id="users-list"
          name="users-list"
          label={t('administrateUsers.paste')}
          value={pastedCsv}
          handleChange={({ value }) => setPastedCsv(value)}
          disabled={batchMembers.length > 0}
          maxRows={4}
          minRows={4}
          multiline
          placeholder=""
          theme="light"
          required
        />
        <Spacer />
        <Button
          ariaLabel={t('administrateUsers.previewInvitations')}
          disabled={batchMembers.length === 0 && !pastedCsv}
          onClick={() => {
            if (batchMembers.length > 0) {
              setPreviewing(true);
              setAddedMembers(batchMembers);
            } else {
              parseCsv(pastedCsv);
            }
          }}
          theme="light"
          type="secondary"
        >
          {t('administrateUsers.previewInvitations')}
        </Button>
      </FullHeightSideSpaceWrapper>
    );
  };

  const tabsToSelect = [
    { label: t('administrateUsers.addNew'), component: addNewTab },
    { label: t('administrateUsers.batchImport'), component: batchImportTab() },
  ];

  return (
    <Dialog
      open
      onClose={
        formStatus || addedMembers.length === 0
          ? onClose
          : () => setShowSendInvitationsDialog(true)
      }
      ariaLabel={
        previewing
          ? t('administrateUsers.previewInvitations')
          : t('administrateUsers.inviteMembers')
      }
      title={
        previewing
          ? t('administrateUsers.previewInvitations')
          : t('administrateUsers.inviteMembers')
      }
      loading={submitting}
      actions={actions}
      contentPadding="0"
      scroll="body"
      formStatus={formStatus}
      errorMessage={errorMessage}
      onCloseNotificationMessage={() => {
        setErrorMessage(null);
        setFailedUpload(false);
      }}
    >
      {formStatus && (
        <SideSpaceWrapper>
          <Alert
            message={formStatus.message}
            severity={formStatus.success ? 'success' : 'error'}
            theme="light"
          />
        </SideSpaceWrapper>
      )}
      {!formStatus &&
        (previewing ? (
          <div>
            <PreviewTitleWrapper>
              <T color={theme.palette.darkDistinct} fontWeight="medium">
                {getMembersAddedText(addedMembers)}
              </T>
              <Button
                ariaLabel={t('generic.goBack')}
                onClick={onBackClick}
                sx={{ ml: '-12px' }}
                theme="light"
                type="text"
              >
                {t('generic.goBack')}
              </Button>
            </PreviewTitleWrapper>
            <ListItems
              items={addedMembersParsed}
              itemsPerPage={8}
              onRemove={onRemoveMember}
            />
            <Spacer />
            <FullHeightSideSpaceWrapper>
              <Button
                ariaLabel={t('generic.goBack')}
                onClick={onBackClick}
                theme="light"
                type="secondary"
              >
                {t('generic.goBack')}
              </Button>
            </FullHeightSideSpaceWrapper>
          </div>
        ) : (
          <FullWidthTabs
            tabsToSelect={tabsToSelect}
            onChangeTabHandler={onChangeTabHandler}
            selectedTabIndex={selectedTabIndex}
          />
        ))}
      <ConfirmationDialog
        open={showDiscardMembersDialog}
        formStatus={discardMembersDialogStatus}
        ariaLabel={t('administrateUsers.discardMembers')}
        title={t('administrateUsers.discardMembers')}
        description={t('administrateUsers.discardMembersDescription')}
        cancelText={t('generic.cancel')}
        confirmText={t('generic.confirm')}
        onCancel={onNotDiscardMembers}
        onClose={onNotDiscardMembers}
        onConfirm={() => {
          setShowDiscardMembersDialog(false);
          setDiscardMembersDialogStatus({ success: true, message: '' });
          setAddedMembers([]);
          setBatchMembers([]);
          setFailedUpload(false);
          setPastedCsv('');
        }}
      />
      <ConfirmationDialog
        open={showSendInvitationsDialog}
        ariaLabel={t('administrateUsers.invitationsNotSent')}
        title={t('administrateUsers.invitationsNotSent')}
        description={t('administrateUsers.invitationsNotSentDescription')}
        cancelText={t('administrateUsers.discard')}
        confirmText={t('administrateUsers.send')}
        onCancel={onClose}
        onClose={() => setShowSendInvitationsDialog(false)}
        onConfirm={onDialogSubmit}
      />
    </Dialog>
  );
};

InviteMembers.propTypes = {
  formStatus: PropTypes.shape({
    success: PropTypes.bool,
    message: PropTypes.string,
  }),
  isUserInOrganization: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
};

InviteMembers.defaultProps = {
  formStatus: null,
  isUserInOrganization: () => false,
};

export default InviteMembers;

import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

export default function useGroups({
  createGroup,
  deleteUserGroups,
  listGroupMembers,
  listGroups,
  onSubmitGroupMembers,
  organizationId,
  registeredMembers,
  removeFromGroup,
  requestToDeleteGroup,
  setFormStatus,
  setRegisteredMembers,
  transferGroup,
  transferUserGroups,
  updateGroup,
}) {
  const { t } = useTranslation();

  const [createGroupFormStatus, setCreateGroupFormStatus] = useState(null);
  const [deleteGroup, setDeleteGroup] = useState(false);
  const [deleteGroupMember, setDeleteGroupMember] = useState(false);
  const [groupMembers, setGroupMembers] = useState([]);
  const [groupMembersNextToken, setGroupMembersNextToken] = useState('');
  const [groupIdToRemoveMember, setGroupIdToRemoveMember] = useState();
  const [groupsInfo, setGroupsInfo] = useState([]);
  const [groupToDelete, setGroupToDelete] = useState(null);
  const [groupsInfoNextToken, setGroupsInfoNextToken] = useState('');
  const [loadingGroupsInfo, setLoadingGroupsInfo] = useState(false);
  const [memberToDeleteFromGroup, setMemberToDeleteFromGroup] = useState(null);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [loadingGroupMembers, setLoadingGroupMembers] = useState(true);

  const getGroupsInfo = async (nextToken) => {
    setLoadingGroupsInfo(true);
    const { data, error } = await listGroups(organizationId)(nextToken);
    if (error) {
      console.error('Error: ', error);
      setGroupsInfoNextToken(null);
      setLoadingGroupsInfo(false);
    }

    if (data) {
      setGroupsInfo((groupsInfo) => [...groupsInfo, ...data.listGroups.items]);
      if (data.listGroups.nextToken) {
        setGroupsInfoNextToken(data.listGroups.nextToken);
      } else {
        setLoadingGroupsInfo(false);
      }
    }
  };

  const getGroupMembers = async (nextToken) => {
    const { data, error } = await listGroupMembers(nextToken, selectedGroupId);
    if (error) {
      console.error('Error: ', error);
      setGroupMembers(null);
    }

    if (data) {
      const {
        listUsersByGroup: { nextToken: newNextToken },
      } = data;

      setGroupMembers((groupMembers) => [
        ...groupMembers,
        ...data.listUsersByGroup.items,
      ]);

      if (!nextToken) {
        setLoadingGroupMembers(false);
      }

      if (newNextToken) {
        setGroupMembersNextToken(data.listUsersByGroup.nextToken);
      }
    }
  };

  const handleOnSubmitGroupMembers = async (groupMembers, groupId) => {
    const userIds = groupMembers.map((member) => member.id);
    const { data, error } = await onSubmitGroupMembers({
      input: { id: groupId, userIds },
    });

    if (error) {
      console.error('Error: ', error);
      return {
        success: false,
        message: t('administrateUsers.updateGroupMembersFailed'),
      };
    }

    if (data?.updateGroupAccess) {
      setGroupsInfo(
        groupsInfo.map((group) => {
          if (group.id === groupId) {
            Object.assign(group, {
              membersCount:
                data.updateGroupAccess.success.count -
                (data.updateGroupAccess.failure?.count || 0),
              updatedAt: new Date().toISOString(),
            });
          }

          return group;
        })
      );

      const updatedRegisteredMembers = registeredMembers.map((member) => {
        const memberWasInGroup = member.groups
          .map((g) => g.id)
          .includes(groupId);
        const memberWasRemoved =
          memberWasInGroup && !userIds.includes(member.id);
        if (memberWasRemoved) {
          return {
            ...member,
            groups: member.groups.filter((g) => g.id !== groupId),
          };
        }

        const memberWasAdded = !memberWasInGroup && userIds.includes(member.id);
        if (memberWasAdded) {
          const groupName = groupsInfo.find((g) => g.id === groupId).name;
          return {
            ...member,
            groups: [...member.groups, { name: groupName, id: groupId }],
          };
        }

        return member;
      });
      const groupMembersUpdated = registeredMembers
        .filter((member) => userIds.includes(member.id))
        .map((member) => {
          return { ...member, id: member.id };
        });
      setGroupMembers(groupMembersUpdated);

      setRegisteredMembers(updatedRegisteredMembers);
      return {
        success: true,
        message: t('administrateUsers.groupUpdatedSuccessMessage'),
      };
    }

    return {
      success: false,
      message: t('administrateUsers.updateGroupMembersFailed'),
    };
  };

  const onCreateGroup = async (values) => {
    const { data, error } = await createGroup(organizationId)({
      input: values,
    });

    if (error) {
      setCreateGroupFormStatus({
        success: false,
        message: t('administrateUsers.createGroupfailed'),
      });
    }

    if (data?.createGroup) {
      groupsInfo.push(
        Object.assign(data.createGroup, {
          membersCount: 0,
        })
      );

      setGroupsInfo(groupsInfo);
      setCreateGroupFormStatus({
        success: true,
        message: t('administrateUsers.createGroupSuccess'),
      });
    }
  };

  const onDeleteGroup = (group) => {
    setDeleteGroup(true);
    setGroupToDelete(group);
  };

  const onDeleteGroupConfirm = async () => {
    const { id: groupId } = groupToDelete;
    const { data, error } = await requestToDeleteGroup({
      input: { id: groupId },
    });

    if (error) {
      setFormStatus({
        success: false,
        message: t('administrateUsers.deleteGroupFailure'),
      });
    }

    if (data) {
      setFormStatus({
        success: true,
        message: t('administrateUsers.deleteGroupSuccess'),
      });
      if (data.deleteGroup.id) {
        const deletedGroup = groupsInfo.find((group) => group.id === groupId);
        const listOfGroups = groupsInfo.filter((group) => group.id !== groupId);

        const updatedRegisteredMembers = registeredMembers.map((member) => {
          if (deletedGroup.ownedBy === member.id) {
            Object.assign(member, {
              ownedGroupsNumber: member.ownedGroupsNumber - 1,
            });
          }

          const memberWasInGroup = member.groups
            .map((g) => g.id)
            .includes(groupId);
          if (memberWasInGroup) {
            return {
              ...member,
              groups: member.groups.filter((g) => g.id !== groupId),
            };
          }

          return member;
        });

        setGroupsInfo(listOfGroups);
        setRegisteredMembers(updatedRegisteredMembers);
      }
    }
  };

  const onDeleteGroupMember = (user, groupId) => {
    setDeleteGroupMember(true);
    setMemberToDeleteFromGroup(user);
    setGroupIdToRemoveMember(groupId);
  };

  const onDeleteGroupMemberConfirm = async () => {
    const { id: userId } = memberToDeleteFromGroup;
    const { data, error } = await removeFromGroup({
      input: { id: groupIdToRemoveMember, userIds: [userId] },
    });

    if (error) {
      setFormStatus({
        success: false,
        message: t('administrateUsers.deleteMemberFromGroupFailure'),
      });
    }

    if (data?.removeGroupAccess) {
      setRegisteredMembers(
        registeredMembers.map((member) => {
          const memberWasRemoved = member.id === userId;
          if (memberWasRemoved) {
            return {
              ...member,
              groups: member.groups.filter(
                (g) => g.id !== groupIdToRemoveMember
              ),
            };
          }
          return member;
        })
      );

      setGroupsInfo(
        groupsInfo.map((group) => {
          if (group.id === groupIdToRemoveMember) {
            Object.assign(group, {
              membersCount: group.membersCount - 1,
              updatedAt: new Date().toISOString(),
            });
          }

          return group;
        })
      );

      setGroupMembers(
        groupMembers.filter((groupMember) => groupMember.id !== userId)
      );

      setFormStatus({
        success: true,
        message: t('administrateUsers.deleteMemberFromGroupSuccess'),
      });
    }
  };

  const onSaveEditGroup = async ({ groupId, name, description }) => {
    const { data } = await updateGroup({
      input: { id: groupId, name, description },
    });

    if (data) {
      const { id, name, description } = data.updateGroup;
      const updatedGroupsInfo = groupsInfo.map((group) => {
        if (group.id === id) {
          return {
            ...group,
            name,
            description,
          };
        }
        return group;
      });
      setGroupsInfo(updatedGroupsInfo);
      const updatedRegisteredMembers = registeredMembers.map((member) => {
        if (member.groups.filter((group) => group && group.id === id)) {
          return {
            ...member,
            groups: member.groups.map((group) => {
              if (group.id === id) {
                return { ...group, name };
              }
              return group;
            }),
          };
        }
        return member;
      });
      setRegisteredMembers(updatedRegisteredMembers);
      return { success: true };
    }

    return { success: false };
  };

  const onTransferGroup = async (input) => {
    const { data } = await transferGroup(organizationId)({ input });

    if (data) {
      setGroupsInfo(
        groupsInfo.map((group) => {
          if (group.id === input.groupId) {
            return {
              ...group,
              ownedBy: input.newOwnerId,
              ownedByName: registeredMembers.find(
                (member) => member.id === input.newOwnerId
              ).name,
            };
          }
          return group;
        })
      );

      setRegisteredMembers(
        registeredMembers.map((member) => {
          if (member.id === input.currentOwnerId) {
            const numberOfGroupsOwned = member.ownedGroupsNumber - 1;
            Object.assign(member, {
              ownedGroupsNumber:
                numberOfGroupsOwned > 0 ? numberOfGroupsOwned : 0,
            });
          }
          if (member.id === input.newOwnerId) {
            Object.assign(member, {
              ownedGroupsNumber: member.ownedGroupsNumber + 1,
            });
          }

          return member;
        })
      );

      return {
        success: true,
        message: t('administrateUsers.transferredOwnershipSuccess'),
      };
    }

    return {
      success: false,
      message: t('administrateUsers.transferredOwnershipFailure'),
    };
  };

  const requestToDeleteUserGroups = async (ownerId) => {
    const { data } = await deleteUserGroups(organizationId)({ ownerId });

    if (data && data.deleteUserGroups) {
      const deletedGroupIds = data.deleteUserGroups?.ids || [];

      setGroupsInfo(
        groupsInfo.filter((group) => !deletedGroupIds.includes(group.id))
      );

      setRegisteredMembers(
        registeredMembers.map((member) => {
          if (member.id === ownerId) {
            const numberOfGroupsOwned =
              member.ownedGroupsNumber - deletedGroupIds.length;
            Object.assign(member, {
              ownedGroupsNumber:
                numberOfGroupsOwned > 0 ? numberOfGroupsOwned : 0,
              groups: member.groups.filter(
                (group) => !deletedGroupIds.includes(group.id)
              ),
            });
          } else {
            Object.assign(member, {
              groups: member.groups.filter(
                (group) => !deletedGroupIds.includes(group.id)
              ),
            });
          }

          return member;
        })
      );

      return true;
    }

    return false;
  };

  const requestToTransferUserGroups = async (values) => {
    const { userSub: currentOwnerId, contentTransferTo: newOwnerId } = values;

    const { data } = await transferUserGroups(organizationId)({
      input: {
        currentOwnerId,
        newOwnerId,
      },
    });

    if (data && data.transferUserGroups) {
      const newOwner = registeredMembers.filter(
        (member) => member.id === newOwnerId
      );
      const transferredGroupIds = data.transferUserGroups?.ids || [];

      setGroupsInfo(
        groupsInfo.map((group) => {
          if (transferredGroupIds.includes(group.id)) {
            Object.assign(group, {
              ownedBy: newOwnerId,
              ownedByName: newOwner.length > 0 ? newOwner[0].name : '',
              updatedAt: new Date().toISOString(),
            });
          }

          return group;
        })
      );

      setRegisteredMembers(
        registeredMembers.map((member) => {
          if (member.id === currentOwnerId) {
            const numberOfGroupsOwned =
              member.ownedGroupsNumber - transferredGroupIds.length;
            Object.assign(member, {
              ownedGroupsNumber:
                numberOfGroupsOwned > 0 ? numberOfGroupsOwned : 0,
            });
          }

          if (member.id === newOwnerId) {
            Object.assign(member, {
              ownedGroupsNumber:
                member.ownedGroupsNumber + transferredGroupIds.length,
            });
          }

          return member;
        })
      );

      return true;
    }

    return false;
  };

  useEffect(() => {
    if (groupsInfoNextToken) {
      getGroupsInfo(groupsInfoNextToken);
    }
  }, [groupsInfoNextToken]);

  useEffect(() => {
    if (groupMembersNextToken) {
      getGroupMembers(groupMembersNextToken);
    }
  }, [groupMembersNextToken]);

  useEffect(() => {
    if (selectedGroupId) {
      setLoadingGroupMembers(true);
      setGroupMembers([]);
      getGroupMembers();
    }
  }, [selectedGroupId]);

  useEffect(() => {
    if (organizationId) {
      setGroupsInfo([]);
      getGroupsInfo();
    }
  }, [organizationId]);

  return {
    createGroupFormStatus,
    deleteGroup,
    deleteGroupMember,
    getGroupsInfo,
    groupMembers,
    groupsInfo,
    handleOnSubmitGroupMembers,
    loadingGroupMembers,
    loadingGroupsInfo,
    onCreateGroup,
    onDeleteGroup,
    onDeleteGroupConfirm,
    onDeleteGroupMember,
    onDeleteGroupMemberConfirm,
    onSaveEditGroup,
    onTransferGroup,
    requestToDeleteUserGroups,
    requestToTransferUserGroups,
    selectedGroupId,
    setCreateGroupFormStatus,
    setDeleteGroup,
    setDeleteGroupMember,
    setMemberToDeleteFromGroup,
    setSelectedGroupId,
  };
}

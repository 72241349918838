import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { invitationStatus, invitationSource } from 'shared/const';

export default function useInvitations({
  isBackOffice,
  isManager,
  listInvitations,
  organizationId,
  sendInvitationsQuery,
  setFormStatus,
}) {
  const { t } = useTranslation();

  const [invitedMembers, setInvitedMembers] = useState([]);
  const [inviteMembersOpen, setInviteMembersOpen] = useState(false);
  const [invitedNextToken, setInvitedNextToken] = useState('');
  const [loadingInvitedMembers, setLoadingInvitedMembers] = useState(true);
  const [showMultipleFeedback, setShowMultipleFeedback] = useState(false);

  const [failureList, setFailureList] = useState([]);
  const [successList, setSuccessList] = useState([]);

  const getInvitedMembers = async (nextToken) => {
    const { data, error } = await listInvitations(organizationId)(nextToken);

    if (error) {
      console.error('Error: ', error);
      setInvitedNextToken(null);
      setLoadingInvitedMembers(false);
    }

    if (data) {
      setInvitedMembers((invitedMembers) => [
        ...invitedMembers,
        ...data.listInvitations.items,
      ]);
      if (data.listInvitations.nextToken) {
        setInvitedNextToken(data.listInvitations.nextToken);
      } else {
        setLoadingInvitedMembers(false);
      }
    }
  };

  const onCloseInviteMembers = () => {
    setInviteMembersOpen(false);
    setFormStatus(null);
  };

  const sendInvitationsHandler = async (users) => {
    const { data, error } = await sendInvitationsQuery(organizationId)(users);

    if (error) {
      setFormStatus({
        success: false,
        message: t('administrateUsers.inviteFailure'),
      });
    }

    if (data) {
      const { failure, success } = data.sendInvitations;
      if (failure && failure.count > 0) {
        setFailureList(failure.items);
      }
      if (success && success.count > 0) {
        setSuccessList(success.items);
        const newInvitedMembers = success.items.map((user) => ({
          name: user.name,
          email: user.email,
          role: user.role,
          status: invitationStatus.pending,
          source: isBackOffice
            ? invitationSource.backoffice
            : invitationSource.client,
          invitedDate: new Date().toISOString(),
        }));
        setInvitedMembers([...newInvitedMembers, ...invitedMembers]);
      }

      setShowMultipleFeedback(true);
      setInviteMembersOpen(false);
    }
  };

  useEffect(() => {
    if (invitedNextToken) {
      getInvitedMembers(invitedNextToken);
    }
  }, [invitedNextToken]);

  useEffect(() => {
    if (!isManager && organizationId) {
      setInvitedMembers([]);
      getInvitedMembers();
    }
  }, [organizationId]);

  return {
    failureList,
    invitedMembers,
    inviteMembersOpen,
    loadingInvitedMembers,
    onCloseInviteMembers,
    sendInvitationsHandler,
    setFailureList,
    setInvitedMembers,
    setInviteMembersOpen,
    setShowMultipleFeedback,
    setSuccessList,
    showMultipleFeedback,
    successList,
  };
}

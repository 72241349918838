import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const HideAfterCounterWrapper = styled.div`
  font-size: 0.8rem;
  font-weight: 600;
`;

const HideAfterCounter = ({ startFrom, onComplete }) => {
  const [seconds, setSeconds] = useState(startFrom);

  const { t } = useTranslation();

  useEffect(() => {
    let interval = null;
    if (seconds) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      clearInterval(interval);
      onComplete();
    }
    return () => clearInterval(interval);
  }, [seconds, onComplete]);

  return (
    <HideAfterCounterWrapper>
      {`(${t('generic.seconds', { count: seconds })})`}
    </HideAfterCounterWrapper>
  );
};

HideAfterCounter.propTypes = {
  startFrom: PropTypes.number.isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default HideAfterCounter;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PLAYLIST_STATUS, PLAYLIST_TYPE } from 'shared/const';
import { localesEnum, selectTranslation } from 'shared/utils';

export default ({
  addNanoDegreeExam,
  createPlaylist,
  decreasePlaylistCounter,
  deletePlaylist,
  getNanoDegreeExam,
  getPlaylist,
  getPlaylistContent,
  handleHistory,
  increasePlaylistCounter,
  isGlobalOrganization,
  isNanodegree,
  listOwnedPlaylists,
  loadPlaylistContent,
  locale,
  managerSelected,
  organization,
  organizationId,
  playlistContent,
  playlistName,
  remindAllEventHandler,
  removePlaylistContent,
  selectedPlaylist,
  sendPlaylistReminder,
  setListOwnedPlaylists,
  setPlaylistContent,
  setSelectedPlaylist,
  transferPlaylist,
  updatePlaylist,
}) => {
  // Dialog open status variables
  const [openPlaylistForm, setOpenPlaylistForm] = useState(false);
  const [openNanodegreeForm, setOpenNanodegreeForm] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openPauseDialog, setOpenPauseDialog] = useState(false);
  const [openPublishDialog, setOpenPublishDialog] = useState(false);
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false);
  const [openAddExamDialog, setOpenAddExamDialog] = useState(false);
  const [openAddCoursesToPlaylist, setOpenAddCoursesToPlaylist] =
    useState(false);
  const [openSharePlaylistDialog, setOpenSharePlaylistDialog] = useState(false);
  const [openShowTransferOwnership, setOpenShowTransferOwnership] =
    useState(false);
  const [openCopyPlaylistForm, setOpenCopyPlaylistForm] = useState(false);

  // Dialog status
  const [formStatus, setFormStatus] = useState();
  const [submittingPublish, setSubmittingPublish] = useState(false);
  const [errorMessagePublish, setErrorMessagePublish] = useState();
  const [errorDetailPublish, setErrorDetailPublish] = useState();
  const [successPublish, setSuccessPublish] = useState();
  const [isExamLoading, setIsExamLoading] = useState(false);

  // Dialog state variables
  const [addCoursesValues, setAddCoursesValues] = useState(null);
  const [archivePlaylistId, setArchivePlaylistId] = useState();
  const [deleteNanodegree, setDeleteNanodegree] = useState(null);
  const [deletePlaylistId, setDeletePlaylistId] = useState();
  const [initialValues, setInitialValues] = useState({ locale });
  const [nanodegreeStatus, setNanodegreeStatus] = useState();
  const [pausePlaylist, setPausePlaylist] = useState();
  const [pausePlaylistId, setPausePlaylistId] = useState();
  const [publishPlaylistId, setPublishPlaylistId] = useState();
  const [publishPlaylistType, setPublishPlaylistType] = useState();
  const [reminderStatus, setReminderStatus] = useState({ open: false });
  const [removeContentId, setRemoveContentId] = useState(false);
  const [scrollToSelected, setScrollToSelected] = useState(null);
  const [selectedExam, setSelectedExam] = useState(null);
  const [selectedNanoDegree, setSelectedNanoDegree] = useState(null);
  const [selectedPlaylistForReminder, setSelectedPlaylistForReminder] =
    useState(null);
  const [shareValues, setShareValues] = useState(null);
  const [submittingReminder, setSubmittingReminder] = useState(false);
  const [transferValues, setTransferValues] = useState(null);

  const { t } = useTranslation();

  // Playlists dialogs

  const updateOwnedPlaylists = (values) => {
    setListOwnedPlaylists([...listOwnedPlaylists, values]);
  };

  async function onCreatePlaylist(values) {
    const { type } = values;

    const input = {
      titleTranslations: JSON.stringify(values.name),
      descriptionTranslations: JSON.stringify(values.description),
      type,
    };

    if (type === PLAYLIST_TYPE.NANO_DEGREE) {
      input.shortDescriptionTranslations = JSON.stringify(
        values.shortDescription
      );
      if (values.editorId) {
        input.editorId = values.editorId;
      }
      if ('thumbnailFile' in values) {
        input.thumbnail = values.thumbnailFile;
      }
      if ('trailerVideo' in values) {
        input.trailerVideo = values.trailerVideo || null;
      }
    }

    const { data, error } = await createPlaylist({
      input,
      ownerId: managerSelected,
    });

    if (error) {
      setFormStatus({
        success: false,
        message:
          type === PLAYLIST_TYPE.NANO_DEGREE
            ? t('nanodegree.createdFailureMessage')
            : t('playlist.playlistCreatedFailureMessage'),
      });
      return { error };
    }

    if (data) {
      const { createPlaylist: playlistData } = data;
      updateOwnedPlaylists(playlistData);
      if (isGlobalOrganization) {
        setScrollToSelected(playlistData.id);
      }
      increasePlaylistCounter(managerSelected);
      setFormStatus({
        success: true,
        message:
          type === PLAYLIST_TYPE.NANO_DEGREE
            ? t('nanodegree.createdSuccessMessage')
            : t('playlist.playlistCreatedSuccessMessage'),
      });
    }

    return true;
  }

  async function updatePlaylistHandler(values) {
    const { type } = values;

    const input = {
      id: values.id,
      titleTranslations: JSON.stringify(values.name),
      descriptionTranslations: JSON.stringify(values.description),
    };

    if (type === PLAYLIST_TYPE.NANO_DEGREE) {
      input.shortDescriptionTranslations = JSON.stringify(
        values.shortDescription
      );
      if (values.editorId) {
        input.editorId = values.editorId;
      }
      if ('thumbnailFile' in values) {
        input.thumbnail = values.thumbnailFile;
      }
      if ('trailerVideo' in values) {
        input.trailerVideo = values.trailerVideo;
      }
    }

    const { data, error } = await updatePlaylist({ input });
    if (error) {
      setFormStatus({
        success: false,
        message:
          type === PLAYLIST_TYPE.NANO_DEGREE
            ? t('nanodegree.updatedFailureMessage')
            : t('playlist.playlistUpdatedFailureMessage'),
      });
      return { error };
    }
    if (data) {
      const playlist = listOwnedPlaylists.find(
        (playlist) => playlist.id === data.updatePlaylist.id
      );
      playlist.titleTranslations = data.updatePlaylist.titleTranslations;
      playlist.descriptionTranslations =
        data.updatePlaylist.descriptionTranslations;

      if (playlist.type === PLAYLIST_TYPE.NANO_DEGREE) {
        playlist.shortDescriptionTranslations =
          data.updatePlaylist.shortDescriptionTranslations;
        playlist.editorId = data.updatePlaylist.editorId;
        playlist.thumbnail = data.updatePlaylist.thumbnail;
        playlist.thumbnailProcessingStatus =
          data.updatePlaylist.thumbnailProcessingStatus;
        playlist.trailerVideoFiles = data.updatePlaylist.trailerVideoFiles;
        playlist.trailerVideoProcessingStatus =
          data.updatePlaylist.trailerVideoProcessingStatus;
      }

      setListOwnedPlaylists([...listOwnedPlaylists]);
      setFormStatus({
        success: true,
        message:
          type === PLAYLIST_TYPE.NANO_DEGREE
            ? t('nanodegree.updatedSuccessMessage')
            : t('playlist.playlistUpdatedSuccessMessage'),
      });
    }
    return true;
  }

  const onClosePlaylistFormHandler = () => {
    setOpenPlaylistForm(false);
    setFormStatus(null);
    setInitialValues({ locale });
  };

  const onCloseNanodegreeFormHandler = () => {
    setOpenNanodegreeForm(false);
    setNanodegreeStatus();
    setFormStatus(null);
    setInitialValues({ locale });
  };

  const editPlaylistHandler = (id) => {
    const selectedPlaylist = listOwnedPlaylists.find(
      (playlist) => playlist.id === id
    );
    const { type } = selectedPlaylist;
    const initialValue = localesEnum.reduce(
      (acc, locale) => {
        const localeContent = {
          name: selectTranslation(
            selectedPlaylist.titleTranslations,
            selectedPlaylist.title,
            locale,
            ''
          ),
          description: selectTranslation(
            selectedPlaylist.descriptionTranslations,
            selectedPlaylist.description,
            locale,
            ''
          ),
        };

        if (type === PLAYLIST_TYPE.NANO_DEGREE) {
          localeContent.shortDescription = selectTranslation(
            selectedPlaylist.shortDescriptionTranslations,
            selectedPlaylist.shortDescription,
            locale,
            ''
          );
        }

        acc[locale] = localeContent;

        return acc;
      },
      {
        id: selectedPlaylist.id,
        locale,
        type,
      }
    );

    if (type === PLAYLIST_TYPE.NANO_DEGREE) {
      const [thumbnailUrl] = selectedPlaylist.thumbnailUrl || [null];

      setOpenNanodegreeForm(true);
      setNanodegreeStatus(selectedPlaylist.status);
      initialValue.editorId = selectedPlaylist.editorId;
      initialValue.thumbnailUrl = thumbnailUrl;
      initialValue.thumbnail = selectedPlaylist.thumbnail;
      initialValue.thumbnailProcessingStatus =
        selectedPlaylist.thumbnailProcessingStatus;
      initialValue.trailerVideoFiles = selectedPlaylist.trailerVideoFiles;
      initialValue.trailerVideoProcessingStatus =
        selectedPlaylist.trailerVideoProcessingStatus;
      initialValue.trailerUrl = selectedPlaylist.trailerUrl;
    } else {
      setOpenPlaylistForm(true);
    }

    setInitialValues(initialValue);
  };

  const deleteHandler = (playlistId, isNanodegree) => {
    setRemoveContentId(false);
    setOpenDeleteDialog(true);
    setDeletePlaylistId(playlistId);
    setDeleteNanodegree(isNanodegree);
  };

  const onDeletePlaylist = async (playlistId) => {
    const { data, error } = await deletePlaylist({
      input: { id: playlistId },
    });
    if (error) {
      setFormStatus({
        success: false,
        message: deleteNanodegree
          ? t('playlist.deleteNanodegreeFailureMessage')
          : t('playlist.deletePlaylistFailureMessage'),
      });
    }
    if (data) {
      const { ownerId } = listOwnedPlaylists.find(
        (playlist) => playlist.id === data.deletePlaylist.id
      );
      const updatedPlaylist = listOwnedPlaylists.filter(
        (playlist) => playlist.id !== data.deletePlaylist.id
      );
      setListOwnedPlaylists([...updatedPlaylist]);
      setSelectedPlaylist(null);
      decreasePlaylistCounter(ownerId);
      setFormStatus({
        success: true,
        message: deleteNanodegree
          ? t('playlist.deleteNanodegreeSuccessMessage')
          : t('playlist.deletePlaylistSuccessMessage'),
      });
    }
  };

  const onDeleteCloseHandler = () => {
    setOpenDeleteDialog(false);
    setDeletePlaylistId();
    setFormStatus(null);
    setDeleteNanodegree(false);
  };

  const onStatusUpdate = async (id, status) => {
    const input = { id, status };

    const { data, error } = await updatePlaylist({ input });

    if (error) {
      setFormStatus({
        success: false,
        message: t(
          isNanodegree
            ? 'nanodegree.updatedFailureMessage'
            : 'playlist.playlistUpdatedFailureMessage'
        ),
      });
      return { error };
    }

    if (data) {
      const playlist = listOwnedPlaylists.find(
        (playlist) => playlist.id === data.updatePlaylist.id
      );
      playlist.status = data.updatePlaylist.status;
      playlist.archivedScheduledAt = data.updatePlaylist.archivedScheduledAt;
      playlist.daysLeft = data.updatePlaylist.daysLeft;
      playlist.archiveDate = data.updatePlaylist.archiveDate;
      setListOwnedPlaylists([...listOwnedPlaylists]);
      setFormStatus({
        success: true,
        message: t(
          isNanodegree
            ? 'nanodegree.updatedSuccessMessage'
            : 'playlist.playlistUpdatedSuccessMessage'
        ),
      });
    }
  };

  // Pause dialog

  const pauseHandler = (id) => {
    setOpenPauseDialog(true);
    setPausePlaylistId(id);
    setPausePlaylist(listOwnedPlaylists.find((playlist) => playlist.id === id));
    setFormStatus(null);
  };

  const onPauseCloseHandler = () => {
    setOpenPauseDialog(false);
    setPausePlaylistId();
    setPausePlaylist();
    setFormStatus(null);
  };

  // Archive dialog

  const archiveHandler = (id) => {
    setOpenArchiveDialog(true);
    setArchivePlaylistId(id);
    setFormStatus(null);
  };

  const onArchiveCloseHandler = () => {
    setOpenArchiveDialog(false);
    setArchivePlaylistId();
    setFormStatus(null);
  };

  // Publish dialog

  const publishHandler = (id, type) => {
    setOpenPublishDialog(true);
    setPublishPlaylistId(id);
    setPublishPlaylistType(type);
    setFormStatus(null);
    setSubmittingPublish(false);
  };

  const onPublishCloseHandler = () => {
    setOpenPublishDialog(false);
    setPublishPlaylistId();
    setPublishPlaylistType();
    setFormStatus(null);
    setSubmittingPublish(false);
  };

  const onPublish = async () => {
    const playlist = listOwnedPlaylists.find(
      (playlist) => playlist.id === publishPlaylistId
    );

    const detailedErrors = [];

    if (publishPlaylistType === PLAYLIST_TYPE.NANO_DEGREE) {
      if ((playlist.children || []).length === 0) {
        detailedErrors.push({
          title: t('nanodegree.publishErrors.empty'),
          description: t('nanodegree.publishErrors.emptyDescription'),
        });
      }

      if ((playlist.children || []).length > 20) {
        detailedErrors.push({
          title: t('nanodegree.publishErrors.tooManyCourses'),
          description: t('nanodegree.publishErrors.tooManyCoursesDescription'),
        });
      }

      if (!playlist.quizId) {
        detailedErrors.push({
          title: t('nanodegree.publishErrors.exam'),
          description: t('nanodegree.publishErrors.examDescription'),
        });
      }

      if (!playlist.editorId) {
        detailedErrors.push({
          title: t('nanodegree.publishErrors.expert'),
          description: t('nanodegree.publishErrors.expertDescription'),
        });
      }

      if (!playlist.thumbnail) {
        detailedErrors.push({
          title: t('nanodegree.publishErrors.thumbnail'),
          description: t('nanodegree.publishErrors.thumbnailDescription'),
        });
      }

      if (!playlist.trailerVideoFiles) {
        detailedErrors.push({
          title: t('nanodegree.publishErrors.trailer'),
          description: t('nanodegree.publishErrors.trailerDescription'),
        });
      }

      if (
        !selectTranslation(
          playlist.shortDescriptionTranslations,
          null,
          locale,
          ''
        )
      ) {
        detailedErrors.push({
          title: t('nanodegree.publishErrors.shortDescription'),
          description: t(
            'nanodegree.publishErrors.shortDescriptionDescription'
          ),
        });
      }

      const { data } = await getPlaylistContent(playlist.children || []);

      if (
        data &&
        data.getCourses &&
        data.getCourses.some((course) => course.daysLeft !== null)
      ) {
        detailedErrors.push({
          title: t('nanodegree.publishErrors.deletedCourse'),
          description: t('nanodegree.publishErrors.deletedCourseDescription'),
        });
      }
    } else if (
      publishPlaylistType === PLAYLIST_TYPE.PLAYLIST &&
      !playlist.refId
    ) {
      if ((playlist.children || []).length === 0) {
        detailedErrors.push({
          title: t('playlist.publishErrors.empty'),
          description: t('playlist.publishErrors.emptyDescription'),
        });
      }

      if (organization.hideVcCourses) {
        const { data } = await getPlaylistContent(playlist.children || []);
        if (
          data &&
          data.getCourses &&
          data.getCourses.some(
            (course) =>
              !course.ownerOrganizationId ||
              course.ownerOrganizationId !== organizationId
          )
        ) {
          detailedErrors.push({
            title: t('playlist.publishErrors.hideVc'),
            description: t('playlist.publishErrors.hideVcDescription'),
          });
        }
      }
    }

    if (detailedErrors.length > 0) {
      setErrorMessagePublish(t('playlist.publishError'));
      if (detailedErrors.length > 0) {
        setErrorDetailPublish(detailedErrors);
      }
    } else {
      const input = {
        id: publishPlaylistId,
        status: PLAYLIST_STATUS.PUBLISHED,
      };

      const { data, error } = await updatePlaylist({ input });

      if (error) {
        setErrorMessagePublish(t('playlist.publishError'));
      }

      if (!error && data && data.updatePlaylist) {
        playlist.status = data.updatePlaylist.status;
        playlist.publishedAt = data.updatePlaylist.publishedAt;
        setListOwnedPlaylists([...listOwnedPlaylists]);
        setSuccessPublish(true);
      }
    }

    onPublishCloseHandler();
  };

  // Content dialog

  const openAddContentHandler = () => {
    setAddCoursesValues({
      id: selectedPlaylist,
      name: playlistName,
      courses: playlistContent.map((course) => ({ ...course, selected: true })),
    });
    setOpenAddCoursesToPlaylist(true);
  };

  const onPlaylistContentDialogClose = () => {
    setSelectedPlaylist(null);
    handleHistory(`manager=${managerSelected}`);
  };

  const addContentHandler = async (id) => {
    const playlist = listOwnedPlaylists.find((playlist) => playlist.id === id);
    const playlistContent = await loadPlaylistContent(playlist);

    setAddCoursesValues({
      id,
      name: selectTranslation(
        playlist.titleTranslations,
        playlist.title,
        locale,
        ''
      ),
      courses: playlistContent.map((course) => ({
        ...course,
        selected: true,
      })),
    });
    setOpenAddCoursesToPlaylist(true);
  };

  const updatePlaylistContentHandler = (
    playlistId,
    selectedCourses,
    playlistChildren
  ) => {
    const updatedPlaylistContent = playlistChildren.map((itemId) =>
      selectedCourses.find((course) => course.id === itemId)
    );

    const listOwnedPlaylistsNew = [...listOwnedPlaylists];
    listOwnedPlaylistsNew.find(
      (playlist) => playlist.id === playlistId
    ).children = playlistChildren;
    setListOwnedPlaylists(listOwnedPlaylistsNew);
    if (selectedPlaylist === playlistId) {
      setPlaylistContent([...updatedPlaylistContent]);
    }
  };

  const deleteFromPlaylist = (contentId) => {
    setDeletePlaylistId(false);
    setOpenDeleteDialog(true);
    setRemoveContentId(contentId);
  };

  const removeFromPlaylistHandler = async (contentId) => {
    const input = { id: selectedPlaylist, content: [contentId] };
    const { data, error } = await removePlaylistContent({ input });

    if (error) {
      setFormStatus({
        success: false,
        message: t(
          isNanodegree
            ? 'nanodegree.removeFromNanodegreeFailureMessage'
            : 'playlist.removeFromPlaylistFailureMessage'
        ),
      });
    }

    if (data) {
      const playlist = listOwnedPlaylists.find(
        (playlist) => playlist.id === selectedPlaylist
      );
      playlist.children = data.removePlaylistContent.children;

      setPlaylistContent(
        playlistContent.filter((child) =>
          data.removePlaylistContent.children.includes(child.id)
        )
      );

      setListOwnedPlaylists(listOwnedPlaylists);
      setFormStatus({
        success: true,
        message: t(
          isNanodegree
            ? 'nanodegree.removeFromNanodegreeSuccessMessage'
            : 'playlist.removeFromPlaylistSuccessMessage'
        ),
      });
    }
  };

  // Add exam dialog

  const openAddExamDialogHandler = async (playlistId) => {
    const selectedPlaylist = listOwnedPlaylists.find(
      (playlist) => playlist.id === playlistId
    );

    setSelectedNanoDegree(selectedPlaylist);
    setIsExamLoading(true);
    setOpenAddExamDialog(true);

    if (selectedPlaylist.quizId) {
      const { data, error } = await getNanoDegreeExam(selectedPlaylist.quizId);

      if (error) {
        setFormStatus({
          success: false,
          message: selectedPlaylist.quizId
            ? t('nanodegree.updateExamFailure')
            : t('nanodegree.addExamFailure'),
        });
        setSelectedExam([]);
      } else {
        const { getQuizzes } = data;
        const { questions } = getQuizzes[0];
        setSelectedExam(questions);
      }
    } else {
      setSelectedExam([]);
    }
    setIsExamLoading(false);
  };

  const onCloseAddExamDialogHandler = () => {
    setFormStatus(null);
    setOpenAddExamDialog(false);
  };

  const onSubmitAddExamHandler = async (questions) => {
    const input = {
      playlistId: selectedNanoDegree.id,
      questions: questions.map(({ text, options }) => {
        return {
          text,
          options,
        };
      }),
    };

    const originalQuizId = selectedNanoDegree?.quizId;
    if (selectedNanoDegree?.quizId) {
      input.id = selectedNanoDegree.quizId;
    }

    const { data, error } = await addNanoDegreeExam({ input });

    if (error) {
      setFormStatus({
        success: false,
        message: originalQuizId
          ? t('nanodegree.updateExamFailure')
          : t('nanodegree.addExamFailure'),
      });
    }

    if (data) {
      const { id: quizId } = data.createOrUpdateQuiz;
      const listOwnedPlaylistsNew = [...listOwnedPlaylists];
      listOwnedPlaylistsNew.find(
        (playlist) => playlist.id === selectedNanoDegree.id
      ).quizId = quizId;
      setListOwnedPlaylists(listOwnedPlaylistsNew);
      setFormStatus({
        success: true,
        message: originalQuizId
          ? t('nanodegree.updateExamSuccess')
          : t('nanodegree.addExamSuccess'),
      });
    }
  };

  // Transfer dialog

  const onTransferOwnershipHandler = async (id) => {
    const newOwnerId = id[0].id;
    const input = {
      playlistId: transferValues.id,
      currentOwnerId: transferValues.ownerId,
      newOwnerId,
    };
    const { data, error } = await transferPlaylist({ input });

    if (error) {
      setFormStatus({
        success: false,
        message: t('administrateUsers.transferredOwnershipFailure'),
      });
    }

    if (data) {
      setFormStatus({
        success: true,
        message: t('administrateUsers.transferredOwnershipSuccess'),
      });
      increasePlaylistCounter(newOwnerId);
      decreasePlaylistCounter(transferValues.ownerId);

      if (managerSelected !== 'all') {
        const updatedPlaylists = listOwnedPlaylists.filter(
          (playlist) => playlist.id !== transferValues.id
        );
        setListOwnedPlaylists([...updatedPlaylists]);
      }
    }
  };

  const openTransferOwnershipHandler = (id) => {
    const playlist = listOwnedPlaylists.find((item) => item.id === id);
    const title = selectTranslation(
      playlist.titleTranslations,
      playlist.title,
      locale,
      ''
    );
    setTransferValues({
      id,
      name: title,
      ownerId: playlist.ownerId,
    });
    setOpenShowTransferOwnership(true);
  };

  const onCloseTransferHandler = () => {
    setOpenShowTransferOwnership(false);
    setFormStatus(null);
    setTransferValues(null);
  };

  // Share dialog

  const openSharePlaylistHandler = async (playlistId, playlistTitle) => {
    setOpenSharePlaylistDialog(true);
    const selectedPlaylist = listOwnedPlaylists.find(
      (playlist) => playlist.id === playlistId
    );
    const { data } = await getPlaylist(playlistId);

    if (data && data.getPlaylist && data.getPlaylist.shareType) {
      selectedPlaylist.shareType = data.getPlaylist.shareType;
    }

    setShareValues({
      playlist: selectedPlaylist,
      playlistName: playlistTitle,
    });
  };

  const onSuccessSharing = (playlistId, shareType) => {
    const playlist = listOwnedPlaylists.find(
      (playlist) => playlist.id === playlistId
    );
    playlist.shareType = shareType;
    setListOwnedPlaylists([...listOwnedPlaylists]);
  };

  const closeShareDialogHandler = () => {
    setShareValues(null);
    setOpenSharePlaylistDialog(false);
  };

  // Reminder dialog

  const sendReminderHandler = async () => {
    setSubmittingReminder(true);
    // send matomo event in client
    if (remindAllEventHandler) {
      remindAllEventHandler();
    }
    let reminderSuccess = false;
    try {
      const { data } = await sendPlaylistReminder(
        null,
        selectedPlaylistForReminder
      );
      reminderSuccess = data?.sendPlaylistReminder?.success;
    } catch (e) {
      console.error(e);
      setSubmittingReminder(false);
    }
    if (reminderSuccess) {
      setListOwnedPlaylists((playlists) =>
        playlists.map((playlist) => {
          if (playlist.id === selectedPlaylistForReminder) {
            return {
              ...playlist,
              lastReminderAt: new Date().toISOString(),
            };
          }
          return playlist;
        })
      );
    }
    setReminderStatus({
      open: true,
      type: reminderSuccess ? 'success' : 'error',
      message: reminderSuccess
        ? t('playlist.reminderSentSuccessfully')
        : t('playlist.reminderSendingFailed'),
    });
    setSubmittingReminder(false);
    setSelectedPlaylistForReminder(null);
  };

  return {
    // Share open variables
    openPlaylistForm,
    openNanodegreeForm,
    openDeleteDialog,
    openPauseDialog,
    openPublishDialog,
    openArchiveDialog,
    openAddExamDialog,
    openAddCoursesToPlaylist,
    openSharePlaylistDialog,
    openShowTransferOwnership,
    openCopyPlaylistForm,
    setOpenAddCoursesToPlaylist,
    setOpenCopyPlaylistForm,
    setOpenPlaylistForm,
    setOpenNanodegreeForm,
    // Dialog status
    formStatus,
    submittingPublish,
    errorMessagePublish,
    errorDetailPublish,
    successPublish,
    setErrorMessagePublish,
    setSuccessPublish,
    isExamLoading,
    // Dialog state variables
    addCoursesValues,
    archivePlaylistId,
    deleteNanodegree,
    deletePlaylistId,
    initialValues,
    nanodegreeStatus,
    pausePlaylist,
    pausePlaylistId,
    publishPlaylistType,
    reminderStatus,
    removeContentId,
    scrollToSelected,
    selectedExam,
    selectedNanoDegree,
    selectedPlaylistForReminder,
    setScrollToSelected,
    setSelectedPlaylistForReminder,
    setSubmittingPublish,
    shareValues,
    submittingReminder,
    transferValues,
    updateOwnedPlaylists,
    // Playlists dialogs
    onCreatePlaylist,
    updatePlaylistHandler,
    onClosePlaylistFormHandler,
    onCloseNanodegreeFormHandler,
    editPlaylistHandler,
    deleteHandler,
    onDeletePlaylist,
    onDeleteCloseHandler,
    onStatusUpdate,
    // Pause dialog
    pauseHandler,
    onPauseCloseHandler,
    // Archive dialog
    archiveHandler,
    onArchiveCloseHandler,
    // Publish dialog
    publishHandler,
    onPublish,
    onPublishCloseHandler,
    // Content dialog
    addContentHandler,
    openAddContentHandler,
    onPlaylistContentDialogClose,
    updatePlaylistContentHandler,
    deleteFromPlaylist,
    removeFromPlaylistHandler,
    // Add exam dialog
    openAddExamDialogHandler,
    onCloseAddExamDialogHandler,
    onSubmitAddExamHandler,
    // Transfer dialog
    openTransferOwnershipHandler,
    onTransferOwnershipHandler,
    onCloseTransferHandler,
    // Share dialog
    openSharePlaylistHandler,
    onSuccessSharing,
    closeShareDialogHandler,
    // Reminder dialog
    sendReminderHandler,
    setReminderStatus,
  };
};

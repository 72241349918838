import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Button from '@shared/atoms/Button';
import FormAutocompleteWithList from '@shared/atoms/FormFields/FormAutocompleteWithList';
import FormInput from '@shared/atoms/FormFields/FormInput';
import FormSelect from '@shared/atoms/FormFields/FormSelect';
import T from '@shared/atoms/Typography';
import lang from '@shared/config/lang';
import { AUTOCOMPLETEWITHLIST_TYPE } from '@shared/const';
import theme from '@shared/themes/default';
import { logger, selectTranslation, useForm } from '@shared/utils';

import HorizontalRule from '@shared/atoms/HorizontalRule';
import DomainsList from './components/DomainsList';
import {
  addFeaturedItem,
  removeFeaturedItem,
  updateDomain,
} from './graphql/mutations';
import { listCourses, listDomains, listFeaturedItems } from './graphql/queries';
import { useGraphQLQuery } from '../../../utils';

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m1};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.l};
`;

const InnerWrapper = styled.div`
  flex: 1;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m1};
`;

const DomainsView = () => {
  const [courses, setCourses] = useState([]);
  const [domains, setDomains] = useState([]);
  const [featuredItems, setFeaturedItems] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState();
  const [selectedDomainLanguages, setSelectedDomainLanguages] = useState();

  const { t } = useTranslation();

  const {
    callQuery: callCoursesQuery,
    data: coursesData,
    errors: coursesErrors,
    hasEnded: hasCoursesEnded,
    hasErrors: hasCoursesErrors,
    loading: coursesLoading,
  } = useGraphQLQuery();

  const {
    callQuery: callDomainsQuery,
    data: domainsData,
    errors: domainsErrors,
    hasEnded: hasDomainsEnded,
    hasErrors: hasDomainsErrors,
    loading: domainsLoading,
  } = useGraphQLQuery();

  const {
    callQuery: callFeaturedQuery,
    data: featuredData,
    errors: featuredErrors,
    hasEnded: hasFeaturedEnded,
    hasErrors: hasFeaturedErrors,
    loading: featuredLoading,
  } = useGraphQLQuery();

  const {
    callQuery: callAddFeaturedQuery,
    data: addFeaturedData,
    errors: addFeaturedErrors,
    hasEnded: hasAddFeaturedEnded,
    hasErrors: hasAddFeaturedErrors,
    loading: addFeaturedLoading,
  } = useGraphQLQuery();

  const {
    callQuery: callRemoveFeaturedQuery,
    data: removeFeaturedData,
    errors: removeFeaturedErrors,
    hasEnded: hasRemoveFeaturedEnded,
    hasErrors: hasRemoveFeaturedErrors,
    loading: removeFeaturedLoading,
  } = useGraphQLQuery();

  const {
    callQuery: callUpdateDomainsQuery,
    data: updateDomainsData,
    errors: updateDomainsErrors,
    hasEnded: hasUpdateDomainsEnded,
    hasErrors: hasUpdateDomainsErrors,
  } = useGraphQLQuery();

  const {
    disableSubmit,
    errors: formErrors,
    handleBlur,
    handleChange,
    handleSubmit,
    properties,
    submitting,
    setDefaults,
    values,
  } = useForm({
    fields: {
      name: {
        required: true,
      },
      metatag: {
        required: true,
      },
      currency: {
        required: true,
      },
      defaultLanguage: {
        required: true,
      },
    },
    onSubmitHook: (domain) => {
      callUpdateDomainsQuery(
        updateDomain({
          ...domain,
          domain: selectedDomain.domain,
          languages: selectedDomainLanguages.map(({ key }) => key),
        })
      );
    },
    onSubmitHookEnded: hasUpdateDomainsEnded,
  });

  const loadCourses = async (domain, nextToken) => {
    callCoursesQuery(listCourses(domain, nextToken));
  };

  const loadDomains = async (nextToken) => {
    callDomainsQuery(listDomains(nextToken));
  };

  const loadFeaturedItems = async (domain) => {
    callFeaturedQuery(listFeaturedItems(domain));
  };

  useEffect(() => {
    if (hasCoursesEnded) {
      if (hasCoursesErrors) {
        logger.error('Error: ', coursesErrors);
      } else {
        const {
          listCourses: { items, nextToken },
        } = coursesData;
        // Filtering out courses marked for archival, as currently it cannot be done from the backend
        setCourses([
          ...courses,
          ...items.filter((course) => !course.archivedScheduledAt),
        ]);
        if (nextToken) {
          loadCourses(selectedDomain.domain, nextToken);
        }
      }
    }
  }, [hasCoursesEnded]);

  useEffect(() => {
    if (hasDomainsEnded) {
      if (hasDomainsErrors) {
        logger.error('Error: ', domainsErrors);
      } else {
        const {
          listDomains: { items, nextToken },
        } = domainsData;
        setDomains([...domains, ...items]);
        if (nextToken) {
          loadDomains(nextToken);
        }
      }
    }
  }, [hasDomainsEnded]);

  useEffect(() => {
    if (hasFeaturedEnded) {
      if (hasFeaturedErrors) {
        logger.error('Error: ', featuredErrors);
      } else {
        const { listFeaturedItems } = featuredData;
        setFeaturedItems([...featuredItems, ...listFeaturedItems]);
      }
    }
  }, [hasFeaturedEnded]);

  useEffect(() => {
    if (hasAddFeaturedEnded) {
      if (hasAddFeaturedErrors) {
        logger.error('Error: ', addFeaturedErrors);
      } else {
        const { addFeaturedItem } = addFeaturedData;
        setFeaturedItems([...featuredItems, addFeaturedItem]);
      }
    }
  }, [hasAddFeaturedEnded]);

  useEffect(() => {
    if (hasRemoveFeaturedEnded) {
      if (hasRemoveFeaturedErrors) {
        logger.error('Error: ', removeFeaturedErrors);
      } else {
        const { removeFeaturedItem } = removeFeaturedData;
        setFeaturedItems(
          featuredItems.filter(({ id }) => id !== removeFeaturedItem.id)
        );
      }
    }
  }, [hasRemoveFeaturedEnded]);

  useEffect(() => {
    if (hasUpdateDomainsEnded) {
      if (hasUpdateDomainsErrors) {
        logger.error('Error: ', updateDomainsErrors);
      } else {
        const { updateDomain } = updateDomainsData;

        setDomains(
          domains.map((domain) =>
            domain.domain === updateDomain.domain ? updateDomain : domain
          )
        );
      }
    }
  }, [hasUpdateDomainsEnded]);

  useEffect(() => {
    loadDomains();
  }, []);

  useEffect(() => {
    if (selectedDomain) {
      setCourses([]);
      setFeaturedItems([]);
      loadCourses(selectedDomain.domain);
      loadFeaturedItems(selectedDomain.domain);
      setSelectedDomainLanguages(
        lang.data.languagesSuggestions
          .map(({ id, name }) => ({ key: id, title: name }))
          .filter(({ key }) => selectedDomain.languages.includes(key))
      );
      setDefaults({
        name: selectedDomain.name,
        metatag: selectedDomain.metatag,
        currency: selectedDomain.currency,
        defaultLanguage: selectedDomain.defaultLanguage,
      });
    }
  }, [selectedDomain]);

  return (
    <OuterWrapper>
      <T fontWeight="medium" variant="headingS">
        Domains
      </T>
      <HorizontalRule />
      <ContentWrapper>
        <InnerWrapper>
          <DomainsList
            domains={domains}
            loading={domainsLoading}
            selectedDomain={selectedDomain}
            setSelectedDomain={setSelectedDomain}
          />
        </InnerWrapper>
        <InnerWrapper>
          {selectedDomain && (
            <InputWrapper>
              <T fontWeight="bold" variant="subtitle">
                Edit Domain
              </T>
              <FormInput
                id="domain"
                name="domain"
                disabled
                label="Domain"
                required
                value={selectedDomain.domain}
              />
              <FormInput
                id="name"
                name="name"
                label="Name"
                errors={formErrors}
                handleBlur={handleBlur}
                handleChange={handleChange}
                properties={properties}
                values={values}
              />
              <FormInput
                id="metatag"
                name="metatag"
                label="MetaTags"
                errors={formErrors}
                handleBlur={handleBlur}
                handleChange={handleChange}
                properties={properties}
                values={values}
              />
              <FormSelect
                id="currency"
                name="currency"
                items={lang.data.currencySuggestions.map((currency) => ({
                  value: currency,
                  key: currency,
                  label: currency,
                }))}
                label="Currency"
                handleChange={handleChange}
                properties={properties}
                values={values}
              />
              <FormAutocompleteWithList
                id="languages"
                name="languages"
                disabled={submitting}
                items={lang.data.languagesSuggestions.map(({ id, name }) => ({
                  key: id,
                  title: name,
                }))}
                label="Languages"
                loadingText={t('generic.loadingText')}
                noOptionsText={t('generic.noSearchResults')}
                onChange={(languages) => {
                  setSelectedDomainLanguages(languages);
                }}
                required
                selectedItems={selectedDomainLanguages}
                theme="dark"
                type={AUTOCOMPLETEWITHLIST_TYPE.FORM}
              />
              <FormSelect
                id="defaultLanguage"
                name="defaultLanguage"
                items={lang.data.languagesSuggestions.map(({ id, name }) => ({
                  value: id,
                  key: id,
                  label: name,
                }))}
                label="Default Language"
                handleChange={handleChange}
                properties={properties}
                values={values}
              />
              <Button
                disabled={disableSubmit}
                loading={submitting}
                onClick={handleSubmit}
              >
                Save
              </Button>
              <T fontWeight="bold" variant="subtitle">
                Edit Promoted Course
              </T>
              <FormAutocompleteWithList
                id="promoted-course"
                name="promoted-course"
                disabled={addFeaturedLoading || removeFeaturedLoading}
                items={courses.map(({ id, name, nameTranslations }) => ({
                  key: id,
                  title: selectTranslation(
                    nameTranslations,
                    name,
                    selectedDomain.defaultLanguage,
                    `<No name, ID: ${id}>`
                  ),
                }))}
                label="Promoted course"
                loading={coursesLoading && featuredLoading}
                loadingText={t('generic.loadingText')}
                noOptionsText={t('generic.noSearchResults')}
                onAdd={(courseId) => {
                  callAddFeaturedQuery(
                    addFeaturedItem({
                      courseId,
                      domain: selectedDomain.domain,
                      organizationId: 'ALL',
                    })
                  );
                }}
                onDelete={(courseId) => {
                  callRemoveFeaturedQuery(
                    removeFeaturedItem({
                      id: featuredItems.find(
                        (item) => item.course.id === courseId
                      ).id,
                    })
                  );
                }}
                required
                selectedItems={featuredItems.map(({ course }) => ({
                  key: course.id,
                  title: selectTranslation(
                    course.nameTranslations,
                    course.name,
                    selectedDomain.defaultLanguage,
                    `<No name, ID: ${course.id}>`
                  ),
                }))}
                theme="dark"
                type={AUTOCOMPLETEWITHLIST_TYPE.FORM}
              />
            </InputWrapper>
          )}
        </InnerWrapper>
      </ContentWrapper>
    </OuterWrapper>
  );
};

export default DomainsView;

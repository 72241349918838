import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import useMediaQuery from '@mui/material/useMediaQuery';

import Alert from 'shared/atoms/Alert';
import Button from 'shared/atoms/Button';
import Form from 'shared/atoms/Form';
import FormInput from 'shared/atoms/FormFields/FormInput';
import T from 'shared/atoms/Typography';
import theme from 'shared/themes/default';
import { useForm } from 'shared/utils';

const StyledButtonWrapper = styled.div`
  display: flex;
  gap: ${theme.spacing.m1};
  justify-content: end;
  margin-top: ${theme.spacing.m3};

  ${theme.breakpoints.mobile} {
    flex-direction: column-reverse;
    margin-top: ${theme.spacing.m1};
  }
`;

const LoginPassword = ({
  disableInputs,
  errorMessage,
  loading,
  onCancel,
  onForgotPassword,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.mobile);

  const {
    disableSubmit,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    properties,
    submitting,
    values,
  } = useForm({
    fields: {
      password: {
        default: '',
        required: true,
        type: 'password',
      },
    },
    onSubmit,
  });

  const sxInput = {
    marginBottom: theme.spacing.m2,
  };

  return (
    <Form onSubmit={handleSubmit}>
      <T
        align="center"
        fontWeight="bold"
        sx={{ mb: theme.spacing.s }}
        phoneVariant="headingS"
        variant="headingM"
      >
        {t('registration.login')}
      </T>
      <T align="center" sx={{ mb: theme.spacing.l }}>
        {t('registration.andYouWillBeReadyToLearn')}
      </T>
      {errorMessage && (
        <Alert
          message={errorMessage}
          severity="error"
          sx={{ mb: theme.spacing[isMobile ? 'm2' : 'l'] }}
        />
      )}
      <FormInput
        autoFocus
        disabled={submitting || disableInputs}
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        id="password"
        label={t('registration.password')}
        name="password"
        placeholder={t('registration.passwordPlaceholder')}
        properties={properties}
        sx={sxInput}
        values={values}
      />
      {onForgotPassword && (
        <Button
          disabled={submitting || loading}
          type="textWhite"
          onClick={onForgotPassword}
          sx={{ ml: `-${theme.spacing.s}` }}
        >
          {t('registration.haveYouForgottenPassword')}
        </Button>
      )}
      <StyledButtonWrapper>
        <Button
          disabled={submitting || loading}
          onClick={onCancel}
          type="secondaryOutline"
        >
          {t('generic.goBack')}
        </Button>
        <Button
          disabled={disableSubmit || loading}
          loading={submitting || loading}
          submit
        >
          {t('registration.login')}
        </Button>
      </StyledButtonWrapper>
    </Form>
  );
};

LoginPassword.propTypes = {
  disableInputs: PropTypes.bool,
  errorMessage: PropTypes.string,
  loading: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onForgotPassword: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

export default LoginPassword;

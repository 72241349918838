import React, { useState } from 'react';

import PropTypes from 'prop-types';

import FileUpload from '../../../molecules/FileUpload';

const FormImage = ({
  name,
  onCompleteUpload,
  onStartUpload,
  properties = {},
  ...props
}) => {
  const { disabled, required } = properties[name] || {};
  const [submitting, setSubmitting] = useState(false);

  const handleStartUpload = () => {
    setSubmitting(true);
    if (onStartUpload) {
      onStartUpload();
    }
  };

  const handleCompleteUpload = () => {
    setSubmitting(false);
    if (onCompleteUpload) {
      onCompleteUpload();
    }
  };

  return (
    <FileUpload
      disabled={disabled || submitting}
      isRequired={required}
      onStartUpload={handleStartUpload}
      onCompleteUpload={handleCompleteUpload}
      {...props}
    />
  );
};

FormImage.propTypes = {
  name: PropTypes.string.isRequired,
  onCompleteUpload: PropTypes.func,
  onStartUpload: PropTypes.func,
  properties: PropTypes.object,
};

export default FormImage;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Button from '@shared/atoms/Button';
import FormToggle from '@shared/atoms/FormFields/FormToggle';
import Input from '@shared/atoms/Input';
import LanguageSelect from '@shared/molecules/LanguageSelect';
import T from '@shared/atoms/Typography';
import theme from '@shared/themes/default';
import { useForm, logger } from '@shared/utils';
import { DOMAIN_LOCALES } from '@shared/const';

import { updateOrganization } from '../../graphql/mutations';
import { checkHideVcCourses } from '../../graphql/queries';
import { useGraphQLQuery } from '../../../../../utils';
import { useAppGlobals } from '../../../../contexts/AppContext';

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m1};
`;

const WrapText = styled.div`
  display: flex;
  gap: ${theme.spacing.m1};
  align-items: center;
`;

const LanguageWrapper = styled.div`
  align-items: center;
  display: flex;
  column-gap: ${theme.spacing.m1};
`;

const SettingsTab = ({
  item,
  onUpdateOrganization,
  setErrorMessage,
  setDetailedErrors,
  setHasError,
  setHasSuccess,
  setSuccessMessage,
}) => {
  const { t } = useTranslation();
  const { domain } = useAppGlobals();
  const languages = DOMAIN_LOCALES[domain];

  const [newOrganization, setNewOrganization] = useState({});
  const [blockHideVcCourses, setBlockHideVcCourses] = useState(false);
  const [mainLoading, setMainLoading] = useState(false);

  const { callQuery, data, errors, hasEnded, hasErrors } = useGraphQLQuery();
  const checkHideVcQuery = useGraphQLQuery();

  const buildDetailedErrors = (
    hasPlaylistsWithVcCourses,
    hasVcCoursesFeatured,
    hasFeaturedCourse
  ) => {
    const detailedErrors = [];
    if (hasPlaylistsWithVcCourses) {
      detailedErrors.push({
        title: t(
          'backoffice.organizations.settings.failedHideVcCoursesBanner.playlistsTitle'
        ),
        description: t(
          'backoffice.organizations.settings.failedHideVcCoursesBanner.playlistsDescription'
        ),
      });
    }
    if (hasVcCoursesFeatured) {
      detailedErrors.push({
        title: t(
          'backoffice.organizations.settings.failedHideVcCoursesBanner.vcCourseFeaturedTitle'
        ),
        description: t(
          'backoffice.organizations.settings.failedHideVcCoursesBanner.vcCourseFeaturedDescription'
        ),
      });
    }
    if (!hasFeaturedCourse) {
      detailedErrors.push({
        title: t(
          'backoffice.organizations.settings.failedHideVcCoursesBanner.featuredItemMissingTitle'
        ),
        description: t(
          'backoffice.organizations.settings.failedHideVcCoursesBanner.featuredItemMissingDescription'
        ),
      });
    }
    return detailedErrors;
  };

  useEffect(() => {
    if (hasEnded) {
      if (hasErrors) {
        logger.error('Error: ', errors);
        setErrorMessage(t('backoffice.organizations.settings.failedToUpdate'));
        setHasError(true);
      } else {
        const { updateOrganization } = data;

        onUpdateOrganization(updateOrganization);
        setSuccessMessage(
          t('backoffice.organizations.settings.successfullyUpdated')
        );
        setHasSuccess(true);
      }
      setMainLoading(false);
    }
  }, [hasEnded]);

  const {
    disableSubmit,
    handleChange,
    handleSubmit,
    properties,
    setDefaults,
    submitting,
    values,
  } = useForm({
    changesRequired: true,
    fields: {
      hideVcCourses: {
        default: false,
      },
      lockVcCourses: {
        default: false,
      },
      internalCoursesOnTop: {
        default: false,
      },
      hasCustomPlans: {
        default: false,
      },
      hidePriceList: {
        default: false,
      },
      languageSelect: {
        // default language is always the first element
        default: languages[0],
      },
    },
    onSubmitHook: (organization) => {
      setMainLoading(true);
      const { languageSelect, ...newOrganizationData } = organization;
      newOrganizationData.invitationLanguage = languageSelect;
      if (!item.hideVcCourses && newOrganizationData.hideVcCourses) {
        checkHideVcQuery.callQuery(checkHideVcCourses(item.id));
        setNewOrganization(newOrganizationData);
      } else {
        callQuery(updateOrganization({ id: item.id, ...newOrganizationData }));
      }
    },
    onSubmitHookEnded: !mainLoading,
  });

  const reset = () => {
    setDefaults({
      hideVcCourses: !!item.hideVcCourses,
      lockVcCourses: !!item.lockVcCourses,
      internalCoursesOnTop: !!item.internalCoursesOnTop,
      hasCustomPlans: !!item.hasCustomPlans,
      hidePriceList: !!item.hidePriceList,
      languageSelect: item?.invitationLanguage || languages[0],
    });
    setBlockHideVcCourses(false);
  };

  useEffect(() => {
    if (checkHideVcQuery.hasEnded) {
      if (checkHideVcQuery.hasErrors) {
        logger.error('Error: ', checkHideVcQuery.errors);
        setErrorMessage(t('backoffice.organizations.settings.failedToUpdate'));
        setHasError(true);
        reset();
        setMainLoading(false);
      } else {
        const {
          checkHideVcCourses: {
            allowed,
            hasPlaylistsWithVcCourses,
            hasVcCoursesFeatured,
            hasFeaturedCourse,
          },
        } = checkHideVcQuery.data;
        if (allowed) {
          callQuery(updateOrganization({ id: item.id, ...newOrganization }));
        } else {
          setErrorMessage(
            t(
              'backoffice.organizations.settings.failedHideVcCoursesBannerTitle'
            )
          );
          const detailedErrors = buildDetailedErrors(
            hasPlaylistsWithVcCourses,
            hasVcCoursesFeatured,
            hasFeaturedCourse
          );
          setDetailedErrors(detailedErrors);
          setHasError(true);
          reset();
          setBlockHideVcCourses(true);
          setMainLoading(false);
        }
      }
    }
  }, [checkHideVcQuery.hasEnded]);

  useEffect(() => {
    reset();
  }, [item]);

  return (
    <FormWrapper>
      <WrapText>
        <FormToggle
          id="hideVcCourses"
          label={t('backoffice.organizations.settings.hideVcCourses')}
          name="hideVcCourses"
          handleChange={handleChange}
          properties={properties}
          values={values}
          disabled={mainLoading || blockHideVcCourses}
        />
        {blockHideVcCourses && (
          <T color={theme.palette.errorDark} variant="paragraphS">
            {t(`backoffice.organizations.settings.failedHideVcCoursesInline`)}
          </T>
        )}
      </WrapText>
      <FormToggle
        id="lockVcCourses"
        label={t('backoffice.organizations.settings.lockVcCourses')}
        name="lockVcCourses"
        handleChange={handleChange}
        properties={properties}
        values={values}
      />
      <FormToggle
        id="internalCoursesOnTop"
        label={t('backoffice.organizations.settings.internalCoursesOnTop')}
        name="internalCoursesOnTop"
        handleChange={handleChange}
        properties={properties}
        values={values}
      />
      <FormToggle
        id="hasCustomPlans"
        label={t('backoffice.organizations.settings.hasCustomPlans')}
        name="hasCustomPlans"
        handleChange={handleChange}
        properties={properties}
        values={values}
      />
      <FormToggle
        id="hidePriceList"
        label={t('backoffice.organizations.settings.hidePriceList')}
        name="hidePriceList"
        handleChange={handleChange}
        properties={properties}
        values={values}
      />
      <LanguageWrapper>
        <LanguageSelect
          id="language"
          languages={languages}
          onChange={handleChange}
          theme="dark"
          value={values.languageSelect}
        />
        <T
          color={theme.palette.lightPure}
          fontWeight="medium"
          variant="paragraph"
        >
          {t('backoffice.organizations.settings.invitationLanguage')}
        </T>
      </LanguageWrapper>
      <T>{t('backoffice.organizations.settings.hubspotId')}</T>
      <Input
        id="hubspotId"
        name="hubspotId"
        properties={properties}
        theme="dark"
        disabled
        value={item.hubspotId || item.hubspotSyncedId || ''}
        sx={{ width: '10%' }}
      />
      <Button
        ariaLabel={t('generic.save')}
        disabled={disableSubmit}
        loading={submitting}
        loadingOnIcon={submitting}
        onClick={handleSubmit}
        sx={{ alignSelf: 'end' }}
        theme="dark"
      >
        {t('generic.save')}
      </Button>
    </FormWrapper>
  );
};

SettingsTab.propTypes = {
  item: PropTypes.object.isRequired,
  onUpdateOrganization: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  setDetailedErrors: PropTypes.func.isRequired,
  setHasError: PropTypes.func.isRequired,
  setHasSuccess: PropTypes.func.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
};

export default SettingsTab;

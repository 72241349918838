import styled from 'styled-components';
import theme from 'shared/themes/default';

export const StyledBlock = styled.div`
  max-width: 100%;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  gap: ${theme.spacing.m2};
`;

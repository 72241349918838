import React, { useState } from 'react';

import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';

import {
  Accordion as AccordionMui,
  AccordionSummary as AccordionSummaryMui,
  AccordionDetails as AccordionDetailsMui,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Icon from 'shared/atoms/Icon';
import theme from 'shared/themes/default';
import { BACKGROUND_THEMES } from 'shared/const';

import { ACCORDION_TYPE_ENUM } from './const';

const StyledSummary = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const AccordionStyle = createGlobalStyle`
  .MuiAccordion-root::before {
    display: none
  }
`;

const Accordion = ({
  details,
  disabled,
  focusBorderColor,
  hideArrow,
  selected,
  summary,
  sxSummary,
  sxDetails,
  sxAccordion,
  type,
  theme: themeLayout,
  defaultExpanded,
  expanded,
  setExpanded,
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  const [hover, setHover] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  /* border styling */
  let styleBorderAccordion;
  if (focused) {
    styleBorderAccordion = `2px solid ${focusBorderColor}`;
  } else if (selected) {
    styleBorderAccordion = `2px solid ${theme.palette.lightPure}`;
  } else {
    styleBorderAccordion = '2px solid transparent';
  }

  /* generic styling */
  const styleAccordion = {
    border: styleBorderAccordion,
    transition: 'border-color .2s linear',
    boxShadow: 'none',
    color: theme.palette.lightPure,
    fontFamily: theme.fonts.primary,
    fontSize: '1.25rem',
    fontWeight: 600,
    margin: 0,
    overflow: 'hidden',
    '&.Mui-expanded': {
      border: styleBorderAccordion,
    },
    '&.Mui-disabled': {
      color: theme.palette.lightPure,
    },
  };

  const styleSummary = {
    minHeight: 'unset',
    padding: '0',
    '&.Mui-expanded': {
      minHeight: 'unset',
    },
    '&:hover': {
      color: theme.palette.brand,
      '>.MuiAccordionSummary-expandIconWrapper >span': {
        color: theme.palette.brand,
      },
    },
    '.MuiAccordionSummary-content': {
      margin: '0',
      overflow: 'hidden',
      '&.Mui-expanded': {
        margin: '0',
      },
    },
    '.MuiAccordionSummary-expandIconWrapper': {
      marginRight: theme.spacing.s,
    },
  };

  const styleDetails = {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.375rem',
    padding: '0',
  };

  if (themeLayout === 'light') {
    styleAccordion.background = theme.palette.lightInteracting;
    styleAccordion['&.Mui-disabled'].background =
      theme.palette.lightInteracting;
    styleAccordion['&:hover'] = { background: theme.palette.lightDistinct };

    styleAccordion.color = theme.palette.mainDark;
    styleAccordion['&:hover'].color = theme.palette.mainDark;
    styleAccordion['&.Mui-disabled'].color = theme.palette.disabledDark;

    styleAccordion.fontWeight = 400;
    styleAccordion['&:hover'].fontWeight = 600;

    styleSummary.background = theme.palette.lightInteracting;
    styleSummary['&.Mui-expanded'].background = theme.palette.lightDistinct;
    styleSummary['&:focus'] = { background: theme.palette.lightDistinct };
    styleSummary['&:hover'] = { background: theme.palette.lightDistinct };

    styleSummary['.MuiAccordionSummary-expandIconWrapper'].marginRight =
      theme.spacing.m2;
    styleSummary['>.MuiAccordionSummary-expandIconWrapper >span'] = {
      color: theme.palette.mainDark,
    };

    styleAccordion.borderRadius = '6px !important';
    styleSummary.borderRadius = '6px';
  } else {
    /* specific type styling */
    switch (type) {
      case 'playerList':
        styleAccordion.background = theme.palette.darkWrapper;
        styleAccordion['&.Mui-expanded'].background = details
          ? theme.palette.mainDark
          : theme.palette.darkWrapper;
        styleAccordion['&.Mui-disabled'].background = theme.palette.darkWrapper;
        styleSummary['&:hover'].background = theme.palette.darkWrapper;

        styleAccordion.borderRadius = 'unset !important';
        styleAccordion['&.Mui-expanded'].borderRadius = 'unset';
        styleSummary.borderRadius = 'unset';
        break;
      case 'certificateCard':
        styleAccordion.background = theme.palette.mainDark;
        styleAccordion['&.Mui-expanded'].background = theme.palette.mainDark;
        styleSummary.background = theme.palette.mainDark;
        styleSummary['&:focus'] = { background: theme.palette.mainDark };
        styleSummary['&:hover'].background = theme.palette.darkWrapper;

        styleAccordion.borderRadius = '6px !important';
        styleAccordion['&.Mui-expanded'].borderRadius = focused
          ? '6px'
          : '6px 6px 0 0';
        styleSummary.borderRadius = '6px';

        // eslint-disable-next-line no-case-declarations
        const borderStyle = `1px solid ${theme.palette.brand}`;
        styleAccordion.border = borderStyle;
        styleAccordion['&.Mui-expanded'].border = borderStyle;
        if (focused) {
          styleAccordion.border = borderStyle;
        }
        break;
      default:
        styleAccordion.background = theme.palette.darkWrapper;
        styleAccordion['&.Mui-expanded'].background = focused
          ? theme.palette.darkDistinct
          : theme.palette.oddDark;
        styleAccordion['&.Mui-disabled'].background = theme.palette.darkWrapper;
        styleSummary.background = theme.palette.darkWrapper;
        styleSummary['&:focus'] = { background: theme.palette.darkDistinct };
        styleSummary['&:hover'].background = theme.palette.darkDistinct;

        styleAccordion.borderRadius = '6px !important';
        styleAccordion['&.Mui-expanded'].borderRadius = focused
          ? '6px'
          : '6px 6px 0 0';
        styleSummary.borderRadius = '6px';
        break;
    }
  }

  let iconColor;
  if (themeLayout === 'light') {
    iconColor = theme.palette.mainDark;
  } else if (hover) {
    iconColor = theme.palette.brand;
  } else {
    iconColor = theme.palette.lightPure;
  }

  return (
    <>
      <AccordionStyle />
      <AccordionMui
        sx={{ ...styleAccordion, ...sxAccordion }}
        disabled={disabled}
        disableGutters
        defaultExpanded={defaultExpanded}
        expanded={expanded}
        onChange={(_, expanded) => {
          if (setExpanded) setExpanded(expanded);
        }}
        {...props}
      >
        <AccordionSummaryMui
          sx={{ ...styleSummary, ...sxSummary }}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onFocus={onFocus}
          onBlur={onBlur}
          expandIcon={
            !hideArrow && details ? (
              <Icon color={iconColor} icon={ExpandMoreIcon} />
            ) : (
              ''
            )
          }
        >
          <StyledSummary>{summary}</StyledSummary>
        </AccordionSummaryMui>
        {details && (
          <AccordionDetailsMui sx={{ ...styleDetails, ...sxDetails }}>
            {details}
          </AccordionDetailsMui>
        )}
      </AccordionMui>
    </>
  );
};

Accordion.propTypes = {
  defaultExpanded: PropTypes.bool,
  expanded: PropTypes.bool,
  setExpanded: PropTypes.func,
  details: PropTypes.object,
  disabled: PropTypes.bool,
  focusBorderColor: PropTypes.oneOf(Object.values(theme.palette)),
  hideArrow: PropTypes.bool,
  selected: PropTypes.bool,
  summary: PropTypes.object.isRequired,
  sxAccordion: PropTypes.object,
  sxSummary: PropTypes.object,
  sxDetails: PropTypes.object,
  type: PropTypes.oneOf(ACCORDION_TYPE_ENUM),
  theme: PropTypes.oneOf(BACKGROUND_THEMES),
};

Accordion.defaultProps = {
  defaultExpanded: false,
  disabled: false,
  focusBorderColor: theme.palette.focus,
  selected: false,
  sxAccordion: {},
  sxSummary: {},
  sxDetails: {},
  type: 'regular',
  theme: 'dark',
};

export default Accordion;

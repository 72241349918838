import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Input, ListItemText, MenuItem, Select } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Button from 'shared/atoms/Button';

import theme from 'shared/themes/default';

const Wrapper = styled.div`
  display: flex;
  gap: ${theme.spacing.m1};
`;

const StyledMenuItem = styled(MenuItem)`
  padding-top: 0;
  padding-bottom: 0;
`;

const StyledListItemText = styled(ListItemText)`
  font-weight: 600;
`;

const menuProps = {
  anchorOrigin: {
    vertical: 34,
    horizontal: 90,
  },
  PaperProps: {
    style: {
      borderRadius: '10px',
      width: '210px',
      marginTop: '0.3rem',
    },
  },
};

export const useStyles = makeStyles(() => ({
  inputRoot: {
    fontFamily: theme.fonts.primary,
    backgroundColor: (props) =>
      props.disabled ? theme.palette.disabledLight : theme.palette.lightPure,
    borderRadius: '20px',
    paddingLeft: '15px',
    height: (props) => (props.isPhone ? 'auto' : '40px'),
    '&:hover': {
      backgroundColor: (props) =>
        props.disabled ? theme.palette.disabledLight : theme.palette.brand,
      '&.MuiSelect-select': {
        backgroundColor: `${theme.palette.brand}, !important`,
      },
    },
    width: '210px',
  },
  select: {
    borderRadius: '16px',
    fontWeight: 600,
    maxWidth: '75%',
  },
  selectIcon: {
    marginRight: '10px',
  },
}));

const ActionDropdown = ({
  options,
  selectedValue,
  onChange,
  titleText,
  applyButtonText,
  onApplyHandler,
  disabled,
  showApplyButton,
}) => {
  const isPhone = useMediaQuery(theme.breakpoints.mobile);
  const classes = useStyles({ disabled, isPhone });

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    onChange(value);
  };

  const getSelectedLabel = () => {
    const selected = options.find((item) => item.value === selectedValue);
    return selected && selected.label ? selected.label : titleText;
  };

  return (
    <Wrapper>
      <Select
        id="action-dropdown"
        autoWidth
        displayEmpty
        classes={{ select: classes.select, icon: classes.selectIcon }}
        MenuProps={menuProps}
        input={<Input className={classes.inputRoot} disableUnderline />}
        value={selectedValue || ''}
        onChange={handleChange}
        renderValue={getSelectedLabel}
        disabled={disabled}
      >
        {options.map((option) => (
          <StyledMenuItem
            key={option.value}
            value={option.value}
            disableRipple
            dense
          >
            <StyledListItemText primary={option.label} disableTypography />
          </StyledMenuItem>
        ))}
      </Select>
      {!disabled && showApplyButton && (
        <Button ariaLabel={applyButtonText} onClick={onApplyHandler}>
          {applyButtonText}
        </Button>
      )}
    </Wrapper>
  );
};

ActionDropdown.propTypes = {
  onApplyHandler: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  selectedValue: PropTypes.string,
  titleText: PropTypes.string,
  applyButtonText: PropTypes.string,
  disabled: PropTypes.bool,
  showApplyButton: PropTypes.bool,
};

ActionDropdown.defaultProps = {
  options: [],
  selectedValue: '',
  titleText: 'Action',
  applyButtonText: 'Apply',
  disabled: false,
  showApplyButton: false,
};

export default ActionDropdown;

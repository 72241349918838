import React from 'react';

import PropTypes from 'prop-types';

import MuiDialog from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import theme from 'shared/themes/default';

import { BACKGROUND_THEMES } from 'shared/const';
import CircularSpinner from 'shared/atoms/CircularSpinner';
import Button from 'shared/atoms/Button';
import { BUTTON_TYPE_ENUM } from 'shared/atoms/Button/const';
import Icon from 'shared/atoms/Icon';
import IconButton from 'shared/atoms/IconButton';
import T from 'shared/atoms/Typography';

import {
  useStyles,
  ConfirmationTitle,
  ConfirmationTitleWrapper,
  ContentBlock,
  Spacer,
  NotificationBlock,
  NotificationWrapper,
} from './styles';

const Dialog = ({
  actions,
  ariaLabel,
  formStatus,
  children,
  confirmationTitle,
  contentPadding,
  errorMessage,
  loading,
  onClose,
  onCloseNotificationMessage,
  open,
  scrollType,
  title,
  topHeaderNode,
  notificationMessage,
  maxWidth,
  ...props
}) => {
  const classes = useStyles();
  const hideChildren =
    !children ||
    (Array.isArray(children) && children.filter(Boolean).length === 0);
  const hasActions = actions && actions.length > 0;
  const showActions = hasActions && !formStatus;
  const isMobile = useMediaQuery(theme.breakpoints.mobile);

  return (
    <MuiDialog
      classes={{ root: !isMobile ? classes.root : classes.mobileRoot }}
      maxWidth={maxWidth}
      open={open}
      fullWidth
      onClose={!onClose ? () => {} : onClose}
      aria-labelledby={ariaLabel}
      scroll={scrollType}
      {...props}
    >
      {topHeaderNode}
      {errorMessage && (
        <NotificationWrapper>
          <NotificationBlock>
            <Icon icon={ErrorOutlineIcon} size="35px" />
            <T
              color={theme.palette.pureDark}
              fontWeight="semibold"
              ml={theme.spacing.m1}
              mr={theme.spacing.m3}
            >
              {errorMessage}
            </T>
            <IconButton
              ariaLabel="close-error-message"
              disabled={loading}
              icon={CloseIcon}
              onClick={onCloseNotificationMessage}
              sx={{
                position: 'absolute',
                top: theme.spacing.s,
                right: theme.spacing.s,
              }}
              theme="light"
              type="transparent"
            />
          </NotificationBlock>
        </NotificationWrapper>
      )}
      {notificationMessage && (
        <NotificationWrapper>
          <NotificationBlock notification>
            <Icon icon={CheckCircleOutlineIcon} size="35px" />
            <T
              color={theme.palette.pureDark}
              fontWeight="semibold"
              ml={theme.spacing.m1}
              mr={theme.spacing.m3}
            >
              {notificationMessage}
            </T>
            <IconButton
              ariaLabel="close-notification-message"
              icon={CloseIcon}
              onClick={onCloseNotificationMessage}
              sx={{
                position: 'absolute',
                top: '0.5rem',
                right: '0.5rem',
              }}
              theme="light"
              type="transparent"
            />
          </NotificationBlock>
        </NotificationWrapper>
      )}
      {!!onClose && (
        <IconButton
          ariaLabel="close-dialog"
          disabled={loading}
          icon={CloseIcon}
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: '0.5rem',
            right: '0.5rem',
          }}
          theme="light"
          type="transparent"
        />
      )}
      <MuiDialogTitle classes={{ root: classes.titleRoot }} id={ariaLabel}>
        {title}
      </MuiDialogTitle>
      <MuiDialogContent classes={{ root: classes.contentRoot }}>
        {confirmationTitle && (
          <ConfirmationTitleWrapper>
            <ConfirmationTitle
              color={theme.palette.pureDark}
              variant="subtitle"
            >
              {confirmationTitle}
            </ConfirmationTitle>
          </ConfirmationTitleWrapper>
        )}
        {!hideChildren && (
          <ContentBlock contentPadding={contentPadding}>
            {children}
          </ContentBlock>
        )}
        {loading && <CircularSpinner thickness={4} />}
      </MuiDialogContent>
      {showActions ? (
        <MuiDialogActions
          classes={{
            root: isMobile ? classes.actionsRoot : classes.actionsOnRight,
          }}
        >
          <>
            {actions.map(
              ({
                disabled,
                handler,
                label,
                theme = 'light',
                type,
                ...buttonProps
              }) => (
                <Button
                  ariaLabel={`${label}-button`}
                  disabled={disabled || loading}
                  key={`${label}-btn`}
                  onClick={handler}
                  sx={{
                    width: isMobile ? '100%' : 'auto',
                  }}
                  theme={theme}
                  type={type}
                  {...buttonProps}
                >
                  {label}
                </Button>
              )
            )}
          </>
        </MuiDialogActions>
      ) : (
        <Spacer />
      )}
    </MuiDialog>
  );
};

Dialog.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      handler: PropTypes.func.isRequired,
      label: PropTypes.string.isRequired,
      style: PropTypes.oneOf(BUTTON_TYPE_ENUM),
      theme: PropTypes.oneOf(BACKGROUND_THEMES),
      type: PropTypes.string,
    })
  ),
  formStatus: PropTypes.object,
  ariaLabel: PropTypes.string.isRequired,
  children: PropTypes.node,
  confirmationTitle: PropTypes.string,
  contentPadding: PropTypes.string,
  errorMessage: PropTypes.string,
  loading: PropTypes.bool,
  open: PropTypes.bool,
  onCloseNotificationMessage: PropTypes.func,
  onClose: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onCloseErrorMessage: PropTypes.func,
  scrollType: PropTypes.oneOf(['paper', 'body']),
  title: PropTypes.string,
  topHeaderNode: PropTypes.object,
  notificationMessage: PropTypes.string,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
};

Dialog.defaultProps = {
  actions: [],
  children: null,
  confirmationTitle: '',
  contentPadding: null,
  errorMessage: null,
  loading: false,
  onCloseNotificationMessage: () => {},
  open: true,
  scrollType: 'paper',
  title: '',
  maxWidth: 'xs',
};

export default Dialog;

export const sendPlaylistReminder = (userId, playlistId) => ({
  query: `mutation sendPlaylistReminder(
    $userId: ID,
    $playlistId: ID!
  ) {
    sendPlaylistReminder(userId: $userId, playlistId: $playlistId) {
      success
      message
    }
  }`,
  variables: {
    userId,
    playlistId,
  },
});

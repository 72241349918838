import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import T from 'shared/atoms/Typography';
import TableWithSearchAndExport from 'shared/organisms/WatchData/TableWithSearchAndExport';
import UsersWatchDataTable from 'shared/organisms/WatchData/UsersWatchDataTable';
import EpisodesSeenGraph from 'shared/organisms/WatchData/EpisodesSeenGraph';
import { TYPE_WATCH_DATA } from 'shared/const';
import Pill from 'shared/atoms/Pill';
import theme from 'shared/themes/default';
import { mapAggregatedWatchDataGraph, callApi } from '../utils';
import { useWatchData } from '../Context';

const Wrapper = styled.div`
  max-width: 100%;
`;

const WrapperPill = styled.div`
  display: flex;
  margin-top: 108px;
  ${theme.breakpoints.mobile} {
    margin-top: 64px;
  }
`;

const ShowUsersData = ({ isBackOffice }) => {
  const [aggregatedWatchData, setAggregatedWatchData] = useState([]);
  const [loadingAggregated, setLoadingAggregated] = useState(false);

  const { t, i18n } = useTranslation();
  const { language: locale } = i18n;
  const {
    downloadWatchData,
    fetchUsersWatchData,
    listAggregatedWatchData,
    loadingUsersWatchData,
    organizationSelectedId,
    setWatchDataError,
    user,
    usersWatchData,
  } = useWatchData();

  useEffect(() => {
    fetchUsersWatchData();
  }, [organizationSelectedId]);

  useEffect(() => {
    if (organizationSelectedId !== 'global') {
      callApi({
        setLoading: setLoadingAggregated,
        setData: setAggregatedWatchData,
        apiMethod: listAggregatedWatchData,
        setError: setWatchDataError,
        type: TYPE_WATCH_DATA.USERS,
        params: { organizationId: organizationSelectedId },
      });
    }
  }, [organizationSelectedId]);

  return (
    <Wrapper>
      <WrapperPill>
        <Pill
          color={theme.palette.lightPure}
          fontColor={theme.palette.mainDark}
        >
          {t('watchData.titleUsers').toUpperCase()}
        </Pill>
      </WrapperPill>
      <T
        color={theme.palette.brand}
        fontWeight="medium"
        phoneVariant="headingXs"
        sx={{
          marginBottom: theme.spacing.m3,
          marginTop: theme.spacing.m2,
        }}
        variant="headingS"
      >
        {isBackOffice
          ? t('watchData.titleAllUsersOrganization')
          : t('watchData.titleAllUsers')}
      </T>
      <EpisodesSeenGraph
        data={mapAggregatedWatchDataGraph(aggregatedWatchData, locale)}
        loading={loadingAggregated}
      />
      <TableWithSearchAndExport
        itemsToDisplay={usersWatchData || []}
        downloadItems={() =>
          downloadWatchData({ watchData: TYPE_WATCH_DATA.USERS })
        }
        user={user}
        tableTitle={t('watchData.activityOfYourUsers')}
        searchPlaceHolder={t('watchData.searchForUsers')}
        TableToDisplay={UsersWatchDataTable}
        showRowsAreClickableMessage
        loading={loadingUsersWatchData}
        spinnerDuration={150}
        emptyMessage={t('watchData.noActivity')}
      />
    </Wrapper>
  );
};

ShowUsersData.propTypes = {
  isBackOffice: PropTypes.bool,
};

export default ShowUsersData;

import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@shared/themes/default';

const SectionStyled = styled.section`
  ${({ isEmpty }) =>
    !isEmpty &&
    `
    background: ${theme.palette.darkDistinct};
  `}
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.s};
  row-gap: ${theme.spacing.s};
`;
const Content = styled.div`
  ${({ height }) =>
    height
      ? `
    height: ${height};
  `
      : ''}
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 10px;
    height: 30px;
  }
  ::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    background-color: ${theme.palette.disabledLight};
    border-radius: 10px;
  }
`;

const Box = ({ children, height, header }) => (
  <SectionStyled isEmpty={!children}>
    {header}
    {children && <Content height={height}>{children}</Content>}
  </SectionStyled>
);

Box.propTypes = {
  children: PropTypes.object,
  height: PropTypes.string,
  header: PropTypes.node,
};

Box.defaultProps = {
  header: null,
};

export default Box;

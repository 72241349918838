import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

import Icon from 'shared/atoms/Icon';
import IconButton from 'shared/atoms/IconButton';
import Input from 'shared/atoms/Input';
import CircularSpinner from 'shared/atoms/CircularSpinner';

import { BACKGROUND_THEMES } from 'shared/const';
import theme from 'shared/themes/default';

import { INPUT_TYPE_ENUM } from 'shared/atoms/Input/const';

const SpinnerWrapper = styled.div`
  padding: ${theme.spacing.xs};
`;

const SearchField = ({
  disabled,
  iconActions,
  loading,
  onChange,
  onClear,
  theme: themeLayout,
  type,
  value,
  ...props
}) => {
  const { t } = useTranslation();

  const onTextInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    onChange({ name, value });
  };

  const getIcon = (iconActions, loading) => {
    if (loading) {
      return (
        <SpinnerWrapper>
          <CircularSpinner color="inherit" size={16} static thickness={5} />
        </SpinnerWrapper>
      );
    }

    return iconActions ? (
      <IconButton
        ariaLabel={t('generic.search')}
        icon={SearchIcon}
        small
        sx={{
          transform: 'rotate(90deg)',
        }}
        theme={type === 'primary' || themeLayout === 'light' ? 'light' : 'dark'}
        type="transparent"
        {...iconActions}
      />
    ) : (
      <Icon
        icon={SearchIcon}
        sx={{
          transform: 'rotate(90deg)',
        }}
      />
    );
  };

  const showClearButton = !disabled && onClear && value;

  return (
    <Input
      disabled={disabled}
      iconLeft={getIcon(iconActions, loading)}
      iconRight={
        showClearButton ? (
          <IconButton
            ariaLabel={t('generic.close')}
            icon={CloseIcon}
            onClick={onClear}
            small
            theme={
              type === 'primary' || themeLayout === 'light' ? 'light' : 'dark'
            }
            type="transparent"
          />
        ) : null
      }
      onChange={onTextInputChange}
      theme={themeLayout}
      type={type}
      value={value}
      {...props}
    />
  );
};

SearchField.propTypes = {
  disabled: PropTypes.bool,
  iconActions: PropTypes.object,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  theme: PropTypes.oneOf(BACKGROUND_THEMES),
  type: PropTypes.oneOf(INPUT_TYPE_ENUM),
  value: PropTypes.string,
};

SearchField.defaultProps = {
  disabled: false,
  loading: false,
  onChange: () => {},
  theme: 'dark',
  type: 'primary',
};

export default SearchField;

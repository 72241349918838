import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MuiSelect from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import MuiMenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import theme from 'shared/themes/default';
import { BACKGROUND_THEMES } from 'shared/const';

import {
  InputStyle,
  ButtonStyle,
  InputMenuStyle,
  InputItemStyle,
  ActionMenuStyle,
  ActionItemStyle,
} from './styles';

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.m1};
  height: 100%;
`;

const CustomSelect = ({
  fullWidth,
  hasAction,
  hasBackground,
  iconOnly,
  id,
  items,
  name,
  onChange,
  required,
  sx: sxProp,
  theme: themeLayout,
  value,
  ...props
}) => {
  const handleChange = (event) => {
    onChange(event.target);
  };

  const textColor =
    themeLayout === 'dark' ? theme.palette.lightPure : theme.palette.mainDark;
  const disabledColor =
    themeLayout === 'dark'
      ? theme.palette.disabledDark
      : theme.palette.disabledLight;
  let colorIcon = hasBackground ? theme.palette.mainDark : textColor;
  colorIcon = hasAction ? colorIcon : theme.palette.darkDistinct;
  colorIcon = props.disabled ? disabledColor : colorIcon;

  const sx = {
    width: fullWidth ? '100%' : undefined,
    '& .MuiSelect-icon': {
      color: colorIcon,
    },
    ...sxProp,
  };

  const sxInputStyle = hasAction
    ? ButtonStyle(hasBackground, themeLayout)
    : InputStyle(themeLayout);
  const sxMenuList = hasAction ? ActionMenuStyle : InputMenuStyle;
  const sxMenuItem = hasAction ? ActionItemStyle : InputItemStyle;

  return (
    <MuiSelect
      id={id}
      name={name}
      value={value}
      onChange={handleChange}
      input={<InputBase />}
      SelectDisplayProps={{
        sx: sxInputStyle,
      }}
      IconComponent={ExpandMoreIcon}
      sx={sx}
      MenuProps={{
        sx: sxMenuList,
      }}
      renderValue={
        iconOnly
          ? (selectedValue) => {
              const selectedItem = items.find(
                (item) => item.value === selectedValue
              );
              return <ItemWrapper>{selectedItem.icon}</ItemWrapper>;
            }
          : undefined
      }
      required={required}
      {...props}
    >
      {items.map(({ key, value, label, icon }) => (
        <MuiMenuItem key={key} value={value} sx={sxMenuItem}>
          {icon ? (
            <ItemWrapper>
              {icon}
              {label}
            </ItemWrapper>
          ) : (
            label
          )}
        </MuiMenuItem>
      ))}
    </MuiSelect>
  );
};

CustomSelect.propTypes = {
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  hasAction: PropTypes.bool,
  hasBackground: PropTypes.bool,
  iconOnly: PropTypes.bool,
  id: PropTypes.string.isRequired,
  items: PropTypes.array,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  sx: PropTypes.object,
  theme: PropTypes.oneOf(BACKGROUND_THEMES),
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
};

CustomSelect.defaultProps = {
  fullWidth: true,
  sx: {},
};

export default CustomSelect;

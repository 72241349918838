import React, { useEffect } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';

import Fade from '@shared/atoms/Fade';
import i18n from '@shared/config/i18Next';
import theme from '@shared/themes/default';
import defaultMuiTheme from '@shared/themes/vcMuiTheme';

import AuthenticationPage from './pages/AuthenticationPage';
import InitialLoading from './pages/InitialLoading';
import PageNotFound from './pages/PageNotFound';
import ProtectedRoute from './molecules/ProtectedRoute';

import CategoriesContainer from '../containers/CategoriesContainer';
import CourseContainer from '../containers/CourseContainer';
import CoursesContainer from '../containers/CoursesContainer';
import DomainsContainer from '../containers/DomainsContainer';
import ExpertsContainer from '../containers/ExpertsContainer';
import MembersContainer from '../containers/MembersContainer';
import OrganizationsContainer from '../containers/OrganizationsContainer';
import PlaylistContainer from '../containers/PlaylistContainer';
import ReportsContainer from '../containers/ReportsContainer';
import UsersContainer from '../containers/UsersContainer';

import { useAppGlobals } from './contexts/AppContext';

const DivWrapper = styled.div`
  height: 100%;
`;

const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 16px;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    margin: 0;
    font-size: 1em;
  }
  html, body {
    height: 100%;
    background-color: ${theme.palette.mainDark};
  }
  #root {
    height:100%;
  }
`;

const App = () => {
  const location = useLocation();
  const { loadingUser, loadingSettings } = useAppGlobals();

  useEffect(() => {
    // Hardcoded to English as backoffice is always in English
    i18n.changeLanguage('en');
    document.documentElement.lang = 'en';
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const showLoadingScreen = loadingUser || loadingSettings;

  return (
    <>
      <Fade show={showLoadingScreen}>
        <InitialLoading />
      </Fade>
      <Fade show={!showLoadingScreen}>
        <DivWrapper>
          <GlobalStyle />
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={defaultMuiTheme}>
              <Routes>
                <Route path="/login" element={<AuthenticationPage />} />
                <Route
                  path="/"
                  element={
                    <ProtectedRoute>
                      <Navigate to="/courses" />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/users"
                  element={
                    <ProtectedRoute>
                      <UsersContainer />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/organizations"
                  element={
                    <ProtectedRoute>
                      <OrganizationsContainer />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/activity"
                  element={
                    <ProtectedRoute>
                      <ReportsContainer />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/courses"
                  element={
                    <ProtectedRoute>
                      <CoursesContainer />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/courses/:id"
                  element={
                    <ProtectedRoute>
                      <CourseContainer />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/experts"
                  element={
                    <ProtectedRoute>
                      <ExpertsContainer />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/playlists"
                  element={
                    <ProtectedRoute>
                      <PlaylistContainer />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/members"
                  element={
                    <ProtectedRoute>
                      <MembersContainer />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/domains"
                  element={
                    <ProtectedRoute>
                      <DomainsContainer />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/categories"
                  element={
                    <ProtectedRoute>
                      <CategoriesContainer />
                    </ProtectedRoute>
                  }
                />
                <Route path="/404" element={<PageNotFound />} />
                <Route path="*" element={<Navigate to="/404" />} />
              </Routes>
            </MuiThemeProvider>
          </StyledEngineProvider>
        </DivWrapper>
      </Fade>
    </>
  );
};

export default App;

import React, { forwardRef } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import SvgIcon from '@mui/material/SvgIcon';

import theme from 'shared/themes/default';

const IconWrapper = styled.div`
  display: inline-block;
  line-height: 0;
`;

const Icon = forwardRef(
  (
    {
      color,
      colorHover,
      icon: Icon,
      size,
      svgContent: SvgContent,
      svgStrokeWidth,
      sx: sxProps,
      viewBox,
      ...props
    },
    ref
  ) => {
    const sx = {
      color,
      fontSize: size,
      '&:hover': {
        color: colorHover,
      },
    };

    return (
      <IconWrapper ref={ref} {...props}>
        {Icon ? (
          <Icon sx={{ ...sxProps, ...sx }} />
        ) : (
          <SvgIcon sx={{ ...sxProps, ...sx }} viewBox={viewBox}>
            <SvgContent stroke={color} strokeWidth={svgStrokeWidth} />
          </SvgIcon>
        )}
      </IconWrapper>
    );
  }
);

Icon.propTypes = {
  color: PropTypes.oneOf(Object.values(theme.palette)),
  colorHover: PropTypes.oneOf(Object.values(theme.palette)),
  icon: (props, propName, componentName) => {
    if (!props[propName] && !props.svgContent) {
      return new Error(
        `The prop \`${propName}\` or the prop \`svgContent\` are marked as required in \`${componentName}\`, but their value is \`undefined\`.`
      );
    }
    if (props[propName] && typeof props[propName] !== 'object') {
      return new Error(
        `Invalid prop \`${propName}\` of type \`${typeof props[
          propName
        ]}\` supplied to \`${componentName}\`, expected \`object\`.`
      );
    }
    return null;
  },
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  svgContent: (props, propName, componentName) => {
    if (!props.icon && !props[propName]) {
      return new Error(
        `The prop \`icon\` or the prop \`${propName}\` are marked as required in \`${componentName}\`, but their value is \`undefined\`.`
      );
    }
    if (props[propName] && typeof props[propName] !== 'function') {
      return new Error(
        `Invalid prop \`${propName}\` of type \`${typeof props[
          propName
        ]}\` supplied to \`${componentName}\`, expected \`function\`.`
      );
    }
    return null;
  },
  svgStrokeWidth: PropTypes.number,
  sx: PropTypes.object,
  viewBox: PropTypes.string,
};

Icon.defaultProps = {
  svgStrokeWidth: 0,
  sx: {},
};

export default Icon;

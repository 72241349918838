import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { graphqlOperation } from '@aws-amplify/api';
import { useTranslation } from 'react-i18next';

import Button from '@shared/atoms/Button';
import Toggle from '@shared/atoms/Toggle';
import Snackbar from '@shared/atoms/Snackbar';
import Tooltip from '@shared/atoms/Tooltip';
import T from '@shared/atoms/Typography';
import AutocompleteWithList from '@shared/molecules/AutocompleteWithList';

import {
  courseType,
  AUTOCOMPLETEWITHLIST_TYPE,
  courseStatus,
} from '@shared/const';
import { logger } from '@shared/utils';
import theme from '@shared/themes/default';

import ValidationList from '../../components/ValidationList';

import { useAppGlobals } from '../../../../contexts/AppContext';
import { useCourse } from '../../../../contexts/CourseContext';

import { graphql } from '../../../../../utils';
import { listOrganizations } from './queries';

const ContentWrapper = styled.div`
  background: ${theme.palette.darkDistinct};
  padding: ${theme.spacing.s} ${theme.spacing.s} ${theme.spacing.xl};
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${theme.spacing.s};
`;

const ToggleWrapper = styled.div`
  display: flex;
  gap: ${theme.spacing.s};
  margin: 0 ${theme.spacing.s} ${theme.spacing.s};
`;

const OrganizationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.s};
  margin-top: ${theme.spacing.m2};
  width: 418px;
`;

const Availability = () => {
  const [everyone, setEveryone] = useState(true);
  const [paid, setPaid] = useState(true);
  const [vcLogo, setVcLogo] = useState(true);
  const [type, setType] = useState();
  const [selectedOrganization, setSelectedOrganization] = useState();
  const [organizations, setOrganizations] = useState([]);
  const [loadingOrganizations, setLoadingOrganizations] = useState(false);
  const [organizationsError, setOrganizationsError] = useState(false);

  const { t } = useTranslation();
  const {
    canPublish,
    course,
    hasChanged,
    hasError,
    locked,
    saveAvailability,
    setHandleSave,
    setHasChanged,
    setHasError,
    setSubmitting,
    submitting,
    validationDetails,
  } = useCourse();

  const { domain } = useAppGlobals();

  const handleSave = async () => {
    try {
      await saveAvailability({
        id: course.id,
        type,
        ownerOrganizationId: everyone ? null : selectedOrganization?.key,
      });
      setHasChanged(false);
    } catch (e) {
      logger.error(e);
      setHasError(true);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (course) {
      const organizationId = everyone ? undefined : selectedOrganization?.key;
      setHasChanged(
        type !== course.type || course.ownerOrganization?.id !== organizationId
      );
    }
    setHandleSave(() => handleSave);
  }, [course, everyone, selectedOrganization, type]);

  const fetchOrganizations = async (nextToken) => {
    const handleError = (error) => {
      logger.error('Error calling listOrganizations', error);
      setLoadingOrganizations(false);
      setOrganizationsError(true);
    };
    try {
      setLoadingOrganizations(true);
      const { data, error } = await graphql(
        graphqlOperation(listOrganizations, { domain, nextToken })
      );
      if (error) throw new Error(error);
      const {
        listOrganizations: { items, nextToken: newNextToken },
      } = data;
      const updatedOrganizationsList = [...organizations, ...items];
      setOrganizations(updatedOrganizationsList);
      if (newNextToken) {
        fetchOrganizations(newNextToken);
      } else {
        if (course && course.ownerOrganization) {
          const ownerOrganization = updatedOrganizationsList.find(
            (organization) => organization.id === course.ownerOrganization?.id
          );
          if (ownerOrganization) {
            setSelectedOrganization({
              key: ownerOrganization.id,
              title: ownerOrganization.name,
            });
          }
        }
        setLoadingOrganizations(false);
      }
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    if (course) {
      setEveryone(!course.ownerOrganization);
      setPaid(course.type !== courseType.FREE);
      setVcLogo(course.type !== courseType.SELFPUBLISH);
    }
  }, [course]);

  useEffect(() => {
    if (!everyone) {
      setPaid(true);
    }
    if (course && !everyone && !organizations.length) {
      fetchOrganizations();
    }
  }, [everyone]);

  useEffect(() => {
    if (everyone) {
      if (paid) {
        setType(courseType.PREMIUM);
      } else {
        setType(courseType.FREE);
      }
    } else if (vcLogo) {
      setType(courseType.PREMIUM);
    } else {
      setType(courseType.SELFPUBLISH);
    }
  }, [everyone, paid, vcLogo]);

  const organizationsToShow = organizations.map((org) => ({
    key: org.id,
    title: org.name,
  }));

  const disableSave =
    locked ||
    !hasChanged ||
    hasError ||
    (!selectedOrganization && !everyone) ||
    (course?.status === courseStatus.PUBLISHED && !canPublish);

  return (
    <>
      <ContentWrapper>
        <HeaderWrapper>
          <T fontWeight="semibold">{`${t(
            'backoffice.courses.availabilityTab.title'
          )}:`}</T>
          <Tooltip
            arrow
            title={
              course.status === courseStatus.PUBLISHED &&
              disableSave &&
              !submitting &&
              !locked &&
              Object.keys(validationDetails)?.length ? (
                <>
                  <T color={theme.palette.mainDark}>
                    {t('backoffice.courses.publishing.save')}
                  </T>
                  <ValidationList details={validationDetails} />
                </>
              ) : (
                ''
              )
            }
          >
            <div>
              <Button
                onClick={handleSave}
                disabled={disableSave}
                loading={submitting}
                type="secondary"
              >
                {t('generic.save')}
              </Button>
            </div>
          </Tooltip>
        </HeaderWrapper>
        <ToggleWrapper>
          <T>{t('backoffice.courses.availabilityTab.selectedCustomer')}</T>
          <Toggle
            checked={everyone}
            disabled={locked || submitting}
            onChange={() => setEveryone(!everyone)}
            theme="dark"
          />
          <T>{t('backoffice.courses.availabilityTab.everyone')}</T>
        </ToggleWrapper>
        <ToggleWrapper>
          <T>{t('backoffice.courses.availabilityTab.free')}</T>
          <Toggle
            checked={paid || !everyone}
            disabled={!everyone || locked || submitting}
            onChange={() => setPaid(!paid)}
            theme="dark"
          />
          <T>{t('backoffice.courses.availabilityTab.paid')}</T>
        </ToggleWrapper>
        {!everyone && (
          <>
            <ToggleWrapper>
              <T>{t('backoffice.courses.availabilityTab.videocationLogo')}</T>
              <Toggle
                checked={vcLogo}
                disabled={locked || submitting}
                onChange={() => setVcLogo(!vcLogo)}
                theme="dark"
              />
            </ToggleWrapper>
            <OrganizationWrapper>
              <T fontWeight="semibold">{`${t(
                'backoffice.courses.availabilityTab.availableTo'
              )}:`}</T>
              <div>
                <AutocompleteWithList
                  allowSingleSelection
                  disabled={locked || submitting}
                  items={organizationsToShow}
                  loading={loadingOrganizations}
                  loadingText={t('generic.loadingText')}
                  noOptionsText={t('generic.noSearchResults')}
                  onChange={(selectedItems) =>
                    setSelectedOrganization(selectedItems[0])
                  }
                  selectedItems={
                    selectedOrganization ? [selectedOrganization] : undefined
                  }
                  theme="dark"
                  type={AUTOCOMPLETEWITHLIST_TYPE.FORM}
                />
              </div>
            </OrganizationWrapper>
          </>
        )}
      </ContentWrapper>
      <Snackbar
        type="error"
        message={t('errors.requestFailed')}
        open={organizationsError}
        onClose={() => setOrganizationsError(false)}
      />
    </>
  );
};

export default Availability;

import { PROCESSING_STATUS, courseStatus } from '@shared/const';
import { selectTranslation } from '@shared/utils';

import TABS from './tabs';

const validateFile = (entity, fileField, statusField) => {
  let hasFile;

  const fileValue = entity[fileField];
  const statusValue = entity[statusField];

  if (Array.isArray(fileValue)) {
    hasFile = fileValue.length > 0;
  } else {
    hasFile =
      typeof fileValue === 'object' &&
      fileValue !== null &&
      Object.keys(fileValue).length > 0;
  }

  return (
    hasFile &&
    (!statusValue || statusValue.status === PROCESSING_STATUS.PROCESSED)
  );
};

const validateAttachments = (entity) => {
  return (entity.attachments || []).reduce((acc, attachment) => {
    if (!validateFile(attachment, 'file', 'processingStatus')) {
      acc[attachment.id] = attachment.name;
    }
    return acc;
  }, {});
};

const validateDescription = (course, locales) => {
  const validation = {};
  const usedLocales = [locales[0]];

  locales.forEach((locale, index) => {
    const localeValidation = {
      description:
        course.descriptionTranslations &&
        !!selectTranslation(
          course.descriptionTranslations,
          '',
          locale,
          ''
        ).trim(),
      name:
        course.nameTranslations &&
        !!selectTranslation(course.nameTranslations, '', locale, '').trim(),
      seoDescription:
        course.seoDescriptionTranslations &&
        !!selectTranslation(
          course.seoDescriptionTranslations,
          '',
          locale,
          ''
        ).trim(),
      shortDescription:
        course.shortDescriptionTranslations &&
        !!selectTranslation(
          course.shortDescriptionTranslations,
          '',
          locale,
          ''
        ).trim(),
      learningPoints:
        course.keyLearningPointsTranslations &&
        selectTranslation(course.keyLearningPointsTranslations, [], locale, [])
          .map((leaningPoint) => leaningPoint.trim())
          .filter((leaningPoint) => leaningPoint).length > 0,
      ...(course.prerequisitesTranslations
        ? {
            prerequisitesTranslations: !!selectTranslation(
              course.prerequisitesTranslations,
              '',
              locale,
              ''
            ).trim(),
          }
        : {}),
      ...(course.typicalLearnerTranslations
        ? {
            typicalLearnerTranslations: !!selectTranslation(
              course.typicalLearnerTranslations,
              '',
              locale,
              ''
            ).trim(),
          }
        : {}),
    };

    let allMissing = true;

    Object.entries(localeValidation).forEach(([key, value]) => {
      if (!value) {
        if (!validation[locale]) {
          validation[locale] = {};
        }
        validation[locale][key] = true;
      } else {
        allMissing = false;
      }
    });

    if (index !== 0 && !allMissing) {
      usedLocales.push(locale);
    } else if (index !== 0) {
      delete validation[locale];
    }
  });

  return {
    usedLocales,
    validation,
  };
};

const validateGameOrder = (courseEpisodes, episodeOrder) => {
  // Validate that between two game episodes there is a non game episode
  const gameEpisodes = new Set(
    courseEpisodes.filter(({ game }) => game).map(({ id }) => id)
  );

  for (let i = 0; i < episodeOrder.length; i += 1) {
    if (
      i !== 0 &&
      gameEpisodes.has(episodeOrder[i]) &&
      gameEpisodes.has(episodeOrder[i - 1])
    ) {
      return false;
    }
  }
  return true;
};

const validateLessons = (course, locales, originalWithoutSubtitles) => {
  const validation = {};
  let totalVideoEpisodes = 0;
  let totalWithoutSubtitle = 0;
  let hasPendingSubtitles = false;
  const isPublished = course?.status === courseStatus.PUBLISHED;

  if (
    !validateFile(course, 'trailerVideoFiles', 'trailerVideoProcessingStatus')
  ) {
    validation.trailer = true;
  }

  if (!(course.episodes?.length > 0)) {
    validation.noEpisodes = true;
  } else {
    if (!validateGameOrder(course.episodes, course.episodeOrder)) {
      validation.gameOrder = true;
    }

    totalVideoEpisodes = course.episodes.length;
    course.episodes.forEach((episode) => {
      const episodeValidation = {};

      locales
        .filter(
          (locale) =>
            !episode.nameTranslations ||
            !selectTranslation(episode.nameTranslations, '', locale, '').trim()
        )
        .forEach((locale) => {
          episodeValidation.description = {
            [locale]: {
              title: true,
            },
          };
        });

      if (!episode.game) {
        if (!validateFile(episode, 'videoFiles', 'videoProcessingStatus')) {
          episodeValidation.video = true;
        }

        if (!validateFile(episode, 'subtitle', 'subtitleProcessingStatus')) {
          episodeValidation.subtitle = true;
          totalWithoutSubtitle += 1;
        }

        if (
          episode.subtitleProcessingStatus &&
          episode.subtitleProcessingStatus.status !==
            PROCESSING_STATUS.PROCESSED
        ) {
          hasPendingSubtitles = true;
        }
      } else {
        totalVideoEpisodes -= 1;
        if (!episode.game.id) {
          episodeValidation.gameId = true;
        }
        if (!episode.game.length) {
          episodeValidation.gameLength = true;
        }
      }

      const attachmentValidation = validateAttachments(episode);

      if (Object.keys(attachmentValidation).length > 0) {
        episodeValidation.attachments = attachmentValidation;
      }

      if (Object.keys(episodeValidation).length > 0) {
        if (!validation.episodes) {
          validation.episodes = [];
        }
        episodeValidation.index = course.episodeOrder.indexOf(episode.id) + 1;
        validation.episodes.push(episodeValidation);
      }
    });
  }

  const mixedOrPendingSubtitles =
    (totalWithoutSubtitle > 0 && totalWithoutSubtitle < totalVideoEpisodes) ||
    hasPendingSubtitles;

  validation.episodes = validation.episodes
    ?.map((episodeValidation) => {
      if (
        mixedOrPendingSubtitles ||
        (originalWithoutSubtitles !== undefined &&
          !(
            isPublished &&
            originalWithoutSubtitles &&
            totalWithoutSubtitle === totalVideoEpisodes
          ))
      ) {
        return episodeValidation;
      }

      const { subtitle, ...rest } = episodeValidation;

      // we only want to return the validation object if it doesn't contain only {index: ...}
      return Object.keys(rest).length > 1 ? rest : undefined;
    })
    .filter(Boolean);

  if (!validation.episodes || validation.episodes.length === 0) {
    delete validation.episodes;
  }

  return validation;
};

const validateCatalog = (course) => {
  const validation = {};

  if (!course.ownerOrganization && !(course.experts?.items?.length > 0)) {
    validation.experts = true;
  }

  if (
    course.experts?.items?.length > 1 &&
    !validateFile(course, 'promopicture', 'promopictureProcessingStatus')
  ) {
    validation.promo = true;
  }

  if (!validateFile(course, 'thumbnail', 'thumbnailProcessingStatus')) {
    validation.thumbnail = true;
  }

  return validation;
};

export default (course, locales, originalWithoutSubtitles) => {
  const { usedLocales, validation: descriptionValidations } =
    validateDescription(course, locales);
  const lessonsValidations = validateLessons(
    course,
    usedLocales,
    originalWithoutSubtitles
  );
  const catalogValidations = validateCatalog(course);
  const attachmentValidations = validateAttachments(course);

  const validation = {};

  if (Object.keys(descriptionValidations).length > 0) {
    validation[TABS.DESCRIPTION] = descriptionValidations;
  }

  if (Object.keys(lessonsValidations).length > 0) {
    validation[TABS.LESSONS] = lessonsValidations;
  }

  if (Object.keys(catalogValidations).length > 0) {
    validation[TABS.CATALOG] = catalogValidations;
  }

  if (Object.keys(attachmentValidations).length > 0) {
    validation[TABS.ATTACHMENTS] = attachmentValidations;
  }

  return validation;
};

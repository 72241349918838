import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import theme from 'shared/themes/default';
import { selectTranslation } from 'shared/utils';

import Alert from 'shared/atoms/Alert';
import CircularSpinner from 'shared/atoms/CircularSpinner';
import T from 'shared/atoms/Typography';
import Dialog from 'shared/molecules/Dialog';
import ConfirmationDialog from 'shared/molecules/ConfirmationDialog';
import ManageQuiz from 'shared/molecules/ManageQuiz';
import { parseCsvContent } from 'shared/molecules/ManageQuiz/utils';

import { usePlaylist } from '../../../context';

const SideSpaceWrapper = styled.div`
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
`;

const Spacer = styled.div`
  height: 2rem;
`;

const ManageQuizDialog = ({ defaultQuestions }) => {
  const {
    dialogs: {
      formStatus,
      onCloseAddExamDialogHandler: onCloseHandler,
      onSubmitAddExamHandler: onSubmitHandler,
      selectedNanoDegree,
      selectedExam,
      isExamLoading,
    },
    locale,
  } = usePlaylist();

  const [quizId, setQuizId] = useState(null);
  const [title, setTitle] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [quizQuestions, setQuizQuestions] = useState(defaultQuestions);
  const [pastedCsvContent, setPastedCsvContent] = useState('');
  const [confirmCloseWithoutSaveDialog, setConfirmCloseWithoutSaveDialog] =
    useState(false);
  const [hasModified, setHasModified] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    setSubmitting(false);
  }, [formStatus]);

  useEffect(() => {
    if (selectedNanoDegree) {
      const { quizId } = selectedNanoDegree;
      setQuizId(quizId);

      const title = selectedNanoDegree
        ? selectTranslation(
            selectedNanoDegree.titleTranslations,
            selectedNanoDegree.title,
            locale,
            ''
          )
        : '';
      setTitle(title);
    }
  }, [selectedNanoDegree]);

  useEffect(() => {
    if (!isExamLoading) {
      if (quizId && selectedExam.length > 0) {
        setQuizQuestions(selectedExam);
        setIsEditing(true);
        setSelectedTabIndex(1);
      }
      setIsLoading(false);
    }
    setIsLoading(isExamLoading);
  }, [isExamLoading, quizId]);

  const onDialogSubmit = () => {
    if (selectedTabIndex === 0) {
      if (pastedCsvContent.length > 0) {
        const { quizQuestions: parseQuestions, error } = parseCsvContent(
          pastedCsvContent,
          t
        );

        setErrorMessage(error);
        if (parseQuestions.length > 0) {
          setConfirmCloseWithoutSaveDialog(false);
          setSubmitting(true);
          onSubmitHandler(parseQuestions);
        }
      }
    } else if (quizQuestions.length > 0) {
      setConfirmCloseWithoutSaveDialog(false);
      setSubmitting(true);
      onSubmitHandler(quizQuestions);
    }
  };

  const titleDialog = t('quizzes.titleDialog', { name: title });
  const subTitle = t(
    isEditing ? 'quizzes.editExamTitle' : 'quizzes.addExamTitle',
    { name: title }
  );

  const onCloseMainDialog = () => {
    if (formStatus || (pastedCsvContent.length === 0 && !hasModified)) {
      onCloseHandler();
    } else {
      setConfirmCloseWithoutSaveDialog(true);
    }
  };

  const onDeleteQuiz = () => {
    setIsEditing(false);
    setQuizQuestions([]);
    setSelectedTabIndex(0);
  };

  const disabledSubmit =
    !hasModified ||
    (pastedCsvContent.length === 0 && quizQuestions.length === 0);

  const actions = [
    {
      ariaLabel: t('quizzes.saveExam'),
      disabled: disabledSubmit,
      handler: onDialogSubmit,
      label: t('quizzes.saveExam'),
    },
  ];
  if (isEditing) {
    actions.unshift({
      ariaLabel: t('quizzes.deleteExam'),
      handler: () => onDeleteQuiz(quizId),
      label: t('quizzes.deleteExam'),
      type: 'secondaryOutline',
    });
  }

  return (
    <Dialog
      ariaLabel={titleDialog}
      actions={!formStatus ? actions : []}
      contentPadding="0"
      errorMessage={errorMessage}
      formStatus={formStatus}
      loading={submitting}
      maxWidth="md"
      onClose={onCloseMainDialog}
      onCloseNotificationMessage={() => {
        setErrorMessage(null);
      }}
      open
      scroll="body"
      title={titleDialog}
    >
      <SideSpaceWrapper>
        <T color={theme.palette.darkDistinct}>{subTitle}</T>
      </SideSpaceWrapper>
      <Spacer />

      {formStatus && (
        <SideSpaceWrapper>
          <Alert
            message={formStatus.message}
            severity={formStatus.success ? 'success' : 'error'}
            theme="light"
          />
        </SideSpaceWrapper>
      )}

      {isLoading && <CircularSpinner static />}

      {!formStatus && !isLoading && (
        <ManageQuiz
          defaultQuizQuestions={selectedExam}
          isEditing={isEditing}
          isExam
          pastedCsvContent={pastedCsvContent}
          quizQuestions={quizQuestions}
          setErrorMessage={setErrorMessage}
          setHasModified={setHasModified}
          setPastedCsvContent={setPastedCsvContent}
          setQuizQuestions={setQuizQuestions}
        />
      )}
      <ConfirmationDialog
        open={confirmCloseWithoutSaveDialog}
        ariaLabel={t('quizzes.examNotSaved')}
        title={t('quizzes.examNotSaved')}
        description={t('quizzes.examNotSavedDescription')}
        cancelText={t('quizzes.discard')}
        confirmText={t('quizzes.save')}
        onCancel={onCloseHandler}
        onClose={() => setConfirmCloseWithoutSaveDialog(false)}
        onConfirm={() => {
          onDialogSubmit();
          setConfirmCloseWithoutSaveDialog(false);
        }}
      />
    </Dialog>
  );
};

ManageQuizDialog.propTypes = {
  defaultQuestions: PropTypes.array,
};

ManageQuizDialog.defaultProps = {
  defaultQuestions: [],
};

export default ManageQuizDialog;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import MuiDrawer from '@mui/material/Drawer';
import theme from 'shared/themes/default';

import Button from 'shared/atoms/Button';
import IconButton from 'shared/atoms/IconButton';
import T from 'shared/atoms/Typography';
import ConfirmationDialog from 'shared/molecules/ConfirmationDialog';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${theme.spacing.m1} ${theme.spacing.m2};
  gap: ${theme.spacing.m1};
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${theme.spacing.m3};
  width: 100%;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${theme.spacing.s};
`;

const Drawer = ({
  anchor,
  children,
  edited,
  errorMessage,
  onClose,
  onSave,
  open,
  saveDisabled,
  saving,
  sx,
  title,
}) => {
  const [showConfirmationDialogDrawer, setShowConfirmationDialogDrawer] =
    useState(false);
  const { t } = useTranslation();

  const sxGeneric = {
    '.MuiDrawer-paper': {
      background: theme.palette.mainDark,
    },
  };

  const onCloseHandle = () => {
    if (edited) {
      setShowConfirmationDialogDrawer(true);
    } else {
      onClose();
    }
  };

  const onDiscardHandle = () => {
    setShowConfirmationDialogDrawer(false);
    onClose();
  };

  return (
    <>
      <MuiDrawer
        anchor={anchor}
        onClose={onCloseHandle}
        open={open}
        sx={{ ...sxGeneric, ...sx }}
      >
        <>
          <Header>
            <HeaderContent>
              <T color={theme.palette.lightPure}>{title}</T>
              <Buttons>
                {onSave && (
                  <Button
                    ariaLabel={t('generic.save')}
                    onClick={onSave}
                    type="secondaryOutline"
                    disabled={saveDisabled || !edited || saving}
                    loading={saving}
                    loadingOnIcon={saving}
                  >
                    {t('generic.save')}
                  </Button>
                )}
                <IconButton
                  ariaLabel={t('generic.close')}
                  disabled={saving}
                  icon={CloseIcon}
                  onClick={onCloseHandle}
                  theme="dark"
                  type="secondary"
                />
              </Buttons>
            </HeaderContent>
            {errorMessage && (
              <T color={theme.palette.errorDark}>{errorMessage}</T>
            )}
          </Header>
          {children}
        </>
      </MuiDrawer>
      {showConfirmationDialogDrawer && (
        <ConfirmationDialog
          open={showConfirmationDialogDrawer}
          title={t('generic.unsavedChanges')}
          description={t('generic.confirmSaveUnsavedChanges')}
          ariaLabel={t('generic.unsavedChanges')}
          cancelText={t('generic.discard')}
          confirmText={t('generic.confirm')}
          disabledConfirm={saveDisabled}
          onConfirm={() => {
            setShowConfirmationDialogDrawer(false);
            onSave();
          }}
          onCancel={onDiscardHandle}
          onClose={() => setShowConfirmationDialogDrawer(false)}
        />
      )}
    </>
  );
};

Drawer.propTypes = {
  anchor: PropTypes.oneOf(['left', 'right']),
  children: PropTypes.node.isRequired,
  edited: PropTypes.bool,
  errorMessage: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  open: PropTypes.bool.isRequired,
  saveDisabled: PropTypes.bool,
  saving: PropTypes.bool,
  sx: PropTypes.object,
  title: PropTypes.string,
};

Drawer.defaultProps = {
  anchor: 'right',
  sx: {},
};

export default Drawer;

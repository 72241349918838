import React from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';

import CircularSpinner from '@shared/atoms/CircularSpinner';
import T from '@shared/atoms/Typography';
import ItemBox from '@shared/molecules/ItemBox';

import theme from '@shared/themes/default';

const ExpertsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.s};
`;

const ExpertsList = ({
  experts,
  filter,
  handleDeleteExperts,
  isLocked,
  loading,
  selectedExpert,
  setSelectedExpert,
}) => {
  const { t } = useTranslation();

  if (loading) return <CircularSpinner static />;

  const titleList = (
    <T color={theme.palette.lightPure}>
      {t('backoffice.experts.listingExperts', { count: experts.length })}
    </T>
  );

  if (experts.length === 0 && !filter) {
    return (
      <ExpertsWrapper>
        {titleList}
        <ItemBox
          key="empty-list"
          id="empty-list"
          menuItems={[]}
          theme="dark"
          title={t('backoffice.experts.noExperts')}
        />
      </ExpertsWrapper>
    );
  }

  if (experts.length === 0) {
    return (
      <ExpertsWrapper>
        {titleList}
        <ItemBox
          key="no-found-experts"
          id="no-found-experts"
          menuItems={[]}
          theme="dark"
          title={t('backoffice.experts.noFoundExperts', { string: filter })}
        />
      </ExpertsWrapper>
    );
  }

  return (
    <ExpertsWrapper>
      {titleList}
      {experts.map(({ courses, firstname, id, isDisabled, lastname }) => {
        const coursesCount = (courses?.items || []).length;
        return (
          <ItemBox
            buttons={
              coursesCount === 0
                ? [
                    {
                      disabled: isLocked,
                      handler: handleDeleteExperts,
                      id: 'delete',
                      label: t('backoffice.experts.delete'),
                      textStyle: true,
                    },
                  ]
                : []
            }
            key={id}
            id={id}
            menuItems={[]}
            onClickHandler={
              !isLocked
                ? (id) =>
                    setSelectedExpert(
                      experts.find((expert) => expert.id === id)
                    )
                : null
            }
            selected={selectedExpert?.id === id}
            theme="dark"
            title={`${firstname} ${lastname}`}
            titleIcon={isDisabled ? PersonOffOutlinedIcon : undefined}
            topText={t('backoffice.experts.coursesCount', {
              count: coursesCount,
            })}
          />
        );
      })}
    </ExpertsWrapper>
  );
};

ExpertsList.propTypes = {
  experts: PropTypes.arrayOf(PropTypes.object),
  filter: PropTypes.string.isRequired,
  handleDeleteExperts: PropTypes.func.isRequired,
  isLocked: PropTypes.bool,
  loading: PropTypes.bool,
  selectedExpert: PropTypes.object,
  setSelectedExpert: PropTypes.func.isRequired,
};

ExpertsList.defaultProps = {
  experts: [],
  isLocked: false,
  loading: false,
};

export default ExpertsList;

import { useEffect } from 'react';
/*
 * useDebouncedFunction hook
 */
export default function useDebouncedFunction(handler, value, delay) {
  useEffect(() => {
    const timeoutHandler = setTimeout(() => {
      handler();
    }, delay);
    return () => {
      clearTimeout(timeoutHandler);
    };
  }, [value, delay]);
}

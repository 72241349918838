import logger from './logger';

const normalize = (value) => {
  if (Array.isArray(value)) {
    return value.length;
  }

  if (typeof value === 'number' || value instanceof Date) {
    return value;
  }

  return value && value.toLowerCase();
};

const propertyExists = (value) => !!value || value === 0;

function descendingComparator(a, b, orderBy) {
  const normalizedA = normalize(a[orderBy]);
  const normalizedB = normalize(b[orderBy]);
  if (propertyExists(normalizedA) || propertyExists(normalizedB)) {
    if (normalizedB < normalizedA || !propertyExists(normalizedB)) {
      return -1;
    }
    if (normalizedB > normalizedA || !propertyExists(normalizedA)) {
      return 1;
    }
    return 0;
  }
  logger.debug(
    `Unable to sort, most probably property '${orderBy}' is not present in object`,
    a,
    b
  );
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function itemIsIncluded(itemId, itemsArray) {
  return itemsArray.findIndex((item) => item.id === itemId) !== -1;
}

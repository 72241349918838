import React from 'react';
import PropTypes from 'prop-types';
import MuiTable from '@mui/material/Table';
import makeStyles from '@mui/styles/makeStyles';

import theme from 'shared/themes/default';

const useStyles = makeStyles({
  root: {
    backgroundColor: theme.palette.pureDark,
    border: 0,
    width: '100%',
  },
});

function TableComponent({ children, ...props }) {
  const classes = useStyles();
  return (
    <MuiTable {...props} classes={classes}>
      {children}
    </MuiTable>
  );
}
TableComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TableComponent;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import T from 'shared/atoms/Typography';
import Dialog from 'shared/molecules/Dialog';
import ConfirmationDialog from 'shared/molecules/ConfirmationDialog';
import Alert from 'shared/atoms/Alert';
import Form from 'shared/atoms/Form';
import theme from 'shared/themes/default';
import AutocompleteWithList from 'shared/molecules/AutocompleteWithList';
import { AUTOCOMPLETEWITHLIST_TYPE } from 'shared/const';

const SideSpaceWrapper = styled.div`
  padding: 0 1rem;
  display: block !important;
  margin-top: ${(props) => (props.addMargin ? '20px !important' : '0px')};
`;
export const Spacer = styled.div`
  height: ${theme.spacing.m1};
`;

function TransferOwnershipView({
  usersToTransfer,
  onClose,
  onSubmitHandler,
  transferItemName,
  formStatus,
}) {
  const [submitting, setSubmitting] = useState(false);
  const [showConfirmationDialogue, setShowConfirmationDialogue] =
    useState(false);
  const [selectedManager, setSelectedManager] = useState([]);
  useEffect(() => {
    if (formStatus) {
      setShowConfirmationDialogue(false);
      setSubmitting(false);
      setSelectedManager([]);
    }
  }, [formStatus]);

  const { t } = useTranslation();

  const onSubmit = () => {
    setSubmitting(true);
    setShowConfirmationDialogue(false);
    onSubmitHandler(selectedManager);
  };

  const onCloseHandler = () => {
    if (selectedManager.length) {
      setShowConfirmationDialogue(true);
    } else {
      onClose();
    }
  };

  const onDiscardHandler = () => {
    setSelectedManager([]);
    setShowConfirmationDialogue(false);
    onClose();
  };

  const actions = [
    {
      ariaLabel: t('generic.cancel'),
      handler: () => onCloseHandler(),
      label: t('generic.cancel'),
      type: 'secondaryOutline',
    },
    {
      ariaLabel: t('administrateUsers.transferOwnership'),
      disabled: !selectedManager.length,
      handler: onSubmit,
      label: t('administrateUsers.transferOwnership'),
      submit: true,
    },
  ];

  const onChangeHandler = (user) => {
    setSelectedManager(user);
  };

  const itemsToSelect = usersToTransfer?.map((user) => ({
    ...user,
    key: user.id,
    title: user.name,
    subtitle: user.email,
  }));

  return (
    <Dialog
      open
      onClose={onCloseHandler}
      ariaLabel={t('administrateUsers.transferOwnershipContent')}
      title={t('administrateUsers.transferOwnershipContent')}
      loading={submitting}
      actions={actions}
      contentPadding="0"
      scroll="body"
      formStatus={formStatus}
    >
      <SideSpaceWrapper>
        <T color={theme.palette.darkDistinct}>
          {t('administrateUsers.transferOwnershipHelper', {
            item: transferItemName,
          })}
        </T>
      </SideSpaceWrapper>
      {!formStatus && (
        <Form onSubmit={onSubmit}>
          <SideSpaceWrapper addMargin>
            <T color={theme.palette.darkDistinct} fontWeight="semibold">
              {t('administrateUsers.transferSearchBoxHeader')}
            </T>
            <Spacer px={6} />
            <AutocompleteWithList
              allowSingleSelection
              items={itemsToSelect}
              listHeaderText={t('administrateUsers.transferringToOwner')}
              loadingText={t('administrateUsers.loadingMembers')}
              noOptionsText={t('generic.noSearchResults')}
              onChange={onChangeHandler}
              placeholder={t('administrateUsers.searchPlaceHolder')}
              type={AUTOCOMPLETEWITHLIST_TYPE.FORM}
            />
          </SideSpaceWrapper>
          {showConfirmationDialogue && (
            <ConfirmationDialog
              open={showConfirmationDialogue}
              title={t('generic.unsavedChanges')}
              description={t('generic.confirmSaveUnsavedChanges')}
              ariaLabel={t('generic.unsavedChanges')}
              cancelText={t('administrateUsers.discard')}
              confirmText={t('generic.confirm')}
              onCancel={onDiscardHandler}
              onConfirm={onSubmit}
              onClose={() => setShowConfirmationDialogue(false)}
            />
          )}
        </Form>
      )}
      {formStatus && (
        <Alert
          message={formStatus.message}
          severity={formStatus.success ? 'success' : 'error'}
          theme="light"
        />
      )}
    </Dialog>
  );
}

TransferOwnershipView.propTypes = {
  usersToTransfer: PropTypes.array,
  onClose: PropTypes.func,
  onSubmitHandler: PropTypes.func.isRequired,
  formStatus: PropTypes.object,
  transferItemName: PropTypes.string,
};

TransferOwnershipView.defaultProps = {
  onClose: () => {},
  formStatus: null,
};

export default TransferOwnershipView;

import styled from 'styled-components';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import withStyles from '@mui/styles/withStyles';
import theme from 'shared/themes/default';

export const StyledErrorOutlineIcon = withStyles({
  root: {
    color: theme.palette.pureDark,
    verticalAlign: 'middle',
    marginLeft: '5px',
    '&:hover, &:focus, &:active': {
      color: theme.palette.brand,
    },
  },
})(ErrorOutlineIcon);

export const ContentOwnershipWrapper = styled.div`
  padding-top: 25px;
`;

export const ContentOwnershipHeader = styled.div`
  font-family: ${theme.fonts.primary};
  color: ${theme.palette.pureDark};
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 700;
  margin-right: 10px;
`;

export const ContentOwnershipSubHeader = styled.div`
  font-family: ${theme.fonts.primary};
  color: ${theme.palette.pureDark};
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 'normal';
  padding-top: 10px;
`;

export const TransferContentOptionWrapper = styled.div`
  padding-top: 10px;
`;

export const SearchManagerWrapper = styled.div`
  padding-top: 15px;
`;

export const AutoCompleteWrapper = styled.div`
  width: 100%;
`;

export const DeleteMembershipAlertText = styled.div`
  width: 85%;
  margin-top: 30px;
  font-family: ${theme.fonts.primary};
  color: ${theme.palette.pureDark};
  font-size: 1rem;
  line-height: 1.35rem;
  font-weight: 'normal';
`;

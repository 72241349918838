import React from 'react';

const IconUnchecked = () => {
  return (
    <svg
      width="24px"
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="CheckboxOutlineBlankIcon"
    >
      <path className="IconUncheckedBackground" d="M20 4v16H4V4h16" />
      <path
        className="IconUncheckedStroke"
        d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
      />
    </svg>
  );
};

export default IconUnchecked;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Alert from 'shared/atoms/Alert';
import T from 'shared/atoms/Typography';
import Form from 'shared/atoms/Form';
import FormAutocomplete from 'shared/atoms/FormFields/FormAutocomplete';
import Dialog from 'shared/molecules/Dialog';
import FileUpload from 'shared/molecules/FileUpload';
import FormInput from 'shared/atoms/FormFields/FormInput';

import theme from 'shared/themes/default';
import { LOCALES, PLAYLIST_TYPE, PROCESSING_STATUS } from 'shared/const';
import { useForm } from 'shared/utils';

import { usePlaylist } from '../../../context';

const UploadWrapper = styled.div`
  margin-top: ${theme.spacing.s};
`;

const sxInputStyle = {
  marginBottom: theme.spacing.s,
};

function CreateNanoDegreeDialog({ initialValues, published }) {
  const { t } = useTranslation();
  const {
    completeUpload,
    debug,
    getUploadUrl,
    graphql,
    locale,
    startUpload,
    staticImagesHostname,
    dialogs: {
      onCloseNanodegreeFormHandler,
      onCreatePlaylist,
      updatePlaylistHandler,
      formStatus,
    },
    experts: { allExperts, loadingExperts },
  } = usePlaylist();

  const onSaveHandler = initialValues.id
    ? updatePlaylistHandler
    : onCreatePlaylist;

  const [uploadingFiles, setUploadingFiles] = useState(false);
  const [selectedEditor, setSelectedEditor] = useState(null);
  const [selectedEditorChanged, setSelectedEditorChanged] = useState(false);
  const [files, setFiles] = useState({});

  const onSelectEditorHandler = ({ value }) => {
    if (!value) {
      setSelectedEditor(null);
    } else {
      setSelectedEditor(value);
    }
    setSelectedEditorChanged(value?.id !== initialValues.editorId);
  };

  const handleAddThumbnail = (file) => {
    setFiles({
      ...files,
      thumbnail: {
        ...file.s3File,
        base64: file.base64,
      },
      thumbnailFile: file.s3File,
      thumbnailProcessingStatus: {
        file: file.s3File,
        status: PROCESSING_STATUS.TO_SCHEDULE,
      },
    });
  };

  const handleDeleteThumbnail = () => {
    setFiles({
      ...files,
      thumbnail: null,
      thumbnailFile: null,
      thumbnailProcessingStatus: null,
    });
  };

  const handleAddTrailer = (file) => {
    setFiles({
      ...files,
      trailerVideo: file.s3File,
      trailerVideoFiles: null,
      trailerVideoProcessingStatus: {
        file: file.s3File,
        status: PROCESSING_STATUS.TO_SCHEDULE,
      },
    });
  };

  const handleDeleteTrailer = () => {
    setFiles({
      ...files,
      trailerVideo: null,
      trailerVideoFiles: null,
      trailerVideoProcessingStatus: null,
    });
  };

  const onSubmitHandler = async (values) => {
    await onSaveHandler({
      ...values,
      ...files,
      id: initialValues.id,
      editorId: selectedEditor?.id,
      type: PLAYLIST_TYPE.NANO_DEGREE,
    });
  };

  useEffect(() => {
    if (!loadingExperts && initialValues?.editorId) {
      setSelectedEditor(
        allExperts.find((item) => item.id === initialValues.editorId) || null
      );
    }
  }, [initialValues, loadingExperts]);

  const {
    edited,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    missingRequired,
    properties,
    setDefaults,
    submitting,
    values,
  } = useForm({
    fields: {
      name: {
        localised: true,
        maxLength: 70,
        required: true,
      },
      description: {
        localised: true,
        maxLength: 500,
        required: true,
      },
      shortDescription: {
        localised: true,
        maxLength: 240,
        required: published,
      },
    },
    locales: LOCALES,
    onSubmit: onSubmitHandler,
    requiredLocales: locale,
  });

  useEffect(() => {
    setDefaults({
      name: LOCALES.reduce((acc, curr) => {
        acc[curr] = initialValues[curr]?.name || '';
        return acc;
      }, {}),
      description: LOCALES.reduce((acc, curr) => {
        acc[curr] = initialValues[curr]?.description || '';
        return acc;
      }, {}),
      shortDescription: LOCALES.reduce((acc, curr) => {
        acc[curr] = initialValues[curr]?.shortDescription || '';
        return acc;
      }, {}),
    });
    setFiles({
      thumbnailUrl: initialValues.thumbnailUrl || null,
      thumbnail: initialValues.thumbnail || null,
      thumbnailProcessingStatus:
        initialValues.thumbnailProcessingStatus || null,
      trailerVideoFiles: initialValues.trailerVideoFiles || null,
      trailerVideoProcessingStatus:
        initialValues.trailerVideoProcessingStatus || null,
      trailerUrl: initialValues.trailerUrl || null,
    });
  }, [initialValues]);

  const editedForm =
    edited ||
    selectedEditorChanged ||
    files.thumbnailProcessingStatus?.status === PROCESSING_STATUS.TO_SCHEDULE ||
    files.trailerVideoProcessingStatus?.status ===
      PROCESSING_STATUS.TO_SCHEDULE;
  const missingRequiredForm =
    missingRequired ||
    (published &&
      (!selectedEditor ||
        !files.thumbnail ||
        !(files.trailerVideo || files.trailerVideoFiles)));

  const actions = [
    {
      ariaLabel: initialValues.id
        ? t('nanodegree.saveChanges')
        : t('nanodegree.create'),
      disabled: missingRequiredForm || uploadingFiles || !editedForm,
      handler: handleSubmit,
      label: initialValues.id
        ? t('nanodegree.saveChanges')
        : t('nanodegree.create'),
    },
  ];

  return (
    <Dialog
      open
      onClose={onCloseNanodegreeFormHandler}
      ariaLabel="create-nano-degree-dialog"
      title={
        initialValues.id
          ? t('nanodegree.editDegree')
          : t('nanodegree.createNewDegree')
      }
      actions={actions}
      loading={submitting}
      formStatus={formStatus}
    >
      <div>
        {formStatus && (
          <Alert
            message={formStatus.message}
            severity={formStatus.success ? 'success' : 'error'}
            theme="light"
          />
        )}
        {!formStatus && (
          <>
            {published && (
              <Alert
                message={t('nanodegree.allFieldsRequired')}
                severity="warning"
                sx={sxInputStyle}
                theme="light"
              />
            )}
            <Form id="create-nano-degree-form" onSubmit={onSubmitHandler}>
              <FormInput
                label={t('nanodegree.nameOfDegree')}
                id="name"
                name="name"
                errors={errors}
                locale={locale}
                handleBlur={handleBlur}
                handleChange={handleChange}
                properties={properties}
                showMaxLength
                sx={sxInputStyle}
                theme="light"
                values={values}
              />

              <FormInput
                label={t('nanodegree.description')}
                id="description"
                name="description"
                errors={errors}
                locale={locale}
                handleBlur={handleBlur}
                handleChange={handleChange}
                minRows={6}
                maxRows={6}
                multiline
                properties={properties}
                showMaxLength
                sx={sxInputStyle}
                theme="light"
                values={values}
              />

              <FormInput
                label={t('nanodegree.shortDescription')}
                id="shortDescription"
                name="shortDescription"
                errors={errors}
                locale={locale}
                handleBlur={handleBlur}
                handleChange={handleChange}
                minRows={3}
                maxRows={3}
                multiline
                properties={properties}
                showMaxLength
                sx={sxInputStyle}
                theme="light"
                values={values}
              />

              <FormAutocomplete
                id="editor"
                name="editor"
                clearOnSelect
                filterOptions
                label={t('nanodegree.editor')}
                placeholder={t('nanodegree.search')}
                noOptionsText={t('nanodegree.noEditorFound')}
                options={allExperts}
                getOptionKey={(option) => option.value}
                getOptionLabel={(option) => (option.name || '').toString()}
                handleChange={onSelectEditorHandler}
                loading={loadingExperts}
                preSelectedValue={selectedEditor}
                required={published}
                theme="light"
              />

              <UploadWrapper>
                <FileUpload
                  completeUpload={completeUpload}
                  debug={debug}
                  disabled={submitting}
                  filename={t('nanodegree.thumbnail')}
                  getUploadUrl={getUploadUrl}
                  graphql={graphql}
                  handleAdd={handleAddThumbnail}
                  handleDelete={handleDeleteThumbnail}
                  id="thumbnail-new"
                  imgUrl={
                    files.thumbnail &&
                    (files.thumbnail.base64 ||
                      `${staticImagesHostname}/${files.thumbnail.key}`)
                  }
                  infoText={
                    <>
                      <T
                        color={theme.palette.mainDark}
                        fontWeight="semibold"
                        variant="paragraphS"
                      >
                        {t('backoffice.nanodegrees.thumbnailInfoTitle')}
                      </T>
                      <T color={theme.palette.mainDark} variant="paragraphS">
                        {t('backoffice.nanodegrees.thumbnailInfoLine1')}
                      </T>
                      <T color={theme.palette.mainDark} variant="paragraphS">
                        {t('backoffice.nanodegrees.thumbnailInfoLine2')}
                      </T>
                    </>
                  }
                  onCompleteUpload={() => setUploadingFiles(false)}
                  onStartUpload={() => setUploadingFiles(true)}
                  processingStatus={
                    files.thumbnailProcessingStatus?.status ||
                    (files.thumbnail ? PROCESSING_STATUS.PROCESSED : null)
                  }
                  small
                  startUpload={startUpload}
                  theme="light"
                  title={t('nanodegree.addThumbnail')}
                  isRequired
                />
              </UploadWrapper>

              <UploadWrapper>
                <FileUpload
                  completeUpload={completeUpload}
                  debug={debug}
                  disabled={submitting}
                  filename={t('nanodegree.trailer')}
                  getUploadUrl={getUploadUrl}
                  graphql={graphql}
                  handleAdd={handleAddTrailer}
                  handleDelete={handleDeleteTrailer}
                  id="trailer-new"
                  infoText={
                    <>
                      <T
                        color={theme.palette.mainDark}
                        fontWeight="semibold"
                        variant="paragraphS"
                      >
                        {t('backoffice.nanodegrees.trailerInfoTitle')}
                      </T>
                      <T color={theme.palette.mainDark} variant="paragraphS">
                        {t('backoffice.nanodegrees.trailerInfoLine1')}
                      </T>
                    </>
                  }
                  onCompleteUpload={() => setUploadingFiles(false)}
                  onStartUpload={() => setUploadingFiles(true)}
                  preview={false}
                  processingStatus={
                    files.trailerVideoProcessingStatus?.status ||
                    (files.trailerVideoFiles
                      ? PROCESSING_STATUS.PROCESSED
                      : null)
                  }
                  small
                  startUpload={startUpload}
                  theme="light"
                  title={t('nanodegree.addTrailer')}
                  isRequired
                />
              </UploadWrapper>
            </Form>
          </>
        )}
      </div>
    </Dialog>
  );
}

CreateNanoDegreeDialog.propTypes = {
  initialValues: PropTypes.object,
  published: PropTypes.bool,
};

CreateNanoDegreeDialog.defaultProps = {
  initialValues: {},
  published: false,
};
export default CreateNanoDegreeDialog;

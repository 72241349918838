export default function usePlaylists({
  deleteUserPlaylists,
  registeredMembers,
  organizationId,
  setRegisteredMembers,
  transferUserPlaylists,
}) {
  const requestToDeleteUserPlaylists = async (ownerId) => {
    const { data } = await deleteUserPlaylists(organizationId)({ ownerId });

    if (data && data.deleteUserPlaylists) {
      const deletedPlaylistIds = data.deleteUserPlaylists?.ids || [];

      setRegisteredMembers(
        registeredMembers.map((member) => {
          if (member.id === ownerId) {
            const numberOfPlaylistsOwned =
              member.ownedPlaylistsNumber - deletedPlaylistIds.length;
            Object.assign(member, {
              ownedPlaylistsNumber:
                numberOfPlaylistsOwned > 0 ? numberOfPlaylistsOwned : 0,
            });
          }

          return member;
        })
      );

      return true;
    }

    return false;
  };

  const requestToTransferUserPlaylists = async (values) => {
    const { userSub: currentOwnerId, contentTransferTo: newOwnerId } = values;

    const { data } = await transferUserPlaylists(organizationId)({
      input: {
        currentOwnerId,
        newOwnerId,
      },
    });

    if (data && data.transferUserPlaylists) {
      const transferredPlaylistIds = data.transferUserPlaylists?.ids || [];

      setRegisteredMembers(
        registeredMembers.map((member) => {
          if (member.id === currentOwnerId) {
            const numberOfPlaylistsOwned =
              member.ownedPlaylistsNumber - transferredPlaylistIds.length;
            Object.assign(member, {
              ownedPlaylistsNumber:
                numberOfPlaylistsOwned > 0 ? numberOfPlaylistsOwned : 0,
            });
          }

          if (member.id === newOwnerId) {
            Object.assign(member, {
              ownedPlaylistsNumber:
                member.ownedPlaylistsNumber + transferredPlaylistIds.length,
            });
          }

          return member;
        })
      );

      return true;
    }

    return false;
  };

  return {
    requestToDeleteUserPlaylists,
    requestToTransferUserPlaylists,
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import withStyles from '@mui/styles/withStyles';

import theme from 'shared/themes/default';
import CircularSpinner from 'shared/atoms/CircularSpinner';
import T from 'shared/atoms/Typography';
import { selectTranslation } from 'shared/utils';

const StyledThumbnail = styled.img`
  margin-right: 6px;
  border-radius: 8px;
  min-width: 70px;
  width: 70px;
  height: 42px;
  object-fit: cover;
`;

const StyledTitleName = styled.div`
  text-overflow: ellipsis;
  width: 100%;
  word-break: break-word;
  margin-left: 5px;
  align-self: center;
  font-weight: 600;
  font-size: 14px;
`;

const styles = {
  root: {
    fontFamily: theme.fonts.primary,
    margin: '0 0 0 72px',
    display: 'grid',
    borderLeft: `2px solid ${theme.palette.disabledLight}`,
    backgroundColor: theme.palette.lightInteracting,
    paddingLeft: theme.spacing.s,
    '& .MuiListItem-root': {
      padding: '0 0 0 0',
    },
  },
};

const StyledList = styled(List)`
  display: grid;
`;

const StyledDiv = styled.div`
  align-items: center;
  background-color: ${theme.palette.lightInteracting};
  border-left: 2px solid ${theme.palette.disabledLight};
  margin-left: 72px;
  height: 80px;
  padding-left: ${theme.spacing.s};
`;

const StyledOuterWrapper = styled.div`
  background-color: ${theme.palette.lightInteracting};
  display: flex;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
    height: 30px;
    background-color: ${theme.palette.lightPure};
  }
  ::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    background-color: ${theme.palette.brand};
    border-radius: 10px;
  }
  max-height: 150px;
`;

const ExpandedCourses = ({ classes, courses, locale }) => {
  const { t } = useTranslation();

  return (
    <StyledOuterWrapper>
      {!courses ? (
        <CircularSpinner />
      ) : (
        <>
          {courses.length === 0 && (
            <StyledDiv>
              <T
                align="center"
                color={theme.palette.disabledLight}
                sx={{ marginTop: theme.spacing.m2 }}
                variant="headingXs"
              >
                {t('playlist.currentlyHaveNoCourses')}
              </T>
            </StyledDiv>
          )}
          <div>
            {courses.map((course) => (
              <StyledList classes={classes} key={course.id}>
                <ListItem>
                  <StyledThumbnail
                    alt={course.name}
                    src={course.thumbnailUrl}
                  />
                  <StyledTitleName>
                    {selectTranslation(
                      course.nameTranslations,
                      course.name,
                      locale,
                      ''
                    )}
                  </StyledTitleName>
                </ListItem>
              </StyledList>
            ))}
          </div>
        </>
      )}
    </StyledOuterWrapper>
  );
};

ExpandedCourses.propTypes = {
  classes: PropTypes.object,
  courses: PropTypes.array,
  locale: PropTypes.string,
};

export default withStyles(styles)(ExpandedCourses);

import React from 'react';
import PropTypes from 'prop-types';
import MuiTableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from 'shared/themes/default';

const useStyles = makeStyles(() => ({
  root: {
    margin: (props) => (props.isExpandedType ? '0' : `${theme.spacing.m1} 0`),
    background: theme.palette.darkDistinct,
    border: (props) =>
      props.isExpandedType || !props.displayBorder
        ? 'none'
        : `1px solid ${theme.palette.pureDark}`,
    boxShadow: 'none',
    overflowX: (props) => (props.isMobile ? 'auto' : 'unset'),
    scrollBehavior: 'smooth',
    opacity: (props) =>
      (props.isExpandedType && props.isExpandedOpen) || !props.isExpandedType
        ? '1'
        : '0',
    transition: (props) =>
      props.isExpandedType ? 'opacity .3s linear' : 'none',
  },
}));

function TableContainer({
  children,
  displayBorder,
  isExpandedOpen,
  isExpandedType,
  ...props
}) {
  const isMobile = useMediaQuery(theme.breakpoints.mobile);
  const classes = useStyles({ isExpandedOpen, isExpandedType, isMobile });
  return (
    <MuiTableContainer component={Paper} className={classes.root} {...props}>
      {children}
    </MuiTableContainer>
  );
}

TableContainer.propTypes = {
  children: PropTypes.node.isRequired,
  displayBorder: PropTypes.bool,
  isExpandedOpen: PropTypes.bool,
  isExpandedType: PropTypes.bool,
};

TableContainer.defaultProps = {
  displayBorder: true,
  isExpandedOpen: false,
  isExpandedType: false,
};

export default TableContainer;

import axios from 'axios';

export const downloadFile = async (token, baseUrl, fileName) => {
  // Get a public download URL for getting the generated file in S3
  const headers = {
    Authorization: `Bearer ${token}`,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
  };
  const url = `https://${baseUrl}/${fileName}`;
  const response = await axios.get(url, {
    method: 'GET',
    responseType: 'blob',
    headers,
  });

  // Build URL and simulate user clicking on a hidden link for downloading the file
  const downloadUrl = window.URL.createObjectURL(
    new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), response.data], {
      type: 'text/csv;charset=utf-8;',
    })
  );
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

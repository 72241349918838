import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import T from 'shared/atoms/Typography';

import theme from 'shared/themes/default';

const TitleBox = styled.div`
  background-color: ${theme.palette.lightInteracting};
  min-height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledList = styled.ul`
  color: ${theme.palette.lightPure};
  font-family: ${theme.fonts.primary};
  margin-top: 0;
  li {
    position: relative;
    padding: 0 25px 0 5px;
    line-height: 22px;
  }
`;

const ContentBox = styled.div`
  background-color: ${theme.palette.oddDark};
  height: 440px;
  padding-top: 1rem;
`;

const OuterWrapper = styled.div`
  width: 341px;

  ${theme.breakpoints.mobile} {
    width: 100%;
  }
`;

const DescriptionBox = ({ titleText, contentList }) => {
  return (
    <OuterWrapper>
      <TitleBox>
        <T
          color={theme.palette.mainDark}
          fontWeight="bold"
          sx={{ textTransform: 'uppercase' }}
          variant="headingXs"
        >
          {titleText}
        </T>
      </TitleBox>
      <ContentBox>
        <StyledList>
          {contentList
            ? contentList.map((item) => <li key={item}>{item}</li>)
            : ''}
        </StyledList>
      </ContentBox>
    </OuterWrapper>
  );
};

DescriptionBox.propTypes = {
  contentList: PropTypes.array.isRequired,
  titleText: PropTypes.string.isRequired,
};

export default DescriptionBox;

import React from 'react';
import PropTypes from 'prop-types';

import theme from 'shared/themes/default';
import Button from 'shared/atoms/Button';
import { getUserInitials } from 'shared/utils';
import { BACKGROUND_THEMES } from 'shared/const';
import { ICONBUTTON_TYPE_ENUM } from './const';

const IconButton = ({
  ariaLabel,
  icon: Icon,
  small,
  sx: sxButton,
  sxIcon,
  theme: themeLayout,
  type,
  user,
  ...props
}) => {
  const sxGeneric = {
    padding: small ? '0.125rem' : '0.5rem',
    minWidth: 'unset',
  };

  let fontSize = '24px';
  let iconType;
  let sxType;
  let sxUser;

  if (user) {
    if (small) {
      sxUser = {
        width: '28px',
        height: '28px',
      };
    } else {
      sxUser = {
        width: '40px',
        height: '40px',
      };
    }
  }

  switch (type) {
    case 'secondary':
      iconType = 'secondaryOutline';
      break;
    case 'secondaryAlt':
      if (themeLayout === 'light') {
        sxType = {
          background: theme.palette.darkWrapper,
          color: theme.palette.lightPure,
          '&:active': {
            color: theme.palette.brand,
          },
          '&:hover': {
            background: theme.palette.darkDistinct,
          },
          '&.Mui-disabled': {
            background: theme.palette.disabledDark,
            color: theme.palette.disabledLight,
          },
        };
        iconType = 'primary';
      } else {
        iconType = 'secondary';
      }
      break;
    case 'transparent':
      sxType = {
        background: 'transparent',
        color:
          themeLayout === 'dark'
            ? theme.palette.lightPure
            : theme.palette.mainDark,
        '&:active': {
          background: `${
            themeLayout === 'dark'
              ? theme.palette.lightPure
              : theme.palette.pureDark
          }30`,
          color:
            themeLayout === 'dark'
              ? theme.palette.brand
              : theme.palette.mainDark,
        },
        '&:hover': {
          background: `${
            themeLayout === 'dark'
              ? theme.palette.lightPure
              : theme.palette.pureDark
          }17`,
        },
        '&.Mui-disabled': {
          color: `${
            themeLayout === 'dark'
              ? theme.palette.lightPure
              : theme.palette.pureDark
          }53`,
        },
      };
      iconType = 'primary';
      break;
    case 'icon':
      sxType = {
        background: 'transparent',
        color:
          themeLayout === 'dark'
            ? theme.palette.lightPure
            : theme.palette.mainDark,
        padding: small ? '0.125rem' : '0.25rem',
        '&:active': {
          color:
            themeLayout === 'dark'
              ? theme.palette.mainDark
              : theme.palette.lightPure,
        },
        '&:hover': {
          color:
            themeLayout === 'dark'
              ? theme.palette.brand
              : theme.palette.disabledDark,
          background: 'transparent',
        },
        '&.Mui-disabled': {
          color:
            themeLayout === 'dark'
              ? theme.palette.disabledDark
              : theme.palette.disabledLight,
        },
      };
      fontSize = small ? '24px' : '32px';
      iconType = 'primary';
      break;
    default:
      sxType = {};
      iconType = type;
      break;
  }

  const sx = {
    ...sxGeneric,
    ...sxType,
    ...sxUser,
    ...sxButton,
  };

  return (
    <Button
      small={small}
      sx={sx}
      type={iconType}
      theme={themeLayout}
      ariaLabel={ariaLabel}
      {...props}
    >
      {user ? getUserInitials(user) : <Icon sx={{ fontSize, ...sxIcon }} />}
    </Button>
  );
};

IconButton.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  icon: (props, propName, componentName) => {
    if (!props[propName] && !props.user) {
      return new Error(
        `The prop \`${propName}\` or the prop \`user\` are marked as required in \`${componentName}\`, but their value is \`undefined\`.`
      );
    }
    if (
      props[propName] &&
      typeof props[propName] !== 'object' &&
      typeof props[propName] !== 'function'
    ) {
      return new Error(
        `Invalid prop \`${propName}\` of type \`${typeof props[
          propName
        ]}\` supplied to \`${componentName}\`, expected \`object\` or \`function\`.`
      );
    }
    return null;
  },
  small: PropTypes.bool,
  sx: PropTypes.object,
  sxIcon: PropTypes.object,
  theme: PropTypes.oneOf(BACKGROUND_THEMES),
  type: PropTypes.oneOf(ICONBUTTON_TYPE_ENUM),
  user: (props, propName, componentName) => {
    if (!props.icon && !props[propName]) {
      return new Error(
        `The prop \`icon\` or the prop \`${propName}\` are marked as required in \`${componentName}\`, but their value is \`undefined\`.`
      );
    }
    if (props[propName] && typeof props[propName] !== 'object') {
      return new Error(
        `Invalid prop \`${propName}\` of type \`${typeof props[
          propName
        ]}\` supplied to \`${componentName}\`, expected \`object\`.`
      );
    }
    return null;
  },
};

IconButton.defaultProps = {
  sx: {},
  sxIcon: {},
  theme: 'dark',
  type: 'primary',
};

export default IconButton;

import React from 'react';

import { useTranslation } from 'react-i18next';

import ConfirmationDialog from 'shared/molecules/ConfirmationDialog';
import InviteMembers from 'shared/organisms/InviteMembers';
import ManageContentOwnershipDialog from 'shared/organisms/ManageContentOwnershipDialog';

import { StyledBlock } from '../styles';

import { useOrganizationMembers } from '../context';

const Dialogs = () => {
  const { t } = useTranslation();

  const {
    formStatus,
    groups: {
      deleteGroup,
      deleteGroupMember,
      onDeleteGroupConfirm,
      onDeleteGroupMemberConfirm,
    },
    onCloseDelete,
    invitations: {
      failureList,
      inviteMembersOpen,
      onCloseInviteMembers,
      sendInvitationsHandler,
      successList,
    },
    organizations: { getOrganizationManagers },
    users: {
      deleteUser,
      isUserInOrganization,
      manageContentOwnership,
      onDeleteUserConfirm,
      onSubmitManageContentOwnership,
      transferContentChoiceOptions,
      transferContentOwnershipFor,
    },
  } = useOrganizationMembers();

  return (
    <StyledBlock>
      {inviteMembersOpen && (
        <InviteMembers
          formStatus={formStatus}
          isUserInOrganization={isUserInOrganization}
          onClose={onCloseInviteMembers}
          onSubmitHandler={sendInvitationsHandler}
          successList={successList}
          failureList={failureList}
        />
      )}
      <ConfirmationDialog
        open={deleteUser}
        title={t('administrateUserRole.deleteAccessDialogTitle')}
        description={t('administrateUserRole.deleteAccessDialogAlertText')}
        ariaLabel={t('administrateUserRole.deleteAccessDialogTitle')}
        cancelText={t('generic.cancel')}
        confirmText={t('administrateUserRole.deleteAccess')}
        onCancel={onCloseDelete}
        onClose={onCloseDelete}
        onConfirm={onDeleteUserConfirm}
        formStatus={formStatus}
      />
      <ManageContentOwnershipDialog
        open={manageContentOwnership}
        selectedUser={transferContentOwnershipFor}
        transferContentOptions={transferContentChoiceOptions}
        organizationManagers={getOrganizationManagers()}
        onSubmitHandler={onSubmitManageContentOwnership}
        onCloseHandler={onCloseDelete}
        formStatus={formStatus}
      />
      <ConfirmationDialog
        open={deleteGroup}
        title={t('administrateUsers.deleteGroup')}
        description={t('administrateUsers.confirmDeleteGroup')}
        ariaLabel={t('administrateUsers.deleteGroup')}
        cancelText={t('generic.cancel')}
        confirmText={t('administrateUsers.deleteGroup')}
        onCancel={onCloseDelete}
        onClose={onCloseDelete}
        onConfirm={onDeleteGroupConfirm}
        formStatus={formStatus}
      />
      <ConfirmationDialog
        open={deleteGroupMember}
        title={t('administrateUsers.deleteMemberFromGroup')}
        description={t('administrateUsers.confirmDeleteMemberFromGroup')}
        ariaLabel={t('administrateUsers.deleteMemberFromGroup')}
        cancelText={t('generic.cancel')}
        confirmText={t('generic.confirm')}
        onCancel={onCloseDelete}
        onClose={onCloseDelete}
        onConfirm={onDeleteGroupMemberConfirm}
        formStatus={formStatus}
      />
    </StyledBlock>
  );
};

export default Dialogs;

import React, { useState, useEffect } from 'react';
import { graphqlOperation } from '@aws-amplify/api';

import { courseStatus } from '@shared/const';

import CoursesView from '../../components/organisms/CoursesView';
import PageTemplate from '../../components/templates/PageTemplate';
import { useAppGlobals } from '../../components/contexts/AppContext';

import { graphql } from '../../utils';
import { listCourses, listFeaturedItems } from './queries';

const CoursesContainer = () => {
  const [courses, setCourses] = useState([]);
  const [nextToken, setNextToken] = useState();
  const [loadingCourses, setLoadingCourses] = useState(true);
  const [loadingFeatures, setLoadingFeatures] = useState(true);
  const [featuredItems, setFeaturedItems] = useState([]);

  const { domain } = useAppGlobals();

  const fetchCourses = async (nextToken) => {
    const params = {
      ownerOrganizationId: '',
      showOrganizationCourses: true,
      limit: 500,
      filter: {
        domain: {
          contains: domain,
        },
        status: {
          ne: courseStatus.ARCHIVED,
        },
      },
    };

    if (nextToken) {
      params.nextToken = nextToken;
    }

    const { data } = await graphql(graphqlOperation(listCourses, params));
    const { items = [], nextToken: newNextToken } = data.listCourses;
    setCourses([...courses, ...items]);
    setNextToken(newNextToken);

    if (!newNextToken) {
      setLoadingCourses(false);
    }
  };

  const fetchFeaturedItems = async () => {
    const { data } = await graphql(graphqlOperation(listFeaturedItems));
    setFeaturedItems(data?.listFeaturedItems);
    setLoadingFeatures(false);
  };

  const updateCourses = () => {
    fetchCourses();
    fetchFeaturedItems();
  };

  useEffect(() => {
    updateCourses();
  }, []);

  useEffect(() => {
    if (nextToken) {
      fetchCourses(nextToken);
    }
  }, [nextToken]);

  return (
    <PageTemplate>
      <CoursesView
        courses={courses}
        loading={loadingCourses || loadingFeatures}
        featuredItems={featuredItems}
      />
    </PageTemplate>
  );
};

export default CoursesContainer;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Alert from 'shared/atoms/Alert';
import Form from 'shared/atoms/Form';
import FormInput from 'shared/atoms/FormFields/FormInput';
import Dialog from 'shared/molecules/Dialog';
import theme from 'shared/themes/default';
import { LOCALES, PLAYLIST_TYPE, MAX_PLAYLIST } from 'shared/const';
import { useForm } from 'shared/utils';

import { usePlaylist } from '../../../context';

function CreateNewPlaylistDialog({ initialValues }) {
  const { t } = useTranslation();
  const {
    dialogs: {
      onClosePlaylistFormHandler: onCloseHandler,
      onCreatePlaylist,
      updatePlaylistHandler,
      formStatus,
    },
  } = usePlaylist();

  const onSave = initialValues.id ? updatePlaylistHandler : onCreatePlaylist;

  const onSubmitHandler = async (values) => {
    await onSave({
      ...values,
      id: initialValues.id,
      type: PLAYLIST_TYPE.PLAYLIST,
    });
  };

  const {
    disableSubmit,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    properties,
    setDefaults,
    submitting,
    values,
  } = useForm({
    changesRequired: true,
    fields: {
      name: {
        localised: true,
        maxLength: MAX_PLAYLIST.NAME_LENGTH,
        required: true,
      },
      description: {
        localised: true,
        maxLength: MAX_PLAYLIST.DESCRIPTION_LENGTH,
        required: true,
      },
    },
    locales: LOCALES,
    onSubmit: onSubmitHandler,
    requiredLocales: initialValues.locale || 'no',
  });

  const actions = [
    {
      ariaLabel: initialValues.id
        ? t('playlist.updatePlaylist')
        : t('playlist.createPlaylist'),
      disabled: disableSubmit,
      handler: handleSubmit,
      label: initialValues.id
        ? t('playlist.updatePlaylist')
        : t('playlist.createPlaylist'),
    },
  ];

  useEffect(() => {
    setDefaults({
      name: LOCALES.reduce((acc, curr) => {
        acc[curr] = initialValues[curr]?.name || '';
        return acc;
      }, {}),
      description: LOCALES.reduce((acc, curr) => {
        acc[curr] = initialValues[curr]?.description || '';
        return acc;
      }, {}),
    });
  }, [initialValues]);

  const sxInput = { mb: theme.spacing.m2 };

  return (
    <Dialog
      open
      onClose={onCloseHandler}
      ariaLabel="create-new-playlist-dialog"
      title={
        initialValues.id
          ? t('playlist.updatePlaylist')
          : t('playlist.newPlaylist')
      }
      actions={actions}
      loading={submitting}
      formStatus={formStatus}
    >
      {formStatus && (
        <Alert
          message={formStatus.message}
          severity={formStatus.success ? 'success' : 'error'}
          theme="light"
        />
      )}
      {!formStatus && (
        <Form onSubmit={handleSubmit}>
          <FormInput
            id="name"
            name="name"
            label={t('playlist.title')}
            errors={errors}
            locale={initialValues.locale || 'no'}
            handleBlur={handleBlur}
            handleChange={handleChange}
            properties={properties}
            sx={sxInput}
            showMaxLength
            theme="light"
            values={values}
          />
          <FormInput
            id="description"
            name="description"
            label={t('playlist.description')}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            locale={initialValues.locale || 'no'}
            maxRows={4}
            minRows={4}
            multiline
            properties={properties}
            sx={sxInput}
            theme="light"
            values={values}
          />
        </Form>
      )}
    </Dialog>
  );
}

CreateNewPlaylistDialog.propTypes = {
  initialValues: PropTypes.object,
};

CreateNewPlaylistDialog.defaultProps = {
  initialValues: {},
};

export default CreateNewPlaylistDialog;

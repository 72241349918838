export const validateCourseForArchiving = (id) => ({
  query: `query validateCourseForArchiving(
    $id: ID!
  ) {
    validateCourseForArchiving(
      id: $id
    ) {
      archiveDate
      nanodegrees {
        titleTranslations
      }
      numberOfPlaylists
      isGloballyFeatured
      isValidForArchiving
    }
  }`,
  variables: {
    id,
  },
});

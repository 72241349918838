import React from 'react';

import ManageOrCreateGroups from 'shared/organisms/ManageOrCreateGroups';

import { useOrganizationMembers } from '../context';

const ManageGroups = () => {
  const {
    isBackOffice,
    isManager,
    listGroupMembers,
    listView,
    onViewGroupActivity,
    registeredMembers,
    removeFromGroup,
    setManageGroupsOpen,
    user,
    groups: {
      createGroupFormStatus,
      handleOnSubmitGroupMembers,
      groupMembers,
      groupsInfo,
      loadingGroupMembers,
      loadingGroupsInfo,
      onCreateGroup,
      onDeleteGroup,
      onDeleteGroupMember,
      onSaveEditGroup,
      onTransferGroup,
      selectedGroupId,
      setCreateGroupFormStatus,
      setSelectedGroupId,
    },
  } = useOrganizationMembers();

  return (
    <ManageOrCreateGroups
      listGroupMembers={listGroupMembers}
      setManageGroupsOpen={() => setManageGroupsOpen(false)}
      groups={groupsInfo}
      loadingGroupsInfo={loadingGroupsInfo}
      onSubmitHandler={onCreateGroup}
      registeredMembers={registeredMembers}
      onSubmitGroupMembers={handleOnSubmitGroupMembers}
      onSaveEditGroup={onSaveEditGroup}
      onDeleteGroupMember={onDeleteGroupMember}
      onDeleteGroup={onDeleteGroup}
      selectedGroupId={selectedGroupId}
      setSelectedGroupId={setSelectedGroupId}
      loadingGroupMembers={loadingGroupMembers}
      groupMembers={groupMembers}
      removeFromGroup={removeFromGroup}
      createGroupFormStatus={createGroupFormStatus}
      userId={user?.attributes?.sub}
      setCreateGroupFormStatus={setCreateGroupFormStatus}
      listView={listView}
      isManager={isManager}
      isBackOffice={isBackOffice}
      onTransferGroup={onTransferGroup}
      onViewGroupActivity={onViewGroupActivity}
    />
  );
};

export default ManageGroups;

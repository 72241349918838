import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import AlertMui from '@mui/material/Alert';

import Button from 'shared/atoms/Button';
import Icon from 'shared/atoms/Icon';
import T from 'shared/atoms/Typography';
import { BACKGROUND_THEMES } from 'shared/const';
import theme from 'shared/themes/default';

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing.s};
  margin-left: calc(-${theme.spacing.s} - 1.375rem);
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${theme.spacing.s};
`;

const Alert = ({
  action,
  detailedErrors,
  message,
  severity,
  sx: sxAlert,
  theme: themeLayout,
}) => {
  const [expanded, setExpanded] = useState(!!detailedErrors?.length);

  const { t } = useTranslation();

  const sx = {
    borderRadius: '6px',
    padding: theme.spacing.s,
    '&.MuiAlert-outlinedError': {
      backgroundColor:
        themeLayout === 'light' ? theme.palette.errorTinted : 'transparent',
      borderColor:
        themeLayout === 'light'
          ? theme.palette.errorDark
          : theme.palette.errorLight,
      color:
        themeLayout === 'light'
          ? theme.palette.errorDark
          : theme.palette.errorLight,
    },
    '&.MuiAlert-outlinedSuccess': {
      backgroundColor:
        themeLayout === 'light' ? theme.palette.successTinted : 'transparent',
      borderColor:
        themeLayout === 'light'
          ? theme.palette.successDark
          : theme.palette.successLight,
      color:
        themeLayout === 'light'
          ? theme.palette.successDark
          : theme.palette.successLight,
    },
    '&.MuiAlert-outlinedWarning': {
      backgroundColor:
        themeLayout === 'light' ? theme.palette.warningTinted : 'transparent',
      borderColor: theme.palette.brand,
      color: theme.palette.brand,
      '.MuiAlert-icon': {
        color: themeLayout === 'light' ? theme.palette.mainDark : 'inherit',
      },
    },
    '.MuiAlert-action': {
      color:
        themeLayout === 'light'
          ? theme.palette.darkDistinct
          : theme.palette.errorTinted,
      fontSize: '1.375rem',
      marginRight: 0,
      padding: `0 0 0 ${theme.spacing.s}`,
    },
    '.MuiAlert-icon': {
      color: 'inherit',
      fontSize: '1.375rem',
      marginRight: theme.spacing.s,
      padding: 0,
      opacity: 1,
    },
    '.MuiAlert-message': {
      overflow: 'visible',
      padding: 0,
    },
    ...sxAlert,
  };

  const buttonSx = {
    cursor: 'pointer',
    fontSize: 'inherit',
  };

  return (
    <AlertMui
      action={
        detailedErrors &&
        (expanded ? (
          <ExpandLess onClick={() => setExpanded(false)} sx={buttonSx} />
        ) : (
          <ExpandMore onClick={() => setExpanded(true)} sx={buttonSx} />
        ))
      }
      iconMapping={{
        error: <Icon icon={ErrorOutlineIcon} size="inherit" />,
        success: <Icon icon={CheckCircleIcon} size="inherit" />,
        warning: <Icon icon={ErrorOutlineIcon} size="inherit" />,
      }}
      severity={severity}
      sx={sx}
      variant="outlined"
    >
      <T
        color={themeLayout === 'light' ? theme.palette.mainDark : 'inherit'}
        fontWeight="semibold"
      >
        {`${message}${
          detailedErrors
            ? ` (${t('generic.errorCount', { count: detailedErrors.length })})`
            : ''
        }`}
      </T>
      {expanded && detailedErrors && (
        <DetailsWrapper>
          {detailedErrors.map(({ title, description }) => (
            <T
              color={
                themeLayout === 'light' ? theme.palette.mainDark : 'inherit'
              }
              key={title}
            >
              <strong>{title}:</strong> {description}
            </T>
          ))}
        </DetailsWrapper>
      )}
      {action && (
        <ButtonWrapper>
          <Button
            onClick={action.handler}
            small
            sx={{
              color: theme.palette.errorDark,
              boxShadow: `inset 0 0 0 1px ${theme.palette.errorDark}`,
              '&:hover': {
                color: theme.palette.mainDark,
                backgroundColor: theme.palette.errorDark,
              },
            }}
            theme="dark"
            type="secondaryOutline"
          >
            {action.label}
          </Button>
        </ButtonWrapper>
      )}
    </AlertMui>
  );
};

Alert.propTypes = {
  action: PropTypes.shape({
    handler: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
  }),
  detailedErrors: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ),
  message: PropTypes.string.isRequired,
  severity: PropTypes.oneOf(['success', 'warning', 'error']).isRequired,
  sx: PropTypes.object,
  theme: PropTypes.oneOf(BACKGROUND_THEMES),
};

Alert.defaultProps = {
  sx: {},
  theme: 'dark',
};

export default Alert;

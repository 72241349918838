/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import FileUploadIcon from '@mui/icons-material/FileUploadOutlined';

import Icon from 'shared/atoms/Icon';
import ProgressBar from 'shared/atoms/ProgressBar';
import T from 'shared/atoms/Typography';

import theme from 'shared/themes/default';
import CircularSpinner from 'shared/atoms/CircularSpinner';
import IconButton from 'shared/atoms/IconButton';
import Info from 'shared/molecules/Info';

import { BACKGROUND_THEMES } from 'shared/const';

const Wrapper = styled.div`
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: ${(props) => (props.small ? 'unset' : 'auto')};
  ::-webkit-scrollbar {
    width: 10px;
    height: 30px;
  }
  ::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    background-color: ${(props) =>
      props.theme === 'light'
        ? theme.palette.darkDistinct
        : theme.palette.disabledLight};
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track: {
    background: transparent;
  }
`;

const DropZone = styled.div`
  padding: ${(props) => (props.small ? 0 : theme.spacing.m1)};
  height: 100%;
  position: relative;
`;

const FileInput = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${({ disabled }) =>
    !disabled &&
    `
    cursor: pointer;
  `}
`;

const FileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing.m2};
  margin: ${theme.spacing.s} ${theme.spacing.m1};
`;

const FileNameWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FileInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Upload = ({
  chunkSize,
  completeUpload,
  debug,
  deleteFiles,
  disabled,
  description,
  getUploadUrl,
  maxFiles,
  onCompleteUpload,
  onDeleteFile,
  onStartUpload,
  returnFiles,
  small,
  startUpload,
  theme: themeLayout,
  validate,
}) => {
  const [files, setFiles] = useState();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dragging, setDragging] = useState(false);

  const filesRef = useRef();

  useEffect(() => {
    if (uploadedFiles.length > 0) {
      let validatedFiles = files;
      if (validate && files && Object.values(files).length > 0) {
        validatedFiles = validate(files);
        setFiles(validatedFiles);
      }

      onCompleteUpload(
        uploadedFiles.filter(
          ({ id }) => !files[id].aborted && !validatedFiles[id].error
        )
      );
    }
  }, [uploadedFiles]);

  useEffect(() => {
    filesRef.current = files;
  }, [files]);

  useEffect(() => {
    return () => {
      const filesToAbort = filesRef?.current;
      if (filesToAbort) {
        Object.values(filesToAbort).forEach((file) => {
          file.controller.abort();
        });
      }
    };
  }, []);

  // update progress for chunk, and caclulate total progress
  const onProgress = (uploadId, chunkId, progress) => {
    setFiles((prevFiles) => {
      const { chunks } = prevFiles[uploadId];
      chunks[chunkId] = progress;
      return {
        ...prevFiles,
        [uploadId]: {
          ...prevFiles[uploadId],
          progress: chunks.reduce((acc, curr) => acc + curr, 0) / chunks.length,
          chunks,
        },
      };
    });
  };

  const getBase64 = (file) =>
    new Promise((accept, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        accept(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });

  const onUpload = async (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    if (maxFiles && event.target.files.length > maxFiles) {
      // TODO handle error
      return;
    }

    if (onStartUpload) {
      onStartUpload();
    }
    setLoading(true);
    const resolvedUploadedFiles = await Promise.all(
      Array.from(event.target.files).map(async (file) => {
        const { data: dataStart, error } = await startUpload({
          fileName: file.name,
          contentType: file.type,
        });
        if (error) return;
        const { fileName, id: uploadId } = dataStart.startUpload;
        setLoading(false);

        const chunksCount = Math.ceil(file.size / chunkSize);

        const controller = new AbortController();

        setFiles((prevFiles) => ({
          ...prevFiles,
          [uploadId]: {
            name: file.name,
            progress: 0,
            chunks: new Array(chunksCount).fill(0),
            controller,
          },
        }));

        const blobs = [];
        for (let i = 0; i < chunksCount; i += 1) {
          blobs.push(file.slice(i * chunkSize, (i + 1) * chunkSize));
        }

        const uploadParts = await Promise.all(
          blobs.map(async (blob, index) => {
            const { data, error } = await getUploadUrl({
              fileName,
              partNumber: index + 1,
              uploadId,
            });
            if (error) return { error };
            return { blob, url: data.getUploadUrl };
          })
        );

        // Abort if any of the calls failed
        if (uploadParts.some((part) => part.error)) return;

        const resolvedUploadPromises = await Promise.all(
          uploadParts.map(async ({ blob, url }, index) => {
            return axios
              .put(url, blob, {
                headers: {
                  'Content-Type': file.type,
                },
                signal: controller.signal,
                onUploadProgress: (event) =>
                  onProgress(uploadId, index, event.loaded / event.total),
              })
              .catch(() => {
                // TODO: handle failures when uploading
                controller.abort(); // cancel all requests if one fails
              });
          })
        );

        // If any of the parts fail we exit
        if (
          resolvedUploadPromises.some((resolvedPromise) => !resolvedPromise) &&
          !debug
        )
          return;

        const resolvedUploadParts = !debug
          ? resolvedUploadPromises.map((resolvedPromise, index) => ({
              etag: resolvedPromise.headers.etag,
              partNumber: index + 1,
            }))
          : [];

        const timer = setInterval(() => {
          setFiles((prevFiles) => {
            return {
              ...prevFiles,
              [uploadId]: {
                ...prevFiles[uploadId],
                completeProgress:
                  (prevFiles[uploadId].completeProgress || 0) + 1,
              },
            };
          });
        }, 500);

        const { data } = await completeUpload({
          fileName,
          parts: resolvedUploadParts,
          uploadId,
        });

        let base64;

        if (returnFiles) {
          base64 = await getBase64(file);
        }

        clearTimeout(timer);

        setFiles((prevFiles) => {
          return {
            ...prevFiles,
            [uploadId]: {
              ...prevFiles[uploadId],
              complete: true,
            },
          };
        });

        return {
          id: uploadId,
          name: file.name,
          base64,
          s3File: data.completeUpload,
        };
      })
    );

    const successfullyUploadedFiles = resolvedUploadedFiles.filter(
      (file) => file
    );
    if (successfullyUploadedFiles?.length) {
      setUploadedFiles(successfullyUploadedFiles);
      if (deleteFiles) {
        setFiles([]);
      }
    }
  };

  const onAbortUpload = (uploadId) => {
    files[uploadId].controller.abort();
    setFiles((prevFiles) => ({
      ...prevFiles,
      [uploadId]: {
        ...prevFiles[uploadId],
        aborted: true,
      },
    }));
  };

  const onRemoveFile = (uploadId) => {
    const filenameToRemove = files[uploadId].name;
    const newFiles = {
      ...files,
      [uploadId]: {
        ...files[uploadId],
        aborted: true,
      },
    };
    const validatedFiles = validate(newFiles);
    setFiles(validatedFiles);
    if (onDeleteFile) {
      onDeleteFile(filenameToRemove);
    }
  };

  const { t } = useTranslation();

  let colorDragging;
  let colorText;
  if (disabled) {
    colorDragging = theme.palette.disabledDark;
    colorText = theme.palette.disabledDark;
  } else {
    colorText =
      themeLayout === 'light'
        ? theme.palette.pureDark
        : theme.palette.lightPure;
    colorDragging = dragging ? theme.palette.brand : colorText;
  }

  const sortedKeys = Object.keys(files || {}).sort((a, b) => {
    const aName = files[a].name.toLowerCase();
    const bName = files[b].name.toLowerCase();
    const aError = files[a].error;
    const bError = files[b].error;

    if (aError && !bError) {
      return -1;
    }
    if (!aError && bError) {
      return 1;
    }

    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  });

  return (
    <Wrapper
      borderColor={colorDragging}
      onDragEnter={() => setDragging(true)}
      onDragLeave={() => setDragging(false)}
      onDrop={() => setDragging(false)}
      small={small}
    >
      <div>
        {sortedKeys?.map(
          (key) =>
            !files[key].aborted && (
              <FileWrapper key={key}>
                <FileNameWrapper>
                  {!small && (
                    <T
                      color={
                        files[key].error ? theme.palette.errorDark : colorText
                      }
                      fontWeight="medium"
                      variant="helperTextS"
                    >
                      {files[key].name}
                    </T>
                  )}
                  {!files[key].complete && (
                    <ProgressBar
                      small
                      progress={
                        files[key].progress * 95 +
                        Math.round(
                          (1 -
                            Math.exp(-1 * (files[key].completeProgress || 0))) *
                            5
                        )
                      }
                    />
                  )}
                </FileNameWrapper>
                {files[key].error && (
                  <Info theme="dark">{files[key].error}</Info>
                )}
                {files[key].progress < 1 && !small && (
                  <IconButton
                    ariaLabel={t('courses.upload.removeFile')}
                    icon={CloseIcon}
                    onClick={() => onAbortUpload(key)}
                    small
                    theme={themeLayout}
                    type="transparent"
                  />
                )}
                {files[key].progress === 1 && !small && (
                  <IconButton
                    ariaLabel={t('courses.upload.removeFile')}
                    icon={CloseIcon}
                    onClick={() => onRemoveFile(key)}
                    small
                    type="transparent"
                  />
                )}
              </FileWrapper>
            )
        )}
        {loading && <CircularSpinner size={30} thickness={3} static />}
      </div>
      <DropZone small={small}>
        <FileInput
          disabled={disabled}
          multiple
          onChange={onUpload}
          type="file"
          value=""
        />
        <FileInfo>
          {description &&
            description.map((text, index) => (
              <T
                key={`description${index}`}
                color={
                  disabled
                    ? theme.palette.disabledDark
                    : theme.palette.disabledLight
                }
                fontWeight="medium"
                variant="helperTextS"
              >
                {text}
              </T>
            ))}
          <T
            color={colorDragging}
            fontWeight="medium"
            sx={{ margin: `${theme.spacing.s} 0` }}
            variant={dragging ? 'paragraph' : 'helperTextS'}
          >
            {t('courses.upload.dragAndDrop')}
          </T>
          <Icon
            icon={FileUploadIcon}
            color={colorDragging}
            size={dragging ? theme.spacing.l : theme.spacing.m1}
          />
        </FileInfo>
      </DropZone>
    </Wrapper>
  );
};

Upload.propTypes = {
  chunkSize: PropTypes.number,
  completeUpload: PropTypes.func.isRequired,
  debug: PropTypes.bool,
  deleteFiles: PropTypes.bool,
  description: PropTypes.array,
  disabled: PropTypes.bool,
  getUploadUrl: PropTypes.func.isRequired,
  maxFiles: PropTypes.number,
  onCompleteUpload: PropTypes.func.isRequired,
  onDeleteFile: PropTypes.func,
  onStartUpload: PropTypes.func,
  returnFiles: PropTypes.bool,
  small: PropTypes.bool,
  startUpload: PropTypes.func.isRequired,
  theme: PropTypes.oneOf(BACKGROUND_THEMES),
  validate: PropTypes.func,
};

Upload.defaultProps = {
  chunkSize: 1024 * 1024 * 1024, // 1GB
  debug: false,
  deleteFiles: false,
  returnFiles: false,
  small: false,
  theme: 'dark',
};

export default Upload;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import Input from 'shared/atoms/Input';
import { BACKGROUND_THEMES } from 'shared/const';
import theme from 'shared/themes/default';

import FormLabel from '../components/FormLabel';

const FormInput = ({
  disabled: disabledProp,
  errors,
  handleBlur,
  handleChange,
  id: idProp,
  infoText,
  label,
  locale,
  name: nameProp,
  properties,
  required: requiredProp,
  showMaxLength,
  sx,
  theme: themeLayout,
  value: valueProp, // TODO: Only used for forms that cannot use useForm yet - to be removed
  values,
  maskValue,
  ...props
}) => {
  const id = locale ? `${idProp}#${locale}` : idProp;
  const name = locale ? `${nameProp}#${locale}` : nameProp;

  const [charCount, setCharCount] = useState(0);

  useEffect(() => {
    setCharCount(values[name]?.length || valueProp?.length || 0);
  }, [name, values, valueProp]);

  const { disabled, maxLength, mode, pattern, required, type } =
    properties[name] || {};

  const onBlurHandler = ({ target: { name } }) => {
    handleBlur({ name });
  };

  const onChangeHandler = ({ target: { name, value } }) => {
    handleChange({ name, value });
    setCharCount(value.length);
  };

  const sxHelperText = {
    fontSize: '0.875rem',
    lineHeight: '140%',
    fontWeight: 500,
    margin: `${theme.spacing.xs} 0 0 0`,
    '&.Mui-error': {
      color:
        themeLayout === 'light'
          ? theme.palette.errorDark
          : theme.palette.errorLight,
    },
  };

  return (
    <FormControl
      disabled={disabledProp === undefined ? disabled : disabledProp}
      error={!!errors[name]}
      fullWidth
      required={requiredProp === undefined ? required : requiredProp}
      sx={sx}
    >
      <FormLabel
        id={id}
        infoText={infoText}
        label={label}
        length={charCount}
        maxLength={showMaxLength && maxLength}
        theme={themeLayout}
      />
      <Input
        id={id}
        inputMode={mode}
        inputType={type}
        maxLength={maxLength}
        name={name}
        onBlur={onBlurHandler}
        onChange={onChangeHandler}
        pattern={pattern}
        theme={themeLayout}
        value={name in values ? values[name] : valueProp}
        data-matomo-mask={maskValue}
        {...props}
      />
      {errors[name] && (
        <FormHelperText sx={sxHelperText}>{errors[name]}</FormHelperText>
      )}
    </FormControl>
  );
};

FormInput.propTypes = {
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  id: PropTypes.string.isRequired,
  infoText: PropTypes.string,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  locale: PropTypes.string,
  name: PropTypes.string.isRequired,
  properties: PropTypes.object,
  required: PropTypes.bool,
  showMaxLength: PropTypes.bool,
  sx: PropTypes.object,
  theme: PropTypes.oneOf(BACKGROUND_THEMES),
  value: PropTypes.string,
  values: PropTypes.object,
  maskValue: PropTypes.bool,
};

FormInput.defaultProps = {
  errors: {},
  handleBlur: () => {},
  handleChange: () => {},
  properties: {},
  sx: {},
  theme: 'dark',
  values: {},
  maskValue: false,
};

export default FormInput;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Radio from 'shared/atoms/Radio';
import Input from 'shared/atoms/Input';

import theme from 'shared/themes/default';

const AnswerRadioStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: ${theme.spacing.s};
`;

const AnswerRadio = ({
  answer,
  disabled,
  index,
  name,
  onChangeAnswer,
  onChangeSelected,
  selected,
  theme: themeLayout,
}) => {
  let selectedColor;

  if (themeLayout === 'light') {
    selectedColor = theme.palette.mainDark;
  } else {
    selectedColor = theme.palette.lightPure;
  }

  const sxRadio = {
    padding: theme.spacing.xs,
    color:
      themeLayout === 'dark' ? theme.palette.lightPure : theme.palette.mainDark,
    '&.Mui-checked': {
      color: selectedColor,
    },
    '&.Mui-disabled': {
      color:
        themeLayout === 'dark'
          ? theme.palette.disabledDark
          : theme.palette.disabledLight,
    },
    '&:hover, &:focus, &:active': {
      color:
        themeLayout === 'dark' ? theme.palette.brand : theme.palette.mainDark,
    },
  };
  return (
    <AnswerRadioStyled>
      <Radio
        sx={sxRadio}
        name={name}
        value={index}
        checked={selected}
        onChange={() => onChangeSelected(index)}
        disabled={disabled}
      />
      <Input
        fullWidth
        maxRows={4}
        multiline
        onChange={(event) => onChangeAnswer(index, event.target.value)}
        sx={{ marginLeft: '42px' /* Fix width to align with the top label */ }}
        value={answer}
        disabled={disabled}
      />
    </AnswerRadioStyled>
  );
};

AnswerRadio.propTypes = {
  disabled: PropTypes.bool,
  theme: PropTypes.string,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  onChangeAnswer: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  onChangeSelected: PropTypes.func.isRequired,
};

AnswerRadio.defaultProps = {
  theme: 'light',
};

export default AnswerRadio;

import React from 'react';
import PropTypes from 'prop-types';
import MuiTableRow from '@mui/material/TableRow';
import theme from 'shared/themes/default';

const TableRow = ({ children, isMobile, onClick }) => {
  const sxTableRow = {
    '&.MuiTableRow-root': {
      margin: '0',
      backgroundColor: theme.palette.oddDark,
      '&:nth-last-child(1)': {
        border: 'none',
      },
    },
  };

  if (onClick) {
    sxTableRow['&.MuiTableRow-root'] = {
      ...sxTableRow['&.MuiTableRow-root'],
      cursor: 'pointer',
    };
    sxTableRow['&.MuiTableRow-root:hover'] = {
      opacity: !isMobile ? '.90' : 'inherit',
      '&>td': {
        color: theme.palette.brand,
      },
    };
    sxTableRow['&.MuiTableRow-root:active'] = {
      opacity: !isMobile ? '.70' : 'inherit',
    };
  }

  return (
    <MuiTableRow onClick={onClick} sx={sxTableRow}>
      {children}
    </MuiTableRow>
  );
};

TableRow.propTypes = {
  children: PropTypes.node.isRequired,
  isMobile: PropTypes.bool,
  onClick: PropTypes.func,
};

TableRow.defaultProps = {
  isMobile: false,
};

export default TableRow;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import T from 'shared/atoms/Typography';
import TableWithSearchAndExport from 'shared/organisms/WatchData/TableWithSearchAndExport';
import OrganizationGroupsStatsTable from 'shared/organisms/WatchData/OrganizationGroupsStatsTable';
import UsersWatchDataTable from 'shared/organisms/WatchData/UsersWatchDataTable';
import EpisodesSeenGraph from 'shared/organisms/WatchData/EpisodesSeenGraph';
import theme from 'shared/themes/default';
import { TYPE_WATCH_DATA } from 'shared/const';
import Tooltip from 'shared/atoms/Tooltip';
import Icon from 'shared/atoms/Icon';
import Pill from 'shared/atoms/Pill';
import { mapAggregatedWatchDataGraph, callApi } from '../utils';
import { useWatchData } from '../Context';

const Wrapper = styled.div`
  max-width: 100%;
`;

const WrapperPill = styled.div`
  display: flex;
  margin-top: 108px;
  ${theme.breakpoints.mobile} {
    margin-top: 64px;
  }
`;

const StyledIconWrapper = styled.div`
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
`;

const ShowGroupsData = ({ isManager, id, onSelectItem }) => {
  const [aggregatedWatchData, setAggregatedWatchData] = useState([]);
  const [loadingAggregated, setLoadingAggregated] = useState(false);
  const { t, i18n } = useTranslation();
  const { language: locale } = i18n;
  const {
    fetchGroups,
    organizationGroupsStats,
    loadingGroups,
    user,
    listGroupUsersWatchData,
    downloadWatchData,
    groupSelected,
    setGroupSelected,
    organizationSelectedId,
    listAggregatedWatchData,
    setWatchDataError,
  } = useWatchData();
  const [groupUsersWatchData, setGroupUsersWatchData] = useState([]);
  const [loadingGroupUsersWatchData, setLoadingGroupUsersWatchData] =
    useState(false);

  const handleError = (error) => {
    console.error('Error calling listGroupUsersWatchData', error);
    setLoadingGroupUsersWatchData(false);
    setWatchDataError(true);
  };

  const fetchGroupUsersWatchData = async () => {
    try {
      const { data, error } = await listGroupUsersWatchData(groupSelected.id);
      if (error) return handleError(error);
      if (data) {
        const { items } = data.listGroupUsersWatchData;
        setGroupUsersWatchData(items);
        setLoadingGroupUsersWatchData(false);
      }
    } catch (error) {
      handleError(error);
    }
    return true;
  };

  useEffect(() => {
    if (organizationSelectedId) {
      setGroupSelected(null);
    }

    fetchGroups();
  }, [organizationSelectedId]);

  useEffect(() => {
    const params = { organizationId: organizationSelectedId };
    let type = TYPE_WATCH_DATA.GROUPS;
    if (groupSelected) {
      type = TYPE_WATCH_DATA.GROUP;
      params.groupId = groupSelected.id;
    }
    callApi({
      setLoading: setLoadingAggregated,
      setData: setAggregatedWatchData,
      apiMethod: listAggregatedWatchData,
      setError: setWatchDataError,
      type,
      params,
    });
  }, [organizationSelectedId, groupSelected]);

  useEffect(() => {
    if (id && !loadingGroups) {
      const group = organizationGroupsStats.find((item) => item.id === id);
      if (group) {
        setGroupSelected(group);
      } else {
        onSelectItem();
      }
    }
  }, [organizationGroupsStats, loadingGroups]);

  useEffect(() => {
    if (groupSelected) {
      setLoadingGroupUsersWatchData(true);
      fetchGroupUsersWatchData();
      onSelectItem(groupSelected.id);
    }
  }, [groupSelected]);

  return (
    <Wrapper>
      <WrapperPill>
        <Pill
          color={theme.palette.lightPure}
          fontColor={theme.palette.mainDark}
        >
          {t('administrateUsers.groups').toUpperCase()}
        </Pill>
        <Tooltip title={t('watchData.titleTooltip')} arrow>
          <StyledIconWrapper>
            <Icon
              color={theme.palette.lightPure}
              colorHover={theme.palette.brand}
              icon={InfoOutlinedIcon}
              size={theme.spacing.m2}
              sx={{
                cursor: 'pointer',
              }}
            />
          </StyledIconWrapper>
        </Tooltip>
      </WrapperPill>
      <T
        color={theme.palette.brand}
        fontWeight="medium"
        phoneVariant="headingXs"
        sx={{
          marginBottom: theme.spacing.m3,
          marginTop: theme.spacing.m2,
        }}
        variant="headingS"
      >
        {groupSelected ? groupSelected.name : t('administrateUsers.allGroups')}
      </T>
      <EpisodesSeenGraph
        data={mapAggregatedWatchDataGraph(aggregatedWatchData, locale)}
        loading={loadingAggregated}
      />
      {!groupSelected && (
        <TableWithSearchAndExport
          itemsToDisplay={organizationGroupsStats}
          downloadItems={() =>
            downloadWatchData({ watchData: TYPE_WATCH_DATA.GROUPS })
          }
          user={user}
          tableTitle={t('watchData.activitiesOfYourGroups')}
          searchPlaceHolder={t('watchData.searchForGroup')}
          TableToDisplay={OrganizationGroupsStatsTable}
          tableProps={{ isManager, selectGroupsHandler: setGroupSelected }}
          showRowsAreClickableMessage
          loading={loadingGroups}
          spinnerDuration={15}
          emptyMessage={t('watchData.noActivity')}
        />
      )}
      {groupSelected && (
        <TableWithSearchAndExport
          itemsToDisplay={groupUsersWatchData}
          downloadItems={() =>
            downloadWatchData({
              watchData: TYPE_WATCH_DATA.GROUP,
              groupId: groupSelected.id,
            })
          }
          user={user}
          tableTitle={t('watchData.activityOfYourUsers')}
          searchPlaceHolder={t('watchData.searchForUsers')}
          TableToDisplay={UsersWatchDataTable}
          showRowsAreClickableMessage
          emptyMessage={t('watchData.noGroupActivity')}
          loading={loadingGroupUsersWatchData}
        />
      )}
    </Wrapper>
  );
};

ShowGroupsData.propTypes = {
  id: PropTypes.string,
  isManager: PropTypes.bool,
  onSelectItem: PropTypes.func.isRequired,
};

export default ShowGroupsData;

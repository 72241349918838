import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import CircularSpinner from 'shared/atoms/CircularSpinner';
import Icon from 'shared/atoms/Icon';

import { selectTranslation } from 'shared/utils/selectTranslation';
import theme from 'shared/themes/default';

const sharedStyles = {
  fontSize: '0.875rem',
  fontWeight: '600',
  lineHeight: '20px',
};

const sxListItemText = {
  '& .MuiListItemText-primary': {
    ...sharedStyles,
    color: theme.palette.disabledDark,
  },
  '& .MuiListItemText-secondary': {
    ...sharedStyles,
    color: theme.palette.pureDark,
  },
};

const getPalette = (completed, completedEpisodes) => {
  if (completed) return theme.palette.successDark;
  if (completedEpisodes === 0) return theme.palette.errorDark;
  return theme.palette.warning;
};

const PlaylistActivityList = ({ items, loading, locale, onHoverProgress }) => {
  const { t } = useTranslation();

  useEffect(() => {
    onHoverProgress && onHoverProgress();
  }, []);

  if (loading) return <CircularSpinner static thickness={2} size={25} />;
  return (
    <List component="ul" aria-label="playlist activity list">
      {items.map((item, index) => {
        const isFirstElement = index === 0;
        const {
          id: courseId,
          name,
          nameTranslations,
          episodesCompleted,
          episodesCount,
          completedTime,
        } = item;
        const courseName = selectTranslation(
          nameTranslations,
          name,
          locale,
          ''
        );
        const secondary = t('watchData.completedEpisodesOutOf', {
          episodesCompleted,
          episodesCount,
        });
        return (
          <React.Fragment key={courseId}>
            {!isFirstElement && <Divider light />}
            <ListItem>
              <ListItemIcon>
                <Icon
                  icon={CheckCircleIcon}
                  color={getPalette(!!completedTime, episodesCompleted)}
                />
              </ListItemIcon>
              <ListItemText
                primary={courseName}
                secondary={secondary}
                sx={sxListItemText}
              />
            </ListItem>
          </React.Fragment>
        );
      })}
    </List>
  );
};

PlaylistActivityList.propTypes = {
  items: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  onHoverProgress: PropTypes.func,
};

PlaylistActivityList.defaultProps = {
  loading: false,
};

export default PlaylistActivityList;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';

import Dialog from 'shared/molecules/Dialog';
import CircularSpinner from 'shared/atoms/CircularSpinner';
import SearchField from 'shared/atoms/SearchField';
import Alert from 'shared/atoms/Alert';
import T from 'shared/atoms/Typography';
import theme from 'shared/themes/default';

import CopyPlaylistPanel from './CopyPlaylistPanel';

const SideSpaceWrapper = styled.div`
  padding: 0 1rem;
`;

const Spacer = styled.div`
  height: ${theme.spacing.m1};
`;

const StyledCopy = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-left: 0.5rem;
  padding-right: 1rem;
`;

const HeaderBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

function SelectExistingPlaylist({
  existingPlaylists,
  setExpandedPlaylist,
  locale,
  playlistCourses,
  readOnlyCopy,
  makeReadOnlyHandler,
  handleSubmit,
  onClose,
  formStatus,
}) {
  const [playlistSearched, setPlaylistSearched] = useState(null);
  const [playlistSelected, setPlaylistSelected] = useState(null);
  const [searchActivated, setSearchActivated] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [submitting, setSubmitting] = useState();

  const { t } = useTranslation();

  function onSubmit() {
    if (!playlistSelected) {
      return false;
    }
    setSubmitting(true);
    handleSubmit(playlistSelected);
    return true;
  }
  useEffect(() => {
    setSubmitting(false);
  }, [formStatus]);

  const onSearchHandler = ({ value }) => {
    setSearchActivated(true);
    setSearchTerm(value);
    setPlaylistSearched(
      existingPlaylists.filter((playlist) =>
        JSON.parse(playlist.titleTranslations)
          [locale].toLowerCase()
          .includes(value.toLowerCase())
      )
    );
  };

  const actions = [
    {
      disabled: formStatus || !playlistSelected,
      handler: onSubmit,
      label: t('playlist.savePlaylistText'),
    },
  ];

  return (
    <Dialog
      open
      onClose={onClose}
      ariaLabel="select-existing-playlist-dialog"
      title={t('playlist.copyPlaylistTitle')}
      loading={submitting}
      actions={actions}
      contentPadding="0"
      formStatus={formStatus}
      scrollType="body"
    >
      {formStatus && (
        <SideSpaceWrapper>
          <Alert
            message={formStatus.message}
            severity={formStatus.success ? 'success' : 'error'}
            theme="light"
          />
        </SideSpaceWrapper>
      )}
      {!formStatus &&
        (!existingPlaylists ? (
          <CircularSpinner />
        ) : (
          <StyledInnerWrapper>
            <SideSpaceWrapper>
              <SearchField
                theme="light"
                placeholder={t('playlist.searchPlaylistHolder')}
                value={searchTerm}
                onClear={() => setSearchTerm('')}
                ariaLabel={t('playlist.searchPlaylistHolder')}
                onChange={onSearchHandler}
              />
            </SideSpaceWrapper>
            <Spacer />
            <CopyPlaylistPanel
              setExpandedPlaylist={setExpandedPlaylist}
              playlistCourses={playlistCourses}
              setPlaylistSelected={setPlaylistSelected}
              locale={locale}
              existingPlaylists={
                searchActivated && searchTerm !== ''
                  ? playlistSearched
                  : existingPlaylists
              }
            />
            <Spacer />
            <StyledCopy>
              <Checkbox
                color="default"
                onChange={makeReadOnlyHandler}
                checked={readOnlyCopy}
              />
              <HeaderBlock>
                <T
                  color={theme.palette.mainDark}
                  fontWeight="bold"
                  variant="paragraphS"
                >
                  {t('playlist.makeCopyText')}
                </T>
                <T color={theme.palette.mainDark} variant="helperText">
                  {t('playlist.subtitleText')}
                </T>
              </HeaderBlock>
            </StyledCopy>
          </StyledInnerWrapper>
        ))}
    </Dialog>
  );
}

SelectExistingPlaylist.propTypes = {
  existingPlaylists: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  formStatus: PropTypes.object,
  playlistCourses: PropTypes.array,
  makeReadOnlyHandler: PropTypes.func,
  readOnlyCopy: PropTypes.bool,
  locale: PropTypes.string,
  setExpandedPlaylist: PropTypes.func,
};

export default SelectExistingPlaylist;

import React from 'react';
import PropTypes from 'prop-types';

import MuiChip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';

import { BACKGROUND_THEMES } from 'shared/const';
import theme from 'shared/themes/default';

import { CHIP_TYPE_ENUM } from './const';

const Chip = ({
  color,
  background,
  icon,
  label,
  onDelete,
  small,
  sx,
  theme: themeLayout,
  type,
}) => {
  let sxStyle;
  switch (type) {
    case 'secondary':
      sxStyle = {
        backgroundColor:
          background ||
          (themeLayout === 'dark'
            ? theme.palette.darkDistinct
            : theme.palette.lightDistinct),
        borderColor:
          color ||
          (themeLayout === 'dark'
            ? theme.palette.lightInteracting
            : theme.palette.darkDistinct),
        color:
          color ||
          (themeLayout === 'dark'
            ? theme.palette.lightInteracting
            : theme.palette.darkDistinct),
        fontWeight: 400,
        '& .MuiChip-icon': {
          color:
            color ||
            (themeLayout === 'dark'
              ? theme.palette.lightInteracting
              : theme.palette.darkDistinct),
        },
        '& .MuiChip-deleteIcon': {
          color:
            themeLayout === 'dark'
              ? theme.palette.lightInteracting
              : theme.palette.darkDistinct,
          '&:hover': {
            color:
              themeLayout === 'dark'
                ? theme.palette.disabledLight
                : theme.palette.disabledDark,
          },
        },
      };
      break;
    case 'primary':
    default:
      sxStyle = {
        backgroundColor:
          background ||
          (themeLayout === 'dark'
            ? theme.palette.darkWrapper
            : theme.palette.lightPure),
        borderColor:
          color ||
          (themeLayout === 'dark'
            ? theme.palette.lightPure
            : theme.palette.mainDark),
        color:
          color ||
          (themeLayout === 'dark'
            ? theme.palette.lightPure
            : theme.palette.mainDark),
        fontWeight: 600,
        '& .MuiChip-icon': {
          color:
            color ||
            (themeLayout === 'dark'
              ? theme.palette.lightPure
              : theme.palette.mainDark),
        },
        '& .MuiChip-deleteIcon': {
          color:
            themeLayout === 'dark'
              ? theme.palette.lightPure
              : theme.palette.mainDark,
          '&:hover': {
            color:
              themeLayout === 'dark'
                ? theme.palette.disabledLight
                : theme.palette.disabledDark,
          },
        },
      };
      break;
  }

  const sxEmptyLabel = {
    ...(label
      ? {}
      : {
          '& .MuiChip-label': {
            display: 'none',
          },
        }),
  };

  const sxChip = {
    '&.MuiChip-sizeMedium': {
      borderRadius: '1.25rem',
      fontSize: '0.875rem',
      height: '2.5rem',
    },
    '&.MuiChip-sizeSmall': {
      borderRadius: '0.5rem',
      fontSize: '0.625rem',
      height: '1rem',
    },
    '& .MuiChip-labelMedium': {
      padding: `0 ${theme.spacing.m1}`,
    },
    '& .MuiChip-labelSmall': {
      padding: `0 ${theme.spacing.s}`,
    },

    '& .MuiChip-iconMedium': {
      marginRight: `-${theme.spacing.s}`,
      marginLeft: theme.spacing.s,
    },
    '& .MuiChip-iconSmall': {
      fontSize: '0.625rem',
      marginRight: `-${theme.spacing.xs}`,
      marginLeft: theme.spacing.xs,
    },
    '& .MuiChip-deleteIconMedium': {
      marginRight: theme.spacing.s,
      marginLeft: `-${theme.spacing.s}`,
    },
    '& .MuiChip-deleteIconSmall': {
      fontSize: '0.625rem',
      marginRight: theme.spacing.xs,
      marginLeft: `-${theme.spacing.xs}`,
    },
    ...sxStyle,
    ...sx,
    ...sxEmptyLabel,
  };

  return (
    <MuiChip
      deleteIcon={<CloseIcon />}
      icon={icon}
      label={label}
      onDelete={onDelete}
      size={small ? 'small' : 'medium'}
      sx={sxChip}
      variant="outlined"
    />
  );
};

Chip.propTypes = {
  color: PropTypes.oneOf(Object.values(theme.palette)),
  background: PropTypes.oneOf(Object.values(theme.palette)),
  icon: PropTypes.node,
  label: PropTypes.string,
  onDelete: PropTypes.func,
  small: PropTypes.bool,
  sx: PropTypes.object,
  theme: PropTypes.oneOf(BACKGROUND_THEMES),
  type: PropTypes.oneOf(CHIP_TYPE_ENUM),
};

Chip.defaultProps = {
  small: false,
  sx: {},
  theme: 'light',
  type: 'primary',
};

export default Chip;

import styled from 'styled-components';
import theme from 'shared/themes/default';

export const OuterWrapper = styled.div`
  margin-top: 20px;
`;

export const GraphOuterWrapper = styled.div`
  background-color: ${theme.palette.darkWrapper};
  border-radius: 6px;
  width: 100%;
  padding: 40px 30px;
  margin-top: 20px;
  > p {
    color: white;
  }
`;

export const TotalEpisodesLabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledIconWrapper = styled.div`
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
`;

export const SpinnerWrapper = styled.div`
  padding: 186px 0;
`;

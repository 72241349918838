import React from 'react';
import PropTypes from 'prop-types';
import { Radio as RadioMui } from '@mui/material';

const Radio = ({ sx: sxRadio, ...props }) => {
  return <RadioMui sx={sxRadio} {...props} />;
};

Radio.propTypes = {
  sx: PropTypes.object,
};

Radio.defaultProps = {
  sx: {},
};

export default Radio;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import theme from 'shared/themes/default';

const TooltipGraphBasicStyle = styled.div`
  background: ${theme.palette.brand};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15136);
  border-radius: 5px;
  padding: 8px;
  text-align: center;
`;

const TitleTooltip = styled.div`
  font-family: ${theme.fonts.primary};
  margin: 0 0 4px;
  font-weight: 700;
  font-size: 18px;
  color: ${theme.palette.mainDark};
`;

const LabelTooltip = styled.div`
  margin: 0;
  font-family: ${theme.fonts.primary};
  font-size: 14px;
  max-width: 180px;
  text-align: left;
`;

const TooltipGraphBasic = ({ active, payload, onActive, labels }) => {
  const { t } = useTranslation();
  const [label1, label2] = labels;

  useEffect(() => {
    if (active && payload && payload.length) {
      const { name } = payload[0].payload;
      onActive(name);
    }
  }, [active, onActive, payload]);

  if (active && payload && payload.length) {
    let text1 = '';
    if (label1.key) {
      text1 = t(label1.key, {
        name: payload[0].payload.name,
        value: payload[0].value,
        ...label1.info,
      });
    } else {
      text1 = label1.replace('{{name}}', payload[0].payload.name);
      text1 = text1.replace('{{value}}', payload[0].value);
    }

    let text2 = '';
    if (label2.key) {
      text2 = t(label2.key, {
        name: payload.length === 1 ? 0 : payload[1].payload.name,
        value: payload.length === 1 ? 0 : payload[1].value,
        ...label2.info,
      });
    } else {
      text2 = label2.replace('{{name}}', payload[1].payload.name);
      text2 = text2.replace('{{value}}', payload[1].value);
    }

    return (
      <TooltipGraphBasicStyle>
        <TitleTooltip>{text1}</TitleTooltip>
        <LabelTooltip>{text2}</LabelTooltip>
      </TooltipGraphBasicStyle>
    );
  }
  return null;
};
TooltipGraphBasic.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  onActive: PropTypes.func,
  labels: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.exact({
        key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        info: PropTypes.object,
      }),
    ])
  ),
};

export default TooltipGraphBasic;

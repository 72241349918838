import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Autocomplete from 'shared/atoms/Autocomplete';
import ListItems from 'shared/molecules/ListItems';
import T from 'shared/atoms/Typography';
import theme from 'shared/themes/default';

import { AUTOCOMPLETEWITHLIST_TYPE } from 'shared/const';

const ListWrapper = styled.div`
  ${({ type }) =>
    type !== AUTOCOMPLETEWITHLIST_TYPE.FORM &&
    `
    margin-top: 25px;
  `}
`;

const StyledUsersListWrapper = styled.div`
  ${({ type }) =>
    type !== AUTOCOMPLETEWITHLIST_TYPE.FORM &&
    `
    margin-top: 10px;
    margin-left: -15px;
    margin-right: -15px;
  `}
`;

const AutocompleteWithList = ({
  allowSingleSelection,
  disabled,
  items,
  limit,
  listHeaderText,
  loading,
  loadingText,
  noOptionsText,
  noItemsText,
  onChange,
  placeholder,
  selectedItems: selectedItemsProps,
  theme: themeLayout,
  type,
}) => {
  const sortItems = (items) =>
    items.sort((a, b) => {
      return a.title?.localeCompare(b.title);
    });
  const [itemsToShow, setItemsToShow] = useState([]);
  const [selectedItems, setSelectedItems] = useState(
    sortItems([...(selectedItemsProps || [])])
  );

  const handleChange = (newItems) => {
    onChange(newItems);
  };

  const handleDelete = (key) => {
    const newItems = selectedItems.filter((item) => item.key !== key);
    setSelectedItems(newItems);
    setItemsToShow(
      sortItems([...itemsToShow, ...items.filter((item) => item.key === key)])
    );
    handleChange(newItems);
  };

  useEffect(() => {
    setItemsToShow(sortItems([...items]));
  }, [items]);

  useEffect(() => {
    setSelectedItems(sortItems([...(selectedItemsProps || [])]));
    setItemsToShow(
      sortItems(
        items.filter((item) => {
          if (item && item.key !== undefined) {
            return !(selectedItemsProps || []).some(
              (selectedItem) => item.key === selectedItem.key
            );
          }
          return false;
        })
      )
    );
  }, [selectedItemsProps]);

  return (
    <>
      <Autocomplete
        clearOnSelect
        disabled={
          disabled ||
          (allowSingleSelection && selectedItems.length > 0) ||
          (limit > 0 ? selectedItems.length >= limit : false)
        }
        filterOptions
        getOptionLabel={(option) => option.title || ''}
        loading={loading}
        loadingText={loadingText}
        noOptionsText={noOptionsText}
        onChange={(newItem) => {
          if (!newItem) {
            return;
          }
          const filteredItems = selectedItems.filter(
            (chip) => chip.key !== newItem.key
          );
          const newItems = [...filteredItems, newItem];
          setSelectedItems(newItems);
          const filterDropDownData = itemsToShow.filter(
            (item) => item.key !== newItem.key
          );
          setItemsToShow(filterDropDownData);
          handleChange(newItems);
        }}
        options={loading ? [] : itemsToShow}
        placeholder={placeholder}
        theme={themeLayout}
        type={themeLayout === 'light' ? 'primary' : 'secondary'}
      />
      <ListWrapper type={type}>
        {(!!selectedItems.length || noItemsText) && (
          <>
            {listHeaderText && (
              <T
                color={
                  themeLayout === 'light'
                    ? theme.palette.darkDistinct
                    : theme.palette.lightInteracting
                }
              >
                {listHeaderText}
              </T>
            )}
            <StyledUsersListWrapper type={type}>
              <ListItems
                items={
                  selectedItems.length
                    ? selectedItems
                    : [{ key: noItemsText, title: noItemsText }]
                }
                onRemove={
                  !!selectedItems.length && !loading && !disabled
                    ? handleDelete
                    : null
                }
                theme={themeLayout}
              />
            </StyledUsersListWrapper>
          </>
        )}
      </ListWrapper>
    </>
  );
};

AutocompleteWithList.propTypes = {
  allowSingleSelection: PropTypes.bool,
  disabled: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      error: PropTypes.bool,
      errorTooltip: PropTypes.string,
      key: PropTypes.string.isRequired,
      label: PropTypes.string,
      showBorder: PropTypes.bool,
      showSecondaryAction: PropTypes.bool,
      subtitle: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
  limit: PropTypes.number,
  listHeaderText: PropTypes.string,
  loading: PropTypes.bool,
  loadingText: PropTypes.string.isRequired,
  noOptionsText: PropTypes.string.isRequired,
  noItemsText: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      error: PropTypes.bool,
      errorTooltip: PropTypes.string,
      key: PropTypes.string.isRequired,
      label: PropTypes.string,
      showBorder: PropTypes.bool,
      showSecondaryAction: PropTypes.bool,
      subtitle: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  theme: PropTypes.string,
  type: PropTypes.oneOf(Object.values(AUTOCOMPLETEWITHLIST_TYPE)),
};

AutocompleteWithList.defaultProps = {
  limit: 0,
  selectedItems: [],
  theme: 'light',
};

export default AutocompleteWithList;

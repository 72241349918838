import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

import CircularSpinner from 'shared/atoms/CircularSpinner';
import IconButton from 'shared/atoms/IconButton';
import T from 'shared/atoms/Typography';

import { PROCESSING_STATUS } from 'shared/const';

import theme from 'shared/themes/default';
import Icon from 'shared/atoms/Icon';

const ThumbnailPreviewWrapper = styled.div`
  ${({ colorBackground }) =>
    colorBackground && `background-color: ${colorBackground};`}
  border: 1px solid ${theme.palette.disabledDark};
  border-radius: 8px;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const ImgStyled = styled.img`
  height: 100%;
  object-fit: contain;
  user-select: none;
  width: 100%;
`;

const StatusWrapper = styled.div`
  align-items: center;
  background-color: ${theme.palette.mainDark}6F;
  display: flex;
  gap: ${theme.spacing.xs};
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const ThumbnailPreview = ({
  colorBackground,
  imageSrc,
  loading,
  onDelete,
  processingStatus,
}) => {
  const { t } = useTranslation();

  const getStatusTitle = (processingStatus) => {
    switch (processingStatus) {
      case PROCESSING_STATUS.ERROR:
        return {
          icon: ErrorOutlineIcon,
          title: t('backoffice.courses.processingError'),
        };
      case PROCESSING_STATUS.PROCESSING:
        return {
          icon: HourglassEmptyIcon,
          title: t('backoffice.courses.processing'),
        };
      case PROCESSING_STATUS.TO_SCHEDULE:
        return {
          icon: CloudUploadOutlinedIcon,
          title: t('backoffice.courses.processingNotScheduled'),
        };
      default:
        return null;
    }
  };

  const statusTitle = getStatusTitle(processingStatus);

  return (
    <ThumbnailPreviewWrapper colorBackground={colorBackground}>
      {imageSrc && <ImgStyled draggable={false} src={imageSrc} />}
      {statusTitle && (
        <StatusWrapper>
          <Icon
            icon={statusTitle.icon}
            color={theme.palette.lightPure}
            size={theme.spacing.m3}
          />
          <T fontWeight="medium" variant="helperTextS">
            {statusTitle.title}
          </T>
        </StatusWrapper>
      )}
      {onDelete && (
        <IconButton
          ariaLabel={t('generic.delete')}
          disabled={loading}
          icon={DeleteOutlineIcon}
          onClick={onDelete}
          sx={{
            position: 'absolute',
            right: theme.spacing.xs,
            top: theme.spacing.xs,
          }}
          theme="dark"
          type="transparent"
        />
      )}
      {loading && <CircularSpinner />}
    </ThumbnailPreviewWrapper>
  );
};

ThumbnailPreview.propTypes = {
  colorBackground: PropTypes.string,
  imageSrc: PropTypes.string,
  loading: PropTypes.bool,
  onDelete: PropTypes.func,
  processingStatus: PropTypes.string,
};

export default ThumbnailPreview;

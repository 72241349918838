export const boApi = (input) => ({
  query: `mutation BoApi(
    $input: BoApiInput
  ) {
    boApi(input: $input) {
      data
    }
  }`,
  variables: {
    input: {
      data: JSON.stringify(input),
    },
  },
});

export const createOrganization = (input) => ({
  query: `mutation CreateOrganization(
    $input: CreateOrganizationInput
  ) {
    createOrganization(input: $input) {
      id
      domain
      name
      organizationNumber
      stripe_customer_id
      email
      archived
      hideVcCourses
      lockVcCourses
      internalCoursesOnTop
      hasCustomPlans
      subscriptions {
        planId
        planName
        validFrom
        expireDate
      }
      invoiceAddress
      invoiceCity
      invoiceEmail
      invoiceName
      invoiceNote
      invoicePeriod
      invoiceSendVia
      invoiceZip
      registeredMembers
      createdBy
      createdAt
      createdFrom
      hidePriceList
    }
  }`,
  variables: {
    input,
  },
});

export const setOrganizationArchived = (id, archived) => ({
  query: `mutation setOrganizationArchived($id: ID!, $archived: Boolean!) {
    setOrganizationArchived(id: $id, archived: $archived) {
      id
      archived
    }
  }`,
  variables: {
    id,
    archived,
  },
});

export const updateOrganization = (input) => ({
  query: `mutation UpdateOrganization(
    $input: UpdateOrganizationInput
  ) {
    updateOrganization(input: $input) {
      id
      domain
      hideVcCourses
      lockVcCourses
      internalCoursesOnTop
      hasCustomPlans
      hidePriceList
      organizationNumber
      invoiceSendVia
      invoiceName
      invoiceEmail
      invoiceAddress
      invoiceZip
      invoiceCity
      invoicePeriod
      invoiceNote
      stripe_customer_id
      invitationLanguage
      purchasedLicenses
      startDate
    }
  }`,
  variables: {
    input,
  },
});

export const updateOrganizationSubscription = (input) => ({
  query: `mutation updateOrganizationSubscription(
    $input: UpdateOrganizationSubscriptionInput!
  ) {
    updateOrganizationSubscription(input: $input) {
      active
      cancel_at
      cancel_at_period_end
      canceled_at
      current_period_end
      current_period_start
      ended_at
      nickname
      subscriptionId
    }
  }`,
  variables: {
    input,
  },
});

export const addFeaturedItem = (input) => ({
  query: `mutation addFeaturedItem(
    $input: AddFeaturedItemInput!
  ) {
    addFeaturedItem(input: $input) {
      id
      courseId
      organizationId
      domain
      course {
        id
        name
        nameTranslations
      }
      image {
        key
        bucket
      }
      imageProcessingStatus {
        status
      }
      description
      title
    }
  }`,
  variables: {
    input,
  },
});

export const removeFeaturedItem = (id) => ({
  query: `mutation removeFeaturedItem(
    $input: RemoveFeaturedItemInput!
  ) {
    removeFeaturedItem(input: $input) {
      id
    }
  }`,
  variables: {
    input: {
      id,
    },
  },
});

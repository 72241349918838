export const listExperts = (domain, nextToken) => ({
  name: 'listExperts',
  query: `query ListExperts(
    $filter: ExpertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExperts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        domain
        firstname
        lastname
        description
        descriptionTranslations
        shortDescription
        shortDescriptionTranslations
        homePage
        isDisabled
        tagline
        socialLinks {
          type
          link
        }
        picture {
          key
          bucket
          region
        }
        verticalImage {
          key
          bucket
          region
        }
        signature {
          key
          bucket
          region
        }
        courses {
          items {
            id
            nameTranslations
          }
        }
      }
      nextToken
    }
  }`,
  variables: {
    filter: {
      domain: {
        contains: domain,
      },
    },
    limit: 500,
    nextToken,
  },
});

import log from 'loglevel';

const env = process.env.NODE_ENV || 'development';

if (env === 'development') {
  log.setLevel(log.levels.TRACE);
} else {
  log.setLevel(log.levels.INFO);
}

export default log;

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import humanizeDuration from 'humanize-duration';

import OndemandVideo from '@mui/icons-material/OndemandVideo';

import T from '@shared/atoms/Typography';
import theme from '@shared/themes/default';
import { useAppGlobals } from '../../../../../contexts/AppContext';
import LessonTitle from './LessonTitle';

const CustomTitle = styled.div`
  align-items: center;
  column-gap: ${theme.spacing.m2};
  display: flex;
`;

const LenghtContainer = styled.div`
  align-items: center;
  column-gap: ${theme.spacing.s};
  display: flex;
`;

const LessonCustomHeader = ({ episodes }) => {
  const { t } = useTranslation();
  const { locale } = useAppGlobals();
  let courseLengthText;

  if (episodes.length > 0) {
    const courseSeconds = episodes.reduce(
      (acc, curr) =>
        acc +
        ((curr.videoFiles &&
          curr.videoFiles.length &&
          curr.videoFiles[0].seconds) ||
          (curr.game && curr.game.length * 60) ||
          0),
      0
    );

    if (courseSeconds >= 60) {
      const length = humanizeDuration(courseSeconds * 1000, {
        language: locale,
        fallbacks: ['en'],
        units: ['h', 'm'],
        round: true,
      });

      courseLengthText = t(
        'backoffice.courses.lessonsTab.lessonsHeaderLength',
        {
          length,
        }
      );
    }
  }

  return courseLengthText ? (
    <CustomTitle>
      <LessonTitle lessons={episodes.length} />
      <LenghtContainer>
        <OndemandVideo sx={{ color: theme.palette.lightPure }} />
        <T color={theme.palette.lightPure} fontWeight="semibold">
          {courseLengthText}
        </T>
      </LenghtContainer>
    </CustomTitle>
  ) : (
    <LessonTitle lessons={episodes.length} />
  );
};

LessonCustomHeader.propTypes = {
  episodes: PropTypes.arrayOf(
    PropTypes.shape({
      videoFiles: PropTypes.arrayOf(
        PropTypes.shape({
          seconds: PropTypes.number,
        })
      ),
    })
  ),
};

export default LessonCustomHeader;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import theme from 'shared/themes/default';

const OuterWrapper = styled.div`
  background-color: ${theme.palette.darkWrapper};
  border-radius: 6px;
  width: 100%;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5rem 0;

  ${theme.breakpoints.mobile} {
    align-items: start;
    flex-direction: column;
    padding: 1rem 0;
  }
`;

const TitleBox = styled.div`
  font-family: ${theme.fonts.primary};
  color: ${theme.palette.lightInteracting};
  font-weight: 600;
  margin: 0 3rem 0 2rem;

  ${theme.breakpoints.mobile} {
    margin-left: 1.5rem;
  }
`;

const InfoBox = styled.div`
  font-family: ${theme.fonts.primary};
  color: ${theme.palette.lightInteracting};
  margin-right: 1.5rem;

  span {
    font-weight: 600;
  }

  ${theme.breakpoints.mobile} {
    margin-left: 1.5rem;
  }
`;

const WatchDataEpisodesSeen = ({
  episodesSeen,
  episodesSeenPreviousYear,
  timePeriodText,
}) => {
  const { t } = useTranslation();

  return (
    <OuterWrapper>
      <FlexWrapper>
        <TitleBox>{t('watchData.totalEpisodesSeen').toUpperCase()}</TitleBox>
        <InfoBox>
          {`${timePeriodText}: `}
          <span>{episodesSeen}</span>
        </InfoBox>
        <InfoBox>
          {`${t('watchData.samePeriodYearBefore')}: `}
          <span>{episodesSeenPreviousYear}</span>
        </InfoBox>
      </FlexWrapper>
    </OuterWrapper>
  );
};

WatchDataEpisodesSeen.propTypes = {
  episodesSeen: PropTypes.number.isRequired,
  episodesSeenPreviousYear: PropTypes.number.isRequired,
  timePeriodText: PropTypes.string.isRequired,
};

export default WatchDataEpisodesSeen;

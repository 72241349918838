import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import MuiDialog from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';

import CircularSpinner from 'shared/atoms/CircularSpinner';
import Alert from 'shared/atoms/Alert';
import Button from 'shared/atoms/Button';
import IconButton from 'shared/atoms/IconButton';
import T from 'shared/atoms/Typography';

import theme from 'shared/themes/default';

const FormStatusWrapper = styled.div`
  padding: 3.5rem 2rem 2rem 2rem;
`;

const useStyles = makeStyles(() => ({
  paper: {
    maxWidth: '400px',
  },
  contentRoot: {
    fontFamily: theme.fonts.primary,
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  actionsRoot: {
    display: 'flex',
    justifyContent: 'right',
    padding: '0 2rem 1rem 2rem',
  },
}));

const ConfirmationDialog = ({
  ariaLabel,
  cancelText,
  confirmText,
  description,
  disabledConfirm,
  formStatus,
  onCancel,
  onClose,
  onConfirm,
  open,
  title,
  ...props
}) => {
  const [submitting, setSubmitting] = useState(false);
  const classes = useStyles();

  const handleConfirm = async () => {
    setSubmitting(true);
    onConfirm();
  };

  useEffect(() => {
    if (open || formStatus) {
      setSubmitting(false);
    }
  }, [formStatus, open]);

  const isMobile = useMediaQuery(theme.breakpoints.mobile);
  const sxButton = {
    minWidth: '70px',
    width: isMobile ? '100%' : 'auto',
  };

  return (
    <MuiDialog
      open={open}
      aria-labelledby={ariaLabel}
      classes={{ paper: classes.paper }}
      {...props}
    >
      {!!onClose && (
        <IconButton
          ariaLabel="close-dialog"
          onClick={onClose}
          disabled={submitting}
          icon={CloseIcon}
          sx={{
            position: 'absolute',
            top: '0.5rem',
            right: '0.5rem',
          }}
          theme="light"
          type="transparent"
        />
      )}
      {formStatus ? (
        <FormStatusWrapper>
          <Alert
            message={formStatus.message}
            severity={formStatus.success ? 'success' : 'error'}
            theme="light"
          />
        </FormStatusWrapper>
      ) : (
        <>
          <MuiDialogContent classes={{ root: classes.contentRoot }}>
            {title && (
              <T
                color={theme.palette.pureDark}
                mt={onClose ? '3rem' : '1.5rem !important'}
                fontWeight="bold"
                variant="subtitle"
                gutterBottom
              >
                {title}
              </T>
            )}
            {description && <T color={theme.palette.pureDark}>{description}</T>}
          </MuiDialogContent>
          <MuiDialogActions classes={{ root: classes.actionsRoot }}>
            <Button
              ariaLabel={`${cancelText}-button`}
              disabled={submitting}
              onClick={onCancel}
              sx={sxButton}
              theme="light"
              type="secondaryOutline"
            >
              {cancelText}
            </Button>
            <Button
              ariaLabel={`${confirmText}-button`}
              disabled={disabledConfirm || submitting}
              onClick={handleConfirm}
              sx={sxButton}
              theme="light"
            >
              {confirmText}
            </Button>
          </MuiDialogActions>
        </>
      )}
      {submitting && <CircularSpinner thickness={4} />}
    </MuiDialog>
  );
};

ConfirmationDialog.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  disabledConfirm: PropTypes.bool,
  formStatus: PropTypes.shape({
    success: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
  }),
  open: PropTypes.bool,
  onClose: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  title: PropTypes.string,
  cancelText: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

ConfirmationDialog.defaultProps = {
  open: true,
  title: '',
  formStatus: null,
};

export default ConfirmationDialog;

import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';

import T from 'shared/atoms/Typography';
import { BACKGROUND_THEMES } from 'shared/const';
import theme from 'shared/themes/default';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) =>
    !props.static &&
    css`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    `}
`;

const SpinnerValueWrapper = styled.div`
  position: relative;
`;

const ProgressWrapper = styled.div`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CircularSpinner = ({
  bottomText,
  color,
  duration,
  onTimeOut,
  showProgress,
  size,
  static: staticProp,
  theme: themeLayout,
  thickness,
  timer,
}) => {
  const [progress, setProgress] = useState(timer ? 100 : 0);
  const [seconds, setSeconds] = useState(1);

  useEffect(() => {
    // Reset seconds when duration is changed, for Storybook
    setSeconds(1);

    if (showProgress || timer) {
      const timer = setInterval(
        () =>
          setSeconds((seconds) =>
            seconds <= duration ? seconds + 1 : seconds
          ),
        1000
      );
      return () => clearInterval(timer);
    }
    return null;
  }, [duration]);

  useEffect(() => {
    if (timer) {
      if (seconds > duration) {
        onTimeOut();
      } else {
        setProgress(100 - (seconds / duration) * 100);
      }
    } else {
      setProgress(
        Math.round((1 - Math.exp(-0.5 * (seconds * (10 / duration)))) * 100)
      );
    }
  }, [seconds]);

  const sxTimer = {
    transform: 'rotate(-90deg) scaleY(-1) !important', // !important because the transforms are added inline
    '& .MuiCircularProgress-circleDeterminate': {
      transition: 'stroke-dashoffset 1s linear 0s',
    },
  };

  return (
    <Wrapper static={staticProp}>
      {!showProgress && !timer ? (
        <CircularProgress thickness={thickness} size={size} sx={{ color }} />
      ) : (
        <SpinnerValueWrapper>
          <CircularProgress
            thickness={thickness}
            size={size}
            sx={{
              color:
                themeLayout === 'dark'
                  ? theme.palette.darkDistinct
                  : theme.palette.lightDistinct,
            }}
            variant="determinate"
            value={100}
          />
          <CircularProgress
            thickness={thickness}
            size={size}
            sx={{
              color,
              position: 'absolute',
              strokeLinecap: 'round',
              left: 0,
              ...(timer ? sxTimer : {}),
            }}
            variant="determinate"
            value={progress}
          />
          <ProgressWrapper>
            <T
              color={
                themeLayout === 'dark'
                  ? theme.palette.lightPure
                  : theme.palette.mainDark
              }
              variant="headingXs"
              fontWeight="semibold"
            >
              {timer ? duration - seconds + 1 : `${progress}%`}
            </T>
          </ProgressWrapper>
        </SpinnerValueWrapper>
      )}
      {bottomText && (
        <T
          color={
            themeLayout === 'dark'
              ? theme.palette.disabledLight
              : theme.palette.disabledDark
          }
        >
          {bottomText}
        </T>
      )}
    </Wrapper>
  );
};

CircularSpinner.propTypes = {
  bottomText: PropTypes.string,
  color: PropTypes.oneOf([...Object.values(theme.palette), 'inherit']),
  duration: PropTypes.number,
  onTimeOut: PropTypes.func,
  showProgress: PropTypes.bool,
  size: PropTypes.number,
  static: PropTypes.bool,
  theme: PropTypes.oneOf(BACKGROUND_THEMES),
  thickness: PropTypes.number,
  timer: PropTypes.bool,
};

CircularSpinner.defaultProps = {
  color: theme.palette.brand,
  onTimeOut: () => {},
  size: 75,
  static: false,
  theme: 'dark',
  thickness: 4,
};

export default CircularSpinner;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CircularSpinner from '@shared/atoms/CircularSpinner';
import Table, { ORDER_TYPES, CELL_TYPES } from '@shared/organisms/Table';
import T from '@shared/atoms/Typography';
import { logger } from '@shared/utils';

import { listUserInvitations } from '../../graphql/queries';
import { useGraphQLQuery } from '../../../../../utils';

const InvitationsTab = ({ email, loadingMove }) => {
  const { t } = useTranslation();

  const [invitations, setInvitations] = useState([]);

  const { callQuery, data, errors, hasEnded, hasErrors, loading } =
    useGraphQLQuery();

  useEffect(() => {
    if (hasEnded) {
      if (hasErrors) {
        logger.error('Error: ', errors);
      } else {
        const {
          listUserInvitations: { items },
        } = data;
        setInvitations(
          items.map((item) => ({
            invitedDate: item.invitedDate,
            organizationId: item.organization.id,
            organizationName: item.organization.name,
            role: item.role,
            status: item.status,
            invitedBy: item.invitedBy
              ? `${item.invitedBy.name} (${item.invitedBy.email})`
              : '',
          }))
        );
      }
    }
  }, [hasEnded]);

  useEffect(() => {
    if (!loadingMove) {
      callQuery(listUserInvitations(email));
    }
  }, [loadingMove]);

  return (
    <>
      {loading && <CircularSpinner static />}
      {invitations.length > 0 && !loading && (
        <Table
          ariaLabel="Invitations table"
          columns={[
            {
              key: 'invitedDate',
              label: t('backoffice.users.invitedDate'),
              type: CELL_TYPES.DATE,
            },
            {
              key: 'organizationName',
              label: t('backoffice.users.organization'),
            },
            {
              key: 'role',
              label: t('backoffice.users.role'),
            },
            {
              key: 'status',
              label: t('backoffice.users.status'),
            },
            {
              key: 'invitedBy',
              label: t('backoffice.users.invitedBy'),
            },
          ]}
          items={invitations}
          defaultOrder={ORDER_TYPES.ASC}
          defaultOrderBy="invitedDate"
        />
      )}
      {invitations.length === 0 && !loading && (
        <T>{t('backoffice.users.noInvitationsFound')}</T>
      )}
    </>
  );
};

InvitationsTab.propTypes = {
  email: PropTypes.string.isRequired,
  loadingMove: PropTypes.bool.isRequired,
};

export default InvitationsTab;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import LinkIcon from '@mui/icons-material/Link';
import MilitaryTech from '@mui/icons-material/MilitaryTech';

import CircularSpinner from '@shared/atoms/CircularSpinner';
import Button from '@shared/atoms/Button';
import Link from '@shared/atoms/Link';
import SearchField from '@shared/atoms/SearchField';
import Snackbar from '@shared/atoms/Snackbar';
import T from '@shared/atoms/Typography';

import SimpleTabView from '@shared/molecules/SimpleTabView';

import Table, { CELL_TYPES, ORDER_TYPES } from '@shared/organisms/Table';

import theme from '@shared/themes/default';
import { courseStatus, courseType } from '@shared/const';
import {
  formatDate,
  selectTranslation,
  getCourseDetailUrl,
} from '@shared/utils';

import { useAppGlobals } from '../../contexts/AppContext';
import CreateCourseDialog from './components/CreateCourseDialog';

import { environment } from '../../../config';
import { graphql } from '../../../utils';

import { createCourse } from './graphql/mutations';

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.m1};
`;

const HeaderBlock = styled.div`
  display: flex;
  column-gap: ${theme.spacing.m1};
`;

const StyledCoursesTableWrapper = styled.div`
  margin: ${theme.spacing.m1} 0 ${theme.spacing.m1} 0 ${theme.spacing.m1};
  width: 100%;
`;

const CourseNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PreviewWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${theme.spacing.m1};
`;

const IconWrapper = styled.div`
  display: flex;
`;

const IconStyle = styled.div`
  margin-right: ${theme.spacing.m1};
`;

const CoursesView = ({ courses, loading, featuredItems }) => {
  const [courseList, setCourseList] = useState();
  const [courseName, setCourseName] = useState();
  const [noCategoryCourses, setNoCategoryCourses] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchedCourses, setSearchedCourses] = useState();
  const [searchedNoCategoryCourses, setSearchedNoCategoryCourses] = useState();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showNewCourseModal, setShowNewCourseModal] = useState(false);
  const [successDeleteOpen, setSuccessDeleteOpen] = useState(false);

  const { domain, locale } = useAppGlobals();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const deletedCourseName = searchParams.get('deletedCourseName');
  const webSiteBaseUri = `https://app${
    environment !== 'production' ? `.${environment}` : ''
  }.videocation.${domain}`;

  useEffect(() => {
    if (deletedCourseName) {
      setSuccessDeleteOpen(true);
      setCourseName(deletedCourseName);
      searchParams.delete('deletedCourseName');
      setSearchParams(searchParams);
    }
  }, [deletedCourseName]);

  useEffect(() => {
    const courseList = courses
      .filter((course) => course)
      .map((course) => {
        if (!course.ownerOrganization || !course.ownerOrganization.name) {
          return { ...course, ownerOrganizationName: 'Videocation' };
        }

        return {
          ...course,
          ownerOrganizationName: course.ownerOrganization.name,
        };
      });

    setCourseList(courseList);

    const noCategoryCourses = courseList.filter((course) => {
      const { categories } = course;
      return !categories || categories.items.length === 0;
    });

    setNoCategoryCourses(noCategoryCourses);

    if (searchTerm) {
      const searchedCourses = courseList.filter((course) => {
        const nameCourse =
          selectTranslation(course.nameTranslations, course.name, locale, '') ||
          '';
        return (
          nameCourse.toLowerCase().includes(searchTerm.toLowerCase()) ||
          course?.experts?.items?.some(
            (expert) =>
              expert &&
              (expert.firstname
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
                expert.lastname
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase()))
          ) ||
          course?.ownerOrganizationName
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase())
        );
      });

      const searchedNoCategoryCourses = searchedCourses.filter((course) => {
        const { categories } = course;
        return !categories || categories.items.length === 0;
      });

      setSearchedCourses(searchedCourses);
      setSearchedNoCategoryCourses(searchedNoCategoryCourses);
    } else {
      setSearchedCourses(courseList);
      setSearchedNoCategoryCourses(noCategoryCourses);
    }
  }, [courses, searchTerm]);

  const coursePreview = (c) => {
    return `${webSiteBaseUri}${getCourseDetailUrl(c, locale)}`;
  };

  const isFeatured = (course) =>
    featuredItems.find(
      (promotedCourse) => promotedCourse.course.id === course.id
    );

  const getName = (course) => {
    return (
      <CourseNameWrapper>
        <Link
          ariaLabel={selectTranslation(
            course.nameTranslations,
            course.name,
            locale,
            `<No name, ID: ${course.id}>`
          )}
          key={course.id}
          to={`/courses/${course.id}`}
          visibility="low"
          paragraph
          sx={{ '&:focus': { outline: 'none' } }}
        >
          {selectTranslation(
            course.nameTranslations,
            course.name,
            locale,
            `<No name, ID: ${course.id}>`
          )}
        </Link>
        <PreviewWrapper>
          <IconWrapper>
            {course.type === courseType.FREE && (
              <IconStyle>
                <T fontWeight="semibold" fontFamily="helperText">
                  {t('backoffice.courses.free')}
                </T>
              </IconStyle>
            )}
            {isFeatured(course) && (
              <IconStyle>
                <MilitaryTech />
              </IconStyle>
            )}
          </IconWrapper>
          <div>
            <Link
              ariaLabel={t('backoffice.courses.preview')}
              key="preview"
              visibility="low"
              href={coursePreview(course)}
              paragraph
              sx={{ '&:focus': { outline: 'none' } }}
            >
              <LinkIcon
                fontSize="small"
                sx={{
                  color: theme.palette.lightPure,
                  transform: 'rotate(-45deg)',
                }}
              />
              {t('backoffice.courses.preview')}
            </Link>
          </div>
        </PreviewWrapper>
      </CourseNameWrapper>
    );
  };

  const getCourseExperts = (course) => {
    const { experts: rawExperts } = course;

    const experts = (rawExperts?.items || []).filter((expert) => expert);

    if (experts.length > 2) {
      return (
        <>
          {experts.slice(0, 2).map((expert) => (
            <div key={expert.id}>
              {expert?.firstname} {expert?.lastname}
            </div>
          ))}
          <div>
            {` ${t('backoffice.courses.moreExperts', { count: experts.length - 2 })}`}
          </div>
        </>
      );
    }
    return experts.map((expert) => (
      <div key={expert.id}>
        {expert?.firstname} {expert?.lastname}
      </div>
    ));
  };

  const getStatus = (course) => {
    const { status, publishedAt, archivedScheduledAt, archiveDate } = course;
    if (status === courseStatus.PUBLISHED) {
      if (archivedScheduledAt) {
        return (
          <>
            {t('backoffice.courses.deletingOn')}
            <div>
              {formatDate({
                date: archiveDate,
              })}
            </div>
          </>
        );
      }
      return (
        <>
          {status}
          <div>
            {formatDate({
              date: publishedAt,
            })}
          </div>
        </>
      );
    }
    return `${status}`;
  };

  const columns = [
    {
      customSort: (a, b, order) => {
        const keyA = selectTranslation(a.nameTranslations, a.name, locale, '');
        const keyB = selectTranslation(b.nameTranslations, b.name, locale, '');

        return order === ORDER_TYPES.ASC
          ? keyA.localeCompare(keyB, locale, { sensitivity: 'base' })
          : keyB.localeCompare(keyA, locale, { sensitivity: 'base' });
      },
      fixedWidth: '40%',
      key: 'nameTranslations',
      label: t('backoffice.courses.course'),
      render: (row) => getName(row),
      sticky: true,
      type: CELL_TYPES.COMPONENT,
    },
    {
      disableSorting: true,
      key: 'experts',
      label: t('backoffice.courses.experts'),
      render: (row) => getCourseExperts(row),
      sticky: true,
      type: CELL_TYPES.COMPONENT,
    },
    {
      customSort: (a, b, order) => {
        const keyAA = a.status;
        const keyBA = b.status;
        const keyAB = a.archiveDate;
        const keyBB = b.archiveDate;
        const keyAC = a.publishedAt;
        const keyBC = b.publishedAt;

        if (keyAA < keyBA) return order === ORDER_TYPES.ASC ? 1 : -1;
        if (keyAA > keyBA) return order === ORDER_TYPES.ASC ? -1 : 1;

        if (!keyAB && keyBB) return order === ORDER_TYPES.ASC ? 1 : -1;
        if (keyAB && !keyBB) return order === ORDER_TYPES.ASC ? -1 : 1;
        if (keyAB < keyBB) return order === ORDER_TYPES.ASC ? -1 : 1;
        if (keyAB > keyBB) return order === ORDER_TYPES.ASC ? 1 : -1;

        if (keyAC < keyBC) return order === ORDER_TYPES.ASC ? -1 : 1;
        if (keyAC > keyBC) return order === ORDER_TYPES.ASC ? 1 : -1;

        return 0;
      },
      key: 'status',
      label: t('backoffice.courses.status'),
      render: (row) => getStatus(row),
      type: CELL_TYPES.COMPONENT,
    },
    {
      fixedWidth: '20%',
      key: 'ownerOrganizationName',
      label: t('backoffice.courses.availability'),
      render: (row) => `${row.ownerOrganizationName}`,
      type: CELL_TYPES.COMPONENT,
    },
  ];

  const uncategorizedCourses = () => {
    if (noCategoryCourses.length <= 0 && selectedIndex === 1) {
      setSelectedIndex(0);
      return null;
    }
    return (
      <StyledCoursesTableWrapper>
        <Table
          ariaLabel="Courses table"
          columns={columns}
          items={searchedNoCategoryCourses}
          defaultOrder={ORDER_TYPES.DESC}
          defaultOrderBy="status"
        />
      </StyledCoursesTableWrapper>
    );
  };

  const onSwitchTab = ({ index }) => {
    setSelectedIndex(index);
  };

  const showAllCourses = () =>
    courseList.length > 0 && (
      <StyledCoursesTableWrapper>
        <Snackbar
          type="success"
          message={t('backoffice.courses.deleting.success', { courseName })}
          open={successDeleteOpen}
          onClose={() => setSuccessDeleteOpen(false)}
        />
        <Table
          ariaLabel="Courses table"
          columns={columns}
          items={searchedCourses}
          defaultOrder={ORDER_TYPES.DESC}
          defaultOrderBy="status"
        />
      </StyledCoursesTableWrapper>
    );

  const getGraphQlQuery = (query) => {
    const graphQlQuery = async (...params) => graphql(query(...params));
    return graphQlQuery;
  };

  return (
    <OuterWrapper>
      <HeaderBlock>
        <SearchField
          type="primary"
          placeholder={t('backoffice.courses.searchForCourses')}
          ariaLabel="search-courses"
          onChange={({ value }) => setSearchTerm(value)}
          onClear={() => setSearchTerm('')}
          value={searchTerm}
        />
        <Button
          ariaLabel={t('backoffice.courses.createCourse')}
          iconRight={AddIcon}
          onClick={() => setShowNewCourseModal(true)}
          sx={{ flex: '0 0 auto' }}
        >
          {t('backoffice.courses.createCourse')}
        </Button>
      </HeaderBlock>
      {loading ? (
        <CircularSpinner thickness={3} size={125} />
      ) : (
        <SimpleTabView onSwitch={onSwitchTab} selectedIndex={selectedIndex}>
          <SimpleTabView.Panel
            hideBorder
            title={t('backoffice.courses.coursesCount', {
              count: courseList.length,
            })}
          >
            {showAllCourses()}
          </SimpleTabView.Panel>
          <SimpleTabView.Panel
            hideBorder
            title={`${noCategoryCourses.length} ${t(
              'backoffice.courses.noCategory'
            )}`}
            disabled={noCategoryCourses.length <= 0}
            sx={`color: ${theme.palette.warningTinted} !important`}
          >
            {uncategorizedCourses()}
          </SimpleTabView.Panel>
        </SimpleTabView>
      )}
      <CreateCourseDialog
        open={showNewCourseModal}
        onClose={() => setShowNewCourseModal(false)}
        createCourseRequest={getGraphQlQuery(createCourse)}
        onCreate={(courseId) => navigate(`/courses/${courseId}`)}
      />
    </OuterWrapper>
  );
};

CoursesView.propTypes = {
  courses: PropTypes.array.isRequired,
  featuredItems: PropTypes.array,
  loading: PropTypes.bool,
};

export default CoursesView;

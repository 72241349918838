import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ORGANIZATION_GLOBAL, PLAYLIST_TYPE } from 'shared/const';
import { usePlaylist } from 'shared/sections/PlaylistAdministration/context';

import SelectExistingPlaylist from './components/SelectExistingPlaylist';

const CopyFromExistingPlaylist = () => {
  const [globalPlaylists, setGlobalPlaylists] = useState(null);
  const [formStatus, setFormStatus] = useState();
  const [readOnlyCopy, setReadOnlyCopy] = useState(false);
  const [playlistCourses, setPlaylistCourses] = useState([]);
  const [playlistCourse, setPlaylistCourse] = useState(null);

  const { t } = useTranslation();
  const {
    playlists: { copyPlaylist, getPlaylistContent, listPlaylists },
    dialogs: { setOpenCopyPlaylistForm, updateOwnedPlaylists },
    locale,
    managerSelected,
  } = usePlaylist();

  const onCloseHandler = () => {
    setOpenCopyPlaylistForm(false);
    setFormStatus(null);
  };

  const handleSubmit = async (playlistSelected) => {
    const input = { id: playlistSelected, readOnlyCopy };
    const { data, error } = await copyPlaylist({ input, managerSelected });
    if (error) {
      console.error('Error: ', error);
      setFormStatus({
        success: false,
        message: t('playlist.copyPlaylistFailureMessage'),
      });
      return { error };
    }
    if (data) {
      updateOwnedPlaylists(data.copyPlaylist);
      setFormStatus({
        success: true,
        message: t('playlist.copyPlaylistSuccessMessage'),
      });
    }
    return true;
  };

  const playlistExpandHandler = async (expandedPlaylist) => {
    const playlistCourse = playlistCourses && playlistCourses[expandedPlaylist];
    if (playlistCourse) {
      setPlaylistCourse(playlistCourse);
      return true;
    }
    const playlist = globalPlaylists.find(
      (playlist) => playlist.id === expandedPlaylist
    );

    const { data, error } = await getPlaylistContent(playlist.children || []);
    if (error) {
      return false;
    }
    if (data) {
      const filteredCourses = data.getCourses.filter(
        (course) => course !== null
      );
      setPlaylistCourse(filteredCourses);
      setPlaylistCourses({
        ...playlistCourses,
        [expandedPlaylist]: filteredCourses,
      });
    }
    return true;
  };

  const makeReadOnlyHandler = () => {
    setReadOnlyCopy(!readOnlyCopy);
  };

  useEffect(() => {
    async function fetchPlaylists() {
      const { data, error } = await listPlaylists(
        ORGANIZATION_GLOBAL,
        null,
        PLAYLIST_TYPE.PLAYLIST,
        true
      );
      if (error) {
        console.error('Error: ', error);
        setGlobalPlaylists([]);
      }
      if (data && data.listPlaylists) {
        setGlobalPlaylists(data.listPlaylists.items);
      }
    }
    fetchPlaylists();
  }, []);

  return (
    <SelectExistingPlaylist
      makeReadOnlyHandler={makeReadOnlyHandler}
      onClose={onCloseHandler}
      existingPlaylists={globalPlaylists}
      handleSubmit={handleSubmit}
      formStatus={formStatus}
      playlistCourses={playlistCourse}
      locale={locale}
      setExpandedPlaylist={playlistExpandHandler}
    />
  );
};

export default CopyFromExistingPlaylist;

import React from 'react';

import PropTypes from 'prop-types';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import Icon from 'shared/atoms/Icon';
import Tooltip from 'shared/atoms/Tooltip';
import T from 'shared/atoms/Typography';

import { BACKGROUND_THEMES } from 'shared/const';
import theme from 'shared/themes/default';

const Info = ({ children, theme: componentTheme }) => {
  const convertedChildren =
    typeof children === 'string' ? (
      <T color={theme.palette.mainDark} variant="paragraphS">
        {children}
      </T>
    ) : (
      children
    );
  return (
    <Tooltip arrow title={convertedChildren}>
      <Icon
        icon={InfoOutlinedIcon}
        color={
          componentTheme === 'dark'
            ? theme.palette.lightPure
            : theme.palette.darkDistinct
        }
        colorHover={theme.palette.brand}
      />
    </Tooltip>
  );
};

Info.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  theme: PropTypes.oneOf(BACKGROUND_THEMES),
};

export default Info;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import FullWidthTabs from 'shared/atoms/FullWidthTabs';

import { AddQuestionsCsv, EditQuiz } from './tabs';
import { parseCsvContent } from './utils';

const ManageQuiz = ({
  alwaysExpanded,
  defaultQuizQuestions,
  disabled,
  isEditing,
  isExam,
  pastedCsvContent,
  quizQuestions,
  setErrorMessage,
  setHasModified,
  setPastedCsvContent,
  setQuizQuestions,
  theme: themeLayout,
}) => {
  const { t } = useTranslation();

  const [selectedTabIndex, setSelectedTabIndex] = useState(isEditing ? 1 : 0);

  const onChangeTabHandler = (index) => {
    if (isEditing) {
      setSelectedTabIndex(index);
    } else {
      const { quizQuestions, error } = parseCsvContent(pastedCsvContent, t);
      if (setErrorMessage) setErrorMessage(error);

      if (quizQuestions.length > 0 && selectedTabIndex === 0) {
        setQuizQuestions(quizQuestions);
        setSelectedTabIndex(1);
      } else {
        setSelectedTabIndex(0);
      }
    }
  };

  useEffect(() => {
    setSelectedTabIndex(isEditing ? 1 : 0);
  }, [isEditing]);

  useEffect(() => {
    const quizWasDeleted =
      pastedCsvContent === '' &&
      quizQuestions.length === 0 &&
      selectedTabIndex !== 0;
    if (quizWasDeleted) {
      setSelectedTabIndex(0);
    }
  }, [pastedCsvContent]);

  const labels = isExam
    ? { tab1: t('quizzes.addQuestions'), tab2: t('quizzes.editTabExam') }
    : { tab1: t('quizzes.newQuiz'), tab2: t('quizzes.editQuiz') };

  const tabsToSelect = [
    {
      label: labels.tab1,
      component: (
        <AddQuestionsCsv
          disabled={disabled}
          pastedCsvContent={pastedCsvContent}
          setPastedCsvContent={(content) => {
            setPastedCsvContent(content);
            const { quizQuestions, error } = parseCsvContent(content, t);

            if (error) {
              setErrorMessage(error);
            } else {
              setQuizQuestions(quizQuestions);
              setSelectedTabIndex(1);
            }
            if (setHasModified)
              setHasModified(
                JSON.stringify(defaultQuizQuestions) !==
                  JSON.stringify(quizQuestions)
              );
          }}
          setQuizQuestions={setQuizQuestions}
          theme={themeLayout}
        />
      ),
      disabled: isEditing,
    },
    {
      label: labels.tab2,
      component: (
        <EditQuiz
          disabled={disabled}
          isEditing={isEditing}
          alwaysExpanded={alwaysExpanded}
          questions={quizQuestions}
          setQuestions={(questions) => {
            if (setHasModified)
              setHasModified(
                JSON.stringify(defaultQuizQuestions) !==
                  JSON.stringify(questions)
              );
            setQuizQuestions(questions);
          }}
          theme={themeLayout}
        />
      ),
      disabled: !isEditing && pastedCsvContent.length === 0,
    },
  ];

  return (
    <FullWidthTabs
      tabsToSelect={tabsToSelect}
      onChangeTabHandler={onChangeTabHandler}
      selectedTabIndex={selectedTabIndex}
      theme={themeLayout}
    />
  );
};

ManageQuiz.propTypes = {
  alwaysExpanded: PropTypes.bool,
  defaultQuizQuestions: PropTypes.array,
  disabled: PropTypes.bool,
  isEditing: PropTypes.bool.isRequired,
  isExam: PropTypes.bool,
  pastedCsvContent: PropTypes.string.isRequired,
  quizQuestions: PropTypes.array.isRequired,
  setErrorMessage: PropTypes.func,
  setHasModified: PropTypes.func,
  setPastedCsvContent: PropTypes.func.isRequired,
  setQuizQuestions: PropTypes.func.isRequired,
  theme: PropTypes.string,
};

ManageQuiz.defaultProps = {
  isExam: true,
  theme: 'light',
};

export default ManageQuiz;

import React, { useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import FormInput from '@shared/atoms/FormFields/FormInput';
import { LOCALES } from '@shared/const';
import LanguageSelect from '@shared/molecules/LanguageSelect';
import theme from '@shared/themes/default';

import { useAppGlobals } from '../../../../contexts/AppContext';

const Wrapper = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m1};
  padding: ${theme.spacing.s};
`;

const DescriptionTab = ({
  formErrors,
  handleBlur,
  handleChange,
  properties,
  submitting,
  values,
}) => {
  const { locale } = useAppGlobals();
  const { t } = useTranslation();

  const [formLocale, setFormLocale] = useState(locale);

  return (
    <Wrapper>
      <FormInput
        id="tagline"
        label={t('backoffice.experts.tagline')}
        name="tagline"
        placeholder={t('backoffice.experts.taglinePlaceholder')}
        showMaxLength
        theme="dark"
        errors={formErrors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        properties={properties}
        values={values}
      />
      <LanguageSelect
        disabled={submitting}
        languages={LOCALES}
        onChange={({ value }) => setFormLocale(value)}
        theme="dark"
        value={formLocale}
      />
      <FormInput
        id="shortDescription"
        label={t('backoffice.experts.shortDescription')}
        locale={formLocale}
        name="shortDescription"
        placeholder={t('backoffice.experts.shortDescriptionPlaceholder')}
        minRows={3}
        multiline
        showMaxLength
        theme="dark"
        errors={formErrors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        properties={properties}
        values={values}
      />
      <FormInput
        id="description"
        label={t('backoffice.experts.description')}
        locale={formLocale}
        name="description"
        placeholder={t('backoffice.experts.descriptionPlaceholder')}
        minRows={6}
        multiline
        showMaxLength
        theme="dark"
        errors={formErrors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        properties={properties}
        values={values}
      />
    </Wrapper>
  );
};

DescriptionTab.propTypes = {
  formErrors: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  properties: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
};

export default DescriptionTab;

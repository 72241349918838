import React from 'react';

import PageTemplate from '../../templates/PageTemplate';
import AuthenticationContainer from '../../../containers/AuthenticationContainer';

const AuthenticationPage = () => {
  return (
    <PageTemplate>
      <AuthenticationContainer />
    </PageTemplate>
  );
};

export default AuthenticationPage;

import React from 'react';
import PropTypes from 'prop-types';

import FormControl from '@mui/material/FormControl';

import Select from 'shared/atoms/Select';
import { BACKGROUND_THEMES } from 'shared/const';

import FormLabel from '../components/FormLabel';

const FormSelect = ({
  disabled: disabledProp,
  handleChange,
  id,
  infoText,
  label,
  name,
  properties,
  required: requiredProp,
  sx,
  theme: themeLayout,
  values,
  ...props
}) => {
  const { disabled, required } = properties[name] || {};

  const onChangeHandler = ({ name, value }) => {
    handleChange({ name, value });
  };

  return (
    <FormControl
      disabled={disabledProp === undefined ? disabled : disabledProp}
      fullWidth
      required={requiredProp === undefined ? required : requiredProp}
      sx={sx}
    >
      <FormLabel
        id={id}
        infoText={infoText}
        label={label}
        theme={themeLayout}
      />
      <Select
        id={id}
        name={name}
        onChange={onChangeHandler}
        theme={themeLayout}
        value={values[name]}
        {...props}
      />
    </FormControl>
  );
};

FormSelect.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  infoText: PropTypes.string,
  handleChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  properties: PropTypes.object,
  required: PropTypes.bool,
  sx: PropTypes.object,
  theme: PropTypes.oneOf(BACKGROUND_THEMES),
  values: PropTypes.object,
};

FormSelect.defaultProps = {
  handleChange: () => {},
  properties: {},
  sx: {},
  theme: 'dark',
  values: {},
};

export default FormSelect;

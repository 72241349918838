import { useState, useEffect } from 'react';
import { ORGANIZATION_GLOBAL } from 'shared/const';
import { selectTranslation } from 'shared/utils';

export default function useCourses({
  listCoursesWatchData,
  organizationSelectedId,
  locale,
  setWatchDataError,
}) {
  const [allCoursesStats, setAllCoursesStats] = useState([]);
  const [loadingCourses, setLoadingCourses] = useState(false);
  const [coursesNextToken, setCoursesNextToken] = useState();

  const handleError = (error) => {
    console.error('Error calling listCoursesWatchData', error);
    setAllCoursesStats([]);
    setWatchDataError(true);
    setLoadingCourses(false);
  };

  const fetchCourses = async (nextToken = null) => {
    setLoadingCourses(true);
    try {
      const { data, error } = await listCoursesWatchData(nextToken);

      if (error) {
        return handleError(error);
      }

      const { items, nextToken: newNextToken } =
        organizationSelectedId === ORGANIZATION_GLOBAL
          ? data.listCoursesWithGlobalWatchData
          : data.listCoursesWatchData;

      const mappedItems = items.map((item) => ({
        ...item,
        name: selectTranslation(item.nameTranslations, '', locale, ''),
      }));

      if (!nextToken) {
        setAllCoursesStats(mappedItems);
      } else {
        setAllCoursesStats((courses) => [...courses, ...mappedItems]);
      }

      if (newNextToken) {
        setCoursesNextToken(newNextToken);
      } else {
        setLoadingCourses(false);
        setCoursesNextToken(null);
      }
    } catch (error) {
      handleError(error);
    }

    return true;
  };

  useEffect(() => {
    if (coursesNextToken) {
      fetchCourses(coursesNextToken);
    }
  }, [coursesNextToken]);

  return {
    allCoursesStats,
    loadingCoursesWatchData: loadingCourses,
    fetchCoursesWatchData: fetchCourses,
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Button from 'shared/atoms/Button';
import Link from 'shared/atoms/Link';
import T from 'shared/atoms/Typography';
import theme from 'shared/themes/default';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m1};
  padding: ${theme.spacing.m3};
  width: 347px;
`;

const DesktopMenu = ({ closePopover, options, onSignOut, user }) => {
  const { t } = useTranslation();

  return (
    <ContentWrapper>
      {options.length > 0 ? (
        <T
          color={theme.palette.mainDark}
          fontWeight="semibold"
          variant="subtitle"
        >
          {t('header.myAccountAdministrate')}
        </T>
      ) : (
        <T color={theme.palette.mainDark}>{`${user.name} (${user.email})`}</T>
      )}
      {options.map((o) => (
        <Link
          ariaLabel={o.ariaLabel}
          key={o.to}
          onClick={closePopover}
          paragraph
          to={o.to}
          theme="light"
        >
          {t(o.text)}
        </Link>
      ))}
      <Button
        ariaLabel={t('header.signOut')}
        fullWidth
        onClick={onSignOut}
        theme="light"
        type="secondary"
      >
        {t('header.signOut')}
      </Button>
    </ContentWrapper>
  );
};

DesktopMenu.propTypes = {
  closePopover: PropTypes.func.isRequired,
  onSignOut: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

export default DesktopMenu;

import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import MuiLink from '@mui/material/Link';

import { VARIANTS, VARIANTS_MAPPING } from 'shared/atoms/Typography';
import { BACKGROUND_THEMES } from 'shared/const';
import theme from 'shared/themes/default';

const getParagraphColor = ({ themeLayout, visibility }) => {
  if (themeLayout === 'light') return theme.palette.mainDark;
  return visibility === 'high' ? theme.palette.brand : theme.palette.lightPure;
};

const getParagraphHoverColor = ({ themeLayout, visibility }) => {
  if (themeLayout === 'light') return theme.palette.darkDistinct;
  return visibility === 'high' ? theme.palette.lightPure : theme.palette.brand;
};

const getNotParagraphColor = ({ themeLayout, visibility }) => {
  if (themeLayout === 'light') return theme.palette.mainDark;
  return visibility === 'high' ? theme.palette.brand : theme.palette.lightPure;
};

const getNotParagraphActiveColor = ({ themeLayout, visibility }) => {
  if (themeLayout === 'light') return theme.palette.mainDark;
  return visibility === 'high' ? theme.palette.lightPure : theme.palette.brand;
};

const Link = ({
  ariaLabel,
  children,
  disabled,
  href,
  paragraph,
  sx: customSx,
  theme: themeLayout,
  to,
  variant,
  visibility,
  ...props
}) => {
  if (to && href) {
    throw new Error(
      "Component only accepts one of these 2 paremeters ('to' and 'href') at a time. Use 'to' for internal SPA navigation, and 'href' for sending user to an external link"
    );
  }

  const sxLinkBase = {
    cursor: 'pointer',
    fontWeight: 600,
  };

  const sxLinkParagraph = {
    color: getParagraphColor({ themeLayout, visibility }),
    textDecorationColor: getParagraphColor({ themeLayout, visibility }),
    '&:hover': {
      color: getParagraphHoverColor({ themeLayout, visibility }),
      textDecoration: themeLayout === 'light' ? 'underline' : 'none',
    },
    '&:focus': {
      outline: `3px solid ${theme.palette.team}`,
      outlineOffset: '1px',
      borderRadius: '1.25rem',
    },
    '&:active': {
      fontWeight: 700,
    },
  };

  const sxLinkNotParagraph = {
    padding: `${theme.spacing.xs} ${theme.spacing.s}`,
    borderRadius: '1.25rem',
    color: getNotParagraphColor({ themeLayout, visibility }),
    textDecorationColor: getNotParagraphColor({ themeLayout, visibility }),
    '&:hover': {
      backgroundColor:
        themeLayout === 'light'
          ? theme.palette.brand
          : theme.palette.darkDistinct,
      textDecoration: 'none',
    },
    '&:focus': {
      outline: `3px solid ${theme.palette.team}`,
      outlineOffset: '1px',
    },
    '&:active': {
      color: getNotParagraphActiveColor({ themeLayout, visibility }),
      fontWeight: 700,
      textDecoration: 'underline',
    },
  };

  const sxLinkDisabled = {
    color: theme.palette.disabledDark,
    textDecorationColor: theme.palette.disabledDark,
    cursor: 'auto',
  };

  let sxLink = { ...sxLinkBase };
  if (disabled) sxLink = { ...sxLink, ...sxLinkDisabled };
  else if (paragraph) sxLink = { ...sxLink, ...sxLinkParagraph };
  else sxLink = { ...sxLink, ...sxLinkNotParagraph };

  const LinkAdapter = React.forwardRef((props, ref) => (
    <RouterLink ref={ref} {...props} />
  ));

  return (
    <MuiLink
      component={to && !disabled ? LinkAdapter : undefined}
      aria-label={ariaLabel}
      href={disabled ? undefined : href}
      to={disabled ? undefined : to}
      rel={href ? 'noopener' : undefined}
      sx={{ ...sxLink, ...customSx }}
      target={href ? '_blank' : undefined}
      variant={VARIANTS_MAPPING[variant]}
      {...props}
    >
      {children}
    </MuiLink>
  );
};

Link.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  paragraph: PropTypes.bool,
  sx: PropTypes.object,
  theme: PropTypes.oneOf(BACKGROUND_THEMES),
  to: PropTypes.string,
  variant: PropTypes.oneOf(VARIANTS),
  visibility: PropTypes.oneOf(['high', 'low']),
};

Link.defaultProps = {
  disabled: false,
  paragraph: false,
  theme: 'dark',
  variant: 'paragraph',
  visibility: 'high',
};

export default Link;

import React, { useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Button from '@shared/atoms/Button';
import Input from '@shared/atoms/Input';
import Select from '@shared/atoms/Select';
import T from '@shared/atoms/Typography';

import theme from '@shared/themes/default';
import { logger } from '@shared/utils';

import { useCourse } from '../../../../../contexts/CourseContext';

const ContentWrapper = styled.div`
  padding: ${theme.spacing.m1} 0 0;
`;

const InputButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${theme.spacing.s};
  width: 100%;
`;

const SelectWrapper = styled.div`
  width: 40%;
  margin-top: ${theme.spacing.xl};
`;

const GAME_LENGTH_OPTIONS = [5, 10, 15, 20];

const GameInput = ({ currentGame, onSubmit }) => {
  const [gameName, setGameName] = useState('');
  const [gameLength, setGameLength] = useState(currentGame?.length || 0);
  const [gameId, setGameId] = useState(currentGame?.id || '');
  const [submittingGameId, setSubmittingGameId] = useState(false);
  const [isValidated, setIsValidated] = useState(false);

  const { getGameName, locked, setHasChanged } = useCourse();
  const { t } = useTranslation();

  const validateGameId = async () => {
    if (gameId?.length > 0) {
      try {
        setSubmittingGameId(true);
        const res = await getGameName(gameId);
        setGameName(res);
        if (gameLength !== 0) {
          setHasChanged(res && gameId !== currentGame?.id);
          if (res) {
            onSubmit({ id: gameId, length: gameLength });
          }
        }
      } catch (e) {
        logger.error(e);
      }
      setSubmittingGameId(false);
      setIsValidated(true);
    }
  };

  const onChangeGameId = (e) => {
    setGameId(e.target.value);
    setIsValidated(false);
  };

  const onChangeGameLength = ({ value }) => {
    setGameLength(value);

    const lengthChanged = value !== currentGame?.length && value !== 0;
    const valid = isValidated && gameName;
    const gameIdChanged = currentGame?.id !== gameId;

    if (lengthChanged && (valid || !gameIdChanged)) {
      setHasChanged(true);
      onSubmit({ id: gameId, length: value });
    }
  };

  return (
    <ContentWrapper>
      <InputButtonWrapper>
        <Input
          disabled={locked}
          onChange={onChangeGameId}
          onClick={(e) => e.stopPropagation()}
          onBlur={validateGameId}
          placeholder={t('backoffice.courses.quizTab.gamePlaceHolder')}
          value={gameId}
        />
        <Button
          ariaLabel={t('backoffice.courses.quizTab.validateGameId')}
          loading={submittingGameId}
          disabled={isValidated}
          onClick={validateGameId}
          sx={{ height: '40px' }}
          type="secondary"
        >
          {t('backoffice.courses.quizTab.validateGameId')}
        </Button>
      </InputButtonWrapper>
      {isValidated && gameName && (
        <T color={theme.palette.lightPure} sx={{ marginTop: theme.spacing.s }}>
          {gameName}
        </T>
      )}
      {isValidated && !gameName && (
        <T color={theme.palette.errorDark} sx={{ marginTop: theme.spacing.s }}>
          {t('backoffice.courses.quizTab.checkError')}
        </T>
      )}
      <SelectWrapper>
        <Select
          disabled={locked}
          id="gameLengthSelect"
          name="gameLengthSelect"
          onChange={onChangeGameLength}
          items={[
            {
              label: t('backoffice.courses.lessonsTab.playingTime'),
              value: 0,
              key: 0,
            },
            ...GAME_LENGTH_OPTIONS.map((value) => ({
              label: t('backoffice.courses.lessonsTab.minutes', {
                amount: value,
              }),
              value,
              key: value,
            })),
          ]}
          value={gameLength}
        />
      </SelectWrapper>
    </ContentWrapper>
  );
};

GameInput.propTypes = {
  currentGame: PropTypes.shape({
    id: PropTypes.string,
    length: PropTypes.number,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default GameInput;

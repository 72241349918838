import { useState, useEffect } from 'react';

export default function useUsers({ listUsersWatchData, setWatchDataError }) {
  const [usersWatchData, setUsersWatchData] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [usersNextToken, setUsersNextToken] = useState();

  const handleError = (error) => {
    console.error('Error retrieving users: ', error);
    setWatchDataError(true);
    setUsersNextToken(null);
    setUsersWatchData(false);
    setLoadingUsers(false);
  };

  const fetchUsers = async (nextToken = null) => {
    setLoadingUsers(true);
    try {
      const { data, error } = await listUsersWatchData(nextToken);
      if (error) return handleError(error);
      if (data) {
        const {
          listUsersWatchData: { items, nextToken: newNextToken },
        } = data;
        if (!nextToken) {
          setUsersWatchData(items);
        } else {
          setUsersWatchData((users) => [...users, ...items]);
        }
        if (newNextToken) {
          setUsersNextToken(newNextToken);
        } else {
          setLoadingUsers(false);
          setUsersNextToken(null);
        }
      }
    } catch (error) {
      handleError(error);
    }

    return true;
  };

  useEffect(() => {
    if (usersNextToken) {
      fetchUsers(usersNextToken);
    }
  }, [usersNextToken]);

  return {
    usersWatchData,
    loadingUsersWatchData: loadingUsers,
    fetchUsersWatchData: fetchUsers,
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import T from 'shared/atoms/Typography';
import theme from 'shared/themes/default';

const OuterWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ModalWrapper = styled.div`
  width: 626px;
`;

const ModalHeader = styled.div`
  align-items: center;
  background: ${({ background }) => background};
  border-radius: 0.375rem 0.375rem 0 0;
  color: ${theme.palette.mainDark};
  display: flex;
  padding: ${theme.spacing.m1} ${theme.spacing.m2};
  width: 100%;
  ${theme.breakpoints.mobile} {
    padding: ${theme.spacing.s} ${theme.spacing.m2};
  }
`;

const ModalHeaderIcon = styled.div`
  align-items: center;
  background: ${theme.palette.darkWrapper};
  border-radius: 2.5rem;
  color: ${theme.palette.lightPure};
  display: flex;
  flex: 0 0 auto;
  height: 2.5rem;
  justify-content: center;
  margin-right: ${theme.spacing.m1};
  width: 2.5rem;
`;

const ModalHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  ${theme.breakpoints.mobile} {
    flex-direction: column;
  }
`;

const ModalHeaderTitle = styled(T)`
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  width: 100%;
`;

const ModalHeaderText = styled(T)`
  align-items: center;
  display: flex;
  flex: 0 0 auto;
`;

const ModalBody = styled.div`
  background: ${theme.palette.darkWrapper};
  border-radius: ${({ footer, header }) =>
    `${header ? '0 0' : '0.375rem 0.375rem'} ${
      footer ? '0 0' : '0.375rem 0.375rem'
    }`};
  padding: ${theme.spacing.l} ${theme.spacing.xl};
  width: 100%;
  ${theme.breakpoints.mobile} {
    padding: ${theme.spacing.m3} ${theme.spacing.s};
  }
`;

const ModalFooter = styled.div`
  background: ${theme.palette.oddDark};
  border-radius: 0 0 0.375rem 0.375rem;
  display: flex;
  justify-content: center;
  padding: ${theme.spacing.m2} 0;
  width: 100%;
`;

const Modal = ({
  body,
  footer,
  headerBackground,
  headerIcon: HeaderIcon,
  headerText,
  headerTitle,
}) => {
  const header = !!HeaderIcon || !!headerText || !!headerTitle;

  return (
    <OuterWrapper>
      <ModalWrapper>
        {header && (
          <ModalHeader background={headerBackground}>
            {!!HeaderIcon && (
              <ModalHeaderIcon>
                <HeaderIcon />
              </ModalHeaderIcon>
            )}
            <ModalHeaderWrapper>
              {!!headerTitle && (
                <ModalHeaderTitle
                  fontWeight="bold"
                  variant="headingXs"
                  color={theme.palette.mainDark}
                >
                  {headerTitle}
                </ModalHeaderTitle>
              )}
              {!!headerText && (
                <ModalHeaderText
                  variant="subtitle"
                  phoneVariant="paragraphS"
                  color={theme.palette.mainDark}
                >
                  {headerText}
                </ModalHeaderText>
              )}
            </ModalHeaderWrapper>
          </ModalHeader>
        )}
        <ModalBody footer={!!footer} header={header}>
          {body}
        </ModalBody>
        {footer && <ModalFooter>{footer}</ModalFooter>}
      </ModalWrapper>
    </OuterWrapper>
  );
};

Modal.propTypes = {
  body: PropTypes.object,
  footer: PropTypes.object,
  headerBackground: PropTypes.string,
  headerIcon: PropTypes.object,
  headerText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  headerTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

Modal.defaultProps = {
  headerBackground: theme.palette.disabledLight,
};

export default Modal;

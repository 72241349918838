export default {
  data: {
    languages: {
      en: 'en',
      no: 'no',
      es: 'es',
    },
    domainSuggestions: ['no', 'es'],
    languagesSuggestions: [
      { id: 'en', name: 'English' },
      { id: 'no', name: 'Norsk' },
      { id: 'es', name: 'Español' },
    ],
    currencySuggestions: ['NOK', 'EUR'],
  },
};

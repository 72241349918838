import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Button from 'shared/atoms/Button';
import HorizontalRule from 'shared/atoms/HorizontalRule';
import SearchField from 'shared/atoms/SearchField';
import T from 'shared/atoms/Typography';

import theme from 'shared/themes/default';
import { invitationStatus, invitationSource } from 'shared/const';
import { downloadFile } from 'shared/utils';

import { useOrganizationMembers } from '../../context';

import FilterDropdown from '../FilterDropdown';
import InvitedMembersTable from '../InvitedMembersTable';
import Totals from '../Totals';

const StyledExportBlock = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 75%;
  margin: 1rem 1rem 1rem 0;
  ${theme.breakpoints.mobile} {
    width: 100%;
  }
`;

const StyledInvitedTableWrapper = styled.div`
  margin: 1rem 0 1rem 0 1rem;
  padding-bottom: 1.5rem;
`;

const StyledBlock = styled.div`
  min-width: 25%;
  ${theme.breakpoints.mobile} {
    width: 100%;
  }
`;

const StyledFilterWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${theme.breakpoints.mobile} {
    display: block;
  }
`;

const filterOptions = (t) => [
  { id: invitationStatus.pending, label: t('administrateUsers.queued') },
  { id: invitationStatus.invited, label: t('administrateUsers.unanswered') },
  { id: invitationStatus.bounced, label: t('administrateUsers.bounced') },
  {
    id: invitationSource.client,
    label: t('administrateUsers.invitedFromClient'),
  },
  {
    id: invitationSource.backoffice,
    label: t('administrateUsers.invitedFromBackoffice'),
  },
  {
    id: invitationSource.import,
    label: t('administrateUsers.invitedFromImport'),
  },
];

const Invited = () => {
  const { t } = useTranslation();

  const [filteredInvitedMembers, setFilteredInvitedMembers] = useState();
  const [searchedInvitedMembers, setSearchedInvitedMembers] = useState();
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedActionText, setSelectedActionText] = useState();
  const [loadingExport, setLoadingExport] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [formStatus, setFormStatus] = useState();
  const [totalInvitedMembers, setTotalInvitedMembers] = useState();
  const [totalBounced, setTotalBounced] = useState();
  const [totalQueued, setTotalQueued] = useState();
  const [totalUnanswered, setTotalUnanswered] = useState();
  const [actionOptions, setActionOptions] = useState([]);

  const {
    downloadInvitations,
    invitations: {
      failureList,
      invitedMembers,
      setFailureList,
      setInvitedMembers,
      setShowMultipleFeedback,
      setSuccessList,
      showMultipleFeedback,
      successList,
    },
    isManager,
    removeInvitations,
    resendInvitations,
    selectedAction,
    setSelectedAction,
    totalLicences,
    user,
  } = useOrganizationMembers();

  useEffect(() => {
    if (invitedMembers) {
      setTotalInvitedMembers(invitedMembers.length);
      setTotalBounced(
        invitedMembers.filter(
          (member) => member.status === invitationStatus.bounced
        ).length
      );
      setTotalQueued(
        invitedMembers.filter(
          (member) => member.status === invitationStatus.pending
        ).length
      );
      setTotalUnanswered(
        invitedMembers.filter(
          (member) => member.status === invitationStatus.invited
        ).length
      );
    } else {
      setTotalInvitedMembers();
      setTotalBounced();
      setTotalQueued();
      setTotalUnanswered();
    }
  }, [invitedMembers]);

  useEffect(() => {
    setActionOptions([
      { value: 'remove', label: t('administrateUsers.removeInvitations') },
      ...(totalLicences
        ? [
            {
              value: 'resend',
              label: t('administrateUsers.resendInvitations'),
            },
          ]
        : []),
    ]);
  }, [totalLicences]);

  const totalsData = () => {
    const data = {};
    data[t('administrateUsers.queued')] = totalQueued;
    data[t('administrateUsers.bounced')] = totalBounced;
    data[t('administrateUsers.unanswered')] = totalUnanswered;
    return data;
  };

  const downloadReport = async () => {
    try {
      setLoadingExport(true);

      // Call backend to generate the file and return the required info for building the download URL
      const { data, error } = await downloadInvitations();
      if (error) {
        console.error('Error calling downloadInvitations', error);
        setLoadingExport(false);
        return;
      }
      const {
        downloadInvitations: { cloudfrontUrl, fileName },
      } = data;

      await downloadFile(
        user?.signInUserSession?.accessToken?.jwtToken,
        cloudfrontUrl,
        fileName
      );
      setLoadingExport(false);
    } catch (error) {
      setLoadingExport(false);
      console.error('Some error happened when building the export file', error);
    }
  };

  const onFilterChange = (values) => {
    const isStatusFilter =
      values.includes(invitationStatus.pending) ||
      values.includes(invitationStatus.bounced) ||
      values.includes(invitationStatus.invited);
    const isSourceFilter =
      values.includes(invitationSource.client) ||
      values.includes(invitationSource.backoffice) ||
      values.includes(invitationSource.import);

    if (values.length > 0) {
      let filteredInvitedMembers = null;
      if (isStatusFilter) {
        filteredInvitedMembers = invitedMembers.filter(
          (item) => item.status && values.includes(item.status)
        );
      }

      if (isSourceFilter) {
        filteredInvitedMembers = (
          filteredInvitedMembers || invitedMembers
        ).filter((item) => item.source && values.includes(item.source));
      }

      setFilteredInvitedMembers(filteredInvitedMembers);
    } else {
      setFilteredInvitedMembers(null);
    }

    setSelectedFilters(values);
  };

  const onSearchHandler = ({ value }) => {
    setSearchTerm(value);
    if (value) {
      const searchedInvitedMembers = (
        filteredInvitedMembers || invitedMembers
      ).filter((member) => {
        return (
          member.name.toLowerCase().includes(value.toLowerCase()) ||
          member.email.toLowerCase().includes(value.toLowerCase())
        );
      });
      setSearchedInvitedMembers(searchedInvitedMembers);
    } else {
      setSearchedInvitedMembers(null);
    }
  };

  const onActionChange = (value) => {
    const selectedItem = actionOptions.find((item) => item.value === value);
    setSelectedAction(value);
    setSelectedActionText(selectedItem.label);
  };

  const updateInvitationsFor = (reinvitedEmails) => {
    setInvitedMembers(
      invitedMembers.map((member) => {
        if (reinvitedEmails.includes(member.email)) {
          return {
            ...member,
            lastReminderAt: new Date().toISOString(),
            numberOfReminder: member.numberOfReminder
              ? member.numberOfReminder + 1
              : 1,
          };
        }
        return member;
      })
    );
  };

  const onActionApplyHandler = async (selectedEmails) => {
    if (selectedEmails.length <= 0) {
      return false;
    }
    const updateSelectedUsers = selectedEmails.map((email) => {
      const member = invitedMembers.find((member) => member.email === email);
      if (member) {
        const { name, email, role } = member;
        return { name, email, role };
      }
      return false;
    });
    if (selectedAction && selectedAction === 'resend') {
      const { data, error } = await resendInvitations({
        users: updateSelectedUsers,
      });
      if (error) {
        setFormStatus({ success: false, message: `${error.message}` });
      }

      if (data) {
        const { failure, success } = data.resendInvitations;
        if (failure && failure.count > 0) {
          setFailureList(failure.items);
        }
        if (success && success.count > 0) {
          setSuccessList(success.items);
          const sucessfullReInvites = success.items.map(
            (member) => member.email
          );
          updateInvitationsFor(sucessfullReInvites);
        }
        setShowMultipleFeedback(true);
      }
    } else if (selectedAction && selectedAction === 'remove') {
      const { data, error } = await removeInvitations({
        users: updateSelectedUsers,
      });

      if (error) {
        setFormStatus({ success: false, message: `${error.message}` });
      }

      if (data) {
        const { failure, success } = data.removeInvitations;
        if (failure && failure.count > 0) {
          setFailureList(failure.items);
        }
        if (success && success.count > 0) {
          setSuccessList(success.items);
          const sucessfullDeletions = success.items.map(
            (member) => member.email
          );
          setInvitedMembers(
            invitedMembers.filter(
              (member) => !sucessfullDeletions.includes(member.email)
            )
          );
        }
        setShowMultipleFeedback(true);
      }
    }
    return true;
  };

  const onConfirmationDialogCloseHandler = async () => {
    setFormStatus(null);
    setShowMultipleFeedback(false);
    setFailureList([]);
    setSuccessList([]);
    setSelectedAction('');
  };

  if (isManager || !invitedMembers?.length) return null;

  return (
    <>
      <T
        color={theme.palette.lightPure}
        fontWeight="medium"
        sx={{ mb: theme.spacing.s, mt: theme.spacing.m1 }}
        variant="subtitle"
      >
        {t('administrateUsers.invitedMembersSummary', {
          count: totalInvitedMembers,
        })}
      </T>
      {!!totalInvitedMembers && (
        <>
          <Totals data={totalsData()} />
          <StyledFilterWrapper>
            <StyledExportBlock>
              <FilterDropdown
                options={filterOptions(t)}
                selectedFilters={selectedFilters}
                onChange={onFilterChange}
              />
              <Button
                ariaLabel={t('administrateUsers.exportAll')}
                onClick={downloadReport}
                loading={loadingExport}
                theme="dark"
                type="primary"
              >
                {t('administrateUsers.exportAll')}
              </Button>
            </StyledExportBlock>
            <StyledBlock>
              <SearchField
                type="secondary"
                placeholder={t('administrateUsers.searchForPeople')}
                ariaLabel={t('administrateUsers.searchForPeople')}
                onChange={onSearchHandler}
                value={searchTerm}
              />
            </StyledBlock>
          </StyledFilterWrapper>
          <StyledInvitedTableWrapper>
            <InvitedMembersTable
              invitedMembers={
                searchedInvitedMembers ||
                filteredInvitedMembers ||
                invitedMembers
              }
              selectedAction={selectedAction}
              selectedActionText={selectedActionText}
              actionOptions={actionOptions}
              onActionChange={onActionChange}
              onActionApplyHandler={onActionApplyHandler}
              onConfirmationDialogCloseHandler={
                onConfirmationDialogCloseHandler
              }
              formStatus={formStatus}
              successList={successList}
              failureList={failureList}
              showMultipleFeedback={showMultipleFeedback}
              setShowMultipleFeedback={setShowMultipleFeedback}
            />
          </StyledInvitedTableWrapper>
          <HorizontalRule />
        </>
      )}
    </>
  );
};

export default Invited;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Form from '@shared/atoms/Form';
import FormInput from '@shared/atoms/FormFields/FormInput';
import Dialog from '@shared/molecules/Dialog';
import theme from '@shared/themes/default';
import { useForm, logger } from '@shared/utils';

import { useAppGlobals } from '../../../contexts/AppContext';

import InvoiceForm from './InvoiceForm';
import { createOrganization } from '../graphql/mutations';
import { useGraphQLQuery } from '../../../../utils';

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.s};
`;

const CreateOrganizationDialog = ({
  onClose,
  onCreateOrganization,
  open,
  setErrorMessage,
  setHasError,
  setHasSuccess,
  setSuccessMessage,
  subscriptionPlans,
  subscriptionPlansLoading,
}) => {
  const [selectedPlanId, setSelectedPlanId] = useState();

  const { domain } = useAppGlobals();
  const { t } = useTranslation();

  const {
    callQuery: callCreateOrganizationQuery,
    data: createOrganizationData,
    errors: createOrganizationErrors,
    hasEnded: hasCreateOrganizationEnded,
    hasErrors: hasCreateOrganizationErrors,
  } = useGraphQLQuery();

  useEffect(() => {
    if (hasCreateOrganizationEnded) {
      if (hasCreateOrganizationErrors) {
        logger.error('Error: ', createOrganizationErrors);
        setErrorMessage(
          t('backoffice.organizations.createOrganization.failedToCreate')
        );
        setHasError(true);
      } else {
        const { createOrganization } = createOrganizationData;

        const plan = subscriptionPlans.find(
          ({ value }) => value === selectedPlanId
        );
        onCreateOrganization({
          ...createOrganization,
          subscriptions: plan
            ? [{ planId: plan.value, planName: plan.label }]
            : [],
          registeredMembers: 0,
        });
        setSuccessMessage(
          t('backoffice.organizations.createOrganization.successfullyCreated', {
            name: createOrganization.name,
          })
        );
        setHasSuccess(true);
      }
    }

    onClose();
  }, [hasCreateOrganizationEnded]);

  const validateOrganizationNumber = (value) => {
    if (!value) return true;

    if (domain === 'es') {
      return /^[A-Z0-9][0-9]{7}[A-Z0-9]$/i.test(value);
    }

    return /^[0-9]{9}$/.test(value);
  };

  const {
    disableSubmit,
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
    properties,
    setDefaults,
    submitting,
    values,
  } = useForm({
    fields: {
      name: {
        required: true,
      },
      ownerName: {
        required: true,
      },
      ownerEmail: {
        lowerCase: true,
        required: true,
        type: 'email',
      },
      organizationNumber: {
        maxLength: 9,
        upperCase: true,
        validation: validateOrganizationNumber,
        validationMessage:
          domain === 'no'
            ? t('errors.organizationNumberValidation')
            : t('errors.organizationNifValidation'),
      },
      invoiceName: {},
      invoiceEmail: {
        lowerCase: true,
        type: 'email',
      },
      invoiceAddress: {},
      invoiceZip: {
        maxLength: 4,
      },
      invoiceCity: {},
      invoiceSendVia: {
        default: 'none',
      },
      invoicePeriod: {
        default: 'none',
      },
      invoiceNote: {},
      subscriptionPlan: {
        required: true,
      },
    },
    onSubmitHook: ({ subscriptionPlan, ...organization }) => {
      const input = Object.entries(organization).reduce(
        (acc, [key, value]) => {
          if (value) {
            acc[key] = value;
          }
          return acc;
        },
        {
          domain,
          subscriptionPlanId: subscriptionPlan,
        }
      );

      setSelectedPlanId(subscriptionPlan);

      if (input.invoicePeriod === 'none') {
        delete input.invoicePeriod;
      }
      if (input.invoiceSendVia === 'none') {
        delete input.invoiceSendVia;
      }

      callCreateOrganizationQuery(createOrganization(input));
    },
    onSubmitHookEnded: hasCreateOrganizationEnded,
  });

  useEffect(() => {
    if (!subscriptionPlansLoading && subscriptionPlans.length > 0) {
      setDefaults({
        subscriptionPlan: subscriptionPlans[0].value,
      });
    }
  }, [subscriptionPlans, subscriptionPlansLoading]);

  const actions = [
    {
      ariaLabel: t('generic.cancel'),
      label: t('generic.cancel'),
      handler: onClose,
      type: 'secondaryOutline',
    },
    {
      ariaLabel: t('backoffice.organizations.createOrganization.create'),
      label: t('backoffice.organizations.createOrganization.create'),
      handler: handleSubmit,
      disabled: disableSubmit,
      submit: true,
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      actions={actions}
      title={t('backoffice.organizations.createOrganization.title')}
      ariaLabel={t('backoffice.organizations.createOrganization.title')}
      loading={submitting}
    >
      <Form onSubmit={handleSubmit}>
        <FormWrapper>
          <FormInput
            label={t(
              'backoffice.organizations.createOrganization.organizationName'
            )}
            id="name"
            name="name"
            placeholder={t(
              'backoffice.organizations.createOrganization.organizationNamePlaceholder'
            )}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            properties={properties}
            values={values}
            theme="light"
          />
          <FormInput
            label={t(
              'backoffice.organizations.createOrganization.administratorName'
            )}
            id="ownerName"
            name="ownerName"
            placeholder={t(
              'backoffice.organizations.createOrganization.administratorNamePlaceholder'
            )}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            properties={properties}
            values={values}
            theme="light"
          />
          <FormInput
            label={t(
              'backoffice.organizations.createOrganization.administratorEmail'
            )}
            id="ownerEmail"
            name="ownerEmail"
            placeholder={t(
              'backoffice.organizations.createOrganization.administratorEmailPlaceholder'
            )}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            properties={properties}
            values={values}
            theme="light"
          />
          <InvoiceForm
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            properties={properties}
            subscriptionPlans={subscriptionPlans}
            theme="light"
            values={values}
          />
        </FormWrapper>
      </Form>
    </Dialog>
  );
};

CreateOrganizationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCreateOrganization: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  setHasError: PropTypes.func.isRequired,
  setHasSuccess: PropTypes.func.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  subscriptionPlans: PropTypes.array.isRequired,
  subscriptionPlansLoading: PropTypes.bool.isRequired,
};

export default CreateOrganizationDialog;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';
import MuiTooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import TitleComponent from './TitleComponent';
import { commonStyles } from './sharedStyles';

const useMobileStyles = makeStyles({
  ...commonStyles,
  tooltip: {
    ...commonStyles.tooltip,
    minWidth: '99vw',
  },
});

/*
  Applying an overlay to all wrapper (and all descendants) of the content.
  It can be done since tooltip is added to the body outside main React node.
  In client target is #page-template, in storybook it's #root
*/
const FakeOverlay = createGlobalStyle`
  #page-template, #root {
    opacity: 0.5;
  }
`;

const TooltipMobile = ({ children, title, ...props }) => {
  const [open, setOpen] = useState(false);
  const classes = useMobileStyles();

  const handleClose = () => setOpen(false);

  return (
    <>
      {open && <FakeOverlay />}
      <MuiTooltip
        open={open}
        classes={classes}
        enterTouchDelay={300}
        leaveTouchDelay={5000}
        onClose={handleClose}
        onOpen={() => setOpen(true)}
        title={<TitleComponent title={title} onClose={handleClose} />}
        {...props}
        arrow={false}
        placement="top"
      >
        {children}
      </MuiTooltip>
    </>
  );
};

TooltipMobile.propTypes = {
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default TooltipMobile;

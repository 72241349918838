import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import T from 'shared/atoms/Typography';
import Autocomplete from 'shared/atoms/Autocomplete';
import Select from 'shared/atoms/Select';
import theme from 'shared/themes/default';

import { commonProps, defaultProps } from './props';

const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.palette.darkWrapper};
  padding: ${theme.spacing.m3} ${theme.spacing.m1};
`;

const TypographyWrapper = styled.div`
  margin-bottom: ${theme.spacing.m1};
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SelectWrapper = styled.div`
  width: 100%;
  display: ${(props) => (props.searchOpen ? 'none' : 'block')};
  margin-right: ${theme.spacing.s};
`;

const MobileTabs = ({
  title,
  tabsConfig,
  selectedTab,
  setSelectedTab,
  onChangeSearch,
  onClearSearch,
  searchingInTitle,
  searchPlaceholder,
  searchOptions,
  getOptionKey,
  searchDisabled,
  searchLoading,
  preSelectedValue,
}) => {
  const [searchOpen, setSearchOpen] = useState(false);

  const options = tabsConfig.map((o) => ({ ...o, value: o.key }));

  const label = searchOpen ? searchingInTitle : title;
  return (
    <TabsWrapper>
      <TypographyWrapper>
        <T fontWeight="semibold" color={theme.palette.lightPure}>
          {label}
        </T>
      </TypographyWrapper>
      <InputsWrapper>
        <SelectWrapper searchOpen={searchOpen}>
          <Select
            id="tabs-menu-select"
            name="tabs-menu-select"
            hasActions
            items={options}
            onChange={({ value }) => setSelectedTab(value)}
            theme="dark"
            value={selectedTab}
          />
        </SelectWrapper>
        <Autocomplete
          options={searchOptions}
          filterOptions
          onChange={onChangeSearch}
          onClear={() => {
            setSearchOpen(false);
            onClearSearch();
          }}
          onExpand={() => setSearchOpen(true)}
          placeholder={searchPlaceholder}
          shrinked
          getOptionKey={getOptionKey}
          disabled={searchDisabled}
          loading={searchLoading}
          preSelectedValue={preSelectedValue}
          type="secondary"
        />
      </InputsWrapper>
    </TabsWrapper>
  );
};

MobileTabs.propTypes = {
  ...commonProps,
  searchingInTitle: PropTypes.string.isRequired,
};

MobileTabs.defaultProps = {
  ...defaultProps,
};

export default MobileTabs;

import { API } from 'aws-amplify';
import { logger } from '@shared/utils';

export const graphql = async (operation, fail) => {
  let data = null;
  try {
    data = await API.graphql(operation);
  } catch (error) {
    logger.log('Error: ', error);
    if (fail) {
      throw error;
    }
    return { error };
  }

  return data;
};

import theme from 'shared/themes/default';

export const InputStyle = (themeLayout) => ({
  color: theme.palette.mainDark,
  '&.MuiSelect-select, & .MuiNativeSelect-select': {
    background: theme.palette.lightPure,
    borderRadius: '6px',
    border: `1px solid ${theme.palette.disabledLight}`,
    padding: `${theme.spacing.xs} ${theme.spacing.s}`,
    height: '30px',
    lineHeight: '30px',
  },
  // Native select has a specific rule for focus defining these two properties
  '& .MuiNativeSelect-select:focus': {
    background: theme.palette.lightPure,
    borderRadius: '6px',
  },
  '&:focus, & :focus': {
    outline: `3px solid ${theme.palette.focus}`,
    outlineOffset: '1px',
  },
  '&:hover, & :hover': {
    backgroundColor: theme.palette.lightDistinct,
    borderRadius: '6px',
  },
  '&.Mui-disabled, & .Mui-disabled': {
    backgroundColor:
      themeLayout === 'dark'
        ? theme.palette.disabledLight
        : theme.palette.lightDistinct,
    borderRadius: '6px',
  },
});

export const ButtonStyle = (hasBackground, themeLayout) => {
  const textColor =
    themeLayout === 'dark' ? theme.palette.lightPure : theme.palette.mainDark;
  const backgroundColor =
    themeLayout === 'dark'
      ? theme.palette.darkDistinct
      : theme.palette.lightInteracting;
  const backgroundColorDisabled =
    themeLayout === 'dark'
      ? theme.palette.disabledLight
      : theme.palette.lightDistinct;
  return {
    textDecoration: hasBackground ? 'none' : 'underline',
    '&.MuiSelect-select, & .MuiNativeSelect-select': {
      padding: `${theme.spacing.xs} ${theme.spacing.m1}`,
      height: '30px',
      lineHeight: '30px',
      borderRadius: theme.spacing.m2,
      backgroundColor: hasBackground ? theme.palette.lightPure : 'none',
      color: hasBackground ? theme.palette.mainDark : textColor,
      border: hasBackground ? `1px solid ${theme.palette.mainDark}` : 'none',
    },
    // Native select has a specific rule for focus defining these two properties
    '& .MuiNativeSelect-select:focus': {
      backgroundColor: hasBackground
        ? theme.palette.lightInteracting
        : backgroundColor,
    },
    '&:focus, & :focus': {
      outline: `3px solid ${theme.palette.focus}`,
      outlineOffset: '1px',
    },
    '&:hover, & :hover': {
      backgroundColor: hasBackground
        ? theme.palette.lightInteracting
        : backgroundColor,
      borderRadius: theme.spacing.m2,
    },
    '&.MuiSelect-select.Mui-disabled, & .MuiNativeSelect-select.Mui-disabled': {
      backgroundColor: backgroundColorDisabled,
      border: hasBackground
        ? `1px solid ${theme.palette.disabledLight}`
        : 'none',
    },
  };
};

export const InputMenuStyle = {
  '& .MuiMenu-paper': {
    border: `1px solid ${theme.palette.disabledLight}`,
    boxShadow: '0px 0px 10px 2px rgb(0 0 0 / 15%)',
    borderRadius: '0.375rem',
  },
};

export const InputItemStyle = {
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  '&.Mui-selected, &.Mui-selected.Mui-focusVisible': {
    backgroundColor: theme.palette.lightInteracting,
    fontWeight: 600,
  },
  '&:hover, &.Mui-selected:hover': {
    backgroundColor: theme.palette.darkDistinct,
    color: theme.palette.lightPure,
    fontWeight: 600,
  },
};

export const ActionMenuStyle = {
  '& .MuiMenu-paper': {
    border: `1px solid ${theme.palette.disabledLight}`,
    borderRadius: '0.625rem',
  },
};

export const ActionItemStyle = {
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  '&.Mui-selected, &.Mui-selected.Mui-focusVisible': {
    backgroundColor: theme.palette.lightInteracting,
    fontWeight: 600,
  },
  '&:hover': {
    backgroundColor: 'transparent',
    fontWeight: 600,
    textDecoration: 'underline',
  },
  '&.Mui-selected:hover': {
    backgroundColor: theme.palette.lightInteracting,
  },
};

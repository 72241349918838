import { useEffect, useState } from 'react';

import { PLAYLIST_TYPE, PLAYLIST_STATUS } from 'shared/const';
import { selectTranslation } from 'shared/utils';

export default ({
  handleHistory,
  isGlobalOrganization,
  listOrganizationPlaylistCreators,
  listPlaylists,
  locale,
  managerSelected,
  organizationId,
  setPlaylistError,
  sortPlaylistContent,
  typeSelected,
  userId,
  getPlaylistContent,
  updatePlaylist,
}) => {
  const [canAddCourses, setCanAddCourses] = useState(true);
  const [canSortCourses, setCanSortCourses] = useState(true);
  const [isNanodegree, setIsNanodegree] = useState(false);
  const [listOwnedPlaylists, setListOwnedPlaylists] = useState([]);
  const [loadingContent, setLoadingContent] = useState(false);
  const [loadingPlaylists, setLoadingPlaylists] = useState(true);
  const [playlistContent, setPlaylistContent] = useState([]);
  const [playlistName, setPlaylistName] = useState('');
  const [playlists, setPlaylists] = useState([]);
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);

  const [isPlaylistManagersLoaded, setPlaylistManagersAsLoaded] =
    useState(false);
  const [playlistManagersNextToken, setPlaylistManagersNextToken] = useState();
  const [playlistManagers, setPlaylistManagers] = useState([]);

  const handleError = (error) => {
    console.error('Error calling listPlaylists', error);
    setPlaylistError(true);
    setPlaylists([]);
    setLoadingPlaylists(false);
  };

  const fetchPlaylists = async (
    managerSelected,
    typeSelected,
    organizationId
  ) => {
    setLoadingPlaylists(true);
    try {
      const type =
        typeSelected in PLAYLIST_TYPE ? typeSelected.toUpperCase() : null;

      const { data, error } = await listPlaylists(
        organizationId,
        managerSelected,
        type
      );
      if (error) handleError(error);
      if (data) {
        setPlaylists(data.listPlaylists.items);
        setLoadingPlaylists(false);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const loadPlaylistContent = async (playlist) => {
    setLoadingContent(true);
    if (playlist.refId !== null) {
      setCanAddCourses(false);
      setCanSortCourses(false);
    } else if (
      playlist.type === PLAYLIST_TYPE.NANO_DEGREE &&
      playlist.status !== PLAYLIST_STATUS.DRAFT
    ) {
      setCanAddCourses(false);
      setCanSortCourses(true);
    } else {
      setCanAddCourses(true);
      setCanSortCourses(true);
    }

    const { data, error } = await getPlaylistContent(playlist.children || []);
    setLoadingContent(false);
    if (error) {
      return false;
    }

    if (data && data.getCourses) {
      return data.getCourses;
    }
  };

  const selectPlaylistHandler = async (id) => {
    if (id === selectedPlaylist) {
      setSelectedPlaylist(null);
      handleHistory(`manager=${managerSelected}`);
    } else {
      setSelectedPlaylist(id);
      handleHistory(
        `manager=${managerSelected}&playlist=${id}${
          typeSelected && isGlobalOrganization
            ? `&type=${typeSelected.toLowerCase()}`
            : ''
        }`
      );
      const playlist = listOwnedPlaylists.find(
        (playlist) => playlist.id === id
      );

      const playlistContent = await loadPlaylistContent(playlist);

      setPlaylistContent(playlistContent);
      setIsNanodegree(playlist.type === PLAYLIST_TYPE.NANO_DEGREE);

      const title = selectTranslation(
        playlist.titleTranslations,
        playlist.title,
        locale,
        ''
      );
      setPlaylistName(title);

      return true;
    }
  };

  const playlistContentOrderHandler = async (
    newPosition,
    childId,
    sortedArray
  ) => {
    const playlist = listOwnedPlaylists.find(
      (playlist) => playlist.id === selectedPlaylist
    );
    const item = (playlist.children || []).find((child) => child === childId);

    if (item) {
      const input = {
        id: selectedPlaylist,
        position: newPosition,
        contentId: childId,
      };

      const { error } = await sortPlaylistContent({ input });
      if (error) {
        console.error('Error: ', error);
      }
      // Update local state playlist content
      const updatedPlaylist = {
        ...playlist,
        children: sortedArray.map((item) => item.id),
      };

      setListOwnedPlaylists(
        listOwnedPlaylists.map((playlist) => {
          if (playlist.id === selectedPlaylist) {
            return updatedPlaylist;
          }
          return playlist;
        })
      );
      setPlaylistContent(sortedArray);
    }

    return true;
  };

  const playlistOrderHandler = async (
    newPosition,
    playlistId,
    orderedPlaylist
  ) => {
    const playlist = listOwnedPlaylists.find(
      (playlist) => playlist.id === playlistId
    );

    if (playlist) {
      const input = {
        id: playlistId,
        position: newPosition,
      };

      const { data } = await updatePlaylist({ input });

      if (data && data.updatePlaylist) {
        setListOwnedPlaylists(orderedPlaylist);
      }
    }
  };

  const getPlaylistCreators = async (nextToken) => {
    const { data, error } = await listOrganizationPlaylistCreators(
      50,
      nextToken
    );

    if (error) {
      console.error('Error calling listOrganizationPlaylistCreators', error);
    }

    if (data) {
      const { items, nextToken: newNextToken } =
        data.listOrganizationPlaylistCreators;
      const playlistManagers = items.map((manager) => {
        const playlistManager = {
          id: manager.id,
          name: manager.name,
          email: manager.email,
          ownedPlaylistsNumber: manager.ownedPlaylistsNumber,
          isSubscriptionAdmin: manager.isSubscriptionAdmin,
        };

        return playlistManager;
      });

      if (!nextToken) {
        setPlaylistManagers(playlistManagers);
      } else {
        setPlaylistManagers((managers) => [...managers, ...playlistManagers]);
      }

      if (newNextToken) {
        setPlaylistManagersNextToken(newNextToken);
      } else {
        setPlaylistManagersAsLoaded(false);
        setPlaylistManagersNextToken(null);
      }
    }
  };

  const increasePlaylistCounter = (managerId) => {
    setPlaylistManagers(
      playlistManagers.map((manager) => {
        if (manager.id === (managerId || userId)) {
          return {
            ...manager,
            ownedPlaylistsNumber: manager.ownedPlaylistsNumber + 1,
          };
        }
        return manager;
      })
    );
  };

  const decreasePlaylistCounter = (managerId) => {
    setPlaylistManagers(
      playlistManagers.map((manager) => {
        if (manager.id === (managerId || userId)) {
          return {
            ...manager,
            ownedPlaylistsNumber: manager.ownedPlaylistsNumber - 1,
          };
        }
        return manager;
      })
    );
  };

  useEffect(() => {
    setPlaylists([]);
    setSelectedPlaylist(null);
    setPlaylistContent([]);
    setListOwnedPlaylists([]);
    fetchPlaylists(
      managerSelected !== 'all' ? managerSelected : '',
      typeSelected,
      organizationId
    );
  }, [managerSelected, typeSelected, organizationId]);

  useEffect(() => {
    setListOwnedPlaylists(playlists);
  }, [playlists]);

  useEffect(() => {
    if (playlistManagersNextToken) {
      getPlaylistCreators(playlistManagersNextToken);
    }
  }, [playlistManagersNextToken]);

  useEffect(() => {
    if (!isPlaylistManagersLoaded) {
      getPlaylistCreators();
    }
  }, [isPlaylistManagersLoaded]);

  return {
    allPlaylists: playlists,
    canAddCourses,
    canSortCourses,
    decreasePlaylistCounter,
    fetchPlaylists,
    increasePlaylistCounter,
    isNanodegree,
    listOwnedPlaylists,
    loadingContent,
    loadingPlaylists,
    loadPlaylistContent,
    playlistContent,
    playlistContentOrderHandler,
    playlistManagers,
    playlistName,
    playlistOrderHandler,
    selectedPlaylist,
    selectPlaylistHandler,
    setListOwnedPlaylists,
    setPlaylistContent,
    setSelectedPlaylist,
  };
};

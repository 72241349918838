export const moveUser = (input) => ({
  query: `mutation moveUser($input: MoveUserInput!) {
    moveUser(input: $input) {
      success
      message
    }
  }`,
  variables: {
    input,
  },
});

export const deleteUser = (userSub) => ({
  query: `mutation deleteUser(
    $input: DeleteUserInput!
  ) {
    deleteUser(input: $input) {
      userSub
    }
  }`,
  variables: {
    input: {
      userSub,
    },
  },
});

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import useMediaQuery from '@mui/material/useMediaQuery';

import T from 'shared/atoms/Typography';
import Button from 'shared/atoms/Button';
import HorizontalRule from 'shared/atoms/HorizontalRule';
import CircularSpinner from 'shared/atoms/CircularSpinner';
import ItemBox from 'shared/molecules/ItemBox';
import GroupMembersTable from 'shared/organisms/GroupMembersTable';
import EditGroup from 'shared/organisms/EditGroup';
import SelectGroupMembersAndPreview from 'shared/organisms/SelectGroupMembersAndPreview';
import ViewGroupMembersMobileView from 'shared/organisms/ViewGroupMembersMobileView';
import TransferOwnershipView from 'shared/organisms/TransferOwnershipView';
import { formatDate } from 'shared/utils';
import theme from 'shared/themes/default';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  ${theme.breakpoints.mobile} {
    flex-direction: column;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m1};
  margin-top: ${theme.spacing.m3};
`;

const StyledHorizontalRule = styled(HorizontalRule)`
  margin-top: 30px;
`;

const SideBlock = styled.div`
  flex: 1;
  padding-left: ${(props) => (props.sidePadding ? '25px' : '0')};
  max-width: 50%;
  ${theme.breakpoints.mobile} {
    max-width: 100%;
  }
`;

const GroupsListWrapper = styled.div`
  margin-top: ${theme.spacing.m2};
  ${theme.breakpoints.mobile} {
    margin-top: 20px;
  }
`;

const ItemBoxWrapper = styled.div`
  margin-top: 6px;
`;

const GroupTableWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: ${(props) => (props.hasMembers ? 'row' : 'column')};
  align-items: center;
  justify-content: ${(props) => (props.hasMembers ? 'space-between' : '')};
`;

const GroupMembersTableWrapper = styled.div`
  margin-top: ${theme.spacing.l};
`;

const SpinnerWrapper = styled.div`
  margin-top: 140px;
`;

const GroupsHeaderWrapper = styled.div`
  text-align: ${(props) => (props.hasMembers ? 'inherit' : 'center')};
`;

const GroupsOverview = ({
  groups,
  loadingGroupsInfo,
  groupMembers,
  onSubmitGroupMembers,
  registeredMembers,
  onSaveEditGroup,
  onDeleteGroupMember,
  onDeleteGroup,
  userId,
  groupOwners,
  selectedManagerId,
  onTransferGroup,
  loadingGroupMembers,
  selectedGroupId,
  setSelectedGroupId,
  onViewGroupActivity,
}) => {
  const [updatedGroupMembers, setUpdatedGroupMembers] = useState(groupMembers);
  const [editGroupMembersOpen, setEditGroupMembersOpen] = useState(false);
  const [editGroupId, setEditGroupId] = useState(false);
  const [editGroupFormStatus, setEditGroupFormStatus] = useState(null);
  const [updateGroupAccessStatus, setUpdateGroupAccessStatus] = useState(null);
  const [isSubmittingUpdateGroupAccess, setIsSubmittingUpdateGroupAccess] =
    useState(false);
  const [updateGroupAccessFormStatus, setUpdateGroupAccessFormStatus] =
    useState(null);
  const [transferGroupOpen, setTransferGroupOpen] = useState(false);
  const [transferGroupName, setTransferGroupName] = useState('');
  const [transferGroupId, setTransferGroupId] = useState();
  const [transferGroupOwnedBy, setTransferGroupOwnedBy] = useState();
  const [transferGroupFormStatus, setTransferGroupFormStatus] = useState(null);

  const { t } = useTranslation();

  const isMobile = useMediaQuery(theme.breakpoints.mobile);

  const onCloseEditGroup = () => {
    setEditGroupFormStatus(null);
    setEditGroupId(null);
  };

  const handleSubmitEditGroup = async (values) =>
    setEditGroupFormStatus(await onSaveEditGroup(values));
  const handleDeleteGroupMemberAccess = async (user) =>
    onDeleteGroupMember(user, selectedGroupId);

  const selectedGroup = groups.filter((g) => g.id === selectedGroupId)[0];

  const groupToEdit = editGroupId && groups.find((g) => g.id === editGroupId);
  const editGroupInitialValues = editGroupId && {
    groupId: groupToEdit.id,
    name: groupToEdit.name,
    description: groupToEdit.description,
  };

  const onSubmitGroupMembersHandlerMobile = async (users) => {
    const response = await onSubmitGroupMembers(users, selectedGroupId);

    if (!response?.success) {
      setUpdateGroupAccessStatus({
        success: false,
        message: t('administrateUsers.updateGroupMembersFailed'),
      });
    }

    if (response?.success) {
      setUpdatedGroupMembers(
        users.sort((a, b) => a.name.localeCompare(b.name))
      );
      setEditGroupMembersOpen(false);
      setUpdateGroupAccessStatus({
        success: true,
        message: t('administrateUsers.groupUpdatedSuccessMessage'),
      });
    }
  };

  const handleUpdateGroupMemberAccess = async (users) => {
    setIsSubmittingUpdateGroupAccess(true);
    const response = await onSubmitGroupMembers(users, selectedGroupId);

    if (response?.success) {
      setUpdateGroupAccessFormStatus(response);
    }

    setIsSubmittingUpdateGroupAccess(false);
  };

  const handleAddGroupMembersDialog = () => {
    setUpdateGroupAccessFormStatus(null);
    setEditGroupMembersOpen(true);
  };

  const onTransferOwnership = (groupId) => {
    const group = groups.find((g) => g.id === groupId);
    setTransferGroupOpen(true);
    setTransferGroupName(group.name);
    setTransferGroupId(groupId);
    setTransferGroupOwnedBy(group.ownedBy);
  };

  const onCloseTransferHandler = () => {
    setTransferGroupOpen(false);
    setTransferGroupFormStatus(null);
  };

  const onTransferOwnershipHandler = async (user) => {
    const input = {
      groupId: transferGroupId,
      currentOwnerId: transferGroupOwnedBy,
      newOwnerId: user[0].id,
    };
    const formStatus = await onTransferGroup(input);
    setSelectedGroupId();

    setTransferGroupFormStatus(formStatus);
  };

  const buttons = (membersCount) => [
    {
      label: t('administrateUsers.members'),
      handler: (groupId) => {
        setSelectedGroupId(groupId);
        if (!isMobile) {
          handleAddGroupMembersDialog();
        }
      },
    },
    {
      label: t('administrateUsers.activity'),
      handler: onViewGroupActivity,
      disabled: membersCount === 0,
    },
  ];

  const menuItems = [
    {
      id: 'editGroup',
      label: t('administrateUsers.editGroup'),
      handler: (groupId) => setEditGroupId(groupId),
    },
    {
      id: 'deleteGroup',
      label: t('administrateUsers.deleteGroup'),
      handler: (groupId) => onDeleteGroup({ id: groupId }),
    },
    {
      id: 'transferGroup',
      label: t('administrateUsers.transferOwnership'),
      handler: (groupId) => onTransferOwnership(groupId),
    },
  ];

  const initialSelectedUsers =
    updatedGroupMembers.length > 0 ? updatedGroupMembers : groupMembers || [];
  const isLoadingSelectGroupMembersAndPreview =
    loadingGroupMembers || !registeredMembers || isSubmittingUpdateGroupAccess;
  return (
    <>
      {loadingGroupsInfo ? (
        <SpinnerWrapper>
          <CircularSpinner static />
        </SpinnerWrapper>
      ) : (
        !!groups?.length && <StyledHorizontalRule />
      )}
      <Wrapper>
        <SideBlock>
          {!!groups?.length && (
            <TitleWrapper>
              <T
                color={theme.palette.lightPure}
                phoneVariant="subtitle"
                phoneWeight="medium"
                variant="headingS"
              >
                {t('administrateUsers.manageYourGroups')}
              </T>
              <T color={theme.palette.disabledLight}>
                {t('administrateUsers.addOrDeleteMembersToGroups')}
              </T>
            </TitleWrapper>
          )}
          {!!groups?.length && (
            <GroupsListWrapper>
              {groups
                .filter(
                  (group) =>
                    selectedManagerId === 'all' ||
                    group.ownedBy === selectedManagerId
                )
                .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
                .map((group) => (
                  <ItemBoxWrapper key={group.id}>
                    <ItemBox
                      id={group.id}
                      buttons={buttons(group.membersCount)}
                      menuItems={menuItems}
                      onClickHandler={
                        isMobile
                          ? undefined
                          : (groupId) => {
                              if (selectedGroupId === groupId) {
                                setSelectedGroupId(null);
                              } else {
                                setSelectedGroupId(groupId);
                              }
                            }
                      }
                      selected={selectedGroupId === group.id}
                      theme="light"
                      title={group.name}
                      topText={`${t('administrateUsers.createdOn')} ${formatDate(
                        {
                          date: group.createdAt,
                        }
                      )}`}
                    />
                  </ItemBoxWrapper>
                ))}
            </GroupsListWrapper>
          )}
        </SideBlock>

        {!isMobile && selectedGroup && (
          <SideBlock sidePadding>
            {loadingGroupMembers ? (
              <SpinnerWrapper>
                <CircularSpinner static />
              </SpinnerWrapper>
            ) : (
              <>
                <GroupTableWrapper hasMembers={groupMembers.length}>
                  <GroupsHeaderWrapper hasMembers={groupMembers.length}>
                    <T color={theme.palette.lightPure} variant="helperText">
                      {t('administrateUsers.group')}
                    </T>
                    <T
                      color={theme.palette.lightPure}
                      fontWeight="semibold"
                      variant="subtitle"
                    >
                      {selectedGroup.name}
                    </T>
                  </GroupsHeaderWrapper>
                  {!groupMembers.length && (
                    <T color={theme.palette.disabledLight} variant="paragraphS">
                      {t('administrateUsers.emptyGroupDescription')}
                    </T>
                  )}
                  <Button
                    ariaLabel={t('administrateUsers.addMembers')}
                    iconRight={AddIcon}
                    onClick={handleAddGroupMembersDialog}
                    theme="dark"
                    type="secondaryOutline"
                  >
                    {t('administrateUsers.addMembers')}
                  </Button>
                </GroupTableWrapper>
                {!!groupMembers.length && (
                  <GroupMembersTableWrapper>
                    <GroupMembersTable
                      groupMembers={groupMembers}
                      onDeleteUser={(row) => handleDeleteGroupMemberAccess(row)}
                      userId={userId}
                    />
                  </GroupMembersTableWrapper>
                )}
              </>
            )}
          </SideBlock>
        )}
        {isMobile && (
          <ViewGroupMembersMobileView
            onSubmitHandler={onSubmitGroupMembersHandlerMobile}
            open={!!selectedGroupId}
            groupName={selectedGroup?.name || ''}
            onClose={() => setSelectedGroupId(null)}
            loading={loadingGroupMembers}
            initialGroupMembers={groupMembers || []}
            updatedGroupMembers={updatedGroupMembers}
            setEditGroupMembersOpen={handleAddGroupMembersDialog}
            setUpdatedGroupMembers={setUpdatedGroupMembers}
            formStatus={updateGroupAccessStatus}
          />
        )}
        {editGroupId && (
          <EditGroup
            initialValues={editGroupInitialValues}
            onSave={handleSubmitEditGroup}
            formStatus={editGroupFormStatus}
            onClose={onCloseEditGroup}
          />
        )}
        {editGroupMembersOpen && (
          <SelectGroupMembersAndPreview
            groupName={selectedGroup?.name || ''}
            onClose={() => setEditGroupMembersOpen(false)}
            onSubmit={handleUpdateGroupMemberAccess}
            allMembers={registeredMembers}
            initialSelected={initialSelectedUsers}
            loading={isLoadingSelectGroupMembersAndPreview}
            isMobile={isMobile}
            formStatus={updateGroupAccessFormStatus}
          />
        )}
        {transferGroupOpen && (
          <TransferOwnershipView
            onSubmitHandler={onTransferOwnershipHandler}
            transferItemName={transferGroupName}
            usersToTransfer={groupOwners.filter(
              (manager) => manager.id !== transferGroupOwnedBy
            )}
            onClose={onCloseTransferHandler}
            formStatus={transferGroupFormStatus}
          />
        )}
      </Wrapper>
    </>
  );
};

GroupsOverview.propTypes = {
  groups: PropTypes.array,
  loadingGroupsInfo: PropTypes.bool,
  groupMembers: PropTypes.array.isRequired,
  onSubmitGroupMembers: PropTypes.func.isRequired,
  onSaveEditGroup: PropTypes.func.isRequired,
  onDeleteGroupMember: PropTypes.func.isRequired,
  registeredMembers: PropTypes.array,
  onDeleteGroup: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  groupOwners: PropTypes.array,
  selectedManagerId: PropTypes.string.isRequired,
  onTransferGroup: PropTypes.func.isRequired,
  loadingGroupMembers: PropTypes.bool,
  selectedGroupId: PropTypes.string,
  setSelectedGroupId: PropTypes.func.isRequired,
  onViewGroupActivity: PropTypes.func.isRequired,
};

GroupsOverview.defaultProps = {
  registeredMembers: [],
  groups: [],
  groupOwners: [],
};

export default GroupsOverview;

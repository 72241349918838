import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import PageTemplate from '../components/templates/PageTemplate';
import ManageCourseView from '../components/organisms/ManageCourseView';
import { CourseProvider } from '../components/contexts/CourseContext';

const CourseContainer = () => {
  const navigate = useNavigate();
  const params = useParams();
  const courseId = params?.id || null;

  return (
    <PageTemplate>
      <CourseProvider navigate={navigate} courseId={courseId}>
        <ManageCourseView />
      </CourseProvider>
    </PageTemplate>
  );
};

export default CourseContainer;

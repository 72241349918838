import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Block from '@mui/icons-material/Block';
import Check from '@mui/icons-material/Check';

import CircularSpinner from '@shared/atoms/CircularSpinner';
import Button from '@shared/atoms/Button';
import Form from '@shared/atoms/Form';
import Icon from '@shared/atoms/Icon';
import T from '@shared/atoms/Typography';
import Accordion from '@shared/molecules/Accordion';
import Dialog from '@shared/molecules/Dialog';
import theme from '@shared/themes/default';
import { formatDate, useForm, logger } from '@shared/utils';

import {
  SUBSCRIPTION_PLAN_STATUS,
  SUBSCRIPTION_PLAN_TYPES,
} from '@shared/const';

import {
  updateOrganization,
  updateOrganizationSubscription,
} from '../../graphql/mutations';
import { listCustomerSubscriptionPlans } from '../../graphql/queries';
import { useGraphQLQuery } from '../../../../../utils';
import InvoiceForm from '../InvoiceForm';

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m1};
`;

const SubscriptionWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${theme.spacing.m3};
`;

const SubscriptionDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
`;

const AccordionWrapper = styled.div`
  padding: ${theme.spacing.m1};
`;

const AccordionContentWrapper = styled.div`
  padding: ${theme.spacing.m1};
`;

const FormWrapper = styled.div`
  align-items: end;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m1};
  max-width: 600px;
`;

const SubscriptionTab = ({
  item,
  onUpdateOrganization,
  setErrorMessage,
  setHasError,
  setHasSuccess,
  setSuccessMessage,
  subscriptionPlans,
  subscriptionPlansLoading,
}) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState();
  const [subscriptionDialogOpen, setSubscriptionDialogOpen] = useState(false);
  const [subscriptionDialogData, setSubscriptionDialogData] = useState({});
  const [subscriptions, setSubscriptions] = useState([]);
  const [showPurchasedLicenses, setShowPurchasedLicenses] = useState(false);
  const [showStartDate, setShowStartDate] = useState(false);

  const isStarted = (subscription) => {
    return subscription.active && !subscription.cancel_at_period_end;
  };

  const {
    callQuery: callCustomerPlansQuery,
    data: customerPlansData,
    errors: customerPlansErrors,
    hasEnded: hasCustomerPlansEnded,
    hasErrors: hasCustomerPlansErrors,
    loading: customerPlansLoading,
  } = useGraphQLQuery();

  useEffect(() => {
    if (hasCustomerPlansEnded) {
      if (hasCustomerPlansErrors) {
        logger.error('Error: ', customerPlansErrors);
        setErrorMessage(
          t('backoffice.organizations.subscription.failedToLoadPlans')
        );
        setHasError(true);
      } else {
        const { listCustomerSubscriptionPlans } = customerPlansData;
        setSubscriptions(listCustomerSubscriptionPlans);
      }
    }
  }, [hasCustomerPlansEnded]);

  useEffect(() => {
    callCustomerPlansQuery(
      listCustomerSubscriptionPlans(item.domain, item.stripe_customer_id)
    );
  }, []);

  const {
    callQuery: callUpdateSubscriptionQuery,
    data: updateSubscriptionData,
    errors: updateSubscriptionErrors,
    hasEnded: hasUpdateSubscriptionEnded,
    hasErrors: hasUpdateSubscriptionErrors,
    loading: updateSubscriptionLoading,
  } = useGraphQLQuery();

  useEffect(() => {
    if (hasUpdateSubscriptionEnded) {
      if (hasUpdateSubscriptionErrors) {
        logger.error('Error: ', updateSubscriptionErrors);
        setErrorMessage(
          isStarted(subscriptionDialogData)
            ? t(
                'backoffice.organizations.subscription.failedToStopSubscription'
              )
            : t(
                'backoffice.organizations.subscription.failedToStartSubscription'
              )
        );
        setHasError(true);
      } else {
        const { updateOrganizationSubscription } = updateSubscriptionData;

        const updatedSubscriptions = subscriptions.map((subscription) => {
          if (
            subscription.subscriptionId ===
            updateOrganizationSubscription.subscriptionId
          ) {
            return {
              ...subscription,
              ...updateOrganizationSubscription,
            };
          }
          return subscription;
        });

        setSubscriptions(updatedSubscriptions);
        setSuccessMessage(
          isStarted(subscriptionDialogData)
            ? t(
                'backoffice.organizations.subscription.successfullyStoppedSubscription'
              )
            : t(
                'backoffice.organizations.subscription.successfullyStartedSubscription'
              )
        );
        setHasSuccess(true);
      }

      setSubscriptionDialogOpen(false);
    }
  }, [hasUpdateSubscriptionEnded]);

  const {
    callQuery: callUpdateOrganizationQuery,
    data: updateOrganizationData,
    errors: updateOrganizationErrors,
    hasEnded: hasUpdateOrganizationEnded,
    hasErrors: hasUpdateOrganizationErrors,
  } = useGraphQLQuery();

  useEffect(() => {
    if (hasUpdateOrganizationEnded) {
      if (hasUpdateOrganizationErrors) {
        logger.error('Error: ', updateOrganizationErrors);
        setErrorMessage(
          t('backoffice.organizations.subscription.failedToUpdateInvoice')
        );
        setHasError(true);
      } else {
        const { updateOrganization } = updateOrganizationData;

        if (selectedPlanId) {
          const plan = subscriptionPlans.find(
            ({ value }) => value === selectedPlanId
          );

          if (plan) {
            updateOrganization.subscriptions = [
              { planId: plan.value, planName: plan.label },
            ];
          }
        }

        onUpdateOrganization(updateOrganization);

        callCustomerPlansQuery(
          listCustomerSubscriptionPlans(
            updateOrganization.domain,
            updateOrganization.stripe_customer_id
          )
        );

        setSuccessMessage(
          t('backoffice.organizations.subscription.successfullyUpdatedInvoice')
        );
        setHasSuccess(true);
      }
    }
  }, [hasUpdateOrganizationEnded]);

  useEffect(() => {
    if (item && item.subscriptions && item.subscriptions.length > 0) {
      const { type, planStatus } = item.subscriptions[0];

      if (
        type === SUBSCRIPTION_PLAN_TYPES.GROUP &&
        planStatus === SUBSCRIPTION_PLAN_STATUS.INVOICE
      ) {
        setShowPurchasedLicenses(true);
        setShowStartDate(true);
      }
    }
  }, [item]);

  const {
    disableSubmit,
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
    properties,
    setDefaults,
    submitting,
    values,
  } = useForm({
    changesRequired: true,
    fields: {
      organizationNumber: {
        maxLength: 9,
      },
      invoiceSendVia: {},
      invoiceName: {},
      invoiceEmail: {
        lowerCase: true,
        type: 'email',
      },
      invoiceAddress: {},
      invoiceZip: {
        maxLength: 4,
      },
      invoiceCity: {},
      invoicePeriod: {},
      invoiceNote: {},
      subscriptionPlan: {
        default: 'none',
      },
      purchasedLicenses: {
        mode: 'numeric',
        pattern: '[0-9]*',
        validation: (value) => /^[0-9]*$/.test(value),
      },
      startDate: {},
    },
    onSubmitHook: (organization) => {
      const input = Object.entries(organization).reduce(
        (acc, [key, value]) => {
          if (value) {
            acc[key] = value;
          } else {
            acc[key] = null;
          }
          return acc;
        },
        {
          id: item.id,
        }
      );

      if (input.invoicePeriod === 'none') {
        input.invoicePeriod = null;
      }
      if (input.invoiceSendVia === 'none') {
        input.invoiceSendVia = null;
      }
      if (subscriptions.length === 0 && input.subscriptionPlan !== 'none') {
        input.stripe_customer_id = item.stripe_customer_id;
        input.subscriptionPlanId = input.subscriptionPlan;
        setSelectedPlanId(input.subscriptionPlan);
      } else {
        setSelectedPlanId();
      }

      delete input.subscriptionPlan;

      callUpdateOrganizationQuery(updateOrganization(input));
    },
    onSubmitHookEnded: hasUpdateOrganizationEnded,
  });

  useEffect(() => {
    setDefaults({
      organizationNumber: item.organizationNumber || '',
      invoiceSendVia: item.invoiceSendVia || 'none',
      invoiceName: item.invoiceName || '',
      invoiceEmail: item.invoiceEmail || '',
      invoiceAddress: item.invoiceAddress || '',
      invoiceZip: item.invoiceZip || '',
      invoiceCity: item.invoiceCity || '',
      invoicePeriod: item.invoicePeriod || 'none',
      invoiceNote: item.invoiceNote || '',
      purchasedLicenses: `${item.purchasedLicenses || ''}`,
      startDate: item.startDate || '',
    });
  }, [item]);

  useEffect(() => {
    if (!subscriptionPlansLoading && subscriptionPlans.length > 0) {
      setDefaults({
        subscriptionPlan: 'none',
      });
    }
  }, [subscriptionPlans, subscriptionPlansLoading]);

  useEffect(() => {
    if (!subscriptionDialogOpen) {
      setSubscriptionDialogData({});
    }
  }, [subscriptionDialogOpen]);

  const getSubscriptionStatus = (subscription) => {
    if (subscription.cancel_at_period_end) {
      return t('backoffice.organizations.subscription.expires', {
        date: formatDate({
          date: new Date(subscription.current_period_end * 1000),
        }),
      });
    }
    if (subscription.active) {
      return t('backoffice.organizations.subscription.active');
    }
    return t('backoffice.organizations.subscription.stopped');
  };

  return (
    <>
      <OuterWrapper>
        {customerPlansLoading && <CircularSpinner static />}
        {!customerPlansLoading &&
          subscriptions.map((subscription) => (
            <SubscriptionWrapper key={subscription.subscriptionId}>
              {subscription.active && !subscription.cancel_at_period_end ? (
                <Icon color={theme.palette.successLight} icon={Check} />
              ) : (
                <Icon color={theme.palette.errorLight} icon={Block} />
              )}
              <SubscriptionDescriptionWrapper>
                <T>{subscription.nickname || 'No name'}</T>
                <T color={theme.palette.lightInteracting} variant="paragraphS">
                  {getSubscriptionStatus(subscription)}
                </T>
              </SubscriptionDescriptionWrapper>
              <Button
                ariaLabel={
                  isStarted(subscription)
                    ? t('backoffice.organizations.subscription.stop')
                    : t('backoffice.organizations.subscription.start')
                }
                onClick={() => {
                  setSubscriptionDialogOpen(true);
                  setSubscriptionDialogData(subscription);
                }}
                theme="dark"
                type={isStarted(subscription) ? 'secondary' : 'primary'}
              >
                {isStarted(subscription)
                  ? t('backoffice.organizations.subscription.stop')
                  : t('backoffice.organizations.subscription.start')}
              </Button>
            </SubscriptionWrapper>
          ))}
        {!customerPlansLoading && subscriptions.length === 0 && (
          <>
            <T align="center" fontWeight="bold" variant="headingXs">
              {t('backoffice.organizations.subscription.noSubscriptionFound')}
            </T>
            <T align="center" variant="paragraph">
              {t('backoffice.organizations.subscription.createSubscription')}
            </T>
          </>
        )}
        <Accordion
          summary={
            <AccordionWrapper>
              <T fontWeight="medium" variant="subtitle">
                {t('backoffice.organizations.subscription.invoice')}
              </T>
            </AccordionWrapper>
          }
          details={
            <AccordionContentWrapper>
              <Form onSubmit={handleSubmit}>
                <FormWrapper>
                  <InvoiceForm
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    properties={properties}
                    subscriptionPlans={
                      subscriptions.length > 0
                        ? []
                        : [
                            {
                              value: 'none',
                              key: 'none',
                              label: t('generic.none'),
                            },
                            ...subscriptionPlans,
                          ]
                    }
                    theme="dark"
                    values={values}
                    showPurchasedLicenses={showPurchasedLicenses}
                    showStartDate={showStartDate}
                  />
                  <Button
                    ariaLabel={t('generic.save')}
                    disabled={
                      disableSubmit ||
                      customerPlansLoading ||
                      subscriptionPlansLoading
                    }
                    loading={submitting}
                    loadingOnIcon={submitting}
                    submit
                    theme="dark"
                  >
                    {t('generic.save')}
                  </Button>
                </FormWrapper>
              </Form>
            </AccordionContentWrapper>
          }
          setExpanded={setExpanded}
          expanded={expanded}
          theme="dark"
        />
      </OuterWrapper>
      <Dialog
        ariaLabel={
          isStarted(subscriptionDialogData)
            ? t('backoffice.organizations.subscription.stopSubscription')
            : t('backoffice.organizations.subscription.startSubscription')
        }
        actions={[
          {
            label: t('generic.cancel'),
            handler: () => {
              setSubscriptionDialogOpen(false);
              setSubscriptionDialogData({});
            },
            type: 'secondaryOutline',
          },
          {
            label: t('generic.confirm'),
            handler: () => {
              callUpdateSubscriptionQuery(
                updateOrganizationSubscription({
                  organizationId: item.id,
                  subscriptionId: subscriptionDialogData.subscriptionId,
                  action: isStarted(subscriptionDialogData) ? 'STOP' : 'START',
                })
              );
            },
          },
        ]}
        loading={updateSubscriptionLoading}
        onClose={() => {
          setSubscriptionDialogOpen(false);
        }}
        open={subscriptionDialogOpen}
        title={
          isStarted(subscriptionDialogData)
            ? t('backoffice.organizations.subscription.stopSubscription')
            : t('backoffice.organizations.subscription.startSubscription')
        }
      >
        {isStarted(subscriptionDialogData)
          ? t('backoffice.organizations.subscription.wantToStop', {
              name: subscriptionDialogData.nickname,
            })
          : t('backoffice.organizations.subscription.wantToStart', {
              name: subscriptionDialogData.nickname,
            })}
      </Dialog>
    </>
  );
};

SubscriptionTab.propTypes = {
  item: PropTypes.object.isRequired,
  onUpdateOrganization: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  setHasError: PropTypes.func.isRequired,
  setHasSuccess: PropTypes.func.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  subscriptionPlans: PropTypes.array.isRequired,
  subscriptionPlansLoading: PropTypes.bool.isRequired,
};

export default SubscriptionTab;

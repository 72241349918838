import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { InputAdornment } from '@mui/material';

import Button from '@shared/atoms/Button';
import Input from '@shared/atoms/Input';
import T from '@shared/atoms/Typography';
import Drawer from '@shared/atoms/Drawer';
import Tooltip from '@shared/atoms/Tooltip';
import theme from '@shared/themes/default';

import { courseStatus, PROCESSING_STATUS } from '@shared/const';
import { logger } from '@shared/utils';

import { useCourse } from '../../../../contexts/CourseContext';

import UploadWithList from '../Shared/UploadWithList';
import ValidationList from '../../components/ValidationList';

const SectionStyled = styled.section`
  background: ${theme.palette.darkWrapper};
  padding: ${theme.spacing.s};
`;

const HeaderStyled = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: ${theme.spacing.s};
`;

const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  column-gap: ${theme.spacing.xl};
`;

const ContentWrapper = styled.div`
  width: 30%;
  padding-bottom: ${theme.spacing.xl};
`;

const DrawerContent = styled.div`
  background: ${theme.palette.darkDistinct};
  margin: ${theme.spacing.s} ${theme.spacing.s} 0;
  padding: ${theme.spacing.s};
  width: 496px;
`;

const Attachments = () => {
  const { t } = useTranslation();

  const {
    course,
    hasChanged,
    locked,
    saveAttachments,
    setHasChanged,
    setHasError,
    setHandleSave,
    setSubmitting,
    setUploadingFiles,
    submitting,
    uploadingFiles,
    validationDetails,
  } = useCourse();

  const [attachments, setAttachments] = useState(course.attachments || []);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [newFilename, setNewFilename] = useState(null);

  const isPublished = course?.status === courseStatus.PUBLISHED;

  const handleSave = async () => {
    try {
      await saveAttachments(attachments);
      setHasChanged(false);
    } catch (e) {
      logger.error(e);
      setHasError(true);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    setHandleSave(() => handleSave);
  }, [attachments]);

  useEffect(() => {
    setAttachments(course.attachments);
  }, [course]);

  const splitFullname = (fullname) => {
    if (fullname) {
      const newFilenameArray = fullname.split('.');
      const ext = newFilenameArray.length > 1 ? newFilenameArray.pop() : null;
      const newFilenameName = newFilenameArray.join('.');
      return { name: newFilenameName, ext };
    }
    return {};
  };

  const handleModify = (index) => {
    setSelectedIndex(index);
    setNewFilename(splitFullname(attachments[index]?.name));
    setOpen(true);
  };

  const handleDelete = (index) => {
    setAttachments(attachments.filter((_, idx) => index !== idx));
    setHasChanged(true);
  };

  const handleAddFiles = (files) => {
    setAttachments([
      ...attachments,
      ...files.map((file) => ({
        key: file.s3File.key,
        name: file.name,
        processingStatus: {
          file: file.s3File,
          status: PROCESSING_STATUS.TO_SCHEDULE,
        },
      })),
    ]);
    setHasChanged(true);
    setUploadingFiles(false);
  };

  const onSaveDrawer = () => {
    const newAttachments = [...attachments];
    const fileName = Object.values(newFilename).filter(
      (a) => a !== '' && a !== null
    );
    newAttachments[selectedIndex].name =
      fileName.length > 1 ? fileName.join('.') : fileName[0];
    setAttachments(newAttachments);
    setOpen(false);
    setHasChanged(true);
  };

  const onStartUpload = () => {
    if (!hasChanged) setHasChanged(true);
    setUploadingFiles(true);
  };

  const listItemsActions = [
    {
      label: t('backoffice.courses.attachmentsTab.modifyFilename'),
      handler: (idx) => handleModify(Number.parseInt(idx, 10)),
    },
    {
      label: t('backoffice.courses.attachmentsTab.deleteFilename'),
      handler: (idx) => handleDelete(Number.parseInt(idx, 10)),
    },
  ];

  const drawerContentIsEdited =
    splitFullname(attachments[selectedIndex]?.name)?.name !== newFilename?.name;
  const saveDisabled =
    !hasChanged || submitting || (isPublished && locked) || uploadingFiles;

  return (
    <SectionStyled>
      <HeaderStyled>
        <TitleWrapper>
          <T
            color={theme.palette.lightPure}
            fontWeight="semibold"
            variant="paragraph"
          >
            {t('backoffice.courses.attachmentsTab.title')}
          </T>
        </TitleWrapper>
        <Tooltip
          arrow
          title={
            course.status === courseStatus.PUBLISHED &&
            saveDisabled &&
            !submitting &&
            !locked &&
            Object.keys(validationDetails)?.length ? (
              <>
                <T color={theme.palette.mainDark}>
                  {t('backoffice.courses.publishing.save')}
                </T>
                <ValidationList details={validationDetails} />
              </>
            ) : (
              ''
            )
          }
        >
          <div>
            <Button
              disabled={saveDisabled}
              loading={submitting}
              onClick={handleSave}
              type="secondary"
            >
              {t('generic.save')}
            </Button>
          </div>
        </Tooltip>
      </HeaderStyled>
      <ContentWrapper>
        <UploadWithList
          actions={listItemsActions}
          deleteFiles
          description={[
            t('backoffice.courses.attachmentsTab.uploadDescription'),
          ]}
          items={attachments}
          onStartUpload={onStartUpload}
          onUpload={handleAddFiles}
          uploadHeight="124px"
          disabled={locked}
        />
        <Drawer
          anchor="right"
          open={open}
          onClose={() => setOpen(false)}
          onSave={onSaveDrawer}
          title={t('backoffice.courses.attachmentsTab.drawerTitle')}
          edited={drawerContentIsEdited}
          saveDisabled={newFilename?.name?.length === 0}
        >
          <DrawerContent>
            <Input
              id="new-filename"
              key="new-filename"
              name="new-filename"
              endAdornment={
                newFilename?.ext ? (
                  <InputAdornment position="start">{`.${newFilename?.ext}`}</InputAdornment>
                ) : null
              }
              onChange={({ target: { value } }) => {
                setNewFilename({ name: value, ext: newFilename.ext });
              }}
              placeholder={t(
                'backoffice.courses.attachmentsTab.filenamePlaceholder'
              )}
              required
              theme="dark"
              value={newFilename?.name}
            />
          </DrawerContent>
        </Drawer>
      </ContentWrapper>
    </SectionStyled>
  );
};

export default Attachments;

import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import T from '@shared/atoms/Typography';
import ListItems from '@shared/molecules/ListItems';
import theme from '@shared/themes/default';
import { selectTranslation } from '@shared/utils';

import { useAppGlobals } from '../../../../contexts/AppContext';

const Wrapper = styled.div`
  background-color: ${theme.palette.evenDark};
  padding: ${(props) =>
    props.hasPagination
      ? theme.spacing.s
      : `${theme.spacing.s} ${theme.spacing.s} 0 ${theme.spacing.s}`};
`;

const CoursesTab = ({ courses }) => {
  const { locale } = useAppGlobals();
  const { t } = useTranslation();

  return (
    <Wrapper hasPagination={courses.length >= 5}>
      <T
        fontWeight="bold"
        sx={courses.length === 0 ? { paddingBottom: theme.spacing.s } : {}}
      >
        {t('backoffice.experts.coursesCount', { count: courses.length })}
      </T>
      {courses.length > 0 && (
        <ListItems
          items={courses.map((course) => ({
            key: course.id,
            title: selectTranslation(course.nameTranslations, null, locale, ''),
          }))}
          theme="dark"
        />
      )}
    </Wrapper>
  );
};

CoursesTab.propTypes = {
  courses: PropTypes.array,
};

CoursesTab.defaultProps = {
  courses: [],
};

export default CoursesTab;

import React, { useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Upload from '@shared/molecules/Upload';

import { courseStatus, PROCESSING_STATUS } from '@shared/const';
import theme from '@shared/themes/default';
import { useAppGlobals } from '../../../../../contexts/AppContext';
import { useCourse } from '../../../../../contexts/CourseContext';

import { graphql } from '../../../../../../utils';
import {
  validateCourseFiles,
  parseCourseFiles,
} from '../../../parseCourseFiles';

const SectionStyled = styled.section`
  background: ${theme.palette.darkWrapper};
  margin: ${theme.spacing.s};
  padding: ${theme.spacing.m1};
  width: 506px;
  height: 100%;
`;

const DrawerBatch = ({ onChange, setChangedDrawerContent, setError }) => {
  const { t } = useTranslation();
  const {
    completeUpload,
    course,
    getUploadUrl,
    hasChanged,
    setHasChanged,
    setUploadingFiles,
    startUpload,
  } = useCourse();
  const { locale } = useAppGlobals();
  const [items, setItems] = useState([]);
  const isPublished = course.status === courseStatus.PUBLISHED;
  const { episodes } = course;

  const processFiles = (files) => {
    try {
      const nameFiles = files.map((file) => file.name);
      const { episodes } = parseCourseFiles(nameFiles, locale, isPublished);

      const newItems = {};
      Object.keys(episodes).forEach((key) => {
        const { name, file, subtitles, trailer } = episodes[key];
        if (trailer) {
          const selectedFile = files.find((f) => f.name === file);
          if (selectedFile) {
            newItems[0] = {
              id: 'trailer',
              trailerVideoFiles: [],
              trailerVideoProcessingStatus: {
                file: selectedFile.s3File,
                status: PROCESSING_STATUS.TO_SCHEDULE,
              },
            };
          }
        } else {
          let newItem = {
            nameTranslations: JSON.stringify({ [locale]: name }),
          };
          const selectedFile = files.find((f) => f.name === file);
          if (selectedFile) {
            newItem = {
              ...newItem,
              videoFiles: [],
              videoProcessingStatus: {
                file: selectedFile.s3File,
                status: PROCESSING_STATUS.TO_SCHEDULE,
              },
            };
          }
          const subtitlesFile = files.find((f) => f.name === subtitles);
          if (subtitlesFile) {
            newItem = {
              ...newItem,
              subtitle: null,
              subtitleProcessingStatus: {
                file: subtitlesFile.s3File,
                status: PROCESSING_STATUS.TO_SCHEDULE,
              },
            };
          }
          newItems[key] = newItem;
        }
      });
      return newItems;
    } catch (e) {
      setError(e.message);
    }
    return [];
  };

  const handleUpload = (files) => {
    if (files?.length > 0) {
      const totalFiles = [...items, ...files];
      const newItems = processFiles(totalFiles);
      setItems(totalFiles);
      if (onChange) onChange(newItems);
    }
    setUploadingFiles(false);
  };

  const handleRemoveFile = (filenameToRemove) => {
    const totalFiles = items.filter((file) => file.name !== filenameToRemove);
    const newItems = processFiles(totalFiles);
    setItems(totalFiles);
    if (onChange) onChange(newItems);
  };

  const getGraphQlQuery = (query) => {
    const graphQlQuery = async (...params) => graphql(query(...params));
    return graphQlQuery;
  };

  const onStartUpload = () => {
    if (!hasChanged) setHasChanged(true);
    setChangedDrawerContent(true);
    setUploadingFiles(true);
  };

  return (
    <SectionStyled>
      <Upload
        chunkSize={1024 * 1024 * 100}
        description={
          isPublished
            ? [
                t('backoffice.courses.lessonsTab.batchUploadDescriptionHeader'),
                t('backoffice.courses.lessonsTab.subtitlesBatchUpload'),
              ]
            : [
                t('backoffice.courses.lessonsTab.batchUploadDescriptionHeader'),
                t(
                  'backoffice.courses.lessonsTab.batchUploadDescriptionLessons'
                ),
                t(
                  'backoffice.courses.lessonsTab.batchUploadDescriptionSubtitle'
                ),
                t('backoffice.courses.lessonsTab.or'),
                t(
                  'backoffice.courses.lessonsTab.batchUploadDescriptionSubtitle2'
                ),
              ]
        }
        startUpload={getGraphQlQuery(startUpload)}
        getUploadUrl={getGraphQlQuery(getUploadUrl)}
        completeUpload={getGraphQlQuery(completeUpload)}
        onCompleteUpload={handleUpload}
        onDeleteFile={handleRemoveFile}
        onStartUpload={onStartUpload}
        validate={(files) => {
          const { validatedFiles, errors } = validateCourseFiles(
            files,
            locale,
            isPublished,
            episodes.length
          );
          setError(
            errors.length === 0
              ? null
              : t('backoffice.courses.lessonsTab.batchUploadError', {
                  count: errors.length,
                })
          );
          return validatedFiles;
        }}
      />
    </SectionStyled>
  );
};

DrawerBatch.propTypes = {
  onChange: PropTypes.func,
  setChangedDrawerContent: PropTypes.func.isRequired,
  setError: PropTypes.func,
};

export default DrawerBatch;

export const removeFromOrganization = (organizationId, { input }) => ({
  query: `mutation removeFromOrganization($input: RemoveFromOrganizationInput!) {
    removeFromOrganization(input: $input) {
      id
      organizationId
    }
  }`,
  variables: {
    input: {
      ...input,
      organizationId,
    },
  },
});

export const transferUserPlaylists = (organizationId, { input }) => ({
  query: `mutation transferUserPlaylists($input: TransferUserContentInput!) {
    transferUserPlaylists(input: $input) {
      ids
    }
  }`,
  variables: {
    input: {
      ...input,
      organizationId,
    },
  },
});

export const transferUserGroups = (organizationId, { input }) => ({
  query: `mutation transferUserGroups($input: TransferUserContentInput!) {
    transferUserGroups(input: $input) {
      ids
    }
  }`,
  variables: {
    input: {
      ...input,
      organizationId,
    },
  },
});

export const changeUserRole = (organizationId, { input }) => ({
  query: `mutation changeUserRole($input: ChangeGrantInput!) {
    changeUserRole(input: $input) {
      success
      message
    }
  }`,
  variables: {
    input: {
      ...input,
      organizationId,
    },
  },
});

export const deleteUserPlaylists = (organizationId, variables) => ({
  query: `mutation deleteUserPlaylists($ownerId: ID!, $organizationId: ID!) {
    deleteUserPlaylists(ownerId: $ownerId, organizationId: $organizationId) {
      ids
    }
  }`,
  variables: {
    ...variables,
    organizationId,
  },
});

export const deleteUserGroups = (organizationId, variables) => ({
  query: `mutation deleteUserGroups($ownerId: ID!, $organizationId: ID!) {
    deleteUserGroups(ownerId: $ownerId, organizationId: $organizationId) {
      ids
    }
  }`,
  variables: {
    ...variables,
    organizationId,
  },
});

export const sendInvitations = (organizationId, users) => ({
  query: `mutation sendInvitations($organizationId: ID!, $users: [InvitationInput]!) {
    sendInvitations(organizationId: $organizationId, users: $users) {
      status
      success {
        count
        items {
          email
          name
          role
        }
      }
      failure{
        count
        items {
          email
          name
          role

        }
      }
    }
  }`,
  variables: {
    organizationId,
    users,
  },
});

export const resendInvitations = (organizationId, variables) => ({
  query: `mutation resendInvitations($organizationId: ID!, $users: [InvitationInput]!) {
    resendInvitations(organizationId: $organizationId, users: $users) {
      status
      success {
        count
        items {
          email
          name
          role
        }
      }
      failure {
        count
        items {
          email
          name
          role
          error
          reason
        }

      }
    }
  }`,
  variables: {
    ...variables,
    organizationId,
  },
});

export const removeInvitations = (organizationId, variables) => ({
  query: `mutation removeInvitations($organizationId: ID!, $users: [InvitationInput]!) {
    removeInvitations(organizationId: $organizationId, users: $users) {
      status
      success {
        count
        items{
          email
          name
          role
        }
      }
      failure {
        count
        items {
          email
          name
          role
        }
      }
    }
  }`,
  variables: {
    ...variables,
    organizationId,
  },
});

export const createGroup = (organizationId, { input }) => ({
  query: `mutation createGroup($input: CreateGroupInput!) {
    createGroup(input: $input) {
      id
      name
      description
      createdAt
      updatedAt
      ownedBy
      ownedByName
    }
  }`,
  variables: {
    input: {
      ...input,
      organizationId,
    },
  },
});

export const updateGroup = (variables) => ({
  query: `mutation updateGroup($input: UpdateGroupInput!) {
    updateGroup(input: $input) {
      id
      name
      description
    }
  }`,
  variables,
});

export const deleteGroup = (variables) => ({
  query: `mutation deleteGroup($input: DeleteGroupInput!) {
    deleteGroup(input: $input) {
      id
      name
      description
    }
  }`,
  variables,
});

export const updateGroupAccess = (variables) => ({
  query: `mutation updateGroupAccess($input: GroupAccessInput!) {
    updateGroupAccess(input: $input) {
      status
      success {
        count
        items {
          email
          name
          role
        }
      }
      failure {
        count
        items {
          email
          name
          role
        }
      }
    }
  }`,
  variables,
});

export const removeGroupAccess = (variables) => ({
  query: `mutation removeGroupAccess($input: GroupAccessInput!) {
    removeGroupAccess(input: $input) {
      status
      success {
        count
        items {
          email
          name
          role
        }
      }
      failure {
        count
        items {
          email
          name
          role
        }
      }
    }
  }`,
  variables,
});

export const transferGroup = (organizationId, { input }) => ({
  query: `mutation transferGroup($input: TransferGroupInput!) {
    transferGroup(input: $input) {
      id
    }
  }`,
  variables: {
    input: {
      ...input,
      organizationId,
    },
  },
});

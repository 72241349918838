import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  AutoScrollActivator,
  DndContext,
  MeasuringStrategy,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import theme from 'shared/themes/default';

const DndWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  row-gap: ${theme.spacing.s};
`;

const DragAndDrop = ({
  isLocked,
  items: originalItems,
  onDragEnd,
  renderItem,
}) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(originalItems);
  }, [originalItems]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );
  const measuringConfig = {
    droppable: {
      strategy: MeasuringStrategy.Always,
    },
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => {
          return item.id === active.id;
        });
        const newIndex = items.findIndex((item) => {
          return item.id === over.id;
        });

        const sortedItems = arrayMove(items, oldIndex, newIndex);
        if (onDragEnd) {
          onDragEnd(sortedItems, active.id, newIndex);
        }

        return sortedItems;
      });
    }
  };

  const contentItems = items.map((item, index, array) => (
    <div key={item.id}>{renderItem(item, index, array)}</div>
  ));

  return !isLocked ? (
    <DndContext
      autoScroll={{
        activator: AutoScrollActivator.DraggableRect,
        treshold: { y: 0.5 },
      }}
      measuring={measuringConfig}
      modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
      sensors={sensors}
      strategy={verticalListSortingStrategy}
      onDragEnd={handleDragEnd}
    >
      <DndWrapper>
        <SortableContext items={items}>{contentItems}</SortableContext>
      </DndWrapper>
    </DndContext>
  ) : (
    <DndWrapper>{contentItems}</DndWrapper>
  );
};

DragAndDrop.propTypes = {
  isLocked: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ),
  onDragEnd: PropTypes.func,
  renderItem: PropTypes.func.isRequired,
};

DragAndDrop.defaultProps = {
  isLocked: false,
  items: [],
};

export default DragAndDrop;

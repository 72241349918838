import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { Amplify } from 'aws-amplify';

import App from './components/App';
import { AppProvider } from './components/contexts/AppContext';
import { getDomainName, getLocale } from './config';

Amplify.configure({
  Auth: {
    identityPoolId: import.meta.env.VITE_IDENTITY_POOL_ID,
    region: import.meta.env.VITE_AWS_REGION,
    userPoolId: import.meta.env.VITE_USER_POOL_ID,
    userPoolWebClientId: import.meta.env.VITE_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: false,
    federationTarget: 'COGNITO_USER_POOLS',
  },
  API: {
    aws_appsync_graphqlEndpoint: import.meta.env.VITE_APPSYNC_GRAPHQL_ENDPOINT,
    aws_appsync_region: import.meta.env.VITE_AWS_REGION,
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    graphql_headers: async () => {
      const headers = {
        domain: getDomainName(),
        locale: getLocale(),
      };
      return headers;
    },
  },
});

const renderApp = () => (
  <BrowserRouter>
    <AppProvider>
      <App />
    </AppProvider>
  </BrowserRouter>
);

const root = document.getElementById('root');
render(renderApp(), root);

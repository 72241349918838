import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import T from '@shared/atoms/Typography';
import Dialog from '@shared/molecules/Dialog';
import { selectTranslation } from '@shared/utils';
import theme from '@shared/themes/default';

import { useAppGlobals } from '../../../contexts/AppContext';
import { TABS, useCourse } from '../../../contexts/CourseContext';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m1};
`;

const PublishDialog = ({ handlePublish, open, setOpen }) => {
  const { course, hasQuiz, setSelectedTab } = useCourse();
  const { t } = useTranslation();
  const { locale } = useAppGlobals();

  return (
    <Dialog
      actions={
        hasQuiz
          ? [
              {
                ariaLabel: t('backoffice.courses.publishing.cancel'),
                handler: () => setOpen(false),
                label: t('backoffice.courses.publishing.cancel'),
                type: 'secondaryOutline',
              },
              {
                ariaLabel: t('backoffice.courses.publishing.confirm'),
                handler: () => handlePublish(course),
                label: t('backoffice.courses.publishing.confirm'),
              },
            ]
          : [
              {
                ariaLabel: t('backoffice.courses.publishing.confirm'),
                handler: () => handlePublish(course),
                label: t('backoffice.courses.publishing.confirm'),
                type: 'secondaryOutline',
              },
              {
                ariaLabel: t('backoffice.courses.publishing.addQuiz'),
                handler: () => {
                  setSelectedTab(TABS.QUIZ);
                  setOpen(false);
                },
                label: t('backoffice.courses.publishing.addQuiz'),
              },
            ]
      }
      ariaLabel={t('backoffice.courses.publishing.title')}
      onClose={() => setOpen(false)}
      open={open}
      title={t('backoffice.courses.publishing.title')}
    >
      <ContentWrapper>
        <T
          color={theme.palette.mainDark}
          fontWeight="semibold"
          sx={{ textAlign: 'center' }}
          variant="headingXs"
        >
          {hasQuiz
            ? t('backoffice.courses.publishing.aboutToPublish', {
                courseName: selectTranslation(
                  course.nameTranslations,
                  course.name,
                  locale,
                  ''
                ),
              })
            : t('backoffice.courses.publishing.courseMissingQuizTitle')}
        </T>
        {!hasQuiz && (
          <div>
            <T color={theme.palette.mainDark}>
              {t('backoffice.courses.publishing.courseMissingQuiz1')}
            </T>
            <T color={theme.palette.mainDark}>
              {t('backoffice.courses.publishing.courseMissingQuiz2')}
            </T>
          </div>
        )}
        <div>
          <T color={theme.palette.mainDark}>
            {t('backoffice.courses.publishing.coursePublishIntro')}
          </T>
          <ul>
            <li>
              <T color={theme.palette.mainDark}>
                {t('backoffice.courses.publishing.coursePublishItem1')}
              </T>
            </li>
            <li>
              <T color={theme.palette.mainDark}>
                {t('backoffice.courses.publishing.coursePublishItem2')}
              </T>
            </li>
            <li>
              <T color={theme.palette.mainDark}>
                {t('backoffice.courses.publishing.coursePublishItem3')}
              </T>
            </li>
          </ul>
        </div>
      </ContentWrapper>
    </Dialog>
  );
};

PublishDialog.propTypes = {
  handlePublish: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default PublishDialog;

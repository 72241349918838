import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Checkbox from '@mui/material/Checkbox';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import withStyles from '@mui/styles/withStyles';
import { useTranslation } from 'react-i18next';
import theme from 'shared/themes/default';
import T from 'shared/atoms/Typography';
import Pagination from 'shared/molecules/Pagination';
import { selectTranslation } from 'shared/utils';
import CircularSpinner from 'shared/atoms/CircularSpinner';

const StyledThumbnail = styled.img`
  align-self: stretch;
  object-fit: cover;
  margin-right: 6px;
  border-radius: 8px;
  min-width: 70px;
  float: left;
  height: 42px;
  width: 70px;
`;

const StyledTitleName = styled.div`
  text-overflow: ellipsis;
  width: 100%;
  word-break: break-word;
  margin: 0 5px;
  align-self: center;
  font-weight: 600;
  font-size: 14px;
`;

const styles = {
  root: {
    fontFamily: theme.fonts.primary,
    margin: '0',
    display: 'grid',
    paddingLeft: '6px',
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.lightPure,
    },
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.lightInteracting,
    },
    '& .MuiListItem-root': {
      padding: '0 0 0 0',
      overflow: 'hidden',
    },
  },
  pointerCheckbox: {
    cursor: 'pointer',
  },
  defaultCheckbox: {
    cursor: 'not-allowed',
  },
};

const StyledList = styled(List)`
  display: grid;
`;

const StyledOuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 330px;
`;

const StyledBox = styled(Box)`
  ::-webkit-scrollbar {
    width: 10px;
    height: 30px;
  }
  ::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    background-color: ${theme.palette.brand};
    border-radius: 10px;
  }
`;

const CourseAndPreviewPanel = ({
  classes,
  coursesSelected,
  coursesToSelect,
  loading,
  initPage,
  locale,
  onCourseClickHandler,
}) => {
  const [page, setPage] = useState(initPage || 1);

  const itemsPerPage = 5;
  const noOfPages = Math.ceil(
    (coursesToSelect && coursesToSelect.length / itemsPerPage) || 1
  );

  const { t } = useTranslation();

  useEffect(() => {
    setPage(1);
  }, [coursesToSelect.length]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <StyledOuterWrapper>
      {loading ? (
        <CircularSpinner />
      ) : (
        <>
          <StyledBox>
            {coursesToSelect.length === 0 && (
              <T
                align="center"
                color={theme.palette.disabledLight}
                sx={{ marginTop: '25%' }}
                variant="headingXs"
              >
                {t('playlist.noCourseFound')}
              </T>
            )}
            {coursesToSelect
              .slice((page - 1) * itemsPerPage, page * itemsPerPage)
              .map((course) => {
                const tempCourse = coursesSelected.find(
                  (courseInternal) => courseInternal.id === course.id
                );
                const courseSelected =
                  (tempCourse && !!tempCourse.selected) || false;
                const courseName = selectTranslation(
                  course.nameTranslations,
                  course.name,
                  locale,
                  ''
                );
                return (
                  <StyledList classes={classes} key={course.id}>
                    <ListItem>
                      <Checkbox
                        color="default"
                        aria-label={courseName}
                        onChange={() => onCourseClickHandler(course.id, page)}
                        checked={courseSelected}
                      />
                      <StyledThumbnail
                        alt={courseName}
                        src={course.thumbnailUrl}
                      />
                      <StyledTitleName>{courseName}</StyledTitleName>
                    </ListItem>
                  </StyledList>
                );
              })}
          </StyledBox>
          {noOfPages > 1 && (
            <Pagination
              totalPages={noOfPages}
              initialPage={page}
              count={noOfPages}
              page={page}
              onChange={handleChange}
              defaultPage={1}
            />
          )}
        </>
      )}
    </StyledOuterWrapper>
  );
};

CourseAndPreviewPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  coursesSelected: PropTypes.array.isRequired,
  coursesToSelect: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  initPage: PropTypes.number,
  locale: PropTypes.oneOf(['no', 'en', 'es']).isRequired,
  onCourseClickHandler: PropTypes.func.isRequired,
};

CourseAndPreviewPanel.defaultProps = {
  initPage: 1,
};

export default withStyles(styles)(CourseAndPreviewPanel);

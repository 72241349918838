import { useState, useEffect } from 'react';

import {
  courseStatus,
  DOMAIN_LOCALES,
  PROCESSING_STATUS,
  TAB_STATUS,
} from '@shared/const';
import { logger, selectTranslation } from '@shared/utils';

import { useAppGlobals } from '../AppContext';
import { graphql } from '../../../utils';
import {
  getCourse,
  getLessonsProcessingStatus,
  getGameName as getGame,
} from './queries';
import {
  addCategoryToCourse,
  addExpertToCourse,
  createOrUpdateQuizMutation,
  deleteCourseMutation,
  deletePublishedCourseMutation,
  deleteQuizMutation,
  publishCourse,
  removeCategoryFromCourse,
  removeExpertFromCourse,
  updateAttachments,
  updateAvailability,
  updateCourseCatalog,
  updateCourseDescription,
  updateLessons,
} from './mutations';
import ERRORS from './errors';
import TABS from './tabs';
import validateCourse from './validate';

export default function useCourse({ navigate, courseId }) {
  const [canPublish, setCanPublish] = useState(false);
  const [course, setCourse] = useState(null);
  const [errorMessage, setErrorMessage] = useState();
  const [errorType, setErrorType] = useState();
  const [hasError, setHasError] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [hasQuiz, setHasQuiz] = useState(false);
  const [handleSave, setHandleSave] = useState(null);
  const [locked, setLocked] = useState(false);
  const [loading, setLoading] = useState(true);
  const [validationDetails, setValidationDetails] = useState();
  const [selectedTab, setSelectedTab] = useState(TABS.DESCRIPTION);
  const [submitting, setSubmitting] = useState(false);
  const [submittingRefresh, setSubmittingRefresh] = useState(false);
  const [uploadingFiles, setUploadingFiles] = useState(false);

  const { domain } = useAppGlobals();

  const clearError = () => {
    setHasError(false);
    setErrorMessage();
    setErrorType();
  };

  const fetchCourse = async (courseId) => {
    const { data, error } = await graphql(getCourse({ id: courseId }));
    if (error) {
      logger.error('Error occured while retrieving course', error);
      setHasError(true);
    } else if (data?.getCourse) {
      setCourse(data.getCourse);
      if (data.getCourse.status === courseStatus.PUBLISHED) setLocked(true);
    } else {
      navigate('/404');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCourse(courseId);
  }, [courseId]);

  useEffect(() => {
    if (course) {
      if (course.quiz) {
        setHasQuiz(true);
      } else {
        setHasQuiz(false);
      }

      const languages = DOMAIN_LOCALES[domain];

      const validationStatus = validateCourse(course, languages);

      setCanPublish(Object.keys(validationStatus).length === 0);
      setValidationDetails(validationStatus);
    }
  }, [course]);

  const getLanguageStatus = (language, courseProp = course) => {
    const isPublished = courseProp?.status === courseStatus.PUBLISHED;
    const languages = DOMAIN_LOCALES[domain];

    const hasDescription =
      courseProp?.descriptionTranslations &&
      !!selectTranslation(courseProp.descriptionTranslations, '', language, '');
    const hasName =
      courseProp?.nameTranslations &&
      !!selectTranslation(courseProp.nameTranslations, '', language, '');
    const hasSeoDescription =
      courseProp?.seoDescriptionTranslations &&
      !!selectTranslation(
        courseProp.seoDescriptionTranslations,
        '',
        language,
        ''
      );
    const hasShortDescription =
      courseProp?.shortDescriptionTranslations &&
      !!selectTranslation(
        courseProp.shortDescriptionTranslations,
        '',
        language,
        ''
      );
    const hasLearningPoints =
      courseProp?.keyLearningPointsTranslations &&
      !!selectTranslation(
        courseProp.keyLearningPointsTranslations,
        [],
        language,
        []
      ).length > 0;
    const hasPrerequisites =
      courseProp?.prerequisitesTranslations &&
      !!selectTranslation(
        courseProp.prerequisitesTranslations,
        [],
        language,
        []
      ).length > 0;
    const hasTypicalLearner =
      courseProp?.typicalLearnerTranslations &&
      !!selectTranslation(
        courseProp.typicalLearnerTranslations,
        [],
        language,
        []
      ).length > 0;

    const isCompleted =
      hasDescription &&
      hasName &&
      hasSeoDescription &&
      hasShortDescription &&
      hasLearningPoints &&
      (!courseProp?.prerequisitesTranslations || hasPrerequisites) &&
      (!courseProp?.typicalLearnerTranslations || hasTypicalLearner);
    const isInProgress =
      (languages || [])[language] ||
      hasDescription ||
      hasName ||
      hasSeoDescription ||
      hasShortDescription ||
      hasLearningPoints ||
      hasPrerequisites ||
      hasTypicalLearner;

    if (isPublished && locked && (isCompleted || isInProgress)) return '';
    if (isCompleted) return TAB_STATUS.COMPLETED;
    if (isInProgress) return TAB_STATUS.IN_PROGRESS;
    return TAB_STATUS.DISABLED;
  };

  /*
   * To be able to reuse the function with the original course, or the modified one,
   * I added the courseProp param, which defaults to the original course
   */
  const getLanguages = (courseProp = course) => {
    const languages = DOMAIN_LOCALES[domain];

    return languages.reduce(
      (acc, language) => ({
        ...acc,
        [language]:
          language === languages[0] ||
          getLanguageStatus(language, courseProp) !== TAB_STATUS.DISABLED,
      }),
      {}
    );
  };

  const createOrUpdateQuiz = async (quiz) => {
    clearError();
    setSubmitting(true);
    const { data, error } = await graphql(createOrUpdateQuizMutation(quiz));

    if (error) {
      logger.error('Error occured while create/update quiz', error);
      setHasError(true);
    } else if (data?.createOrUpdateQuiz) {
      setCourse({ ...course, quiz: data.createOrUpdateQuiz });
    }
    setSubmitting(false);
  };

  const deleteCourse = async (course, locale) => {
    clearError();
    const response = await graphql(deleteCourseMutation(course.id));
    if (response.error) {
      if (response.error.errors.length > 1) {
        logger.error('Multiple errors occured while deleting course:');
        response.error.errors.forEach((error) => {
          logger.error(error.message);
        });
      } else {
        logger.error(
          'Error occured while deleting course',
          response.error.errors[0].message
        );
      }
      setErrorMessage(response.error.errors[0].message);
      setErrorType(ERRORS.DELETE);
      setHasError(true);
    } else {
      const courseName = selectTranslation(
        course.nameTranslations,
        course.name,
        locale,
        ''
      );
      navigate(`/courses?deletedCourseName=${courseName}`);
    }
  };

  const deleteQuiz = async (input) => {
    clearError();
    setSubmitting(true);
    const { data, error } = await graphql(deleteQuizMutation(input));

    if (error) {
      logger.error('Error occured while deleting quiz', error);
      setHasError(true);
    } else if (data?.deleteQuiz) {
      setCourse({ ...course, quiz: null });
    }
    setSubmitting(false);
  };

  const saveAttachments = async (attachments) => {
    const mappedAttachments = (attachments || []).map(
      ({ id, name, file, processingStatus, key, ...rest }) => {
        const result = id ? { id, name } : { name, ...rest };

        if (processingStatus?.status === PROCESSING_STATUS.TO_SCHEDULE) {
          result.file = processingStatus.file;
        }

        return result;
      }
    );

    clearError();
    setSubmitting(true);
    const {
      data: { updateCourse },
      error,
    } = await graphql(
      updateAttachments({
        id: course.id,
        attachments: mappedAttachments,
      })
    );
    if (error) {
      logger.error('Error occured while saving course', error);
      setHasError(true);
    } else {
      setCourse({ ...course, ...updateCourse });
    }
    setSubmitting(false);
  };

  const saveDescription = async (newCourse) => {
    clearError();
    setSubmitting(true);
    const {
      data: { updateCourse },
      error,
    } = await graphql(updateCourseDescription(newCourse));
    if (error) {
      logger.error('Error occured while saving course', error);
      setHasError(true);
    } else {
      setCourse({ ...course, ...updateCourse });
    }
    setSubmitting(false);
  };

  const saveAvailability = async (newCourse) => {
    clearError();
    setSubmitting(true);
    const {
      data: { updateCourse },
      error,
    } = await graphql(updateAvailability(newCourse));
    if (error) {
      logger.error('Error occured while saving availability', error);
      setHasError(true);
    } else {
      const { ownerOrganization } = updateCourse;
      const updatedItem = { ...course, ...updateCourse };

      if (ownerOrganization) {
        updatedItem.ownerOrganization = ownerOrganization;
      }
      setCourse(updatedItem);
    }
    setSubmitting(false);
  };

  const saveLessons = async (newCourse) => {
    clearError();
    setSubmitting(true);

    const {
      data: { updateCourse },
      error,
    } = await graphql(updateLessons(newCourse));
    if (error) {
      logger.error('Error occured while saving lessons', error);
      setHasError(true);
    } else {
      setCourse({ ...course, ...updateCourse });
    }
    setSubmitting(false);
  };

  const saveCatalog = async (newCourse) => {
    clearError();
    setSubmitting(true);

    const queries = [];
    const addRemoveQueries = ({
      field,
      idQueryName,
      queryToAdd,
      queryToRemove,
    }) => {
      if (
        JSON.stringify(newCourse[field] || '{}') !==
        JSON.stringify(course[field] || '{}')
      ) {
        const newFieldItems = newCourse[field]?.items || [];
        const oldFieldItems = course[field]?.items || [];

        const itemsToRemove =
          oldFieldItems.filter(
            (oldItem) =>
              !newFieldItems.find((newItem) => oldItem.id === newItem.id)
          ) || [];

        const itemsToAdd =
          newFieldItems.filter(
            (newItem) =>
              !oldFieldItems.find((oldItem) => newItem.id === oldItem.id)
          ) || [];

        itemsToRemove.map((item) =>
          queries.push(async () => {
            try {
              const { error } = await graphql(
                queryToRemove({ [idQueryName]: item.id, courseId: course.id })
              );
              if (error) throw new Error(error);
            } catch ({ errors }) {
              logger.error(
                `Error occured while saving ${field} in course`,
                errors
              );
              setHasError(true);
            }
          })
        );
        itemsToAdd.map((item) =>
          queries.push(async () => {
            try {
              const { error } = await graphql(
                queryToAdd({ [idQueryName]: item.id, courseId: course.id })
              );
              if (error) throw new Error(error);
            } catch (error) {
              logger.error(
                `Error occured while saving ${field} in course`,
                error
              );
              setHasError(true);
            }
          })
        );
      }
    };

    addRemoveQueries({
      field: 'categories',
      idQueryName: 'categoryId',
      queryToAdd: addCategoryToCourse,
      queryToRemove: removeCategoryFromCourse,
    });
    addRemoveQueries({
      field: 'experts',
      idQueryName: 'expertId',
      queryToAdd: addExpertToCourse,
      queryToRemove: removeExpertFromCourse,
    });

    await Promise.all(queries.map((query) => query()));

    /* *
     * this query is separated from the async flow because
     * we need the update query to return the updated course
     * */
    try {
      const updatedCourse = {
        id: newCourse.id,
      };

      if (
        newCourse.promopictureProcessingStatus?.status ===
        PROCESSING_STATUS.TO_SCHEDULE
      ) {
        updatedCourse.promopicture =
          newCourse.promopictureProcessingStatus.file;
      } else if (
        !newCourse.promopicture &&
        !newCourse.promopictureProcessingStatus
      ) {
        updatedCourse.promopicture = null;
      }

      if (
        newCourse.thumbnailProcessingStatus?.status ===
        PROCESSING_STATUS.TO_SCHEDULE
      ) {
        updatedCourse.thumbnail = newCourse.thumbnailProcessingStatus.file;
      } else if (!newCourse.thumbnail && !newCourse.thumbnailProcessingStatus) {
        updatedCourse.thumbnail = null;
      }

      const {
        data: { updateCourse },
        error,
      } = await graphql(updateCourseCatalog(updatedCourse));

      if (error) {
        logger.error('Error occured while saving course', error);
        setHasError(true);
      } else {
        setCourse({ ...course, ...updateCourse });
      }
    } catch (e) {
      setHasError(true);
      fetchCourse(newCourse.id);
    }

    setSubmitting(false);
  };

  const publish = async (newCourse) => {
    clearError();
    setSubmitting(true);
    const { data: { updateCourse } = {}, error } = await graphql(
      publishCourse(newCourse.id)
    );
    if (error) {
      logger.error('Error occured while publishing course', error);
      setHasError(true);
    } else {
      setCourse({ ...course, ...updateCourse });
    }
    setSubmitting(false);
  };

  const getFormattedTranslations = (content) => {
    let formattedField = {};
    try {
      formattedField = JSON.parse(content || '{}');
    } catch (err) {
      logger.error('Error: ', err);
    }
    return formattedField;
  };

  const getGameName = async (gameId) => {
    const { data, error } = await graphql(getGame(gameId));
    if (error) {
      logger.error('Error occured while checking game', error);
    }
    return data?.getGameName;
  };

  const deletePublishedCourse = async () => {
    clearError();
    setSubmitting(true);
    const { data: { updateCourse } = {}, error } = await graphql(
      deletePublishedCourseMutation(course.id)
    );
    if (error) {
      logger.error('Error occured while deleting published course', error);
      setErrorMessage(error.errors[0].message);
      setErrorType(ERRORS.DELETE_PUBLISHED);
      setHasError(true);
    } else {
      setCourse({ ...course, ...updateCourse });
    }
    setSubmitting(false);
  };

  // Fetch and update the processing status of any lesson items (videofiles, attachments and subtitles) and the trailer
  const fetchLessonsProcessingStatus = async () => {
    setSubmittingRefresh(true);
    const { data, error } = await graphql(
      getLessonsProcessingStatus({ id: courseId })
    );
    if (error) {
      logger.error('Error occured while retrieving course', error);
      setHasError(true);
    } else if (data?.getCourse) {
      const processingData = data.getCourse;
      setCourse({
        ...course,
        ...(processingData.trailerVideoProcessingStatus
          ? {
              trailerVideoProcessingStatus:
                processingData.trailerVideoProcessingStatus,
            }
          : {}),
        episodes: course.episodes.map((episode) => {
          const episodeProcessingData = processingData.episodes.find(
            ({ id }) => id === episode.id
          );
          return {
            ...episode,
            ...(episodeProcessingData.subtitleProcessingStatus
              ? {
                  subtitleProcessingStatus:
                    episodeProcessingData.subtitleProcessingStatus,
                }
              : {}),
            ...(episodeProcessingData.videoProcessingStatus
              ? {
                  videoProcessingStatus:
                    episodeProcessingData.videoProcessingStatus,
                }
              : {}),
            attachments: episode.attachments.map((attachment) => {
              const attachmentProcessingData =
                episodeProcessingData.attachments.find(
                  ({ id }) => id === attachment.id
                );
              return {
                ...attachment,
                processingStatus: attachmentProcessingData?.processingStatus,
              };
            }),
          };
        }),
      });
    }
    setSubmittingRefresh(false);
  };

  return {
    canPublish,
    clearError,
    course,
    createOrUpdateQuiz,
    deleteCourse,
    deletePublishedCourse,
    deleteQuiz,
    errorMessage,
    errorType,
    fetchLessonsProcessingStatus,
    getFormattedTranslations,
    getGameName,
    getLanguages,
    getLanguageStatus,
    handleSave,
    hasChanged,
    hasError,
    hasQuiz,
    loading,
    locked,
    publish,
    saveAttachments,
    saveAvailability,
    saveCatalog,
    saveDescription,
    saveLessons,
    selectedTab,
    setHandleSave,
    setHasChanged,
    setHasError,
    setLocked,
    setSelectedTab,
    setSubmitting,
    setUploadingFiles,
    submitting,
    submittingRefresh,
    uploadingFiles,
    validationDetails,
    validateCourse,
  };
}

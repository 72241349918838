import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Table as TableMui,
  TableBody as TableBodyMui,
  TableCell as TableCellMui,
  TableRow as TableRowMui,
} from '@mui/material';
import theme from 'shared/themes/default';

const TotalsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Totals = ({ data, columns, ...props }) => {
  const keys = Object.keys(data);
  const chunkSize = keys.length / columns;
  const chunked = keys
    .reduce((all, one, i) => {
      const ch = Math.floor(i / chunkSize);
      const newAll = [...all];
      newAll[ch] = [].concat(all[ch] || [], one);
      return newAll;
    }, [])
    .map((item, i) => ({ i, data: item }));
  const sxTable = {
    width: 'auto',
    '&:not(:first-child)': {
      marginLeft: '4.5rem',
    },
  };
  const sxCell = {
    border: 0,
    padding: 0,
    paddingRight: '2.5rem',
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    color: theme.palette.lightPure,
  };
  const sxCellContent = {
    border: 0,
    padding: 0,
    color: theme.palette.lightPure,
    fontSize: '1rem',
    lineHeight: '1.5rem',
  };
  return (
    <TotalsWrapper>
      {chunked.map((column) => (
        <TableMui sx={sxTable} key={column.i} {...props}>
          <TableBodyMui>
            {column.data.map((item) => (
              <TableRowMui key={`${column.i}-${item}`}>
                <TableCellMui sx={sxCell}>{`${item}:`}</TableCellMui>
                <TableCellMui sx={sxCellContent}>{data[item]}</TableCellMui>
              </TableRowMui>
            ))}
          </TableBodyMui>
        </TableMui>
      ))}
    </TotalsWrapper>
  );
};

Totals.propTypes = {
  data: (props, propName, componentName) => {
    if (props[propName] === undefined) {
      return new Error(
        `The prop \`${propName}\` is marked as required in \`${componentName}\`, but its value is \`${props[propName]}\`.`
      );
    }
    if (props[propName] && typeof props[propName] !== 'object') {
      return new Error(
        `Invalid prop \`${propName}\` of type \`${typeof props[
          propName
        ]}\` supplied to \`${componentName}\`, expected \`object\`.`
      );
    }
    return null;
  },
  columns: PropTypes.number,
};

Totals.defaultProps = {
  columns: 1,
};

export default Totals;

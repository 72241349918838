import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import T from 'shared/atoms/Typography';
import Dialog from 'shared/molecules/Dialog';
import ConfirmationDialog from 'shared/molecules/ConfirmationDialog';
import TransferOwnershipView from 'shared/organisms/TransferOwnershipView';
import { PLAYLIST_STATUS, PLAYLIST_TYPE } from 'shared/const';
import theme from 'shared/themes/default';

import CreateNanoDegreeDialog from './CreateNanoDegreeDialog';
import CopyFromExistingPlaylist from './CopyFromExistingPlaylist';
import CreateNewPlaylistDialog from './CreateNewPlaylistDialog';
import ManageQuizDialog from './ManageQuizDialog';
import PlaylistSelectCourse from './PlaylistSelectCourse';
import SharePlaylistContainer from './SharePlaylistContainer';

import { usePlaylist } from '../../context';

const PlaylistPublishDialogWrapper = styled.div`
  margin-bottom: 1rem;
  text-align: center;
`;

const Dialogs = () => {
  const { t } = useTranslation();

  const {
    dialogs: {
      // Share open variables
      openPlaylistForm,
      openNanodegreeForm,
      openDeleteDialog,
      openPauseDialog,
      openPublishDialog,
      openArchiveDialog,
      openAddExamDialog,
      openAddCoursesToPlaylist,
      openSharePlaylistDialog,
      openShowTransferOwnership,
      openCopyPlaylistForm,
      // Dialog status
      formStatus,
      submittingPublish,
      // Dialog state variables
      archivePlaylistId,
      deleteNanodegree,
      deletePlaylistId,
      initialValues,
      nanodegreeStatus,
      pausePlaylist,
      pausePlaylistId,
      publishPlaylistType,
      removeContentId,
      selectedPlaylistForReminder,
      setSelectedPlaylistForReminder,
      setSubmittingPublish,
      submittingReminder,
      transferValues,
      // Playlists dialogs
      onDeletePlaylist,
      onDeleteCloseHandler,
      onStatusUpdate,
      // Pause dialog
      onPauseCloseHandler,
      // Archive dialog
      onArchiveCloseHandler,
      // Publish dialog
      onPublish,
      onPublishCloseHandler,
      // Content dialog
      removeFromPlaylistHandler,
      // Transfer dialog
      onTransferOwnershipHandler,
      onCloseTransferHandler,
      sendReminderHandler,
    },
    playlists: { isNanodegree, playlistManagers },
  } = usePlaylist();

  return (
    <>
      {openCopyPlaylistForm && <CopyFromExistingPlaylist />}
      {openPlaylistForm && (
        <CreateNewPlaylistDialog initialValues={initialValues} />
      )}
      {openNanodegreeForm && (
        <CreateNanoDegreeDialog
          published={nanodegreeStatus === PLAYLIST_STATUS.PUBLISHED}
          initialValues={initialValues}
        />
      )}
      {openAddExamDialog && <ManageQuizDialog />}
      {openAddCoursesToPlaylist && <PlaylistSelectCourse />}
      {openSharePlaylistDialog && <SharePlaylistContainer />}
      {openShowTransferOwnership && (
        <TransferOwnershipView
          onSubmitHandler={onTransferOwnershipHandler}
          transferItemName={transferValues.name}
          usersToTransfer={playlistManagers.filter(
            (manager) => manager.id !== transferValues.ownerId
          )}
          onClose={onCloseTransferHandler}
          formStatus={formStatus}
        />
      )}

      <ConfirmationDialog
        open={openDeleteDialog}
        ariaLabel={
          removeContentId
            ? t(
                isNanodegree
                  ? 'nanodegree.removeFromNanodegree'
                  : 'playlist.removeFromPlaylist'
              )
            : t(
                deleteNanodegree
                  ? 'playlist.removeNanodegree'
                  : 'playlist.removePlaylist'
              )
        }
        title={
          removeContentId
            ? t(
                isNanodegree
                  ? 'nanodegree.removeFromNanodegree'
                  : 'playlist.removeFromPlaylist'
              )
            : t(
                deleteNanodegree
                  ? 'playlist.removeNanodegree'
                  : 'playlist.removePlaylist'
              )
        }
        description={t('playlist.areYouSure')}
        cancelText={t('generic.cancel')}
        confirmText={t('generic.delete')}
        onCancel={onDeleteCloseHandler}
        onClose={onDeleteCloseHandler}
        formStatus={formStatus}
        onConfirm={
          removeContentId
            ? () => removeFromPlaylistHandler(removeContentId)
            : () => onDeletePlaylist(deletePlaylistId)
        }
      />

      <Dialog
        ariaLabel={
          publishPlaylistType === PLAYLIST_TYPE.PLAYLIST
            ? t('playlist.publishPlaylist')
            : t('nanodegree.publish')
        }
        open={openPublishDialog}
        actions={[
          {
            ariaLabel: t('generic.cancel'),
            handler: onPublishCloseHandler,
            label: t('generic.cancel'),
            type: 'secondaryOutline',
          },
          {
            ariaLabel: t('playlist.publish'),
            handler: () => {
              setSubmittingPublish(true);
              onPublish();
            },
            label: t('playlist.publish'),
          },
        ]}
        confirmationTitle={
          publishPlaylistType === PLAYLIST_TYPE.PLAYLIST
            ? t('playlist.publishPlaylist')
            : t('nanodegree.publish')
        }
        formStatus={formStatus}
        loading={submittingPublish}
      >
        <PlaylistPublishDialogWrapper>
          <T
            fontWeight="semibold"
            align="center"
            component="span"
            color={theme.palette.mainDark}
          >
            {publishPlaylistType === PLAYLIST_TYPE.PLAYLIST
              ? t('playlist.soonAvailable')
              : t('nanodegree.soonAvailable')}
          </T>
          <T align="center" component="span" color={theme.palette.mainDark}>
            {` ${t('playlist.contentChecked')}`}
          </T>
        </PlaylistPublishDialogWrapper>
        {publishPlaylistType === PLAYLIST_TYPE.NANO_DEGREE && (
          <T align="center" italic color={theme.palette.mainDark}>
            {t('playlist.noEditAfterPublishing')}
          </T>
        )}
      </Dialog>

      <Dialog
        actions={[
          {
            ariaLabel: t('generic.cancel'),
            handler: () => setSelectedPlaylistForReminder(null),
            label: t('generic.cancel'),
            type: 'secondaryOutline',
            disabled: submittingReminder,
          },
          {
            ariaLabel: t('playlist.sendReminder'),
            handler: sendReminderHandler,
            label: t('playlist.sendReminder'),
            loading: submittingReminder,
          },
        ]}
        ariaLabel={t('playlist.sendReminder')}
        onClose={() => setSelectedPlaylistForReminder(null)}
        open={!!selectedPlaylistForReminder}
        title={t('watchData.sendReminder')}
      >
        <T color={theme.palette.pureDark}>
          {t('playlist.sendReminderDialogText')}
        </T>
      </Dialog>

      <ConfirmationDialog
        open={openPauseDialog}
        ariaLabel={t('playlist.pause')}
        title={t('playlist.pause')}
        description={
          pausePlaylist?.type === PLAYLIST_TYPE.NANO_DEGREE
            ? t('nanodegree.confirmPause')
            : t('playlist.confirmPause')
        }
        cancelText={t('generic.cancel')}
        confirmText={t('playlist.pause')}
        onCancel={onPauseCloseHandler}
        onClose={onPauseCloseHandler}
        formStatus={formStatus}
        onConfirm={() =>
          onStatusUpdate(pausePlaylistId, PLAYLIST_STATUS.PAUSED)
        }
      />

      <ConfirmationDialog
        open={openArchiveDialog}
        ariaLabel={t('nanodegree.confirmArchiveTitle')}
        title={t('nanodegree.confirmArchiveTitle')}
        description={
          <>
            <T
              color={theme.palette.pureDark}
              component="span"
              fontWeight="bold"
            >
              {t('nanodegree.confirmArchiveUndone')}
            </T>
            <br />
            {t('nanodegree.confirmArchiveDescription')}
          </>
        }
        cancelText={t('generic.cancel')}
        confirmText={t('generic.delete')}
        onCancel={onArchiveCloseHandler}
        onClose={onArchiveCloseHandler}
        formStatus={formStatus}
        onConfirm={() =>
          onStatusUpdate(archivePlaylistId, PLAYLIST_STATUS.ARCHIVED)
        }
      />
    </>
  );
};

export default Dialogs;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Button from '@shared/atoms/Button';
import FormAutocompleteWithList from '@shared/atoms/FormFields/FormAutocompleteWithList';
import FormInput from '@shared/atoms/FormFields/FormInput';
import FormSelect from '@shared/atoms/FormFields/FormSelect';
import { AUTOCOMPLETEWITHLIST_TYPE, PROCESSING_STATUS } from '@shared/const';
import FormImage from '@shared/atoms/FormFields/FormImage';
import theme from '@shared/themes/default';
import { logger, selectTranslation, useForm } from '@shared/utils';
import { useAppGlobals } from '../../../../contexts/AppContext';
import {
  getUploadUrl,
  startUpload,
  completeUpload,
} from '../../../../../graphql/mutations';
import { getStaticImagesHostname } from '../../../../../config';

import { addFeaturedItem, removeFeaturedItem } from '../../graphql/mutations';
import { listCourses } from '../../graphql/queries';
import { graphql, useGraphQLQuery } from '../../../../../utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m1};
  max-width: 600px;
  padding: ${theme.spacing.s};
`;

const FeaturedItemTab = ({
  item,
  onUpdateOrganization,
  setErrorMessage,
  setHasError,
  setHasSuccess,
  setSuccessMessage,
}) => {
  const [courses, setCourses] = useState([]);
  const [course, setCourse] = useState(item.featuredItem?.courseId);
  const [courseSubmitting, setCourseSubmitting] = useState(false);
  const [featuredItem, setFeaturedItem] = useState(item.featuredItem);
  const [promotedType, setPromotedType] = useState('none');

  const { domain, locale } = useAppGlobals();
  const { t } = useTranslation();

  const options = [
    {
      label: t('backoffice.organizations.featuredItem.none'),
      key: 'none',
      value: 'none',
    },
    {
      label: t('backoffice.organizations.featuredItem.promotedCourse'),
      key: 'course',
      value: 'course',
    },
    {
      label: t('backoffice.organizations.featuredItem.image'),
      key: 'image',
      value: 'image',
    },
  ];

  useEffect(() => {
    if (item.featuredItem?.courseId) {
      setPromotedType('course');
    } else if (item.featuredItem?.imageProcessingStatus) {
      setPromotedType('image');
    }
  }, []);

  // Courses for selector initialisation

  const {
    callQuery: callCoursesQuery,
    data: coursesData,
    errors: coursesErrors,
    hasEnded: hasCoursesEnded,
    hasErrors: hasCoursesErrors,
    loading: coursesLoading,
  } = useGraphQLQuery();

  const loadCourses = async (nextToken) => {
    callCoursesQuery(
      listCourses({
        domain,
        hideVcCourses: item.hideVcCourses,
        nextToken,
        ownerOrganizationId: item.id,
      })
    );
  };

  useEffect(() => {
    setCourses([]);
    loadCourses();
  }, [item.hideVcCourses]);

  useEffect(() => {
    if (hasCoursesEnded) {
      if (hasCoursesErrors) {
        logger.error('Error: ', coursesErrors);
        setErrorMessage(t('backoffice.organizations.featuredItem.error'));
        setHasError(true);
      } else {
        const {
          listCourses: { items, nextToken },
        } = coursesData;
        // Filtering out courses marked for archival, as currently it cannot be done from the backend
        setCourses([
          ...courses,
          ...items.filter((course) => !course.archivedScheduledAt),
        ]);
        if (nextToken) {
          loadCourses(nextToken);
        }
      }
    }
  }, [hasCoursesEnded]);

  // Promoted course queries

  const {
    callQuery: callAddFeaturedCourseQuery,
    data: addFeaturedCourseData,
    errors: addFeaturedCourseErrors,
    hasEnded: hasAddFeaturedCourseEnded,
    hasErrors: hasAddFeaturedCourseErrors,
  } = useGraphQLQuery();

  const {
    callQuery: callRemoveFeaturedCourseQuery,
    errors: removeFeaturedCourseErrors,
    hasEnded: hasRemoveFeaturedCourseEnded,
    hasErrors: hasRemoveFeaturedCourseErrors,
  } = useGraphQLQuery();

  // Promoted image queries and form

  const {
    callQuery: callAddFeaturedImageQuery,
    data: addFeaturedImageData,
    errors: addFeaturedImageErrors,
    hasEnded: hasAddFeaturedImageEnded,
    hasErrors: hasAddFeaturedImageErrors,
  } = useGraphQLQuery();

  const {
    callQuery: callRemoveFeaturedImageQuery,
    errors: removeFeaturedImageErrors,
    hasEnded: hasRemoveFeaturedImageEnded,
    hasErrors: hasRemoveFeaturedImageErrors,
  } = useGraphQLQuery();

  const {
    edited,
    errors: formErrors,
    handleBlur,
    handleChange,
    handleSubmit,
    missingRequired,
    properties,
    setDefaults,
    submitting,
    values,
  } = useForm({
    changesRequired: true,
    fields: {
      title: {
        default: item.featuredItem?.title || '',
        required: true,
        maxLength: 70,
      },
      description: {
        default: item.featuredItem?.description || '',
        required: true,
        maxLength: 300,
      },
      image: {
        default: {
          value: item.featuredItem?.image,
          processingStatus: item.featuredItem?.imageProcessingStatus,
        },
        required: true,
      },
    },
    onSubmitHook: ({ title, description, image }) => {
      if (featuredItem) {
        callRemoveFeaturedImageQuery(removeFeaturedItem(featuredItem.id));
      } else {
        callAddFeaturedImageQuery(
          addFeaturedItem({
            organizationId: item.id,
            domain,
            image:
              image.processingStatus?.status === PROCESSING_STATUS.PROCESSED
                ? image.value
                : image.processingStatus.file,
            title,
            description,
          })
        );
      }
    },
    onSubmitHookEnded:
      (!featuredItem || hasRemoveFeaturedImageEnded) &&
      (hasAddFeaturedImageEnded || hasRemoveFeaturedImageErrors),
  });

  // Promoted course calls

  const handleCourseSubmit = async () => {
    setCourseSubmitting(true);

    if (featuredItem) {
      callRemoveFeaturedCourseQuery(removeFeaturedItem(featuredItem.id));
    } else if (promotedType === 'course') {
      callAddFeaturedCourseQuery(
        addFeaturedItem({
          organizationId: item.id,
          domain,
          courseId: course,
        })
      );
    }
  };

  useEffect(() => {
    if (hasRemoveFeaturedCourseEnded) {
      if (hasRemoveFeaturedCourseErrors) {
        logger.error('Error: ', removeFeaturedCourseErrors);
        setErrorMessage(t('backoffice.organizations.featuredItem.error'));
        setHasError(true);
        setCourseSubmitting(false);
      } else {
        setFeaturedItem();
        if (promotedType === 'course') {
          callAddFeaturedCourseQuery(
            addFeaturedItem({
              organizationId: item.id,
              domain,
              courseId: course,
            })
          );
        } else {
          setCourse();
          setCourseSubmitting(false);
          setDefaults({
            title: '',
            description: '',
            image: null,
          });

          onUpdateOrganization({ id: item.id, featuredItem: null });
          setSuccessMessage(
            t('backoffice.organizations.featuredItem.successfullyRemoved')
          );
          setHasSuccess(true);
        }
      }
    }
  }, [hasRemoveFeaturedCourseEnded]);

  useEffect(() => {
    if (hasAddFeaturedCourseEnded) {
      if (hasAddFeaturedCourseErrors) {
        logger.error('Error: ', addFeaturedCourseErrors);
        setErrorMessage(t('backoffice.organizations.featuredItem.error'));
        setHasError(true);
      } else {
        const { addFeaturedItem } = addFeaturedCourseData;
        setFeaturedItem(addFeaturedItem);
        setCourse(addFeaturedItem.courseId);
        setDefaults({
          title: '',
          description: '',
          image: null,
        });

        onUpdateOrganization({ id: item.id, featuredItem: addFeaturedItem });
        setSuccessMessage(
          t('backoffice.organizations.featuredItem.successfullyAdded')
        );
        setHasSuccess(true);
      }
      setCourseSubmitting(false);
    }
  }, [hasAddFeaturedCourseEnded]);

  // Promoted image calls
  useEffect(() => {
    if (hasRemoveFeaturedImageEnded) {
      if (hasRemoveFeaturedImageErrors) {
        logger.error('Error: ', removeFeaturedImageErrors);
        setErrorMessage(t('backoffice.organizations.featuredItem.error'));
        setHasError(true);
      } else {
        const { title, description, image } = values;
        setFeaturedItem();
        callAddFeaturedImageQuery(
          addFeaturedItem({
            organizationId: item.id,
            domain,
            image:
              image.processingStatus?.status === PROCESSING_STATUS.PROCESSED
                ? image.value
                : image.processingStatus.file,
            title,
            description,
          })
        );
      }
    }
  }, [hasRemoveFeaturedImageEnded]);

  useEffect(() => {
    if (hasAddFeaturedImageEnded) {
      if (hasAddFeaturedImageErrors) {
        logger.error('Error: ', addFeaturedImageErrors);
        setErrorMessage(t('backoffice.organizations.featuredItem.error'));
        setHasError(true);
      } else {
        const { addFeaturedItem } = addFeaturedImageData;
        setFeaturedItem(addFeaturedItem);
        setCourse();
        setDefaults({
          title: addFeaturedItem.title,
          description: addFeaturedItem.description,
          image: {
            value: values.image.value,
            processingStatus: addFeaturedItem.imageProcessingStatus,
          },
        });

        onUpdateOrganization({ id: item.id, featuredItem: addFeaturedItem });
        setSuccessMessage(
          t('backoffice.organizations.featuredItem.successfullyAdded')
        );
        setHasSuccess(true);
      }
    }
  }, [hasAddFeaturedImageEnded]);

  const handleAddImage = (file) => {
    handleChange({
      name: 'image',
      value: {
        value: { base64: file.base64 },
        processingStatus: {
          file: file.s3File,
          status: PROCESSING_STATUS.TO_SCHEDULE,
        },
      },
    });
  };

  const handleDeleteImage = () => {
    handleChange({
      name: 'image',
      value: null,
    });
  };

  return (
    <Wrapper>
      <FormSelect
        id="type"
        name="type"
        items={options}
        disabled={submitting}
        label={t('backoffice.organizations.featuredItem.type')}
        handleChange={({ value }) => setPromotedType(value)}
        required
        theme="dark"
        value={promotedType}
      />
      {promotedType === 'none' && (
        <Button
          ariaLabel={t('generic.save')}
          disabled={courseSubmitting || !featuredItem}
          loading={courseSubmitting}
          loadingOnIcon={courseSubmitting}
          onClick={handleCourseSubmit}
          sx={{ alignSelf: 'end' }}
          theme="dark"
        >
          {t('generic.save')}
        </Button>
      )}
      {promotedType === 'course' && (
        <>
          <FormAutocompleteWithList
            id="promoted-course"
            name="promoted-course"
            disabled={courseSubmitting}
            items={courses.map(({ id, name, nameTranslations }) => ({
              key: id,
              title: selectTranslation(
                nameTranslations,
                name,
                locale,
                `<No name, ID: ${id}>`
              ),
            }))}
            label={t('backoffice.organizations.featuredItem.promotedCourse')}
            limit={1}
            loading={coursesLoading}
            loadingText={t('generic.loadingText')}
            noOptionsText={t('generic.noSearchResults')}
            onAdd={setCourse}
            onDelete={() => {
              setCourse();
            }}
            required
            selectedItems={
              course
                ? [courses.find(({ id }) => id === course)]
                    .filter((course) => course)
                    .map((course) => ({
                      key: course.id,
                      title: selectTranslation(
                        course.nameTranslations,
                        course.name,
                        locale,
                        `<No name, ID: ${course.id}>`
                      ),
                    }))
                : []
            }
            theme="dark"
            type={AUTOCOMPLETEWITHLIST_TYPE.FORM}
          />
          <Button
            ariaLabel={t('generic.save')}
            disabled={
              courseSubmitting || !course || course === featuredItem?.course?.id
            }
            loading={courseSubmitting}
            loadingOnIcon={courseSubmitting}
            onClick={handleCourseSubmit}
            sx={{ alignSelf: 'end' }}
            theme="dark"
          >
            {t('generic.save')}
          </Button>
        </>
      )}
      {promotedType === 'image' && (
        <>
          <FormInput
            id="title"
            name="title"
            label={t('backoffice.organizations.featuredItem.title')}
            errors={formErrors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            properties={properties}
            showMaxLength
            theme="dark"
            values={values}
          />
          <FormInput
            id="description"
            name="description"
            label={t('backoffice.organizations.featuredItem.description')}
            errors={formErrors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            minRows={3}
            multiline
            properties={properties}
            showMaxLength
            theme="dark"
            values={values}
          />
          <FormImage
            completeUpload={completeUpload}
            getUploadUrl={getUploadUrl}
            graphql={graphql}
            handleAdd={handleAddImage}
            handleDelete={handleDeleteImage}
            id="image"
            infoText={t('backoffice.organizations.featuredItem.pictureInfo')}
            imgUrl={
              values.image?.value &&
              (values.image.value.base64 ||
                `${getStaticImagesHostname()}/${values.image.value.key}`)
            }
            name="image"
            processingStatus={values.image?.processingStatus?.status}
            properties={properties}
            startUpload={startUpload}
            theme="dark"
            title={t('backoffice.organizations.featuredItem.picture')}
          />
          <Button
            ariaLabel={t('generic.save')}
            disabled={missingRequired || !edited || submitting}
            loading={submitting}
            loadingOnIcon={submitting}
            onClick={handleSubmit}
            sx={{ alignSelf: 'end' }}
            theme="dark"
          >
            {t('generic.save')}
          </Button>
        </>
      )}
    </Wrapper>
  );
};

FeaturedItemTab.propTypes = {
  item: PropTypes.object.isRequired,
  onUpdateOrganization: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  setHasError: PropTypes.func.isRequired,
  setHasSuccess: PropTypes.func.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
};

export default FeaturedItemTab;

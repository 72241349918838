import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Table, { ORDER_TYPES } from 'shared/organisms/Table';

const OrganizationGroupsStatsTable = ({
  itemsToDisplay,
  isManager,
  selectGroupsHandler,
}) => {
  const { t } = useTranslation();

  const columns = [
    {
      key: 'name',
      label: t('administrateUsers.groupName'),
      sticky: true,
      fixedWidth: isManager ? '43%' : '20%',
    },
    { key: 'membersCount', label: t('watchData.users') },
    { key: 'playlistsCompleted', label: t('watchData.completedPlaylist') },
    { key: 'coursesCompleted', label: t('watchData.completedCourses') },
    { key: 'episodesCompleted', label: t('watchData.completedEpisodes') },
    { key: 'certificatesEarned', label: t('watchData.certificatesEarned') },
  ];

  if (!isManager) {
    columns.splice(1, 0, {
      key: 'ownedByName',
      label: t('watchData.owner'),
      fixedWidth: '21%',
      maskValue: true,
    });
  }

  return (
    <Table
      ariaLabel={t('watchData.activitiesOfYourGroups')}
      columns={columns}
      items={itemsToDisplay}
      defaultOrder={ORDER_TYPES.DESC}
      defaultOrderBy="episodesCompleted"
      rowIsClickable={(row) => row.membersCount}
      onRowClick={(row) => selectGroupsHandler(row)}
    />
  );
};

OrganizationGroupsStatsTable.propTypes = {
  itemsToDisplay: PropTypes.array.isRequired,
  selectGroupsHandler: PropTypes.func.isRequired,
  isManager: PropTypes.bool,
};

OrganizationGroupsStatsTable.defaultProps = {
  isManager: false,
};

export default OrganizationGroupsStatsTable;

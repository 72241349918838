/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';

import Alert from 'shared/atoms/Alert';
import Button from 'shared/atoms/Button';
import Form from 'shared/atoms/Form';
import Icon from 'shared/atoms/Icon';
import SearchField from 'shared/atoms/SearchField';
import T from 'shared/atoms/Typography';
import ConfirmationDialog from 'shared/molecules/ConfirmationDialog';
import Dialog from 'shared/molecules/Dialog';
import ListItems from 'shared/molecules/ListItems';

import theme from 'shared/themes/default';

export const Spacer = styled.div`
  height: ${theme.spacing.m1};
`;

export const ButtonWrapper = styled.div`
  padding: ${theme.spacing.m1};
`;

export const SideSpaceWrapper = styled.div`
  padding: 0 ${theme.spacing.m1};
  display: block !important;
`;

export const ListWrapper = styled.div`
  margin-top: ${theme.spacing.m1};
  display: flex;
  flex-direction: column;
`;

export const NoGroupMembersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 250px;
`;

export const NoMembersInnerWrapper = styled.div`
  max-width: 250px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const UserIconWrapper = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: ${theme.palette.brand};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
`;

export const SharingOptionsList = styled.ul`
  margin-top: 1.5rem;
  padding: 0;
`;

function ViewGroupMembersMobileView({
  initialGroupMembers,
  loading,
  onClose,
  onSubmitHandler,
  groupName,
  open,
  setEditGroupMembersOpen,
  updatedGroupMembers,
  setUpdatedGroupMembers,
  formStatus,
}) {
  const [submitting, setSubmitting] = useState(false);
  const [showConfirmationDialogue, setShowConfirmationDialogue] =
    useState(false);
  const [groupMembers, setGroupMembers] = useState([]);
  const [groupMembersParsed, setGroupMembersParsed] = useState([]);
  const [membersSearched, setMembersSearched] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (formStatus) {
      setShowConfirmationDialogue(false);
      setSubmitting(false);
    }
  }, [formStatus]);

  useEffect(() => {
    const newMembersCount =
      updatedGroupMembers?.length - initialGroupMembers?.length;
    if (newMembersCount > 0) {
      setGroupMembers(updatedGroupMembers);
    }
  }, [updatedGroupMembers]);

  useEffect(() => {
    setSubmitting(false);
    setGroupMembers(initialGroupMembers);
  }, [initialGroupMembers]);

  const memberParse = (member) => {
    const { email: subtitle, name: title } = member;

    return {
      key: subtitle,
      subtitle,
      title,
    };
  };

  useEffect(() => {
    setGroupMembersParsed(
      (groupMembers || []).map((member) => memberParse(member))
    );
  }, [groupMembers]);

  const onSubmit = () => {
    setSubmitting(true);
    setShowConfirmationDialogue(false);
    onSubmitHandler(groupMembers);
  };

  const onRemoveUserFromGroup = (email) => {
    const newValue = groupMembers.filter((user) => user.email !== email);
    setGroupMembers(newValue);
  };

  const onCloseHandler = () => {
    if (!formStatus && groupMembers.length !== initialGroupMembers.length) {
      setShowConfirmationDialogue(true);
    } else {
      onClose();
    }
  };

  const onDiscardHandler = () => {
    setGroupMembers(initialGroupMembers);
    setShowConfirmationDialogue(false);
    onClose();
  };

  const actions = [
    {
      ariaLabel: t('generic.cancel'),
      handler: () => onCloseHandler(),
      label: t('generic.cancel'),
      type: 'secondaryOutline',
    },
    {
      ariaLabel: t('generic.saveChanges'),
      disabled: groupMembers.length === initialGroupMembers.length,
      handler: onSubmit,
      label: t('generic.saveChanges'),
      submit: true,
    },
  ];

  const onSearchHandler = ({ value }) => {
    setSearchTerm(value);
    if (value) {
      const searchedMember = groupMembers
        .filter((member) => {
          return (
            member.name.toLowerCase().includes(value.toLowerCase()) ||
            member.email.toLowerCase().includes(value.toLowerCase())
          );
        })
        .map((member) => memberParse(member));
      setMembersSearched(searchedMember);
    } else {
      setMembersSearched(null);
    }
  };

  const addMembersToGroupHandler = () => {
    setEditGroupMembersOpen(true);
    setUpdatedGroupMembers(groupMembers);
  };

  return (
    <Dialog
      open={open}
      onClose={onCloseHandler}
      ariaLabel={t('administrateUsers.viewMembers')}
      title={t('administrateUsers.viewMembers')}
      loading={submitting || loading}
      actions={actions}
      contentPadding="0"
      scroll="body"
      formStatus={formStatus}
    >
      <SideSpaceWrapper>
        <T color={theme.palette.darkDistinct}>{groupName}</T>
      </SideSpaceWrapper>
      {!formStatus && (
        <Form onSubmit={onSubmit}>
          <ButtonWrapper>
            <Button
              ariaLabel={t('administrateUsers.addMembersToGroup')}
              iconRight={AddIcon}
              fullWidth
              onClick={addMembersToGroupHandler}
              sx={{ justifyContent: 'space-between' }}
              theme="light"
              type="secondary"
            >
              {t('administrateUsers.addMembersToGroup')}
            </Button>
          </ButtonWrapper>
          <SideSpaceWrapper>
            <T color={theme.palette.darkDistinct} fontWeight="semibold">
              {t('administrateUsers.searchToFindLabel')}
            </T>
            <Spacer />
            <SearchField
              theme="light"
              ariaLabel={t('administrateUsers.searchForPeople')}
              placeholder={t('administrateUsers.searchForPeople')}
              value={searchTerm}
              onClear={onSearchHandler}
              onChange={onSearchHandler}
            />
            <Spacer />
            <T color={theme.palette.darkDistinct}>
              {t('administrateUsers.groupHasXMembers', {
                count: groupMembersParsed.length,
              })}
            </T>
          </SideSpaceWrapper>
          {!groupMembersParsed.length && (
            <NoGroupMembersWrapper>
              <NoMembersInnerWrapper>
                <UserIconWrapper>
                  <Icon
                    icon={PersonIcon}
                    color={theme.palette.lightPure}
                    size="4rem"
                  />
                </UserIconWrapper>
                <T color={theme.palette.darkDistinct} align="center">
                  {t('administrateUsers.noGroupsMembers')}
                </T>
              </NoMembersInnerWrapper>
            </NoGroupMembersWrapper>
          )}
          <ListWrapper>
            <ListItems
              items={membersSearched || groupMembersParsed}
              onRemove={onRemoveUserFromGroup}
            />
          </ListWrapper>
          {showConfirmationDialogue && (
            <ConfirmationDialog
              open={showConfirmationDialogue}
              title={t('generic.unsavedChanges')}
              description={t('generic.confirmSaveUnsavedChanges')}
              ariaLabel={t('generic.unsavedChanges')}
              cancelText={t('administrateUsers.discard')}
              confirmText={t('generic.confirm')}
              onCancel={onDiscardHandler}
              onConfirm={onSubmit}
              onClose={() => setShowConfirmationDialogue(false)}
            />
          )}
        </Form>
      )}
      {formStatus && (
        <Alert
          message={formStatus.message}
          severity={formStatus.success ? 'success' : 'error'}
          theme="light"
        />
      )}
    </Dialog>
  );
}

ViewGroupMembersMobileView.propTypes = {
  initialGroupMembers: PropTypes.array,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmitHandler: PropTypes.func.isRequired,
  groupName: PropTypes.string.isRequired,
  setEditGroupMembersOpen: PropTypes.func.isRequired,
  updatedGroupMembers: PropTypes.array,
  setUpdatedGroupMembers: PropTypes.func,
  formStatus: PropTypes.object,
};

ViewGroupMembersMobileView.defaultProps = {
  initialGroupMembers: [],
  loading: false,
  onClose: () => {},
  formStatus: null,
};

export default ViewGroupMembersMobileView;

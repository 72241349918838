import React from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';

import CircularSpinner from '@shared/atoms/CircularSpinner';
import ItemBox from '@shared/molecules/ItemBox';

import theme from '@shared/themes/default';

const DomainsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.s};
`;

const DomainsList = ({
  domains,
  loading,
  selectedDomain,
  setSelectedDomain,
}) => {
  if (loading) return <CircularSpinner static />;

  return (
    <DomainsWrapper>
      {domains.map(({ domain }) => (
        <ItemBox
          key={domain}
          id={domain}
          menuItems={[]}
          onClickHandler={(domain) =>
            setSelectedDomain(domains.find((d) => d.domain === domain))
          }
          selected={selectedDomain?.domain === domain}
          theme="dark"
          title={domain}
        />
      ))}
    </DomainsWrapper>
  );
};

DomainsList.propTypes = {
  domains: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  selectedDomain: PropTypes.object,
  setSelectedDomain: PropTypes.func.isRequired,
};

DomainsList.defaultProps = {
  domains: [],
  loading: false,
};

export default DomainsList;

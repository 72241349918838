import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useFormControl } from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import IconButton from 'shared/atoms/IconButton';

import { BACKGROUND_THEMES } from 'shared/const';
import theme from 'shared/themes/default';

import { INPUT_TYPE_ENUM } from './const';

const Input = ({
  ariaLabel,
  disabled,
  error,
  iconLeft: IconLeft,
  iconRight: IconRight,
  inputMode,
  inputType,
  inputProps,
  maxLength,
  pattern,
  success,
  sx: sxProp,
  theme: themeLayout,
  type,
  value,
  ...props
}) => {
  const { t } = useTranslation();
  const [passwordInputType, setPasswordInputType] = useState('password');

  const isPassword = inputType === 'password';

  const PasswordButton = () => {
    // Input component uses FormControl internally, but this button does not, so this is needed for inputs in forms
    // The prop gets priority
    const { disabled: disabledFormControl } = useFormControl() || {};

    const showPassword = passwordInputType !== 'password';

    return (
      <IconButton
        ariaLabel={t('generic.showPassword')}
        disabled={disabled === undefined ? disabledFormControl : disabled}
        icon={showPassword ? Visibility : VisibilityOff}
        onClick={() => {
          setPasswordInputType(showPassword ? 'password' : 'text');
        }}
        small
        theme={type === 'primary' || themeLayout === 'light' ? 'light' : 'dark'}
        type="transparent"
      />
    );
  };

  const sxGeneric = {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '140%',
    borderRadius: '6px',
    '&.Mui-focused': {
      outline: `3px solid ${theme.palette.focus}`,
      outlineOffset: '1px',
    },
    '&.MuiInputBase-multiline': {
      padding: `${theme.spacing.xs} 0`,
    },
    '&.MuiInputBase-adornedStart': {
      paddingLeft: theme.spacing.s,
    },
    '&.MuiInputBase-adornedEnd': {
      paddingRight: theme.spacing.s,
    },
    '& .MuiOutlinedInput-input': {
      height: '32px',
      padding: `${theme.spacing.xs} ${theme.spacing.s}`,
    },
    '&.Mui-error': {
      '& .MuiOutlinedInput-notchedOutline': {
        // Adding important to avoid a clash with focused and hover styles
        borderColor: `${
          themeLayout === 'light'
            ? theme.palette.errorDark
            : theme.palette.errorLight
        } !important`,
        borderWidth: '2px !important',
      },
    },
  };

  let sxType;
  if (type === 'primary' || (type === 'secondary' && themeLayout === 'light')) {
    sxType = {
      color: theme.palette.mainDark,
      backgroundColor: theme.palette.lightPure,
      '& .MuiOutlinedInput-input::placeholder': {
        color: theme.palette.disabledDark,
        opacity: 1,
      },
      '&:hover': {
        backgroundColor: theme.palette.lightDistinct,
      },
      '& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline':
        {
          borderColor: theme.palette.disabledLight,
        },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.disabledDark,
        borderWidth: '1px',
      },
      '&.Mui-disabled': {
        backgroundColor:
          themeLayout === 'light'
            ? theme.palette.lightDistinct
            : theme.palette.disabledLight,
        color:
          themeLayout === 'light'
            ? theme.palette.disabledDark
            : theme.palette.mainDark,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.disabledLight,
        },
        '.MuiOutlinedInput-input': {
          color:
            themeLayout === 'light'
              ? theme.palette.disabledDark
              : theme.palette.mainDark,
          // Material UI is adding this property for disabled, color does not override it
          WebkitTextFillColor:
            themeLayout === 'light'
              ? theme.palette.disabledDark
              : theme.palette.mainDark,
          '&::placeholder': {
            color:
              themeLayout === 'light'
                ? theme.palette.disabledLight
                : theme.palette.disabledDark,
            // Material UI is adding this property for disabled, color does not override it
            WebkitTextFillColor:
              themeLayout === 'light'
                ? theme.palette.disabledLight
                : theme.palette.disabledDark,
          },
        },
      },
    };
  } else if (type === 'secondary') {
    sxType = {
      color: theme.palette.lightPure,
      backgroundColor: 'transparent',
      '& .MuiOutlinedInput-input::placeholder': {
        color: theme.palette.disabledLight,
        opacity: 1,
      },
      '&:hover': {
        backgroundColor: theme.palette.oddDark,
      },
      '& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline':
        {
          borderColor: theme.palette.lightInteracting,
        },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.lightInteracting,
        borderWidth: '1px',
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.oddDark,
        borderColor: theme.palette.disabledDark,
        color: theme.palette.disabledDark,
        '& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline':
          {
            borderColor: theme.palette.disabledDark,
          },
        '.MuiOutlinedInput-input': {
          color: theme.palette.disabledLight,
          // Material UI is adding this property for disabled, color does not override it
          WebkitTextFillColor: theme.palette.disabledLight,
          '&::placeholder': {
            color: theme.palette.disabledDark,
            // Material UI is adding this property for disabled, color does not override it
            WebkitTextFillColor: theme.palette.disabledDark,
          },
        },
      },
    };
  }

  let sxValidation;

  if (success) {
    sxValidation = {
      '& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline':
        {
          borderColor:
            themeLayout === 'light'
              ? theme.palette.successDark
              : theme.palette.successLight,
          borderWidth: '2px',
        },
    };
  }

  const sx = {
    ...sxGeneric,
    ...sxType,
    ...sxValidation,
    ...sxProp,
  };

  const IconLeftComponent =
    React.isValidElement(IconLeft) || !IconLeft ? IconLeft : <IconLeft />;
  const IconRightComponent =
    React.isValidElement(IconRight) || !IconRight ? IconRight : <IconRight />;

  return (
    <OutlinedInput
      disabled={disabled}
      endAdornment={isPassword ? <PasswordButton /> : IconRightComponent}
      error={error}
      fullWidth
      inputProps={{
        'aria-label': ariaLabel,
        inputMode,
        maxLength,
        pattern,
        ...inputProps,
      }}
      startAdornment={IconLeftComponent}
      sx={sx}
      type={isPassword ? passwordInputType : inputType}
      value={value}
      {...props}
    />
  );
};

Input.propTypes = {
  ariaLabel: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  iconLeft: PropTypes.object,
  iconRight: PropTypes.object,
  inputProps: PropTypes.object,
  inputMode: PropTypes.string,
  inputType: PropTypes.string,
  maxLength: PropTypes.number,
  pattern: PropTypes.string,
  success: PropTypes.bool,
  sx: PropTypes.object,
  theme: PropTypes.oneOf(BACKGROUND_THEMES),
  type: PropTypes.oneOf(INPUT_TYPE_ENUM),
  value: PropTypes.string,
};

Input.defaultProps = {
  success: false,
  sx: {},
  theme: 'dark',
  type: 'primary',
};

export default Input;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import Input from '@shared/atoms/Input';
import IconButton from '@shared/atoms/IconButton';
import T from '@shared/atoms/Typography';
import Menu from '@shared/molecules/Menu';

import theme from '@shared/themes/default';

const Container = styled.div`
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${theme.spacing.s};
`;

const FieldWrapper = styled.div`
  margin-bottom: ${theme.spacing.s};
`;

const DynamicInputList = ({
  emptyLabel,
  inputs,
  onChange,
  title,
  isRequired,
  types,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleAddInput = () => {
    onChange([
      ...inputs,
      { ...types[0], id: `${types[0].type}-${uuid()}`, value: '' },
    ]);
  };

  const handleChangeInput = (index, value) => {
    const newList = [...inputs];
    newList[index] = { ...inputs[index], value };
    onChange(newList);
  };

  const handleDeleteInput = (index) => {
    onChange(inputs.filter((_, idx) => index !== idx));
  };

  const handleClearInput = (index) => {
    const newList = [...inputs];
    newList[index] = { ...inputs[index], value: '' };
    onChange(newList);
  };

  const getMenu = (options) => {
    return options.reduce(
      (prev, { type, label, placeholder, icon, svgIcon }) => {
        if (inputs.find((input) => input.type === type)) return prev;
        return [
          ...prev,
          {
            icon,
            svgIcon,
            label,
            handler: () =>
              onChange([
                ...inputs,
                {
                  id: `${type}-${uuid()}`,
                  type,
                  label,
                  placeholder,
                  value: '',
                },
              ]),
          },
        ];
      },
      []
    );
  };

  const customMenuHandler = (event) => {
    setAnchorEl(anchorEl === null ? event.currentTarget : null);
  };

  const isMultipleTypes = types.length > 1;
  const currentMenu = getMenu(types);

  return (
    <Container>
      <Header>
        <T>
          <>
            {title}
            {!isRequired && (
              <T component="span" fontWeight="medium" italic>
                {` (${t('generic.optional')})`}
              </T>
            )}
          </>
        </T>
        {(types.length === 1 || currentMenu.length > 0) && (
          <IconButton
            ariaLabel={t('generic.add')}
            icon={AddIcon}
            onClick={isMultipleTypes ? customMenuHandler : handleAddInput}
            type="secondary"
          />
        )}
      </Header>
      {isMultipleTypes && currentMenu.length > 0 && (
        <Menu
          freeMenu
          initAnchorEl={anchorEl}
          menuItems={currentMenu}
          setInitAnchorEl={setAnchorEl}
        />
      )}
      {inputs.length === 0 ? (
        <T
          color={theme.palette.disabledDark}
          sx={{
            textAlign: 'center',
            margin: `${theme.spacing.m1} ${theme.spacing.s}`,
          }}
        >
          {emptyLabel}
        </T>
      ) : (
        inputs.map(({ id, label, placeholder, value }, index) => (
          <FieldWrapper key={id}>
            {label && <T sx={{ mb: theme.spacing.xs }}>{label}</T>}
            <Input
              iconRight={
                <>
                  {value && value.length > 0 && (
                    <IconButton
                      ariaLabel={t('generic.close')}
                      icon={CloseIcon}
                      onClick={() => handleClearInput(index)}
                      small
                      type="transparent"
                      theme="light"
                    />
                  )}
                  <IconButton
                    ariaLabel={t('generic.delete')}
                    icon={DeleteOutlineIcon}
                    onClick={() => handleDeleteInput(index)}
                    small
                    type="transparent"
                    theme="light"
                  />
                </>
              }
              key={id}
              value={value}
              placeholder={placeholder}
              onChange={(ev) => handleChangeInput(index, ev.target.value)}
            />
          </FieldWrapper>
        ))
      )}
    </Container>
  );
};

DynamicInputList.propTypes = {
  emptyLabel: PropTypes.string,
  inputs: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  types: PropTypes.array.isRequired,
};

export default DynamicInputList;

import { useState, useEffect } from 'react';

export default function useOrganizations({
  listOrganizationsWatchData,
  setWatchDataError,
}) {
  const [organizationsWatchData, setOrganizationsWatchData] = useState([]);
  const [loadingOrganizations, setLoadingOrganizations] = useState(true);
  const [organizationsNextToken, setOrganizationsNextToken] = useState();

  const handleError = (error) => {
    console.error('Error calling listOrganizationsWatchData', error);
    setWatchDataError(true);
    setOrganizationsWatchData([]);
    setLoadingOrganizations(false);
  };

  const fetchOrganizations = async (nextToken = null) => {
    setLoadingOrganizations(true);
    try {
      const { data, error } = await listOrganizationsWatchData(nextToken);
      if (error) {
        return handleError(error);
      }

      const {
        listOrganizationsWatchData: { items, nextToken: newNextToken },
      } = data;
      if (!nextToken) {
        setOrganizationsWatchData(items);
      } else {
        setOrganizationsWatchData((organizations) => [
          ...organizations,
          ...items,
        ]);
      }

      if (newNextToken) {
        setOrganizationsNextToken(newNextToken);
      } else {
        setLoadingOrganizations(false);
        setOrganizationsNextToken(null);
      }
    } catch (error) {
      handleError(error);
    }

    return true;
  };

  useEffect(() => {
    if (organizationsNextToken) {
      fetchOrganizations(organizationsNextToken);
    }
  }, [organizationsNextToken]);

  return {
    organizationsWatchData,
    loadingOrganizationsWatchData: loadingOrganizations,
    fetchOrganizationsWatchData: fetchOrganizations,
  };
}

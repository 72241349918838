import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Chip from 'shared/atoms/Chip';
import { courseStatus } from 'shared/const';
import Table, { CELL_TYPES, ORDER_TYPES } from 'shared/organisms/Table';
import theme from 'shared/themes/default';

const InfoWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${theme.spacing.s};
`;

const AllCoursesOverviewTable = ({ itemsToDisplay, selectCourseHandler }) => {
  const { t } = useTranslation();

  const columns = [
    {
      key: 'name',
      label: t('watchData.courseName'),
      sticky: true,
      fixedWidth: '30%',
      type: CELL_TYPES.COMPONENT,
      render: (row) => (
        <InfoWrapper>
          {row.name}
          {row.status === courseStatus.ARCHIVED && (
            <Chip
              color={theme.palette.errorLight}
              label={t('watchData.deleted')}
              small
              theme="dark"
            />
          )}
        </InfoWrapper>
      ),
    },
    { key: 'viewsCount', label: t('watchData.usersViewed') },
    { key: 'completedCount', label: t('watchData.usersCompleted') },
    {
      key: 'averageCompletion',
      label: t('watchData.averageCompletion'),
      type: CELL_TYPES.COMPONENT,
      render: (row) =>
        t('watchData.percentage', { percentage: row.averageCompletion }),
    },
    { key: 'certificatesIssued', label: t('watchData.certificatesIssued') },
  ];

  const showCourseOwner = itemsToDisplay.some(
    (item) => 'ownerOrganizationName' in item
  );
  if (showCourseOwner) {
    columns.splice(1, 0, {
      key: 'ownerOrganizationName',
      label: t('watchData.organizationName'),
      maskValue: true,
    });
  }

  return (
    <Table
      ariaLabel={t('watchData.activitiesOfYourCourses')}
      columns={columns}
      items={itemsToDisplay}
      defaultOrder={ORDER_TYPES.DESC}
      defaultOrderBy="viewsCount"
      onRowClick={(row) => selectCourseHandler(row)}
      rowIsClickable={(row) => !!row.viewsCount}
    />
  );
};

AllCoursesOverviewTable.propTypes = {
  itemsToDisplay: PropTypes.array.isRequired,
  selectCourseHandler: PropTypes.func.isRequired,
};

export default AllCoursesOverviewTable;

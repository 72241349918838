import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import Autocomplete from '@shared/atoms/Autocomplete';
import T from '@shared/atoms/Typography';
import PlaylistAdministration from '@shared/sections/PlaylistAdministration';
import { PlaylistProvider } from '@shared/sections/PlaylistAdministration/context';
import theme from '@shared/themes/default';

import { useAppGlobals } from '../../contexts/AppContext';
import {
  getUploadUrl,
  startUpload,
  completeUpload,
} from '../../../graphql/mutations';
import { environment, getStaticImagesHostname } from '../../../config';
import {
  copyPlaylistMutation,
  createOrUpdateQuiz,
  createPlaylistMutation,
  deletePlaylistMutation,
  removePlaylistContent,
  sendPlaylistReminder,
  sortPlaylistContent,
  transferPlaylist,
  updatePlaylistAccessMutation,
  updatePlaylistContentMutation,
  updatePlaylistMutation,
} from './graphql/mutations';
import {
  getOrganization,
  getOrganizationMembers,
  getOrganizationMembersCount,
  getPlaylist,
  getPlaylistContentQuery,
  listAllCoursesQuery,
  listGroups,
  listGroupsByPromotedPlaylist,
  listUsersByPromotedPlaylist,
  listOrganizationPlaylistCreators,
  listOrganizationsNames,
  listPlaylists,
  listExperts,
  getQuiz,
} from './graphql/queries';
import { graphql, listOrganizationOptions } from '../../../utils';

const AutocompleteWrapper = styled.div`
  max-width: 400px;
  margin-bottom: 1.5rem;
`;

const PlaylistView = () => {
  const { domain, locale } = useAppGlobals();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { t } = useTranslation();

  const [organizationSelected, setOrganizationSelected] = useState(null);
  const [organizationId, setOrganizationId] = useState(null);
  const [organizationInfo, setOrganizationInfo] = useState(null);

  const searchParams = new URLSearchParams(search);
  const preSelectedOrganizationId = searchParams.get('organization');
  const preSelectedManagerId = searchParams.get('manager');
  const preSelectedPlaylistId = searchParams.get('playlist');
  const preSelectedType = searchParams.get('type');

  const previewBaseUrl = `https://app${
    environment !== 'production' ? `.${environment}` : ''
  }.videocation.${domain}`;

  const fetchOrganizations = async () => {
    const response = await graphql(listOrganizationsNames(domain));
    return listOrganizationOptions(
      response?.data?.listOrganizations.items || []
    );
  };

  const fetchOrganization = async (organizationId) => {
    setOrganizationId();
    setOrganizationInfo();

    if (!organizationId || organizationId === 'global') {
      setOrganizationId('global');
      setOrganizationInfo({ id: 'global', hideVcCourses: false });
    } else {
      const response = await graphql(getOrganization(organizationId));
      const selectedOrganizationInfo = response.data.getOrganization;

      if (selectedOrganizationInfo) {
        setOrganizationId(organizationId);
        setOrganizationInfo(selectedOrganizationInfo);
        setOrganizationSelected({
          name:
            selectedOrganizationInfo.name ||
            `No name (ID: ${selectedOrganizationInfo.id})`,
          value: selectedOrganizationInfo.id,
        });
      }
    }
  };

  const seeActivityHandler = (id) => {
    navigate(
      `/activity?organization=${organizationId}&view=playlists&id=${id}`
    );
  };

  const selectOrganizationHandler = (organization) => {
    setOrganizationSelected(organization);
    if (organization) {
      const { value: organizationId } = organization;
      navigate(`/playlists?organization=${organizationId}`);
    } else {
      navigate('/playlists');
    }
  };

  const getGraphQlQuery = (query) => {
    const graphQlQuery = async (...params) => graphql(query(...params));
    return graphQlQuery;
  };

  const getGraphQlQueryWithOrganization = (query, organizationId) => {
    const graphQlQuery = async (...params) =>
      graphql(query(organizationId, ...params));
    return graphQlQuery;
  };

  useEffect(() => {
    fetchOrganization(preSelectedOrganizationId);
  }, [preSelectedOrganizationId]);

  return (
    <div>
      <T
        color={theme.palette.lightPure}
        sx={{
          marginBottom: theme.spacing.m1,
        }}
        variant="subtitle"
      >
        {t('backoffice.general.findOrganization')}
      </T>
      <AutocompleteWrapper>
        <Autocomplete
          getAllOptions={fetchOrganizations}
          getOptionKey={(option) => option.value}
          getOptionLabel={(option) => option.name}
          onChange={selectOrganizationHandler}
          preSelectedValue={organizationSelected}
        />
      </AutocompleteWrapper>
      {organizationId && organizationInfo && (
        <PlaylistProvider
          copyPlaylist={getGraphQlQueryWithOrganization(
            copyPlaylistMutation,
            organizationId
          )}
          listAllCourses={async (...params) =>
            graphql(
              listAllCoursesQuery(
                organizationId === 'global' ? '' : organizationId,
                organizationInfo.hideVcCourses,
                domain,
                ...params
              )
            )
          }
          listPlaylists={getGraphQlQuery(listPlaylists)}
          locale={locale}
          organization={organizationInfo}
          managerId={preSelectedManagerId || 'all'}
          type={preSelectedType}
          handleHistory={(search) =>
            navigate(
              {
                pathname,
                search: [`organization=${organizationId}`, search].join('&'),
              },
              { replace: true }
            )
          }
          getPlaylistContent={getGraphQlQuery(getPlaylistContentQuery)}
          updatePlaylist={getGraphQlQuery(updatePlaylistMutation)}
          updatePlaylistAccess={getGraphQlQuery(updatePlaylistAccessMutation)}
          updatePlaylistContent={getGraphQlQuery(updatePlaylistContentMutation)}
          addNanoDegreeExam={getGraphQlQuery(createOrUpdateQuiz)}
          getNanoDegreeExam={getGraphQlQuery(getQuiz)}
          createPlaylist={getGraphQlQueryWithOrganization(
            createPlaylistMutation,
            organizationId === 'global' ? null : organizationId
          )}
          deletePlaylist={getGraphQlQuery(deletePlaylistMutation)}
          getOrganizationMembers={getGraphQlQueryWithOrganization(
            getOrganizationMembers,
            organizationId
          )}
          getOrganizationMembersCount={getGraphQlQueryWithOrganization(
            getOrganizationMembersCount,
            organizationId
          )}
          getPlaylist={getGraphQlQuery(getPlaylist)}
          listExperts={async (...params) =>
            graphql(listExperts(domain, ...params))
          }
          listGroups={getGraphQlQueryWithOrganization(
            listGroups,
            organizationId
          )}
          listGroupsByPromotedPlaylist={getGraphQlQueryWithOrganization(
            listGroupsByPromotedPlaylist,
            organizationId
          )}
          listOrganizationPlaylistCreators={getGraphQlQueryWithOrganization(
            listOrganizationPlaylistCreators,
            organizationId
          )}
          listUsersByPromotedPlaylist={getGraphQlQuery(
            listUsersByPromotedPlaylist
          )}
          removePlaylistContent={getGraphQlQuery(removePlaylistContent)}
          playlistId={preSelectedPlaylistId}
          seeActivityHandler={seeActivityHandler}
          sendPlaylistReminder={getGraphQlQuery(sendPlaylistReminder)}
          sortPlaylistContent={getGraphQlQuery(sortPlaylistContent)}
          transferPlaylist={getGraphQlQueryWithOrganization(
            transferPlaylist,
            organizationId
          )}
          graphql={graphql}
          completeUpload={completeUpload}
          getUploadUrl={getUploadUrl}
          startUpload={startUpload}
          hasSubscription
          isSubscriptionAdmin
          staticImagesHostname={getStaticImagesHostname()}
          previewBaseUrl={previewBaseUrl}
        >
          <PlaylistAdministration isBackOffice key={organizationId} />
        </PlaylistProvider>
      )}
    </div>
  );
};

export default PlaylistView;

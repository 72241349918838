import React from 'react';
import PropTypes from 'prop-types';

import FormControl from '@mui/material/FormControl';

import AutocompleteWithList from 'shared/molecules/AutocompleteWithList';
import { BACKGROUND_THEMES, AUTOCOMPLETEWITHLIST_TYPE } from 'shared/const';

import FormLabel from '../components/FormLabel';

const FormAutocompleteWithList = ({
  disabled: disabledProp,
  id,
  infoText,
  label,
  name,
  onAdd,
  onDelete,
  properties,
  required: requiredProp,
  selectedItems,
  sx,
  theme: themeLayout,
  values,
  ...props
}) => {
  const { disabled, required } = properties[name] || {};

  const onChangeHandler = (values) => {
    if (values.length > selectedItems.length) {
      onAdd(
        values.find(
          (value) => !selectedItems.find((item) => item.key === value.key)
        ).key
      );
    } else if (values.length < selectedItems.length) {
      onDelete(
        selectedItems.find(
          (item) => !values.find((value) => value.key === item.key)
        ).key
      );
    }
  };

  return (
    <FormControl
      disabled={disabledProp === undefined ? disabled : disabledProp}
      fullWidth
      required={requiredProp === undefined ? required : requiredProp}
      sx={sx}
    >
      <FormLabel
        id={id}
        infoText={infoText}
        label={label}
        theme={themeLayout}
      />
      <AutocompleteWithList
        id={id}
        name={name}
        onChange={onChangeHandler}
        selectedItems={selectedItems}
        theme={themeLayout}
        type={AUTOCOMPLETEWITHLIST_TYPE.FORM}
        value={values[name]}
        disabled={disabledProp}
        {...props}
      />
    </FormControl>
  );
};

FormAutocompleteWithList.propTypes = {
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  id: PropTypes.string.isRequired,
  infoText: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  properties: PropTypes.object,
  required: PropTypes.bool,
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  sx: PropTypes.object,
  theme: PropTypes.oneOf(BACKGROUND_THEMES),
  values: PropTypes.object,
};

FormAutocompleteWithList.defaultProps = {
  properties: {},
  selectedItems: [],
  sx: {},
  theme: 'dark',
  values: {},
};

export default FormAutocompleteWithList;

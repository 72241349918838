import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import FormToggle from '@shared/atoms/FormFields/FormToggle';
import T from '@shared/atoms/Typography';
import Dialog from '@shared/molecules/Dialog';
import theme from '@shared/themes/default';
import { downloadFile, logger } from '@shared/utils';

import { useAppGlobals } from '../../../contexts/AppContext';

import { boApi } from '../graphql/mutations';
import { useGraphQLQuery } from '../../../../utils';

const ExportAllUsersDialog = ({
  onClose,
  open,
  setErrorMessage,
  setHasError,
  setHasSuccess,
  setSuccessMessage,
}) => {
  const { user } = useAppGlobals();
  const [jobId, setJobId] = useState();
  const [processing, setProcessing] = useState(false);
  const [progress, setProgress] = useState();
  const [singleUsers, setSingleUsers] = useState(false);
  const { t } = useTranslation();

  const { callQuery, data, errors, hasEnded, hasErrors } = useGraphQLQuery();

  useEffect(() => {
    if (hasEnded) {
      if (hasErrors) {
        logger.error('Error: ', errors);
        setErrorMessage(
          t('backoffice.organizations.exportAllUsers.failedToExport')
        );
        setHasError(true);
        onClose();
      } else {
        const {
          boApi: { data: boApiData },
        } = data;

        const parsedData = JSON.parse(boApiData);

        if (parsedData.Item?.ended) {
          const response = JSON.parse(parsedData.Item?.resp);
          downloadFile(
            user?.signInUserSession?.accessToken?.jwtToken,
            response.dom,
            response.file
          );

          setProcessing(false);
          setSuccessMessage(
            t('backoffice.organizations.exportAllUsers.successfullyExported')
          );
          setHasSuccess(true);

          onClose();
        } else {
          setProgress(parsedData.Item?.progress || 0);
          setTimeout(() => {
            callQuery(
              boApi({
                module: 'db',
                method: 'get',
                arguments: {
                  table: 'bo-task-table',
                  key: {
                    id: jobId,
                  },
                },
              })
            );
          }, 10000);
        }
      }
    }
  }, [hasEnded]);

  useEffect(() => {
    setProcessing(false);
    setSingleUsers(false);
    setProgress();
  }, [open]);

  const actions = [
    {
      ariaLabel: t('generic.cancel'),
      label: t('generic.cancel'),
      handler: onClose,
      type: 'secondaryOutline',
    },
    {
      ariaLabel: t('backoffice.organizations.exportAllUsers.export'),
      label: t('backoffice.organizations.exportAllUsers.export'),
      handler: () => {
        const jobId = uuidv4();
        setJobId(jobId);
        callQuery(
          boApi({
            module: 'db',
            method: 'create',
            arguments: {
              table: 'bo-task-table',
              item: {
                id: jobId,
                jobId: 'bo-export-all-users',
                module: 'export',
                method: 'exportUsers',
                arguments: {
                  includeNonOrgMembers: singleUsers,
                },
              },
            },
          })
        );
        setProcessing(true);
      },
      disabled: false,
      submit: true,
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      actions={actions}
      title={t('backoffice.organizations.exportAllUsers.title')}
      ariaLabel={t('backoffice.organizations.exportAllUsers.title')}
      loading={processing}
    >
      {progress !== undefined ? (
        <T color={theme.palette.mainDark}>
          {t('backoffice.organizations.exportAllUsers.progress', {
            count: progress,
          })}
        </T>
      ) : (
        <FormToggle
          id="singleUsers"
          label={t(
            'backoffice.organizations.exportAllUsers.includeSingleSubscribers'
          )}
          name="singleUsers"
          handleChange={({ value }) => setSingleUsers(value)}
          theme="light"
          values={{ singleUsers }}
        />
      )}
    </Dialog>
  );
};

ExportAllUsersDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  setHasError: PropTypes.func.isRequired,
  setHasSuccess: PropTypes.func.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
};

export default ExportAllUsersDialog;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Avatar from '@mui/material/Avatar';

import Button from 'shared/atoms/Button';
import Link from 'shared/atoms/Link';
import theme from 'shared/themes/default';
import { getUserInitials } from 'shared/utils';

const StyledLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.m1};
  flex-flow: row wrap;
`;

const StyledLinkContainerMenu = styled.div`
  background-color: ${theme.palette.lightPure};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m1};
  padding: ${theme.spacing.m2} ${theme.spacing.m1};
`;

const Wrapper = styled.div`
  display: block;
`;

const MenuHeader = styled.div`
  background: ${theme.palette.mainDark};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m1};
  padding: ${theme.spacing.m2} ${theme.spacing.m1};
`;

const MenuHeaderLine = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const MenuHeaderLineMain = styled(MenuHeaderLine)`
  justify-content: space-between;
`;

const MenuHeaderLineStretch = styled(MenuHeaderLine)`
  justify-content: space-between;
  column-gap: 0.5rem;
  flex-wrap: nowrap;
`;

const MenuHeaderUser = styled.div`
  display: flex;
  align-items: center;
`;

const MenuHeaderUsername = styled.div`
  margin-left: 1rem;
  font-size: 1.25rem;
  color: ${theme.palette.lightPure};
  max-width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${theme.fonts.primary};
`;

const StyledAvatar = styled(Avatar)`
  font-size: 1.25rem;
  font-weight: 400;
  width: 2.5rem;
  height: 2.5rem;
  background-color: ${theme.palette.lightPure};
  color: ${theme.palette.mainDark};
`;

const MobileMenu = ({
  adminOptions,
  closePopover,
  loginUrl,
  options,
  onSignOut,
  registerUrl,
  showButtons,
  user,
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <MenuHeader>
        {user ? (
          <>
            <MenuHeaderLineMain>
              <MenuHeaderUser>
                <StyledAvatar>{getUserInitials(user)}</StyledAvatar>
                <MenuHeaderUsername>{user.name}</MenuHeaderUsername>
              </MenuHeaderUser>
              <Button
                ariaLabel={t('header.signOut')}
                onClick={onSignOut}
                theme="dark"
                type="secondaryOutline"
              >
                {t('header.signOut')}
              </Button>
            </MenuHeaderLineMain>
            <MenuHeaderLine>
              <StyledLinkContainer>
                {adminOptions.map((o) => (
                  <Link
                    ariaLabel={o.ariaLabel}
                    key={o.to}
                    onClick={closePopover}
                    paragraph
                    to={o.to}
                    theme="dark"
                    visibility="low"
                  >
                    {t(o.text)}
                  </Link>
                ))}
              </StyledLinkContainer>
            </MenuHeaderLine>
          </>
        ) : (
          <MenuHeaderLineStretch>
            {showButtons && (
              <Button
                ariaLabel={t('header.login')}
                fullWidth
                theme="dark"
                to={loginUrl}
                type="secondary"
              >
                {t('header.login')}
              </Button>
            )}
            {showButtons && (
              <Button
                ariaLabel={t('header.register')}
                fullWidth
                theme="dark"
                to={registerUrl}
                type="secondaryOutline"
              >
                {t('header.register')}
              </Button>
            )}
          </MenuHeaderLineStretch>
        )}
      </MenuHeader>
      {options.length > 0 && (
        <StyledLinkContainerMenu>
          {options.map((o) => (
            <Link
              ariaLabel={o.ariaLabel}
              href={o.href}
              key={o.href || o.to}
              onClick={closePopover}
              paragraph
              to={o.to}
              theme="light"
            >
              {t(o.text)}
            </Link>
          ))}
        </StyledLinkContainerMenu>
      )}
    </Wrapper>
  );
};

MobileMenu.propTypes = {
  adminOptions: PropTypes.array.isRequired,
  closePopover: PropTypes.func.isRequired,
  loginUrl: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onSignOut: PropTypes.func.isRequired,
  registerUrl: PropTypes.string,
  showButtons: PropTypes.bool,
  user: PropTypes.object,
};

export default MobileMenu;

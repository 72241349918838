import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Alert from 'shared/atoms/Alert';
import Form from 'shared/atoms/Form';
import FormInput from 'shared/atoms/FormFields/FormInput';
import Dialog from 'shared/molecules/Dialog';
import theme from 'shared/themes/default';
import { useForm } from 'shared/utils';

const EditGroup = ({
  formStatus,
  initialValues,
  maxNameLength,
  onSave,
  onClose,
}) => {
  const { t } = useTranslation();

  const { groupId, name, description } = initialValues;

  const {
    disableSubmit,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    properties,
    submitting,
    values,
  } = useForm({
    changesRequired: true,
    fields: {
      name: {
        default: name || '',
        maxLength: maxNameLength,
        required: true,
      },
      description: {
        default: description || '',
      },
    },
    onSubmit: (values) => onSave({ ...values, groupId }),
  });

  const actions = [
    {
      ariaLabel: t('generic.saveChanges'),
      disabled: disableSubmit,
      handler: handleSubmit,
      label: t('generic.saveChanges'),
    },
  ];

  return (
    <Dialog
      open
      onClose={onClose}
      ariaLabel={t('administrateUsers.editGroup')}
      title={t('administrateUsers.editGroup')}
      actions={actions}
      loading={submitting}
      formStatus={formStatus}
    >
      {formStatus && (
        <Alert
          message={t(
            formStatus.success
              ? 'administrateUsers.groupUpdatedSuccessMessage'
              : 'administrateUsers.groupUpdatedFailureMessage'
          )}
          severity={formStatus.success ? 'success' : 'error'}
          theme="light"
        />
      )}
      {!formStatus && (
        <Form>
          <FormInput
            id="name"
            name="name"
            theme="light"
            label={t('administrateUsers.groupName')}
            placeholder={t('administrateUsers.groupNamePlaceHolder')}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            properties={properties}
            sx={{ mb: theme.spacing.m2 }}
            values={values}
          />
          <FormInput
            id="description"
            name="description"
            theme="light"
            label={t('administrateUsers.description')}
            minRows={4}
            multiline
            placeholder={t('administrateUsers.descriptionPlaceHolder')}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            properties={properties}
            sx={{ mb: theme.spacing.xs }}
            values={values}
          />
        </Form>
      )}
    </Dialog>
  );
};

EditGroup.propTypes = {
  formStatus: PropTypes.object,
  initialValues: PropTypes.shape({
    description: PropTypes.string.isRequired,
    groupId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  maxNameLength: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

EditGroup.defaultProps = {
  maxNameLength: 70,
};

export default EditGroup;

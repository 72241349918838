import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from 'shared/atoms/Button';
import Table, { CELL_TYPES, ORDER_TYPES } from 'shared/organisms/Table';

const GroupMembersTable = ({ onDeleteUser, groupMembers, userId }) => {
  const { t } = useTranslation();

  return (
    <Table
      ariaLabel="group members table"
      columns={[
        {
          key: 'name',
          label: t('administrateUsers.name'),
          sticky: true,
          maskValue: true,
        },
        {
          key: 'email',
          label: t('administrateUsers.email'),
          maskValue: true,
        },
        {
          key: 'delete',
          disableSorting: true,
          type: CELL_TYPES.COMPONENT,
          render: (row) => (
            <Button
              ariaLabel={`${t('playlist.remove')} ${row.email}`}
              onClick={() => onDeleteUser(row)}
              small
              type="text"
            >
              {t('playlist.remove')}
            </Button>
          ),
        },
      ]}
      items={groupMembers.map((row) => ({
        ...row,
        name: `${row.name} ${
          userId === row.id ? ` (${t('administrateUsers.you')})` : ''
        }`,
      }))}
      defaultOrder={ORDER_TYPES.ASC}
      defaultOrderBy="name"
    />
  );
};

GroupMembersTable.propTypes = {
  onDeleteUser: PropTypes.func.isRequired,
  groupMembers: PropTypes.array.isRequired,
  userId: PropTypes.string,
};

export default GroupMembersTable;

import styled from 'styled-components';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import withStyles from '@mui/styles/withStyles';
import HorizontalRule from 'shared/atoms/HorizontalRule';
import theme from 'shared/themes/default';

export const StyledErrorOutlineIcon = withStyles({
  root: {
    color: theme.palette.lightPure,
    verticalAlign: 'middle',
    marginLeft: '0.3rem',
    '&:hover, &:focus, &:active': {
      color: theme.palette.brand,
    },
  },
})(ErrorOutlineIcon);

export const Block = styled.div`
  font-family: ${theme.fonts.primary};
  color: ${theme.palette.lightInteracting};
  font-size: 1rem;
  line-height: 2em;
  font-weight: 'normal';
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${theme.breakpoints.mobile} {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
`;

export const ButtonWrapper = styled.div`
  ${theme.breakpoints.mobile} {
    align-self: flex-start;
    margin-top: 0.625rem;
  }
`;

export const SearchMemberWrapper = styled.div`
  padding-top: 2.15rem;
  ${theme.breakpoints.mobile} {
    padding-top: 0px;
  }
`;

export const AutoCompleteWrapper = styled.div`
  width: 39%;
  ${theme.breakpoints.tablet} {
    width: 50%;
  }
  ${theme.breakpoints.mobile} {
    width: 100%;
  }
`;

export const StyledHorizontalRule = styled(HorizontalRule)`
  margin-top: 1.875rem;
`;

export const SelectedMemberPreviewWrapper = styled.div`
  padding-top: 0.625rem;
`;

export const SelectedMember = styled.div`
  color: ${theme.palette.lightPure};
  font-family: ${theme.fonts.primary};
  font-size: 1.25rem;
  text-transform: uppercase;
  display: inline-block;
`;

export const SelectedMemberName = styled.div`
  color: ${theme.palette.brand};
  font-family: ${theme.fonts.primary};
  font-size: 1.25rem;
  text-transform: capitalize;
  display: inline-block;
  padding-left: 0.3rem;
`;

export const SelectedMembersRole = styled.div`
  padding-top: 1.55rem;
  width: 39%;
  ${theme.breakpoints.tablet} {
    width: 50%;
  }
  ${theme.breakpoints.mobile} {
    width: 100%;
  }
`;

export const SelectWrapper = styled.div`
  width: 100%;
`;

export const ContentOwnershipWrapper = styled.div`
  padding-top: 1.875rem;
`;

export const ContentOwnershipHeader = styled.div`
  font-family: ${theme.fonts.primary};
  color: ${theme.palette.lightInteracting};
  font-size: 1.25rem;
  line-height: 2em;
  font-weight: 'normal';
  margin-right: 0.625rem;
`;

export const ContentOwnershipSubHeader = styled.div`
  font-family: ${theme.fonts.primary};
  color: ${theme.palette.lightInteracting};
  font-size: 1rem;
  line-height: 2em;
  font-weight: 'normal';
`;

export const TransferContentOptionWrapper = styled.div`
  padding-top: 0.625rem;
`;

export const SearchManagerWrapper = styled.div`
  padding-top: 1.25rem;
`;

export const FormSubmitButtonWrapper = styled.div`
  padding-top: 1.56rem;
  ${theme.breakpoints.mobile} {
    align-self: flex-start;
    padding-top: 1rem;
  }
`;

export const MessageBoxWrapper = styled.div`
  margin-top: 0.625rem;
`;
